import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  CardFooter,
} from "../shadcn/Card";
import { Progress } from "../shadcn/Progress";
import {
  CheckCircle2,
  ChevronRight,
  ClipboardList,
  ExternalLink,
  FileSpreadsheet,
  LineChart,
  Zap,
} from "lucide-react";
import { Button } from "../shadcn/Button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../shadcn/Tooltip";
import { Link, useNavigate } from "react-router-dom";
import CollapsibleSection from "./CollapsibleSection";
import StepAccordion from "./StepAccordion";

const getCategoryIcon = (categoryTitle) => {
  const iconMap = {
    IDENTIFY: <ClipboardList className="tw-h-6 tw-w-6" />,
    MEASURE: <FileSpreadsheet className="tw-h-6 tw-w-6" />,
    VERIFY: <CheckCircle2 className="tw-h-6 tw-w-6" />,
    REPORT: <LineChart className="tw-h-6 tw-w-6" />,
    REDUCE: <Zap className="tw-h-6 tw-w-6" />,
  };

  return iconMap[categoryTitle];
};

const CategoryCard = ({
  category,
  index,
  calculateCategoryProgress,
  calculateProgress,
  nextTask,
  setStepsProgressData,
}) => {
  const isNextTaskCategory = nextTask && nextTask.catIndex === index;
  const [openStep, setOpenStep] = useState(null);
  const navigate = useNavigate();

  const handleOpenAccordion = (step) => {
    if (isNextTaskCategory) {
      if (nextTask.url.startsWith("https://")) {
        // For external URLs, open in a new tab
        window.open(nextTask.url, "_blank");
      } else {
        // For internal URLs
        navigate(nextTask.url, { state: { stringId: nextTask.stringId } });
      }

      // setOpenStep(`item-${nextTask.stepIndex}`);
    } else {
      setOpenStep("item-0");
    }
  };
  return (
    <Card key={index} className="tw-flex tw-flex-col">
      <CardHeader className="tw-relative">
        <div className="tw-absolute tw-top-4 tw-right-4 tw-bg-primary tw-text-primary-foreground tw-rounded-full tw-w-8 tw-h-8 tw-flex tw-items-center tw-justify-center tw-font-bold">
          {index + 1}
        </div>
        <CardTitle className="tw-flex tw-items-center tw-gap-2">
          {getCategoryIcon(category.name)}
          {category.name}
        </CardTitle>
        <CardDescription>
          Progress: {calculateCategoryProgress(category).toFixed(0)}%
        </CardDescription>
      </CardHeader>
      <CardContent className="tw-flex-grow">
        <Progress
          value={calculateCategoryProgress(category)}
          className="tw-mb-4"
        />
        <StepAccordion
          steps={category.steps}
          categoryIndex={index}
          calculateProgress={calculateProgress}
          nextTask={nextTask}
          setStepsProgressData={setStepsProgressData}
          openStep={openStep}
          setOpenStep={setOpenStep}
        />
      </CardContent>
      <CardFooter className="tw-flex tw-justify-between tw-items-center">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                className={`tw-flex-grow ${
                  isNextTaskCategory ? "tw-animate-pulse" : ""
                }`}
                onClick={() => handleOpenAccordion()}
              >
                {calculateCategoryProgress(category) === 100
                  ? "Review"
                  : isNextTaskCategory
                  ? `Continue: ${nextTask.subStep}`
                  : "Open"}
                <ChevronRight className="tw-h-4 tw-w-4 tw-ml-2" />
              </Button>
            </TooltipTrigger>
            {/* <TooltipContent>
              {calculateCategoryProgress(category) === 100
                ? "View a summary of completed tasks"
                : isNextTaskCategory
                ? `Next task: ${nextTask.step} ${nextTask.task}`
                : `Continue working on ${category.name}`}
            </TooltipContent> */}
          </Tooltip>
        </TooltipProvider>
        <Link
          to={category.resourceLink}
          className="tw-ml-2"
          aria-label={`Learn more about ${category.title}`}
        >
          <Button variant="outline" size="icon">
            <ExternalLink className="tw-h-4 tw-w-4" />
          </Button>
        </Link>
      </CardFooter>
    </Card>
  );
};

export default CategoryCard;
