import React, { useState, useEffect } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { getActiveUserData, getInventoryProgress } from "../../helpers/store";

function NewReportingPeriodModal({
  show,
  onHide,
  onAddMeasurementYear,
  forceAnswer = false,
}) {
  const [measurementYear, setMeasurementYear] = useState("");
  const [measurementType, setMeasurementType] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSavingMeasurementYear, setIsSavingMeasurementYear] = useState(false);
  const [activeUser, setActiveUser] = useState(JSON.parse(getActiveUserData()));

  useEffect(() => {
    if (measurementYear && measurementType) {
      const existingInventoryProgress =
        JSON.parse(getInventoryProgress()) || [];
      const isDuplicate = existingInventoryProgress.some(
        (inv) =>
          inv.measurementyear === measurementYear &&
          inv.measurementyeartype === measurementType
      );
      setIsDisabled(isDuplicate);
    } else {
      setIsDisabled(false);
    }
  }, [measurementYear, measurementType]);

  const handleSubmit = async () => {
    setIsSavingMeasurementYear(true);
    const newUser = !activeUser.inventoryId ? true : false;
    const result = await onAddMeasurementYear(
      measurementYear,
      measurementType,
      newUser
    );
    setIsSavingMeasurementYear(false);
    onHide(); // Close the modal if measurement year was added successfully
  };

  return (
    <Modal
      show={show}
      onHide={!forceAnswer ? onHide : undefined}
      size="xl"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Set Your Reporting Year</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="measurementYear">
            <Form.Label>Reporting Year*</Form.Label>
            <Form.Control
              as="select"
              value={measurementYear}
              onChange={(e) => setMeasurementYear(e.target.value)}
            >
              <option value="">Please select...</option>
              <option value="2025">2025</option>
              <option value="2024">2024</option>
              <option value="2023">2023</option>
              <option value="2022">2022</option>
              <option value="2021">2021</option>
              <option value="2020">2020</option>
              <option value="2019">2019</option>
            </Form.Control>
          </Form.Group>
          <br />
          <Form.Group controlId="measurementYear">
            <Form.Label>
              Please note that in Australia, financial years run from 1 July to
              30 June the current year. For example, selecting Financial Year
              2022 (FY22) corresponds to the period 1 July 2021 - 30 June 2022.
            </Form.Label>
          </Form.Group>

          <Form.Group controlId="type">
            <Form.Label>Type*</Form.Label>
            <Form.Control
              as="select"
              value={measurementType}
              onChange={(e) => setMeasurementType(e.target.value)}
            >
              <option value="">Please select...</option>
              <option value="calendar">Calendar Year</option>
              <option value="financial">Financial Year</option>
            </Form.Control>
          </Form.Group>
        </Form>
        {isDisabled && (
          <div className="text-danger mt-2">
            The selected reporting year and type already exist.
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          type="submit"
          onClick={handleSubmit}
          disabled={
            !measurementYear ||
            !measurementType ||
            isDisabled ||
            isSavingMeasurementYear
          }
        >
          {isSavingMeasurementYear && (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
            </>
          )}
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default NewReportingPeriodModal;
