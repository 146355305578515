import { clearLoginData } from "../../helpers/store";
import {
  getAuthorizationHeader,
  getAuthorizationHeaderWithContentType,
} from "../../helpers/utils";
import { toast } from "react-toastify";

// export const getInviteUrl = async (travelType) => {
//   try {
//     const response = await fetch("/api/wfh/get-invite-url", {
//       method: "GET",
//       headers: getAuthorizationHeaderWithContentType(),
//       mode: "cors",
//     });

//     if (response.status === 200) {
//       const jsonData = await response.json();
//       return jsonData;
//     } else if (response.status === 401) {
//       clearLoginData();
//     }

//     return [];
//   } catch (error) {
//     console.error(error);
//   }
// };

export const saveEmployeeEngagementForm = async (body) => {
  try {
    const response = await fetch(
      "/api/employeeEngagement/save-employee-engagement-form",
      {
        method: "POST",
        headers: getAuthorizationHeaderWithContentType(),
        body: JSON.stringify(body),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      // await response.json();
      toast.success("Employee Engagement form saved");
    } else if (response.status === 401) {
      clearLoginData();
    } else {
      toast.error("Failed to save Employee Engagement form");
      throw new Error(`Failed to save form, status code: ${response.status}`);
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getEmployeeEngagementForms = async () => {
  try {
    const response = await fetch(
      "/api/employeeEngagement/get-employee-engagement-forms",
      {
        method: "GET",
        headers: getAuthorizationHeaderWithContentType(),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getOfficesByBusiness = async () => {
  try {
    const response = await fetch(
      "/api/employeeEngagement/get-offices-by-business",
      {
        method: "GET",
        headers: getAuthorizationHeaderWithContentType(),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getEmployeeEngagementForm = async (formId) => {
  try {
    const response = await fetch(
      "/api/employeeEngagement/get-employee-engagement-form/" + formId,
      {
        method: "GET",
        headers: getAuthorizationHeaderWithContentType(),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const saveEmployeeEngagementEstimationFormData = async (body) => {
  try {
    const response = await fetch(
      "/api/employeeEngagement/save-employee-engagement-estimation-form-data",
      {
        method: "POST",
        headers: getAuthorizationHeaderWithContentType(),
        body: JSON.stringify(body),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      toast.success("Employee Engagement estimation form data saved");
      // await response.json();
      return response;
    } else if (response.status === 401) {
      clearLoginData();
    } else {
      toast.error("Failed to save Employee Engagement estimation form data");
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const saveEmployeeEngagementFormData = async (body) => {
  try {
    const response = await fetch(
      "/api/employeeEngagement/save-employee-engagement-form-data",
      {
        method: "POST",
        headers: getAuthorizationHeaderWithContentType(),
        body: JSON.stringify(body),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      // await response.json();
      toast.success("Employee Engagement form data saved");
      return response;
    } else if (response.status === 401) {
      clearLoginData();
    } else {
      toast.error("Failed to save Employee Engagement form data");
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteEmployeeEngagementFormData = async (formId, userId) => {
  try {
    const response = await fetch(
      `/api/employeeEngagement/delete-employee-engagement-form-data?formId=${formId}&userId=${userId}`,
      {
        method: "DELETE",
        headers: getAuthorizationHeaderWithContentType(),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      toast.success("Employee Engagement form data deleted successfully");
      return response;
    } else if (response.status === 401) {
      clearLoginData();
    } else {
      toast.error("Failed to delete Employee Engagement form data");
    }
  } catch (error) {
    console.error(error);
    toast.error(
      "An error occurred while deleting Employee Engagement form data"
    );
    throw error;
  }
};

export const deleteEmployeeEngagementEstimationFormData = async (
  formId,
  officeId
) => {
  try {
    const response = await fetch(
      `/api/employeeEngagement/delete-employee-engagement-estimation-form-data?formId=${formId}&officeId=${officeId}`,
      {
        method: "DELETE",
        headers: getAuthorizationHeaderWithContentType(),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      toast.success(
        "Employee Engagement estimation form data deleted successfully"
      );
      return response;
    } else if (response.status === 401) {
      clearLoginData();
    } else {
      toast.error("Failed to delete Employee Engagement estimation form data");
    }
  } catch (error) {
    console.error(error);
    toast.error(
      "An error occurred while deleting Employee Engagement estimation form data"
    );
    throw error;
  }
};

export const getEmployeeEngagementFormData = async (
  employeeEngagementFormId,
  uid
) => {
  try {
    const queryParams = new URLSearchParams({
      employeeEngagementFormId,
      uid,
    }).toString();

    const response = await fetch(
      `/api/employeeEngagement/get-employee-engagement-form-data?${queryParams}`,
      {
        method: "GET",
        headers: getAuthorizationHeaderWithContentType(),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getEmployeeEngagementEstimationFormData = async (
  employeeEngagementFormId,
  officeId
) => {
  try {
    const queryParams = new URLSearchParams({
      employeeEngagementFormId,
      officeId,
    }).toString();

    const response = await fetch(
      `/api/employeeEngagement/get-employee-engagement-estimation-form-data?${queryParams}`,
      {
        method: "GET",
        headers: getAuthorizationHeaderWithContentType(),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getEmployeeEngagementAllUsersFormData = async (formId) => {
  try {
    // const response = await fetch("/api/wfh/get-wfh-form-data/" + wfhFormId, {

    const response = await fetch(
      "/api/employeeEngagement/get-employee-engagement-all-users-form-data/" +
        formId,
      {
        method: "GET",
        headers: getAuthorizationHeaderWithContentType(),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getEmployeeEngagementAllEstimationFormData = async (formId) => {
  try {
    // const response = await fetch("/api/wfh/get-wfh-form-data/" + wfhFormId, {

    const response = await fetch(
      "/api/employeeEngagement/get-employee-engagement-all-estimation-form-data/" +
        formId,
      {
        method: "GET",
        headers: getAuthorizationHeaderWithContentType(),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAllEmployees = async () => {
  try {
    const response = await fetch(`/api/users/get-all-employees`, {
      method: "GET",
      headers: getAuthorizationHeader(),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getEmployeeEngagementInviteUrl = async (
  employeeEngagementFormId
) => {
  try {
    // const encodedSupplierName = encodeURIComponent(supplierName);
    const response = await fetch(
      "/api/employeeEngagement/get-employee-engagement-invite-url/" +
        employeeEngagementFormId,
      {
        method: "GET",
        headers: getAuthorizationHeaderWithContentType(),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
    throw error;
  }
};
