import {
  getAuthorizationHeader,
  getAuthorizationHeaderWithContentType,
} from "../../helpers/utils";
import { clearLoginData } from "../../helpers/store";

export const getAllSolutions = async () => {
  try {
    const response = await fetch("/api/solutions/all", {
      method: "GET",
      headers: getAuthorizationHeader(),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }
    return [];
  } catch (error) {
    console.error(error);
  }
};

export const addSolution = async (body) => {
  try {
    const response = await fetch("/api/solutions/add-solution", {
      method: "POST",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const getSelectedSolutions = async () => {
  try {
    const response = await fetch(`/api/solutions/selected-solutions`, {
      method: "GET",
      headers: getAuthorizationHeader(),
      mode: "cors",
    });

    if (response.status === 200) {
      const data = await response.json();
      //return jsonData;
      const datacopy = data;
      let arrayList = [];

      for (var i in data) {
        //console.log("First loop", data[i]);
        var obj = {
          solutionId: data[i].id,
          officeId: data[i].office_id,
          ratings: data[i].ratings,
          solution: data[i].solution,
          category: data[i].category,
          title: data[i].title,
          partner: data[i].partnername,
          tasks: [],
        };

        for (var j in datacopy) {
          //console.log("Second loop", data[j]);
          if (
            data[i].id === datacopy[j].id &&
            data[i].office_id === datacopy[j].office_id
          ) {
            if (Object.keys(datacopy[j].task).length !== 0) {
              datacopy[j].task["status"] = data[j]["progress_status"];
              datacopy[j].task["dateAdded"] = data[j]["created_at"];
              obj.tasks.push(datacopy[j].task);
            }
          }
        }
        //console.log("Before status Obj", obj);
        obj["status"] =
          obj.tasks.length !== 0
            ? obj.tasks.some((item) => {
                return item.status === "InProgress";
              })
              ? "InProgress"
              : "Completed"
            : data[i].progress_status;

        obj["dateAdded"] =
          obj.tasks.length !== 0
            ? obj.tasks[0]["dateAdded"]
            : data[i].date_added;

        //console.log("Obj", obj);
        //console.log(arrayList);
        if (arrayList.length !== 0) {
          //console.log("NOT FIRST TIME");
          const check = arrayList.some((item) => {
            return (
              item.solutionId === obj.solutionId &&
              item.officeId === obj.officeId
            );
          });

          if (!check) arrayList.push(obj);
        } else {
          //console.log("FIRST TIME");
          //console.log("Obj", obj);
          arrayList.push(obj);
        }
      }
      //console.log(arrayList);
      return arrayList;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const updateCompletedSolutions = async (solutionId, officeId, body) => {
  try {
    const response = await fetch(
      `/api/solutions/update-solutions?solutionId=${solutionId}&officeId=${officeId}`,
      {
        method: "PUT",
        headers: getAuthorizationHeaderWithContentType(),
        body: JSON.stringify(body),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const updateCompletedSolutionTasks = async (
  solutionId,
  officeId,
  body
) => {
  try {
    const response = await fetch(
      `/api/solutions/update-solution-tasks?solutionId=${solutionId}&officeId=${officeId}`,
      {
        method: "PUT",
        headers: getAuthorizationHeaderWithContentType(),
        body: JSON.stringify(body),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const getBusinessDetails = async () => {
  try {
    const response = await fetch("/api/users/get-business", {
      method: "GET",
      headers: getAuthorizationHeader(),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return;
  } catch (error) {
    console.error(error);
  }
};

export const addQuestion = async (body) => {
  try {
    const response = await fetch("/api/solutions/add-question", {
      method: "POST",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return;
  } catch (error) {
    console.error(error);
  }
};

export const getQuestionByBusinessId = async () => {
  try {
    const response = await fetch("/api/solutions/get-question", {
      method: "GET",
      headers: getAuthorizationHeader(),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      if (jsonData.length !== 0) {
        return jsonData[0];
      }
      return null;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return null;
  } catch (error) {
    console.error(error);
  }
};

export const updateAOIGeneratedStatus = async (status) => {
  try {
    const response = await fetch(
      "/api/solutions/update-aoi-generated-status/" + status,
      {
        method: "PUT",
        headers: getAuthorizationHeader(),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      return;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return;
  } catch (error) {
    console.error(error);
  }
};

export const getAllAreasOfImprovementByQuestion = async (questionId) => {
  try {
    const response = await fetch(
      "/api/solutions/get-all-areas-of-improvement-by-question/" + questionId,
      {
        method: "GET",
        headers: getAuthorizationHeader(),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }
    return [];
  } catch (error) {
    console.error(error);
  }
};

export const exploreSolutions = async (body) => {
  try {
    const response = await fetch("/api/solutions/explore-solutions", {
      method: "POST",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return;
  } catch (error) {
    console.error(error);
  }
};

export const getAllRecommendedSolutions = async (body) => {
  try {
    const response = await fetch(
      "/api/solutions/get-all-recommended-solutions",
      {
        method: "POST",
        headers: getAuthorizationHeaderWithContentType(),
        body: JSON.stringify(body),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return;
  } catch (error) {
    console.error(error);
  }
};

export const getAllRecommendedSolutionsByQuestionId = async (questionId) => {
  try {
    const response = await fetch(
      "/api/solutions/get-all-recommended-solutions-by-question-id/" +
        questionId,
      {
        method: "GET",
        headers: getAuthorizationHeader(),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }
    return [];
  } catch (error) {
    console.error(error);
  }
};
