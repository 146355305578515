import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Form, Alert } from "react-bootstrap";
import { useAuth } from "../../Contexts/AuthContext";

const ForgetPasswordForm = () => {
  // State
  const [email, setEmail] = useState("");

  // Handler
  const { resetPassword } = useAuth();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setMessage("");
      setError("");
      setLoading(true);
      await resetPassword(email);
      setMessage("Check your inbox for further instructions");
    } catch {
      setError("Failed to reset password");
    }

    setLoading(false);
  }

  return (
    <Form className="mt-4" onSubmit={handleSubmit}>
      {error && <Alert variant="danger">{error}</Alert>}
      {message && <Alert variant="success">{message}</Alert>}
      <Form.Group className="mb-3">
        <Form.Control
          placeholder={"Email address"}
          value={email}
          name="email"
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Button className="w-100" type="submit" disabled={!email}>
          Send reset link
        </Button>
      </Form.Group>

      {/* <Link className="fs--1 text-600" to="#!">
        I can't recover my account using this page
        <span className="d-inline-block ms-1"> &rarr;</span>
      </Link> */}
    </Form>
  );
};

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string,
};

ForgetPasswordForm.defaultProps = { layout: "simple" };

export default ForgetPasswordForm;
