export const emissionsBoundariesReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SET_EMISSIONS_BOUNDARIES":
      return {
        ...state,
        emissionsBoundaries: payload,
      };

    case "OPEN_EMISSIONS_BOUNDARIES_MODAL":
      return {
        ...state,
        emissionsBoundaryModal: {
          ...state.emissionsBoundaryModal,
          modalContent: {
            ...state.emissionsBoundaryModal.modalContent,
            emissionsBoundaryId: payload.emissionsBoundaryId,
            id: payload.id,
            title: payload.title,
            name: payload.name,
            dataSources: payload.dataSources,
            description: payload.description,
            locationList: payload.locationList,
            selectedLocations: payload.selectedLocations,
            amountCO2e: payload.amountCO2e,
            amountAUD: payload.amountAUD,
            reasoning: payload.reasoning,
            showWarning: payload.showWarning,
          },
          show: true,
        },
      };

    case "UPDATE_SINGLE_COLUMN":
      return {
        ...state,
        emissionsBoundaries: state.emissionsBoundaries.map(
          (emissionsBoundary) =>
            emissionsBoundary.id === payload.column.id
              ? {
                  ...emissionsBoundary,
                  items: [...payload.reorderedItems],
                }
              : emissionsBoundary
        ),
      };

    case "UPDATE_DUAL_COLUMN":
      return {
        ...state,
        emissionsBoundaries: state.emissionsBoundaries.map(
          (emissionsBoundary) =>
            emissionsBoundary.id === payload.sourceColumn.id ||
            emissionsBoundary.id === payload.destColumn.id
              ? {
                  ...emissionsBoundary,
                  items:
                    (emissionsBoundary.id === payload.sourceColumn.id &&
                      payload.updatedSourceItems) ||
                    (emissionsBoundary.id === payload.destColumn.id &&
                      payload.updatedDestItems),
                }
              : emissionsBoundary
        ),
      };

    case "ADD_TASK_CARD":
      return {
        ...state,
        emissionsBoundaries: state.emissionsBoundaries.map(
          (emissionsBoundary) =>
            emissionsBoundary.id === payload.targetListId
              ? {
                  ...emissionsBoundary,
                  items: [...emissionsBoundary.items, payload.newCard],
                }
              : emissionsBoundary
        ),
      };

    case "TOGGLE_EMISSIONS_BOUNDARIES_MODAL":
      return {
        ...state,
        emissionsBoundaryModal: {
          ...state.emissionsBoundaryModal,
          show: !state.emissionsBoundaryModal.show,
        },
      };

    case "UPDATE_EMISSIONS_BOUNDARIES_MODAL_OFFICES":
      return {
        ...state,
        emissionsBoundaries: state.emissionsBoundaries.map(
          (emissionsBoundary) =>
            emissionsBoundary.id === payload.emissionsBoundaryId
              ? {
                  ...emissionsBoundary,
                  items: emissionsBoundary.items.map((item) =>
                    item.id === payload.id
                      ? { ...item, offices: payload.offices }
                      : item
                  ),
                }
              : emissionsBoundary
        ),
        emissionsBoundaryModal: {
          ...state.emissionsBoundaryModal,
          modalContent: {
            ...state.emissionsBoundaryModal.modalContent,
            selectedLocations: payload.offices,
          },
        },
      };

    case "UPDATE_EMISSIONS_BOUNDARIES_MODAL_MANUAL_INPUT_AUD":
      return {
        ...state,
        emissionsBoundaries: state.emissionsBoundaries.map(
          (emissionsBoundary) =>
            emissionsBoundary.id === payload.emissionsBoundaryId
              ? {
                  ...emissionsBoundary,
                  items: emissionsBoundary.items.map((item) =>
                    item.id === payload.id
                      ? { ...item, spendMoneyData: payload.amountAUD }
                      : item
                  ),
                }
              : emissionsBoundary
        ),
        emissionsBoundaryModal: {
          ...state.emissionsBoundaryModal,
          modalContent: {
            ...state.emissionsBoundaryModal.modalContent,
            amountAUD: payload.amountAUD,
          },
        },
      };

    case "UPDATE_EMISSIONS_BOUNDARIES_MODAL_MANUAL_INPUT_CO2e":
      return {
        ...state,
        emissionsBoundaries: state.emissionsBoundaries.map(
          (emissionsBoundary) =>
            emissionsBoundary.id === payload.emissionsBoundaryId
              ? {
                  ...emissionsBoundary,
                  items: emissionsBoundary.items.map((item) =>
                    item.id === payload.id
                      ? { ...item, emissions: payload.amountCO2e }
                      : item
                  ),
                }
              : emissionsBoundary
        ),
        emissionsBoundaryModal: {
          ...state.emissionsBoundaryModal,
          modalContent: {
            ...state.emissionsBoundaryModal.modalContent,
            amountCO2e: payload.amountCO2e,
          },
        },
      };

    case "UPDATE_EMISSIONS_BOUNDARIES_MODAL_REASONING":
      return {
        ...state,
        emissionsBoundaries: state.emissionsBoundaries.map(
          (emissionsBoundary) =>
            emissionsBoundary.id === payload.emissionsBoundaryId
              ? {
                  ...emissionsBoundary,
                  items: emissionsBoundary.items.map((item) =>
                    item.id === payload.id
                      ? { ...item, reasoning: payload.reasoning }
                      : item
                  ),
                }
              : emissionsBoundary
        ),
        emissionsBoundaryModal: {
          ...state.emissionsBoundaryModal,
          modalContent: {
            ...state.emissionsBoundaryModal.modalContent,
            reasoning: payload.reasoning,
            showWarning: payload.showWarning,
          },
        },
      };

    default:
      return state;
  }
};
