import { useState } from "react";
import {
  Button,
  Form,
  FormCheck,
  Modal,
  Row,
  Spinner,
  Col,
} from "react-bootstrap";

import {
  createUnifiedFrozenDataCollection,
  updateDashboardOrder,
  updateFrozenDate,
  updateFrozenStatus,
  updateShowPreviousYearStatus,
} from "./adminApi";
import { getActiveUserData } from "../../helpers/store";
import { getFormattedDate } from "../../helpers/utils";

export const EditFrozenDataModal = ({ show, onHide, data, onDataUpdated }) => {
  const noData = data?.inventoryProgress.length < 1;
  const activeUser = JSON.parse(getActiveUserData());
  const [period, setPeriod] = useState(data?.inventoryProgress[0]?.id);
  const [frozenStatus, setFrozenStatus] = useState(
    data?.inventoryProgress[0]?.is_frozen
  );
  const [isLoading, setIsLoading] = useState(false);
  const [createSnapshot, setCreateSnapshot] = useState(false);
  const [currentSnapshot, setCurrentSnapshot] = useState(
    data?.inventoryProgress[0]?.frozen_date
  );

  const handleStatusChange = (e) => {
    setFrozenStatus(e.target.checked);
  };

  const handlePeriodChange = (e) => {
    const newPeriodId = e.target.value;
    const selectedInventoryProgress = data?.inventoryProgress?.find(
      (entry) => entry.id.toString() === newPeriodId
    );
    setPeriod(newPeriodId);
    setFrozenStatus(
      selectedInventoryProgress ? selectedInventoryProgress.is_frozen : false
    );
    // console.log("SELECTED", selectedInventoryProgress);
    setCurrentSnapshot(selectedInventoryProgress?.frozen_date || null);
  };

  const handleCreateSnapshotChange = (e) => {
    setCreateSnapshot(e.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const selectedInventoryProgress = data?.inventoryProgress?.find(
      (entry) => +entry.id === +period
    );

    const updateInventoryProgress = {
      id: +period,
      isFrozen: frozenStatus,
      userId: activeUser.uid,
    };
    await updateFrozenStatus(updateInventoryProgress);

    if (createSnapshot) {
      const dataForUpdate = {
        businessId: data.id,
        year: +selectedInventoryProgress?.measurement_year,
        reportingType: selectedInventoryProgress?.measurement_year_type,
      };
      await createUnifiedFrozenDataCollection(dataForUpdate);
      // Send Timestamp update
      await updateFrozenDate(updateInventoryProgress);
    }
    onDataUpdated();
    setIsLoading(false);
    onHide();
  };
  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Edit Freeze Data for <i>{data.name}</i>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {noData ? (
              <div>No Data for this Business</div>
            ) : (
              <>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="statusSelect">
                    <Form.Label>Select a Period</Form.Label>
                    <Form.Select
                      aria-label="Default select"
                      value={period}
                      onChange={handlePeriodChange}
                    >
                      {data?.inventoryProgress?.map((entry) => (
                        <option value={entry.id} key={entry.id}>
                          {entry.measurement_year} -{" "}
                          {capitalizeFirstLetter(entry.measurement_year_type)}{" "}
                          Year
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="frozenStatusSwitch">
                    <Form.Label>Show Frozen Data:</Form.Label>
                    <div
                      style={{ display: "flex", flexDirection: "row", gap: 10 }}
                    >
                      <FormCheck type="switch" id="frozenStatus">
                        <FormCheck.Input
                          type="checkbox"
                          checked={frozenStatus}
                          onChange={handleStatusChange}
                        />
                      </FormCheck>
                      <div>{frozenStatus ? "Frozen Data" : "Live version"}</div>
                    </div>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="createSnapshotCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Select to Create a new Snapshot"
                      checked={createSnapshot}
                      onChange={handleCreateSnapshotChange}
                    />
                  </Form.Group>
                  <div
                    style={{ display: "flex", flexDirection: "row", gap: 10 }}
                  >
                    <div>Last Snapshot:</div>
                    <div>
                      {currentSnapshot
                        ? getFormattedDate(currentSnapshot)
                        : "No snapshot available"}
                    </div>
                  </div>
                </Row>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            {isLoading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{" "}
                Saving... This might take a few minutes as we are processing all
                the data.
              </>
            ) : (
              <Button type="submit" disabled={noData}>
                Save
              </Button>
            )}
          </Modal.Footer>
        </Form>
      }
    </Modal>
  );
};

export const EditDashboardModal = ({ show, onHide, data, onDataUpdated }) => {
  console.log(data);
  const noData = data?.inventoryProgress.length < 1;
  const activeUser = JSON.parse(getActiveUserData());
  const [period, setPeriod] = useState(data?.inventoryProgress[0]?.id);
  const [showStatus, setShowStatus] = useState(
    data?.inventoryProgress[0]?.show_previous_year
  );
  const [dashboardOrder, setDashboardOrder] = useState(
    data?.businesspreference || "default"
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleStatusChange = (e) => {
    setShowStatus(e.target.checked);
  };
  console.log(data);
  const handlePeriodChange = (e) => {
    const newPeriodId = e.target.value;
    const selectedInventoryProgress = data?.inventoryProgress?.find(
      (entry) => entry.id.toString() === newPeriodId
    );
    setPeriod(newPeriodId);
    setShowStatus(
      selectedInventoryProgress
        ? selectedInventoryProgress.show_previous_year
        : false
    );
  };

  const handleDashboardOrderChange = (e) => {
    setDashboardOrder(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const updateInventoryProgress = {
      id: +period,
      showYearStatus: showStatus,
      userId: activeUser.uid,
    };

    await updateShowPreviousYearStatus(updateInventoryProgress);

    const updateOrder = {
      businessId: data.id,
      businessPreference: dashboardOrder,
    };

    // Call the updateDashboardOrder API
    await updateDashboardOrder(updateOrder);

    onDataUpdated();
    setIsLoading(false);
    onHide();
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Dashboard Data for <i>{data.name}</i>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {noData ? (
            <div>No Data for this Business</div>
          ) : (
            <>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="dashboardOrderSelect">
                  <Form.Label>
                    The selected dashboard option will be applicable for all
                    periods the business has data for:
                  </Form.Label>
                  <Form.Select
                    aria-label="Dashboard Order Select"
                    value={dashboardOrder}
                    onChange={handleDashboardOrderChange}
                  >
                    <option value="default">Default</option>
                    <option value="waste">Waste</option>
                  </Form.Select>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="statusSelect">
                  <Form.Label>Select a Period</Form.Label>
                  <Form.Select
                    aria-label="Default select"
                    value={period}
                    onChange={handlePeriodChange}
                  >
                    {data?.inventoryProgress?.map((entry) => (
                      <option value={entry.id} key={entry.id}>
                        {entry.measurement_year} -{" "}
                        {capitalizeFirstLetter(entry.measurement_year_type)}{" "}
                        Year
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="showStatusSwitch">
                  <Form.Label>Show Previous Year Data:</Form.Label>
                  <div
                    style={{ display: "flex", flexDirection: "row", gap: 10 }}
                  >
                    <FormCheck type="switch" id="showStatus">
                      <FormCheck.Input
                        type="checkbox"
                        checked={showStatus}
                        onChange={handleStatusChange}
                      />
                    </FormCheck>
                    <div>
                      {showStatus
                        ? "Show % vs previous Year"
                        : "No Previous Year %"}
                    </div>
                  </div>
                </Form.Group>
              </Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {isLoading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Saving...
            </>
          ) : (
            <Button type="submit" disabled={noData}>
              Save
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
