import {
  getAuthorizationHeader,
  getAuthorizationHeaderWithContentType,
} from "../../helpers/utils";
import { clearLoginData } from "../../helpers/store";

export const getAllPartners = async () => {
  try {
    const response = await fetch("/api/partners/all", {
      method: "GET",
      headers: getAuthorizationHeader(),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      console.log(jsonData);
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }
    return [];
  } catch (error) {
    console.error(error);
  }
};

export const addPartners = async (body) => {
  console.log(body);
  try {
    const response = await fetch("/api/partners/add-partners", {
      method: "POST",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const getSelectedPartners = async () => {
  try {
    const response = await fetch(`/api/partners/selected-partners`, {
      method: "GET",
      headers: getAuthorizationHeader(),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const updateCompletedPartners = async (partnerId, officeId, body) => {
  try {
    const response = await fetch(
      `/api/partners/update-partners?partnerId=${partnerId}&officeId=${officeId}`,
      {
        method: "PUT",
        headers: getAuthorizationHeaderWithContentType(),
        body: JSON.stringify(body),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};
