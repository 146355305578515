import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  getActiveUserData,
  previousPeriodInventoryProgress,
} from "../../helpers/store";
import bg2 from "../../Assets/img/icons/spot-illustrations/corner-2.png";

import EmissionYearStatCard from "../common/EmissionYearStatCard";
import {
  faCalculator,
  faUser,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";

const calculateDeltaPercentage = (current, previous) => {
  // console.log("current", current);
  // console.log("previous", previous);
  if (previous === 0 || previous === null) {
    return null; // Avoid division by zero and handle null data
  }
  const delta = current - previous;
  return (delta / previous) * 100;
};

const YearPeriodData = ({
  isParentLoading,
  yearEmissions,
  revenue,
  employeeNumber,
}) => {
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  let emissionPerEmployee;
  let emissionPerMillionAUD;
  let missingRevenue = false;
  let missingEmployees = false;
  let deltaEmissionsPreviousYear = null;
  let deltaEmissionsPerEmployeePreviousYear = null;
  let deltaEmissionsPerRevenuePreviousYear = null;
  //if previousYearData.totalEmissions === null we dont calculate deltas
  //if yearEmissions === 0 we dont calculate any deltas

   const previousYearData = previousPeriodInventoryProgress();

  if (!previousYearData) {
    if (revenue === null) {
      emissionPerMillionAUD = 0;
      missingRevenue = true;
    } else {
      emissionPerMillionAUD = (yearEmissions * 1000) / revenue;
    }

    if (employeeNumber === null) {
      emissionPerEmployee = 0;
      missingEmployees = true;
    } else {
      emissionPerEmployee = (yearEmissions * 1000) / employeeNumber;
    }
  } else {
    if (yearEmissions !== 0) {
      // If emissions are 0 we do not need to calculate
      if (previousYearData?.totalEmissions !== null) {
        deltaEmissionsPreviousYear = calculateDeltaPercentage(
          yearEmissions,
          previousYearData.totalEmissions
        );
      }

      // if (revenue === null || previousYearData.totalEmissions === null) {
      if (revenue === null) {
        emissionPerMillionAUD = 0;
        missingRevenue = true;
      } else {
        emissionPerMillionAUD = (yearEmissions * 1000) / revenue;
        if (previousYearData.revenue) {
          // console.log(previousYearData);
          let emissionsPerRevenuePreviousYear =
            (previousYearData.totalEmissions * 1000) / previousYearData.revenue;

          deltaEmissionsPerRevenuePreviousYear = calculateDeltaPercentage(
            emissionPerMillionAUD,
            emissionsPerRevenuePreviousYear
          );

          // console.log("Revenue Delta", deltaEmissionsPerRevenuePreviousYear);
        }
      }

      // if (employeeNumber === null || previousYearData.totalEmissions === null) {
      if (employeeNumber === null) {
        emissionPerEmployee = 0;
        missingEmployees = true;
      } else {
        emissionPerEmployee = (yearEmissions * 1000) / employeeNumber;
        if (previousYearData.employeeNumber) {
          let emissionsPerEmployeePreviousYear =
            (previousYearData.totalEmissions * 1000) /
            previousYearData.employeeNumber;

          deltaEmissionsPerEmployeePreviousYear = calculateDeltaPercentage(
            emissionPerEmployee,
            emissionsPerEmployeePreviousYear
          );

          // console.log("EmployeeDelta", deltaEmissionsPerEmployeePreviousYear);
        }
      }
    }
  }

  const cardData = [
    {
      id: 0,
      cardType: "any",
      title: `Emissions in ${activeUser.selectedMeasurementYear}`,
      amount: yearEmissions,
      totalEmissions: yearEmissions,
      missingAmount: false,
      units: "t CO₂e",
      decimals: 1,
      amountLastYear: deltaEmissionsPreviousYear,
      icon: faCalculator,
      image: bg2,
      color: "primary",
      className: "",
    },
    {
      id: 1,
      cardType: "revenue",
      title: "Emissions per Revenue",
      amount: emissionPerMillionAUD,
      totalEmissions: yearEmissions,
      missingAmount: missingRevenue,
      amountLastYear: deltaEmissionsPerRevenuePreviousYear,
      units: "kg CO₂e per $",
      decimals: 3,
      icon: faDollarSign,
      image: bg2,
      color: "primary",
      className: "",
    },
    {
      id: 2,
      cardType: "employee",
      title: "Emissions per Employee",
      amount: emissionPerEmployee,
      totalEmissions: yearEmissions,
      missingAmount: missingEmployees,
      decimals: 0,
      amountLastYear: deltaEmissionsPerEmployeePreviousYear,
      units: "kg CO₂e per Employee",
      icon: faUser,
      image: bg2,
      color: "primary",
      className: "",
    },
  ];

  return (
    <>
      <Col md="auto" className="mb-3 mb-md-0">
        <Row className="g-3 gy-md-0 h-100 align-items-center">
          <Col xs="auto">
            <h4 className="text-700 mb-3 text-nowrap">
              {`Data For ${activeUser.selectedMeasurementYear} ${
                activeUser.selectedMeasurementYearType.charAt(0).toUpperCase() +
                activeUser.selectedMeasurementYearType.slice(1)
              } Year`}
            </h4>
          </Col>
        </Row>
      </Col>
      <Row className="gx-3">
        <Col lg={12}>
          <Row className="g-4 mb-3">
            {cardData.map((data) => (
              <EmissionYearStatCard key={data.id} stat={data} />
            ))}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default YearPeriodData;
