import React from "react";
import styles from "./OnboardingCard.module.css";

const BannerImageCard = ({ optionText, onClick, imageUrl }) => (
  <div className={styles.card} onClick={onClick}>
    <div className={styles.card__overlay}></div>
    {imageUrl ? (
      <img
        src={imageUrl}
        alt={optionText || "Image"}
        className={styles.card__image}
        style={{ width: "100%", height: "100%", objectFit: "cover" }} // Ensure the image covers the container properly
      />
    ) : (
      <p>Loading image...</p>
    )}
  </div>
);

export default BannerImageCard;
