import React, { useContext, useState } from "react";
import { TransparencyContext } from "../../context/Context";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, Button, Spinner } from "react-bootstrap";
import LoadingTransparencyHub from "./LoadingTransparencyHub";
import { getSubCollectionRef } from "../../helpers/store";
import { setResponsesToSurvey } from "./transparencyHubAPI";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Step3TransparencyHub() {
  const {
    transparencyQuestions,
    supplierData,
    assessmentData,
    nextStep,
    prefillRequest,
    modifyPrefillRequest,
    isLoading,
  } = useContext(TransparencyContext);
  const [loading, setLoading] = useState(false);

  const questionsBySection = (transparencyQuestions || []).reduce(
    (acc, curr) => {
      if (!acc[curr.section]) {
        acc[curr.section] = [];
      }
      acc[curr.section].push(curr);
      return acc;
    },
    {}
  );

  const cardStyle = {
    marginBottom: "10px",
    cursor: "pointer",
    transition: "transform 0.2s",
  };

  const cardHoverStyle = {
    transform: "scale(1.02)",
  };

  const linkStyle = {
    textDecoration: "none",
    color: "black",
    fontSize: "18px",
    fontWeight: "bold",
    display: "block",
  };

  const subCollectionRef = getSubCollectionRef();
  const assessmentSelected = assessmentData?.find(
    (assessment) => assessment.id === subCollectionRef
  );
  let responsesTransparency = {
    responses: [],
    progress: [],
    totalScore: null,
  };
  if (assessmentSelected?.responses) {
    responsesTransparency = assessmentSelected?.responses;
    // console.log("Responses", responsesTransparency);
  }

  const handleSubmit = async () => {
    // Here you can add any additional actions needed on submit
    //calculate the totalScoreMaxScore
    // console.log("Submit", transparencyQuestions);
    setLoading(true);
    const maxTotalScore = transparencyQuestions?.reduce((acc, curr) => {
      acc += curr.maxScore;
      return acc;
    }, 0);
    const updatedResponses = responsesTransparency;
    updatedResponses.maxTotalScore = maxTotalScore;
    // console.log(updatedResponses);
    await setResponsesToSurvey(
      supplierData?.supplierId,
      subCollectionRef,
      responsesTransparency
    );
    setLoading(false);
    // Go to next section
    nextStep();
  };

  const handleSubmitAutocomplete = async () => {
    setLoading(true);
    const updatedResponses = responsesTransparency;
    updatedResponses.prefillRequest = true;

    modifyPrefillRequest();
    await setResponsesToSurvey(
      supplierData?.supplierId,
      subCollectionRef,
      responsesTransparency
    );
    setLoading(false);
  };

  return (
    // <>{!isLoading ? <div>NOT LOADING</div> : <LoadingTransparencyHub />}</>

    <Container>
      {!isLoading ? (
        <>
          {Object?.keys(questionsBySection)?.map((section, index) => {
            const data = responsesTransparency.progress.find(
              (sectionData) => sectionData.sectionName === section
            );
            const totalQuestions = questionsBySection[section]?.length || 0;
            const answeredQuestions = data?.respondedQuestions || 0;

            const squares = Array.from({ length: totalQuestions }, (_, i) => (
              <div
                key={i}
                style={{
                  width: "13px",
                  height: "16px",
                  backgroundColor:
                    i < answeredQuestions ? "#00d27a" : "#e0e0e0",
                  margin: "2px",
                  display: "inline-block",
                  borderRadius: 3,
                }}
              ></div>
            ));

            return (
              <Card
                key={section}
                style={cardStyle}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = cardHoverStyle.transform;
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "none";
                }}
              >
                <Card.Body>
                  <Row className="align-items-center">
                    <Col xs={8}>
                      <Link
                        to={`form/`}
                        state={{
                          questions: questionsBySection[section],
                          responses: responsesTransparency,
                          supplierId: supplierData?.supplierId,
                          sectionNumber: index + 1,
                          assessmentData: assessmentData,
                        }}
                        style={linkStyle}
                      >
                        {index + 1} - {section}
                      </Link>
                    </Col>
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        gap: 5,
                      }}
                    >
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {squares}
                      </div>
                      <span style={{ fontSize: 13 }}>
                        {answeredQuestions} of {totalQuestions} questions
                        completed
                      </span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            );
          })}
          <div style={{ display: "flex", justifyContent: "end", gap: 20 }}>
            {" "}
            <div className="d-flex justify-content-end mt-3">
              {!prefillRequest ? (
                <Button
                  variant="outline-primary"
                  onClick={handleSubmitAutocomplete}
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Autocomplete form with the documenst submited"
                  )}
                </Button>
              ) : (
                <Button variant="outline-success" className="w-100">
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ marginRight: "5px" }}
                  />
                  Request to Autocomplete Form Submitted
                </Button>
              )}
            </div>
            <div className="d-flex justify-content-end mt-3">
              <Button
                variant="primary"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Submit Form"
                )}
              </Button>
            </div>
          </div>
        </>
      ) : (
        <LoadingTransparencyHub />
      )}
    </Container>
  );
}

export default Step3TransparencyHub;
