import React, { useEffect, useState } from "react";
import Setup from "./setup";
import AllForms from "./AllForms/index";
import Collapsible from "./Utility/Collapsible";
import { useLocation } from "react-router-dom";
import { getEventDetailsById } from "./eventsAPI";
// import Chart from "./Dashboard/chart";
import Dashboard from "./Dashboard/index";
import { Spinner } from "react-bootstrap";
import PageHeader from "../common/PageHeader";

const EventForm = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [eventDetails, setEventDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getEventDetails = async () => {
      try {
        setIsLoading(true);
        setIsOpen(false);
        const jsonData = await getEventDetailsById(location.state.id);
        if (!jsonData) return;

        console.log(jsonData);
        setEventDetails(jsonData);

        setSubmitted(true);
        setIsLoading(false);
      } catch (err) {
        console.error(err.message);
      }
    };
    if (!location.state) return;
    getEventDetails();
  }, [location]);

  return (
    <>
      <PageHeader
        title="Your event page"
        description={`<p>An emissions analysis for events is an important tool for organizers who want to reduce the environmental impact of their events and support their attendees. By providing a clear understanding of the carbon footprint of an event and offering practical solutions for reducing emissions, this tool can help you create more sustainable events that benefit both the environment and the community.</p> 

        <p>Start by filling the event details section and later confirm or edit the following sections:</p>
        <ul>
        <li>Water</li>
        
        <li>Food and drinks</li>
        
        <li>Flights</li>
        
        <li>Regional Ground Transport</li>
      
        <li>Local Transport</li>
        
        <li>Energy</li>
        </ul>
        `}
        className="mb-3"
        col={8}
      ></PageHeader>
      {isLoading ? (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <Collapsible open={isOpen} submitted={submitted}>
          <Setup eventDetails={eventDetails} />
        </Collapsible>
      )}

      {submitted && <AllForms eventDetails={eventDetails} />}
      {submitted && <Dashboard eventDetails={eventDetails} />}
    </>
  );
};

export default EventForm;
