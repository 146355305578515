import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SubmittedWasteForm from "./submittedWasteForm";
import DraftedWasteForm from "./draftedWasteForm";
import { Accordion, Button, Col, Row } from "react-bootstrap";
import {
  getWasteDataByEventId,
  getWasteDataByWasteId,
  saveDraftedWasteForm,
  submitDraftedWasteForm,
} from "./wasteAPI";
import WidgetSectionTitle from "../../../widgets/WidgetSectionTitle";
import { toast, ToastContainer } from "react-toastify";
import { faList } from "@fortawesome/free-solid-svg-icons";

const ACCORDIAN_ITEMS = {
  "Municipal Waste": "municipal",
  "Construction and demolition Waste": "construction and demolition",
  "Commercial and Industrial Waste": "commercial and industrial",
  "Recycling Waste": "recycling",
  "Paper Waste": "paper",
  "Sanitary and nappies Waste": "sanitary and nappies",
  "Green and Organic Waste": "green and organic",
};

const WasteDataViewer = () => {
  const nav = useNavigate();
  const location = useLocation();

  const [wasteData, setWasteData] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [navigate, setNavigate] = useState(false);
  const [wasteId, setWasteId] = useState(null);
  const [eventId, setEventId] = useState(null);

  async function handleSaveDraft(e) {
    e.preventDefault();
    await saveDraftedWasteForm(wasteData);
    setSubmitted(false);
    toast("Waste form drafted successfully!");
    if (navigate) {
      window.location.href = "/welcome/data/climate-active/all-forms";
    } else {
      nav("/welcome/dashboard/event-form", {
        state: { id: eventId },
      });
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    await submitDraftedWasteForm(wasteData);
    setSubmitted(true);
    toast("Waste form submitted successfully!");
    if (navigate) {
      window.location.href = "/welcome/data/climate-active/all-forms";
    } else {
      nav("/welcome/dashboard/event-form", {
        state: { id: eventId },
      });
    }
  }

  useEffect(() => {
    const getWasteData = async () => {
      try {
        if (!location.state) return;

        let jsonData = null;
        if (location.state.formId !== undefined) {
          setWasteId(location.state.wasteId);
          const response = await getWasteDataByWasteId(location.state.formId);
          jsonData = response.formData;
          setNavigate(true);
        }

        if (location.state.id !== undefined) {
          setEventId(location.state.id);
          jsonData = await getWasteDataByEventId(location.state.id);
          setNavigate(false);
        }
        if (!jsonData) return;

        setWasteData({
          id: jsonData.id,
          municipal: {
            numberOfWheelieBins: jsonData.municipal_no_of_wheelie_bins,
            daysBetweenCollection: jsonData.municipal_no_of_days_btw_collection,
            fullnessOfBins: jsonData.municipal_full_perc,
            numberOfCollections: jsonData.municipal_no_of_collections_per_year,
            numberOfBins: jsonData.municipal_no_of_full_bins,
            volumeOfCollections: jsonData.municipal_vol_of_total_collections,
            totalWeightOfWaste: jsonData.municipal_total_weight_in_tonnes,
          },
          constructionAndDemolition: {
            numberOfWheelieBins:
              jsonData.constructionanddemolition_no_of_wheelie_bins,
            daysBetweenCollection:
              location.state?.row
                ?.constructionanddemolition_no_of_days_btw_collection,
            fullnessOfBins: jsonData.constructionanddemolition_full_perc,
            numberOfCollections:
              location.state?.row
                ?.constructionanddemolition_no_of_collections_per_year,
            numberOfBins: jsonData.constructionanddemolition_no_of_full_bins,
            volumeOfCollections:
              jsonData.constructionanddemolition_vol_of_total_collections,
            totalWeightOfWaste:
              jsonData.constructionanddemolition_total_weight_in_tonnes,
          },
          commercialAndIndustrial: {
            numberOfWheelieBins:
              jsonData.commercialandindustrial_no_of_wheelie_bins,
            daysBetweenCollection:
              jsonData.commercialandindustrial_no_of_days_btw_collection,
            fullnessOfBins: jsonData.commercialandindustrial_full_perc,
            numberOfCollections:
              jsonData.commercialandindustrial_no_of_collections_per_year,
            numberOfBins: jsonData.commercialandindustrial_no_of_full_bins,
            volumeOfCollections:
              jsonData.commercialandindustrial_vol_of_total_collections,
            totalWeightOfWaste:
              jsonData.commercialandindustrial_total_weight_in_tonnes,
          },
          recycling: {
            numberOfWheelieBins: jsonData.recycling_no_of_wheelie_bins,
            daysBetweenCollection: jsonData.recycling_no_of_days_btw_collection,
            fullnessOfBins: jsonData.recycling_full_perc,
            numberOfCollections: jsonData.recycling_no_of_collections_per_year,
            numberOfBins: jsonData.recycling_no_of_full_bins,
            volumeOfCollections: jsonData.recycling_vol_of_total_collections,
            totalWeightOfWaste: jsonData.recycling_total_weight_in_tonnes,
          },
          paper: {
            numberOfWheelieBins: jsonData.paper_no_of_wheelie_bins,
            daysBetweenCollection: jsonData.paper_no_of_days_btw_collection,
            fullnessOfBins: jsonData.paper_full_perc,
            numberOfCollections: jsonData.paper_no_of_collections_per_year,
            numberOfBins: jsonData.paper_no_of_full_bins,
            volumeOfCollections: jsonData.paper_vol_of_total_collections,
            totalWeightOfWaste: jsonData.paper_total_weight_in_tonnes,
          },
          sanitaryAndNappies: {
            numberOfWheelieBins: jsonData.sanitaryandnappies_no_of_wheelie_bins,
            daysBetweenCollection:
              jsonData.sanitaryandnappies_no_of_days_btw_collection,
            fullnessOfBins: jsonData.sanitaryandnappies_full_perc,
            numberOfCollections:
              jsonData.sanitaryandnappies_no_of_collections_per_year,
            numberOfBins: jsonData.sanitaryandnappies_no_of_full_bins,
            volumeOfCollections:
              jsonData.sanitaryandnappies_vol_of_total_collections,
            totalWeightOfWaste:
              jsonData.sanitaryandnappies_total_weight_in_tonnes,
          },
          greenAndOrganic: {
            numberOfWheelieBins: jsonData.greenandorganic_no_of_wheelie_bins,
            daysBetweenCollection:
              jsonData.greenandorganic_no_of_days_btw_collection,
            fullnessOfBins: jsonData.greenandorganic_full_perc,
            numberOfCollections:
              jsonData.greenandorganic_no_of_collections_per_year,
            numberOfBins: jsonData.greenandorganic_no_of_full_bins,
            volumeOfCollections:
              jsonData.greenandorganic_vol_of_total_collections,
            totalWeightOfWasteWood: jsonData.greenandorganic_perc_of_wood,
            totalWeightOfWasteFood: jsonData.greenandorganic_perc_of_food,
            totalWeightOfWasteGarden: jsonData.greenandorganic_perc_of_garden,
          },
          startDate: jsonData.startdate,
          endDate: jsonData.enddate,
        });

        if (jsonData.completion_status === "Completed") {
          setSubmitted(true);
        }
      } catch (err) {
        console.error(err.message);
      }
    };
    getWasteData();
  }, [location]);

  return (
    <>
      {wasteData ? (
        submitted ? (
          <>
            {" "}
            <WidgetSectionTitle
              icon={faList}
              title="Submitted Form"
              subtitle="View your submitted form"
              transform="shrink-2"
              className="mb-4 mt-6"
            />
            <Accordion>
              {Object.keys(ACCORDIAN_ITEMS).map((item, index) => {
                return (
                  <>
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>{item}</Accordion.Header>
                      <Accordion.Body id={`collapse${index}`}>
                        <SubmittedWasteForm
                          data={wasteData}
                          type={ACCORDIAN_ITEMS[item]}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                );
              })}
            </Accordion>
          </>
        ) : (
          <>
            {" "}
            <WidgetSectionTitle
              icon={faList}
              title="Drafted Form"
              subtitle="View your drafted form"
              transform="shrink-2"
              className="mb-4 mt-6"
            />
            <Accordion>
              {Object.keys(ACCORDIAN_ITEMS).map((item, index) => {
                return (
                  <>
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>{item}</Accordion.Header>
                      <Accordion.Body id={`collapse${index}`}>
                        <DraftedWasteForm
                          wasteData={wasteData}
                          setWasteData={setWasteData}
                          type={ACCORDIAN_ITEMS[item]}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                );
              })}
            </Accordion>
            <br />
            <Row style={{ alignItems: "center", justifyContent: "center" }}>
              <Col sm={2} style={{ padding: "5" }}>
                <Button onClick={handleSaveDraft}>Save As Draft</Button>
              </Col>
              <Col sm={2} style={{ padding: "5" }}>
                <Button onClick={handleSubmit}>Submit</Button>
              </Col>
            </Row>
          </>
        )
      ) : null}
      <ToastContainer />
    </>
  );
};

export default WasteDataViewer;
