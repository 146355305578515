import React, { useEffect, useState } from "react";
import WidgetSectionTitle from "../../widgets/WidgetSectionTitle";
import Dashboard from "./Dashboard/index";
import { Button, Col, Row, Form, Card, Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { saveWfhFormData, getWfhFormData } from "./wfhAPI";
import { useNavigate } from "react-router-dom";
import { faList } from "@fortawesome/free-solid-svg-icons";

import {
  countryStateDataJSON,
  targetedCountryCodeList,
  CountryCodeNameObject,
  StateCodeNameObject,
} from "../../data/CountryStateData.js";

const WfhFormData = () => {
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [wfhFormId, setWfhFormId] = useState();
  const [wfhFormDataId, setWfhFormDataId] = useState();
  const [fteDaysPerYear, setFteDaysPerYear] = useState(240);
  const [fteHoursPerWeek, setFteHoursPerWeek] = useState(40);
  const [workingState, setWorkingState] = useState("ACT");
  const [isRenewableEnergy, setIsRenewableEnergy] = useState("No");
  const [usingComputer, setUsingComputer] = useState("Yes");
  const [usingMonitor, setUsingMonitor] = useState("No");
  const [typeOfWorkPlace, setTypeOfWorkPlace] = useState("Small");
  const [usingHeatingOrCooling, setUsingHeatingOrCooling] = useState("Yes");
  const [usingGasHeating, setUsingGasHeating] = useState("No");
  const [formName, setFormName] = useState("WFH Form");

  const [wfhFormDataStatus, setWfhFormDataStatus] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [countryData, setCountryData] = useState(
    targetedCountryCodeList(["Australia", "United States"])
  );
  const [country, setCountry] = useState("AU");
  const [stateData, setStateData] = useState([]);
  const [countryStateJSON, setCountryStateJSON] = useState(
    countryStateDataJSON()
  );

  const queryParams = new URLSearchParams(window.location.search);
  const formId = queryParams.get("wfhFormId");

  const [values, setValues] = useState({
    country: "",
    countryCode: "",
    state: "",
    stateCode: "",
  });

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    const response = await saveWfhFormData({
      id: wfhFormDataId,
      wfhFormId,
      fteDaysPerYear,
      fteHoursPerWeek,
      country: values.countryCode,
      workingState: values.stateCode,
      isRenewableEnergy,
      usingComputer,
      usingMonitor,
      typeOfWorkPlace,
      usingHeatingOrCooling,
      usingGasHeating,
    });
    setWfhFormDataId(response.id || null);
    //to initiate chart data calculation
    sessionStorage.setItem("dashboardDataCalCompleted", false);
    setIsLoading(false);
    toast(`WFH data saved successfully.`);
    navigate("/welcome/data/wfh");
  }

  const handleChange = (event) => {
    if (event.target.name === "country") {
      setStateData(countryStateJSON[event.target.value.split(",")[0]]);

      setValues((values) => ({
        ...values,
        countryCode: event.target.value.split(",").at(-1),
      }));
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.value.split(",")[0],
      }));
    } else if (event.target.name === "state") {
      setValues((values) => ({
        ...values,
        stateCode: event.target.value.split(",").at(-1),
      }));
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.value.split(",")[0],
      }));
    } else {
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.value,
      }));
    }
  };

  async function get(formId) {
    setIsLoadingData(true);
    setWfhFormId(formId || null);
    const formData = await getWfhFormData(formId);
    console.log("formData", formData);
    if (formData && formData.wfh_form_data_id) {
      setWfhFormDataId(formData.wfh_form_data_id);
      setFteDaysPerYear(formData.fte_days_year);
      setFteHoursPerWeek(formData.fte_hours_week);
      setCountry(formData.country);
      setWorkingState(formData.state);
      setIsRenewableEnergy(formData.is_renewable_energy);
      setUsingComputer(formData.using_computer);
      setUsingMonitor(formData.using_stand_alone_monitor);
      setTypeOfWorkPlace(formData.work_place_type);
      setUsingHeatingOrCooling(formData.using_heating_cooling);
      setUsingGasHeating(formData.using_gas_heating);
      setWfhFormDataStatus(formData.status);
      setIsSubmitted(formData.status === "Completed" ? true : false);
      setFormName(formData.name);

      // Populate country and state dropdowns based on country and state codes
      const countryName = CountryCodeNameObject()[formData.country];
      const stateName = StateCodeNameObject(formData.country, formData.state)[
        formData.state
      ];

      if (countryName && stateName) {
        setValues({
          country: countryName,
          countryCode: formData.country,
          state: stateName,
          stateCode: formData.state,
        });
        setStateData(countryStateDataJSON()[countryName]);
      }
    }
    setIsLoadingData(false);
  }

  useEffect(() => {
    get(formId);
  }, [formId]);

  return (
    <>
      <WidgetSectionTitle
        icon={faList}
        title="WFH Emissions Form"
        subtitle="Fill your WFH form data here"
        transform="shrink-2"
        className="mb-4 mt-6"
      />
      {isLoadingData && <Spinner animation="border" role="status" />}
      {!isLoadingData && (
        <Form onSubmit={handleSubmit}>
          <Card>
            <Card.Body>
              <Card.Title>Form: {formName}</Card.Title>
              <Card.Text></Card.Text>

              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Which country are you located in</Form.Label>
                    <Form.Select
                      name="country"
                      value={values.country + "," + values.countryCode}
                      onChange={handleChange}
                      required
                    >
                      <option>Select the country</option>
                      {countryData.map((data, index) => {
                        return (
                          <option
                            key={index}
                            value={data.name + "," + data.code}
                          >
                            {data.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>What state are you based in</Form.Label>
                    <Form.Select
                      name="state"
                      value={values.state + "," + values.stateCode}
                      onChange={handleChange}
                      required
                    >
                      <option>Select the state</option>
                      {stateData.map((data, index) => {
                        return (
                          <option
                            key={index}
                            value={data.name + "," + data.code}
                          >
                            {data.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      How many days a year do you work from home?
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="FTE days per year"
                      value={fteDaysPerYear}
                      onChange={(e) => setFteDaysPerYear(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      How many hours a week do you work from home?
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="FTE hours per week"
                      value={fteHoursPerWeek}
                      onChange={(e) => setFteHoursPerWeek(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Do you use renewable or carbon neutral energy at home?
                    </Form.Label>
                    <Form.Select
                      value={isRenewableEnergy}
                      onChange={(e) => setIsRenewableEnergy(e.target.value)}
                    >
                      <option value="">Please select...</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Do you work from a small (ie. sunroom) large (ie. office)
                      or a common area?
                    </Form.Label>
                    <Form.Select
                      value={typeOfWorkPlace}
                      onChange={(e) => setTypeOfWorkPlace(e.target.value)}
                    >
                      <option value="">Please select...</option>
                      <option value="Small">Small</option>
                      <option value="Large">Large</option>
                      <option value="Shared">Shared</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Do you use a computer or laptop to complete work-related
                      tasks at home?
                    </Form.Label>
                    <Form.Select
                      value={usingComputer}
                      onChange={(e) => setUsingComputer(e.target.value)}
                    >
                      <option value="">Please select...</option>
                      <option value="No">No</option>
                      <option value="Yes">Yes</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Do you use an extra monitor?</Form.Label>
                    <Form.Select
                      value={usingMonitor}
                      onChange={(e) => setUsingMonitor(e.target.value)}
                    >
                      <option value="">Please select...</option>
                      <option value="No">No</option>
                      <option value="Yes">Yes</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Are you using heating or cooling in the area where you
                      work?
                    </Form.Label>
                    <Form.Select
                      value={usingHeatingOrCooling}
                      onChange={(e) => setUsingHeatingOrCooling(e.target.value)}
                    >
                      <option value="">Please select...</option>
                      <option value="No">No</option>
                      <option value="Yes">Yes</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                {usingHeatingOrCooling === "Yes" && (
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Do you use gas heating for this space?
                      </Form.Label>
                      <Form.Select
                        value={usingGasHeating}
                        onChange={(e) => setUsingGasHeating(e.target.value)}
                      >
                        <option value="">Please select...</option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                )}
              </Row>
              <Row>
                <Col>
                  <Button
                    type="submit"
                    disabled={
                      !values.country ||
                      !values.state ||
                      !wfhFormId ||
                      !fteDaysPerYear ||
                      !fteHoursPerWeek ||
                      !workingState ||
                      !isRenewableEnergy ||
                      !usingComputer ||
                      !usingMonitor ||
                      !typeOfWorkPlace ||
                      !usingHeatingOrCooling ||
                      (usingHeatingOrCooling === "Yes" && !usingGasHeating) ||
                      wfhFormDataStatus === "Completed"
                    }
                  >
                    {isLoading && (
                      <>
                        <Spinner animation="border" role="status" size="sm">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>{" "}
                      </>
                    )}
                    Save
                  </Button>{" "}
                  &nbsp;
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Form>
      )}
      <ToastContainer />
      {isSubmitted && <Dashboard wfhFormDataId={wfhFormDataId} />}
    </>
  );
};

export default WfhFormData;
