import { clearLoginData } from "../../helpers/store";
import {
  getAuthorizationHeader,
  getAuthorizationHeaderWithContentType,
} from "../../helpers/utils";

export const submitEventForm = async (body) => {
  try {
    const response = await fetch("/api/events/submit-event-form", {
      method: "POST",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      console.log(jsonData);
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const getAllEvents = async () => {
  try {
    const response = await fetch("/api/events/get-all-events", {
      method: "GET",
      headers: getAuthorizationHeader(),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      console.log(jsonData);
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }
    return [];
  } catch (error) {
    console.error(error);
  }
};

export const getEventDetailsById = async (id) => {
  try {
    const response = await fetch("/api/events/get-event-details-by-id/" + id, {
      method: "GET",
      headers: getAuthorizationHeaderWithContentType(),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      console.log(jsonData);
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};
