import { clearLoginData } from "../../../helpers/store";
import { getAuthorizationHeaderWithContentType } from "../../../helpers/utils";

export const saveStaffData = async (request) => {
  try {
    const response = await fetch("/api/climateActive/save-staff-data", {
      method: "POST",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(request),
      mode: "cors",
    });

    if (response.status === 200) {
      return;
    } else if (response.status === 401) {
      clearLoginData();
    } else {
      throw new Error("Operation failed. " + response.statusText);
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAllStaffData = async () => {
  try {
    const response = await fetch("/api/climateActive/get-all-staff-data", {
      method: "GET",
      headers: getAuthorizationHeaderWithContentType(),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      console.log(jsonData);
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const getStaffDataById = async (id) => {
  try {
    const response = await fetch(
      "/api/climateActive/get-staff-data-by-id/" + id,
      {
        method: "GET",
        headers: getAuthorizationHeaderWithContentType(),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      console.log(jsonData);
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};
