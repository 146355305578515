import React from "react";
import WidgetSectionTitle from "../../../widgets/WidgetSectionTitle";
import AllFormsTable from "./AllFormsTable";
import { faList } from "@fortawesome/free-solid-svg-icons";

const Index = ({ eventDetails }) => {
  console.log("eventDetails: ", eventDetails);
  const forms = [
    {
      id: eventDetails.id,
      city: eventDetails.city,
      state: eventDetails.state,
      numberOfDays: eventDetails.num_of_days,
      numberOfPeople: eventDetails.num_of_people,
      name: "Water",
      completionStatus: eventDetails.waterDataCompletionStatus,
      link: "/welcome/data/climate-active/water-form",
      startDate: eventDetails.startdate,
      endDate: eventDetails.enddate,
    },
    {
      id: eventDetails.id,
      city: eventDetails.city,
      state: eventDetails.state,
      numberOfDays: eventDetails.num_of_days,
      numberOfPeople: eventDetails.num_of_people,
      name: "Waste",
      completionStatus: eventDetails.wasteDataCompletionStatus,
      link: "/welcome/data/climate-active/waste-data-viewer",
      startDate: eventDetails.startdate,
      endDate: eventDetails.enddate,
    },
    {
      id: eventDetails.id,
      city: eventDetails.city,
      state: eventDetails.state,
      numberOfDays: eventDetails.num_of_days,
      numberOfPeople: eventDetails.num_of_people,
      name: "Food",
      completionStatus: eventDetails.foodDataCompletionStatus,
      link: "/welcome/data/climate-active/food-form",
      startDate: eventDetails.startdate,
      endDate: eventDetails.enddate,
    },
    {
      id: eventDetails.id,
      city: eventDetails.city,
      state: eventDetails.state,
      numberOfDays: eventDetails.num_of_days,
      numberOfPeople: eventDetails.num_of_people,
      name: "Flights",
      completionStatus: eventDetails.flightsDataCompletionStatus,
      link: "/welcome/data/climate-active/flights-form",
      startDate: eventDetails.startdate,
      endDate: eventDetails.enddate,
    },
    {
      id: eventDetails.id,
      city: eventDetails.city,
      state: eventDetails.state,
      numberOfDays: eventDetails.num_of_days,
      numberOfPeople: eventDetails.num_of_people,
      name: "Regional Ground Transport",
      completionStatus:
        eventDetails.regionalGroundTransportDataCompletionStatus,
      link: "/welcome/data/climate-active/regional-ground-transport-form",
      startDate: eventDetails.startdate,
      endDate: eventDetails.enddate,
    },
    {
      id: eventDetails.id,
      city: eventDetails.city,
      state: eventDetails.state,
      numberOfDays: eventDetails.num_of_days,
      numberOfPeople:
        (eventDetails.perc_local_attendees / 100) * eventDetails.num_of_people,
      name: "Local Transport",
      completionStatus: eventDetails.flightsDataCompletionStatus,
      link: "/welcome/data/climate-active/local-transport-form",
      startDate: eventDetails.startdate,
      endDate: eventDetails.enddate,
    },
  ];
  return (
    <>
      <WidgetSectionTitle
        icon={faList}
        title="Section to be completed"
        subtitle=" Complete all sections to complete event analysis."
        transform="shrink-2"
        className="mb-4 mt-6"
      />
      <div className="mb-3">
        <AllFormsTable forms={forms} />
      </div>
    </>
  );
};

export default Index;
