import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Button } from "react-bootstrap";
import classNames from "classnames";
// import { Picker } from "emoji-mart";
import AppContext, { ChatContext } from "../../../../context/Context";
// import "emoji-mart/css/emoji-mart.css";
import TextareaAutosize from "react-textarea-autosize";
import { getAllRecommendedSolutions } from "../../solutionsAPI";
import io from "socket.io-client";
import { useAuth } from "../../../../Contexts/AuthContext";

const formatDate = (date) => {
  const options = {
    weekday: "short",
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  //format date from "Mon, May 22, 2023 at 1:16 PM" to "Mon, 1:16 PM, May 22, 2023"
  const now = date
    .toLocaleString("en-US", options)
    .split(",")
    .map((item) => item.trim())
    .map((item, index) => {
      if (index === 0) {
        return item;
      } else if (index === 2) {
        return item.split("at").map((item) => item.trim());
      } else {
        return item;
      }
    });

  // return {
  // time: {
  //   day: "Mon",
  //   hour: "11:54 AM",
  //   date: "May 5, 2019",
  // },
  // };

  return {
    day: now[0],
    hour: now[2][1],
    date: now[1] + ", " + now[2][0],
  };
};

const MessageTextArea = () => {
  const {
    messagesDispatch,
    messages,
    threadsDispatch,
    currentThread,
    setScrollToBottom,
    isOpenThreadInfo,
  } = useContext(ChatContext);
  useSocket(messagesDispatch, currentThread, threadsDispatch, messages);
  const [previewEmoji, setPreviewEmoji] = useState(false);
  const [message, setMessage] = useState("");
  const [isSolution, setIsSolution] = useState(false);

  const {
    config: { isDark, isRTL },
  } = useContext(AppContext);

  const addEmoji = (e) => {
    let emoji = e.native;
    setMessage(message + emoji);
    setPreviewEmoji(false);
  };

  const addNewMessage = async (newMessage, isEnded, isWaiting) => {
    // console.log("MessageTextArea.js, addNewMessage, newMessage: ", newMessage);

    const { content } = messages.find(
      ({ id }) => id === currentThread.messagesId
    );
    // console.log("MessageTextArea.js, handleSubmit, content: ", content);

    if (message) {
      messagesDispatch({
        type: "EDIT",
        payload: {
          id: currentThread.messagesId,
          content: [...content, newMessage],
        },
        id: currentThread.messagesId,
      });

      currentThread.isWaiting = isWaiting;
      currentThread.isEnded = isEnded;

      threadsDispatch({
        type: "EDIT",
        payload: currentThread,
        id: currentThread.id,
        isUpdatedStart: true,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // let newMessages = [];
    let isEnded = false;
    let isWaiting = true;

    const date = new Date();

    //send message to server
    if (message) {
      let userNewMessage = {
        senderUserId: 3,
        message: `${message.replace(/(?:\r\n|\r|\n)/g, "<br>")}`,
        status: "seen",
        time: formatDate(date),
        delayMessage: true,
        isSolution: false,
      };
      // console.log("MessageTextArea.js, userNewMessage: ", userNewMessage);
      // newMessages = [userNewMessage];
      await addNewMessage(userNewMessage, isEnded, isWaiting);

      const body = {
        userAnswer: message,
        questionId: currentThread.questionId,
        solutionId: currentThread.areasOfImprovementId,
      };
      // console.log(body);

      setMessage("");

      // console.log(messages);

      await getAllRecommendedSolutions(body);
    }

    setScrollToBottom(true);
  };

  useEffect(() => {
    if (isOpenThreadInfo) {
      setPreviewEmoji(false);
    }
  }, [isOpenThreadInfo]);

  return (
    <Form className="chat-editor-area" onSubmit={handleSubmit}>
      <TextareaAutosize
        minRows={1}
        maxRows={6}
        value={message}
        placeholder="Type your message"
        onChange={({ target }) => setMessage(target.value)}
        className="form-control outline-none resize-none rounded-0 border-0 emojiarea-editor"
      />

      {/* <Form.Group controlId="chatFileUpload">
        <Form.Label className="chat-file-upload cursor-pointer">
          <FontAwesomeIcon icon="paperclip" />
        </Form.Label>
        <Form.Control type="file" className="d-none" />
      </Form.Group> */}

      {/* <Button
        variant="link"
        className="emoji-icon"
        onClick={() => setPreviewEmoji(!previewEmoji)}
      >
        <FontAwesomeIcon
          icon={["far", "laugh-beam"]}
          onClick={() => setPreviewEmoji(!previewEmoji)}
        />
      </Button>

      {previewEmoji && (
        <Picker
          set="google"
          onSelect={addEmoji}
          sheetSize={20}
          style={{
            position: "absolute",
            bottom: "2rem",
            left: isRTL ? "2%" : "auto",
            right: isRTL ? "auto" : "2%",
            padding: 0,
            zIndex: 1,
            backgroundColor: getColor("100"),
          }}
          theme={isDark ? "dark" : "light"}
          showPreview={false}
          showSkinTones={false}
        />
      )} */}

      <Button
        variant="send"
        size="sm"
        className={classNames({
          "text-primary": message.length > 0,
        })}
        type="submit"
        disabled={currentThread.isWaiting}
      >
        Send
      </Button>
    </Form>
  );
};

MessageTextArea.propTypes = {
  thread: PropTypes.object,
};

export default MessageTextArea;

const useSocket = (
  messagesDispatch,
  currentThread,
  threadsDispatch,
  messages
) => {
  const { currentUser } = useAuth();
  // console.log("MessageTextArea.js, messages: ", messages);

  useEffect(() => {
    const socket = io();

    socket.on("CHATBOT", async (data) => {
      // Log the messages state within the event handler
      // console.log("CHATBOT messages:", messages);

      const { content } = messages.find(
        ({ id }) => id === currentThread.messagesId
      );
      // console.log("MessageTextArea.js, connectSocket, content: ", content);

      // console.log("CHATBOT", data);

      const response = data.data;
      // console.log("response", response);

      if (currentUser.businessId !== response.businessId) return;

      //if response is not empty, then add response to messages
      if (response) {
        let isEnded = false;

        const date = new Date();

        const botMessage = response.message;
        // console.log("botMessage", botMessage);

        let botNewMessage = {
          senderUserId: 25,
          message: `${botMessage.replace(/(?:\r\n|\r|\n)/g, "<br>")}`,
          status: "seen",
          time: formatDate(date),
          delayMessage: false,
          isSolution: false,
        };

        //if response isSolution is true
        // console.log("response.isSolution", response.isSolution);
        if (response.isSolution) {
          //add a button to the message
          botNewMessage = {
            senderUserId: 25,
            message: `${
              botMessage.replace(/(?:\r\n|\r|\n)/g, "<br>") + "<br/><br/>"
            }`,
            status: "seen",
            time: formatDate(date),
            delayMessage: false,
            isSolution: true,
          };
          isEnded = true;
        }
        let isWaiting = false;

        let newMessages = [botNewMessage];

        messagesDispatch({
          type: "EDIT",
          payload: {
            id: currentThread.messagesId,
            content: content.concat(newMessages),
          },
          id: currentThread.messagesId,
        });

        currentThread.isWaiting = isWaiting;
        currentThread.isEnded = isEnded;

        threadsDispatch({
          type: "EDIT",
          payload: currentThread,
          id: currentThread.id,
          isUpdatedStart: true,
        });
      }
    });

    // Clean up the socket connection on component unmount
    return () => {
      socket.disconnect();
    };
  }, [messagesDispatch, currentThread, threadsDispatch, messages]);
};
