import React, { useEffect, useState } from "react";
import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
import { Button, Card, Nav, Row, Tab } from "react-bootstrap";
import Flex from "../../components/common/Flex";
import { useAuth } from "../../Contexts/AuthContext";
import {
  createContentImageAPI,
  getBusinessContent,
  requestContent,
} from "./contentApi";
import ContentCard from "./contentCard";
import IconButton from "../common/IconButton";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { getActiveUserData, getWhiteLabel } from "../../helpers/store";
import UpgradeSubscriptionRequestModal from "./UpgradeSubscriptionRequestModal";

const NavItem = ({ item }) => {
  return (
    <Nav.Item as="li">
      <Nav.Link
        className="mb-0 py-3 cursor-pointer"
        eventKey={item.toLowerCase()}
      >
        {item}
      </Nav.Link>
    </Nav.Item>
  );
};

const Content = () => {
  const { currentUser } = useAuth();
  useEffect(() => {
    const analytics = getAnalytics();
    setUserId(analytics, currentUser.uid);
    setUserProperties(analytics, { businessId: currentUser.businessId });
    logEvent(analytics, "page_view", {
      page_title: "Content",
    });
  }, [currentUser]);

  const navigate = useNavigate();
  const [userData, setUserData] = useState(JSON.parse(getActiveUserData()));
  const [allData, setAllData] = useState();
  const [milestoneData, setMilestoneData] = useState();
  const [otherData, setOtherData] = useState();
  const [requestedData, setRequestedData] = useState(); //to show modal
  const [modalShow, setModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function handleRequestContentImage() {
    //To notify request has been placed
    if (userData.subscription === "FREE") {
      setModalShow(true);
    }
    //To disable button
    setIsLoading(true);

    await requestContent({
      imageId: "imageId",
      imageName: "imageName",
      imageURL: "imageURL",
      contentType: "Requested",
      title: "title",
      description: "description",
      requestedBy: userData.uid,
      userName: userData.displayName,
      userEmail: userData.email,
      businesssName: userData.businessName,
      accountingSoftware: userData.authProvider,
      processingStatus: userData.processingStatus,
      subscription: userData.subscription,
    });

    await getContent();
    setIsLoading(false);
    navigate("/welcome/user-content");
  }

  async function getContent() {
    const data = await getBusinessContent();
    // console.log(data);
    if (!data) return;

    let result = [];

    //all
    result = data.filter((data) => {
      return data.content_type !== "Requested";
    });
    setAllData(result);

    //milestone
    result = data.filter((data) => {
      return data.content_type === "Milestone";
    });
    setMilestoneData(result);

    //other
    result = data.filter((data) => {
      return data.content_type === "Other";
    });
    setOtherData(result);

    //requested
    result = data.filter((data) => {
      return data.content_type === "Requested";
    });
    setRequestedData(result);
  }
  useEffect(() => {
    getContent();
  }, []);

  // const [navItems] = useState([
  //   "All",
  //   "Milestone",
  //   "Other",
  //   `Requested (${requestedData ? requestedData.length : 0})`,
  // ]);

  const generateNavItems = () => {
    const requestedCount = requestedData ? requestedData.length : 0;
    return ["All", "Milestone", "Other", `Requested (${requestedCount})`];
  };

  const generateRequestedEventKey = () => {
    return `requested (${requestedData ? requestedData.length : 0})`;
  };

  function handleClose() {
    setModalShow(false);
  }

  return (
    <>
      <UpgradeSubscriptionRequestModal
        showModal={modalShow}
        handleClose={handleClose}
      />
      <Card className="mt-3">
        <Tab.Container id="audience-tab" defaultActiveKey="all">
          <Card.Header
            as={Flex}
            justifyContent="between"
            alignItems="center"
            className="ps-0 py-0 border-bottom"
          >
            <Nav
              as="ul"
              className="nav-tabs border-0 flex-nowrap chart-tab tab-active-caret"
            >
              {generateNavItems().map((item) => (
                <NavItem item={item} key={item} />
              ))}
            </Nav>
            <div id="orders-actions">
              <IconButton
                variant="falcon-default"
                size="sm"
                icon={faDownload}
                transform="shrink-3"
                style={{ marginRight: "5px" }}
              >
                <span
                  className="d-none d-sm-inline-block ms-1"
                  onClick={handleRequestContentImage}
                >
                  Request Content
                </span>
                {isLoading && (
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    style={{ marginLeft: "10px" }}
                  ></span>
                )}
              </IconButton>
            </div>
          </Card.Header>

          <Card.Body>
            <Row>
              <Tab.Content>
                <Tab.Pane unmountOnExit eventKey="all">
                  <ContentCard data={allData} />
                </Tab.Pane>
                <Tab.Pane unmountOnExit eventKey="milestone">
                  <ContentCard data={milestoneData} />
                </Tab.Pane>
                <Tab.Pane unmountOnExit eventKey="other">
                  <ContentCard data={otherData} />
                </Tab.Pane>
                <Tab.Pane unmountOnExit eventKey={generateRequestedEventKey()}>
                  <ContentCard data={requestedData} />
                </Tab.Pane>
              </Tab.Content>
            </Row>
          </Card.Body>
        </Tab.Container>
      </Card>
    </>
  );
};

export default Content;
