import DataFrame from "dataframe-js";
// import {
//   getFirestore,
//   collection,
//   getDocs,
//   query,
//   where,
// } from "firebase/firestore";
//import app from "../../firebase";
import { getUnifiedDateFromStore } from "../../helpers/unifiedDataHelper";
import { filter, groupBy, map } from "lodash";
import { clearLoginData, getActiveUserData } from "../../helpers/store";
import { getColorForCategory, wasteCategories } from "./chartHelpers";
import { getAuthorizationHeaderWithContentType } from "../../helpers/utils";
import { wasteTypes } from "../ClimateActive/Waste/wasteData";

//const fireStoreDB = getFirestore(app);

export const fetchDateRange = (months) => {
  var start = new Date();
  start.setMonth(start.getMonth() - months);
  var end = new Date();
  return [start, end];
};

export const toFetchAndSliceDates = (data, start, end) => {
  // format  data
  const journalDates = data.map(({ date }) => date);
  //console.log(journalDates);
  const journalEmissions = data.map(({ emissions }) => emissions);
  //console.log(journalEmissions);
  const suppliers = data.map(({ contactName }) => contactName);
  //console.log(suppliers);
  const accountNames = data.map(({ accountName }) => accountName);
  // console.log(accountNames);
  // let categoryNames = data.map(({ primaryCategory }) => primaryCategory);
  // if (data[0].scope !== "SCOPE3") {
  //   categoryNames = data.map(({ secondaryCategory }) => secondaryCategory);
  // }
  const categoryNames = data.map(({ secondaryCategory }) => secondaryCategory);

  // change timestamp to a date string to filter dates
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const dateString = journalDates.map((val, idx) => formatDate(val));

  //last 12th month
  // console.log(startDate, endDate);
  // let start, end;
  // if (startDate === undefined && endDate === undefined) {
  //   const months = 12;
  //   [start, end] = fetchDateRange(months);
  // } else {
  //   start = startDate;
  //   end = endDate;
  // }
  // console.log(start, end);

  //Generate list of indexes, where the date lies between 'start' and 'end'
  var index_list = dateString.map((val, idx) =>
    new Date(val) >= start && new Date(val) <= end ? idx : null
  );
  // console.log(index_list);

  //UPDATE JournalDate AND Emissions according to index list
  let journalDate = [];
  let emissionsList = [];
  let suppliersList = [];
  let accountNameList = [];
  let categoryNameList = [];
  index_list.forEach((idx) => {
    if (idx !== null && index_list[idx] !== null) {
      journalDate.push(journalDates[idx]);
      emissionsList.push(journalEmissions[idx]);
      suppliersList.push(suppliers[idx]);
      accountNameList.push(accountNames[idx]);
      categoryNameList.push(categoryNames[idx]);
    }
  });

  let scopeData = {
    JournalDate: "",
    Emissions: "",
    Suppliers: "",
    AccountNames: "",
    CategoryNames: "",
  };
  scopeData.JournalDate = journalDate.map(function (v) {
    return v.slice(0, 7);
  });
  scopeData.Emissions = emissionsList;
  scopeData.Suppliers = suppliersList;
  scopeData.AccountNames = accountNameList;
  scopeData.CategoryNames = categoryNameList;

  return scopeData;
};

export const toFetchAndSliceDatesWithPrimaryCategory = (data, start, end) => {
  // format  data
  const journalDates = data.map(({ date }) => date);
  //console.log(journalDates);
  const journalEmissions = data.map(({ emissions }) => emissions);
  //console.log(journalEmissions);
  const suppliers = data.map(({ contactName }) => contactName);
  //console.log(suppliers);
  const accountNames = data.map(({ accountName }) => accountName);
  // console.log(accountNames);
  // let categoryNames = data.map(({ primaryCategory }) => primaryCategory);
  // if (data[0].scope !== "SCOPE3") {
  //   categoryNames = data.map(({ secondaryCategory }) => secondaryCategory);
  // }
  const categoryNames = data.map(({ primaryCategory }) => primaryCategory);
  // console.log(categoryNames);

  // change timestamp to a date string to filter dates
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const dateString = journalDates.map((val, idx) => formatDate(val));

  //last 12th month
  // console.log(startDate, endDate);
  // let start, end;
  // if (startDate === undefined && endDate === undefined) {
  //   const months = 12;
  //   [start, end] = fetchDateRange(months);
  // } else {
  //   start = startDate;
  //   end = endDate;
  // }
  // console.log(start, end);

  //Generate list of indexes, where the date lies between 'start' and 'end'
  var index_list = dateString.map((val, idx) =>
    new Date(val) >= start && new Date(val) <= end ? idx : null
  );
  //console.log(index_list);

  //UPDATE JournalDate AND Emissions according to index list
  let journalDate = [];
  let emissionsList = [];
  let suppliersList = [];
  let accountNameList = [];
  let categoryNameList = [];
  index_list.forEach((idx) => {
    if (idx !== null && index_list[idx] !== null) {
      journalDate.push(journalDates[idx]);
      emissionsList.push(journalEmissions[idx]);
      suppliersList.push(suppliers[idx]);
      accountNameList.push(accountNames[idx]);
      categoryNameList.push(categoryNames[idx]);
    }
  });

  let scopeData = {
    JournalDate: "",
    Emissions: "",
    Suppliers: "",
    AccountNames: "",
    CategoryNames: "",
  };
  scopeData.JournalDate = journalDate.map(function (v) {
    return v.slice(0, 7);
  });
  scopeData.Emissions = emissionsList;
  scopeData.Suppliers = suppliersList;
  scopeData.AccountNames = accountNameList;
  scopeData.CategoryNames = categoryNameList;

  return scopeData;
};

export const toFetchEmissionsWithPrimaryCategory = (data, start, end) => {
  // remove where primary category is ""
  data = data.filter((row) => row.primaryCategory !== "");
  // console.log(data);
  const journalDates = data.map(({ date }) => date);
  //console.log(journalDates);
  const journalEmissions = data.map(({ emissions }) => emissions);
  // console.log(journalEmissions);
  const categoryNames = data.map(({ primaryCategory }) => primaryCategory);
  // console.log(categoryNames);

  // change timestamp to a date string to filter dates
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const dateString = data.map((val, idx) => formatDate(val.date));

  //Generate list of indexes, where the date lies between 'start' and 'end'
  var index_list = dateString.map((val, idx) =>
    new Date(val) >= start && new Date(val) <= end ? idx : null
  );

  //UPDATE CategoryNames AND Emissions according to index list
  let emissionsList = [];
  let categoryNameList = [];
  index_list.forEach((idx) => {
    if (idx !== null && index_list[idx] !== null) {
      emissionsList.push(journalEmissions[idx]);
      categoryNameList.push(categoryNames[idx]);
    }
  });

  let scopeData = {
    Emissions: "",
    CategoryNames: "",
  };
  scopeData.Emissions = emissionsList;
  scopeData.CategoryNames = categoryNameList;
  // console.log(scopeData);

  //group by category name
  const df3 = new DataFrame(scopeData);
  let grouped = df3
    .select("CategoryNames", "Emissions")
    .groupBy("CategoryNames")
    .aggregate((group) => group.stat.sum("Emissions"));
  grouped = grouped.sortBy("aggregation");
  // console.log(grouped);

  scopeData = {
    Emissions: grouped.select("aggregation").toArray(),
    CategoryNames: grouped.select("CategoryNames").toArray(),
  };
  // console.log(scopeData);

  return scopeData;
};

export const xeroLabelData = (xeroScopeData) => {
  //merge xero data by date
  let df3 = new DataFrame(xeroScopeData);
  //console.log(df3);
  const grouped = df3
    .select("JournalDate", "Emissions")
    .groupBy("JournalDate")
    .aggregate((group) => group.stat.sum("Emissions"));
  //console.log(grouped);

  //labels
  const list_labels = grouped.select("JournalDate").toArray();
  //console.log("Journal Date List", list_labels);
  const label = [].concat.apply([], list_labels);
  //console.log(label);

  //data
  const list_data = grouped.select("aggregation").toArray();
  //change array of arrays to single array 'merged'
  const merged_data = Array.prototype.concat.apply([], list_data);
  const data = merged_data.map((i) => {
    return parseFloat(i, 10);
  });
  //console.log(label, data);
  return [label, data];
};

export const xeroLabelDataByContactName = (xeroScopeData) => {
  if (xeroScopeData.Suppliers.length > 1) {
    // groupby supplier name
    const df3 = new DataFrame(xeroScopeData);
    let grouped = df3
      .select("Suppliers", "Emissions")
      .groupBy("Suppliers")
      .aggregate((group) => group.stat.sum("Emissions"));
    grouped = grouped.sortBy("aggregation").tail(10);

    //labels
    const list_labels = grouped.select("Suppliers").toArray();
    const label = Array.prototype.concat.apply([], list_labels);

    //data
    const list_data = grouped.select("aggregation").toArray();
    //change array of arrays to single array 'merged'
    const merged_data = Array.prototype.concat.apply([], list_data);
    const data = merged_data.map((i) => {
      return parseFloat(i, 10);
    });
    return [label, data];
  } else {
    const label = xeroScopeData.Suppliers;
    const data = xeroScopeData.Emissions;
    return [label, data];
  }
};

export const xeroLabelDataByAccountName = (xeroScopeData) => {
  if (xeroScopeData.AccountNames.length > 1) {
    // groupby account name
    const df3 = new DataFrame(xeroScopeData);
    let grouped = df3
      .select("AccountNames", "Emissions")
      .groupBy("AccountNames")
      .aggregate((group) => group.stat.sum("Emissions"));
    grouped = grouped.sortBy("aggregation").tail(10);

    //labels
    const list_labels = grouped.select("AccountNames").toArray();
    const label = Array.prototype.concat.apply([], list_labels);

    //data
    const list_data = grouped.select("aggregation").toArray();
    //change array of arrays to single array 'merged'
    const merged_data = Array.prototype.concat.apply([], list_data);
    const data = merged_data.map((i) => {
      return parseFloat(i, 10);
    });
    return [label, data];
  } else {
    const label = xeroScopeData.AccountNames;
    const data = xeroScopeData.Emissions;
    return [label, data];
  }
};

export const xeroLabelDataByCategoryName = (xeroScopeData) => {
  if (xeroScopeData.CategoryNames.length > 1) {
    // group by category name
    const df3 = new DataFrame(xeroScopeData);
    let grouped = df3
      .select("CategoryNames", "Emissions")
      .groupBy("CategoryNames")
      .aggregate((group) => group.stat.sum("Emissions")); // Calculate the sum of "Emissions" instead of "CategoryNames"
    grouped = grouped.sortBy("aggregation").tail(8);

    // labels
    const list_labels = grouped.select("CategoryNames").toArray();
    const label = Array.prototype.concat.apply([], list_labels);
    // console.log(label);

    // data
    const list_data = grouped.select("aggregation").toArray();
    // console.log(list_data);

    // change array of arrays to a single array 'merged'
    const merged_data = Array.prototype.concat.apply([], list_data);
    const data = merged_data.map((i) => {
      return parseFloat(i, 10);
    });
    return [label, data];
  } else {
    const label = xeroScopeData.CategoryNames;
    const data = xeroScopeData.Emissions;
    return [label, data];
  }
};

export const sortLabelledData = (label, data) => {
  //sort the data by date
  let label_data = [];
  label.forEach((key, i) => (label_data[i] = { label: key, data: data[i] }));
  //console.log(label_data);

  let sorted_label_data = label_data.sort((a, b) =>
    (a.label + "-01").localeCompare(b.label + "-01")
  );
  //console.log(sorted_label_data);

  let labelData = {}; //empty object
  sorted_label_data.forEach((item, i) => {
    let value = labelData[item.label] || 0;
    labelData[item.label] = value + item.data;
  });
  // console.log(labelData);

  const labelList = Object.keys(labelData);
  const dataList = Object.values(labelData);

  return { labelList, dataList };
};

export const sortLabelledDataByEmissions = (label, data) => {
  //sort the data by emission
  let label_data = [];
  label.forEach((key, i) => (label_data[i] = { label: key, data: data[i] }));
  // console.log(label_data);

  let sorted_label_data = label_data.sort((a, b) => b.data - a.data);
  // console.log(sorted_label_data);

  let labelData = {}; //empty object
  sorted_label_data.forEach((item, i) => {
    let value = labelData[item.label] || 0;
    labelData[item.label] = value + item.data;
  });
  // console.log(labelData);

  const labelList = Object.keys(labelData);
  const dataList = Object.values(labelData);

  return { labelList, dataList };
};

export const sortFormLabelledData = (date, categoryname, emissions) => {
  //sort the data by date
  let labelData = [];
  date.forEach(
    (key, i) =>
      (labelData[i] = {
        label: key,
        data: emissions[i],
        category: categoryname[i],
      })
  );
  //console.log(labelData);

  let sortedLabelData = labelData.sort((a, b) =>
    (a.label + "-01").localeCompare(b.label + "-01")
  );
  //console.log(sortedLabelData);

  let formattedLabelData = {};
  sortedLabelData.forEach((item, i) => {
    const { label, data, category } = item;
    const date = label;
    if (!formattedLabelData[date]) {
      formattedLabelData[date] = [];
    }
    formattedLabelData[date].push({ category, data });
  });
  //console.log(formattedLabelData);

  const labelList = Object.keys(formattedLabelData);
  const dataList = Object.values(formattedLabelData);

  //format datalist to be [{label: "category", data: [data]}]
  const modifiedDataList = [];
  const obj = {};

  // Group the data by label
  dataList.forEach((item) => {
    item.forEach((i) => {
      if (!obj[i.category]) {
        obj[i.category] = [];
      }
      obj[i.category].push(i.data);
    });
  });

  // Convert the grouped data to the desired format
  for (const [label, data] of Object.entries(obj)) {
    modifiedDataList.push({ label: label, data: data });
  }

  //console.log(labelList);
  //console.log(modifiedDataList);

  return { labelList, dataList: modifiedDataList };
};

export const toBeDisplayedData = (dataToDisplay, data) => {
  let journalsList = [];
  let emissionsList = [];
  let suppliersList = [];
  let accountNameList = [];
  let categoryNameList = [];

  if (dataToDisplay?.length > 0) {
    data.JournalDate.forEach((date, idx) => {
      let displayData = dataToDisplay;
      if (displayData.includes(date)) {
        journalsList.push(data.JournalDate[idx]);
        emissionsList.push(data.Emissions[idx]);
        suppliersList.push(data.Suppliers[idx]);
        accountNameList.push(data.AccountNames[idx]);
        categoryNameList.push(data.CategoryNames[idx]);
      }
    });
  }
  return [
    journalsList,
    emissionsList,
    suppliersList,
    accountNameList,
    categoryNameList,
  ];
};

export const getChartData = async (billData, xeroData, groupBy, start, end) => {
  if (billData.length > 0 && xeroData.length > 0) {
    // console.log("XERO and BILL Data");

    //fetch bill date and emissions only in last 12month
    if (groupBy === "PrimaryCategory") {
      billData = toFetchEmissionsWithPrimaryCategory(billData, start, end);
    } else {
      billData = toFetchAndSliceDates(billData, start, end);
    }
    //unique month and year in bill data
    const uniqueBillData = [...new Set(billData.JournalDate)];
    //console.log("uniqueBillData", uniqueBillData);

    //fetch xero date and emissions only in last 12month
    if (groupBy === "PrimaryCategory") {
      xeroData = toFetchEmissionsWithPrimaryCategory(xeroData, start, end);
    } else {
      xeroData = toFetchAndSliceDates(xeroData, start, end);
    }

    //unique month and year in xero data
    const uniqueXeroData = [...new Set(xeroData.JournalDate)];
    //console.log("uniqueXeroData", uniqueXeroData);

    //separating data based on date present in bill data
    let commonBillXeroDataToDisplay = [];
    let xeroDataToDisplay = [];

    uniqueXeroData.forEach((date) => {
      if (uniqueBillData.includes(date)) {
        commonBillXeroDataToDisplay.push(date);
      } else {
        xeroDataToDisplay.push(date);
      }
    });
    let billDataToDisplay = [
      ...new Set([...uniqueBillData, ...commonBillXeroDataToDisplay]),
    ];

    // console.log(billDataToDisplay, xeroDataToDisplay);

    //populating bill data to be displayed
    const billDataToBeDisplayed = toBeDisplayedData(
      billDataToDisplay,
      billData
    );

    let billDataJournals = billDataToBeDisplayed[0];
    let billDataEmissions = billDataToBeDisplayed[1];
    let billDataSuppliers = billDataToBeDisplayed[2];
    let billDataAccountName = billDataToBeDisplayed[3];
    let billDataCategoryName = billDataToBeDisplayed[4];

    //populating bill xero to be displayed
    const xeroDataToBeDisplayed = toBeDisplayedData(
      xeroDataToDisplay,
      xeroData
    );

    let xeroDataJournals = xeroDataToBeDisplayed[0];
    let xeroDataEmissions = xeroDataToBeDisplayed[1];
    let xeroDataSuppliers = xeroDataToBeDisplayed[2];
    let xeroDataAccountNames = xeroDataToBeDisplayed[3];
    let xeroDataCategoryNames = xeroDataToBeDisplayed[4];

    //merge xero data by date
    const xeroScopeData = {
      JournalDate: xeroDataJournals,
      Emissions: xeroDataEmissions,
      Suppliers: xeroDataSuppliers,
      AccountNames: xeroDataAccountNames,
      CategoryNames: xeroDataCategoryNames,
    };

    let xeroLabelledData = [];
    let label = [];
    let data = [];

    //By Journal Date
    if (groupBy === "Date") {
      xeroLabelledData = xeroLabelData(xeroScopeData);
      xeroDataJournals = xeroLabelledData[0];
      xeroDataEmissions = xeroLabelledData[1];

      label = [...billDataJournals, ...xeroDataJournals];
      data = [...billDataEmissions, ...xeroDataEmissions];
    }
    //By Contact Name
    if (groupBy === "contactName") {
      xeroLabelledData = xeroLabelDataByContactName(xeroScopeData);
      xeroDataSuppliers = xeroLabelledData[0];
      xeroDataEmissions = xeroLabelledData[1];

      label = [...billDataSuppliers, ...xeroDataSuppliers];
      data = [...billDataEmissions, ...xeroDataEmissions];
    }

    //By Account Name
    if (groupBy === "accountName") {
      xeroLabelledData = xeroLabelDataByAccountName(xeroScopeData);
      xeroDataAccountNames = xeroLabelledData[0];
      xeroDataEmissions = xeroLabelledData[1];

      label = [...billDataAccountName, ...xeroDataAccountNames];
      data = [...billDataEmissions, ...xeroDataEmissions];
    }

    //By Category Name
    if (groupBy === "categoryName") {
      xeroLabelledData = xeroLabelDataByCategoryName(xeroScopeData);
      xeroDataCategoryNames = xeroLabelledData[0];
      xeroDataEmissions = xeroLabelledData[1];

      label = [...billDataCategoryName, ...xeroDataCategoryNames];
      data = [...billDataEmissions, ...xeroDataEmissions];
    }

    //By Primary Category Name
    if (groupBy === "PrimaryCategory") {
      xeroLabelledData = xeroLabelDataByCategoryName(xeroScopeData);
      xeroDataCategoryNames = xeroLabelledData[0];
      xeroDataEmissions = xeroLabelledData[1];

      label = [...billDataCategoryName, ...xeroDataCategoryNames];
      data = [...billDataEmissions, ...xeroDataEmissions];

      const { labelList, dataList } = sortLabelledDataByEmissions(label, data);

      return {
        Label: labelList,
        Data: dataList,
      };
    }

    //By Date and Category Name
    // if (groupBy === "dateAndCategoryName") {
    //   xeroLabelledData = xeroLabelDataByDateAndCategoryName(xeroScopeData);
    //   label = xeroLabelledData[0];
    //   dataSet = xeroLabelledData[1];
    // }

    const { labelList, dataList } = sortLabelledData(label, data);

    const undefinedInddex = labelList.indexOf(undefined);
    if (undefinedInddex !== -1) {
      labelList.splice(undefinedInddex, 1);
      dataList.splice(undefinedInddex, 1);
    }

    return {
      Label: labelList,
      Data: dataList,
    };
  } else if (billData.length > 0 && xeroData.length === 0) {
    //BILL DATA ONLY
    // console.log("Bill data only");
    //fetch bill date and emissions only in last 12month
    if (groupBy === "Date&PrimaryCategory" || groupBy === "PrimaryCategory") {
      billData = toFetchAndSliceDatesWithPrimaryCategory(billData, start, end);
    } else {
      billData = toFetchAndSliceDates(billData, start, end);
    }
    // console.log("billData", billData);

    //By Journal Date
    if (groupBy === "Date") {
      const { labelList, dataList } = sortLabelledData(
        billData.JournalDate,
        billData.Emissions
      );

      const undefinedInddex = labelList.indexOf(undefined);
      if (undefinedInddex !== -1) {
        labelList.splice(undefinedInddex, 1);
        dataList.splice(undefinedInddex, 1);
        // console.log(labelList, dataList);
      }

      return {
        Label: labelList,
        Data: dataList,
      };
    }

    //By Contact Name
    if (groupBy === "contactName") {
      const { labelList, dataList } = sortLabelledData(
        billData.Suppliers,
        billData.Emissions
      );

      const undefinedInddex = labelList.indexOf(undefined);
      if (undefinedInddex !== -1) {
        labelList.splice(undefinedInddex, 1);
        dataList.splice(undefinedInddex, 1);
        // console.log(labelList, dataList);
      }

      return {
        Label: labelList,
        Data: dataList,
      };
    }

    //By Account Name
    if (groupBy === "accountName") {
      const { labelList, dataList } = sortLabelledData(
        billData.AccountNames,
        billData.Emissions
      );

      const undefinedInddex = labelList.indexOf(undefined);
      if (undefinedInddex !== -1) {
        labelList.splice(undefinedInddex, 1);
        dataList.splice(undefinedInddex, 1);
        // console.log(labelList, dataList);
      }

      return {
        Label: labelList,
        Data: dataList,
      };
    }

    //By Category Name
    if (groupBy === "categoryName") {
      const { labelList, dataList } = sortLabelledData(
        billData.CategoryNames,
        billData.Emissions
      );

      const undefinedInddex = labelList.indexOf(undefined);
      if (undefinedInddex !== -1) {
        labelList.splice(undefinedInddex, 1);
        dataList.splice(undefinedInddex, 1);
        // console.log(labelList, dataList);
      }

      return {
        Label: labelList,
        Data: dataList,
      };
    }

    //By Date and Category Name
    if (groupBy === "Date&SecondaryCategory") {
      const { labelList, dataList } = sortFormLabelledData(
        billData.JournalDate,
        billData.CategoryNames,
        billData.Emissions
      );

      return {
        Label: labelList,
        Data: dataList,
      };
    }

    //By Date and Primary Category Name
    if (groupBy === "Date&PrimaryCategory") {
      const { labelList, dataList } = sortLabelledData(
        billData.CategoryNames,
        billData.Emissions
      );

      const undefinedInddex = labelList.indexOf(undefined);
      if (undefinedInddex !== -1) {
        labelList.splice(undefinedInddex, 1);
        dataList.splice(undefinedInddex, 1);
        // console.log(labelList, dataList);
      }

      return {
        Label: labelList,
        Data: dataList,
      };
    }

    //By Primary Category Name
    if (groupBy === "PrimaryCategory") {
      const { labelList, dataList } = sortLabelledDataByEmissions(
        billData.CategoryNames,
        billData.Emissions
      );
      // console.log(labelList, dataList);

      return {
        Label: labelList,
        Data: dataList,
      };
    }

    //By Primary Category Name
  } else {
    if (xeroData.length > 0 && billData.length === 0) {
      //XERO DATA ONLY
      // console.log("Xero data only", groupBy);

      //fetch xero date and emissions only in last 12month
      if (groupBy === "PrimaryCategory") {
        xeroData = toFetchEmissionsWithPrimaryCategory(xeroData, start, end);
      } else {
        xeroData = toFetchAndSliceDates(xeroData, start, end);
      }
      //console.log("xeroData=>", xeroData);

      let xeroLabelledData = [];

      //By Journal Date
      if (groupBy === "Date") {
        xeroLabelledData = xeroLabelData(xeroData);
      }
      //By Contact Name
      if (groupBy === "contactName") {
        xeroLabelledData = xeroLabelDataByContactName(xeroData);
      }
      //By Account Name
      if (groupBy === "accountName") {
        xeroLabelledData = xeroLabelDataByAccountName(xeroData);
      }
      //By Category Name
      if (groupBy === "categoryName") {
        xeroLabelledData = xeroLabelDataByCategoryName(xeroData);
      }

      //By Primary Category Name
      if (groupBy === "PrimaryCategory") {
        xeroLabelledData = xeroLabelDataByCategoryName(xeroData);
        // console.log(xeroLabelledData);

        const { labelList, dataList } = sortLabelledDataByEmissions(
          xeroLabelledData[0],
          xeroLabelledData[1]
        );
        // console.log(labelList, dataList);

        return {
          Label: labelList,
          Data: dataList,
        };
      }

      let label = xeroLabelledData[0];
      let data = xeroLabelledData[1];

      const { labelList, dataList } = sortLabelledData(label, data);

      const undefinedInddex = labelList.indexOf(undefined);
      if (undefinedInddex !== -1) {
        labelList.splice(undefinedInddex, 1);
        dataList.splice(undefinedInddex, 1);
        // console.log(labelList, dataList);
      }

      return {
        Label: labelList,
        Data: dataList,
      };
    } else {
      return {
        Label: [],
        Data: [],
      };
    }
  }
};

export const fetchFormData = async (businessId, primaryCategory) => {
  try {
    let qry = "";
    let data = [];

    const fullData = await getUnifiedDateFromStore();
    if (primaryCategory === "") {
      data = filter(fullData, {
        inclusionStatus: "INCLUDED",
        scope: "SCOPE3",
        dataSource: "Form",
      });

      // qry = query(
      //   collection(fireStoreDB, "UnifiedData", businessId, "DataLines"),
      //   where("inclusionStatus", "==", "INCLUDED"),
      //   where("scope", "==", "SCOPE3"),
      //   where("dataSource", "==", "Form")
      // );
    } else {
      data = filter(fullData, {
        inclusionStatus: "INCLUDED",
        scope: "SCOPE3",
        dataSource: "Form",
        primaryCategory: primaryCategory,
      });
      // qry = query(
      //   collection(fireStoreDB, "UnifiedData", businessId, "DataLines"),
      //   where("inclusionStatus", "==", "INCLUDED"),
      //   where("scope", "==", "SCOPE3"),
      //   where("dataSource", "==", "Form"),
      //   where("primaryCategory", "==", primaryCategory)
      // );
    }

    // const querySnapshot = await getDocs(qry);
    // querySnapshot.forEach((doc) => {
    //   data.push(doc.data());
    // });

    //update 'emissions' in formData from 'kg' to 't'
    data = data.map((item) => ({
      ...item,
      // emissions: item.emissions / 1000,
    }));

    //for each data, check if primary category is in ["Flight", "Transport (air)"] then set primary category to "Flights"
    data = data.map((item) => {
      if (
        item.primaryCategory === "Flight" ||
        item.primaryCategory === "Transport (air)"
      ) {
        item.primaryCategory = "Flights";
      }

      if (
        item.primaryCategory === "Staff" ||
        item.primaryCategory === "Transport (land and sea)"
      ) {
        item.primaryCategory = "Employee Commute";
      }

      return item;
    });

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchBillData = async (officeId, businessId, scope, name) => {
  let names = [];
  //for electricity
  if (name === "Electricity supply") {
    names = [
      "Electricity supply",
      "Electricity supply from the grid full scope",
    ];
  }
  //for natural gas
  if (name === "Natural gas") {
    names = ["Natural gas", "Natural Gas National average (GJ)"];
  }

  try {
    let qry = "";
    let data = [];
    const fullData = await getUnifiedDateFromStore();
    if (officeId === "all") {
      data = filter(fullData, (item) => {
        return (
          item.inclusionStatus === "INCLUDED" &&
          item.scope === scope &&
          (item.secondaryCategory === names[0] ||
            item.secondaryCategory === names[1]) &&
          item.dataSource === "Bill"
        );
      });
      // qry = query(
      //   collection(fireStoreDB, "UnifiedData", businessId, "DataLines"),
      //   where("inclusionStatus", "==", "INCLUDED"),
      //   where("scope", "==", scope),
      //   where("secondaryCategory", "==", name),
      //   where("dataSource", "==", "Bill")
      // );
    } else {
      if (officeId !== "" && name !== "") {
        data = filter(fullData, (item) => {
          return (
            item.inclusionStatus === "INCLUDED" &&
            item.scope === scope &&
            (item.secondaryCategory === names[0] ||
              item.secondaryCategory === names[1]) &&
            item.dataSource === "Bill" &&
            item.officeId === officeId
          );
        });
        // qry = query(
        //   collection(fireStoreDB, "UnifiedData", businessId, "DataLines"),
        //   where("inclusionStatus", "==", "INCLUDED"),
        //   where("scope", "==", scope),
        //   where("secondaryCategory", "==", name),
        //   where("dataSource", "==", "Bill"),
        //   where("officeId", "==", officeId)
        // );
      } else if (officeId !== "" && name === "") {
        data = filter(fullData, {
          inclusionStatus: "INCLUDED",
          scope: scope,
          dataSource: "Bill",
          officeId: officeId,
        });

        // qry = query(
        //   collection(fireStoreDB, "UnifiedData", businessId, "DataLines"),
        //   where("inclusionStatus", "==", "INCLUDED"),
        //   where("scope", "==", scope),
        //   where("dataSource", "==", "Bill"),
        //   where("officeId", "==", officeId)
        // );
      } else {
        if (scope !== "") {
          data = filter(fullData, {
            inclusionStatus: "INCLUDED",
            dataSource: "Bill",
            scope: scope,
          });
          // qry = query(
          //   collection(fireStoreDB, "UnifiedData", businessId, "DataLines"),
          //   where("inclusionStatus", "==", "INCLUDED"),
          //   where("dataSource", "==", "Bill"),
          //   where("scope", "==", scope)
          // );
        } else {
          data = filter(fullData, {
            inclusionStatus: "INCLUDED",
            dataSource: "Bill",
          });
          // qry = query(
          //   collection(fireStoreDB, "UnifiedData", businessId, "DataLines"),
          //   where("inclusionStatus", "==", "INCLUDED"),
          //   where("dataSource", "==", "Bill")
          // );
        }
      }
    }
    // if (qry !== undefined) {
    //   let querySnapshot = await getDocs(qry);
    //   querySnapshot.forEach((doc) => {
    //     data.push({
    //       id: doc.id,
    //       ...doc.data(),
    //     });
    //   });
    // }

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchXeroData = async (business, scope, name, dataSource) => {
  let data = [];
  let qry = "";

  const fullData = await getUnifiedDateFromStore();
  //assign category based on scope
  let category = "primaryCategory";
  if (scope !== "SCOPE3") {
    category = "secondaryCategory";
  }
  try {
    if (name !== "") {
      if (category === "primaryCategory") {
        data = filter(fullData, {
          inclusionStatus: "INCLUDED",
          scope: scope,
          dataSource: dataSource,
          primaryCategory: name,
        });
      } else {
        data = filter(fullData, {
          inclusionStatus: "INCLUDED",
          scope: scope,
          dataSource: dataSource,
          secondaryCategory: name,
        });
      }
      // qry = query(
      //   collection(fireStoreDB, "UnifiedData", business, "DataLines"),
      //   where("inclusionStatus", "==", "INCLUDED"),
      //   where("scope", "==", scope),
      //   where("dataSource", "==", dataSource),
      //   where(category, "==", name)
      // );
    } else {
      if (scope !== "") {
        data = filter(fullData, {
          inclusionStatus: "INCLUDED",
          scope: scope,
          dataSource: dataSource,
        });

        // qry = query(
        //   collection(fireStoreDB, "UnifiedData", business, "DataLines"),
        //   where("inclusionStatus", "==", "INCLUDED"),
        //   where("dataSource", "==", dataSource),
        //   where("scope", "==", scope)
        // );
      } else {
        data = filter(fullData, {
          inclusionStatus: "INCLUDED",
          dataSource: dataSource,
        });
        // qry = query(
        //   collection(fireStoreDB, "UnifiedData", business, "DataLines"),
        //   where("inclusionStatus", "==", "INCLUDED"),
        //   where("dataSource", "==", dataSource)
        // );
      }
    }
    // if (qry !== undefined) {
    //   let querySnapshot = await getDocs(qry);
    //   querySnapshot.forEach((doc) => {
    //     data.push({
    //       id: doc.id,
    //       ...doc.data(),
    //     });
    //   });
    // }
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchTwelveMonthsWithPrecedenceChartData = async (
  business,
  office,
  scope,
  categoryName,
  groupBy,
  dataSource,
  startDate,
  endDate
) => {
  let xeroData = await fetchXeroData(business, scope, categoryName, dataSource);

  let chartData = [];
  if (scope === "SCOPE3") {
    const formData = await fetchFormData(business, "");

    const combinedData = [...formData, ...xeroData];

    chartData = await getChartData(
      combinedData,
      [],
      groupBy,
      startDate,
      endDate
    );
  } else {
    const billData = await fetchBillData(office, business, scope, categoryName);
    chartData = await getChartData(
      billData,
      xeroData,
      groupBy,
      startDate,
      endDate
    );
  }

  return chartData;
};

export const fetchChartDataForForm = async (
  business,
  groupBy,
  startDate,
  endDate,
  primaryCategory
) => {
  const formData = await fetchFormData(business, primaryCategory);
  const chartData = await getChartData(
    formData,
    [],
    groupBy,
    startDate,
    endDate
  );

  return chartData;
};

export const fetchChartDataForScope3XeroOrMYOB = async (
  business,
  scope,
  categoryName,
  groupBy,
  dataSource,
  startDate,
  endDate
) => {
  const data = await fetchXeroData(business, scope, categoryName, dataSource);

  const chartData = await getChartData([], data, groupBy, startDate, endDate);

  return chartData;
};

export const fetchUnifiedData = async (business) => {
  let data = [];
  try {
    const fullData = await getUnifiedDateFromStore();
    data = filter(fullData, {
      inclusionStatus: "INCLUDED",
    });

    data = map(data, function square(itemData) {
      if (itemData.dataSource === "Form") {
        // itemData.emissions = itemData.emissions / 1000; // Convert from 'kg' to 't'

        // if primary category is in ["Flight", "Transport (air)"] then set primary category to "Flights"
        if (
          itemData.primaryCategory === "Flight" ||
          itemData.primaryCategory === "Transport (air)"
        ) {
          itemData.primaryCategory = "Flights";
        }
        // if primary category is in ["Staff", "Transport (land and sea)"] then set primary category to "Employee Commute"
        if (
          itemData.primaryCategory === "Staff" ||
          itemData.primaryCategory === "Transport (land and sea)"
        ) {
          itemData.primaryCategory = "Employee Commute";
        }
      }
      return itemData;
    });

    // const qry = query(
    //   collection(fireStoreDB, "UnifiedData", business, "DataLines"),
    //   where("inclusionStatus", "==", "INCLUDED")
    // );

    // const querySnapshot = await getDocs(qry);
    // querySnapshot.forEach((doc) => {
    //   const itemData = doc.data();

    //   // Check if the dataSource is 'Form' before updating emissions
    //   if (itemData.dataSource === "Form") {
    //     itemData.emissions = itemData.emissions / 1000; // Convert from 'kg' to 't'
    //   }

    //   data.push(itemData);
    // });

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchChartDataForEmissonsByPrimaryCategory = async (
  business,
  groupBy,
  startDate,
  endDate
) => {
  const unifiedData = await fetchUnifiedData(business);
  const chartData = await getChartData(
    unifiedData,
    [],
    groupBy,
    startDate,
    endDate
  );

  return chartData;
};

// TODO: Refactor this function to use FullData
export const fetchChartDataForEmissonsByContactName = async (
  business,
  groupBy,
  startDate,
  endDate
) => {
  const unifiedData = await fetchUnifiedData(business);
  //remove datalines where the dataSource is 'Form'
  const data = unifiedData.filter((item) => item.dataSource !== "Form");

  const chartData = await getChartData(data, [], groupBy, startDate, endDate);

  return chartData;
};

// TODO: Refactor this function to use FullData
export const fetchChartDataForEmissonsBySecondaryCategory = async (
  business,
  groupBy,
  startDate,
  endDate
) => {
  const unifiedData = await fetchUnifiedData(business);
  const chartData = await getChartData(
    unifiedData,
    [],
    groupBy,
    startDate,
    endDate
  );

  return chartData;
};

export const fetchSecondaryCategories = async (startDate, endDate) => {
  const fullData = await getUnifiedDateFromStore();
  // console.log("fullData =>", fullData);

  let data = filter(fullData, (item) => {
    return item.date >= startDate && item.date <= endDate;
  });

  //format date to month and year
  data = data.map((item) => {
    return {
      ...item,
      date: item.date.split("-")[0] + "-" + item.date.split("-")[1],
    };
  });

  //group by date
  const grouped = groupBy(data, "secondaryCategory");
  // console.log(grouped);

  //calculate the sum of emissions for each date
  const totalEmissions = [];
  const date = [];
  for (const [key, value] of Object.entries(grouped)) {
    let sum = 0;
    value.forEach((item) => {
      sum += item.totalEmissions;
    });
    totalEmissions.push(sum);
    date.push(key);
  }

  return {
    Label: date,
    Data: totalEmissions,
  };
};

export const fetchScope1TotalEmissions = async (startDate, endDate) => {
  const fullData = await getUnifiedDateFromStore();
  // console.log("fullData =>", fullData);

  let data = filter(fullData, (item) => {
    return (
      item.scope === "SCOPE1" && item.date >= startDate && item.date <= endDate
    );
  });

  //format date to month and year
  data = data.map((item) => {
    return {
      ...item,
      date: item.date.split("-")[0] + "-" + item.date.split("-")[1],
    };
  });

  //group by date
  const grouped = groupBy(data, "date");
  // console.log(grouped);

  //calculate the sum of emissions for each date
  const totalEmissions = [];
  const date = [];
  for (const [key, value] of Object.entries(grouped)) {
    let sum = 0;
    value.forEach((item) => {
      sum += item.totalEmissions;
    });
    totalEmissions.push(sum);
    date.push(key);
  }

  return {
    Label: date,
    Data: totalEmissions,
  };
};

export const fetchScope2TotalEmissions = async (startDate, endDate) => {
  const fullData = await getUnifiedDateFromStore();
  let data = filter(fullData, (item) => {
    return (
      item.scope === "SCOPE2" && item.date >= startDate && item.date <= endDate
    );
  });
  // console.log("Data", data);

  //format date to month and year
  data = data.map((item) => {
    return {
      ...item,
      date: item.date.split("-")[0] + "-" + item.date.split("-")[1],
    };
  });

  //group by date
  const grouped = groupBy(data, "date");
  // console.log(grouped);

  //calculate the sum of emissions for each date
  const totalEmissions = [];
  const date = [];
  for (const [key, value] of Object.entries(grouped)) {
    let sum = 0;
    value.forEach((item) => {
      sum += item.totalEmissions;
    });
    totalEmissions.push(sum);
    date.push(key);
  }

  return {
    Label: date,
    Data: totalEmissions,
  };
};

export const fetchScope3TotalEmissions = async (startDate, endDate) => {
  const fullData = await getUnifiedDateFromStore();
  let data = filter(fullData, (item) => {
    return (
      item.scope === "SCOPE3" && item.date >= startDate && item.date <= endDate
    );
  });
  // console.log(data);

  //if dataSources is 'Form' then set emissions to 't'
  data = data.map((item) => {
    if (item.dataSource === "Form") {
      // item.totalEmissions = item.totalEmissions / 1000;
    }
    return item;
  });

  //format date to month and year
  data = data.map((item) => {
    return {
      ...item,
      date: item.date.split("-")[0] + "-" + item.date.split("-")[1],
    };
  });

  //group by date
  const grouped = groupBy(data, "date");
  // console.log(grouped);

  //calculate the sum of emissions for each date
  const totalEmissions = [];
  const date = [];
  for (const [key, value] of Object.entries(grouped)) {
    let sum = 0;
    value.forEach((item) => {
      sum += item.totalEmissions;
    });
    totalEmissions.push(sum);
    date.push(key);
  }

  return {
    Label: date,
    Data: totalEmissions,
  };
};

export const fetchEmissionsByPrimaryCategory = async (startDate, endDate) => {
  const fullData = await getUnifiedDateFromStore();
  let data = filter(fullData, (item) => {
    return item.date >= startDate && item.date <= endDate;
  });

  //if dataSources is 'Form' then set primary category differently and set emissions to 't'
  data.forEach((item) => {
    if (item.dataSource === "Form") {
      // item.totalEmissions = item.totalEmissions / 1000;

      if (
        item.primaryCategory === "Flight" ||
        item.primaryCategory === "Transport (air)"
      ) {
        item.primaryCategory = "Flights";
      }
      if (
        item.primaryCategory === "Staff" ||
        item.primaryCategory === "Transport (land and sea)"
      ) {
        item.primaryCategory = "Employee Commute";
      }
    }
  });

  //group by primary category
  const grouped = groupBy(data, "primaryCategory");
  // console.log(grouped);

  //calculate the sum of emissions for each primary category
  const totalEmissions = [];
  const primaryCategory = [];
  for (const [key, value] of Object.entries(grouped)) {
    let sum = 0;
    value.forEach((item) => {
      sum += item.totalEmissions;
    });
    totalEmissions.push(sum);
    primaryCategory.push(key);
  }

  //sort the data by emission in descending order
  const { labelList, dataList } = sortLabelledDataByEmissions(
    primaryCategory,
    totalEmissions
  );
  // console.log("sortedData", sortedData);

  return {
    Label: labelList,
    Data: dataList,
  };
};

const getMonthsOrder = (selectedMeasurementYearType) => {
  return selectedMeasurementYearType === "calendar"
    ? [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ]
    : [
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
      ];
};

const getCorrectMonthIndex = (date, selectedMeasurementYearType) => {
  const monthIndex = date.getMonth();
  if (selectedMeasurementYearType === "financial") {
    return (monthIndex + 6) % 12;
  }
  return monthIndex;
};

export const fetchEmissionsFromClimateActive = async (startDate, endDate) => {
  const activeUser = JSON.parse(getActiveUserData());
  function normalizeCategory(category) {
    if (category === "Transport (air)" || category === "Flight") {
      return "Flights";
    }
    if (category === "Transport (land and sea)" || category === "Staff") {
      return "EmployeeCommute";
    }
    switch (category) {
      case "Paper and cardboard (not recycled)":
        return "Paper and cardboard";
      case "General waste (municipal waste)":
        return "General waste";
      default:
        return category;
    }
  }

  const fullData = await getUnifiedDateFromStore();

  let data = filter(fullData, (item) => {
    return (
      item.dataSource === "Form" &&
      item.date >= startDate &&
      item.date <= endDate
    );
  });

  const monthlyData = {};

  data.forEach((entry) => {
    const month = new Date(entry.date).toLocaleString("en-us", {
      month: "long",
    });
    const normalizedCategory = normalizeCategory(entry.primaryCategory);

    if (!monthlyData[month]) {
      monthlyData[month] = {
        month: month,
        Flights: 0,
        Waste: 0,
        Paper: 0,
        WFH: 0,
        EmployeeCommute: 0,
        Water: 0,
      };
    }

    if (normalizedCategory in monthlyData[month]) {
      monthlyData[month][normalizedCategory] += entry.totalEmissions;
    }
  });

  const monthsOrder = getMonthsOrder(activeUser.selectedMeasurementYearType);

  const orderedMonthlyData = monthsOrder.map((month) => {
    return (
      monthlyData[month] || {
        month: month,
        Flights: 0,
        Waste: 0,
        Paper: 0,
        WFH: 0,
        EmployeeCommute: 0,
        Water: 0,
      }
    );
  });

  return {
    orderedMonthlyData,
  };
};

export const fetchEmissionsFromWasteCalculator = async (startDate, endDate) => {
  const activeUser = JSON.parse(getActiveUserData());
  function normalizeCategory(category) {
    switch (category) {
      case "Paper and cardboard (not recycled)":
        return "Paper and cardboard";
      case "General waste (municipal waste)":
        return "General waste";
      default:
        return category;
    }
  }

  const fullData = await getUnifiedDateFromStore();

  let data = filter(fullData, (item) => {
    return (
      item.dataSource === "Form" &&
      item.primaryCategory === "Waste" &&
      item.date >= startDate &&
      item.date <= endDate
    );
  });

  // console.log("Full waste data", data);

  const wasteEmissionsByMonth = {};
  let yearlyWasteData;
  const wasteWeightByMonth = {};

  //Create the yearly Waste data for all the offices
  const groupedByWasteCategory = data.reduce((acc, item) => {
    const normalizedSecondaryCategory = normalizeCategory(
      item.secondaryCategory
    );

    if (!acc[normalizedSecondaryCategory]) {
      acc[normalizedSecondaryCategory] = {
        totalEmissions: 0,
        totalWeight: 0,
        // datalines: 0,
      };
    }

    acc[normalizedSecondaryCategory].totalEmissions += item.totalEmissions;
    if (item.secondaryEmissionFactor !== 0) {
      acc[normalizedSecondaryCategory].totalWeight +=
        (item.totalEmissions / item.secondaryEmissionFactor) * 1000;
    }

    return acc;
  }, {});
  // console.log(groupedByWasteCategory);
  yearlyWasteData = Object.entries(groupedByWasteCategory).map(
    ([category, values]) => ({
      id: category,
      label: category,
      value: values.totalEmissions,
      weight: values.totalWeight,
      color: getColorForCategory(category),
      // datalines: values.datalines,
    })
  );

  // Create yearly Waste data for each office

  const groupedByOfficeAndCategory = data.reduce((acc, item) => {
    const normalizedSecondaryCategory = normalizeCategory(
      item.secondaryCategory
    );
    if (!acc[item.officeId]) {
      acc[item.officeId] = {};
    }
    if (!acc[item.officeId][normalizedSecondaryCategory]) {
      acc[item.officeId][normalizedSecondaryCategory] = {
        totalEmissions: 0,
        totalWeight: 0,
      };
    }
    acc[item.officeId][normalizedSecondaryCategory].totalEmissions +=
      item.totalEmissions;
    acc[item.officeId][normalizedSecondaryCategory].totalWeight +=
      (item.totalEmissions / item.secondaryEmissionFactor) * 1000;

    return acc;
  }, {});

  const yearlyWasteDataByOffice = Object.entries(
    groupedByOfficeAndCategory
  ).map(([officeId, categories]) => ({
    officeId,
    categories: Object.entries(categories).map(([category, values]) => ({
      id: category,
      label: category,
      value: values.totalEmissions,
      weight: values.totalWeight,
      color: getColorForCategory(category),
    })),
  }));

  data.forEach((entry) => {
    // if(entry.secondaryCategory === "Recycling"){
    //   console.log(entry)
    // }

    const month = new Date(entry.date).toLocaleString("en-us", {
      month: "long",
    });
    const normalizedSecondaryCategory = normalizeCategory(
      entry.secondaryCategory
    );

    if (!wasteEmissionsByMonth[month]) {
      wasteEmissionsByMonth[month] = { month: month };
      wasteCategories.forEach((category) => {
        wasteEmissionsByMonth[month][category] = 0;
        wasteEmissionsByMonth[month][`${category}Color`] =
          getColorForCategory(category);
      });
    }

    if (!wasteWeightByMonth[month]) {
      wasteWeightByMonth[month] = { month: month };
      wasteCategories.forEach((category) => {
        wasteWeightByMonth[month][category] = 0;
        wasteWeightByMonth[month][`${category}Color`] =
          getColorForCategory(category);
      });
    }

    if (!wasteEmissionsByMonth[month][normalizedSecondaryCategory]) {
      wasteEmissionsByMonth[month][normalizedSecondaryCategory] = 0;
    }
    wasteEmissionsByMonth[month][normalizedSecondaryCategory] +=
      entry.totalEmissions;

    if (!wasteWeightByMonth[month][normalizedSecondaryCategory]) {
      wasteWeightByMonth[month][normalizedSecondaryCategory] = 0;
      // console.log(wasteWeightByMonth[month]);
    }
    wasteWeightByMonth[month][normalizedSecondaryCategory] =
      wasteWeightByMonth[month][normalizedSecondaryCategory] +
      (entry.totalEmissions / entry.secondaryEmissionFactor) * 1000;
  });

  const wasteEmissionsByMonthAndOffice = {};
  const wasteWeightByMonthAndOffice = {};

  data.forEach((entry) => {
    const month = new Date(entry.date).toLocaleString("en-us", {
      month: "long",
    });

    if (!wasteEmissionsByMonthAndOffice[entry.officeId]) {
      wasteEmissionsByMonthAndOffice[entry.officeId] = {};
    }
    if (!wasteEmissionsByMonthAndOffice[entry.officeId][month]) {
      wasteEmissionsByMonthAndOffice[entry.officeId][month] = 0;
    }
    wasteEmissionsByMonthAndOffice[entry.officeId][month] +=
      entry.totalEmissions;

    if (!wasteWeightByMonthAndOffice[entry.officeId]) {
      wasteWeightByMonthAndOffice[entry.officeId] = {};
    }
    if (!wasteWeightByMonthAndOffice[entry.officeId][month]) {
      wasteWeightByMonthAndOffice[entry.officeId][month] = 0;
    }
    wasteWeightByMonthAndOffice[entry.officeId][month] +=
      (entry.totalEmissions / entry.secondaryEmissionFactor) * 1000;
  });

  const monthsOrder = getMonthsOrder(activeUser.selectedMeasurementYearType);

  const monthlyWasteEmissionData = monthsOrder.map((month) => {
    return (
      wasteEmissionsByMonth[month] || {
        month: month,
        ...Object.fromEntries(wasteCategories.map((category) => [category, 0])),
        ...Object.fromEntries(
          wasteCategories.map((category) => [
            `${category}Color`,
            getColorForCategory(category),
          ])
        ),
      }
    );
  });

  const monthlyWasteWeightData = monthsOrder.map((month) => {
    return (
      wasteWeightByMonth[month] || {
        month: month,
        ...Object.fromEntries(wasteCategories.map((category) => [category, 0])),
        ...Object.fromEntries(
          wasteCategories.map((category) => [
            `${category}Color`,
            getColorForCategory(category),
          ])
        ),
      }
    );
  });

  const wasteDataLineGraph = Object.entries(wasteEmissionsByMonthAndOffice).map(
    ([officeId, emissionsByMonth]) => {
      const monthlyData = monthsOrder.map((month) => {
        return {
          x: month,
          y: emissionsByMonth[month] || 0,
          weight: wasteWeightByMonthAndOffice[officeId][month] || 0,
        };
      });
      return {
        id: officeId,
        color: "hsl(278, 70%, 50%)", // You can change the color logic if needed
        data: monthlyData,
      };
    }
  );

  return {
    yearlyWasteData,
    yearlyWasteDataByOffice,
    monthlyWasteEmissionData,
    monthlyWasteWeightData,
    wasteDataLineGraph,
  };
};

export const fetchEmissionsFromWaterCalculator = async (startDate, endDate) => {
  const activeUser = JSON.parse(getActiveUserData());

  // For water this will just return category
  function normalizeCategory(category) {
    return category;
  }

  const fullData = await getUnifiedDateFromStore();

  let data = filter(fullData, (item) => {
    return (
      item.dataSource === "Form" &&
      item.primaryCategory === "Water" &&
      item.date >= startDate &&
      item.date <= endDate
    );
  });

  // console.log("Full water data", data);

  const wasteEmissionsByMonth = {};
  let yearlyWasteData;
  const wasteWeightByMonth = {};

  //Create the yearly Waste data for all the offices
  const groupedByWasteCategory = data.reduce((acc, item) => {
    const normalizedSecondaryCategory = normalizeCategory(
      item.secondaryCategory
    );

    if (!acc[normalizedSecondaryCategory]) {
      acc[normalizedSecondaryCategory] = {
        totalEmissions: 0,
        totalWeight: 0,
        // datalines: 0,
      };
    }

    acc[normalizedSecondaryCategory].totalEmissions += item.totalEmissions;
    if (item.secondaryEmissionFactor !== 0) {
      acc[normalizedSecondaryCategory].totalWeight +=
        (item.totalEmissions / item.secondaryEmissionFactor) * 1000;
    }

    return acc;
  }, {});
  // console.log(groupedByWasteCategory);
  yearlyWasteData = Object.entries(groupedByWasteCategory).map(
    ([category, values]) => ({
      id: category,
      label: category,
      value: values.totalEmissions,
      weight: values.totalWeight,
      color: getColorForCategory(category),
      // datalines: values.datalines,
    })
  );

  // Create yearly Waste data for each office

  const groupedByOfficeAndCategory = data.reduce((acc, item) => {
    const normalizedSecondaryCategory = normalizeCategory(
      item.secondaryCategory
    );
    if (!acc[item.officeId]) {
      acc[item.officeId] = {};
    }
    if (!acc[item.officeId][normalizedSecondaryCategory]) {
      acc[item.officeId][normalizedSecondaryCategory] = {
        totalEmissions: 0,
        totalWeight: 0,
      };
    }
    acc[item.officeId][normalizedSecondaryCategory].totalEmissions +=
      item.totalEmissions;
    acc[item.officeId][normalizedSecondaryCategory].totalWeight +=
      (item.totalEmissions / item.secondaryEmissionFactor) * 1000;

    return acc;
  }, {});

  const yearlyWasteDataByOffice = Object.entries(
    groupedByOfficeAndCategory
  ).map(([officeId, categories]) => ({
    officeId,
    categories: Object.entries(categories).map(([category, values]) => ({
      id: category,
      label: category,
      value: values.totalEmissions,
      weight: values.totalWeight,
      color: getColorForCategory(category),
    })),
  }));

  data.forEach((entry) => {
    // if(entry.secondaryCategory === "Recycling"){
    //   console.log(entry)
    // }

    const month = new Date(entry.date).toLocaleString("en-us", {
      month: "long",
    });
    const normalizedSecondaryCategory = normalizeCategory(
      entry.secondaryCategory
    );

    if (!wasteEmissionsByMonth[month]) {
      wasteEmissionsByMonth[month] = { month: month };
      wasteCategories.forEach((category) => {
        wasteEmissionsByMonth[month][category] = 0;
        wasteEmissionsByMonth[month][`${category}Color`] =
          getColorForCategory(category);
      });
    }

    if (!wasteWeightByMonth[month]) {
      wasteWeightByMonth[month] = { month: month };
      wasteCategories.forEach((category) => {
        wasteWeightByMonth[month][category] = 0;
        wasteWeightByMonth[month][`${category}Color`] =
          getColorForCategory(category);
      });
    }

    if (!wasteEmissionsByMonth[month][normalizedSecondaryCategory]) {
      wasteEmissionsByMonth[month][normalizedSecondaryCategory] = 0;
    }
    wasteEmissionsByMonth[month][normalizedSecondaryCategory] +=
      entry.totalEmissions;

    if (!wasteWeightByMonth[month][normalizedSecondaryCategory]) {
      wasteWeightByMonth[month][normalizedSecondaryCategory] = 0;
      // console.log(wasteWeightByMonth[month]);
    }
    wasteWeightByMonth[month][normalizedSecondaryCategory] =
      wasteWeightByMonth[month][normalizedSecondaryCategory] +
      (entry.totalEmissions / entry.secondaryEmissionFactor) * 1000;
  });

  const wasteEmissionsByMonthAndOffice = {};
  const wasteWeightByMonthAndOffice = {};

  data.forEach((entry) => {
    const month = new Date(entry.date).toLocaleString("en-us", {
      month: "long",
    });

    if (!wasteEmissionsByMonthAndOffice[entry.officeId]) {
      wasteEmissionsByMonthAndOffice[entry.officeId] = {};
    }
    if (!wasteEmissionsByMonthAndOffice[entry.officeId][month]) {
      wasteEmissionsByMonthAndOffice[entry.officeId][month] = 0;
    }
    wasteEmissionsByMonthAndOffice[entry.officeId][month] +=
      entry.totalEmissions;

    if (!wasteWeightByMonthAndOffice[entry.officeId]) {
      wasteWeightByMonthAndOffice[entry.officeId] = {};
    }
    if (!wasteWeightByMonthAndOffice[entry.officeId][month]) {
      wasteWeightByMonthAndOffice[entry.officeId][month] = 0;
    }
    wasteWeightByMonthAndOffice[entry.officeId][month] +=
      (entry.totalEmissions / entry.secondaryEmissionFactor) * 1000;
  });

  const monthsOrder = getMonthsOrder(activeUser.selectedMeasurementYearType);

  const monthlyWasteEmissionData = monthsOrder.map((month) => {
    return (
      wasteEmissionsByMonth[month] || {
        month: month,
        ...Object.fromEntries(wasteCategories.map((category) => [category, 0])),
        ...Object.fromEntries(
          wasteCategories.map((category) => [
            `${category}Color`,
            getColorForCategory(category),
          ])
        ),
      }
    );
  });

  const monthlyWasteWeightData = monthsOrder.map((month) => {
    return (
      wasteWeightByMonth[month] || {
        month: month,
        ...Object.fromEntries(wasteCategories.map((category) => [category, 0])),
        ...Object.fromEntries(
          wasteCategories.map((category) => [
            `${category}Color`,
            getColorForCategory(category),
          ])
        ),
      }
    );
  });

  const wasteDataLineGraph = Object.entries(wasteEmissionsByMonthAndOffice).map(
    ([officeId, emissionsByMonth]) => {
      const monthlyData = monthsOrder.map((month) => {
        return {
          x: month,
          y: emissionsByMonth[month] || 0,
          weight: wasteWeightByMonthAndOffice[officeId][month] || 0,
        };
      });
      return {
        id: officeId,
        color: "hsl(278, 70%, 50%)", // You can change the color logic if needed
        data: monthlyData,
      };
    }
  );

  let yearlyWaterData = yearlyWasteData;
  let yearlyWaterDataByOffice = yearlyWasteDataByOffice;
  let monthlyWaterEmissionData = monthlyWasteEmissionData;
  let monthlyWaterWeightData = monthlyWasteWeightData;
  let waterDataLineGraph = wasteDataLineGraph;

  // return {
  //   yearlyWaterData,
  //   yearlyWaterDataByOffice,
  //   monthlyWaterEmissionData,
  //   monthlyWaterWeightData,
  //   waterDataLineGraph,
  // };

  return {
    yearlyWasteData,
    yearlyWasteDataByOffice,
    monthlyWasteEmissionData,
    monthlyWasteWeightData,
    wasteDataLineGraph,
  };
};

export const fetchEmissionsBySupplier2 = async (startDate, endDate) => {
  const fullData = await getUnifiedDateFromStore();
  const activeUser = JSON.parse(getActiveUserData());

  // Filter data based on date range and dataSource
  const data = fullData.filter((item) => {
    return (
      item.dataSource !== "Form" &&
      item.scope === "SCOPE3" &&
      new Date(item.date) >= new Date(startDate) &&
      new Date(item.date) <= new Date(endDate)
    );
  });

  // Group data by supplier
  const grouped = data.reduce((acc, entry) => {
    if (!acc[entry.contactName]) {
      acc[entry.contactName] = [];
    }
    acc[entry.contactName].push(entry);
    return acc;
  }, {});

  const supplierTotals = {};
  const monthlyAggregates = {};
  const supplierDataLines = {};

  // Calculate total emissions per supplier and monthly aggregates
  for (const contactName in grouped) {
    grouped[contactName].forEach((entry) => {
      const monthYear = new Date(entry.date).toLocaleString("en-us", {
        month: "long",
        year: "numeric",
      });

      if (!supplierTotals[contactName]) {
        supplierTotals[contactName] = 0;
      }
      supplierTotals[contactName] += entry.totalEmissions;

      if (!supplierDataLines[contactName]) {
        supplierDataLines[contactName] = 0;
      }
      supplierDataLines[contactName] += 1;

      if (!monthlyAggregates[monthYear]) {
        monthlyAggregates[monthYear] = {};
      }

      if (!monthlyAggregates[monthYear][contactName]) {
        monthlyAggregates[monthYear][contactName] = {
          datalines: 0,
          totalEmissions: 0,
        };
      }
      monthlyAggregates[monthYear][contactName].datalines += 1;
      monthlyAggregates[monthYear][contactName].totalEmissions +=
        entry.totalEmissions;
    });
  }

  // Identify top 10 suppliers
  const topSuppliers = Object.entries(supplierTotals)
    .sort((a, b) => b[1] - a[1]) // Sort by total emissions descending
    .slice(0, 10)
    .map((entry) => entry[0]);

  // Calculate totals for "Others"
  const others = Object.entries(supplierTotals)
    .filter(([supplier]) => !topSuppliers.includes(supplier))
    .reduce(
      (acc, [supplier, value]) => {
        acc.value += value;
        acc.datalines += supplierDataLines[supplier];
        return acc;
      },
      { value: 0, datalines: 0 }
    );

  // Sorting months based on selectedMeasurementYearType
  const monthsOrder = getMonthsOrder(activeUser.selectedMeasurementYearType);
  // Prepare the final data structure
  const monthlyData = monthsOrder.map((month) => ({
    month,
    ...Object.fromEntries(topSuppliers.map((supplier) => [supplier, 0])),
    Others: 0,
  }));

  const yearlyData = [
    ...topSuppliers.map((supplier) => ({
      id: supplier,
      label: supplier,
      value: supplierTotals[supplier],
      datalines: supplierDataLines[supplier],
    })),
    {
      id: "Others",
      label: "Others",
      value: others.value,
      datalines: others.datalines,
    },
  ];

  for (const monthYear in monthlyAggregates) {
    const [month, year] = monthYear.split(" ");
    const monthIndex = monthsOrder.indexOf(month);

    if (monthIndex !== -1) {
      for (const supplier in monthlyAggregates[monthYear]) {
        if (topSuppliers.includes(supplier)) {
          monthlyData[monthIndex][supplier] =
            monthlyAggregates[monthYear][supplier].totalEmissions;
        } else {
          monthlyData[monthIndex].Others +=
            monthlyAggregates[monthYear][supplier].totalEmissions;
        }
      }
    }
  }

  // Calculate the number of unique suppliers
  const supplierNumber = Object.keys(grouped).length;

  return { monthlyData, yearlyData, supplierNumber };
};

export const fetchEmissionsByScope = async (startDate, endDate) => {
  const fullData = await getUnifiedDateFromStore();
  const activeUser = JSON.parse(getActiveUserData());

  const filteredData = fullData.filter((item) => {
    return (
      new Date(item.date) >= new Date(startDate) &&
      new Date(item.date) <= new Date(endDate)
    );
  });

  const byDataSource = groupBy(filteredData, "dataSource");

  // Filter out data where secondary category is 'market based' from byDataSource
  for (const dataSource in byDataSource) {
    byDataSource[dataSource] = byDataSource[dataSource].filter(
      (item) => item.secondaryCategory !== "market based"
    );
  }

  const countOccurrences = (dataSourceObject, key) => {
    return dataSourceObject[key] ? dataSourceObject[key].length : 0;
  };
  const countMYOB = countOccurrences(byDataSource, "MYOB");
  const countXero = countOccurrences(byDataSource, "Xero");
  const countForm = countOccurrences(byDataSource, "Form");
  const countBill = countOccurrences(byDataSource, "Bill");
  const countCSV = countOccurrences(byDataSource, "CSV");

  // Adjust totalEmissions if dataSource is "Form"
  const adjustedData = filteredData.map((item) => {
    if (item.dataSource === "Form") {
      item.totalEmissions = item.totalEmissions;
    }
    return item;
  });

  const monthsOrder = getMonthsOrder(activeUser.selectedMeasurementYearType);

  // Initialize groupedData with months
  const groupedData = monthsOrder.reduce((acc, month) => {
    acc[month] = {
      scope1: 0,
      scope2ByOffice: {}, // Will populate with officeIds
      scope2: {
        total: 0,
        gas: 0,
        electricity: 0,
        locationBasedElectricity: 0.0,
        marketBasedElectricity: 0.0,
        electricityConsumption: 0.0,
      },
      scope3: 0,
    };
    return acc;
  }, {});

  // Totals for each scope
  let totalScope1 = 0;
  let totalScope2 = 0;
  let totalScope3 = 0;
  let totalLines = 0;

  adjustedData.forEach((item) => {
    const month = new Date(item.date).toLocaleString("en-us", {
      month: "long",
    });

    if (item.scope === "SCOPE1") {
      groupedData[month].scope1 += item.totalEmissions;
      totalScope1 += item.totalEmissions;
    }

    if (item.scope === "SCOPE2") {
      const officeId = item.officeId;

      // Ensure officeId exists in groupedData[month].scope2
      if (!groupedData[month].scope2ByOffice[officeId]) {
        groupedData[month].scope2ByOffice[officeId] = {
          total: 0,
          gas: 0,
          electricity: 0,
          locationBasedElectricity: 0,
          marketBasedElectricity: 0,
          electricityConsumption: 0,
        };
      }

      // Aggregate Scope 2 emissions
      const scope2OfficeData = groupedData[month].scope2ByOffice[officeId];

      if (
        item.primaryCategory === "Stationary energy (gaseous fuels)" ||
        item.secondaryCategory === "Natural gas"
      ) {
        groupedData[month].scope2.gas += item.totalEmissions;
        scope2OfficeData.gas += item.totalEmissions;
      } else if (
        item.primaryCategory === "Electricity" ||
        item.secondaryCategory === "Electricity supply"
      ) {
        if (
          item.secondaryCategory ===
          "Electricity supply from the grid full scope"
        ) {
          groupedData[month].scope2.electricity += item.totalEmissions;
          groupedData[month].scope2.locationBasedElectricity +=
            item.totalEmissions;
          groupedData[month].scope2.electricityConsumption += item.consumption;
          scope2OfficeData.electricity += item.totalEmissions;
          scope2OfficeData.locationBasedElectricity += item.totalEmissions;
          scope2OfficeData.electricityConsumption += item.consumption;
        } else if (item.secondaryCategory === "market based") {
          groupedData[month].scope2.marketBasedElectricity +=
            item.totalEmissions;
          scope2OfficeData.marketBasedElectricity += item.totalEmissions;
        } else {
          groupedData[month].scope2.electricity += item.totalEmissions;
          groupedData[month].scope2.locationBasedElectricity +=
            item.totalEmissions;
          scope2OfficeData.electricity += item.totalEmissions;
          scope2OfficeData.locationBasedElectricity += item.totalEmissions;
        }
      }

      if (item.secondaryCategory !== "market based") {
        groupedData[month].scope2.total += item.totalEmissions;
        scope2OfficeData.total += item.totalEmissions;
        totalScope2 += item.totalEmissions;
      }
    }

    if (item.scope === "SCOPE3") {
      groupedData[month].scope3 += item.totalEmissions;
      totalScope3 += item.totalEmissions;
    }

    if (item.secondaryCategory !== "market based") {
      totalLines++;
    }
  });

  const result = monthsOrder.map((month) => ({
    month,
    ...groupedData[month],
  }));

  return {
    monthlyData: result,
    totals: {
      totalScope1,
      totalScope2,
      totalScope3,
      totalLines,
      lines: {
        countIntegration: countMYOB + countXero,
        countForm,
        countBill,
        countCSV,
      },
    },
  };
};

export const fetchEmissionsByCategory = async (startDate, endDate) => {
  const fullData = await getUnifiedDateFromStore();
  const activeUser = JSON.parse(getActiveUserData());
  let data = fullData.filter((item) => {
    return item.date >= startDate && item.date <= endDate;
  });

  // If dataSource is 'Form', set primary category differently and adjust emissions
  data.forEach((item) => {
    if (item.dataSource === "Form") {
      item.totalEmissions = item.totalEmissions;

      if (
        item.primaryCategory === "Flight" ||
        item.primaryCategory === "Transport (air)"
      ) {
        item.primaryCategory = "Flights";
      }
      if (
        item.primaryCategory === "Staff" ||
        item.primaryCategory === "Transport (land and sea)"
      ) {
        item.primaryCategory = "Employee Commute";
      }
    }
  });

  //filter out data where secondary category is 'market based'
  data = data.filter((item) => item.secondaryCategory !== "market based");

  // Group by primary category
  const groupedByCategory = data.reduce((acc, item) => {
    if (!acc[item.primaryCategory]) {
      acc[item.primaryCategory] = {
        totalEmissions: 0,
        datalines: 0,
      };
    }
    acc[item.primaryCategory].totalEmissions += item.totalEmissions;
    acc[item.primaryCategory].datalines += 1;
    return acc;
  }, {});

  const yearlyData = Object.entries(groupedByCategory).map(
    ([category, values]) => ({
      id: category,
      label: category,
      value: values.totalEmissions,
      datalines: values.datalines,
    })
  );

  // Initialize months array
  const monthsOrder = getMonthsOrder(activeUser.selectedMeasurementYearType);
  // Initialize monthly data structure with all months
  const monthlyData = monthsOrder.map((month) => ({
    month,
    categories: {},
  }));

  // Fill monthly data with emissions
  data.forEach((item) => {
    const itemDate = new Date(item.date);
    const monthIndex = getCorrectMonthIndex(
      itemDate,
      activeUser.selectedMeasurementYearType
    );
    const month = monthsOrder[monthIndex];
    const monthData = monthlyData.find((m) => m.month === month);

    if (!monthData.categories[item.primaryCategory]) {
      monthData.categories[item.primaryCategory] = 0;
    }
    monthData.categories[item.primaryCategory] += item.totalEmissions;
  });

  return {
    yearlyData,
    monthlyData,
  };
};
