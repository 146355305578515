import React, { useEffect, useState } from "react";
import Projects from "./project/Projects";
import ProjectProvider from "./ProjectProvider";
import { getProjectsList } from "./projectAPI";
import PageHeader from "../common/PageHeader";
import ShoppingCart from "./cart/ShoppingCart";
import TotalEmissionsController from "./TotalEmissionsController";

const Index = () => {
  const [projectsList, setProjectsList] = useState([]);
  const getProjects = async () => {
    const projectsList = await getProjectsList();
    setProjectsList(projectsList);
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <ProjectProvider>
      <PageHeader
        title="Offset Projects"
        description={`Offset projects are projects that reduce, avoid or remove emissions from the atmosphere. They are used to compensate for emissions that cannot be reduced further.`}
        className="mb-3"
      ></PageHeader>
      <br />
      <ShoppingCart />
      <br />
      <Projects projectsList={projectsList} />
    </ProjectProvider>
  );
};

export default Index;
