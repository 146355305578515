import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faMagnifyingGlassChart,
  faChartPie,
  faFileAlt,
  faCalculator,
  faLightbulb,
  faFileExcel,
  faNewspaper,
  faTree,
  faChartLine,
  faHandshake,
  faAward,
  faTable,
  faLock,
  faEnvelopesBulk,
  faCalendarPlus,
  faPlug,
  faStar,
  faCog,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { faTrello } from "@fortawesome/free-brands-svg-icons";
import Flex from "../../../components/common/Flex";
import SoftBadge from "../../../components/common/SoftBadge";

const icons = {
  faHouse,
  faMagnifyingGlassChart,
  faChartPie,
  faFileAlt,
  faCalculator,
  faLightbulb,
  faFileExcel,
  faNewspaper,
  faTree,
  faChartLine,
  faHandshake,
  faAward,
  faTable,
  faLock,
  faEnvelopesBulk,
  faCalendarPlus,
  faTrello,
  faPlug,
  faStar,
  faCog,
  faEnvelope,
};

const NavbarVerticalMenuItem = ({ route }) => {
  return (
    <Flex alignItems="center">
      {route.icon && (
        <span className="nav-link-icon">
          <FontAwesomeIcon icon={icons[route.icon]} className="fs-0" />
        </span>
      )}
      <span className="nav-link-text ps-1">{route.name}</span>
      {route.badge && (
        <SoftBadge pill bg={route.badge.type} className="ms-2">
          {route.badge.text}
        </SoftBadge>
      )}
    </Flex>
  );
};

// prop-types
const routeShape = {
  active: PropTypes.bool,
  name: PropTypes.string.isRequired,
  to: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};
routeShape.children = PropTypes.arrayOf(PropTypes.shape(routeShape));
NavbarVerticalMenuItem.propTypes = {
  route: PropTypes.shape(routeShape).isRequired,
};

export default React.memo(NavbarVerticalMenuItem);
