export const faqs = [
  {
    id: 0,
    title: `How does Falcon's pricing work?`,
    description: `The free version of Falcon is available for teams of up to 15 people. Our Falcon Premium plans of 15 or fewer qualify for a small team discount. As your team grows to 20 users or more and gets more value out of Falcon, you'll get closer to our standard monthly price per seat. The price of a paid Falcon plan is tiered, starting in groups of 5 and 10 users, based on the number of people you have in your Team or Organization.`
  },
  {
    id: 1,
    title: 'What forms of payment do you accept?',
    description: `You can purchase Falcon with any major credit card. For annual subscriptions, we can issue an invoice payable by bank transfer or check. Please contact us to arrange an invoice purchase. Monthly purchases must be paid for by credit card.`
  },
  {
    id: 2,
    title: 'We need to add more people to our team. How will that be billed?',
    description: `You can add as many new teammates as you need before changing your subscription. We will subsequently ask you to correct your subscription to cover current usage.`
  },
  {
    id: 3,
    title: `How secure is Falcon?`,
    description: `Protecting the data you trust to Falcon is our first priority. Falcon uses physical, procedural, and technical safeguards to preserve the integrity and security of your information. We regularly back up your data to prevent data loss and aid in recovery. Additionally, we host data in secure SSAE 16 / SOC1 certified data centers, implement firewalls and access restrictions on our servers to better protect your information, and work with third party security researchers to ensure our practices are secure.`
  },
  {
    id: 4,
    title: `Will I be charged sales tax?`,
    description: `As of May 2016, state and local sales tax will be applied to fees charged to customers with a billing address in the State of New York.`
  },
  {
    id: 5,
    title: `Do you offer discounts?`,
    description: `We've built in discounts at each tier for teams smaller than 15 members. We also offer two months for free in exchange for an annual subscription.`
  },
  {
    id: 6,
    title: `Do you offer academic pricing?`,
    description: `We're happy to work with student groups using Falcon. Contact Us`
  },
  {
    id: 7,
    title: `Is there an on-premise version of Falcon?`,
    description: `We are passionate about the web. We don't plan to offer an internally hosted version of Falcon. We hope you trust us to provide a robust and secure service so you can do the work only your team can do.`
  },
  {
    id: 8,
    title: `What is your refund policy?`,
    description: `We do not offer refunds apart from exceptions listed below. If you cancel your plan before the next renewal cycle, you will retain access to paid features until the end of your subscription period. When your subscription expires, you will lose access to paid features and all data associated with those features. Exceptions to our refund policy: canceling within 48 hours of initial charge will result in a full refund. If you cancel within this timeframe, you will lose access to paid features and associated data immediately upon canceling.`
  }
];

export const faqAccordions = [
  {
    id: 0,
    title: 'How do I disconnect from Xero?',
    description:
      <span>Please go to our <a href='https://app.netnada.com.au/welcome/overview' target='_blank' rel="noreferrer">overview</a> page to disconnect from Xero and click on the 'Disconnect from Xero' button.</span>
  },
  {
    id: 1,
    title: 'When and how does data sync occur ? Is it manual, automatic, on login, real-time. Are there any settings related to synchronizing?',
    description:
      <span>The Xero data sync occurs automatically every time the user logs into our application via Xero ('Sign In Xero' button on Sign-In/Sign-up screens) or if the user tries to connect to Xero via the 'Connect to Xero' button on the <a href="https://app.netnada.com.au/welcome/overview" target="_blank" rel="noreferrer">overview</a> screen.</span>
  },
  {
    id: 2,
    title: 'What does incorporating data from Xero in your app look like?',
    description:
      <span>NetNada uses accounting data to accelerate Xero users' understanding of their carbon footprint. Accessing structured data from journals or charts of accounts enables NetNada to attribute carbon emission factors to transactions and perform a complete business carbon accounting calculation. Moreover, a Xero integration allows a more frequent calculation of emissions and ensures the business can have an up-to-date carbon footprint.</span>
  },
  {
    id: 3,
    title: 'What doesn’t your integration do?',
    description:
      <span><p>NetNada is a “read-only” integration. This means that your data inside Xero is not deleted, modified, or updated in any way by using our integration.</p><p>Using the Xero integration does not automatically make your business carbon neutral. While using our software and Xero integration can facilitate your path to carbon neutrality, NetNada helps understand your business emissions and reduce them before purchasing carbon offsets.</p></span>
  },
  {
    id: 4,
    title: 'What happens to my Xero data if I cancel my subscription with NetNada?',
    description:
      <span>The synced Xero data is archived in our database if the user decides to connect to Xero again. Read our <a href="https://www.netnada.com.au/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a> for more information.</span>
  },
  {
    id: 5,
    title: 'What data do we sync from Xero?',
    description:
      <span>We sync only the customer’s organisation details and accounting journals. Read more about accounting integration scopes <a href="https://developer.xero.com/documentation/api/accounting/journals" target="_blank" rel="noreferrer">here</a></span>
  },
  {
    id: 6,
    title: 'Where do we store the Xero data?',
    description:
      <span>We store your data in a cloud-hosted database secured by Google security and <a href="https://firebase.google.com/support/privacy" target="_blank" rel="noreferrer">Privacy Policies</a></span>
  }
];
