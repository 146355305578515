export const flightFlatFileWorkbook = {
  name: "Flight Data",
  sheets: [
    {
      name: "Flight Data(Default)",
      slug: "flightDataDefault",
      fields: [
        // {
        //   key: "departureCity",
        //   type: "string",
        //   label: "Departure City",
        //   constraints: [],
        // },
        {
          key: "departureCode",
          type: "string",
          label: "Departure Code",
          description: "Enter a 3-letter flight code in capital letters (e.g., LAX, JFK, CDG).",
          constraints: [
            {
              type: "required",
            },
          ],
        },
        // {
        //   key: "destinationCity",
        //   type: "string",
        //   label: "Destination City",
        //   constraints: [],
        // },
        {
          key: "destinationCode",
          type: "string",
          label: "Destination Code",
          description: "Enter a 3-letter flight code in capital letters (e.g., LAX, JFK, CDG).",
          constraints: [
            {
              type: "required",
            },
          ],
        },
        {
          key: "numberOfOnewayFlights",
          type: "number",
          label: "Number of one-way flights",
          constraints: [
            {
              type: "required",
            },
          ],
        },
        {
          key: "class",
          type: "string",
          label: "Class",
          // constraints: [
          //   {
          //     type: "required",
          //   },
          // ],
        },
        {
          key: "timePeriodFrom",
          type: "string",
          label: "Departure Date (click for formats allowed)",
          description: "Enter the date in one of the following formats: YYYY/MM/DD, YYYY-MM-DD, YYYY.MM.DD, DD/MM/YYYY, DD-MM-YYYY, or DD.MM.YYYY.",
          constraints: [
            {
              type: "required",
            },
          ],
        },
        // {
        //   key: "timePeriodTo",
        //   type: "string",
        //   label: "Time Period To(DD-MMM-YYYY))",
        //   constraints: [
        //     {
        //       type: "required",
        //     },
        //   ],
        // },
      ],
    },
  ],
  actions: [
    {
      label: "Submit",
      operation: "flight:submit",
      description: "Would you like to submit your flight data?",
      mode: "foreground",
      primary: true,
      confirm: true,
      constraints: [{ type: 'hasAllValid'},{ type: 'hasData' }],
    },
    {
      label: "Cancel",
      operation: "flight:cancel",
      description: "Would you like to cancel your flight data?",
      mode: "foreground",
      primary: false,
      confirm: true,
    },
  ],
};

