import { useSpace } from "@flatfile/react";
import { useEffect, useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";

const FlatFileUploader = ({ metadata, config }) => {
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    sessionStorage.setItem("modified", "false");
  }, []);

  function handleClose() {
    toast(`File Uploaded Successfully`);
    sessionStorage.setItem("fileUploadCompleted", true);
    window.location = config.redirectUrl;
  }

  const space = useSpace({
    name: "Data Uploader",
    publishableKey: process.env.REACT_APP_FLATFILE_PUBLISHABLE_KEY,
    environmentId: process.env.REACT_APP_FLATFILE_ENVIRONMENT_ID,
    sidebarConfig: {
      showDataChecklist: false,
      showSidebar: false,
    },
    workbook: config.workbook,
    listener: config.listener,
    showLoadingState: true,
    loading: <CustomLoadingComponent />,
    spaceInfo: metadata,
    themeConfig: {
      root: {
        primaryColor: "#2c7be5",
      },
    },
    closeSpace: {
      operation: config.operation,
      onClose: handleClose,
    },
  });

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="xl"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{config.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 0,
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {space}
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default FlatFileUploader;

const CustomLoadingComponent = () => (
  <div
    className="d-flex align-items-center justify-content-center"
    style={{ minHeight: "100px" }}
  >
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
    <ToastContainer />
  </div>
);
