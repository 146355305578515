import React, { useEffect } from "react";
import {
  Button,
  Modal,
  Form,
  Spinner,
  ProgressBar,
  Row,
  Col,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faList, faStream } from "@fortawesome/free-solid-svg-icons";
import WidgetSectionTitle from "../../widgets/WidgetSectionTitle";
import { useState } from "react";
import AdvanceTableWrapper from "../common/advance-table/AdvanceTableWrapper";
import { Card } from "react-bootstrap";
import AdvanceTable from "../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../common/advance-table/AdvanceTableFooter";
import {
  deleteReportRequest,
  getReportRequests,
  updateReportRequest,
} from "./adminApi";
import SoftBadge from "../common/SoftBadge";
import Flex from "../common/Flex";
import { defaultStyles, FileIcon } from "react-file-icon";
import IconButton from "../common/IconButton";
import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import app from "../../firebase";
import AdvanceTableSearchBox from "../common/advance-table/AdvanceTableSearchBox";

const UploadReports = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [reportRequests, setReportRequests] = useState([]);
  const [showReportUploadModal, setShowReportUploadModal] = useState(false);
  const [businessId, setBusinessId] = useState(null);
  const [reportId, setReportId] = useState(null);

  const columns = [
    {
      accessor: "business_id",
      Header: "Business Id",
    },
    {
      accessor: "business_name",
      Header: "Business Name",
    },
    {
      accessor: "document_type",
      Header: "Document",
      headerProps: { className: "text-left" },
      cellProps: {
        className: "fs-0",
      },
      Cell: (rowData) => {
        const { document_type, document_name, document_url, report_status } =
          rowData.row.original;

        let lowerCaseDocumentType =
          document_type && document_type !== undefined
            ? document_type.toLowerCase()
            : "";
        return (
          <Flex alignItems="center" justifyContent="center">
            <div className="file-thumbnail">
              <FileIcon
                size={5}
                extension={lowerCaseDocumentType}
                type="image"
                {...defaultStyles[lowerCaseDocumentType]}
              />
            </div>
            {report_status === "InProgress" ? (
              <div className="ms-3 flex-shrink-1 flex-grow-1">
                {document_name}
              </div>
            ) : null}
            {report_status === "Completed" ? (
              <div className="ms-3 flex-shrink-1 flex-grow-1">
                <a href={document_url} target="_blank" rel="noreferrer">
                  {document_name}
                </a>
              </div>
            ) : null}
          </Flex>
        );
      },
    },
    {
      accessor: "report_status",
      Header: "Status",

      headerProps: { className: "text-center" },
      cellProps: {
        className: "fs-0",
      },
      Cell: (rowData) => {
        const { report_status } = rowData.row.original;
        return (
          <SoftBadge
            pill
            bg={report_status === "Completed" ? "success" : "warning"}
            className="d-block"
          >
            {report_status}
            <FontAwesomeIcon
              icon={report_status === "Completed" ? faCheck : faStream}
              transform="shrink-2"
              className="ms-1"
            />
          </SoftBadge>
        );
      },
    },
    {
      accessor: "created_at",
      Header: "Created At",
    },
    {
      accessor: "",
      Header: "Actions",
      Cell: (rowData) => {
        const { id, business_id, report_status } = rowData.row.original;
        return (
          <>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon={faArrowUpFromBracket}
              transform="shrink-3"
              onClick={() => handleOpen(id, business_id)}
            >
              Upload (.pdf)
            </IconButton>
          </>
        );
      },
    },
  ];

  async function getReports() {
    const response = await getReportRequests();
    setReportRequests(response);
    setIsLoading(false);
  }

  useEffect(() => {
    getReports();
  }, []);

  async function handleClose(isRefresh) {
    setShowReportUploadModal(false);
    if (isRefresh) {
      const response = await getReportRequests();
      setReportRequests(response);
    }
  }

  function handleOpen(reportId, businessId) {
    setBusinessId(businessId);
    setReportId(reportId);
    setShowReportUploadModal(true);
  }

  return (
    <>
      <WidgetSectionTitle
        icon={faList}
        title="Report Uploader"
        subtitle="User request for reports are here."
        transform="shrink-2"
        className="mb-4 mt-6"
      />
      {showReportUploadModal && (
        <ReportUploadModal
          data={{ reportId, businessId: businessId }}
          showModal={showReportUploadModal}
          handleClose={handleClose}
        />
      )}

      {isLoading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999, // Adjust the z-index as needed
          }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className="mb-3">
          <AdvanceTableWrapper
            columns={columns}
            data={reportRequests}
            selection
            sortable
            pagination
            perPage={7}
            rowCount={reportRequests.length}
          >
            <Card>
              <Card.Header>
                <Row className="flex-start-center mb-1">
                  <Col>
                    <AdvanceTableSearchBox table />
                  </Col>
                  <Col>
                    <UploadReportsHeader
                      table
                      data={reportRequests}
                      handleClose={handleClose}
                    />
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="p-0">
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="btn-reveal-trigger text-nowrap align-middle"
                  tableProps={{
                    size: "lg",
                    className: "fs--1 mb-0 overflow-hidden",
                  }}
                />
              </Card.Body>
              <Card.Footer>
                <AdvanceTableFooter
                  data={reportRequests}
                  rowCount={reportRequests.length}
                  table
                  rowInfo
                  navButtons
                />
              </Card.Footer>
            </Card>
          </AdvanceTableWrapper>
        </div>
      )}
    </>
  );
};

const ReportUploadModal = ({ showModal, handleClose, data }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(0);
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    if (!selectedFile) {
      console.log("File not selected");
      return;
    }

    const selectedFileName = selectedFile.name;
    const storage = getStorage(app);
    let filePath =
      "businesses/" + data.businessId + "/reports/" + selectedFile.name;
    const storageRef = ref(storage, filePath);
    const uploadTask = uploadBytesResumable(storageRef, selectedFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (error) => console.log(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (documentUrl) => {
          await updateReportRequest({ ...data, documentUrl, selectedFileName });
          handleClose(true);
        });
      }
    );
  }

  function handleSelectFile(uploadedFile) {
    setSelectedFile(uploadedFile);
  }

  return (
    <div>
      {" "}
      <Modal
        show={showModal}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Upload report(only PDF files accepted)
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formFileLg" className="mb-3">
              <Form.Control
                type="file"
                size="lg"
                accept=".pdf"
                onChange={(e) => handleSelectFile(e.target.files[0])}
                multiple
              />
            </Form.Group>
            <ProgressBar
              variant="warning"
              now={progress}
              label={`${progress}%`}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit">Upload</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

const UploadReportsHeader = ({ selectedRowIds, data, handleClose }) => {
  const [showDeleteQuestionModal, setShowDeleteQuestionModal] = useState(false);
  const [showDeleteLoadingModal, setShowDeleteLoadingModal] = useState(false);

  const handleDelete = async () => {
    setShowDeleteQuestionModal(false);
    setShowDeleteLoadingModal(true);
    // console.log("Delete", data, selectedRowIds);

    const selectedRows = Object.keys(selectedRowIds);
    let selectedReports = [];
    for (let i = 0; i < selectedRows.length; i++) {
      const index = parseInt(selectedRows[i]);
      const reportId = data[index].id;
      const reportUrl = data[index].document_url;
      selectedReports.push({ reportId, reportUrl });
    }
    // console.log("Delete", selectedReports);

    //delete selected files from firebase storage and then delete from db
    const storage = getStorage(app);
    for (let i = 0; i < selectedReports.length; i++) {
      const filePath = selectedReports[i].reportUrl;
      const storageRef = ref(storage, filePath);
      await deleteObject(storageRef);
    }

    //delete from db
    await deleteReportRequest(selectedReports);

    setShowDeleteLoadingModal(false);
    handleClose(true);
  };

  return (
    <>
      {showDeleteQuestionModal && (
        <DeleteQuestionModal
          showDeleteQuestionModal={showDeleteQuestionModal}
          setShowDeleteQuestionModal={setShowDeleteQuestionModal}
          handleDelete={handleDelete}
        />
      )}
      {showDeleteLoadingModal && <DeleteLoadingModal />}
      <Row className="flex-between-center">
        {/* <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
          <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
            Upload and view all user reports
          </h5>
        </Col> */}
        <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
          {Object.keys(selectedRowIds).length > 0 && (
            <div className="d-flex">
              <h5 className="fs-0 mb-0 text-nowrap py-2">
                {Object.keys(selectedRowIds).length >= 0
                  ? "You have selected " +
                    Object.keys(selectedRowIds).length +
                    " file(s)"
                  : "Selection Example"}
              </h5>
              <Button
                type="button"
                variant="falcon-default"
                size="sm"
                className="ms-2"
                onClick={() => {
                  setShowDeleteQuestionModal(true);
                }}
              >
                Delete
              </Button>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

//Ask user to confirm before deleting
const DeleteQuestionModal = ({
  showDeleteQuestionModal,
  setShowDeleteQuestionModal,
  handleDelete,
}) => {
  return (
    <Modal
      show={showDeleteQuestionModal}
      onHide={() => setShowDeleteQuestionModal(false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">Delete Selected Files</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete the selected files?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={() => setShowDeleteQuestionModal(false)}
        >
          Cancel
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

//Show loading modal while deleting
const DeleteLoadingModal = () => {
  return (
    <Modal show={true} centered>
      <Modal.Body>
        <div className="text-center">
          <Spinner animation="border" variant="primary" />
          <p className="mt-2">Deleting selected files...</p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UploadReports;
