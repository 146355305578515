import React from "react";
import PageHeader from "../common/PageHeader";

const EmissionsBoundariesHeader = () => {
  return (
    <PageHeader
      title="Emissions Boundaries"
      description={`For an organisation, the emissions boundary must include all relevant emissions
  sources (see relevance test below). This includes all emissions under the direct control
  or ownership of an organisation (e.g. stationary energy), as well as emissions that are
  a consequence of the organisation’s activities but outside of its direct ownership or
  control (e.g. waste, transport, consulting, and marketing ).`}
      className="mb-3"
    ></PageHeader>
  );
};

export default EmissionsBoundariesHeader;
