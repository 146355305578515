import { clearLoginData } from "../../helpers/store";
import {
  getAuthorizationHeader,
  getAuthorizationHeaderWithContentType,
} from "../../helpers/utils";

export const getInventoryProgress = async () => {
  const response = await fetch("/api/overview/get-inventory-progress", {
    method: "GET",
    headers: getAuthorizationHeaderWithContentType(),
    mode: "cors",
  });

  if (response.status === 200) {
    const data = await response.json();
    const inventoryProgress = data.data;
    return inventoryProgress;
  } else if (response.status === 401) {
    clearLoginData();
  } else {
  }
  return { status: "error", message: "Failed to get date filters" };
};

export const getStepsProgressByInventoryId = async (inventoryId, body) => {
  const response = await fetch(
    `/api/overview/get-steps-progress/${inventoryId}`,
    {
      method: "GET",
      headers: getAuthorizationHeaderWithContentType(),
      mode: "cors",
      // body: JSON.stringify(body),
    }
  );

  if (response.status === 200) {
    const data = await response.json();
    const progress = data.data;
    return progress;
  } else if (response.status === 401) {
    clearLoginData();
  } else {
  }
  return { status: "error", message: "Failed to get date filters" };
};

export const addMeasurementYear = async (body) => {
  const response = await fetch("/api/overview/add-measurement-year", {
    method: "POST",
    headers: getAuthorizationHeaderWithContentType(),
    body: JSON.stringify(body),
    mode: "cors",
  });

  if (response.status === 200) {
    const jsonData = await response.json();
    if (jsonData.data) {
      return {
        status: jsonData.status,
        message: jsonData.message,
        data: jsonData.data, // Return the entire data object
      };
    }
  } else if (response.status === 401) {
    clearLoginData();
  } else {
  }
  return { status: "error", message: "Failed to add baseline year" };
};

export const addEmissionBoundariesStatus = async (body) => {
  const response = await fetch("/api/overview/add-emission-boundaries-status", {
    method: "POST",
    headers: getAuthorizationHeaderWithContentType(),
    body: JSON.stringify(body),
    mode: "cors",
  });

  if (response.status === 200) {
    const jsonData = await response.json();
    return {
      status: "success",
      message: "Date filters fetched successfully",
      data: jsonData.data,
    };
  } else if (response.status === 401) {
    clearLoginData();
  } else {
  }
  return {
    status: "error",
    message: "Failed to add emission boundaries status",
  };
};

export const getUserBusinessLocations = async () => {
  try {
    const response = await fetch(`/api/users/user-business-locations`, {
      method: "GET",
      headers: getAuthorizationHeader(),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const getAllFiles = async () => {
  try {
    const response = await fetch(`/api/files/file-uploads`, {
      method: "GET",
      headers: getAuthorizationHeader(),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const getEmployeeCount = async () => {
  try {
    const response = await fetch(`/api/users/get-employee-count`, {
      method: "GET",
      headers: getAuthorizationHeader(),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData.employeeCount;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const updateBusinessProcStatusAuthProvider = async (body) => {
  try {
    const response = await fetch(
      `/api/users/update-business-proc-status-auth-provider`,
      {
        method: "PUT",
        headers: getAuthorizationHeaderWithContentType(),
        body: JSON.stringify(body),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};
export const updateStepsProgressByInventoryAndName = async (body) => {
  const response = await fetch(`/api/overview/update-steps-progress`, {
    method: "PUT",
    headers: getAuthorizationHeaderWithContentType(),
    mode: "cors",
    body: JSON.stringify(body),
  });

  if (response.status === 200) {
    const data = await response.json();
    const progress = data.data;
    return { status: "success", data: progress };
  } else if (response.status === 401) {
    clearLoginData();
  } else {
    return { status: "error", message: "Failed to update steps progress" };
  }
};

export const updateCurrentStepProgressInInventoryProgress = async (body) => {
  const response = await fetch(`/api/overview/update-current-step-progress`, {
    method: "PUT",
    headers: getAuthorizationHeaderWithContentType(),
    mode: "cors",
    body: JSON.stringify(body),
  });

  if (response.status === 200) {
    const data = await response.json();
    const progress = data.data;
    return { status: "success", data: progress };
  } else if (response.status === 401) {
    clearLoginData();
  } else {
    return {
      status: "error",
      message: "Failed to update current steps progress",
    };
  }
};

export const createStepsProgress = async (body) => {
  const response = await fetch(`/api/overview/create-steps-progress`, {
    method: "POST",
    headers: getAuthorizationHeaderWithContentType(),
    mode: "cors",
    body: JSON.stringify(body),
  });

  if (response.ok) {
    const data = await response.json();
    const progress = data.data;
    return { status: "success", data: progress };
  } else if (response.status === 401) {
    clearLoginData();
  } else {
    return { status: "error", message: "Failed to create steps progress" };
  }
};

export const deleteStepsProgressRow = async (
  inventoryId,
  stringId,
  officeId
) => {
  try {
    // Build query parameters, excluding officeId if it's not provided
    const queryParamsObj = {
      inventoryId,
      stringId,
    };

    if (officeId !== undefined && officeId !== null) {
      queryParamsObj.officeId = officeId;
    }

    const queryParams = new URLSearchParams(queryParamsObj).toString();

    const response = await fetch(
      `/api/overview/delete-steps-progress-row?${queryParams}`,
      {
        method: "DELETE",
        headers: getAuthorizationHeaderWithContentType(),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateActiveStatusForSteps = async (body) => {
  const response = await fetch(`/api/overview/update-active-status-for-steps`, {
    method: "PUT",
    headers: getAuthorizationHeaderWithContentType(),
    mode: "cors",
    body: JSON.stringify(body),
  });

  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else if (response.status === 401) {
    clearLoginData();
  } else {
    return { status: "error", message: "Failed to update active status" };
  }
};

export const getNextProgressStepByInventoryId = async (inventoryId) => {
  try {
    const response = await fetch(`/api/overview/get-next-step/${inventoryId}`, {
      method: "GET",
      headers: getAuthorizationHeader(),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      const nextStep = jsonData.data;
      return nextStep;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};
