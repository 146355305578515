import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import WaterWidget from "./WaterWidget";
import { faTint } from "@fortawesome/free-solid-svg-icons";

function WaterTotalWidgets({ total }) {
  // const diverted = {
  //   emissions: total.emissions - generalWaste.emissions,
  //   weight: total.weight - generalWaste.weight,
  // };
  // console.log(diverted)
  return (
    <Row className="mb-4">
      <h5>Totals</h5>
      {/* <Col>
        <WaterWidget
          total={generalWaste}
          title="General Waste"
          icon={faTrash}
        />
      </Col> */}
      <Col>
        <WaterWidget total={total} title="Total Water Usage" icon={faTint} />
      </Col>
      {/* <Col>
        <WaterWidget total={recycled} title="Recycled Waste" icon={faRecycle} />
      </Col> */}
    </Row>
  );
}

export default WaterTotalWidgets;
