import React from "react";

const pageNotFound = () => {
  return (
    <div className="text-danger" style={{ textAlign: "center" }}>
      <h1>Oops…</h1>
      <p>We are sorry, we are not able to find what you are looking for.</p>
      <p>Either you are looking for the content does not exists.</p>
      <p>OR</p>
      <p>
        Your subscription does not have access to this information. Please
        contact our support.
      </p>
      <p>Thank you</p>
    </div>
  );
};

export default pageNotFound;
