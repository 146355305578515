import React, { useEffect, useState } from "react";
import { Row, Col, Button, Modal, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCheckCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { deleteEmployeeEngagementFormData } from "./EmployeeEngagementAPI";

const EmployeeEngagementEmployeeCard = ({
  employee,
  refreshData,
  formStatus,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Combine them into a formatted string

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const buttonStyle = {
    // width: "100%", // Set the width to 100%
    width: "100%", // Set the width to 100%
    textAlign: "center",
  };

  const handleDelete = async () => {
    setIsLoading(true); // Show loading spinner
    try {
      const response = await deleteEmployeeEngagementFormData(
        employee.formId,
        employee.id
      );

      if (response.ok) {
        console.log("Employee engagement form data deleted successfully");
        // window.location.reload(); // Refresh the page after deletion
        refreshData(employee.formId); // Trigger the parent's get function
      } else {
        console.log("Failed to delete employee engagement form data");
      }
    } catch (error) {
      console.error("Error during delete operation:", error);
    } finally {
      setIsLoading(false); // Hide loading spinner
      setShowDeleteConfirmationModal(false);
    }
  };

  useEffect(() => {}, [location.state]);

  return (
    <>
      {DeleteConfirmationModal(
        showDeleteConfirmationModal,
        setShowDeleteConfirmationModal,
        handleDelete,
        isLoading
      )}
      <Row>
        <Col xs={9}>
          {/* <h5 className="mb-2 d-flex align-items-center h-100"> */}
          <h5 className=" d-flex align-items-center ">
            {employee.given} {employee.family}
          </h5>
          <small style={{ display: "block" }}>{employee.email}</small>
          <small style={{ display: "block" }}>
            {employee.hasSubmittedData
              ? `Submitted on ${employee.date}`
              : "Data not submitted"}
          </small>
          {/* <small style={{ display: "block" }}>{employee.data}</small> */}
          {/* <small style={{ display: "block" }}>Submitted on 23/04/2024</small> */}
        </Col>
        <Col xs={2} className="d-flex align-items-center ">
          <Link
            to="/welcome/data/employee-engagement-form-data"
            state={{
              ...location.state,
              employeeEngagementFormDataId: employee.id,
              employeeName: employee.given,
            }}
          >
            {employee.hasSubmittedData ? (
              <Button variant="success" className="w-100" style={buttonStyle}>
                View/Edit response
                <FontAwesomeIcon icon={faCheckCircle} className="ms-2" />
              </Button>
            ) : (
              <Button variant="primary" className="w-100" style={buttonStyle}>
                Complete form
                <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
              </Button>
            )}
          </Link>
        </Col>
        <Col xs={1} className="d-flex align-items-center">
          {employee.hasSubmittedData ? (
            // <Button variant="outline-danger" onClick={handleDelete}>
            <Button
              variant="outline-danger"
              onClick={() => setShowDeleteConfirmationModal(true)}
              disabled={formStatus === "Completed"}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </>
  );
};

const DeleteConfirmationModal = (
  showDeleteConfirmationModal,
  setShowDeleteConfirmationModal,
  handleDelete,
  isLoading
) => {
  return (
    <Modal
      centered
      show={showDeleteConfirmationModal}
      onHide={() => setShowDeleteConfirmationModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete user submission</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this user's data?</Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => setShowDeleteConfirmationModal(false)}
          disabled={isLoading}
        >
          No
        </Button>
        <Button variant="danger" onClick={handleDelete} disabled={isLoading}>
          {isLoading ? <Spinner animation="border" size="sm" /> : "Yes"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmployeeEngagementEmployeeCard;
