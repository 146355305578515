import { useEffect, useState } from "react";
import { fetchUnifiedDataFromFirebaseByBusinessId } from "../../helpers/unifiedDataHelper";
import { Modal, Spinner } from "react-bootstrap";
import SupplierIntroduction from "../unifiedData/SupplierIntroduction";
import EmissionsCalculations from "../unifiedData/EmissionsCalculations";

const SupplierDetailModal = ({
  showCalculationsModal,
  handleCloseCalculationsModal,
  contactData,
  data,
  startDate,
  endDate,
}) => {
  const [totalPages, setTotalPages] = useState(0);
  const [supplierDataLines, setSupplierDataLines] = useState([]);

  const showSupplierIntroduction =
    contactData.contact &&
    contactData.contact.supplierId &&
    contactData.contact.supplierId.trim() !== "";

  async function toFetchEmissionFactorData(supplierUnifiedData) {
    const uniqueEmissionFactorIds = [
      ...new Set(supplierUnifiedData.map((item) => item.emissionFactorId)),
    ];

    const results = await fetchEmissionFactors(uniqueEmissionFactorIds);
    // console.log("result", results);
    const emissionFactorCache = results.reduce((acc, item) => {
      acc[item["_id"]] = item;
      return acc;
    }, {});
    // console.log("emissionFactorCache", emissionFactorCache);
    // Combine the unified data with the fetched results
    return supplierUnifiedData.map((dataLine, index) => ({
      ...dataLine,
      // emissionFactorData: results[index],
      emissionFactorData: emissionFactorCache[dataLine.emissionFactorId],
    }));
  }

  async function getSupplierDataLines(supplierInfo) {
    try {
      const fullData = await fetchUnifiedDataFromFirebaseByBusinessId(
        data.businessId,
        startDate,
        endDate
      );

      if (fullData && fullData.length > 0) {
        let unifiedData = [];
        if (supplierInfo.dataSource === "Bill") {
          const supplierName = supplierInfo.contactName;
          unifiedData = fullData.filter(
            (item) =>
              item.contactName === supplierName &&
              item.inclusionStatus === "INCLUDED"
          );
        } else {
          const supplierId = supplierInfo.contactId;
          unifiedData = fullData.filter(
            (item) =>
              item.contactId === supplierId &&
              item.inclusionStatus === "INCLUDED"
          );
        }

        return unifiedData;
      } else {
        console.log("No data retrieved or empty data array.");
        return []; // Or handle the case where data is empty.
      }
    } catch (error) {
      console.log("Error occurred:", error);
      // Handle the error appropriately, log, throw, or return a default value.
    }
  }

  async function fetchEmissionFactors(emissionFactorIds) {
    const targetUrl = `https://netnada-factor-production-8ed52ccdfd5e.herokuapp.com/factors/getByIds`;
    try {
      const response = await fetch(targetUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_NETNADA_API_KEY,
        },
        body: JSON.stringify({
          ids: emissionFactorIds,
        }),
        mode: "cors",
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Failed to fetch emission factors:", error);
      return null;
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      // console.time("getSupplierDataLines Time");
      const unifiedData = await getSupplierDataLines(contactData); // Ensure `supplierData` is defined or fetched from somewhere
      // console.timeEnd("getSupplierDataLines Time");

      // console.log("unifiedData =>", unifiedData);

      // console.time("toFetchEmissionFactorData Time");
      const enrichedData = await toFetchEmissionFactorData(unifiedData);
      // console.timeEnd("toFetchEmissionFactorData Time");

      // console.log("enrichedData =>", enrichedData);
      setSupplierDataLines(enrichedData);
      setTotalPages(enrichedData.length);
    };

    fetchData().catch(console.error);
  }, []);

  return (
    <Modal
      show={showCalculationsModal}
      onHide={handleCloseCalculationsModal}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Calculations
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* {console.log("data=>", data)}
          {console.log("contactData=>", contactData)}
          {console.log("supplierId=>", contactData.contact.supplierId)} */}
        {showSupplierIntroduction && (
          <SupplierIntroduction
            contactData={contactData}
            handleCloseCalculationsModal={handleCloseCalculationsModal}
            data={data}
          />
        )}

        {/* <hr /> */}
        {supplierDataLines.length === 0 ? (
          <div className="text-center">
            <Spinner animation="border" />
            <p>
              Loading individual transactions. This may take up to 10 seconds...
            </p>
          </div>
        ) : (
          <EmissionsCalculations
            handleCloseCalculationsModal={handleCloseCalculationsModal}
            supplierDataLines={supplierDataLines}
            totalPages={totalPages}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SupplierDetailModal;
