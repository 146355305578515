import React from "react";
import users from "../../../../data/people";
import Flex from "../../../common/Flex";
import Avatar from "../../../common/Avatar";
import classNames from "classnames";

const ChatLoader = ({ senderUserId }) => {
  const user = users.find(({ id }) => id === senderUserId);
  const name = user?.name.split(" ")[0];
  const isLeft = senderUserId !== 3;
  return (
    <Flex
      className={classNames("p-3", {
        "d-block": !isLeft,
      })}
    >
      {isLeft && <Avatar size="2xl" src={user.avatarSrc} className="me-2" />}

      <div
        className={classNames("flex-1", {
          "d-flex justify-content-end": !isLeft,
        })}
      >
        <div
          className={classNames("w-xxl-75", {
            "w-100": !isLeft,
          })}
        >
          <Flex
            alignItems="center"
            className={classNames("hover-actions-trigger", {
              "flex-end-center": !isLeft,
              "align-items-center": isLeft,
            })}
          >
            <>
              <div
                className={classNames("p-2 rounded-2 chat-message", {
                  "bg-200": isLeft,
                  "bg-primary text-white light": !isLeft,
                })}
              >
                <div className="fs--2 text-500">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            </>
          </Flex>
        </div>
      </div>
    </Flex>
  );
};

export default ChatLoader;
