import { createContext } from "react";
import { settings } from "../config";

const AppContext = createContext(settings);

export const EmailContext = createContext({ emails: [] });

export const ProductContext = createContext({ products: [] });

export const ProjectContext = createContext({ projects: [] });

export const SolutionContext = createContext({ solutions: [] });

export const MySolutionsContext = createContext({ mySolutions: [] });

export const AreasOfImprovementContext = createContext({
  areasOfImprovement: [],
});

export const FeedContext = createContext({ feeds: [] });

export const AuthWizardContext = createContext({ user: {} });

export const TransparencyContext = createContext({
  step: 1,
  setStep: () => {},
});

export const WasteContext = createContext({ step: 1, setStep: () => {} });

export const WaterContext = createContext({ step: 1, setStep: () => {} });

export const ChatContext = createContext();

export const KanbanContext = createContext({
  KanbanColumns: [],
  kanbanTasks: [],
});

export const EmissionsBoundariesContext = createContext({
  EmissionsBoundariesColumns: [],
  emissionsBoundaries: [],
});

export default AppContext;
