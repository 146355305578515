import React, { useReducer } from "react";
import { mySolutionReducer } from "../../../reducers/productReducer";
import { MySolutionsContext } from "../../../context/Context";
import MySolutions from "./mySolutions";

const MySolutionProvider = () => {
  // console.log("In MySolutionProvider");

  const initMySolutionData = {
    mySolutions: [],
  };

  const [mySolutionsState, mySolutionsDispatch] = useReducer(
    mySolutionReducer,
    initMySolutionData
  );
  // console.log(mySolutionsState);

  return (
    <MySolutionsContext.Provider
      value={{
        mySolutionsState,
        mySolutionsDispatch,
      }}
    >
      <MySolutions />
    </MySolutionsContext.Provider>
  );
};

export default MySolutionProvider;
