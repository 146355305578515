import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import StatisticsCard from "../../chartjs/StatisticsCard";
import bg3 from "../../../Assets/img/icons/spot-illustrations/corner-3.png";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import app from "../../../firebase";
const fireStoreDB = getFirestore(app);

const TotalEmissionsCard = ({ wfhFormDataId, userId, businessId }) => {
  const [totalStatus, setTotalStatus] = useState(false);
  const [total, setTotal] = useState(0);

  const fetch = async (wfhFormDataId, userId, businessId) => {
    let totalEmissions = 0;
    if (userId) {
      //fetch wfh data by user id from firebase 'UnifiedData' collection
      const q = query(
        collection(fireStoreDB, "UnifiedData", businessId, "DataLines"),
        where("mainId", "==", wfhFormDataId),
        where("subId", "==", userId)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());
        totalEmissions += doc.data().emissions;
      });
      setTotal(totalEmissions);
      setTotalStatus(true);
    }
  };

  useEffect(() => {
    fetch(wfhFormDataId, userId, businessId);
  }, [wfhFormDataId, userId, businessId]);

  const statsData = [
    {
      title: "Total",
      value: total,
      decimal: true,
      suffix: " kg CO₂e",
      prefix: "",
      valueClassName: "",
      badgeBg: "success",
      link: "/",
      linkText: "",
      image: bg3,
      status: totalStatus,
    },
  ];
  return (
    <Row className="g-3 mb-3" style={{ justifyContent: "space-around" }}>
      {statsData.map((stat, index) => (
        <Col key={stat.title} sm={6} md={4}>
          <StatisticsCard stat={stat} style={{ minWidth: "12rem" }} />
        </Col>
      ))}
    </Row>
  );
};

export default TotalEmissionsCard;
