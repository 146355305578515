import { useEffect, useState } from "react";
import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
import {
  faBan,
  faCheck,
  faList,
  faStream,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../Contexts/AuthContext";
import { Card, Nav, Row, Tab, Spinner } from "react-bootstrap";
import Flex from "../common/Flex";
import { getAllFiles } from "../new-overview/newOverviewAPI";
import AllInvoiceTable from "../DataUploader/AllInvoices/AllInvoiceTable";
import {
  getAllInvoicesByBusinessId,
  getBusinesses,
  getOffices,
} from "./adminApi";
import Select from "react-select";

const recentPurchaseTableBadge = {
  Success: { content: "Success", type: "success", icon: faCheck },
  Blocked: { content: "Blocked", type: "secondary", icon: faBan },
  Pending: { content: "Pending", type: "warning", icon: faStream },
};

const NavItem = ({ item }) => (
  <Nav.Item as="li">
    <Nav.Link
      className="mb-0 py-3 cursor-pointer"
      eventKey={item.toLowerCase()}
    >
      {item}
    </Nav.Link>
  </Nav.Item>
);

const Invoices = () => {
  const { currentUser } = useAuth();
  const [activeTabKey, setActiveTabKey] = useState("all");
  const [nameId, setNameId] = useState({});
  const [businesses, setBusinesses] = useState([]);
  const [offices, setOffices] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [allInvoices, setAllInvoices] = useState([]);
  const [electricityInvoices, setElectricityInvoices] = useState([]);
  const [gasInvoices, setGasInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [navItems] = useState(["Electricity", "Gas"]);

  useEffect(() => {
    const analytics = getAnalytics();
    if (currentUser) {
      setUserId(analytics, currentUser.uid);
      setUserProperties(analytics, { businessId: currentUser.businessId });
      logEvent(analytics, "page_view", { page_title: "All Forms" });
    }
  }, [currentUser]);

  useEffect(() => {
    if (selectedBusiness) {
      // Filter offices by selected business
      const filteredOffices = offices[selectedBusiness.value] || [];
      if (filteredOffices.length === 0) {
        setNameId({});
        return;
      }
      const nameIdMap = {};
      filteredOffices.forEach((office) => {
        nameIdMap[office.id] = office.name;
      });
      setNameId(nameIdMap);
    }
  }, [offices, selectedBusiness]);

  const getInvoices = async (businessId) => {
    try {
      setIsLoading(true);
      const jsonData = await getAllInvoicesByBusinessId(businessId);
      jsonData.forEach((invoice) => {
        invoice.status = recentPurchaseTableBadge[invoice.approval_status];
        invoice.startDate = new Date(invoice.start_date);
        invoice.endDate = new Date(invoice.end_date);
        invoice.document = {
          name: invoice.document_name,
          url: invoice.document_url,
        };
        invoice.location = nameId[invoice.office_id];
      });
      setAllInvoices(jsonData);
      setElectricityInvoices(
        jsonData.filter((invoice) => invoice.category === "Electricity")
      );
      setGasInvoices(jsonData.filter((invoice) => invoice.category === "Gas"));
    } catch (err) {
      console.error("Error fetching invoices:", err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchBusinesses = async () => {
    try {
      setIsLoading(true);
      const businessList = await getBusinesses();
      businessList.sort((a, b) => (a.name > b.name ? 1 : -1));
      const businessOptions = businessList.map((business) => ({
        value: business.id,
        label: `${business.id} - ${business.name}`,
      }));
      setBusinesses(businessOptions);
      const officeList = await getOffices();
      setOffices(officeList);
    } catch (err) {
      console.error("Error fetching businesses:", err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBusinesses();
  }, []);

  useEffect(() => {
    if (selectedBusiness) {
      getInvoices(selectedBusiness.value);
    }
  }, [selectedBusiness]);

  const handleSelectBusiness = (selectedOption) => {
    // console.log(`Selected business: ${selectedOption.label}`);
    setSelectedBusiness(selectedOption);
  };

  const handleRefresh = () => {
    if (selectedBusiness) {
      getInvoices(selectedBusiness.value);
    }
  };

  const queryParams = new URLSearchParams(window.location.search);
  const tabKey = queryParams.get("tabKey");
  useEffect(() => {
    setActiveTabKey(tabKey || "electricity");
  }, [tabKey]);

  return (
    <Card className="mt-3">
      <Tab.Container
        id="audience-tab"
        activeKey={activeTabKey}
        onSelect={(k) => setActiveTabKey(k)}
      >
        <Card.Header
          as={Flex}
          justifyContent="between"
          alignItems="center"
          className="ps-0 py-0 border-bottom"
        >
          <Nav
            as="ul"
            className="nav-tabs border-0 flex-nowrap chart-tab tab-active-caret"
          >
            {navItems.map((item) => (
              <NavItem item={item} key={item} />
            ))}
          </Nav>
          <div className="ms-auto" style={{ width: "500px" }}>
            <Select
              options={businesses}
              onChange={handleSelectBusiness}
              placeholder="Select Business"
              isSearchable
            />
          </div>
        </Card.Header>
        <Card.Body>
          <Row>
            <Tab.Content>
              {isLoading ? (
                <div
                  style={{
                    position: "fixed",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 9999,
                  }}
                >
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <>
                  {allInvoices.length === 0 ? (
                    <div className="text-center mt-4">
                      No invoices found for the selected business.
                    </div>
                  ) : (
                    <>
                      <Tab.Pane unmountOnExit eventKey="electricity">
                        <AllInvoiceTable
                          data={electricityInvoices}
                          getUploads={getInvoices}
                          isLoading={isLoading}
                          isAdmin={true}
                          handleInvoiceRefresh={handleRefresh}
                        />
                      </Tab.Pane>
                      <Tab.Pane unmountOnExit eventKey="gas">
                        <AllInvoiceTable
                          data={gasInvoices}
                          getUploads={getInvoices}
                          isLoading={isLoading}
                          isAdmin={true}
                          handleInvoiceRefresh={handleRefresh}
                        />
                      </Tab.Pane>
                    </>
                  )}
                </>
              )}
            </Tab.Content>
          </Row>
        </Card.Body>
      </Tab.Container>
    </Card>
  );
};

export default Invoices;
