import React from "react";
import app from "../../../firebase";
import { getFirestore } from "firebase/firestore";
import { Button, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

const db = getFirestore(app);

const HelpAndSupportPreview = ({
  mainTitle,
  content,
  showLearnMoreLink,
  learnMoreLink,
}) => {
  return (
    // <Card
    //   style={{
    //     width: "100%",
    //     height: "93%",
    //     overflowY: "auto",
    //     borderRadius: "5px",
    //   }}
    // >
    <Card className="mb-3" style={{ height: "200%" }}>
      <Card.Header
        style={{
          backgroundColor: "#f8f9fa",
          borderBottom: "1px solid #dee2e6", // Border to separate the header
          paddingBottom: "10px", // Extra padding to visually separate the header
        }}
      >
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="d-flex align-items-center">
            {/* Custom Icon (Document-like) */}
            <div style={iconContainerStyle}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#13597A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                <polyline points="14 2 14 8 20 8"></polyline>
                <line x1="16" y1="13" x2="8" y2="13"></line>
                <line x1="16" y1="17" x2="8" y2="17"></line>
                <polyline points="10 9 9 9 8 9"></polyline>
              </svg>
            </div>

            <div className="ms-3">
              {/* Main Title */}
              <h4 className="mb-0">{mainTitle}</h4>
              {/* Learn More Button */}
              {showLearnMoreLink && (
                <Button
                  href={learnMoreLink} // Example link
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: "none",
                    backgroundColor: "#fff", // White background
                    color: "#13597A", // Your desired text color
                    fontSize: "1rem",
                    display: "flex",
                    alignItems: "center",
                    // padding: "8px 12px", // Padding to give the button space
                    border: "1px solid #13597A", // Border with your color
                    borderRadius: "4px", // Optional: for rounded corners
                    // marginTop: "5px", // Adjust margin as needed
                    cursor: "pointer",
                  }}
                >
                  Learn more
                  <FontAwesomeIcon
                    icon={faArrowUpRightFromSquare}
                    style={{
                      marginLeft: "5px",
                      fontSize: "1rem",
                    }}
                  />
                </Button>
              )}
            </div>
          </div>
        </div>

        {/* <div className="d-flex justify-content-between align-items-center mt-2">
          <Button
            variant="primary"
            style={{
              backgroundColor: "#7C3AED", // Purple button
              borderColor: "#7C3AED",
            }}
          >
            Track framework progress
          </Button>
          <Button
            variant="link"
            href="https://example.com/learn-more" // Example link
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more <i className="bi bi-box-arrow-up-right"></i>
          </Button>
        </div> */}
      </Card.Header>

      <Card.Body>
        {/* Sections */}
        {/* <div>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div> */}
        <div
          dangerouslySetInnerHTML={{
            __html: content.replace(
              /<iframe[^>]+src="https:\/\/www.loom.com\/share\/([^"]+)"[^>]*><\/iframe>/g,
              `<iframe frameborder="0" allowfullscreen="true" width="100%" height="315" src="https://www.loom.com/embed/$1"></iframe>`
            ),
          }}
        />
      </Card.Body>
    </Card>
  );
};

export default HelpAndSupportPreview;

// Custom Icon Container Style
const iconContainerStyle = {
  backgroundColor: "#F89745", // Orange background
  borderRadius: "6px", // Rounded corners
  padding: "5px",
};
