import React, { forwardRef, useEffect, useState } from "react";
import WidgetSectionTitle from "../../widgets/WidgetSectionTitle";
import { toast, ToastContainer } from "react-toastify";
import { Card } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import IconButton from "../../components/common/IconButton";
import Flex from "../common/Flex";
import {
  faAdd,
  faChevronRight,
  faCopy,
  faEdit,
  faFile,
  faFill,
  faFolderOpen,
  faList,
  faPager,
  faRemove,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import {
  saveEmployeeEngagementForm,
  getEmployeeEngagementForms,
  //   getInviteUrl,
  //   getWfhFormUserCount,
  //   fetchWfhInviteUrl,
} from "./EmployeeEngagementAPI";
import { getFormattedDate } from "../../helpers/utils";
import AdvanceTableWrapper from "../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../common/advance-table/AdvanceTableFooter";
import { useAuth } from "../../Contexts/AuthContext";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { getActiveUserData } from "../../helpers/store";
import {
  fetchDashboardDataFromFirebaseByBusinessId,
  removeUnifiedDateFromStore,
} from "../../helpers/unifiedDataHelper";

const EmployeeEngagementForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const [userRole] = useState(activeUser.role);
  const [showEmployeeEngagementFormModal, setShowEmployeeEngagementFormModal] =
    useState(false);
  const [employeeEngagementForms, setEmployeeEngagementForms] = useState([]);
  const [employeeEngagementForm, setEmployeeEngagementForm] = useState();

  async function handleCloseEmployeeEngagementFormModal() {
    setIsLoading(true);
    const employeeEngagementForms = await getEmployeeEngagementForms();
    //fetch count of users who submitted each form
    for (let i = 0; i < employeeEngagementForms.length; i++) {
      employeeEngagementForms[i]["count"] = 1;
    }
    setEmployeeEngagementForms(employeeEngagementForms);
    setShowEmployeeEngagementFormModal(false);
    setIsLoading(false);
  }

  async function get() {
    // console.log("Start Get for EmployeeEngagementForm");
    setIsLoading(true);
    const employeeEngagementForms = await getEmployeeEngagementForms();
    //fetch count of users who submitted each form
    for (let i = 0; i < employeeEngagementForms.length; i++) {
      employeeEngagementForms[i]["count"] = 1;
    }
    setEmployeeEngagementForms(employeeEngagementForms);
    setIsLoading(false);
  }

  useEffect(() => {
    get();
  }, []);

  const columns = [
    {
      accessor: "id",
      Header: "",
      disableSortBy: true,

      Cell: (rowData) => {
        return (
          <>
            {userRole !== "EMPLOYEE" && (
              <>
                {rowData.row.original.status === "In Progress" && (
                  <>
                    <IconButton
                      variant="falcon-default"
                      size="sm"
                      icon={faEdit}
                      transform="shrink-3"
                      onClick={() => {
                        setEmployeeEngagementForm(rowData.row.original);
                        setShowEmployeeEngagementFormModal(true);
                      }}
                    ></IconButton>{" "}
                  </>
                )}
              </>
            )}
          </>
        );
      },
    },

    {
      accessor: "name",
      Header: "Name",
    },
    {
      accessor: "",
      Header: "Created At",
      Cell: (rowData) => {
        const { created_at } = rowData.row.original;
        return created_at ? getFormattedDate(created_at) : "";
      },
    },
    {
      accessor: "",
      Header: "Start Date",
      Cell: (rowData) => {
        const { start_date } = rowData.row.original;
        return start_date ? getFormattedDate(start_date) : "";
      },
    },
    {
      accessor: "",
      Header: "End Date",
      Cell: (rowData) => {
        const { end_date } = rowData.row.original;
        return end_date ? getFormattedDate(end_date) : "";
      },
    },
    {
      accessor: "status",
      Header: "Status",
    },
    {
      accessor: "formLink",
      Header: "View Form",
      Cell: (rowData) => {
        const { id, name, start_date, end_date, created_at, status } =
          rowData.row.original;

        return (
          <Flex>
            <div>
              <Link
                to="/welcome/data/employee-engagement-response-overview"
                state={{
                  employeeEngagementFormId: id,
                  name,
                  startDate: start_date,
                  endDate: end_date,
                  createdAt: created_at,
                  status,
                }}
              >
                <Button variant="primary" size="sm">
                  View Form
                </Button>
              </Link>
            </div>
          </Flex>
        );
      },
    },
  ];

  const noDataMessages = {
    title: "No Employee Engagement Data Found",
    subtitle: "Consider adding Employee Engagement Data",
  };

  return (
    <>
      {" "}
      <WidgetSectionTitle
        icon={faList}
        title="Employee Engagement Emissions calculator"
        subtitle="Begin by adding a new Employee Engagement form and then sending users the invite link to fill the form"
        transform="shrink-2"
        className="mb-4 mt-6"
      />
      <AdvanceTableWrapper
        columns={columns}
        data={employeeEngagementForms}
        sortable
        pagination
        perPage={7}
        rowCount={employeeEngagementForms.length}
      >
        <Card>
          <Card.Header>
            <Row className="flex-between-center">
              <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                  Employee Engagement Form
                </h5>
              </Col>
              <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                {isLoading ? (
                  <Spinner
                    animation="border"
                    role="status"
                    size="sm"
                    style={{ color: "#f5803e" }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  <></>
                )}
              </Col>
              <Col xs={4} className="ms-auto text-end ps-0">
                {userRole !== "EMPLOYEE" && (
                  <>
                    <IconButton
                      variant="falcon-default"
                      size="sm"
                      icon={faAdd}
                      style={{ marginRight: "5px" }}
                    >
                      <span
                        className="d-none d-sm-inline-block ms-1"
                        onClick={() => {
                          setEmployeeEngagementForm(null);
                          setShowEmployeeEngagementFormModal(true);
                        }}
                      >
                        Add Employee Engagement form
                      </span>
                    </IconButton>
                  </>
                )}
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="btn-reveal-trigger text-nowrap align-middle"
              tableProps={{
                size: "lg",
                className: "fs--1 mb-0 overflow-hidden",
              }}
              noDataMessage={noDataMessages}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTableFooter
              rowCount={employeeEngagementForms.length}
              table
              rowInfo
              navButtons
            />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
      {showEmployeeEngagementFormModal && (
        <EmployeeEngagementFormModal
          showModal={showEmployeeEngagementFormModal}
          handleClose={handleCloseEmployeeEngagementFormModal}
          data={employeeEngagementForm}
          setShowEmployeeEngagementFormModal={
            setShowEmployeeEngagementFormModal
          } // Pass the state setter function
        />
      )}
      <ToastContainer />
    </>
  );
};

const EmployeeEngagementFormModal = ({
  showModal,
  handleClose,
  data,
  setShowEmployeeEngagementFormModal,
}) => {
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const [formName, setFormName] = useState(data?.name);
  const [formStatus, setFormStatus] = useState(data?.status || "In Progress");
  const [initialFormStatus, setInitialFormStatus] = useState(
    data?.status || "In Progress"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDatePeriods, setSelectedDatePeriods] = useState({
    startDate: data?.start_date
      ? new Date(data.start_date)
      : new Date(activeUser.dateRangeStart),
    endDate: data?.end_date
      ? new Date(data.end_date)
      : new Date(activeUser.dateRangeEnd),
  });

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    // debugger; // Add debugger line here

    await saveEmployeeEngagementForm({
      id: data?.id,
      formName,
      formStatus,
      startDate: selectedDatePeriods.startDate.toDateString(),
      endDate: selectedDatePeriods.endDate.toDateString(),
    });
    if (formStatus === "Completed") {
      // console.log("Entering if Completed Loop");
      // console.log("Start Delete");
      await removeUnifiedDateFromStore();
      // console.log("FinishDelete");
      // console.log("Start Refetch");
      await fetchDashboardDataFromFirebaseByBusinessId(
        activeUser.businessId,
        activeUser.currentStartDate,
        activeUser.currentEndDate
      );
      // console.log("Finish Refetch");
    }
    // toast(`Employee Engagement form saved successfully.`);
    setIsLoading(false);
    //to initiate chart data calculation
    // console.log("Start Set dashboardDataCalCompleted");
    sessionStorage.setItem("dashboardDataCalCompleted", false);
    // console.log("Finish Set dashboardDataCalCompleted");

    handleClose(true);
  }
  const onDatePeriodChange = (dates) => {
    const [start, end] = dates;
    setSelectedDatePeriods({
      startDate: start,
      endDate: end,
    });
  };

  return (
    <div>
      {" "}
      <Modal
        show={showModal}
        onHide={() => setShowEmployeeEngagementFormModal(false)} // Call setShowEmployeeEngagementFormModal on modal hide
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Employee Engagement Form
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name=""
                    placeholder="Name"
                    value={formName}
                    onChange={(e) => setFormName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    value={formStatus}
                    onChange={(e) => setFormStatus(e.target.value)}
                  >
                    <option>In Progress</option>
                    {data && <option>Completed</option>}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Time Period</Form.Label>
                  <DatePicker
                    selected={selectedDatePeriods.startDate}
                    onChange={onDatePeriodChange}
                    selectsRange
                    startDate={selectedDatePeriods.startDate}
                    endDate={selectedDatePeriods.endDate}
                    minDate={new Date(activeUser.dateRangeStart)} // Set minimum date
                    maxDate={new Date(activeUser.dateRangeEnd)} // Set
                    dateFormat="dd/MM/yyyy"
                    customInput={<GreetingsDateInput />}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              disabled={
                !formName ||
                !selectedDatePeriods.startDate ||
                !selectedDatePeriods.endDate
                // ||
                //initialFormStatus === "Completed"
              }
            >
              {isLoading && (
                <>
                  <Spinner animation="border" role="status" size="sm">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>{" "}
                </>
              )}
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

const GreetingsDateInput = forwardRef(({ value, onClick }, ref) => (
  <div>
    <Form.Control
      size="lg"
      ref={ref}
      onClick={onClick}
      value={value}
      className="ps-4"
      onChange={(e) => {
        console.log({ e });
      }}
    />
    <FontAwesomeIcon
      icon={faCalendarAlt}
      className="text-primary position-absolute top-50 translate-middle-y ms-2"
    />
  </div>
));

export default EmployeeEngagementForm;
