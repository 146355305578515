import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BarChart from "./BarChart";
import { Row, Col, Form } from "react-bootstrap";
import EmissionsAreaChart from "./EmissionsAreaChart";
import { getUserBusinessLocations } from "../new-overview/newOverviewAPI";

const highlightStyle = {
  // fontWeight: 'bold',
  color: "#007bff",
};

function ScopeTwoCard({ data }) {
  const [nameId, setNameId] = useState({});
  const [location, setLocation] = useState("");
  const [selectedOfficeId, setSelectedOfficeId] = useState("");
  const [locationData, setLocationData] = useState([]);

  useEffect(() => {
    async function fetchBusinessLocations() {
      try {
        const userOffices = await getUserBusinessLocations();
        const nameIdDict = {};
        const officeNames = [];

        userOffices.forEach((office) => {
          const name = office.officename;
          const id = office.office_id;
          nameIdDict[name] = id;
          officeNames.push(name);
        });

        setNameId(nameIdDict);
        setLocationData(officeNames);
      } catch (error) {
        console.error("Error fetching business locations:", error);
      }
    }

    fetchBusinessLocations();
  }, []);

  function handleLocationSelection(e) {
    const selectedLocation = e.target.value;
    setLocation(selectedLocation);

    if (selectedLocation && nameId[selectedLocation]) {
      setSelectedOfficeId(nameId[selectedLocation]);
    } else {
      // When "All Locations" is selected or no matching office ID is found
      setSelectedOfficeId("");
    }
  }

  const scopeTwoData = React.useMemo(() => {
    if (!data) return [];

    return data.map(({ month, scope2ByOffice }) => {
      if (selectedOfficeId) {
        // If a specific office is selected
        return {
          month,
          gas: parseFloat(scope2ByOffice?.[selectedOfficeId]?.gas || 0).toFixed(
            2
          ),
          electricity: parseFloat(
            scope2ByOffice?.[selectedOfficeId]?.electricity || 0
          ).toFixed(2),
          electricityConsumption: parseFloat(
            scope2ByOffice?.[selectedOfficeId]?.electricityConsumption || 0
          ).toFixed(2),
          locationBasedEmissions: parseFloat(
            scope2ByOffice?.[selectedOfficeId]?.locationBasedElectricity || 0
          ).toFixed(2),
          marketBasedEmissions: parseFloat(
            scope2ByOffice?.[selectedOfficeId]?.marketBasedElectricity || 0
          ).toFixed(2),
        };
      } else {
        // Sum over all offices
        const offices = Object.keys(scope2ByOffice || {});
        const totalGas = offices.reduce(
          (sum, officeId) =>
            sum + parseFloat(scope2ByOffice[officeId]?.gas || 0),
          0
        );
        const totalElectricity = offices.reduce(
          (sum, officeId) =>
            sum + parseFloat(scope2ByOffice[officeId]?.electricity || 0),
          0
        );
        const totalElectricityConsumption = offices.reduce(
          (sum, officeId) =>
            sum +
            parseFloat(scope2ByOffice[officeId]?.electricityConsumption || 0),
          0
        );
        const totalLocationBasedEmissions = offices.reduce(
          (sum, officeId) =>
            sum +
            parseFloat(scope2ByOffice[officeId]?.locationBasedElectricity || 0),
          0
        );
        const totalMarketBasedEmissions = offices.reduce(
          (sum, officeId) =>
            sum +
            parseFloat(scope2ByOffice[officeId]?.marketBasedElectricity || 0),
          0
        );
        return {
          month,
          gas: totalGas.toFixed(2),
          electricity: totalElectricity.toFixed(2),
          electricityConsumption: totalElectricityConsumption.toFixed(2),
          locationBasedEmissions: totalLocationBasedEmissions.toFixed(2),
          marketBasedEmissions: totalMarketBasedEmissions.toFixed(2),
        };
      }
    });
  }, [data, selectedOfficeId]);
  // console.log("scopeTwoData", scopeTwoData);

  const keys = ["gas", "electricity"];
  const colorsChart = ["#ffca6c", "#99d6d6"];

  const generateAnalysis = (data) => {
    if (!data || data.length === 0) return "";

    const gasValues = data.map((d) => parseFloat(d.gas));
    const electricityValues = data.map((d) => parseFloat(d.electricity));

    const hasZeroValue = data.some(
      (d) => parseFloat(d.gas) + parseFloat(d.electricity) === 0
    );

    const hasVariation = (values) => {
      const max = Math.max(...values);
      const min = Math.min(...values);
      return max > 10 * min;
    };

    if (hasZeroValue) {
      return (
        <>
          Scope 2 emissions generally show consistent usage across all months.
          It looks like there's a month with 0 emissions. You can update the
          information
          <Link to="/welcome/data/calculations" style={{ marginLeft: 5 }}>
            here
          </Link>{" "}
          to ensure we have complete monthly data.
        </>
      );
    } else if (hasVariation(gasValues) || hasVariation(electricityValues)) {
      return (
        <>
          Scope 2 emissions generally show consistent usage across all months.
          There appears to be a high variation in the data across the months.
          You can update the information
          <Link to="/welcome/data/all-invoices" style={{ marginLeft: 5 }}>
            here
          </Link>{" "}
          to ensure we have accurate monthly data.
        </>
      );
    }

    return "";
  };

  const analysisMessage = generateAnalysis(scopeTwoData);

  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h4 style={{ margin: 0 }}>Scope 2</h4>
          <div style={{ marginLeft: "auto", minWidth: "200px" }}>
            <Form.Select
              aria-label="Select Location"
              onChange={handleLocationSelection}
              value={location}
              disabled={locationData.length === 0}
            >
              <option value="">All Locations</option>
              {locationData.map((item, i) => (
                <option key={i} value={item}>
                  {item}
                </option>
              ))}
            </Form.Select>
          </div>
        </div>
        <br />
        <p>
          Scope 2 emissions are indirect greenhouse gas emissions associated
          with the purchase of electricity, steam, heating, and cooling. These
          emissions are considered indirect because they are a result of the
          activities of the generating plant, not the company itself. For
          instance, if a company buys electricity to power its office building,
          the emissions produced by the power plant generating that electricity
          are Scope 2 emissions.
        </p>
      </div>

      <Row>
        <Col lg={6}>
          <BarChart
            data={scopeTwoData}
            colorTheme={colorsChart}
            keys={keys}
            title={"Scope 2 Data"}
            skipTotals={true}
            small={true}
          />
        </Col>
        <Col lg={6}>
          <EmissionsAreaChart
            data={scopeTwoData}
            colorTheme={colorsChart}
            keys={["Location Based", "Market Based"]}
            title={"Invoice Based Electricity Data"}
            skipTotals={true}
            small={true}
          />
        </Col>
      </Row>

      {analysisMessage && (
        <div style={{ marginTop: "20px" }}>{analysisMessage}</div>
      )}
    </>
  );
}

export default ScopeTwoCard;
