import React, { useState, useEffect } from "react";
import {
  Spinner,
  Modal,
  Form,
  Button,
  FloatingLabel,
  Card,
} from "react-bootstrap";
import { CSVLink } from "react-csv";
import AdvanceTableWrapper from "../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../common/advance-table/AdvanceTableFooter";
import {
  getBusinessDataSourcesFromContacts,
  getSupplierDetailsForBusiness,
  sendSuppliersByEmail,
} from "./adminApi";
import { getActiveUserData } from "../../helpers/store";
import { fetchCampaignURL } from "../../helpers/suppliersHelper";
import SendSupplierDetailsModal from "../unifiedData/SendSupplierDetailsModal";

const SelectDataSourcesModal = ({
  showSelectDataSourcesModal,
  handleSelectDataSourcesModalClose,
  selectedBusiness,
}) => {
  const [businessId] = useState(selectedBusiness.id);
  const [selectedDataSource, setSelectedDataSource] = useState("");
  const [dataSources, setDataSources] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showSelectReportingYearModal, setShowSelectReportingYearModal] =
    useState(false);

  function handleSelectReportingYearModalOpen() {
    setShowSelectReportingYearModal(true);
  }

  function handleDataSourceChange(e) {
    setSelectedDataSource(e.target.value);
  }

  async function fetchDataSources(businessId) {
    setIsLoading(true);
    const response = await getBusinessDataSourcesFromContacts(businessId);
    setDataSources(response);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchDataSources(businessId);
  }, [businessId]);

  return (
    <div>
      {showSelectReportingYearModal ? (
        <SelectReportingYearModal
          showSelectReportingYearModal={showSelectReportingYearModal}
          handleSelectReportingYearModalClose={() => {
            setShowSelectReportingYearModal(false);
            handleSelectDataSourcesModalClose();
          }}
          setShowSelectReportingYearModal={setShowSelectReportingYearModal}
          selectedBusiness={selectedBusiness}
          dataSource={selectedDataSource}
        />
      ) : (
        <Modal
          show={showSelectDataSourcesModal}
          onHide={handleSelectDataSourcesModalClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Select Data Sources
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="dataSource">
                <Form.Label>
                  Data Source*{" "}
                  {isLoading && (
                    <Spinner
                      animation="grow"
                      role="status"
                      size="sm"
                      style={{ color: "#f5803e", marginRight: "4px" }}
                    ></Spinner>
                  )}
                </Form.Label>
                <FloatingLabel label="Data Source Selection">
                  <Form.Select
                    required
                    name="business"
                    value={selectedDataSource}
                    onChange={handleDataSourceChange}
                  >
                    <option value="">Select a data source</option>
                    {dataSources.map((source) => (
                      <option key={source} value={source}>
                        {source}
                      </option>
                    ))}
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={handleSelectReportingYearModalOpen}
              disabled={selectedDataSource === ""}
            >
              Next
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      ;
    </div>
  );
};

const SelectReportingYearModal = ({
  showSelectReportingYearModal,
  handleSelectReportingYearModalClose,
  setShowSelectReportingYearModal,
  selectedBusiness,
  dataSource,
}) => {
  const [businessId] = useState(selectedBusiness.id);
  const [businessName] = useState(selectedBusiness.name);
  const [measurementYear, setMeasurementYear] = useState("");
  const [measurementType, setMeasurementType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [showSuppliersModal, setShowSuppliersModal] = useState(false);

  async function handleNext() {
    setIsLoading(true);
    const supplierData = await getSupplierDetailsForBusiness(
      businessId,
      measurementYear,
      measurementType,
      dataSource
    );
    setSuppliers(supplierData);
    setIsLoading(false);
    setShowSuppliersModal(true);
    // setShowSelectReportingYearModal(false);
  }
  return (
    <div>
      {isLoading ? (
        <LoadingSupplierModal
          show={showSelectReportingYearModal}
          handleClose={handleSelectReportingYearModalClose}
        />
      ) : showSuppliersModal ? (
        // <DisplaySuppliersModal
        //   show={showSuppliersModal}
        //   handleDisplaySuppliersModalClose={() => {
        //     setShowSuppliersModal(false);
        //     handleSelectReportingYearModalClose();
        //   }}
        //   setShowSuppliersModal={setShowSuppliersModal}
        //   suppliers={suppliers}
        //   data={{
        //     selectedBusiness: selectedBusiness,
        //     dataSource: dataSource,
        //     reportingYear: measurementYear,
        //     reportingType: measurementType,
        //   }}
        // />
        <SendSupplierDetailsModal
          show={showSuppliersModal}
          handleClose={() => {
            setShowSuppliersModal(false);
            handleSelectReportingYearModalClose();
          }}
          suppliers={suppliers}
          businessId={businessId}
          businessName={businessName}
          reportingType={measurementType}
          reportingYear={measurementYear}
        />
      ) : (
        <Modal
          show={showSelectReportingYearModal}
          onHide={handleSelectReportingYearModalClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Select Reporting Period
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="type">
                <Form.Label>Reporting Type*</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => setMeasurementType(e.target.value)}
                >
                  <option value="">Please select...</option>
                  <option value="calendar">Calendar Year</option>
                  <option value="financial">Financial Year</option>
                </Form.Control>
              </Form.Group>
            </Form>
            <br />
            <Form.Group controlId="measurementYear">
              <Form.Label>Reporting Year*</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => setMeasurementYear(e.target.value)}
              >
                <option value="">Please select...</option>
                <option value="2025">2025</option>
                <option value="2024">2024</option>
                <option value="2023">2023</option>
                <option value="2022">2022</option>
                <option value="2021">2021</option>
                <option value="2020">2020</option>
                <option value="2019">2019</option>
              </Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            {/* Button to go to previous step */}
            <Button
              onClick={() => {
                setShowSelectReportingYearModal(false);
              }}
            >
              Previous
            </Button>
            {/* Button to go to next step */}
            <Button
              onClick={handleNext}
              disabled={
                measurementYear === "" || measurementType === "" || isLoading
              }
            >
              {isLoading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              Next
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

const LoadingSupplierModal = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Loading</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column align-items-center">
        <Spinner animation="border" role="status" className="mb-3">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <p>Loading suppliers...</p>
      </Modal.Body>
    </Modal>
  );
};

const DisplaySuppliersModal = ({
  show,
  handleDisplaySuppliersModalClose,
  setShowSuppliersModal,
  suppliers,
  data,
}) => {
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const [reportingType] = useState(
    data.reportingType === "calendar" ? "CY" : "FY"
  );
  const [reportingYear] = useState(data.reportingYear);
  const [isLoading, setIsLoading] = useState(false);

  const suppliersColumns = [
    {
      accessor: "contactId",
      Header: "Contact ID",
      headerProps: { className: "text-center" },
      cellProps: { className: "text-wrap" },
    },
    {
      accessor: "preferredName",
      Header: "Preferred Name",
      headerProps: { className: "text-center" },
      cellProps: { className: "text-wrap" },
    },
    {
      accessor: "preferredEmail",
      Header: "Preferred Email",
      headerProps: { className: "text-center" },
      cellProps: { className: "text-wrap" },
    },
    {
      accessor: "preferredABN",
      Header: "Preferred ABN",
      headerProps: { className: "text-center" },
      cellProps: { className: "text-wrap" },
    },
    {
      accessor: "supplierId",
      Header: "Connected Supplier ID",
      headerProps: { className: "text-center" },
      cellProps: { className: "text-wrap" },
    },
    {
      accessor: "abn",
      Header: "Supplier ABN",
      headerProps: { className: "text-center" },
      cellProps: { className: "text-wrap" },
    },
    {
      accessor: "name",
      Header: "Supplier Name",
      headerProps: { className: "text-center" },
      cellProps: { className: "text-wrap" },
    },
    {
      accessor: "email",
      Header: "Supplier Email",
      headerProps: { className: "text-center" },
      cellProps: { className: "text-wrap" },
    },
    {
      accessor: "website",
      Header: "Supplier Website",
      headerProps: { className: "text-center" },
      cellProps: { className: "text-wrap" },
    },
    {
      accessor: "status",
      Header: "Status",
      headerProps: { className: "text-center" },
      cellProps: { className: "text-wrap" },
    },
  ];

  // CSV data for download
  const [resolvedCsvData, setResolvedCsvData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const resolvedData = await Promise.all(
        suppliers.map(async (supplier) => {
          const inviteURL = await fetchCampaignURL(
            supplier.id,
            supplier.name,
            `${reportingType}${reportingYear}`
          );
          return {
            "Contact ID": supplier.contactId,
            "Preferred Name": supplier.preferredName,
            "Preferred Email": supplier.preferredEmail,
            "Preferred ABN": supplier.preferredABN,
            "Connected Supplier ID": supplier.supplierId,
            "Supplier ABN": supplier.abn,
            "Supplier Name": supplier.name,
            "Supplier Email": supplier.email,
            "Supplier Website": supplier.website,
            "Supplier Approval Status": supplier.status,
            InviteURL: inviteURL,
          };
        })
      );
      setResolvedCsvData(resolvedData);
    };

    fetchData();
  }, [suppliers, reportingType, reportingYear]);

  async function handleSendSuppliersByEmail() {
    setIsLoading(true);
    await sendSuppliersByEmail({
      email: activeUser.email,
      suppliers: resolvedCsvData,
      businessName: data.selectedBusiness.name,
      reportingYear: reportingYear,
      reportingType: reportingType,
    });
    setIsLoading(false);
  }

  return (
    <Modal
      show={show}
      onHide={handleDisplaySuppliersModalClose}
      centered
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>Suppliers</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {suppliers.length === 0 && (
          <div className="d-flex flex-column align-items-center">
            <p>No suppliers found for the selected data source.</p>
          </div>
        )}

        {/* Table to display suppliers */}
        {suppliers.length > 0 && (
          <>
            <p>
              This table displays the details of the suppliers. You can Initiate
              Supplier Engagement by selecting the desired action below.
            </p>
            <div className="mb-3">
              <AdvanceTableWrapper
                columns={suppliersColumns}
                data={suppliers}
                sortable
                pagination
                perPage={10}
                rowCount={suppliers.length}
              >
                <Card>
                  <Card.Body className="p-0">
                    <AdvanceTable
                      table
                      headerClassName="bg-200 text-900 text-nowrap align-middle"
                      rowClassName="btn-reveal-trigger text-nowrap align-middle"
                      tableProps={{
                        size: "lg",
                        className: "fs--1 mb-0 overflow-hidden",
                      }}
                    />
                  </Card.Body>
                  <Card.Footer>
                    <AdvanceTableFooter
                      data={suppliers}
                      rowCount={suppliers.length}
                      table
                      rowInfo
                      navButtons
                    />
                  </Card.Footer>
                </Card>
              </AdvanceTableWrapper>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {/* Button to go to previous step */}
        <Button
          onClick={() => {
            setShowSuppliersModal(false);
          }}
        >
          Previous
        </Button>
        {/* Button to download CSV */}
        <CSVLink
          data={resolvedCsvData}
          filename={`${data.selectedBusiness.name}_${reportingType}_${reportingYear}_suppliers.csv`}
        >
          <Button
            variant="success"
            disabled={isLoading || suppliers.length === 0}
          >
            Download CSV
          </Button>
        </CSVLink>
        {/* Button to send CSV by email */}
        <Button
          variant="primary"
          onClick={handleSendSuppliersByEmail}
          disabled={isLoading || suppliers.length === 0}
        >
          {isLoading && (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: "4px" }}
              />{" "}
            </>
          )}
          Send CSV by Email
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectDataSourcesModal;
