import { useEffect, useState } from "react";
import { getActiveUserData } from "../../helpers/store";
import { formatDateToYYYYMMDD } from "../../helpers/utils";

import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  doc,
  writeBatch,
} from "firebase/firestore";
import app from "../../firebase";
import {
  fetchNetnadaFactorsFromMongoDBOpenSearch,
  groupUnifiedData,
} from "../../helpers/suppliersHelper";
import { updateGroupedUnifiedData } from "../../helpers/groupedUnifiedDataHelper";
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Spinner,
  Accordion,
  Alert,
} from "react-bootstrap";

import CustomEmissionFactorModal from "./CustomEmissionFactorModal";
import { set } from "lodash";

const fireStoreDB = getFirestore(app);

const EditEmissionCategoryModal = ({
  showEditModal,
  handleEditClose,
  data,
  updateChartData,
  startDate,
  endDate,
}) => {
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const [reportingYear] = useState(
    parseInt(activeUser.selectedMeasurementYear)
  );
  const [unifiedData] = useState(data.unifiedData || null);
  const [unifiedDataCalCompleted] = useState(
    JSON.parse(sessionStorage.getItem("unifiedDataCalCompleted"))
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isCustomLoading, setIsCustomLoading] = useState(false);
  const [primaryCategory, setPrimaryCategory] = useState(
    data.unifiedData.primaryCategory
  );
  const [secondaryCategory, setSecondaryCategory] = useState(
    data.unifiedData.emissionFactorId
  );
  const [secondaryCategoryObject, setSecondaryCategoryObject] = useState(null);

  const [combinedCategoryFactorsObj] = useState(
    data.combinedCategoryFactorsObj || null
  );
  const [primaryCategories] = useState(data.primaryCategories || []);

  const [secondaryCategories, setSecondaryCategories] = useState([]);

  const [showSecondaryCategories, setShowSecondaryCategories] = useState(
    data.unifiedData.primaryCategory
      ? data.unifiedData.secondaryCategory !== null
        ? true
        : false
      : false
  );

  const [factors, setFactors] = useState([]);
  const [selectedCustomFactorId, setSelectedCustomFactorId] = useState("");

  const [showCustomEmissionFactorModal, setShowCustomEmissionFactorModal] =
    useState(false);

  const handleOpenCustomEmissionFactorModal = () => {
    setShowCustomEmissionFactorModal(true);
  };

  const handleCloseCustomEmissionFactorModal = () => {
    setShowCustomEmissionFactorModal(false);
    fetchCustomEmissionFactors();
  };

  const fetchCustomEmissionFactors = async () => {
    // console.log("fetchCustomEmissionFactors");
    const factors = await fetchNetnadaFactorsFromMongoDBOpenSearch({
      sourceDataset: "CUSTOM",
      source: data.businessId,
    });

    // Check if factors is truthy and not an empty array
    if (factors && factors.length > 0) {
      setFactors(factors);
    }

    // console.log("factors=>", factors);
    // setFactors(factors);
  };

  // useEffect to run on component mount
  useEffect(() => {
    // console.log("useEffect ran");
    fetchCustomEmissionFactors();
  }, []);
  // console.log("data=>", data);

  const toSetSecondaryCategoriesByPrimaryCategory = (primaryCategory) => {
    if (primaryCategory) {
      // console.log("primaryCategory=>", primaryCategory);
      let categories = combinedCategoryFactorsObj[primaryCategory];
      // console.log("categories=>", categories);

      if (!categories) {
        return [];
      }

      // console.log("categories=>", categories);
      // console.log("combinedCategoryFactorsObj=>", combinedCategoryFactorsObj);

      // Group by unit
      const groupedByUnit = categories.reduce((acc, item) => {
        // If the unit doesn't exist in the accumulator, add it as a key with an empty array
        if (!acc[item.unit]) {
          acc[item.unit] = [];
        }

        // Push the current item to the corresponding unit array
        acc[item.unit].push(item);
        return acc;
      }, {});
      // console.log("groupedByUnit=>", groupedByUnit);

      let secondaryCategories = [];

      //if the primary category is 'Electricity', then filter only unit 'kwh' for the year nearest to reporting year
      if (primaryCategory === "Electricity") {
        secondaryCategories = Object.keys(groupedByUnit)
          .filter((unit) => unit === "kwh")
          .map((unit) => ({
            groupName: "For input activity in " + unit,
            categories: groupedByUnit[unit],
          }));

        //find the categories whose year is nearest to reporting year
        let categories = secondaryCategories[0].categories;

        //sort the categories by year in descending order
        categories.sort((a, b) => parseInt(b.year) - parseInt(a.year));

        //fetch unique years
        const uniqueYears = [
          ...new Set(categories.map((item) => item.year)),
        ].sort((a, b) => b - a);

        let nearestLowerYear = null;
        //compare the unique years with reporting year and get the nearest lower year
        for (let i = 0; i < uniqueYears.length; i++) {
          if (uniqueYears[i] <= reportingYear) {
            nearestLowerYear = uniqueYears[i];
            break;
          }
        }
        // console.log("nearestLowerYear=>", nearestLowerYear);

        //filter the categories whose year is nearestLowerYear
        categories = categories.filter(
          (item) => item.year === nearestLowerYear
        );

        //update the secondaryCategories with the filtered categories
        secondaryCategories[0].categories = categories;

        // console.log("secondaryCategories=>", secondaryCategories);

        return secondaryCategories;
      }

      //if the primary category is 'Stationary energy (gaseous fuels)', then filter only unit 'GJ'
      if (primaryCategory === "Stationary energy (gaseous fuels)") {
        secondaryCategories = Object.keys(groupedByUnit)
          .filter((unit) => unit === "GJ")
          .map((unit) => ({
            groupName: "For input activity in " + unit,
            categories: groupedByUnit[unit].filter(
              (item) =>
                item.activity_name === "Natural Gas National average (GJ)"
            ),
          }));

        // console.log("secondaryCategories=>", secondaryCategories);

        return secondaryCategories;
      }

      //if others , then filter only unit '$'
      // Transform the grouped object back into an array suitable for the dropdown
      secondaryCategories = Object.keys(groupedByUnit)
        .filter((unit) => unit === "$")
        .map((unit) => ({
          groupName: "For input activity in " + unit,
          categories: groupedByUnit[unit],
        }));

      // console.log("secondaryCategories=>", secondaryCategories);

      return secondaryCategories;
    }
  };

  useEffect(() => {
    if (primaryCategory) {
      const secondaryCategories =
        toSetSecondaryCategoriesByPrimaryCategory(primaryCategory);
      setSecondaryCategories(secondaryCategories);
      setShowSecondaryCategories(true);
    }
  }, [primaryCategory]);

  const handleChange = (event) => {
    event.preventDefault();
    setPrimaryCategory(event.target.value);
    console.log(event.target);
    const secondaryCategories = toSetSecondaryCategoriesByPrimaryCategory(
      event.target.value
    );
    setSecondaryCategories(secondaryCategories);
    setShowSecondaryCategories(true);
  };

  // Handle the submit for the custom emission factor
  async function handleCustomSubmit(e) {
    // console.log("factors=>", factors);
    // console.log("selectedCustomFactorId=>", selectedCustomFactorId);
    const selectedFactor = factors.find(
      (factor) => factor._id === selectedCustomFactorId
    );

    // console.log("Selected Factor:", selectedFactor);

    // setIsLoading(true);
    setIsCustomLoading(true);
    e.preventDefault();

    // Date manipulation to get the right data
    const startDateString = `${formatDateToYYYYMMDD(
      new Date(startDate)
    )}T00:00:00.000Z`;
    const endDateString = `${formatDateToYYYYMMDD(
      new Date(endDate)
    )}T00:00:00.000Z`;

    try {
      const qry = query(
        collection(fireStoreDB, "UnifiedData", data.businessId, "DataLines"),
        where("contactName", "==", unifiedData.contactName),
        where("inclusionStatus", "==", "INCLUDED"),
        where("date", ">=", startDateString),
        where("date", "<=", endDateString)
      );
      const querySnapshot = await getDocs(qry);

      const batch = writeBatch(fireStoreDB);

      const factor = parseFloat(selectedFactor.co2e_total);
      const activity = selectedFactor;

      querySnapshot.forEach(async (queryDoc) => {
        const queryData = queryDoc.data();
        let emissions = 0;

        //if the data source is bill, calculate the emissions using the consumption
        if (queryData.dataSource === "Bill") {
          // if accountName is electricity, convert emission factor to t/kWh
          if (queryData.accountName === "electricity") {
            const consumption = parseFloat(queryData.consumption);
            emissions = (consumption * factor) / 1000;
          } else if (queryData.accountName === "gas") {
            // if accountName is gas, convert emission factor to t/GJ
            const consumption = parseFloat(queryData.consumption);
            emissions = (consumption * factor) / (1000 * 1000);
          }
        } else {
          if (queryData.subTotal) {
            const subTotal = parseFloat(queryData.subTotal);
            emissions = (subTotal * factor) / 1000;
          }
        }

        const unifiedDataDocRef = doc(
          fireStoreDB,
          "UnifiedData",
          data.businessId,
          "DataLines",
          queryDoc.id
        );

        const updateData = {
          categoryMatchingSource: "MANUAL",
          primaryCategory: activity.category_name,
          primaryEmissionFactor: activity.category_co2e_total,
          secondaryCategory: activity.activity_name,
          secondaryEmissionFactor: factor,
          scope: activity.default_scope || "SCOPE3",
          emissionFactorId: activity._id,
          score: 1.0,
          emissions,
        };

        batch.update(unifiedDataDocRef, updateData);
      });

      // Commit the batched writes
      await batch.commit();

      // console.log("Updating Categories completed");

      //fetch the updated unified data
      let unifiedDataList = [];

      const updatedUnifiedDataQuery = query(
        collection(fireStoreDB, "UnifiedData", data.businessId, "DataLines"),
        where("contactName", "==", unifiedData.contactName),
        where("inclusionStatus", "==", "INCLUDED"),
        where("date", ">=", startDateString),
        where("date", "<=", endDateString)
      );
      const updatedUnifiedDataQuerySnapshot = await getDocs(
        updatedUnifiedDataQuery
      );

      for (const doc of updatedUnifiedDataQuerySnapshot.docs) {
        const data = doc.data();
        unifiedDataList.push(data);
      }
      // console.log("unifiedDataList=>", unifiedDataList);

      //group the updated unified data
      const groupedData = await groupUnifiedData(unifiedDataList);
      // console.log("groupedData=>", groupedData);

      //update the GroupedUnifiedData collection
      await updateGroupedUnifiedData(groupedData);

      //update chart data
      if (unifiedDataCalCompleted) {
        await updateChartData();
      }
      setIsLoading(false);
      handleEditClose(true);
    } catch (error) {
      console.log(error);
    }

    handleEditClose(true);
    setIsCustomLoading(false);
  }
  // handle submit fo normal factors
  async function handleSubmit(e) {
    // console.time("updateCategories");
    setIsLoading(true);
    try {
      e.preventDefault();

      let activity = null;
      let factor = 0.0;

      if (!secondaryCategoryObject) {
        //if the secondaryCategories {'groupName':"For input activity in $",categories=[{}]} has a group name 'For input activity in $', then get the first element of the categories array
        let categories =
          secondaryCategories.filter(
            (item) => item.groupName === "For input activity in $"
          )[0]?.categories || [];

        if (categories.length === 0) {
          categories = secondaryCategories[0].categories;
        }

        activity = categories[0];
        factor = parseFloat(categories[0].category_co2e_total);
      } else {
        activity = secondaryCategoryObject;
        factor = parseFloat(secondaryCategoryObject.co2e_total);
      }
      // console.log("activity=>", activity);

      // console.log("activity=>", activity);
      // console.log("factor=>", factor);

      // Date manipulation to get the right data
      const startDateString = `${formatDateToYYYYMMDD(
        new Date(startDate)
      )}T00:00:00.000Z`;
      const endDateString = `${formatDateToYYYYMMDD(
        new Date(endDate)
      )}T00:00:00.000Z`;

      try {
        const qry = query(
          collection(fireStoreDB, "UnifiedData", data.businessId, "DataLines"),
          where("contactName", "==", unifiedData.contactName),
          where("inclusionStatus", "==", "INCLUDED"),
          where("date", ">=", startDateString),
          where("date", "<=", endDateString)
        );

        const querySnapshot = await getDocs(qry);

        // Collect all documents into an array
        const docs = querySnapshot.docs;

        // Function to split array into chunks of a given size
        function chunkArray(array, size) {
          const result = [];
          for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, i + size));
          }
          return result;
        }

        // Split the documents into chunks of 490
        const docChunks = chunkArray(docs, 490);

        // Process each chunk
        for (const chunk of docChunks) {
          const batch = writeBatch(fireStoreDB);

          for (const queryDoc of chunk) {
            const queryData = queryDoc.data();
            let emissions = 0;

            // Calculate emissions based on the data source and account name
            if (queryData.dataSource === "Bill") {
              if (queryData.accountName === "electricity") {
                const consumption = parseFloat(queryData.consumption);
                emissions = (consumption * factor) / 1000;
              } else if (queryData.accountName === "gas") {
                const consumption = parseFloat(queryData.consumption);
                emissions = (consumption * factor) / (1000 * 1000);
              }
            } else if (queryData.subTotal) {
              const subTotal = parseFloat(queryData.subTotal);
              emissions = (subTotal * factor) / 1000;
            }

            const unifiedDataDocRef = doc(
              fireStoreDB,
              "UnifiedData",
              data.businessId,
              "DataLines",
              queryDoc.id
            );

            const updateData = {
              categoryMatchingSource: "MANUAL",
              primaryCategory: primaryCategory,
              primaryEmissionFactor: activity.category_co2e_total,
              secondaryCategory: activity.activity_name,
              secondaryEmissionFactor: factor,
              scope: activity.default_scope || "SCOPE3",
              emissionFactorId: activity._id,
              score: 1.0,
              emissions,
            };

            batch.update(unifiedDataDocRef, updateData);
          }

          // Commit the batch for the current chunk
          await batch.commit();
        }

        // Proceed with fetching and processing the updated data
        let unifiedDataList = [];

        const updatedUnifiedDataQuery = query(
          collection(fireStoreDB, "UnifiedData", data.businessId, "DataLines"),
          where("contactName", "==", unifiedData.contactName),
          where("inclusionStatus", "==", "INCLUDED"),
          where("date", ">=", startDateString),
          where("date", "<=", endDateString)
        );

        const updatedUnifiedDataQuerySnapshot = await getDocs(
          updatedUnifiedDataQuery
        );

        for (const doc of updatedUnifiedDataQuerySnapshot.docs) {
          const data = doc.data();
          unifiedDataList.push(data);
        }

        // Group the updated unified data
        const groupedData = await groupUnifiedData(unifiedDataList);

        // Update the GroupedUnifiedData collection
        await updateGroupedUnifiedData(groupedData);

        // Update chart data if needed
        if (unifiedDataCalCompleted) {
          await updateChartData();
        }

        setIsLoading(false);
        handleEditClose(true);
      } catch (error) {
        console.log(error);
      }

      // try {
      //   const qry = query(
      //     collection(fireStoreDB, "UnifiedData", data.businessId, "DataLines"),
      //     where("contactName", "==", unifiedData.contactName),
      //     where("inclusionStatus", "==", "INCLUDED"),
      //     where("date", ">=", startDateString),
      //     where("date", "<=", endDateString)
      //   );
      //   const querySnapshot = await getDocs(qry);

      //   const batch = writeBatch(fireStoreDB);

      //   querySnapshot.forEach(async (queryDoc) => {
      //     const queryData = queryDoc.data();
      //     let emissions = 0;

      //     //if the data source is bill, calculate the emissions using the consumption
      //     if (queryData.dataSource === "Bill") {
      //       // if accountName is electricity, convert emission factor to t/kWh
      //       if (queryData.accountName === "electricity") {
      //         const consumption = parseFloat(queryData.consumption);
      //         emissions = (consumption * factor) / 1000;
      //       } else if (queryData.accountName === "gas") {
      //         // if accountName is gas, convert emission factor to t/GJ
      //         const consumption = parseFloat(queryData.consumption);
      //         emissions = (consumption * factor) / (1000 * 1000);
      //       }
      //     } else {
      //       if (queryData.subTotal) {
      //         const subTotal = parseFloat(queryData.subTotal);
      //         emissions = (subTotal * factor) / 1000;
      //       }
      //     }

      //     const unifiedDataDocRef = doc(
      //       fireStoreDB,
      //       "UnifiedData",
      //       data.businessId,
      //       "DataLines",
      //       queryDoc.id
      //     );

      //     const updateData = {
      //       categoryMatchingSource: "MANUAL",
      //       primaryCategory: primaryCategory,
      //       primaryEmissionFactor: activity.category_co2e_total,
      //       secondaryCategory: activity.activity_name,
      //       secondaryEmissionFactor: factor,
      //       scope: activity.default_scope,
      //       emissionFactorId: activity._id,
      //       score: 1.0,
      //       emissions,
      //     };

      //     batch.update(unifiedDataDocRef, updateData);
      //   });

      //   // Commit the batched writes
      //   await batch.commit();

      //   // console.log("Updating Categories completed");

      //   //fetch the updated unified data
      //   let unifiedDataList = [];

      //   const updatedUnifiedDataQuery = query(
      //     collection(fireStoreDB, "UnifiedData", data.businessId, "DataLines"),
      //     where("contactName", "==", unifiedData.contactName),
      //     where("inclusionStatus", "==", "INCLUDED"),
      //     where("date", ">=", startDateString),
      //     where("date", "<=", endDateString)
      //   );
      //   const updatedUnifiedDataQuerySnapshot = await getDocs(
      //     updatedUnifiedDataQuery
      //   );

      //   for (const doc of updatedUnifiedDataQuerySnapshot.docs) {
      //     const data = doc.data();
      //     unifiedDataList.push(data);
      //   }
      //   // console.log("unifiedDataList=>", unifiedDataList);

      //   //group the updated unified data
      //   const groupedData = await groupUnifiedData(unifiedDataList);
      //   // console.log("groupedData=>", groupedData);

      //   //update the GroupedUnifiedData collection
      //   await updateGroupedUnifiedData(groupedData);

      //   //update chart data
      //   if (unifiedDataCalCompleted) {
      //     await updateChartData();
      //   }
      //   setIsLoading(false);
      //   handleEditClose(true);
      // } catch (error) {
      //   console.log(error);
      // }
    } catch (error) {
      console.log(error);
    }
    // console.timeEnd("updateCategories");
  }

  return (
    <div>
      {showCustomEmissionFactorModal && (
        <CustomEmissionFactorModal
          showCustomEmissionFactorModal={showCustomEmissionFactorModal}
          handleCloseCustomEmissionFactorModal={
            handleCloseCustomEmissionFactorModal
          }
          data={{
            businessId: data.businessId,
            unifiedData: data.unifiedData,
          }}
        />
      )}
      <Modal
        show={showEditModal}
        onHide={handleEditClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Body>
            <h5>Edit Emissions Category</h5>
            Each emission category has a different emissions factor. Editing the
            category will result in a recalculation of the emissions for the
            supplier.
          </Modal.Body>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="warning">
            <p>
              Applying a factor will result in a recalculation of all the
              emissions for this supplier in this reporting year.
            </p>
          </Alert>
          <br />
          <Row>
            <h5>Choose an emission factor</h5>
          </Row>

          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Apply Emission Factor</Accordion.Header>
              <Accordion.Body>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>Primary Category</Form.Label>
                      <Form.Select
                        aria-label="Default select"
                        value={primaryCategory}
                        required
                        onChange={handleChange}
                      >
                        <option value="">Please select</option>
                        {primaryCategories.sort().map((cat, index) => (
                          <option value={primaryCategories[index]}>
                            {primaryCategories[index]}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    {showSecondaryCategories ? (
                      <Form.Group className="mb-3" controlId="">
                        <Form.Label>Secondary Category</Form.Label>

                        <Form.Select
                          aria-label="Default select"
                          value={secondaryCategory}
                          onChange={(e) => {
                            // Find the secondary category object
                            const secondaryCategoryObj = secondaryCategories
                              .flatMap((group) => group.categories)
                              .find((cat) => cat._id === e.target.value);
                            setSecondaryCategory(e.target.value);
                            setSecondaryCategoryObject(secondaryCategoryObj);
                          }}
                        >
                          <option value="">Please select your factor</option>

                          {secondaryCategories.map((group) => (
                            <optgroup label={group.groupName}>
                              {group.categories.map((cat) => (
                                <option key={cat._id} value={cat._id}>
                                  {cat.activity_name}
                                  {cat.state_code !== "" && (
                                    <span>
                                      {" - "}({cat.state_code})
                                    </span>
                                  )}
                                </option>
                              ))}
                            </optgroup>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    ) : null}
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        type="submit"
                        disabled={
                          !primaryCategory ||
                          isCustomLoading ||
                          isLoading ||
                          secondaryCategories.length === 0
                        }
                      >
                        {isLoading ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          "Apply factor"
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <br />
          <Row>
            <h5> Or choose your own custom emission factor</h5>
          </Row>

          {/* <br /> */}

          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Apply Custom Emission Factor</Accordion.Header>
              <Accordion.Body>
                <Row>
                  <Col>
                    <h5>Choose from your custom emission factors here</h5>
                    <Form.Select
                      value={selectedCustomFactorId}
                      onChange={(e) =>
                        setSelectedCustomFactorId(e.target.value)
                      }
                    >
                      {factors.length === 0 ? (
                        <option value="">
                          No custom emission factors added
                        </option>
                      ) : (
                        <>
                          <option value="">
                            Please select a custom emission factor
                          </option>
                          {factors.map((factor) => (
                            <option key={factor._id} value={factor._id}>
                              {factor.activity_name}
                            </option>
                          ))}
                        </>
                      )}
                    </Form.Select>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    {/* <h5>Create Custom Emission Factor here </h5> */}
                    <Button
                      variant="outline-dark"
                      onClick={handleOpenCustomEmissionFactorModal}
                    >
                      + Create New Custom EF
                    </Button>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    {/* <Button */}
                    <Button
                      type="submit"
                      disabled={
                        !selectedCustomFactorId || isLoading || isCustomLoading
                      }
                      variant="primary"
                      onClick={handleCustomSubmit}
                    >
                      {isCustomLoading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Apply Custom Emission Factor"
                      )}
                      {/* Apply Custom Emission Factor */}
                    </Button>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Modal.Body>
        {/* <Modal.Footer></Modal.Footer> */}
      </Modal>
    </div>
  );
};
export default EditEmissionCategoryModal;
