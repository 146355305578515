import React, { useContext, useState } from "react";
import { Col, Modal, Row, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Flex from "../../../components/common/Flex";
import IconButton from "../../../components/common/IconButton";
import AppContext, { ProjectContext } from "../../../context/Context";
import {
  faChevronRight,
  faPlus,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import useProjectHook from "../project/useProjectHook";

const AddProjectModal = () => {
  const {
    projectsState: {
      currentProject,
      portfolioModal: { show, project, type },
      totalEmissions,
    },
    projectsDispatch,
  } = useContext(ProjectContext);

  const { handleAddToCart } = useProjectHook(project);

  const {
    config: { isDark },
  } = useContext(AppContext);

  const handleClose = () => {
    projectsDispatch({
      type: "TOGGLE_CART_MODAL",
    });
  };

  const handleAddToPortfolio = () => {
    handleAddToCart(currentProject.id, false);
  };

  // New state to hold the user's emissions input
  const [userEmissions, setUserEmissions] = useState(totalEmissions.toString());

  const calculatedCost = Math.round(
    totalEmissions * currentProject.costpertonne
  );

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header
        closeButton
        closeVariant={isDark ? "white" : null}
        className="border-200"
      >
        <Modal.Title as="h5">
          <Flex alignItems="center">
            <div className="icon-item bg-soft-success shadow-none">
              <FontAwesomeIcon icon={faPlus} className="text-success" />
            </div>
            <div className="ms-2">You just added 1 item</div>
          </Flex>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="g-0 align-items-center">
          <Col>
            <Flex alignItems="center">
              <Image
                src={currentProject.imagesrc}
                rounded
                className="me-3 d-none d-md-block"
                width="80"
                alt="project image"
              />
              <div className="flex-1">
                <h5 className="fs-0">
                  <Link
                    to={currentProject.link}
                    className="text-900"
                    target="_blank"
                  >
                    {currentProject.title}
                  </Link>
                </h5>
              </div>
            </Flex>
          </Col>
          <Col sm="auto" className="ps-sm-3 d-none d-sm-block">
            ${currentProject.costpertonne}
          </Col>
        </Row>
        <br />
        {/* Total Emissions input */}
        <Row className="g-0 align-items-center my-3">
          <Col xs={6}>
            <Flex alignItems="center">
              <h5 className="fs-0 me-2">Total Emissions (tonnes/CO₂e)</h5>
            </Flex>
          </Col>
          <Col xs={6}>
            <Flex alignItems="center" justifyContent="end">
              <span>
                {totalEmissions === 0 && (
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    className="text-danger me-2"
                  />
                )}
                {totalEmissions} tonnes/CO₂e
              </span>
            </Flex>
          </Col>
        </Row>
        <br />
        {/* Calculated Cost */}
        <Row className="g-0 align-items-center my-3">
          <Col></Col>
          <Col sm="auto" className="ps-sm-3">
            <Flex alignItems="center" justifyContent="end">
              <h5 className="fs-0">
                Calculated Cost: ${calculatedCost.toFixed(2)}
              </h5>
            </Flex>
          </Col>
        </Row>
      </Modal.Body>
      {type !== "remove" && (
        <Modal.Footer className="border-200">
          <IconButton
            size="sm"
            className="ms-2"
            icon={faChevronRight}
            variant="primary"
            iconAlign="right"
            onClick={handleAddToPortfolio}
            disabled={totalEmissions === 0} // Disable the button if the user hasn't entered a value
          >
            Add to Portfolio
          </IconButton>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default AddProjectModal;
