import React, { useMemo } from "react";
import AdvanceTableWrapper from "../../common/advance-table/AdvanceTableWrapper";
import { Card } from "react-bootstrap";
import AdvanceTable from "../../common/advance-table/AdvanceTable";
function WasteTable({ categoriesData }) {
  const wasteColumns = useMemo(() => {
    const baseColumns = [
      {
        accessor: "wasteType",
        Header: "Waste Category",
      },
      {
        accessor: "volumeOfCollections",
        Header: "Volume(L)",
      },
      {
        accessor: "totalWeightOfWaste",
        Header: "Weight(tonnes)",
      },
    ];
    if (categoriesData[0]?.source === "estimator") {
      return [
        ...baseColumns,
        {
          accessor: "numBins",
          Header: "Number of Bins",
        },
        {
          accessor: "volBins",
          Header: "Bin Volume(L)",
        },
        {
          accessor: "percBins",
          Header: "Bin Fill(%)",
        },
        {
          accessor: "frequency",
          Header: "Frequency (days)",
        },
      ];
    }
    return baseColumns;
  }, [categoriesData]);
  // if (isLoading) {
  // return <div>Loading...</div>;
  // }
  return (
    <>
      <div className="mb-3">
        <AdvanceTableWrapper
          columns={wasteColumns}
          data={categoriesData}
          sortable
          pagination
          perPage={20}
          rowCount={categoriesData.length}
        >
          <Card>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="btn-reveal-trigger text-nowrap align-middle"
                tableProps={{
                  size: "lg",
                  className: "fs--1 mb-0 overflow-hidden",
                }}
              />
            </Card.Body>
            {/* <Card.Footer>
	 <AdvanceTableFooter
	 data={categoriesData}
	 rowCount={categoriesData.length}
	 table
	 rowInfo
	 navButtons
	 />
	 </Card.Footer> */}
          </Card>
        </AdvanceTableWrapper>
      </div>
    </>
  );
}
export default WasteTable;
