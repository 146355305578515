import classNames from "classnames";
import React from "react";
import { Card, Row } from "react-bootstrap";
import RecommendedSolutionList from "./RecommendedSolutionList";

const RecommendedSolutionCard = ({ data, aoiObject }) => {
  console.log("In recommendedSolutionCard");
  console.log(data);
  return (
    <Card className="mb-3">
      <Card.Body className={classNames("p-0 overflow-hidden")}>
        <Row className={classNames("g-0")}>
          {data.map((solution, index) => (
            <RecommendedSolutionList
              data={solution}
              key={solution.id}
              index={index}
              aoiObject={aoiObject}
            />
          ))}
        </Row>
      </Card.Body>
    </Card>
    // <>
    //   <Row>
    //     {data.map((solution, index) => (
    //       <RecommendedSolutionList
    //         data={solution}
    //         key={solution.id}
    //         index={index}
    //       />
    //     ))}
    //   </Row>
    // </>
  );
};

export default RecommendedSolutionCard;
