import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Button, Card, Col, Row } from "react-bootstrap";
import Background from "./Background";
import corner4 from "../../Assets/img/illustrations/corner-4.png";
import createMarkup from "../../helpers/createMarkup";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faExclamationCircle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

const PageHeader = ({
  title,
  preTitle,
  titleTag: TitleTag,
  description,
  image,
  col,
  children,
  link,
  ...rest
}) => (
  <Card {...rest}>
    <Background
      image={image}
      className="bg-card"
      style={{
        borderTopRightRadius: "0.375rem",
        borderBottomRightRadius: "0.375rem",
      }}
    />
    <Card.Body className="position-relative">
      <Row>
        <Col {...col}>
          {preTitle && <h6 className="text-600">{preTitle}</h6>}
          <TitleTag className="mb-0">{title}</TitleTag>
          {description && (
            <>
              <p
                className={classNames("mt-2", { "mb-0": !children })}
                dangerouslySetInnerHTML={createMarkup(description)}
              />
              {title === "Identify" && (
                <Button
                  as={Link}
                  variant="link"
                  to="https://docs.google.com/spreadsheets/d/1mU-EYYppeRLsPdvZ9RLSr7GbmjQ5W2Y58UHL6o-DIdI/edit#gid=495923610"
                  target="_blank"
                  style={{
                    padding: "0px",
                  }}
                >
                  <FontAwesomeIcon icon={faExclamationCircle} /> See data
                  requirements
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className="ms-1 fs--2"
                  />
                </Button>
              )}
            </>
          )}
          {children}
        </Col>
        {link && (
          <Col className="d-flex justify-content-end align-items-start">
            <Button as={Link} variant="link" to={link.to} target="_blank">
              {link.label}
              <FontAwesomeIcon icon={faChevronRight} className="ms-1 fs--2" />
            </Button>
          </Col>
        )}
      </Row>
    </Card.Body>
  </Card>
);

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  preTitle: PropTypes.string,
  titleTag: PropTypes.string,
  description: PropTypes.string,
  col: PropTypes.shape(Col.propTypes),
  image: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

PageHeader.defaultProps = { col: { lg: 8 }, image: corner4, titleTag: "h3" };

export default PageHeader;
