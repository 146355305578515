import PageHeader from "../common/PageHeader";
import TransparencyWizardLayout from "./TransparencyWizardLayout";
import TransparencyHubProvider from "./TransparencyHubProvider";

const Index = () => {
  const variant = "p";
  const validation = true;
  const progressBar = true;

  return (
    <>
      <PageHeader
        title="Welcome To The Transparency Hub"
        description="The Transparency Hub is a platform where you can access and submit various forms and surveys to help us better understand your sustainability efforts and goals. By providing us with this information, we can offer tailored solutions and recommendations to help you achieve your sustainability objectives."
        className="mb-3"
      ></PageHeader>
      <TransparencyHubProvider>
        <TransparencyWizardLayout
          variant={variant}
          validation={validation}
          progressBar={progressBar}
        />
      </TransparencyHubProvider>
    </>
  );
};

export default Index;
