import React from "react";
import corner4 from "../../../Assets/img/illustrations/corner-4.png";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Background from "../../../components/common/Background";
// import paths from "routes/paths";

const CreateHelpAndSupportDocsHeader = () => {
  return (
    <Card className="mb-3">
      <Background
        image={corner4}
        className="bg-card d-none d-md-block"
        style={{
          borderTopRightRadius: "0.375rem",
          borderBottomRightRadius: "0.375rem",
        }}
      />
      <Card.Body className="position-relative">
        <Row>
          <Col xl={10}>
            <h3>Manage Help and Support Documentation</h3>
            <p className="mb-0">
              Add or view help and support documentation for our app.
            </p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CreateHelpAndSupportDocsHeader;
