import {
  getAuthorizationHeader,
  getAuthorizationHeaderWithContentType,
} from "../../helpers/utils";
import { clearLoginData } from "../../helpers/store";

export const getBusinessReports = async () => {
  try {
    const response = await fetch("/api/reports/business-reports", {
      method: "GET",
      headers: getAuthorizationHeader(),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      // console.log(jsonData);
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const requestReport = async (body) => {
  try {
    const response = await fetch("/api/reports/request-report", {
      method: "POST",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      // console.log(jsonData);
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const updateAccessedAt = async (body) => {
  try {
    const response = await fetch(`/api/reports/update-accessed-at`, {
      method: "PUT",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      // console.log(jsonData);
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const updateStandardReportAccessedAt = async (body) => {
  try {
    const response = await fetch(
      `/api/reports/update-standard-report-accessed-at`,
      {
        method: "PUT",
        headers: getAuthorizationHeaderWithContentType(),
        body: JSON.stringify(body),
      }
    );

    if (response.status === 200) {
      //const fileBlob = await response.blob();
      //download(fileBlob, body.documentName + ".pdf");
      //var file = window.URL.createObjectURL(fileBlob);
      //window.open(file);
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const requestStandardReport = async (body) => {
  try {
    const response = await fetch("/api/reports/request-standard-report", {
      method: "POST",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      // console.log(jsonData);
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};
