import { Card, Row, Col } from "react-bootstrap";
import classNames from "classnames";
import Flex from "../common/Flex";
import CountUp from "react-countup";

const TransparencyScoreDashboard = ({ data }) => {
  return (
    <Card style={{ marginBottom: "1.5rem" }}>
      <Card.Body>
        <Row>
          {data.map((stat, index) => (
            <Col
              lg={3}
              key={stat.id}
              className={classNames({
                "border-bottom border-lg-0 border-lg-end":
                  index !== data.length - 1,
                "pb-3 pb-lg-0": index === 0,
                "py-3 py-lg-0": index === 1,
                "pt-3 pt-lg-0": index === 2,
                // "pt-3 pt-lg-0": index === 3,
              })}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                className="mb-3 position-static text-center"
                style={{ flex: 1 }}
              >
                <stat.icon
                  style={{ color: stat.color }}
                  className="me-2"
                  size={24}
                />
                <h5 className="mb-0 flex-1">{stat.title}</h5>
              </Flex>
              <Flex style={{ flex: 1, paddingLeft: 3 }}>
                <p className="font-sans-serif lh-1 mb-1 fs-4 pe-2">
                  <CountUp
                    start={0}
                    end={stat.amount}
                    duration={2.75}
                    // className="text-700 fs-2 me-2"
                    style={{ color: "var(--falcon-headings-color)" }}
                    separator=","
                  />
                </p>
                <span
                  className="fw-normal text-600"
                  style={{ fontSize: "0.80em" }}
                >
                  {" " + stat.units}
                </span>
              </Flex>
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default TransparencyScoreDashboard;
