import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
import React, { useEffect } from "react";
import { useAuth } from "../../Contexts/AuthContext";
import WidgetSectionTitle from "../../widgets/WidgetSectionTitle";
import GrantTable from "./grantTable";
import { faList } from "@fortawesome/free-solid-svg-icons";

const MyGrants = () => {
  const { currentUser } = useAuth();
  useEffect(() => {
    const analytics = getAnalytics();
    setUserId(analytics, currentUser.uid);
    setUserProperties(analytics, { businessId: currentUser.businessId });
    logEvent(analytics, "page_view", {
      page_title: "My partners",
    });
  }, [currentUser]);
  return (
    <>
      <WidgetSectionTitle
        icon={faList}
        title="My grants"
        r
        subtitle="Sustainability is just a word, it needs action. Set your own goals and get in contact if you need any help."
        transform="shrink-2"
        className="mb-4 mt-6"
      />
      <div className="mb-3">
        <GrantTable />
      </div>
    </>
  );
};

export default MyGrants;
