import React, { useEffect, useState } from "react";
import EmissionDataItem from "./EmissionDataItem"; // Adjust the path as necessary
import Pagination from "./Pagination"; // Adjust the path as necessary
import { Button } from "react-bootstrap";

const EmissionsCalculations = ({
  supplierDataLines,
  totalPages,
  handleCloseCalculationsModal,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const handleNext = () => {
    setCurrentPage((prevCurrentPage) =>
      Math.min(prevCurrentPage + 1, supplierDataLines.length - 1)
    );
  };

  const handlePrevious = () => {
    setCurrentPage((prevCurrentPage) => Math.max(prevCurrentPage - 1, 0));
  };

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "20px",
      }}
    >
      <h3>How were emissions calculated?</h3>
      <p>
        This table shows the breakdown of each transaction its associated
        categorisation and emission calculation
      </p>

      {/* <Button variant="primary" onClick={handleShow}>
        Add Custom Data Line
      </Button>

      <AddCustomDataline
        show={showModal}
        handleClose={handleClose}
        datalineData={supplierDataLines[currentPage]}
      /> */}

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onNext={handleNext}
        onPrevious={handlePrevious}
      />
      <EmissionDataItem
        handleCloseCalculationsModal={handleCloseCalculationsModal}
        data={supplierDataLines[currentPage]}
      />
    </div>
  );
};

export default EmissionsCalculations;
