import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faStream } from "@fortawesome/free-solid-svg-icons";
import SoftBadge from "../../../components/common/SoftBadge";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../../../components/common/advance-table/AdvanceTableFooter";
import AllFormsTableHeader from "./AllFormsTableHeader";
import { Link } from "react-router-dom";

const recentPurchaseTableBadge = {
  Completed: {
    content: "Submitted",
    type: "success",
    icon: faCheck,
  },
  InProgress: { content: "Saved as draft", type: "warning", icon: faStream },
};

const columns = [
  {
    accessor: "name",
    Header: "Section",
    headerProps: { className: "pe-1" },
    cellProps: {
      className: "fw-semi-bold",
    },
    Cell: (rowData) => {
      const row = rowData.row.original;
      const {
        name,
        link,
        id,
        city,
        state,
        numberOfDays,
        numberOfPeople,
        startDate,
        endDate,
        completionStatus,
      } = row;
      return (
        <Link
          to={link}
          state={{
            id,
            city,
            state,
            numberOfDays,
            numberOfPeople,
            startDate,
            endDate,
            completionStatus,
          }}
        >
          {name}
        </Link>
      );
    },
  },
  {
    accessor: "status",
    Header: "Completion Status",

    headerProps: { className: "text-center" },
    cellProps: {
      className: "fs-0",
    },
    Cell: (rowData) => {
      const { status } = rowData.row.original;
      return (
        <SoftBadge pill bg={status.type} className="d-block">
          {status.content}
          <FontAwesomeIcon
            icon={status.icon}
            transform="shrink-2"
            className="ms-1"
          />
        </SoftBadge>
      );
    },
  },
];

const AllFormsTable = ({ forms }) => {
  const [data, setData] = useState(null);

  function storeData(forms) {
    try {
      //set status of forms
      for (var i = 0; i < forms.length; i++) {
        const status = forms[i]["completionStatus"];
        forms[i]["status"] = recentPurchaseTableBadge[status];
      }
      console.log("forms", forms);
      setData(forms);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    storeData(forms);
  }, [forms]);

  console.log("data", data);

  return (
    <>
      {data ? (
        <AdvanceTableWrapper
          columns={columns}
          data={data}
          selection
          sortable
          pagination
          perPage={7}
          rowCount={data.length}
        >
          <Card>
            <Card.Header>
              <AllFormsTableHeader table />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="btn-reveal-trigger text-nowrap align-middle"
                tableProps={{
                  size: "sm",
                  className: "fs--1 mb-0 overflow-hidden",
                }}
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTableFooter
                rowCount={data.length}
                table
                rowInfo
                navButtons
              />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      ) : null}
    </>
  );
};

export default AllFormsTable;
