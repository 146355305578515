import React from "react";

import BarChart from "./BarChart";
import TextAllScopesGraph from "./TextAllScopesGraph";
import { isAllDataZero } from "./chartHelpers";

function AllScopesCard({ data }) {
  //if any the we have an scope that is bigger than 0 and lower than 1 we round 2 decimals
  //if values are bigger than 1 then we round no decimals
  // console.log(data);
  const scopeRoundData = data?.map(({ month, scope1, scope2, scope3 }) => {
    const roundValue = (value) => {
      if (value > 0 && value < 1) {
        return Number(value.toFixed(2));
      } else if (value >= 1) {
        return Math.round(value);
      }
      return value;
    };

    return {
      month,
      scope1: roundValue(scope1),
      scope2: roundValue(scope2?.total),
      scope3: roundValue(scope3),
    };
  });

  // console.log("SCOPES DATA", scopeRoundData);
  const keys = ["scope1", "scope2", "scope3"];
  // const colorsChart = [
  //   "#6699cc",
  //   "#66cc99",
  //   "#ffb84d",
  // ];
  const colorsChart = ["#7ba8d9", "#99d6d6", "#ffca6c"];
  const allDataIsZero = isAllDataZero(scopeRoundData, keys);
  return (
    <div style={{ paddingBottom: 10 }}>
      {!allDataIsZero && <TextAllScopesGraph data={scopeRoundData} />}
      <BarChart
        data={scopeRoundData}
        colorTheme={colorsChart}
        keys={keys}
        title={"All Scopes Data"}
      />
    </div>
  );
}

export default AllScopesCard;
