import { clearLoginData } from "../../helpers/store";
import { getAuthorizationHeaderWithContentType } from "../../helpers/utils";

export const getAllEmissionsBoundaries = async (inventoryId) => {
  try {
    const response = await fetch(
      "/api/emissionsBoundaries/get-all-emissions-boundaries/" + inventoryId,
      {
        method: "GET",
        headers: getAuthorizationHeaderWithContentType(),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const updateEmissionsBoundary = async (
  emissionBoundaryId,
  toBeEmissionBoundary
) => {
  try {
    const response = await fetch(
      "/api/emissionsBoundaries/update-emissions-boundary/" +
        emissionBoundaryId,
      {
        method: "PUT",
        headers: getAuthorizationHeaderWithContentType(),
        mode: "cors",
        body: JSON.stringify({
          toBeEmissionBoundary,
        }),
      }
    );

    if (response.status === 200) {
      return {
        status: "success",
        message: "Emission boundary updated successfully",
      };
    } else if (response.status === 401) {
      clearLoginData();
    } else {
      return { status: "error", message: "Failed to update emission boundary" };
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const removeSelectedLocation = async (emissionBoundaryId, officeId) => {
  const response = await fetch(
    "/api/emissionsBoundaries/remove-selected-location/" + emissionBoundaryId,
    {
      method: "PUT",
      headers: getAuthorizationHeaderWithContentType(),
      mode: "cors",
      body: JSON.stringify({
        officeId,
      }),
    }
  );

  if (response.status === 200) {
    return {
      status: "success",
      message: "Location/s updated successfully",
    };
  } else if (response.status === 401) {
    clearLoginData();
  } else {
    return { status: "error", message: "Failed to update location/s" };
  }
};

export const addSelectedLocation = async (emissionBoundaryId, officeId) => {
  const response = await fetch(
    "/api/emissionsBoundaries/add-selected-location/" + emissionBoundaryId,
    {
      method: "PUT",
      headers: getAuthorizationHeaderWithContentType(),
      mode: "cors",
      body: JSON.stringify({
        officeId,
      }),
    }
  );

  if (response.status === 200) {
    return {
      status: "success",
      message: "Location/s updated successfully",
    };
  } else if (response.status === 401) {
    clearLoginData();
  } else {
    return { status: "error", message: "Failed to update location/s" };
  }
};

export const updateEmissionBoundaryAmountAUD = async (
  emissionBoundaryId,
  amountAUD
) => {
  const response = await fetch(
    "/api/emissionsBoundaries/update-emissions-boundary-amount-aud/" +
      emissionBoundaryId,
    {
      method: "PUT",
      headers: getAuthorizationHeaderWithContentType(),
      mode: "cors",
      body: JSON.stringify({
        amountAUD,
      }),
    }
  );

  if (response.status === 200) {
    return {
      status: "success",
      message: "Amount updated successfully",
    };
  } else if (response.status === 401) {
    clearLoginData();
  } else {
    return { status: "error", message: "Failed to update amount" };
  }
};

export const updateEmissionBoundaryAmountCO2e = async (
  emissionBoundaryId,
  amountCO2e
) => {
  const response = await fetch(
    "/api/emissionsBoundaries/update-emissions-boundary-amount-co2e/" +
      emissionBoundaryId,
    {
      method: "PUT",
      headers: getAuthorizationHeaderWithContentType(),
      mode: "cors",
      body: JSON.stringify({
        amountCO2e,
      }),
    }
  );

  if (response.status === 200) {
    return {
      status: "success",
      message: "Emissions updated successfully",
    };
  } else if (response.status === 401) {
    clearLoginData();
  } else {
    return { status: "error", message: "Failed to update emissions" };
  }
};

export const updateEmissionBoundaryReasoning = async (
  emissionBoundaryId,
  reasoning
) => {
  const response = await fetch(
    "/api/emissionsBoundaries/update-emissions-boundary-reasoning/" +
      emissionBoundaryId,
    {
      method: "PUT",
      headers: getAuthorizationHeaderWithContentType(),
      mode: "cors",
      body: JSON.stringify({
        reasoning,
      }),
    }
  );

  if (response.status === 200) {
    return {
      status: "success",
      message: "Reasoning updated successfully",
    };
  } else if (response.status === 401) {
    clearLoginData();
  } else {
    return { status: "error", message: "Failed to update reasoning" };
  }
};
