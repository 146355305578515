import React, { useEffect, useState } from "react";
import OnBoardingCardLayout from "../../layouts/OnBoardingCardLayout";
import Onboarding from "./Onboarding";
import { questionsData } from "../../data/OnBoardingData";
import GettingStarted from "./GettingStarted";
import { saveOnBoardingDetails } from "./OnBoardingAPI";
import {
  getActiveUserData,
  getWhiteLabel,
  setActiveUserData,
} from "../../helpers/store";
import { createContentImageAPI } from "../content/contentApi";

const LandingPage = () => {
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  // Combine all state in a single object for better management
  const [state, setState] = useState({
    showQuestions: false,
    currentQuestionIndex: 0,
    isLastQuestion: false,
    userName: activeUser.displayName,
    answers: {},
    showGettingStarted: false,
  });

  const [isSkipped, setIsSkipped] = useState(false);
  const [questions, setQuestions] = useState(questionsData);
  const [imageUrl, setImageUrl] = useState(null);
  const [loadingImage, setLoadingImage] = useState(false);
  const [errors, setErrors] = useState({}); // State for form errors

  // Toggle the visibility of the question panel
  const handleShowQuestions = () => {
    setState((prevState) => ({
      ...prevState,
      showQuestions: !prevState.showQuestions,
    }));
  };

  // Function to build the final onboarding details object
  const buildDetails = (answers) => ({
    CAExperience:
      answers[1]?.map((option) => {
        const key = Object.keys(option)[0]; // Get the key of the object
        return key === "Other" ? option[key] : key; // If the key is "Other," pick the value; otherwise, pick the key
      }) || null, // Save null if empty
    CompletingReportFor: answers[2] || null,
    NameOfTheCompany: answers[3] ? answers[3]?.["Company Name"] : null, // Save null if empty
    WebsiteOfTheCompany: answers[3] ? answers[3]?.["Website"] : null, // Save null if empty
    NumberOfEmployees: answers[4] || null,
    Category: answers[3] ? answers[3]?.["Industry"] : null, // Save null if empty
    Integration: answers[6] || null,
    CAReason: answers[7] || null,
    CARequestingCompanyDetails: answers[8]
      ? {
          companyName: answers[8]?.["Company Name"] || null, // Save null if empty
          companyWebsite: answers[8]?.["Website"] || null, // Save null if empty
        }
      : null, // If answers[8] doesn't exist, save as null
    CARequestingTenderDetails: answers[9]
      ? {
          tenderName: answers[9]?.["Tender Name"] || null, // Save null if empty
          tenderWebsite: answers[9]?.["Website"] || null, // Save null if empty
        }
      : null, // If answers[9] doesn't exist, save as null
    CAReporting:
      answers[10]?.map((option) => {
        const key = Object.keys(option)[0]; // Get the key of the object
        return key === "Other" ? option[key] : key; // If the key is "Other," pick the value; otherwise, pick the key
      }) || null, // Save null if empty
  });

  // Function to handle getting the next question
  const getNextQuestion = (currentQuestion, answer) => {
    // For MultiSelectDecisionBox
    if (currentQuestion.id === 7 && Array.isArray(answer)) {
      const selectedNextQuestions = currentQuestion.options
        .filter((option) => answer.includes(option.label)) // Filter the selected options
        .map((option) => option.nextQuestion) // Map to their nextQuestion IDs
        .filter((nextQuestionId) => nextQuestionId !== null); // Ignore nulls

      // If there are multiple selected options, return the first follow-up question to display
      return selectedNextQuestions.length > 0 ? selectedNextQuestions[0] : null;
    }

    // For other types like DecisionSelectBox or SelectBox
    // if (
    //   currentQuestion.type === "DecisionSelectBox" &&
    //   currentQuestion.options
    // ) {
    //   const selectedOption = currentQuestion.options.find(
    //     (opt) => opt.label === answer
    //   );
    //   return selectedOption?.nextQuestion ?? null;
    // }

    return currentQuestion.nextQuestion;
  };

  // Function to handle the previous question logic
  const getPreviousQuestion = (currentQuestion) => {
    return currentQuestion.previousQuestion;
  };
  // Function to handle answers without advancing to the next question
  const handleAnswer = (answer, questionId, label = null) => {
    setState((prevState) => {
      const currentQuestion = questions.find((q) => q.id === questionId);
      const updatedAnswers = { ...prevState.answers };

      // Handle special cases for MultiSelectBox
      if (answer === null && (questionId === 1 || questionId === 10) && label) {
        // For MultiSelectBox: Remove the specific option by its label
        updatedAnswers[questionId] = (updatedAnswers[questionId] || []).filter(
          (opt) => !(typeof opt === "object" && opt.hasOwnProperty(label))
        );
        if (updatedAnswers[questionId].length === 0) {
          delete updatedAnswers[questionId];
        }
      } else if (
        currentQuestion.type === "MultiSelectBox1" ||
        currentQuestion.type === "MultiSelectBox2"
      ) {
        const selectedOptions = updatedAnswers[questionId] || [];

        const optionKey = answer ? Object.keys(answer)[0] : label;

        const isAlreadySelected = selectedOptions.some(
          (opt) =>
            typeof opt === "object" &&
            opt.hasOwnProperty(answer ? Object.keys(answer)[0] : label)
        );

        let updatedOptions;

        if (isAlreadySelected) {
          // Replace the existing entry if `Other` is already selected
          updatedOptions = selectedOptions.map((opt) => {
            if (typeof opt === "object" && opt.hasOwnProperty(optionKey)) {
              return answer; // Replace with the new `answer` object
            }
            return opt;
          });
        } else {
          // Add the new answer if not already selected
          updatedOptions = [...selectedOptions, answer];
        }

        updatedAnswers[questionId] =
          updatedOptions.length > 0 ? updatedOptions : undefined;
      }

      //Handle MultiSelectDecisionBox answers
      else if (
        currentQuestion.type === "MultiSelectDecisionBox" &&
        questionId === 7
      ) {
        const selectedOptions = updatedAnswers[questionId] || [];
        const updatedOptions = selectedOptions.includes(answer)
          ? selectedOptions.filter((opt) => opt !== answer)
          : [...selectedOptions, answer];
        updatedAnswers[questionId] = updatedOptions;

        //if updatedOptions is empty, remove answer of question 7 from updatedAnswers
        if (updatedOptions.length === 0) {
          delete updatedAnswers[questionId];
          return { ...prevState, answers: updatedAnswers };
        }

        // Dynamically update the nextQuestion field based on selected options
        const selectedNextQuestions = currentQuestion.options
          .filter((option) => updatedOptions.includes(option.label))
          .map((option) => option.nextQuestion)
          .filter((nextQuestionId) => nextQuestionId !== null)
          .sort((a, b) => a - b); // Sort the selected next questions in ascending order

        // Check if there are selected options with next questions
        if (selectedNextQuestions.length > 0) {
          setQuestions((prevState) => {
            const updatedQuestions = [...prevState];

            // Set the initial question's nextQuestion to the first in the sequence
            const startIndex = updatedQuestions.findIndex(
              (q) => q.id === questionId
            );
            if (startIndex !== -1) {
              updatedQuestions[startIndex].nextQuestion =
                selectedNextQuestions[0];
            }

            // Loop through selectedNextQuestions to create the chain
            selectedNextQuestions.forEach((currentQuestionId, index) => {
              const currentIndex = updatedQuestions.findIndex(
                (q) => q.id === currentQuestionId
              );
              if (currentIndex !== -1) {
                // Set previousQuestion and nextQuestion based on the position in the array
                updatedQuestions[currentIndex].previousQuestion =
                  index === 0 ? questionId : selectedNextQuestions[index - 1];
                updatedQuestions[currentIndex].nextQuestion =
                  index < selectedNextQuestions.length - 1
                    ? selectedNextQuestions[index + 1]
                    : null;
              }
            });

            return updatedQuestions;
          });
        } else {
          // If no valid nextQuestion, set question 7's nextQuestion to null
          setQuestions((prevState) => {
            const updatedQuestions = [...prevState];
            updatedQuestions[
              updatedQuestions.findIndex((q) => q.id === questionId)
            ].nextQuestion = null;
            return updatedQuestions;
          });
        }
      }

      // Handle TextBox answers
      else if (currentQuestion.type === "TextBox") {
        const previousAnswers = updatedAnswers[questionId] || {};
        updatedAnswers[questionId] = { ...previousAnswers, ...answer };
      }

      // Handle CompanyDetails answers
      else if (currentQuestion.type === "CompanyDetails") {
        const previousAnswers = updatedAnswers[questionId] || {};
        updatedAnswers[questionId] = { ...previousAnswers, ...answer };
      }

      // For SelectBox and other types, store a single answer
      else {
        updatedAnswers[questionId] = answer;
      }

      // Update the state with the updated answers
      return { ...prevState, answers: updatedAnswers };
    });
  };

  // Handle moving to the next question when clicking "Next"
  const handleNext = async () => {
    const currentQuestion = questions[state.currentQuestionIndex];
    const answer = state.answers[currentQuestion.id];

    if (currentQuestion.id === 7 && Array.isArray(answer)) {
      // Handle multiple selected options for question 7
      const selectedNextQuestions = currentQuestion.options
        .filter((option) => answer.includes(option.label))
        .map((option) => option.nextQuestion)
        .filter((nextQuestionId) => nextQuestionId !== null);

      if (selectedNextQuestions.length > 0) {
        // Move to the next question based on dynamic selection
        setState((prevState) => ({
          ...prevState,
          currentQuestionIndex: questions.findIndex(
            (q) => q.id === selectedNextQuestions[0]
          ),
          isLastQuestion: false,
        }));
      } else {
        // If no more questions, finish onboarding
        const details = buildDetails(state.answers);
        saveOnBoardingDetails(details);

        //update business name and onboarding status
        activeUser.businessName = state.answers[3]?.["Company Name"];
        activeUser.onboardingCompleted = true;
        setActiveUserData(JSON.stringify(activeUser));

        // Fetch content image
        await fetchContentImage(activeUser);

        //update the state to show the getting started screen
        setState((prevState) => ({
          ...prevState,
          showQuestions: false,
          showGettingStarted: true,
        }));
      }
    } else {
      // For all other questions, move to the next question as normal
      const nextQuestionId = getNextQuestion(currentQuestion, answer);

      if (nextQuestionId) {
        setState((prevState) => ({
          ...prevState,
          currentQuestionIndex: questions.findIndex(
            (q) => q.id === nextQuestionId
          ),
          isLastQuestion: false,
        }));
      } else {
        // Finish the onboarding if there's no next question
        const details = buildDetails(state.answers);
        saveOnBoardingDetails(details);

        // Update the business name and onboarding status
        activeUser.businessName = state.answers[3]?.["Company Name"];
        activeUser.onboardingCompleted = true;
        setActiveUserData(JSON.stringify(activeUser));

        // Fetch content image
        await fetchContentImage(activeUser);

        // Show the getting started screen
        setState((prevState) => ({
          ...prevState,
          showQuestions: false,
          showGettingStarted: true,
        }));
      }
    }
  };

  // Handle moving to the previous question
  const handleBack = () => {
    const currentQuestion = questions[state.currentQuestionIndex];
    const previousQuestionId = getPreviousQuestion(currentQuestion);

    if (previousQuestionId) {
      setState((prevState) => ({
        ...prevState,
        currentQuestionIndex: questions.findIndex(
          (q) => q.id === previousQuestionId
        ),
      }));

      // Delete the answer for the current question
      setState((prevState) => {
        const updatedAnswers = { ...prevState.answers };
        delete updatedAnswers[currentQuestion.id];
        return { ...prevState, answers: updatedAnswers };
      });

      //reset errors
      setErrors({});
    }
  };

  // Function to fetch the content image
  const fetchContentImage = async (userData) => {
    setLoadingImage(true);
    const body = {
      requestedBy: userData.uid,
      contentType: "Milestone",
      title: `Welcome to ${getWhiteLabel().name}`,
      description: `Welcome to ${getWhiteLabel().name}`,
      imageName: "welcome.jpg",
      modifications: {
        companyName: userData.businessName,
        whitelabelName: getWhiteLabel().name,
      },
    };
    const response = await createContentImageAPI(body);
    // Extract image URL from response and set it in state
    if (response && response[0].imageDownloadURL) {
      setImageUrl(response[0].imageDownloadURL);
    }
    setLoadingImage(false);
  };

  // Handle skipping a question
  const handleSkip = () => {
    const currentQuestion = questions[state.currentQuestionIndex];

    // Reset the answer for the current question
    setState((prevState) => {
      const updatedAnswers = { ...prevState.answers };
      delete updatedAnswers[currentQuestion.id]; // Remove the answer of the current question
      // Set the updated state with the cleared answer
      return { ...prevState, answers: updatedAnswers };
    });

    //Set the isSkipped state to true
    setIsSkipped(true);

    // Update the state with the cleared answer and call handleNext after state update
    // handleNext(); // Ensure handleNext is called after the state is updated
  };

  // Call handleNext after the state is updated
  useEffect(() => {
    if (isSkipped) {
      handleNext();
      setIsSkipped(false);
    }
  }, [isSkipped]);

  // Function to check if the user is on the last question when answer is null
  const checkIfLastQuestion = (currentQuestion) => {
    const type = currentQuestion.type;

    if (type === "TextBox") {
      const nextQuestionId = currentQuestion.nextQuestion;
      if (nextQuestionId === null) {
        setState((prevState) => ({
          ...prevState,
          isLastQuestion: true,
        }));
      }
    } else {
      const nextQuestion = questions[state.currentQuestionIndex + 1] || null;

      // If no next question and we are not on the last question, it's the end of the onboarding
      if (nextQuestion === null) {
        setState((prevState) => ({
          ...prevState,
          isLastQuestion: true,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          isLastQuestion: false,
        }));
      }
    }
  };

  // Check if it’s the last question
  useEffect(() => {
    const currentQuestion = questions[state.currentQuestionIndex];
    const currentQuestionAnswer = state.answers[currentQuestion?.id] || null;

    if (currentQuestionAnswer !== null) {
      const nextQuestion = getNextQuestion(
        currentQuestion,
        currentQuestionAnswer
      );
      setState((prevState) => ({
        ...prevState,
        isLastQuestion: !nextQuestion,
      }));
    } else {
      checkIfLastQuestion(currentQuestion);
    }
  }, [state.currentQuestionIndex, state.answers]);

  // Safely access the current question
  const currentQuestion =
    questions && questions.length > 0
      ? questions[state.currentQuestionIndex]
      : null;

  let isAnswerSelected = false; // Initialize it as false

  // Check for type 'TextBox' with multiple keys (e.g., { Tender Name: 'ddd', Website: 'ki' })
  if (currentQuestion?.type === "TextBox") {
    const answer = state.answers[currentQuestion?.id];

    // If answer exists and it's an object, check if all keys have values
    if (answer && typeof answer === "object") {
      // Define the expected keys
      const expectedKeys =
        currentQuestion.id === 8
          ? ["Company Name", "Website"]
          : ["Tender Name", "Website"];

      //if any error exists, set isAnswerSelected to false
      if (Object.keys(errors).length > 0) {
        isAnswerSelected = false;
      } else {
        // Check if all expected keys are present AND have non-empty values
        isAnswerSelected = expectedKeys.every(
          (key) =>
            answer.hasOwnProperty(key) &&
            answer[key] !== undefined &&
            answer[key] !== ""
        );
      }
    }

    // Check if the answer is not empty
  } else if (currentQuestion?.type === "IntegrationSelectBox") {
    isAnswerSelected =
      state.answers[currentQuestion?.id] !== undefined &&
      state.answers[currentQuestion?.id] !== null &&
      state.answers[currentQuestion?.id] !== "";
  } else if (currentQuestion?.type === "CompanyDetails") {
    const answer = state.answers[currentQuestion?.id];

    // If answer exists and it's an object, check if all keys have values
    if (answer && typeof answer === "object") {
      // Define the expected keys
      const expectedKeys = ["Company Name", "Website", "Industry"];

      //if any error exists, set isAnswerSelected to false
      if (Object.keys(errors).length > 0) {
        isAnswerSelected = false;
      } else {
        // Check if all expected keys are present AND have non-empty values
        isAnswerSelected = expectedKeys.every(
          (key) =>
            answer.hasOwnProperty(key) &&
            answer[key] !== undefined &&
            answer[key] !== ""
        );
      }
    }
  } else if (
    currentQuestion?.type === "MultiSelectBox1" ||
    currentQuestion?.type === "MultiSelectBox2"
  ) {
    const answers = state.answers[currentQuestion?.id] || [];

    // Check if there are any selected options
    isAnswerSelected = answers.length > 0;

    // Check if all selected options have non-empty values
    if (isAnswerSelected) {
      // Check if every selected option has a non-empty value
      for (let i = 0; i < answers.length; i++) {
        const option = answers[i];
        if (typeof option === "object") {
          // If the option is an object, check if it has a non-empty value
          const key = Object.keys(option)[0];
          if (option[key] === undefined || option[key] === "") {
            isAnswerSelected = false;
            break;
          }
        }
      }
    }
  } else {
    // Default check for other types (like SelectBox, etc.)
    isAnswerSelected = state.answers[currentQuestion?.id] !== undefined;
  }

  return !state.showGettingStarted ? (
    <OnBoardingCardLayout
      leftSideContent={
        <div>
          <h2>
            <span style={{ color: "#005677" }}>{currentQuestion.question}</span>
          </h2>
          <p className="text-muted">{currentQuestion.description}</p>
        </div>
      }
      showQuestions={state.showQuestions}
      showBack={state.currentQuestionIndex > 0}
      skip={currentQuestion.skip}
      handleQuestions={handleShowQuestions}
      handleBack={handleBack}
      handleNext={handleNext}
      handleSkip={handleSkip}
      isLastQuestion={state.isLastQuestion}
      userName={state.userName}
      tooltipText={currentQuestion.tooltipText}
      isAnswerSelected={isAnswerSelected}
      isFinishing={loadingImage}
    >
      <Onboarding
        currentQuestion={currentQuestion}
        answers={state.answers}
        handleAnswer={handleAnswer}
        currentQuestionIndex={state.currentQuestionIndex}
        errors={errors}
        setErrors={setErrors}
      />
    </OnBoardingCardLayout>
  ) : (
    <GettingStarted loading={loadingImage} imageURL={imageUrl} />
  );
};

export default LandingPage;
