import React, { useState } from "react";
import { Button, Card, Col, Form, Row, Alert } from "react-bootstrap";
import invite from "../../Assets/img/icons/spot-illustrations/16.png";
import { useAuth } from "../../Contexts/AuthContext";
import { BsExclamationCircleFill } from "react-icons/bs";
import { getAuthorizationHeader } from "../../helpers/utils";
import { clearLoginData } from "../../helpers/store";

const sendInviteEmail = async (userName, email) => {
  const response = await fetch(
    `/api/users/user-invite?name=${userName}&email=${email}`,
    {
      method: "POST",
      headers: getAuthorizationHeader(),
      mode: "cors",
    }
  );

  if (response.status === 401) {
    clearLoginData();
  }
};

const InviteUser = () => {
  const { currentUser } = useAuth();
  const [values, setValues] = useState({
    email: "",
  });
  const [business, setBusiness] = useState(
    sessionStorage.getItem("businessId")
  );

  const [formErrors, setFormErrors] = useState({});
  const [showMessage, setShowMessage] = useState(false);

  const handleChange = (event) => {
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));

    console.log(values);
  };

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    console.log("handlesubmit");
    if (validate(values)) {
      console.log("Send Email");
      sendInviteEmail(currentUser.displayName, values.email);
      setShowMessage(true);
    } else {
      setShowMessage(false);
    }
  };

  const validate = (values) => {
    console.log("Validate the form....");
    let errors = {};

    //email field
    if (!values.email) {
      errors.email = "Email address is required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = "Email address is invalid";
    }
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Card>
        <Card.Body className="text-center py-5">
          <Row className="justify-content-center">
            <Col xs={7} md={5}>
              <img className="img-fluid" src={invite} alt="image" width="58%" />
            </Col>
          </Row>
          <h3 className="mb-0 mt-4">Invite User by Email</h3>
          <p className="mt-2 mb-4">
            User added will be able to add new data to your business and view
            all dashboards.
          </p>
          <Row className="justify-content-center">
            <Col md={7}>
              <Form as={Row} className="g-2">
                <Col sm>
                  <Form.Control
                    required
                    type="email"
                    name="email"
                    placeholder="Email address"
                    value={values.email}
                    onChange={handleChange}
                  />

                  {formErrors.email && (
                    <>
                      <p className="text-danger dangerMessage mt-2">
                        <BsExclamationCircleFill className="mb-1" />{" "}
                        {formErrors.email}
                      </p>
                    </>
                  )}
                </Col>
                <Col sm="auto">
                  <Button
                    variant="primary"
                    className="d-block w-100"
                    type="submit"
                    onClick={() => handleSubmit()}
                  >
                    Send Invitation
                  </Button>
                </Col>
              </Form>
            </Col>
          </Row>

          <Row className="justify-content-center mt-3">
            <Col md={7}>
              <Alert
                className="successAlert"
                show={showMessage}
                variant="success"
                dismissible
                onClose={() => setShowMessage(false)}
              >
                The Invitation Has Been Sent!
              </Alert>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default InviteUser;
