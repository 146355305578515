import React from "react";
import { Button, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faUndo } from "@fortawesome/free-solid-svg-icons";

const OfficeLocationManagementHeader = ({
  reportingPeriod,
  title,
  selectedCount,
  onAddLocation,
  isChangesMade,
  handleSave,
  handleClearChanges, // Add the new prop
  isLoading,
}) => {
  return (
    <div className="d-flex justify-content-between align-items-center mb-3">
      <div>
        <h4>Office Locations for {reportingPeriod}</h4>
        <p className="text-muted">{title}</p>
      </div>
      <div className="d-flex align-items-center">
        <span
          className="me-3"
          style={{ color: selectedCount === 0 ? "red" : "inherit" }}
        >
          {selectedCount} {selectedCount === 1 ? "office" : "offices"} selected
        </span>
        <Button
          variant="primary"
          className="me-2"
          onClick={onAddLocation}
          disabled={isLoading}
        >
          + Add Location
        </Button>
        {isChangesMade && (
          <>
            <Button
              variant="outline-warning"
              onClick={handleSave}
              type="button"
              disabled={isLoading}
              className="me-2 d-flex align-items-center"
            >
              {isLoading ? (
                <>
                  <Spinner
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="ms-2">Saving...</span>
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faSave} className="me-2" />
                  <span>Save Changes</span>
                </>
              )}
            </Button>
            <Button
              variant="outline-danger"
              onClick={handleClearChanges} // Link the new button to handleClearChanges
              type="button"
              disabled={isLoading}
              className="d-flex align-items-center"
            >
              <FontAwesomeIcon icon={faUndo} className="me-2" />
              <span>Clear Changes</span>
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default OfficeLocationManagementHeader;
