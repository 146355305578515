import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import NewOverviewCard from "../new-overview/newOverviewCard";
import { fa1, fa2 } from "@fortawesome/free-solid-svg-icons";

function ActivityDataModal({ showActivityDataModal, toggleActivityDataModal }) {
  return (
    <Modal
      show={showActivityDataModal}
      onHide={toggleActivityDataModal}
      size="xl"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Add activity data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-2">
          <Col>
            <NewOverviewCard
              title="Upload Invoices"
              descriptionIncompleted="Add invoices from your energy provider and we will calculate emissions based on your consumption"
              descriptionCompleted="Invoices uploaded successfully. Feel free to add more data or edit the existing data"
              buttonTextIncompleted="Upload Invoices "
              buttonTextCompleted="Invoices Added"
              isCompleted={false}
              link="/welcome/data/file-uploader"
              icon={fa1}
              tutorialLink="https://www.netnada.com.au/documentation/adding-invoices-to-activity-data"
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={toggleActivityDataModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ActivityDataModal;
