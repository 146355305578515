import React, { useState, forwardRef } from "react";
import PropTypes from "prop-types";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { BsInfoCircle } from "react-icons/bs"; // Import the BsInfoCircle icon

const CustomDateInput = forwardRef(
  (
    { value, onClick, isInvalid, isValid, formControlProps, errorMessage },
    ref
  ) => {
    return (
      <>
        <Form.Control
          ref={ref}
          isInvalid={isInvalid}
          isValid={isValid}
          value={value}
          onClick={onClick}
          {...formControlProps}
        />
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      </>
    );
  }
);

const BusinessDetailsWizardInput = ({
  label,
  name,
  errors,
  type = "text",
  options = [],
  placeholder,
  formControlProps,
  formGroupProps,
  setValue,
  datepickerProps,
  handleChange,
  disable,
  subText, // Added subText prop
}) => {
  const [date, setDate] = useState(null);

  const renderLabel = () => <Form.Label>{label}</Form.Label>;

  const renderSubText = () =>
    !!subText && (
      <div
        className="d-flex align-items-center"
        style={{ paddingBottom: 12, marginTop: -6 }}
      >
        <Form.Text className="text-muted">{subText}</Form.Text>
        <OverlayTrigger
          placement="right"
          overlay={<Tooltip id={`tooltip-${name}`}>{subText}</Tooltip>}
        >
          <BsInfoCircle
            className="ms-1 text-info"
            style={{ cursor: "pointer" }}
          />
        </OverlayTrigger>
      </div>
    );

  if (type === "date") {
    return (
      <Form.Group {...formGroupProps}>
        {!!label && renderLabel()}
        {renderSubText()}
        <DatePicker
          selected={date}
          onChange={(date) => {
            setDate(date);
            setValue(name, date);
          }}
          customInput={
            <CustomDateInput
              formControlProps={formControlProps}
              errorMessage={errors[name]?.message}
              isInvalid={errors[name]}
              isValid={Object.keys(errors).length > 0 && !errors[name]}
            />
          }
          {...datepickerProps}
        />
      </Form.Group>
    );
  }

  if (["checkbox", "switch", "radio"].includes(type)) {
    return (
      <Form.Group {...formGroupProps}>
        <Form.Check type={type} id={name + Math.floor(Math.random() * 100)}>
          <Form.Check.Input
            type={type}
            {...formControlProps}
            isInvalid={errors[name]}
            isValid={Object.keys(errors).length > 0 && !errors[name]}
          />
          <Form.Check.Label className="ms-2">{label}</Form.Check.Label>
        </Form.Check>
        {renderSubText()}
        <Form.Control.Feedback type="invalid" className="mt-0">
          {errors[name]?.message}
        </Form.Control.Feedback>
      </Form.Group>
    );
  }

  if (
    [
      "select",
      "selectCountry",
      "selectState",
      "selectPrimaryCategory",
      "selectSecondaryCategory",
    ].includes(type)
  ) {
    return (
      <Form.Group {...formGroupProps}>
        {renderLabel()}
        {renderSubText()}
        <Form.Select
          type="select"
          {...formControlProps}
          onChange={handleChange}
          disabled={disable}
          isInvalid={errors[name]}
          isValid={Object.keys(errors).length > 0 && !errors[name]}
        >
          <option value="">{placeholder}</option>
          {options.map((option, index) => (
            <option
              key={index}
              value={
                ["selectCountry", "selectState"].includes(type)
                  ? `${option.name},${option.code}`
                  : option
              }
            >
              {option.name || option}
            </option>
          ))}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {errors[name]?.message}
        </Form.Control.Feedback>
      </Form.Group>
    );
  }

  if (type === "textarea") {
    return (
      <Form.Group {...formGroupProps}>
        {renderLabel()}
        {renderSubText()}
        <Form.Control
          as="textarea"
          placeholder={placeholder}
          {...formControlProps}
          isValid={Object.keys(errors).length > 0 && !errors[name]}
          isInvalid={errors[name]}
          rows={4}
        />
        <Form.Control.Feedback type="invalid">
          {errors[name]?.message}
        </Form.Control.Feedback>
      </Form.Group>
    );
  }

  return (
    <Form.Group {...formGroupProps}>
      {renderLabel()}
      {renderSubText()}
      <Form.Control
        type={type}
        placeholder={placeholder}
        {...formControlProps}
        isInvalid={errors[name]}
        isValid={Object.keys(errors).length > 0 && !errors[name]}
      />
      <Form.Control.Feedback type="invalid">
        {errors[name]?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

CustomDateInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  isInvalid: PropTypes.bool,
  isValid: PropTypes.bool,
  formControlProps: PropTypes.object,
  errorMessage: PropTypes.string,
};

BusinessDetailsWizardInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  type: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  formControlProps: PropTypes.object,
  formGroupProps: PropTypes.object,
  setValue: PropTypes.func,
  datepickerProps: PropTypes.object,
  subText: PropTypes.string, // Added subText to PropTypes
};

BusinessDetailsWizardInput.defaultProps = { required: false };

export default BusinessDetailsWizardInput;
