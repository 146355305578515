import React, { useEffect } from "react";
import { Spinner, Col, Row, Dropdown, Form } from "react-bootstrap";
import { useState } from "react";
import WidgetSectionTitle from "../../widgets/WidgetSectionTitle";
import AdvanceTableWrapper from "../common/advance-table/AdvanceTableWrapper";
import { Card } from "react-bootstrap";
import AdvanceTable from "../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../common/advance-table/AdvanceTableFooter";
import AdvanceTableSearchBox from "../common/advance-table/AdvanceTableSearchBox";
import { getUserTickets, updateUserTicketStatus } from "./adminApi";
import { useAuth } from "../../Contexts/AuthContext";
import CardDropdown from "../common/CardDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faCheck, faStream } from "@fortawesome/free-solid-svg-icons";
import SoftBadge from "../common/SoftBadge";

const UserTickets = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { currentUser } = useAuth();
  const [userTickets, setUserTickets] = useState([]);
  const [ticketStatus, setTicketStatus] = useState("OPEN");

  const columns = [
    {
      accessor: "",
      Header: "Actions",
      Cell: (rowData) => {
        return (
          <>
            <CardDropdown>
              <div className="py-2">
                {currentUser.role === "SUPER_ADMIN" &&
                  rowData.row.original.status === "OPEN" && (
                    <Dropdown.Item
                      as="button"
                      onClick={() => handleCloseTicket(rowData.row.original)}
                    >
                      Close Ticket
                    </Dropdown.Item>
                  )}
              </div>
            </CardDropdown>
          </>
        );
      },
    },
    {
      accessor: "id",
      Header: "Ticket Id",
    },
    {
      accessor: "email",
      Header: "Email",
    },
    {
      accessor: "business_id",
      Header: "Business Id",
    },
    {
      accessor: "name",
      Header: "Name",
    },
    {
      accessor: "description",
      Header: "Description",
    },
    {
      accessor: "status",
      Header: "Status",
      headerProps: { className: "text-center" },
      cellProps: {
        className: "fs-0",
      },
      Cell: (rowData) => {
        const { status } = rowData.row.original;
        return (
          <>
            <SoftBadge
              pill
              bg={status === "CLOSED" ? "success" : "warning"}
              className="d-block"
            >
              {status}
              <FontAwesomeIcon
                icon={status === "Completed" ? faCheck : faStream}
                transform="shrink-2"
                className="ms-1"
              />
            </SoftBadge>
          </>
        );
      },
    },

    // {
    //   accessor: "created_at",
    //   Header: "Created At",
    // },
    {
      accessor: "modified_at",
      Header: "Updated At",
    },
  ];

  async function handleUserTicketStatusFilterChange(status) {
    setTicketStatus(status);
    await setUserTicketsData(status);
  }

  async function handleCloseTicket(ticket) {
    setIsLoading(true);
    await updateUserTicketStatus({ id: ticket.id, status: "CLOSED" });
    await setUserTicketsData(ticketStatus);
  }

  async function setUserTicketsData(status) {
    const response = await getUserTickets(status);
    setUserTickets(response);
    setIsLoading(false);
  }

  useEffect(() => {
    setUserTicketsData(ticketStatus);
  }, []);

  return (
    <>
      <WidgetSectionTitle
        icon={faList}
        title="User Tickets"
        subtitle="List of users tickets"
        transform="shrink-2"
        className="mb-4 mt-6"
      />

      {isLoading && (
        <div className="mb-3">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}

      <div className="mb-3">
        <AdvanceTableWrapper
          columns={columns}
          data={userTickets}
          sortable
          pagination
          perPage={25}
          rowCount={userTickets.length}
        >
          <Card>
            <Card.Header>
              <Row className="flex-end-center mb-3">
                <Col sm={4} lg={2}>
                  <Form.Select
                    aria-label="Default select"
                    value={ticketStatus}
                    onChange={(e) =>
                      handleUserTicketStatusFilterChange(e.target.value)
                    }
                  >
                    <option value="OPEN">OPEN</option>
                    <option value="CLOSED">CLOSED</option>
                  </Form.Select>
                </Col>
                <Col xs="auto" sm={6} lg={4}>
                  <AdvanceTableSearchBox table />
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="btn-reveal-trigger text-nowrap align-middle"
                tableProps={{
                  size: "lg",
                  className: "fs--1 mb-0 overflow-hidden",
                }}
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTableFooter
                data={userTickets}
                rowCount={userTickets.length}
                table
                rowInfo
                navButtons
              />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </div>
    </>
  );
};

export default UserTickets;
