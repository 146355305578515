import React, { useEffect, useState } from "react";
import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
import { useAuth } from "../../../Contexts/AuthContext";
import AllInvoiceTable from "./AllInvoiceTable";
import WidgetSectionTitle from "../../../widgets/WidgetSectionTitle";
import {
  faBan,
  faCheck,
  faList,
  faStream,
} from "@fortawesome/free-solid-svg-icons";
import { getAllFiles } from "../../new-overview/newOverviewAPI";

const recentPurchaseTableBadge = {
  Success: { content: "Success", type: "success", icon: faCheck },
  Blocked: { content: "Blocked", type: "secondary", icon: faBan },
  Pending: { content: "Pending", type: "warning", icon: faStream },
};

const Widgets = () => {
  const { currentUser } = useAuth();
  useEffect(() => {
    const analytics = getAnalytics();
    setUserId(analytics, currentUser.uid);
    setUserProperties(analytics, { businessId: currentUser.businessId });
    logEvent(analytics, "page_view", {
      page_title: "All Invoices",
    });
  }, [currentUser]);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //fetch list of offices for the user
  const [nameId, setNameId] = useState(() => {
    const businessOffices = JSON.parse(
      sessionStorage.getItem("businessOffices")
    );
    let nameIdDict = {};
    for (let i = 0; i < businessOffices.length; i++) {
      let str = businessOffices[i].split(",");
      nameIdDict[str[1]] = str[0];
    }
    return nameIdDict;
  });

  const getUploads = async () => {
    try {
      setIsLoading(true);
      const jsonData = await getAllFiles();

      //set status jsonData
      for (var i = 0; i < jsonData.length; i++) {
        const status = jsonData[i]["approval_status"];
        jsonData[i]["status"] = recentPurchaseTableBadge[status];
      }
      //format start and end dates
      for (var j = 0; j < jsonData.length; j++) {
        const startDate = jsonData[j]["start_date"];
        jsonData[j]["startDate"] = new Date(startDate);
        const endDate = jsonData[j]["end_date"];
        jsonData[j]["endDate"] = new Date(endDate);
      }

      //set document jsonData
      for (var k = 0; k < jsonData.length; k++) {
        const name = jsonData[k]["document_name"];
        const url = jsonData[k]["document_url"];
        jsonData[k]["document"] = { name, url };
      }

      //set location name
      for (var l = 0; l < jsonData.length; l++) {
        const locationId = jsonData[l]["office_id"];
        jsonData[l]["location"] = nameId[locationId];
      }

      setData(jsonData);
      setIsLoading(false);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getUploads();
  }, []);
  return (
    <>
      <>
        <WidgetSectionTitle
          icon={faList}
          title="Data Uploader"
          subtitle="Upload all your expenses for analysis during the reporting period."
          transform="shrink-2"
          className="mb-4 mt-6"
        />
        <div className="mb-3">
          <AllInvoiceTable
            data={data}
            getUploads={getUploads}
            isLoading={isLoading}
            isAdmin={false}
          />
        </div>
      </>
    </>
  );
};

export default Widgets;
