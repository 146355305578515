import { clearLoginData } from "../../helpers/store";
import { getAuthorizationHeaderWithContentType } from "../../helpers/utils";

export const submitEngageSupplier = async (businessId, data) => {
    
  try {
    const response = await fetch(
      `/api/users/update-supplier-engagement/${businessId}`,
      {
        method: "PUT",
        headers: getAuthorizationHeaderWithContentType(),
        body: JSON.stringify(data),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      console.log(jsonData);
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};
