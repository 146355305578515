import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faBan, faStream } from "@fortawesome/free-solid-svg-icons";
import SoftBadge from "../../components/common/SoftBadge";
import AdvanceTableWrapper from "../../components/common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../components/common/advance-table/AdvanceTable";
import GrantTableHeader from "./grantTableHeader";
import AdvanceTableFooter from "../../components/common/advance-table/AdvanceTableFooter";
import { getSelectedGrants } from "./grantsAPI";
import { useAuth } from "../../Contexts/AuthContext";

const recentPurchaseTableBadge = {
  Completed: { content: "Success", type: "success", icon: faCheck },
  InProgress: { content: "In-Progress", type: "warning", icon: faStream },
};

const columns = [
  {
    accessor: "location",
    Header: "Location",
    headerProps: { className: "pe-7" },
  },
  {
    accessor: "granttitle",
    Header: "Grant Name",
    headerProps: { className: "pe-1" },
    cellProps: {
      className: "fw-semi-bold",
    },
  },
  {
    accessor: "grantprovider",
    Header: "Provider",
    headerProps: { className: "pe-7" },
  },
  {
    accessor: "startDateString",
    Header: "Date Added",
  },
  {
    accessor: "approval_status",
    Header: "Status",

    headerProps: { className: "text-center" },
    cellProps: {
      className: "fs-0",
    },
    Cell: (rowData) => {
      const { status } = rowData.row.original;
      return (
        <SoftBadge pill bg={status.type} className="d-block">
          {status.content}
          <FontAwesomeIcon
            icon={status.icon}
            transform="shrink-2"
            className="ms-1"
          />
        </SoftBadge>
      );
    },
  },
];

const GrantTable = () => {
  const { currentUser } = useAuth();
  const [business, setBusiness] = useState(currentUser.businessId);
  const [data, setData] = useState(null);

  //fetch list of offices for the business
  const [nameId, setNameId] = useState(() => {
    const businessOffices = JSON.parse(
      sessionStorage.getItem("businessOffices")
    );
    let nameIdDict = {};
    for (let i = 0; i < businessOffices.length; i++) {
      let str = businessOffices[i].split(",");
      console.log(str);
      nameIdDict[str[1]] = str[0];
    }
    return nameIdDict;
  });

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [day, month, year].join("/");
  }

  const getGrants = async () => {
    try {
      const grants = await getSelectedGrants();
      console.log(grants);

      //set status grants
      for (var i = 0; i < grants.length; i++) {
        const status = grants[i]["progress_status"];
        //console.log(recentPurchaseTableBadge[status])
        grants[i]["status"] = recentPurchaseTableBadge[status];
      }
      //format start date
      for (var j = 0; j < grants.length; j++) {
        const startDate = grants[j]["date_added"];
        grants[j]["startDateString"] = formatDate(startDate);
      }

      //set location name
      for (var l = 0; l < grants.length; l++) {
        const locationId = grants[l]["office_id"];
        grants[l]["location"] = nameId[locationId];
      }
      //store all selected grants in session as string
      let selectedGrants = [];
      for (let i in grants) selectedGrants.push(grants[i]);
      sessionStorage.setItem("selectedGrants", JSON.stringify(selectedGrants));
      console.log(grants);

      setData(grants);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getGrants();
  }, []);
  return (
    <>
      {data ? (
        <AdvanceTableWrapper
          columns={columns}
          data={data}
          selection
          sortable
          pagination
          perPage={7}
          rowCount={data.length}
        >
          <Card>
            <Card.Header>
              <GrantTableHeader table />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="btn-reveal-trigger text-nowrap align-middle"
                tableProps={{
                  size: "sm",
                  className: "fs--1 mb-0 overflow-hidden",
                }}
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTableFooter
                rowCount={data.length}
                table
                rowInfo
                navButtons
              />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      ) : null}
    </>
  );
};

export default GrantTable;
