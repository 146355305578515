import { faCheck, faStream } from "@fortawesome/free-solid-svg-icons";

import { Card, Col, Row } from "react-bootstrap";
import AdvanceTableWrapper from "../../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../../common/advance-table/AdvanceTableFooter";
import AllFormsTableHeader from "./AllFormsTableHeader";
import { useEffect, useMemo, useState } from "react";
import FlightDetailDataModalTable from "./FlightDetailDataModalTable";
import { flightColumns } from "./flightColumns";
import { allFormsColumns } from "./allFormsColumns";
import { generalColumns } from "./generalColumns";
import AdvanceTableSearchBox from "../../common/advance-table/AdvanceTableSearchBox";

const recentPurchaseTableBadge = {
  Completed: {
    content: "Submitted",
    type: "success",
    icon: faCheck,
  },
  InProgress: { content: "Saved as draft", type: "warning", icon: faStream },
  SUBMITTED: {
    content: "Submitted",
    type: "success",
    icon: faCheck,
  },
  DRAFT: { content: "Saved as draft", type: "warning", icon: faStream },
};

const AllFormsTable = ({ forms, name, handleClose, isLoading }) => {
  const [data, setData] = useState(null);
  const [selectedFlightData, setSelectedFlightData] = useState(null);
  const [showFlightDataModal, setShowFlightDataModal] = useState(false);

  function handleFlightDataOpen(data) {
    setSelectedFlightData(data);
    setShowFlightDataModal(true);
  }

  function handleFlightDataClose() {
    setSelectedFlightData(null);
    setShowFlightDataModal(false);
  }

  const getFormColumns = (formType) => {
    switch (formType) {
      case "flight":
        return flightColumns(handleFlightDataOpen);
      case "all":
        return generalColumns;
      default:
        return allFormsColumns;
    }
  };

  const columnsToRender = useMemo(() => getFormColumns(name), [name]);

  function storeData(forms) {
    if (forms) {
      try {
        //set status of forms
        for (var i = 0; i < forms.length; i++) {
          const status = forms[i]["completion_status"];
          forms[i]["status"] = recentPurchaseTableBadge[status];
        }
        // console.log("forms", forms);
        setData(forms);
      } catch (err) {
        console.error(err.message);
      }
    }
  }

  useEffect(() => {
    storeData(forms);
  }, [forms]);

  const noDataMessages = {
    title: `No ${name.charAt(0).toUpperCase() + name.slice(1)} Forms Found`,
    subtitle: "Consider adding some activity data",
  };
  return (
    <>
      {showFlightDataModal && (
        <FlightDetailDataModalTable
          data={selectedFlightData}
          showModal={showFlightDataModal}
          handleClose={handleFlightDataClose}
        />
      )}
      {data ? (
        <AdvanceTableWrapper
          columns={columnsToRender}
          data={data}
          selection
          sortable
          pagination
          perPage={15}
          rowCount={(data || []).length}
        >
          <Card>
            <Card.Header>
              <Row className="align-items-center mb-3">
                <Col>
                  <AllFormsTableHeader
                    table
                    name={name}
                    data={data}
                    handleClose={handleClose}
                  />
                </Col>
                <Col xs="auto">
                  <AdvanceTableSearchBox table />
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="btn-reveal-trigger text-nowrap align-middle"
                tableProps={{
                  size: "sm",
                  className: "fs--1 mb-0 overflow-hidden",
                }}
                noDataMessage={noDataMessages}
                isLoading={isLoading}
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTableFooter
                rowCount={(data || []).length}
                table
                rowInfo
                navButtons
              />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      ) : null}
    </>
  );
};

export default AllFormsTable;
