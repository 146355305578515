import { useState } from "react";
import { Card, Nav, Tab } from "react-bootstrap";
import Flex from "../common/Flex";
import CalculatorCard from "./CalculatorCard";

import AllCalculators from "./AllCalculators";
import WasteCard from "./WasteCard";
import WaterCard from "./WaterCard";

const NavItem = ({ item }) => {
  return (
    <Nav.Item as="li">
      <Nav.Link
        className="mb-0 py-3 cursor-pointer"
        eventKey={item.toLowerCase().replace(/\s+/g, "")}
      >
        {item}
      </Nav.Link>
    </Nav.Item>
  );
};

function CalculatorsCard({ data, wasteData, waterData, businessPreference }) {
  // console.log("Data", data);
  // console.log("wasteData", wasteData);

  const [navItems] = useState([
    "All",
    "Waste",
    "WFH",
    "Paper",
    "Flights",
    "Employee Commute",
    "Water",
  ]);

  const defaultActiveKey = businessPreference === "waste" ? "waste" : "all";

  return (
    <Card className="mt-3">
      <Tab.Container id="audience-tab" defaultActiveKey={defaultActiveKey}>
        <Card.Header
          as={Flex}
          justifyContent="between"
          alignItems="center"
          className="ps-0 py-0 border-bottom"
        >
          <Nav
            as="ul"
            className="nav-tabs border-0 flex-nowrap chart-tab tab-active-caret"
          >
            {navItems.map((item) => (
              <NavItem item={item} key={item} />
            ))}
          </Nav>
        </Card.Header>

        <Card.Body>
          <Tab.Content>
            <Tab.Pane unmountOnExit eventKey="all">
              <AllCalculators data={data?.orderedMonthlyData} />
            </Tab.Pane>
            <Tab.Pane unmountOnExit eventKey="waste">
              <WasteCard data={wasteData} />
            </Tab.Pane>
            <Tab.Pane unmountOnExit eventKey="wfh">
              <CalculatorCard
                data={data.orderedMonthlyData}
                keyProp="WFH"
                color="#00688b"
              />
            </Tab.Pane>
            <Tab.Pane unmountOnExit eventKey="paper">
              <CalculatorCard
                data={data?.orderedMonthlyData}
                keyProp="Paper"
                color="#4da6cc"
              />
            </Tab.Pane>
            <Tab.Pane unmountOnExit eventKey="flights">
              <CalculatorCard
                data={data?.orderedMonthlyData}
                keyProp="Flights"
                color="#ffd099"
              />
            </Tab.Pane>
            <Tab.Pane unmountOnExit eventKey="employeecommute">
              <CalculatorCard
                data={data?.orderedMonthlyData}
                keyProp="EmployeeCommute"
                color="#ffb066"
              />
            </Tab.Pane>
            <Tab.Pane unmountOnExit eventKey="water">
              {/* {console.log("water dataaa", waterData)} */}
              {/* <CalculatorCard
                data={data?.orderedMonthlyData}
                keyProp="Water"
                color="#0c8bf2"
              /> */}
              <WaterCard data={waterData} />
            </Tab.Pane>
          </Tab.Content>
        </Card.Body>
      </Tab.Container>
    </Card>
  );
}

export default CalculatorsCard;
