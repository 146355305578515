import { Link } from "react-router-dom";
import Flex from "../../common/Flex";
import { getFormattedDate } from "../../../helpers/utils";
import SoftBadge from "../../common/SoftBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export const allFormsColumns = [
  {
    accessor: "name",
    Header: "Form Name",
    headerProps: { className: "pe-1" },
    cellProps: {
      className: "fw-semi-bold",
    },
    Cell: (rowData) => {
      const row = rowData.row.original;
      const { category } = row;
      if (category === "Staff") {
        const { id, completion_status } = rowData.row.original;
        return (
          <Flex>
            <div>
              {completion_status === "DRAFT" && (
                <Link to={"/welcome/data/climate-active/staff-form/" + id}>
                  Staff Form
                </Link>
              )}
              {completion_status === "SUBMITTED" && "Staff Form"}
            </div>
          </Flex>
        );
      }
      if (category === "Flight") {
        return (
          <Flex>
            <div>{"Flight Form"}</div>
          </Flex>
        );
      }
      if (category === "Employee Engagement") {
        // const { id, name, link, uid } = row;
        const { link, id, name, start_date, end_date, created_at, status } =
          row;
        return (
          <>
            <Link
              to={link}
              state={{
                employeeEngagementFormId: id,
                name,
                startDate: start_date,
                endDate: end_date,
                createdAt: created_at,
                status:
                  status?.content === "Submitted"
                    ? "Completed"
                    : status?.content || "Default Value",
              }}
            >
              {name}
            </Link>
          </>
        );
      }
      const { id, name, link } = row;
      return (
        <>
          {category === "Waste" ? (
            <Link to={link} state={{ formId: id }}>
              {name}
            </Link>
          ) : (
            <Link to={link} state={{ row: row }}>
              {name}
            </Link>
          )}
        </>
      );
    },
  },
  {
    accessor: "start_date",
    Header: "Start Date",
    Cell: (rowData) => {
      const { category } = rowData.row.original;
      if (category === "Staff" || category === "Flight") {
        const { start_date } = rowData.row.original;
        return start_date ? getFormattedDate(start_date) : "";
      }
      const { startdate } = rowData.row.original;
      return getFormattedDate(startdate);
    },
  },
  {
    accessor: "enddate",
    Header: "End Date",
    Cell: (rowData) => {
      const { category } = rowData.row.original;
      if (category === "Staff" || category === "Flight") {
        const { end_date } = rowData.row.original;
        return end_date ? getFormattedDate(end_date) : "";
      }
      const { enddate } = rowData.row.original;
      return getFormattedDate(enddate);
    },
  },
  {
    accessor: "status",
    Header: "Completion Status",
    headerProps: { className: "text-center" },
    cellProps: {
      className: "fs-0",
    },
    Cell: (rowData) => {
      const { status } = rowData.row.original;
      return (
        <SoftBadge pill bg={status.type} className="d-block">
          {status.content}
          <FontAwesomeIcon
            icon={status.icon}
            transform="shrink-2"
            className="ms-1"
          />
        </SoftBadge>
      );
    },
  },
];
