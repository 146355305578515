import React, { useEffect, useState } from "react";
// import Chart from "./chart";
import WidgetSectionTitle from "../../../widgets/WidgetSectionTitle";
import { Col, Row } from "react-bootstrap";
import PageHeader from "../../common/PageHeader";
import TotalEmissionsCard from "./totalEmissionsCard";
import { fetchEventFormData } from "./eventDashboardHelper";
import { faList } from "@fortawesome/free-solid-svg-icons";

const Index = ({ eventDetails }) => {
  console.log("eventDetails: ", eventDetails);

  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);
  const [totalPerAttendee, setTotalPerAttendee] = useState(0);
  const [totalPerAttendeeStatus, setTotalPerAttendeeStatus] = useState(false);
  const [totalStatus, setTotalStatus] = useState(false);
  const [total, setTotal] = useState(0);

  const fetch = async (eventId, businessId, numberOfPeople) => {
    let l = [];
    let d = [];
    let totalEmissions = 0;
    if (eventId) {
      //fetch water data
      const waterTotalEmissions = await fetchEventFormData(
        businessId,
        eventId,
        "Water"
      );
      if (waterTotalEmissions) {
        l.push("Water");
        d.push(waterTotalEmissions);
        totalEmissions += waterTotalEmissions;
      }

      //fetch waste data
      const wasteTotalEmissions = await fetchEventFormData(
        businessId,
        eventId,
        "Waste"
      );
      if (wasteTotalEmissions) {
        l.push("Waste");
        d.push(wasteTotalEmissions);
        totalEmissions += wasteTotalEmissions;
      }

      //fetch food data
      const foodTotalEmissions = await fetchEventFormData(
        businessId,
        eventId,
        "Food"
      );
      if (foodTotalEmissions) {
        l.push("Food");
        d.push(foodTotalEmissions);
        totalEmissions += foodTotalEmissions;
      }

      //fetch flight data
      const flightTotalEmissions = await fetchEventFormData(
        businessId,
        eventId,
        "Flight"
      );
      if (flightTotalEmissions) {
        l.push("Flight");
        d.push(flightTotalEmissions);
        totalEmissions += flightTotalEmissions;
      }

      //fetch local transport data
      const localTransportTotalEmissions = await fetchEventFormData(
        businessId,
        eventId,
        "Local Transport"
      );
      if (localTransportTotalEmissions) {
        l.push("Local Transport");
        d.push(localTransportTotalEmissions);
        totalEmissions += localTransportTotalEmissions;
      }

      //fetch regional ground transport data
      const regionalGroundTransportTotalEmissions = await fetchEventFormData(
        businessId,
        eventId,
        "Regional Ground Transport"
      );
      if (regionalGroundTransportTotalEmissions) {
        l.push("Regional Ground Transport");
        d.push(regionalGroundTransportTotalEmissions);
        totalEmissions += regionalGroundTransportTotalEmissions;
      }
      setLabels(l);
      setData(d);
      setTotal(totalEmissions);
      setTotalStatus(true);
      setTotalPerAttendee(totalEmissions / numberOfPeople);
      setTotalPerAttendeeStatus(true);
    }
  };

  useEffect(() => {
    if (eventDetails) {
      const { id, business_id, num_of_people } = eventDetails;
      console.log("Event Id: ", id);
      console.log("Business Id: ", business_id);
      console.log("Number of people: ", num_of_people);
      fetch(id, business_id, num_of_people);
    }
  }, [eventDetails]);
  return (
    <>
      <WidgetSectionTitle
        icon={faList}
        title="Dashboard"
        subtitle="Total emissions and break down by scope."
        transform="shrink-2"
        className="mb-4 mt-6"
      />
      <PageHeader
        title="Total Emissions"
        description="The component displays total emissions"
        className="mb-3"
      ></PageHeader>
      <Row className="gx-3">
        <Col lg={12}>
          <TotalEmissionsCard eventDetails={eventDetails} />
        </Col>
      </Row>
      <>
        <PageHeader
          title="Emissions Breakdown"
          description="The chart displays form emissions"
          className="mb-3"
        ></PageHeader>
        <Row className="gx-3">
          <Col lg={12}>
            {/* <Chart eventDetails={eventDetails} /> */}
          </Col>
        </Row>
      </>
    </>
  );
};

export default Index;
