import { ProjectContext } from "../../../context/Context";
import { useContext } from "react";
import { toast } from "react-toastify";

const useProjectHook = (project) => {
  const {
    projectsState: { projects, cartItems, totalEmissions },
    projectsDispatch,
    isInShoppingCart,
    isInFavouriteItems,
  } = useContext(ProjectContext);

  const handleAddTotalEmission = (projectId, showModal) => {
    if (isInShoppingCart(projectId)) {
      toast.error("Project already added to cart");
    } else {
      const currentProject = projects.find((item) => item.id === projectId);
      projectsDispatch({
        type: "SET_CURRENT_PROJECT",
        payload: {
          currentProject,
          showModal,
        },
      });
    }
  };

  const handleAddToCart = (projectId, showModal) => {
    if (isInShoppingCart(projectId)) {
      toast.error("Project already added to cart");
    } else {
      //add project to cart in such a way that percentage is recalculated for each project
      const project = projects.find((item) => item.id === projectId);
      const percentage = 100 / (cartItems.length + 1);
      const totalEmission = totalEmissions * (percentage / 100);
      const calculatedCost = Math.round(totalEmission * project.costpertonne);
      const projectCost = totalEmission * project.costpertonne;

      //add project to cart
      projectsDispatch({
        type: "ADD_TO_CART",
        payload: {
          project: {
            ...project,
            totalEmission,
            projectCost,
            percentage,
          },
        },
      });

      //update percentage for each project in cart on addition of new project
      if (cartItems.length > 0) {
        cartItems.forEach((item) => {
          const totalEmission = totalEmissions * (percentage / 100);
          const calculatedCost = Math.round(totalEmission * item.costpertonne);
          const projectCost = totalEmission * item.costpertonne;

          projectsDispatch({
            type: "UPDATE_CART_ITEM",
            payload: {
              project: {
                ...item,
                totalEmission,
                projectCost,
                percentage,
              },
              showModal,
            },
          });
        });
      }
    }
  };

  const handleFavouriteClick = () => {
    projectsDispatch({
      type: isInFavouriteItems(project.id)
        ? "REMOVE_FROM_FAVOURITES"
        : "ADD_TO_FAVOURITES",
      payload: { project },
    });
  };

  return { handleAddTotalEmission, handleAddToCart, handleFavouriteClick };
};

export default useProjectHook;
