import React, { useContext, useState } from "react";
import { Button, Container, Form, Row, Col } from "react-bootstrap";
import { binVolumes, wasteTypes } from "./wasteData";
import Select from "react-select";
import { customOption } from "./PageWeightForm";
import { WasteContext } from "../../../context/Context";

import WasteTable from "./WasteTable";
function PageCalculator() {
  const { categoriesData, setCategoriesData, formData, handleSubmitContext } =
    useContext(WasteContext);
  const [wasteType, setWasteType] = useState(null);
  const [frequency, setFrequency] = useState("");
  const [numBins, setNumBins] = useState("");
  const [volBins, setVolBins] = useState(null);
  const [percBins, setPercBins] = useState("");
  const [volumeOfCollections, setVolumeOfCollections] = useState("");
  const [totalWeightOfWaste, setTotalWeightOfWaste] = useState("");
  const startDate = new Date(formData.startDate);
  const endDate = new Date(formData.endDate);
  const totalDays = (endDate - startDate) / (1000 * 60 * 60 * 24) + 1;
  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "daysBetweenCollection":
        if (!value || parseFloat(value) >= 0) {
          setFrequency(value);
        }
        break;
      case "numberOfWheelieBins":
        if (!value || parseFloat(value) >= 0) {
          setNumBins(value);
        }
        break;
      case "fullnessOfBins":
        setPercBins(value);
        break;
      default:
        break;
    }
    if (frequency && numBins && percBins && wasteType && volBins) {
      calculateResults();
    }
  };
  const calculateResults = () => {
    const numberOfCollections = Math.floor(totalDays / frequency);
    const volume =
      numberOfCollections * numBins * volBins.value * (percBins / 100);
    const weight = (volume * wasteType.density) / 1000;
    setVolumeOfCollections(volume.toFixed(1));
    setTotalWeightOfWaste(weight.toFixed(6));
  };
  const handleAddEntry = () => {
    if (
      wasteType &&
      frequency &&
      numBins &&
      percBins &&
      volBins &&
      volumeOfCollections &&
      totalWeightOfWaste
    ) {
      setCategoriesData((prevEntries) => [
        ...prevEntries,
        {
          wasteIcon: wasteType.icon,
          wasteType: wasteType.value,
          frequency,
          volBins: volBins.value,
          numBins,
          percBins,
          volumeOfCollections,
          totalWeightOfWaste,
          source: "estimator",
        },
      ]);
      resetForm();
    }
  };
  const resetForm = () => {
    setWasteType(null);
    setFrequency("");
    setNumBins("");
    setPercBins("");
    setVolumeOfCollections("");
    setTotalWeightOfWaste("");
  };
  const handleSubmit = () => {
    // console.log("Form submitted", categoriesData);
    const addedWaste = categoriesData.map(({ wasteIcon, ...item }) => item);
    setCategoriesData(addedWaste);
    handleSubmitContext();
  };
  return (
    <Container>
      <Row>
        <p className="text-center mb-4">
          On this page, you can enter the details of your waste. Based on the
          information provided, we will calculate the equivalent volume and
          weight of the waste produced. Please fill in the fields below and add
          your entries.
        </p>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Select the category of Waste</Form.Label>
            <Select
              value={wasteType}
              onChange={setWasteType}
              options={wasteTypes}
              placeholder="Select Waste Type"
              classNamePrefix="react-select"
              components={{ Option: customOption }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>How many days between collections?</Form.Label>
            <Form.Control
              type="number"
              name="daysBetweenCollection"
              placeholder="14"
              value={frequency}
              onChange={handleFieldChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Select the size of the Bins</Form.Label>
            <Select
              value={volBins}
              onChange={setVolBins}
              options={binVolumes}
              placeholder="Select size of Bins"
              classNamePrefix="react-select"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>What is the number of Bins</Form.Label>
            <Form.Control
              type="number"
              name="numberOfWheelieBins"
              placeholder="0"
              value={numBins}
              onChange={handleFieldChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>How full are they when collected (%)</Form.Label>
            <div style={{ display: "flex", gap: 10 }}>
              <Form.Text className="text-muted">{percBins}%</Form.Text>
              <Form.Range
                name="fullnessOfBins"
                min={0}
                max={100}
                value={percBins}
                onChange={handleFieldChange}
              />
            </div>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Equivalent Volume for {totalDays} days in (liters)
            </Form.Label>
            <Form.Control
              type="number"
              name="volumeOfCollections"
              placeholder="--"
              value={volumeOfCollections}
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              Equivalent Weight for {totalDays} days in (Tonnes)
            </Form.Label>
            <Form.Control
              type="number"
              name="totalWeightOfWaste"
              placeholder="--"
              value={totalWeightOfWaste}
              disabled
            />
          </Form.Group>
          <Button variant="primary" onClick={handleAddEntry}>
            Add Entry
          </Button>
        </Col>
      </Row>
      <Row className="mt-5">
        <h3>Data to Submit:</h3>
        <WasteTable categoriesData={categoriesData} />
        <Col>
          {categoriesData.length > 0 && (
            <Button variant="success" onClick={handleSubmit} className="mt-3">
              Submit All Entries
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
}
export default PageCalculator;
