import React, { useContext, useEffect, useState } from "react";
import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
import WidgetSectionTitle from "../../../widgets/WidgetSectionTitle";
import SolutionTable from "./solutionTable";
import SolutionCardTableWithAccordian from "./solutionCardTableWithAccordian";
import { getSelectedSolutions } from "../solutionsAPI";
import { useNavigate } from "react-router-dom";
import { MySolutionsContext, ProductContext } from "../../../context/Context";
import { useAuth } from "../../../Contexts/AuthContext";
import { faList } from "@fortawesome/free-solid-svg-icons";

const MySolutions = () => {
  const { currentUser } = useAuth();
  useEffect(() => {
    const analytics = getAnalytics();
    setUserId(analytics, currentUser.uid);
    setUserProperties(analytics, { businessId: currentUser.businessId });
    logEvent(analytics, "page_view", {
      page_title: "My Solutions",
    });
  }, [currentUser]);

  // console.log("in my solutions");
  const {
    mySolutionsState: { mySolutions },
    mySolutionsDispatch,
  } = useContext(MySolutionsContext);

  const getSolutions = async () => {
    const data = await getSelectedSolutions();
    await mySolutionsDispatch({
      type: "FETCH_MY_SOLUTIONS_SUCCESS",
      payload: { data },
    });
  };

  useEffect(() => {
    getSolutions();
  }, []);

  // console.log(mySolutions);
  return (
    <>
      <WidgetSectionTitle
        icon={faList}
        title="My solutions"
        r
        subtitle="Sustainability is just a word, it needs action. Set your own goals and get in contact if you need any help."
        transform="shrink-2"
        className="mb-4 mt-6"
      />
      <div className="mb-3">
        <SolutionTable mySolutions={mySolutions} />
      </div>
      <div className="mb-3">
        <SolutionCardTableWithAccordian />
      </div>
    </>
  );
};

export default MySolutions;
