import WasteHubProvider from "./WasteHubProvider";
import WasteWizardLayout from "./WasteWizardLayout";
import WidgetSectionTitle from "../../../widgets/WidgetSectionTitle";
import { faList } from "@fortawesome/free-solid-svg-icons";
const Index = () => {
  const variant = "p";
  const validation = true;
  const progressBar = true;

  return (
    <>
      <WidgetSectionTitle
        icon={faList}
        title="Waste Form"
        subtitle="Fill your waste form here"
        transform="shrink-2"
        className="mb-4 mt-6"
      />
      <WasteHubProvider>
        <WasteWizardLayout
          variant={variant}
          validation={validation}
          progressBar={progressBar}
        />
      </WasteHubProvider>
    </>
  );
};
export default Index;
