import React, { useEffect, useReducer } from "react";
import { areasOfImprovementReducer } from "../../../reducers/productReducer";
import { AreasOfImprovementContext } from "../../../context/Context";
import AreasOfImprovement from "./AreasOfImprovement";
import { useLocation } from "react-router-dom";
import { getQuestionByBusinessId } from "../solutionsAPI";

const AreasOfImprovementProvider = () => {
  const location = useLocation();

  const initAreasOfImprovementData = {
    areasOfImprovement: [],
  };

  const [areasOfImprovementState, areasOfImprovementDispatch] = useReducer(
    areasOfImprovementReducer,
    initAreasOfImprovementData
  );
  const [status, setStatus] = React.useState(false);

  async function get(location) {
    let status = false;
    if (location.state) {
      // setStatus(location.state.status);
      status = location.state.status;
      const data = location.state.areasOfImprovement;

      areasOfImprovementDispatch({
        type: "FETCH_AREAS_OF_IMPROVEMENT_SUCCESS",
        payload: { data },
      });
    } else {
      const areasOfImprovementExists = await getQuestionByBusinessId();
      // console.log("areasOfImprovementExists=>", areasOfImprovementExists);
      if (areasOfImprovementExists) {
        status =
          areasOfImprovementExists.aoi_generated_status === "InProgress"
            ? true
            : false;
      }
    }
    setStatus(status);
  }

  useEffect(() => {
    // console.log("In areasofimprovemntprovider=>", location.state);
    get(location);
  }, [location.state]);

  return (
    <AreasOfImprovementContext.Provider
      value={{
        areasOfImprovementState,
        areasOfImprovementDispatch,
      }}
    >
      <AreasOfImprovement status={status} setStatus={setStatus} />
    </AreasOfImprovementContext.Provider>
  );
};

export default AreasOfImprovementProvider;
