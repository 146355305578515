import React, { useState } from "react";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from "../shadcn/DropdownMenu";
import { Button } from "../shadcn/Button";
import { Calendar, ChevronDown, Plus } from "lucide-react";
import NewReportingPeriodModal from "../new-overview/NewReportingPeriodModal";

export default function YearSelector({
  measurementYearDisplay,
  currentInventoryProgress,
  onMeasurementYearSelection,
  onAddNewPeriod,
}) {
  const [isNewPeriodModalOpen, setIsNewPeriodModalOpen] = useState(false);

  const handleOpenNewPeriodModal = () => {
    setIsNewPeriodModalOpen(true);
  };

  const handleCloseNewPeriodModal = () => {
    setIsNewPeriodModalOpen(false);
  };

  const handleAddNewPeriod = (year, type) => {
    onAddNewPeriod(year, type);
    handleCloseNewPeriodModal();
  };

  const formattedMeasurementYearDisplay = measurementYearDisplay
    .split("-")
    .map((part, index) =>
      index === 1
        ? part.charAt(0).toUpperCase() + part.slice(1) // Capitalize the second part (year type)
        : part
    )
    .join(" ");

  return (
    <>
      <div className="tw-flex tw-items-center tw-gap-2">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="outline"
              className="tw-w-[200px] tw-justify-between"
            >
              <span className="tw-flex tw-items-center tw-gap-2">
                <Calendar className="tw-h-4 tw-w-4" />
                {formattedMeasurementYearDisplay}
              </span>
              <ChevronDown className="tw-h-4 tw-w-4 tw-opacity-50" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="tw-w-[200px]">
            {(currentInventoryProgress ?? [])
              .sort((a, b) => b.measurementyear - a.measurementyear) // Sort years in descending order
              .map((inventory) => (
                <DropdownMenuItem
                  key={inventory.inventoryid}
                  onSelect={() =>
                    onMeasurementYearSelection(
                      `${
                        inventory.measurementyear
                      } ${inventory?.measurementyeartype
                        ?.charAt(0)
                        .toUpperCase()}${inventory?.measurementyeartype?.slice(
                        1
                      )}`
                    )
                  }
                >
                  <Calendar className="tw-mr-2 tw-h-4 tw-w-4" />
                  <span>
                    {inventory.measurementyear}{" "}
                    {inventory?.measurementyeartype?.charAt(0).toUpperCase()}
                    {inventory?.measurementyeartype?.slice(1)}
                  </span>
                </DropdownMenuItem>
              ))}
            {(currentInventoryProgress ?? []).length === 0 && (
              <DropdownMenuItem disabled>
                <span className="tw-text-muted-foreground">
                  No periods available
                </span>
              </DropdownMenuItem>
            )}
            <DropdownMenuSeparator />
            <DropdownMenuItem onSelect={handleOpenNewPeriodModal}>
              <Plus className="tw-mr-2 tw-h-4 tw-w-4" />
              <span>Add New Reporting Period</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <NewReportingPeriodModal
        show={isNewPeriodModalOpen}
        onHide={handleCloseNewPeriodModal}
        onAddMeasurementYear={handleAddNewPeriod}
      />
    </>
  );
}
