import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
  CardFooter,
} from "../shadcn/Card";
import { Skeleton } from "../shadcn/Skeleton";

const LoadingCard = () => {
  return (
    <Card className="tw-flex tw-flex-col">
      <CardHeader className="tw-relative">
        <div className="tw-absolute tw-top-4 tw-right-4 tw-bg-primary tw-text-primary-foreground tw-rounded-full tw-w-8 tw-h-8 tw-flex tw-items-center tw-justify-center tw-font-bold">
          <Skeleton className="tw-h-4 tw-w-4 tw-rounded-full" />
        </div>
        <CardTitle className="tw-flex tw-items-center tw-gap-2">
          <Skeleton className="tw-h-6 tw-w-6 tw-rounded-full" />
          <Skeleton className="tw-h-6 tw-w-32" />
        </CardTitle>
        <CardDescription>
          <Skeleton className="tw-h-4 tw-w-24" />
        </CardDescription>
      </CardHeader>
      <CardContent className="tw-flex-grow">
        <Skeleton className="tw-h-2 tw-w-full tw-mb-4" />
        <Skeleton className="tw-h-32 tw-w-full" />
      </CardContent>
      <CardFooter className="tw-flex tw-justify-between tw-items-center">
        <Skeleton className="tw-h-10 tw-w-full" />
        <Skeleton className="tw-h-10 tw-w-10 tw-ml-2" />
      </CardFooter>
    </Card>
  );
};

export default LoadingCard;
