import React, { useContext, useEffect, useState } from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { WasteContext } from "../../../context/Context";
import {
  getActiveUserData,
  getSelectedLocationsFromStorage,
} from "../../../helpers/store";
import { GreetingsDateInput } from "../../../helpers/DataDateFilter";
import ReactDatePicker from "react-datepicker";

function PageDateOfficeQuestion() {
  const { formData, setFormData, nextStep } = useContext(WasteContext);
  const [office, setOffice] = useState(formData.office || null);
  const [formName, setFormName] = useState(formData.formName || "");
  const [activeUser, setActiveUser] = useState(JSON.parse(getActiveUserData()));
  const [locations, setLocations] = useState([]);

  const officeData = locations.map((office) => ({
    label: `${office.officename} (${office.location}, ${office.country})`,
    value: office.office_id,
    country: office.country,
    state: office.location,
  }));

  useEffect(() => {
    const selectedLocations = getSelectedLocationsFromStorage();
    setLocations(selectedLocations);
  }, []);

  const [selectedDates, setSelectedDates] = useState({
    startDate: formData.startDate
      ? new Date(formData.startDate)
      : new Date(activeUser.dateRangeStart),
    endDate: formData.endDate
      ? new Date(formData.endDate)
      : new Date(activeUser.dateRangeEnd),
  });

  const handleFormNameChange = (event) => {
    setFormName(event.target.value);
  };

  const handleAddEntry = () => {
    if (
      office &&
      formName &&
      selectedDates.startDate &&
      selectedDates.endDate
    ) {
      setFormData({
        ...formData,
        formName,
        userId: activeUser.uid,
        office,
        startDate: selectedDates.startDate.toISOString().substring(0, 10),
        endDate: selectedDates.endDate.toISOString().substring(0, 10),
        update_date: new Date().toISOString().substring(0, 10),
      });
      nextStep();
      // console.log(selectedDates.startDate.toDateString());
      // console.log(selectedDates.startDate.toISOString().substring(0, 10));
    }
  };

  const onChange = (dates) => {
    const [start, end] = dates;
    setSelectedDates({
      startDate: start,
      endDate: end,
    });
  };

  return (
    <Container className="mt-5">
      <h3 className="mb-4 text-center">Waste Disposal Emissions Calculator</h3>
      <p className="text-center mb-4">
        Please fill out the information below to start calculating the emissions
        for your waste disposal.
      </p>
      <Form.Group as={Row} className="mb-4">
        <Form.Label column sm="4">
          Name your waste submission:
        </Form.Label>
        <Col sm="8">
          <Form.Control
            type="text"
            placeholder="Enter a memorable name"
            value={formName}
            onChange={handleFormNameChange}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-4">
        <Col sm="6">
          <Form.Label>Choose an Office Location:</Form.Label>
          <Select
            value={office}
            onChange={setOffice}
            options={officeData}
            placeholder="Select office"
            classNamePrefix="react-select"
          />
        </Col>
        <Col sm="6">
          <Form.Label>Select a Date Range for Waste Data:</Form.Label>
          <ReactDatePicker
            selected={selectedDates.startDate}
            onChange={onChange}
            selectsRange
            startDate={selectedDates.startDate}
            endDate={selectedDates.endDate}
            minDate={new Date(activeUser.dateRangeStart)} // Set minimum date
            maxDate={new Date(activeUser.dateRangeEnd)} // Set maximum
            showMonthDropdown
            showYearDropdown
            dateFormat="dd/MM/yyyy"
            customInput={<GreetingsDateInput />}
          />
        </Col>
      </Form.Group>
      <Row>
        <Col className="d-flex justify-content-center">
          <Button variant="primary" onClick={handleAddEntry} className="mt-3">
            Continue
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default PageDateOfficeQuestion;
