import app from "../../../firebase";
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
const fireStoreDB = getFirestore(app);

export const fetchEventFormData = async (
  businessId,
  eventId,
  primaryCategory
) => {
  const formQuery = query(
    collection(fireStoreDB, "Events", businessId, eventId.toString()),
    where("primaryCategory", "==", primaryCategory)
  );
  const formquerySnapshot = await getDocs(formQuery);
  let totalEmmissions = 0;
  formquerySnapshot.forEach((doc) => {
    const { emissions } = doc.data();
    totalEmmissions += emissions;
  });
  return totalEmmissions;
};
