import React, { useContext, useEffect, useState } from "react";
import { Accordion, Alert, Badge, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faRedo,
  faStream,
  faBan,
  faExchange,
} from "@fortawesome/free-solid-svg-icons";
import StarRating from "../../common/StarRating";
import { useAuth } from "../../../Contexts/AuthContext";
import {
  getSelectedSolutions,
  updateCompletedSolutionTasks,
} from "../solutionsAPI";
import { MySolutionsContext } from "../../../context/Context";
const SolutionAccordian = ({ product, index }) => {
  console.log("In SolutionAccordian");

  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { solutionId, officeId, ratings, solution, category, title, tasks } =
    product;
  console.log(product);
  console.log(solutionId, officeId, ratings, category, title, tasks);
  const [values, setValues] = useState({
    tasks,
    updatedBy: currentUser.uid,
  });
  const [officeName, setofficeName] = useState("");

  const { mySolutionsDispatch } = useContext(MySolutionsContext);

  const getSolutions = async () => {
    console.log("Getting updated solutions");
    const data = await getSelectedSolutions();
    await mySolutionsDispatch({
      type: "FETCH_MY_SOLUTIONS_SUCCESS",
      payload: { data },
    });
  };

  async function handleUpdate() {
    console.log("Solution", product);
    console.log("SolutionValue", values);

    await updateCompletedSolutionTasks(
      product.solutionId,
      product.officeId,
      values
    );
  }

  async function handleChange(event, idx, checked) {
    if (event) event.preventDefault();
    let initTasks = [...values.tasks];
    initTasks[idx].status = checked ? "Completed" : "InProgress";
    console.log(initTasks);
    setValues((values) => ({
      ...values,
      tasks: initTasks,
    }));
    await handleUpdate();
    await getSolutions();
    navigate("/welcome/solutions/my-solutions");
  }

  //fetch list of offices for the user
  function getOfficeName() {
    console.log(product.officeId);

    const businessOffices = JSON.parse(
      sessionStorage.getItem("businessOffices")
    );

    let idNameDict = {};
    for (let i = 0; i < businessOffices.length; i++) {
      let str = businessOffices[i].split(",");
      idNameDict[str[1]] = str[0];
    }

    setofficeName(idNameDict[product.officeId]);
  }

  useEffect(() => {
    console.log("useeffect");
    getOfficeName();
    setValues((values) => ({
      ...values,
      tasks,
    }));
  }, [tasks]);

  return (
    <>
      <Col
        xs={12}
        className={classNames("p-card", {
          "bg-100": index % 2 !== 0,
        })}
      >
        <Row>
          <Col sm={12} md={12}>
            <Row className="h-100">
              <h5 className="mt-3 mt-sm-0">{title}</h5>
              <p className="fs--1 mb-2 mb-md-3">
                <Badge
                  pill
                  bg={
                    category === "Energy"
                      ? "danger"
                      : category === "Recycling"
                      ? "info"
                      : category === "Travel"
                      ? "warning"
                      : category === "Swaps"
                      ? "primary"
                      : category === "Staff Involvement"
                      ? "dark"
                      : category === "Cost Saver"
                      ? "info"
                      : category === "Transport"
                      ? "warning"
                      : category === "Waste"
                      ? "primary"
                      : null
                  }
                >
                  {category}
                  <FontAwesomeIcon
                    icon={
                      category === "Energy"
                        ? faCheck
                        : category === "Recycling"
                        ? faRedo
                        : category === "Travel"
                        ? faStream
                        : category === "Swaps"
                        ? faBan
                        : category === "Staff Involvement"
                        ? faExchange
                        : category === "Cost Saver"
                        ? faRedo
                        : category === "Transport"
                        ? faStream
                        : category === "Waste"
                        ? faBan
                        : null
                    }
                    transform="shrink-2"
                    className="ms-1"
                  />
                </Badge>{" "}
                <Badge pill>{officeName}</Badge>
              </p>
              {ratings && <StarRating readonly rating={ratings} />}
              <p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: solution,
                  }}
                />
              </p>
              <p>
                <Accordion>
                  {tasks &&
                  tasks.length !== 0 &&
                  Object.keys(tasks[0]).length !== 0
                    ? tasks.map((task, idx) => (
                        <Accordion.Item eventKey={task.id}>
                          <Accordion.Header>
                            {/* <>
                              <Alert variant="danger">
                                {values.tasks[idx].status}
                              </Alert>
                            </> */}
                            <Form.Check
                              type="checkbox"
                              id={idx}
                              label={task.title}
                              checked={
                                values.tasks[idx].status === "Completed"
                                  ? true
                                  : false
                              }
                              onChange={(event) => {
                                handleChange(event, idx, event.target.checked);
                              }}
                            />
                          </Accordion.Header>
                          <Accordion.Body>{task.solution}</Accordion.Body>
                        </Accordion.Item>
                        // <>
                        //   <Alert variant="danger">{task.id}</Alert>
                        //   <Alert variant="danger">{task.solution}</Alert>
                        // </>
                      ))
                    : null}
                </Accordion>
              </p>
            </Row>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default SolutionAccordian;
