import PropTypes from "prop-types";
import classNames from "classnames";
import {
  Card,
  Col,
  Row,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Background from "../common/Background";
import createMarkup from "../../helpers/createMarkup";
import { Link } from "react-router-dom"; // Import the Link component
import bg1 from "../../Assets/img/icons/spot-illustrations/corner-1.png";
import bg3 from "../../Assets/img/icons/spot-illustrations/corner-3.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCircle,
  faPlay,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";

const NewOverviewCard = ({
  title,
  preTitle,
  titleTag: TitleTag,
  descriptionIncompleted,
  descriptionCompleted,
  image,
  col,
  children,
  isCompleted,
  buttonText,
  link,
  buttonTextCompleted,
  buttonTextIncompleted,
  toggleModal,
  icon,
  tutorialLink,
  onClick,
  ...rest
}) => (
  <Card
    {...rest}
    style={{
      backgroundColor: isCompleted ? "#E5FCE5" : "",
      minHeight: 230,
      height: "100%",
    }}
  >
    <Background
      // image={image}
      image={isCompleted ? bg3 : bg1}
      className="bg-card"
      style={{
        borderTopRightRadius: "0.375rem",
        borderBottomRightRadius: "0.375rem",
      }}
    />
    <Card.Body className="position-relative">
      <Row>
        <Col {...col} lg={8}>
          {preTitle && <h6 className="text-600">{preTitle}</h6>}

          <TitleTag className="mb-0" style={{marginRight: "-40px"}}>
            {icon !== null ? (
              <span className="fa-layers fa-fw" style={{ marginRight: "8px" }}>
                <FontAwesomeIcon icon={faCircle} color="#e7f4fe" />
                <FontAwesomeIcon icon={icon} size="2xs" color="#2c7be5" />
              </span>
            ) : null}
            {title}
          </TitleTag>
          {descriptionIncompleted && (
            <p
              className={classNames("mt-2", { "mb-0": !children })}
              dangerouslySetInnerHTML={
                isCompleted
                  ? createMarkup(descriptionCompleted)
                  : createMarkup(descriptionIncompleted)
              }
            />
          )}

          {children}
        </Col>
        {tutorialLink && (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Watch Tutorials</Tooltip>}
          >
            <Col className="d-flex justify-content-end align-items-start" >
              <Button
                variant={isCompleted ? "outline-success" : "outline-primary"}
                onClick={() => {
                  window.open(tutorialLink, "_blank");
                }}
              >
                <FontAwesomeIcon icon={faPlay} style={{ marginLeft: "5px" }} />
              </Button>
            </Col>
          </OverlayTrigger>
        )}
      </Row>
    </Card.Body>
    <Card.Footer className="position-relative">
      {title === "Spend Data" || title === "Activity Data" ? (
        <Button variant="primary" onClick={toggleModal}>
          {buttonTextIncompleted}
        </Button>
      ) : (
        <Link to={link}>
          {isCompleted ? (
            <Button variant="outline-success">
              <FontAwesomeIcon icon={faCheck} style={{ marginRight: "5px" }} />
              {buttonTextCompleted}
            </Button>
          ) : title === "Emissions Boundaries" ? (
            <Button
              variant="primary"
              onClick={()=> {onClick(true)}}
            >
              {buttonTextIncompleted}
            </Button>
          ) : (
            <Button variant="primary">{buttonTextIncompleted}</Button>
          )}
        </Link>
      )}
    </Card.Footer>
  </Card>
);

NewOverviewCard.propTypes = {
  title: PropTypes.string.isRequired,
  preTitle: PropTypes.string,
  titleTag: PropTypes.string,
  descriptionIncompleted: PropTypes.string,
  descriptionCompleted: PropTypes.string,
  col: PropTypes.shape(Col.propTypes),
  image: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  isCompleted: PropTypes.bool,
  link: PropTypes.string,
  buttonTextCompleted: PropTypes.string,
  buttonTextIncompleted: PropTypes.string,
  onClick: PropTypes.func,
};

NewOverviewCard.defaultProps = {
  col: { lg: 8 },
  image: bg1,
  titleTag: "h3",
  isCompleted: false,
  icon: null,
};

export default NewOverviewCard;
