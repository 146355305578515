/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-case-declarations */
import { toast } from "react-toastify";
// dummy promo codes
const promoCodes = [
  { code: "GET20", discount: 20 },
  { code: "GET50", discount: 50 },
];

export const solutionReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "FETCH_ALL_SOLUTIONS_SUCCESS":
      return {
        ...state,
        solutions: action.payload.data,
      };
    case "SORT_PRODUCT":
      return {
        ...state,
        solutions: [...state.solutions].sort((a, b) => {
          if (payload.sortBy === "review") {
            if (payload.order === "asc") {
              return a.reviews.length - b.reviews.length;
            } else {
              return b.reviews.length - a.reviews.length;
            }
          } else if (payload.sortBy === "category") {
            const categoryOrder = [
              "Cost Saver",
              "Energy",
              "Recycling",
              "Staff Involvement",
              "Swaps",
              "Travel",
              "Transport",
              "Waste",
            ];
            if (payload.order === "asc") {
              return (
                categoryOrder.indexOf(a.category) -
                categoryOrder.indexOf(b.category)
              );
            } else {
              return (
                categoryOrder.indexOf(b.category) -
                categoryOrder.indexOf(a.category)
              );
            }
          } else {
            if (payload.order === "asc") {
              return a[payload.sortBy] - b[payload.sortBy];
            } else {
              return b[payload.sortBy] - a[payload.sortBy];
            }
          }
        }),
      };
    case "ADD_TO_CART":
      return {
        ...state,
        cartItems: [...state.cartItems, payload.solution],
        cartModal: {
          show: true,
          solution: payload.solution,
          businessId: payload.businessId,
          solutionId: payload.solutionId,
          userId: payload.userId,
        },
      };
    case "UPDATE_CART_ITEM":
      return {
        ...state,
        cartItems: state.cartItems.map((item) =>
          item.id === payload.product.id ? payload.product : item
        ),
        cartModal: {
          show: payload.showModal,
          product: payload.product,
          quantity: payload.quantity,
        },
      };
    case "REMOVE_FROM_CART":
      return {
        ...state,
        cartItems: state.cartItems.filter(
          (product) => product.id !== payload.product.id
        ),
        cartModal: {
          show: true,
          product: payload.product,
          type: "remove",
        },
      };
    case "ADD_TO_FAVOURITES":
      return {
        ...state,
        products: state.products.map((product) =>
          product.id === payload.product.id
            ? { ...product, favorite: product.favorite + 1 }
            : product
        ),
        favouriteItems: [...state.favouriteItems, payload.product],
      };
    case "REMOVE_FROM_FAVOURITES":
      return {
        ...state,
        products: state.products.map((product) =>
          product.id === payload.product.id
            ? { ...product, favorite: product.favorite - 1 }
            : product
        ),
        favouriteItems: state.favouriteItems.filter(
          (product) => product.id !== payload.product.id
        ),
      };
    case "TOGGLE_CART_MODAL":
      return {
        ...state,
        cartModal: {
          ...state.cartModal,
          show: !state.cartModal.show,
        },
      };
    case "APPLY_PROMO": {
      const code = promoCodes.find((promo) => promo.code === payload.promoCode);
      if (code) {
        toast.success(
          <span>
            Congratulations, You got <strong>${code.discount}%</strong>{" "}
            discount!
          </span>
        );
      } else {
        toast.error("Promo code is not valid! Try again.");
      }
      return {
        ...state,
        promo: code,
      };
    }
    case "CHECKOUT": {
      return {
        ...state,
        cartItems: [],
        promo: null,
      };
    }
    case "RESET":
      return {
        ...state,
      };
    default:
      return state;
  }
};

export const mySolutionReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "FETCH_MY_SOLUTIONS_SUCCESS":
      return {
        ...state,
        mySolutions: action.payload.data,
      };
    case "SORT_MY_SOLUTIONS":
      return {
        ...state,
        mySolutions: [...state.mySolutions].sort((a, b) => {
          if (payload.sortBy === "review") {
            if (payload.order === "asc") {
              return a.reviews.length - b.reviews.length;
            } else {
              return b.reviews.length - a.reviews.length;
            }
          } else if (payload.sortBy === "category") {
            const categoryOrder = [
              "Energy",
              "Recycling",
              "Staff Involvement",
              "Swaps",
              "Travel",
            ];
            if (payload.order === "asc") {
              return (
                categoryOrder.indexOf(a.category) -
                categoryOrder.indexOf(b.category)
              );
            } else {
              return (
                categoryOrder.indexOf(b.category) -
                categoryOrder.indexOf(a.category)
              );
            }
          } else {
            if (payload.order === "asc") {
              return a[payload.sortBy] - b[payload.sortBy];
            } else {
              return b[payload.sortBy] - a[payload.sortBy];
            }
          }
        }),
      };
  }
};

export const areasOfImprovementReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "FETCH_AREAS_OF_IMPROVEMENT_SUCCESS":
      return {
        ...state,
        areasOfImprovement: action.payload.data,
      };
    case "SORT_AREAS_OF_IMPROVEMENT":
      return {
        ...state,
        areasOfImprovement: [...state.areasOfImprovement].sort((a, b) => {
          if (payload.sortBy === "priority") {
            const priorityOrder = ["High", "Medium", "Low"];
            if (payload.order === "asc") {
              return (
                priorityOrder.indexOf(a.priority) -
                priorityOrder.indexOf(b.priority)
              );
            } else {
              return (
                priorityOrder.indexOf(b.priority) -
                priorityOrder.indexOf(a.priority)
              );
            }
          } else {
            if (payload.order === "asc") {
              return a[payload.sortBy] - b[payload.sortBy];
            } else {
              return b[payload.sortBy] - a[payload.sortBy];
            }
          }
        }),
      };
  }
};
