import React, { useState } from "react";
import { Card, Col, Form, Nav, Row, Tab } from "react-bootstrap";
import Flex from "../common/Flex";

import AllScopesCard from "./AllScopesCard";

import ScopeOneCard from "./ScopeOneCard";
import ScopeTwoCard from "./ScopeTwoCard";
import ScopeThreeCard from "./ScopeThreeCard";

const NavItem = ({ item }) => {
  return (
    <Nav.Item as="li">
      <Nav.Link
        className="mb-0 py-3 cursor-pointer"
        eventKey={item.toLowerCase().replace(/\s+/g, "")}
      >
        {item}
      </Nav.Link>
    </Nav.Item>
  );
};

function ScopesCard({ chartData }) {
  // console.log(chartData)
  const [navItems] = useState(["All", "Scope 3", "Scope 2", "Scope 1"]);
  return (
    <Card className="mt-3">
      <Tab.Container id="audience-tab" defaultActiveKey="all">
        <Card.Header
          as={Flex}
          justifyContent="between"
          alignItems="center"
          className="ps-0 py-0 border-bottom"
        >
          <Nav
            as="ul"
            className="nav-tabs border-0 flex-nowrap chart-tab tab-active-caret"
          >
            {navItems.map((item) => (
              <NavItem item={item} key={item} />
            ))}
          </Nav>
        </Card.Header>

        <Card.Body>
          <Tab.Content>
            <Tab.Pane unmountOnExit eventKey="all">
              <AllScopesCard data={chartData?.emissionsByScope} />
            </Tab.Pane>
            <Tab.Pane unmountOnExit eventKey="scope1">
              <ScopeOneCard data={chartData?.emissionsByScope} />
            </Tab.Pane>
            <Tab.Pane unmountOnExit eventKey="scope2">
              <ScopeTwoCard data={chartData?.emissionsByScope} />
            </Tab.Pane>
            <Tab.Pane unmountOnExit eventKey="scope3">
              <ScopeThreeCard
                pieData={chartData?.emissionsBySupplier?.yearlyData}
                barData={chartData?.emissionsBySupplier?.monthlyData}
                totalSuppliers={chartData?.emissionsBySupplier?.supplierNumber}
              />
            </Tab.Pane>
          </Tab.Content>
        </Card.Body>
      </Tab.Container>
    </Card>
  );
}

export default ScopesCard;
