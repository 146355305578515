import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import WasteWidget from "./WasteWidget";
import {
  faArrowRightArrowLeft,
  faRecycle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

function WasteTotalWidgets({ total, recycled, generalWaste }) {
  const diverted = {
    emissions: total.emissions - generalWaste.emissions,
    weight: total.weight - generalWaste.weight,
  };
  // console.log(diverted)
  return (
    <Row className="mb-4">
      <h5>Totals</h5>
      <Col>
        <WasteWidget
          total={generalWaste}
          title="General Waste"
          icon={faTrash}
        />
      </Col>
      <Col>
        <WasteWidget
          total={diverted}
          title="Diverted Waste"
          icon={faArrowRightArrowLeft}
        />
      </Col>
      <Col>
        <WasteWidget total={recycled} title="Recycled Waste" icon={faRecycle} />
      </Col>
    </Row>
  );
}

export default WasteTotalWidgets;
