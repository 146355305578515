import React from "react";
import PageHeader from "../../common/PageHeader";
import { Link, useLocation } from "react-router-dom";
import DynamicForm from "./DynamicForm";
import { Card } from "react-bootstrap";
import { getSubCollectionRef } from "../../../helpers/store";
import { setResponsesToSurvey } from "../transparencyHubAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

function Index() {
  const location = useLocation();
  const { questions, responses, supplierId, sectionNumber, assessmentData } =
    location.state;

  // console.log("RESPONSES", responses);
  function transformResponses(responseArray) {
    return responseArray.reduce((acc, curr) => {
      const key = Object.keys(curr)[0];
      acc[key] = curr[key];
      return acc;
    }, {});
  }
  const transformedResponses = transformResponses(responses?.responses);

  function evaluateScore(question, response) {
    let scoreSection = 0;
    // console.log("Question coming", question);
    // Check if the scoring logic exists
    if (question.scoringLogic) {
      let safeEvalStr;
      if (question.type === "checkbox") {
        const answerValues = response[question.id];
        if (question.scoringLogic.includes("Object.values")) {
          safeEvalStr = question.scoringLogic.replace(
            /Object.values\(answer\)/g,
            `Object.values(${JSON.stringify(answerValues)})`
          );
        } else {
          safeEvalStr = question.scoringLogic.replace(
            /answer/g,
            JSON.stringify(answerValues)
          );
        }
        // console.log("LOGIC", safeEvalStr);
      } else if (question.type === "file") {
        safeEvalStr = question.scoringLogic.replace(
          /answer/g,
          JSON.stringify(response[question.id].url)
        );
      } else if (question.type === "number") {
        safeEvalStr = question.scoringLogic.replace(
          /answer/g,
          response[question.id]
        );
        // console.log("LOGIC", safeEvalStr);
      } else {
        safeEvalStr = question.scoringLogic.replace(
          /answer/g,
          JSON.stringify(response[question.id])
        );
      }

      if (question.scoringLogic.includes("maxScore")) {
        safeEvalStr = safeEvalStr.replace(/maxScore/g, question.maxScore);
      }

      // console.log("Evaluating result", safeEvalStr);

      try {
        // Evaluate the prepared string
        const score = eval(safeEvalStr);
        // console.log("Score", score);
        scoreSection += score;
      } catch (error) {
        console.error("Error evaluating scoring logic:", error);
      }
    }

    return scoreSection;
  }

  const onSaveForm = async (formData) => {
    // console.log("Form data", formData);

    let updatedResponses = {
      ...responses,
      responses: [...responses.responses],
    };
    let updatedProgress = [...(responses.progress || [])];

    const sectionName = questions[0]?.section || "";
    const sectionIndex = updatedProgress.findIndex(
      (p) => p.sectionName === sectionName
    );

    Object.entries(formData).forEach(([questionId, answer]) => {
      if (answer !== "") {
        const responseIndex = updatedResponses?.responses.findIndex(
          (r) => r[questionId]
        );
        if (responseIndex !== -1) {
          updatedResponses.responses[responseIndex][questionId] = answer;
        } else {
          updatedResponses.responses.push({ [questionId]: answer });
        }
      }
    });

    const totalNumberQuestions = questions?.length;
    const totalScoreSection = questions?.reduce(
      (total, question) => total + question.maxScore,
      0
    );
    let respondedQuestions = 0;
    let scoreSection = 0;

    questions?.forEach((question) => {
      const response = updatedResponses.responses.find((r) => r[question.id]);
      if (response) {
        respondedQuestions++;
        scoreSection += evaluateScore(question, response);
      }
    });

    const sectionProgress = {
      sectionName,
      respondedQuestions,
      totalNumberQuestions,
      scoreSection,
      totalScoreSection,
    };
    if (sectionIndex !== -1) {
      updatedProgress[sectionIndex] = sectionProgress;
    } else {
      updatedProgress.push(sectionProgress);
    }

    updatedResponses.progress = updatedProgress;
    updatedResponses.totalScore = updatedProgress.reduce(
      (total, section) => total + section.scoreSection,
      0
    );

    // console.log("updatedResponses", updatedResponses);

    const subCollectionRef = getSubCollectionRef();
    // console.log(subCollectionRef);
    // console.log(supplierId);
    await setResponsesToSurvey(supplierId, subCollectionRef, updatedResponses);
    // Then we need an endpoint to overwrite the TransparencyResponses in the TransparencyAssessment
    // We also need the TransparencyAssessment ref sent here
  };

  // console.log("questions", questions);
  return (
    <>
      <PageHeader
        title={
          <div>
            <Link
              to="/welcome/transparency-hub"
              className="ml-2"
              state={{
                stepSent: 3,
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </Link>{" "}
            Section {sectionNumber}: {questions[0]?.section}
          </div>
        }
        className="mb-3"
      />
      <Card>
        <Card.Body>
          {" "}
          <DynamicForm
            questions={questions}
            responses={transformedResponses}
            onSave={onSaveForm}
            sectionNumber={sectionNumber}
            supplierId={supplierId}
            assessmentData={assessmentData}
          />
        </Card.Body>
      </Card>
    </>
  );
}

export default Index;
