import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import CartItem from "./CartItem";
import { ProjectContext } from "../../../context/Context";
import { getActiveUserData } from "../../../helpers/store";
import { addSelectedProjects } from "../projectAPI";
import { toast } from "react-toastify";
import TotalEmissionsController from "../TotalEmissionsController";

const ShoppingCart = () => {
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const [totalCost, setTotalCost] = useState(0);
  const [totalPercentage, setTotalPercentage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const {
    projectsState: { cartItems, totalEmissions },
    projectsDispatch,
  } = useContext(ProjectContext);

  function calculateSubtotal(cartItems) {
    let subtotal = 0;
    cartItems.forEach((project) => {
      subtotal += project.projectCost;
    });

    return subtotal;
  }

  function calculateTotalPercentage(cartItems) {
    let totalPercentage = 0;
    cartItems.forEach((project) => {
      totalPercentage += project.percentage;
    });
    return Math.round(totalPercentage);
  }

  useEffect(() => {
    setTotalCost(calculateSubtotal(cartItems));
    setTotalPercentage(calculateTotalPercentage(cartItems));
  }, [cartItems]);

  async function handleRequestQuote() {
    setIsLoading(true);

    const body = {
      inventoryId: activeUser.inventoryId,
      projects: cartItems.map((project) => ({
        projectId: project.id,
        projectTitle: project.title,
        portfolioPercentage: project.percentage,
        costPerTonne: project.costpertonne,
        totalEmission: project.totalEmission,
        projectCost: project.projectCost,
      })),
    };

    const response = await addSelectedProjects(body);

    if (response.type === "error") {
      toast.error(response.message);
      setIsLoading(false);
      return;
    }

    toast.success(response.message);

    projectsDispatch({
      type: "CHECKOUT",
    });

    setIsLoading(false);
  }

  return (
    <>
      <Card>
        <Card.Header>
          <Row className="justify-content-between">
            <Col md={6}>
              <h5 className="mb-3 mb-md-0">
                Offsets Portfolio ({cartItems.length} Items)
              </h5>
            </Col>
            {/* Adjust the column sizes as needed */}
            <Col md={6}>
              <TotalEmissionsController />
            </Col>
          </Row>
        </Card.Header>
        <Card.Body
          className={`p-0 ${
            cartItems.length === 0
              ? "d-flex justify-content-center align-items-center"
              : ""
          }`}
          style={{ height: "100%" }}
        >
          {cartItems.length > 0 ? (
            <>
              <Row className="gx-card mx-0 bg-200 text-900 fs--1 fw-semi-bold">
                <Col xs={6} lg={5} className="py-2">
                  Name
                </Col>
                <Col xs={6} lg={7} className="py-2">
                  <Row>
                    <Col xs={6} lg={3} className="text-center py-2">
                      Cost per tonne
                    </Col>
                    <Col lg={3} className="py-2 text-center">
                      Portfolio Percentage (%)
                    </Col>
                    <Col xs={6} lg={3} className="text-center py-2">
                      Total Emissions (tonnes/CO₂e)
                    </Col>
                    <Col lg={3} className="text-end py-2">
                      Project cost
                    </Col>
                  </Row>
                </Col>
              </Row>
              {cartItems.map((project) => (
                <CartItem key={project.id} project={project} />
              ))}

              <Row className="fw-bold gx-card mx-0">
                <Col xs={6} lg={5} className="py-2 text-end text-900">
                  Total
                </Col>
                <Col xs={6} lg={7} className="px-0">
                  <Row className="gx-card mx-0 h-100">
                    <Col xs={6} lg={3} className="py-2 text-center">
                      {/* This will be the Cost per tonne total if you have one, otherwise leave it empty */}
                    </Col>
                    <Col
                      xs={6}
                      lg={3}
                      className="py-2 text-center"
                      style={{
                        color: totalPercentage !== 100 ? "red" : "inherit",
                      }}
                    >
                      {totalPercentage}%
                    </Col>
                    <Col xs={6} lg={3} className="text-center py-2">
                      {/* This will be the Total Emissions if you have a total to display here */}
                      {totalEmissions}
                    </Col>
                    <Col lg={3} className="text-end py-2">
                      ${totalCost.toLocaleString("en-AU")}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          ) : (
            <p className="p-x1 mb-0 bg-light">
              Start by adding a carbon footprint number and selecting a project.
              You can add more than one project.
            </p>
          )}
        </Card.Body>

        <Card.Footer className="bg-light d-flex justify-content-end">
          <Button
            variant="primary"
            size="sm"
            disabled={cartItems.length === 0 || totalPercentage !== 100}
            onClick={handleRequestQuote}
          >
            {isLoading && (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            Request Final Quote
          </Button>
        </Card.Footer>
      </Card>
    </>
  );
};

export default ShoppingCart;
