import { groupBy, map, sumBy, sum } from "lodash";
import {
  fetchSecondaryCategories,
  fetchEmissionsByPrimaryCategory,
  fetchScope1TotalEmissions,
  fetchScope2TotalEmissions,
  fetchScope3TotalEmissions,
  fetchTwelveMonthsWithPrecedenceChartData,
} from "../chartjs/UnifiedDataEmissionDataHelperNew";
import { getAllEmissionsBoundaries } from "../emissions-boundaries/EmissionsBoundariesApi";
import { getBusinessDetails } from "../solutions/solutionsAPI";
import { formatDateToYYYYMM01 } from "../../helpers/utils";
import { getUnifiedDateFromStore } from "../../helpers/unifiedDataHelper";
// import { getBusinessDetails } from "./reportsApi";

export const getReportIntroduction = async (
  inventoryProgress,
  userData,
  fromDate,
  toDate
) => {
  // Get locations from local storage
  // const inventoryProgress = getInventoryProgress();
  // let inventoryProgressParsed = JSON.parse(inventoryProgress);
  // let organisationName = inventoryProgressParsed[0].organisationName;
  // console.log("inventoryProgressParsed=>", inventoryProgressParsed);

  let introduction =
    "Welcome to <b>[[COMPANY_NAME]]</b> Carbon Emissions Summary Report, a vital part of our sustainability plan. This report offers a digestible snapshot of our company's carbon emissions during the <b>[[FROM_DATE]]</b> to <b>[[TO_DATE]]</b> reporting period and it encapsulates our commitment to environmental responsibility, transparency, and our concerted efforts to mitigate our carbon footprint.";

  // // uses name from business details (set when user completes the onboarding process)
  // introduction = introduction.replace(
  //   "[[COMPANY_NAME]]",
  //   userData.businessName
  // );

  // uses name from inventory progress (set in Organisation details)
  introduction = introduction.replace(
    "[[COMPANY_NAME]]",
    userData.businessName
  );

  introduction = introduction.replace("[[FROM_DATE]]", fromDate);
  introduction = introduction.replace("[[TO_DATE]]", toDate);

  return introduction;
};

export const getCompanyLogo = async (userData, fromDate, toDate) => {
  const businessDetails = await getBusinessDetails();
  // console.log("businessDetails=>", businessDetails);

  let organisationLogo = businessDetails?.logourl;

  // console.log("organisationLogo=>", organisationLogo);

  // inventoryProgressCompanyLogo =
  //   "https://1000logos.net/wp-content/uploads/2021/06/NAB-National-Australia-Bank-logo.png";

  let companyLogo = "";

  if (organisationLogo === null || organisationLogo === undefined) {
    companyLogo = "Carbon Emissions Summary Report";
  } else {
    companyLogo = `<img src='${organisationLogo}' alt='Description of Image' style='max-height: 50px; padding-bottom: 5px;'>Carbon Emissions Summary Report`;
  }
  // console.log("companyLogo=>", companyLogo);
  return companyLogo;
};

// Get scope emissions
export const getScopesEmissions = async (
  inventoryProgress,
  userData,
  startDate,
  endDate
) => {
  const scopeEmissions = {
    scope1: 0,
    scope2: 0,
    scope3: 0,
    total: 0,
    uniqueSuppliers: 0,
    offsets: 0,
  };

  const formattedStartDate = formatDateToYYYYMM01(new Date(startDate));
  const formattedEndDate = formatDateToYYYYMM01(new Date(endDate));

  const scope1Data = await fetchScope1TotalEmissions(
    formattedStartDate,
    formattedEndDate
  );

  if (scope1Data) {
    const total = scope1Data["Data"].reduce((prev, next) => prev + next, 0);
    scopeEmissions.scope1 = total.toFixed(3);
  }

  const scope2Data = await fetchScope2TotalEmissions(
    formattedStartDate,
    formattedEndDate
  );

  if (scope2Data) {
    const total = scope2Data["Data"].reduce((prev, next) => prev + next, 0);
    scopeEmissions.scope2 = total.toFixed(3);
  }

  const scope3Data = await fetchScope3TotalEmissions(
    formattedStartDate,
    formattedEndDate
  );

  if (scope3Data) {
    const total = scope3Data["Data"].reduce((prev, next) => prev + next, 0);
    scopeEmissions.scope3 = total.toFixed(3);
  }

  scopeEmissions.total = sum([
    parseFloat(scopeEmissions.scope1),
    parseFloat(scopeEmissions.scope2),
    parseFloat(scopeEmissions.scope3),
  ]).toFixed(3);

  const fullData = await getUnifiedDateFromStore();

  const data = fullData.filter((item) => {
    return (
      new Date(item.date) >= new Date(startDate) &&
      new Date(item.date) <= new Date(endDate)
    );
  });

  // console.log("unifiedData=>", unifiedData);

  const groupedData = groupBy(data, "contactName");

  if (groupedData) {
    scopeEmissions.uniqueSuppliers = Object.keys(groupedData).length;
  }

  // console.log("inventoryProgress?.offsets=>", inventoryProgress?.offsets);
  // console.log("scopeEmissions.scope1", scopeEmissions.scope1);
  // console.log("inventoryProgress?.offsets=>", Number(inventoryProgress?.offsets));

  // scopeEmissions.offsets = inventoryProgress?.offsets ?? 0;
  scopeEmissions.offsets = parseFloat(inventoryProgress?.offsets ?? 0).toFixed(
    3
  );
  // console.log("scopeEmissions.offsets=>", scopeEmissions.offsets);

  return scopeEmissions;
};

// Get Top 10 Suppliers Description
export const getTopSuppliersDescription = async (
  inventoryProgress,
  userData,
  fromDate,
  toDate
) => {
  let topSuppliersDescription =
    "This table offers a comprehensive view of the environmental impact stemming from the supply chain, highlighting those key contributors that significantly influence [[COMPANY_NAME]]'s carbon footprint.";

  // topSuppliersDescription = topSuppliersDescription.replace(
  //   "[[COMPANY_NAME]]",
  //   userData.businessName
  // );

  topSuppliersDescription = topSuppliersDescription.replace(
    "[[COMPANY_NAME]]",
    inventoryProgress.organisationname
  );

  return topSuppliersDescription;
};

// Get Top 10 Suppliers
export const getTopSuppliers = async (userData, startDate, endDate) => {
  const fullData = await getUnifiedDateFromStore();

  const data = fullData.filter((item) => {
    return (
      item.contactName !== "" &&
      item.contactName !== "unknown" &&
      new Date(item.date) >= new Date(startDate) &&
      new Date(item.date) <= new Date(endDate)
    );
  });

  // console.log("unifiedData=>", unifiedData);

  const groupedData = groupBy(data, "contactName");

  const emissionsByCategory = map(groupedData, function (val, key) {
    const contactName = val[0]?.contactName;
    let primaryCategory;
    let secondaryCategory;

    try {
      var occurences = val.reduce(function (r, row) {
        const primarySecondary =
          row.primaryCategory + "," + row.secondaryCategory;
        r[primarySecondary] = ++r[primarySecondary] || 1;
        return r;
      }, {});

      var result = Object.keys(occurences).map(function (key) {
        return { key: key, value: occurences[key] };
      });

      if (result && result.length > 0) {
        result.sort((a, b) => b.value - a.value);
        primaryCategory = result[0].key.split(",")[0];
        secondaryCategory = result[0].key.split(",")[1];
      }
    } catch (error) {
      console.log("Error=>", error);
    }

    // return {
    //   y: contactName,
    //   // category: primaryCategory,
    //   x: parseFloat(sumBy(val, "emissions")?.toFixed(3)),
    // };

    return [
      contactName,
      primaryCategory,
      parseFloat(sumBy(val, "totalEmissions")?.toFixed(3)),
    ];
  });

  let top10Suppliers = emissionsByCategory
    .sort((a, b) => b[2] - a[2])
    .slice(0, 10);

  // console.log("top10Suppliers=>", top10Suppliers);

  // Create an array of hex values to use as colours for the top 10 suppliers
  const hexValuesArray = [
    "#FF933B",
    "#FF9D4D",
    "#FFA75E",
    "#FFB070",
    "#FFBA82",
    "#FFC494",
    "#FFCEA6",
    "#FFD8B8",
    "#FFE2C9",
    "#FFEBDB",
  ];
  // Creating an array of objects from top10Suppliers
  const top10SuppliersObjects = top10Suppliers.map((supplier, index) => ({
    y: supplier[0], // contactName
    x: supplier[2], // emissions
    color: hexValuesArray[index],
  }));

  // console.log("top10SuppliersObjects=>", top10SuppliersObjects);

  return { top10Suppliers, top10SuppliersObjects };
};

// Get Top 10 Emission Categories Description
export const getTopPrimaryCategoriesDescription = async (
  inventoryProgress,
  userData,
  fromDate,
  toDate
) => {
  // let topPrimaryCategoriesDescription =
  //   "This table showcases the highest emissions sources irrespective of scope, highlights the key activities or services and goods purchases that contribute to [[COMPANY_NAME]]'s total carbon footprint";

  let topPrimaryCategoriesDescription =
    "There are two 'types' of boundaries that need to be set when compiling a GHG inventory; an organisational boundary and an operational boundary. Organisational boundaries allow a business to distinguish between GHG emitting activities that are attributable to their organisation, and those that are not. Operational boundaries allow an organisation to define the emissions that they own or control and categorise them into different scopes (as either direct or indirect). Dividing emissions up into different scopes allows an organisation to determine opportunities for emissions reduction, as well as knowing where their emissions are occurring along the value chain.\n\n <br/>\n";

  // topPrimaryCategoriesDescription = topPrimaryCategoriesDescription.replace(
  //   "[[COMPANY_NAME]]",
  //   userData.businessName
  // );

  topPrimaryCategoriesDescription = topPrimaryCategoriesDescription.replace(
    "[[COMPANY_NAME]]",
    inventoryProgress.organisationname
  );

  return topPrimaryCategoriesDescription;
};

// Get Top 10 Emission Categories
export const getTopPrimaryCategories = async (userData, startDate, endDate) => {
  const formattedStartDate = formatDateToYYYYMM01(new Date(startDate));
  const formattedEndDate = formatDateToYYYYMM01(new Date(endDate));

  const emissionsByPrimaryCategory = await fetchEmissionsByPrimaryCategory(
    formattedStartDate,
    formattedEndDate
  );

  let Label = [];
  let Data = [];
  // console.log("emissionsByPrimaryCategory=>", emissionsByPrimaryCategory);

  if (emissionsByPrimaryCategory) {
    Label = emissionsByPrimaryCategory["Label"];
    Data = emissionsByPrimaryCategory["Data"];
  }

  let roundedData = Data.map((value) => Number(value.toFixed(3)));
  // Create an array of arrays combining labels with their corresponding data values
  let topPrimaryCategories = Label.map((label, index) => [
    label,
    roundedData[index],
  ]);
  // Sort the combined array by the data values in descending order
  topPrimaryCategories.sort((a, b) => b[1] - a[1]);

  // Slice the top 10 category-data pairs
  // console.log("topPrimaryCategories=>", topPrimaryCategories);

  const hexValuesArray = [
    "#FF8826",
    "#FF8F33",
    "#FF9640",
    "#FF9D4D",
    "#FFA459",
    "#FFAB66",
    "#FFB273",
    "#FFB980",
    "#FFC08C",
    "#FFC799",
    "#FFCEA6",
    "#FFD5B3",
    "#FFDCBF",
    "#FFE3CC",
    "#FFEAD9",
    "#FFEAD9",
    "#FFEAD9",
    "#FFEAD9",
    "#FFEAD9",
    "#FFEAD9",
  ];

  // Convert the topPrimaryCategories array into an array of objects that is formatted for the emissions boundary chart in the Hybiscus template
  const topPrimaryCategoriesObjects = topPrimaryCategories.map(
    (category, index) => ({
      y: category[0], // category
      x: category[1], // emissions
      color: hexValuesArray[index], // colour
    })
  );

  // console.log("topPrimaryCategoriesObjects=>", topPrimaryCategoriesObjects);

  return { topPrimaryCategories, topPrimaryCategoriesObjects };
};

// Get Methodology
export const getMethodology = async (
  inventoryProgress,
  userData,
  fromDate,
  toDate,
  issuedBy
) => {
  let methodology =
    "In order for [[COMPANY_NAME]] to negate the impact of its greenhouse gas emissions, it must first quantify them. [[ISSUER_NAME]] does this by conducting an emissions assessment and then applying the methodologies outlined within the World Business Council for Sustainable Development’s (WBCSD) Greenhouse Gas Accounting Protocol.\n\n<br/>\n# GHG Protocol\nThe protocol contains universally recognised accounting methods and boundaries that can be applied to different levels, sizes and types of organisations when creating their GHG inventory. This includes multinational organisations, energy intensive primary industry, as well as small to medium enterprises (SME). Boundaries are important when compiling a GHG inventory, as they give organisations consistency and scope when accounting for their emissions.";

  // methodology = methodology.replace("[[COMPANY_NAME]]", userData.businessName);
  methodology = methodology.replace("[[COMPANY_NAME]]", userData.businessName);
  methodology = methodology.replace("[[ISSUER_NAME]]", issuedBy);

  return methodology;
};

// Get Organisational Boundaries
export const getOrganisationalBoundaries = async (
  inventoryProgress,
  userData,
  fromDate,
  toDate,
  issuedBy
) => {
  let organisationalBoundaries = `
 # **Organisational boundaries**\n\n
  
  When setting organisational boundaries, [[ISSUER_NAME]] applies a financial control rationale, asserting that businesses should account for emissions generated from activities over which they have financial control, deriving the majority of financial benefits and/or risks. NetNada adopts this rationale, believing that the business is responsible for the products and services it consumes. The purchase is seen as an endorsement of the conditions under which goods and services are produced. This rationale is both comprehensive and simple: if you bought it, then the emissions produced and embodied within it are your responsibility. This clear delineation aims to ensure the best outcome for [[COMPANY_NAME]] and other certified businesses, instilling consumer confidence in the authenticity of organisations certified with NetNada. All emission sources listed in the emissions boundary are part of the inventory.
  
  <br/>
  
  **Quantified emissions** have been assessed as relevant and are quantified in the carbon inventory. This may include emissions that are not identified as arising due to the operations of the certified entity, however are **optionally included**.
  
  **Non-quantified emissions** have been assessed as relevant and are captured within the emissions boundary, but are not measured (quantified) in the carbon inventory.
  
  **Excluded emissions** are those that have been assessed as not relevant to an organisation's operations and are outside of its emissions boundary or are outside of the scope of the inventory.
  `;
  organisationalBoundaries = organisationalBoundaries.replace(
    "[[COMPANY_NAME]]",
    userData.businessName
  );

  // organisationalBoundaries = organisationalBoundaries.replace(
  //   "[[COMPANY_NAME]]",
  //   inventoryProgress.organisationname
  // );
  organisationalBoundaries = organisationalBoundaries.replace(
    "[[ISSUER_NAME]]",
    issuedBy
  );

  return organisationalBoundaries;
};

// Get Operational Boundaries
export const getOperationalBoundaries = async (
  userData,
  fromDate,
  toDate,
  issuedBy
) => {
  let operationalBoundaries =
    "# **Operational boundaries**\n\nThe primary function of operational boundaries is to categorize emissions from organizational operations into different scopes. These scopes, detailed below, help organizations delineate and define the origin of their emissions:\n\n<br/>\n\n**Scope 1: Direct GHG Emissions** Emissions originating from sources owned or controlled by the company. Examples include emissions from combustion in owned or controlled boilers, furnaces, and vehicles.\n\n**Scope 2: Electricity Indirect GHG Emissions** Emissions resulting from the generation of purchased electricity consumed by the company.\n\n**Scope 3: Other Indirect GHG Emissions** Emissions that arise as a consequence of the company's activities but occur from sources not owned or controlled by the company. This includes emissions from waste, the extraction and production of purchased materials, transportation of purchased fuels, and employee commuting.\n\n";

  // Replace [[ISSUER_NAME]] with the issuedBy value
  operationalBoundaries = operationalBoundaries.replace(
    /\[\[ISSUER_NAME\]\]/g,
    issuedBy
  );

  return operationalBoundaries;
};
// Get Emissions Boundaries table
export const getEmissionsBoundaries = async (
  userData,
  startDate,
  endDate,
  topPrimaryCategories
) => {
  const formattedStartDate = formatDateToYYYYMM01(new Date(startDate));
  const formattedEndDate = formatDateToYYYYMM01(new Date(endDate));

  const businessId = userData.businessId;
  let emissionsBoundaries = await getAllEmissionsBoundaries(
    userData.inventoryId
  );
  // console.log("emissionsBoundaries=>", emissionsBoundaries);
  // console.log("topPrimaryCategories=>", topPrimaryCategories);

  // const emissionsBySecondaryCategory =
  //   await fetchChartDataForEmissonsBySecondaryCategory(
  //     businessId,
  //     "categoryName",
  //     startDate,
  //     endDate
  //   );

  const emissionsBySecondaryCategory = await fetchSecondaryCategories(
    formattedStartDate,
    formattedEndDate
  );

  // console.log("emissionsBySecondaryCategory=>", emissionsBySecondaryCategory);

  // Turn the Data, Label arrays into one object
  let emissionsBySecondaryCategoryObject =
    emissionsBySecondaryCategory.Label.reduce(
      (obj, key, index) => ({
        ...obj,
        [key]: emissionsBySecondaryCategory.Data[index],
      }),
      {}
    );

  // console.log(
  //   "emissionsBySecondaryCategoryObject=>",
  //   emissionsBySecondaryCategoryObject
  // );

  if (!emissionsBoundaries) {
    return { emissionsBoundaryOverview: [], emissionsBoundaryReasons: [] };
  }

  let emissionsBoundaryOverview = [];
  let emissionsBoundaryReasons = [];

  // Does the colour and styling of the pill inside emissionsBoudnaryOverview
  emissionsBoundaries.forEach((obj) => {
    obj.items.forEach((item) => {
      if (item.emission_boundary === "Included") {
        item.emission_boundary =
          "<span style='display:inline-block; padding:1px 1px; background-color:#b8ec9c; color:black; border-radius:50px; text-align:center; min-width:100px;font-size:9px;'>Included</span>";
      } else if (item.emission_boundary === "Non-quantified") {
        item.emission_boundary =
          "<span style='display:inline-block; padding:1px 1px; background-color:#ff8f8f; color:black; border-radius:50px; text-align:center; min-width:100px;font-size:9px;'>Non-quantified</span>";
      } else if (item.emission_boundary === "Excluded") {
        item.emission_boundary =
          "<span style='display:inline-block; padding:1px 1px; background-color:#cccccc; color:black; border-radius:50px; text-align:center; min-width:100px;font-size:9px;'>Excluded</span>";
      }
      emissionsBoundaryOverview.push([item.title, item.emission_boundary]);
    });

    if (obj.name !== "Included") {
      obj.items.forEach((item) => {
        if (!item.reasoning) {
          item.reasoning = "No reason provided";
        }
        emissionsBoundaryReasons.push([item.title, item.reasoning]);
      });
    }
  });

  // console.log("emissionsBoundaryOverview=>", emissionsBoundaryOverview);

  emissionsBoundaryOverview = emissionsBoundaryOverview.map((overviewItem) => {
    const matchingCategory = topPrimaryCategories.find(
      (categoryItem) =>
        categoryItem[0].toLowerCase() === overviewItem[0].toLowerCase()
    );
    // console.log("overviewItem=>", overviewItem[0]);
    // console.log("matchingCategory=>", matchingCategory);
    // console.log("categoryItem=>", categoryItem[0]);

    // Add the value from topPrimaryCategories if a match is found, else add 0
    return matchingCategory
      ? [...overviewItem, matchingCategory[1]]
      : [...overviewItem, 0];
  });

  // console.log(
  //   "emissionsBoundaryOverview just before mapping =>",
  //   emissionsBoundaryOverview
  // );

  // This section is to do the mapping of NetNada categories + extras like Waste, Working from home, etc
  // to the categories in the emissions boundaries table

  let transportAirArray = emissionsBoundaryOverview.find(
    (subarray) => subarray[0] === "Transport (air)"
  );
  let transportLandAndSeaArray = emissionsBoundaryOverview.find(
    (subarray) => subarray[0] === "Transport (land and sea)"
  );
  let electricityArray = emissionsBoundaryOverview.find(
    (subarray) => subarray[0] === "Electricity"
  );
  let waterArray = emissionsBoundaryOverview.find(
    (subarray) => subarray[0] === "Water"
  );
  let workingFromHomeArray = emissionsBoundaryOverview.find(
    (subarray) => subarray[0] === "Working from home"
  );

  if (transportAirArray) {
    let additionalValue =
      (emissionsBySecondaryCategoryObject["Customs agencies"] || 0) +
      (emissionsBySecondaryCategoryObject["Airplane travel and flights"] || 0) +
      (emissionsBySecondaryCategoryObject["Airport operations"] || 0);
    // Add this value to the existing value in the 'Transport (air)' subarray

    let flightsArray = topPrimaryCategories.find(
      (category) => category[0] === "Flights"
    );
    let flightValue = flightsArray ? flightsArray[1] : 0;

    transportAirArray[2] = parseFloat(
      (transportAirArray[2] + additionalValue + flightValue).toFixed(3)
    );
  }

  if (transportLandAndSeaArray) {
    let additionalValue =
      (emissionsBySecondaryCategoryObject["Petrol and diesel"] || 0) -
      (emissionsBySecondaryCategoryObject["Customs agencies"] || 0) -
      (emissionsBySecondaryCategoryObject["Airplane travel and flights"] || 0) -
      (emissionsBySecondaryCategoryObject["Airport operations"] || 0);
    // let additionalValue =
    //   (emissionsBySecondaryCategoryObject["Bus and tramway"] || 0) +
    //   (emissionsBySecondaryCategoryObject["Railway passenger transport"] || 0) +
    //   (emissionsBySecondaryCategoryObject["Taxi and car hire"] || 0) +
    //   (emissionsBySecondaryCategoryObject["Travel facilitation"] || 0) +
    //   (emissionsBySecondaryCategoryObject["Water transport"] || 0) +
    //   (emissionsBySecondaryCategoryObject["Petrol and diesel"] || 0);

    let transportArray = topPrimaryCategories.find(
      (category) => category[0] === "Transport"
    );
    let transportValue = transportArray ? transportArray[1] : 0;

    //  -
    // (emissionsBySecondaryCategoryObject["Bicycle"] || 0) -
    // (emissionsBySecondaryCategoryObject["Bus"] || 0) -
    // (emissionsBySecondaryCategoryObject["Medium Car: unknown fuel"] || 0) -
    // (emissionsBySecondaryCategoryObject["Walk"] || 0) -
    // (emissionsBySecondaryCategoryObject["Ferry : Foot passenger"] || 0) -
    // (emissionsBySecondaryCategoryObject["Motorbike/scooter"] || 0) -
    // (emissionsBySecondaryCategoryObject["Taxi - National Average"] || 0) -
    // (emissionsBySecondaryCategoryObject["Train"] || 0) -
    // (emissionsBySecondaryCategoryObject["Light rail and tram"] || 0);

    // transportLandAndSeaArray[2] = parseFloat(
    //   (transportLandAndSeaArray[2] + additionalValue).toFixed(3)
    // );
    transportLandAndSeaArray[2] = Math.max(
      0,
      parseFloat(
        (
          transportLandAndSeaArray[2] +
          transportValue +
          additionalValue
        ).toFixed(3)
      )
    );
  }

  if (electricityArray) {
    let additionalValue =
      emissionsBySecondaryCategoryObject["Electricity supply"] || 0;
    electricityArray[2] = parseFloat(
      (electricityArray[2] + additionalValue).toFixed(3)
    );
  }

  if (waterArray) {
    let additionalValue =
      emissionsBySecondaryCategoryObject[
        "Water supply, sewerage and drainage services"
      ] || 0;
    waterArray[2] = parseFloat((waterArray[2] + additionalValue).toFixed(3));
  }

  if (workingFromHomeArray) {
    // Find the subarray for 'WFH' and get the value from index 1
    let wfmArray = topPrimaryCategories.find(
      (category) => category[0] === "WFH"
    );
    let additionalValue = wfmArray ? wfmArray[1] : 0;
    workingFromHomeArray[2] = parseFloat(
      (workingFromHomeArray[2] + additionalValue).toFixed(3)
    );
  }

  emissionsBoundaryOverview.sort((a, b) => b[2] - a[2]);
  // console.log("emissionsBoundaryOverview=>", emissionsBoundaryOverview);

  return { emissionsBoundaryOverview, emissionsBoundaryReasons };
};

// Get Scope Emissions Donut Description
export const getScopeEmissionsDonutDescription = async (
  inventoryProgress,
  userData,
  fromDate,
  toDate
) => {
  let scopeEmissionsDonutDescription =
    "The GHG protocol designates scopes 1 and 2 as mandatory reporting categories, while scope 3 is voluntary. However, under NetNada certification program, organisations are obligated to include scope 3 emissions. This mandate stems from the significant embodied emissions associated with the sale, delivery, and purchase of products and services. 'Embodied emissions' refer to emissions generated in the manufacture and distribution of a product, where energy is often provided by fossil fuels, contributing to greenhouse gas emissions. [[COMPANY_NAME]] includes embodied emissions based on the products and services it has acquired as part of the increasing number of companies that understand the need to also account for GHG emissions along their value chains and product portfolios to comprehensively manage GHG-related risks and opportunities.";

  // scopeEmissionsDonutDescription = scopeEmissionsDonutDescription.replace(
  //   "[[COMPANY_NAME]]",
  //   userData.businessName
  // );
  scopeEmissionsDonutDescription = scopeEmissionsDonutDescription.replace(
    "[[COMPANY_NAME]]",
    inventoryProgress.organisationname
  );

  return scopeEmissionsDonutDescription;
};

// Take the emissions from scope 1, 2 and 3 and format them into a donut chart
export const getScopeEmissionsDonut = async (
  userData,
  startDate,
  endDate,
  scopeEmissions
) => {
  // console.log("scopeEmissions=>", scopeEmissions);

  if (scopeEmissions.total === 0) {
    return [];
  }

  if (!scopeEmissions.scope1 && scopeEmissions.scope1 !== 0) {
    scopeEmissions.scope1 = 0;
  }
  if (!scopeEmissions.scope2 && scopeEmissions.scope2 !== 0) {
    scopeEmissions.scope2 = 0;
  }
  if (!scopeEmissions.scope3 && scopeEmissions.scope3 !== 0) {
    scopeEmissions.scope3 = 0;
  }

  let scope1percent = (scopeEmissions.scope1 / scopeEmissions.total) * 100;
  let scope2percent = (scopeEmissions.scope2 / scopeEmissions.total) * 100;
  let scope3percent = (scopeEmissions.scope3 / scopeEmissions.total) * 100;

  let scope1Start = 0;
  let scope2Start = scope1percent;
  let scope3Start = scope1percent + scope2percent;

  if (scope2percent > 0) {
    scope2Start = scope1percent - 10;
  }
  if (scope3percent > 0) {
    scope3Start = scope1percent + scope2percent - 10;
  }

  if (scope2Start < 0) {
    scope2Start = 0;
  }
  if (scope3Start < 0) {
    scope3Start = 0;
  }

  let scopeEmissionsDonut = [
    {
      start: parseInt(scope1Start),
      end: parseInt(scope1percent),
      label: "Scope 1",
      colour: "#c0143c",
    },
    {
      start: parseInt(scope2Start),
      end: parseInt(scope1percent + scope2percent),
      label: "Scope 2",
      colour: "#ff8c3c",
    },
    {
      start: parseInt(scope3Start),
      end: parseInt(scope1percent + scope2percent + scope3percent),
      label: "Scope 3",
      colour: "#204cdc",
    },
  ];

  return scopeEmissionsDonut;
};

// Get the locations saved in the database
export const getLocations = async (
  inventoryProgress,
  userData,
  startDate,
  endDate
) => {
  // Try to get the data from session storage
  const userBusinessLocations = sessionStorage.getItem("userBusinessLocations");
  let storedLocations;

  if (userBusinessLocations) {
    storedLocations = JSON.parse(userBusinessLocations);
  } else {
    console.log("No userBusinessLocations found in session storage");
  }

  // // Get locations from local storage
  // const inventoryProgress = getInventoryProgress();
  // let inventoryProgressParsed = JSON.parse(inventoryProgress);
  // let inventoryProgressLocations = inventoryProgressParsed[0].locations;

  // Get locations from local storage
  let inventoryProgressLocations = inventoryProgress.locations;

  if (
    inventoryProgressLocations === null ||
    inventoryProgressLocations === undefined
  ) {
    return [];
  }

  // console.log("inventoryProgressLocations=>", inventoryProgressLocations);
  // console.log("storedLocations=>", storedLocations);

  const selectedLocations = storedLocations.filter((location) =>
    inventoryProgressLocations.includes(location.office_id)
  );

  const selectedLocationsFormatted = selectedLocations.map((object) => [
    object.officename,
    object.country,
    object.location,
    object.street,
    object.city,
    object.postcode,
  ]);

  return selectedLocationsFormatted;
};

export const getScope3Emissions = async (userData, startDate, endDate) => {
  const businessId = userData.businessId;
  const authProvider = userData.authProvider;
  let scope3 = await fetchTwelveMonthsWithPrecedenceChartData(
    businessId,
    "",
    "SCOPE3",
    "",
    "Date",
    authProvider,
    startDate,
    endDate
  );

  if (scope3?.Data && scope3?.Label) {
    //scope3DataByAccountName = scope3DataByAccountName.Label.map((e, i) => { {name: e, value1: scope3DataByAccountName.Data[i] }});
    scope3 = scope3.Label.map(function (e, i) {
      return {
        x: scope3.Label[i],
        y: scope3.Data[i] > 0 ? scope3.Data[i] * 1000 : 0,
      };
    });
  } else {
    scope3 = [];
  }
  return scope3;
};

export const getScope2GasEmissions = async (userData, startDate, endDate) => {
  const businessId = userData.businessId;
  const authProvider = userData.authProvider;
  let scope2GasData = await fetchTwelveMonthsWithPrecedenceChartData(
    businessId,
    "all",
    "SCOPE2",
    "Natural gas",
    "Date",
    authProvider,
    startDate,
    endDate
  );

  if (scope2GasData?.Data && scope2GasData?.Label) {
    scope2GasData = scope2GasData.Label.map(function (e, i) {
      return {
        x: scope2GasData.Label[i],
        y: scope2GasData.Data[i] > 0 ? scope2GasData.Data[i] * 1000 : 0,
      };
    });
  } else {
    scope2GasData = [];
  }
  return scope2GasData;
};

export const getScope2ElectricityEmissions = async (
  userData,
  startDate,
  endDate
) => {
  const businessId = userData.businessId;
  const authProvider = userData.authProvider;
  let scope2ElectricityData = await fetchTwelveMonthsWithPrecedenceChartData(
    businessId,
    "all",
    "SCOPE2",
    "Electricity supply",
    "Date",
    authProvider,
    startDate,
    endDate
  );

  if (scope2ElectricityData?.Data && scope2ElectricityData?.Label) {
    scope2ElectricityData = scope2ElectricityData.Label.map(function (e, i) {
      return {
        x: scope2ElectricityData.Label[i],
        y:
          scope2ElectricityData.Data[i] > 0
            ? scope2ElectricityData.Data[i] * 1000
            : 0,
      };
    });
  } else {
    scope2ElectricityData = [];
  }
  return scope2ElectricityData;
};

export const getScope1PetrolAndDieselEmissions = async (
  userData,
  startDate,
  endDate
) => {
  const businessId = userData.businessId;
  const authProvider = userData.authProvider;

  let scope1Data = await fetchTwelveMonthsWithPrecedenceChartData(
    businessId,
    "all",
    "SCOPE1",
    "Petrol and diesel",
    "Date",
    authProvider,
    startDate,
    endDate
  );

  if (scope1Data?.Data && scope1Data?.Label) {
    scope1Data = scope1Data.Label.map(function (e, i) {
      return {
        x: scope1Data.Label[i],
        y: scope1Data.Data[i],
      };
    });
  } else {
    scope1Data = [];
  }
  return scope1Data;
};

// async function getUnifiedDataByContactName(businessId, startDate, endDate) {
//   let unifiedData = [];
//   try {
//     const qry = query(
//       collection(fireStoreDB, "UnifiedData", businessId, "DataLines"),
//       where("inclusionStatus", "==", "INCLUDED"),
//       where("contactName", "!=", "")
//     );
//     const querySnapshot = await getDocs(qry);
//     querySnapshot.forEach((doc) => {
//       unifiedData.push({
//         id: doc.id,
//         ...doc.data(),
//       });
//     });

//     //fetch bill date and emissions only in last 12month
//     if (unifiedData.length > 0) {
//       unifiedData = toFetchDateFilteredData(unifiedData, startDate, endDate);
//     }
//     return unifiedData;
//   } catch (error) {
//     console.log(error);
//   }
// }

const toFetchDateFilteredData = (data, startDate, endDate) => {
  let dateFilteredData = [];
  // change timestamp to a date string to filter dates
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  //last 12th month
  // const months = 12;
  // const { start, end } = fetchDateRange(months);
  // console.log(start, end);
  data.forEach((item) => {
    const date = formatDate(item.date);
    if (new Date(date) >= startDate && new Date(date) <= endDate) {
      dateFilteredData.push(item);
    }
  });
  return dateFilteredData;
};

const fetchDateRange = (months) => {
  var start = new Date();
  start.setMonth(start.getMonth() - months);
  var end = new Date();
  return { start, end };
};
