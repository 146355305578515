import { getAuthorizationHeaderWithContentType } from "../../helpers/utils";
import { clearLoginData } from "../../helpers/store";
import { toast } from "react-toastify";

export const saveOnBoardingDetails = async (body) => {
  try {
    const response = await fetch(
      "/api/users/update-business-onboarding-details",
      {
        method: "POST",
        headers: getAuthorizationHeaderWithContentType(),
        body: JSON.stringify(body),
        mode: "cors",
      }
    );

    if (response.status === 401) {
      clearLoginData();
    } else if (response.status !== 200) {
      toast.error("Failed to save onboarding details.");
    } else {
      toast.success(`Successfully saved.`);
    }
  } catch (error) {
    console.error(error);
  }
};
