import React, { useReducer } from "react";
import PropTypes from "prop-types";
import { ProjectContext } from "../../context/Context";
import { projectData } from "../../data/ecommerce/productData";
import { projectReducer } from "../../reducers/projectReducer";

const ProjectProvider = ({ children }) => {
  const initData = {
    projects: [],
    currentProject: {},
    cartItems: [],
    favouriteItems: [],
    portfolioModal: {
      show: false,
      project: {},
      totalEmissions: 0,
      type: "add",
    },
    totalEmissions: 0,
  };
  const [projectsState, projectsDispatch] = useReducer(
    projectReducer,
    initData
  );

  const isInShoppingCart = (id) =>
    !!projectsState.cartItems.find((cartItem) => cartItem.id === id);
  const isInFavouriteItems = (id) =>
    !!projectsState.favouriteItems.find(
      (favouriteItem) => favouriteItem.id === id
    );

  return (
    <ProjectContext.Provider
      value={{
        projectsState,
        projectsDispatch,
        isInShoppingCart,
        isInFavouriteItems,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

ProjectProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProjectProvider;
