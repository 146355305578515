import React from "react";
import AdvanceTableWrapper from "../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../common/advance-table/AdvanceTable";
import { Button, Card } from "react-bootstrap";
import { LuInspect } from "react-icons/lu";
import { GrRadialSelected } from "react-icons/gr";
import { GrRadial } from "react-icons/gr";

function WaterDashboardTable({
  tableData,
  showWeight,
  handleSelect,
  selectedOfficeId,
}) {
  const emissionsColumns = [
    {
      accessor: "officeName",
      Header: "Location Name",
      Cell: ({ row }) => {
        const isSelected = row.original?.officeId === selectedOfficeId;
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="link"
              onClick={() => {
                handleSelect(row.original.officeId);
              }}
              style={{ color: row.original.color, padding: 0 }}
            >
              {isSelected ? <GrRadialSelected /> : <GrRadial />}
            </Button>
            <span style={{ marginLeft: "5px" }}>{row.original.officeName}</span>
          </div>
        );
      },
    },
    // {
    //   accessor: "divertedEmissions",
    //   Header: "Diverted t-CO₂e",
    // },
    {
      accessor: "totalEmissions",
      Header: "Total t-CO₂e",
    },
    {
      accessor: "totalEmissionsPercentage",
      Header: "% of total",
    },
    {
      accessor: "edit",
      Header: "",
      Cell: ({ row }) => {
        return (
          <Button
            variant="link"
            onClick={() => {
              handleSelect(row.original.officeId);
            }}
            // style={{ color: row.original.color }}
          >
            <LuInspect />
          </Button>
        );
      },
    },
  ];
  const weightColumns = [
    {
      accessor: "officeName",
      Header: "Location Name",
      Cell: ({ row }) => {
        const isSelected = row.original?.officeId === selectedOfficeId;
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="link"
              onClick={() => {
                handleSelect(row.original.officeId);
              }}
              style={{ color: row.original.color, padding: 0 }}
            >
              {isSelected ? <GrRadialSelected /> : <GrRadial />}
            </Button>
            <span style={{ marginLeft: "5px" }}>{row.original.officeName}</span>
          </div>
        );
      },
    },
    // {
    //   accessor: "divertedWeight",
    //   Header: "Diverted (t)",
    // },
    {
      accessor: "totalWeight",
      Header: "Total (kL)",
    },
    {
      accessor: "totalWeightPercentage",
      Header: "% of total",
    },
    {
      accessor: "edit",
      Header: "",
      Cell: ({ row }) => {
        return (
          <Button
            variant="link"
            onMouseEnter={() => handleSelect(row.original.officeId)}
          >
            <LuInspect />
          </Button>
        );
      },
    },
  ];
  /* <div style={{ width: "100px", overflowX: "auto" }}>Hello</div> */
  const columns = showWeight ? weightColumns : emissionsColumns;
  return (
    <>
      <h6>Location Table</h6>
      <AdvanceTableWrapper
        columns={columns}
        data={tableData}
        sortabl-e
        pagination
        perPage={10}
        rowCount={tableData.length}
      >
        <Card>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="btn-reveal-trigger text-nowrap align-middle"
              tableProps={{
                size: "lg",
                className: "fs--1 mb-0 overflow-hidden",
              }}
            />
          </Card.Body>
          {/* <Card.Footer>
    <AdvanceTableFooter
      data={categoriesData}
      rowCount={categoriesData.length}
      table
      rowInfo
      navButtons
    />
  </Card.Footer> */}
        </Card>
      </AdvanceTableWrapper>
    </>
  );
}

export default WaterDashboardTable;
