import React from "react";
import { Badge, Image } from "react-bootstrap";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import classNames from "classnames";

const ProjectSingleImage = ({ id, imageSrc, title, layout }) => {
  return (
    <Image
      rounded={layout === "list"}
      src={imageSrc}
      className={classNames("img-fluid", {
        "rounded-top": layout === "grid",
      })}
      alt={title}
      style={{ objectFit: "cover", height: "200px", width: "100%" }}
    />
  );
};

const ProjectImage = ({ id, imageSrc, title, layout }) => {
  return (
    <div
      className={classNames("position-relative overflow-hidden", {
        "rounded-top": layout === "grid",
        "d-flex align-items-start": layout === "list", // This will align items to the top in a list layout
      })}
      style={{ height: "200px" }} // This fixed height will align all images to the top
    >
      <ProjectSingleImage
        id={id}
        imageSrc={imageSrc}
        title={title}
        layout={layout}
      />
    </div>
  );
};

ProjectSingleImage.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  imageSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
};

ProjectImage.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  imageSrc: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
};

export default ProjectImage;
