// NavItemPill.js
import React from "react";
import { Nav } from "react-bootstrap";
import classNames from "classnames";
import Flex from "../common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Define the CSS styles as a string
const styles = `
  .nav-item-pill {
    border-radius: 0;
    padding: 0.75rem 1.25rem;
    margin: 0 0.25rem;
    background-color: #f0f0f0;
    color: #333;
    font-weight: 500;
    transition: background-color 0.3s, color 0.3s;
    cursor: pointer;
    text-decoration: none;
  }

  .nav-item-pill:hover {
    background-color: #e0e0e0;
    color: #000;
    text-decoration: none;
  }

  .nav-item-pill.active {
    background-color: #007bff;
    color: #fff;
  }

  .nav-item-pill.active:hover {
    background-color: #0056b3;
    color: #fff;
  }
`;

// Function to inject styles into the document head
const injectStyles = () => {
  if (typeof document !== "undefined") {
    if (!document.getElementById("nav-item-pill-styles")) {
      const style = document.createElement("style");
      style.id = "nav-item-pill-styles";
      style.innerHTML = styles;
      document.head.appendChild(style);
    }
  }
};

// Call the function to inject styles
injectStyles();

const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
  const isActive = step === index;

  return (
    <Nav.Item>
      <Nav.Link
        className={classNames("nav-item-pill", { active: isActive })}
        onClick={() => handleNavs(index)}
      >
        <Flex alignItems="center" justifyContent="center" style={{ gap: 5 }}>
          {icon}
          <span className="d-none d-md-block mt-1" style={{ fontSize: 15 }}>
            {label}
          </span>
        </Flex>
      </Nav.Link>
    </Nav.Item>
  );
};

export default NavItemPill;
