import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col, Form, Row, Button } from "react-bootstrap";
import { updateCompletedGrants } from "./grantsAPI";
import IconButton from "../../components/common/IconButton";
import { faCheck, faBarsProgress } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../Contexts/AuthContext";

const GrantTableHeader = ({ selectedRowIds }) => {
  console.log(selectedRowIds);
  const { currentUser } = useAuth();
  const [business, setBusiness] = useState(currentUser.businessId);

  async function handleClick(status) {
    if (status !== null) {
      let body = { status, updatedBy: currentUser.uid };
      let keys = Object.keys(selectedRowIds);
      const selectedGrants = JSON.parse(
        sessionStorage.getItem("selectedGrants")
      );
      for (let i in keys) {
        let grantId = selectedGrants[keys[i]]["id"];
        let officeId = selectedGrants[keys[i]]["office_id"];
        await updateCompletedGrants(grantId, officeId, body);
      }
      window.location = "/welcome/grants/my-grants";
    }
  }

  return (
    <Row className="flex-between-center">
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
          See and change the status of your grants.
        </h5>
      </Col>
      <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
        <div id="orders-actions">
          <IconButton
            variant="falcon-default"
            size="sm"
            icon={faCheck}
            transform="shrink-3"
          >
            <span
              className="d-none d-sm-inline-block ms-1"
              onClick={() => {
                handleClick("Completed");
              }}
            >
              Mark As Completed
            </span>
          </IconButton>
          <IconButton
            variant="falcon-default"
            size="sm"
            icon={faBarsProgress}
            transform="shrink-3"
            className="mx-2"
          >
            <span
              className="d-none d-sm-inline-block ms-1"
              onClick={() => {
                handleClick("InProgress");
              }}
            >
              Mark As In-Progress
            </span>
          </IconButton>
        </div>
      </Col>
    </Row>
  );
};

GrantTableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
};

export default GrantTableHeader;
