import React, { useMemo } from "react";
import AdvanceTableWrapper from "../common/advance-table/AdvanceTableWrapper";
import { Card } from "react-bootstrap";
import AdvanceTable from "../common/advance-table/AdvanceTable";

function WaterTable({ categoriesData }) {
  const waterColumns = useMemo(() => {
    const baseColumns = [
      {
        accessor: "category",
        Header: "Category",
      },
      {
        accessor: "volumeOfWater",
        Header: "Volume (kL)",
      },
    ];

    if (categoriesData[0]?.source === "estimator") {
      return [
        ...baseColumns,
        {
          accessor: "developmentType",
          Header: "Development Type",
        },
        {
          accessor: "developmentSubType",
          Header: "Development Sub-Type",
        },
        {
          accessor: "keyMetric",
          Header: "Key Metric",
        },
        {
          accessor: "keyMetricUnit",
          Header: "Key Metric Unit",
        },
        {
          accessor: "keyMetricValue",
          Header: "Key Metric Value",
        },
      ];
    }

    return baseColumns;
  }, [categoriesData]);

  //   if (isLoading) {
  //     return <div>Loading...</div>;
  //   }
  return (
    <>
      <div className="mb-3">
        <AdvanceTableWrapper
          columns={waterColumns}
          data={categoriesData}
          sortable
          pagination
          perPage={10}
          rowCount={categoriesData.length}
        >
          <Card>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="btn-reveal-trigger text-nowrap align-middle"
                tableProps={{
                  size: "lg",
                  className: "fs--1 mb-0 overflow-hidden",
                }}
              />
            </Card.Body>
            {/* <Card.Footer>
            <AdvanceTableFooter
              data={categoriesData}
              rowCount={categoriesData.length}
              table
              rowInfo
              navButtons
            />
          </Card.Footer> */}
          </Card>
        </AdvanceTableWrapper>
      </div>
    </>
  );
}

export default WaterTable;
