import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  runTransaction,
  doc,
} from "firebase/firestore";

import { getActiveUserData } from "./store";
import app from "../firebase";

const fireStoreDB = getFirestore(app);

export async function deleteLinesGroupedUnifiedData(data) {
  const activeUser = JSON.parse(getActiveUserData());
  try {
    await runTransaction(fireStoreDB, async (transaction) => {
      // Get a reference to the GroupedUnifiedData collection
      const groupedDataCollection = collection(
        fireStoreDB,
        "GroupedUnifiedData",
        activeUser.businessId,
        "Groups"
      );

      // Look for a document in GroupedUnifiedData/businessId/Groups with the same date, primaryCategory, secondaryCategory and supplier
      let deleteQuery = query(
        groupedDataCollection,
        where("date", "==", data.date),
        where("primaryCategory", "==", data.primaryCategory),
        where("secondaryCategory", "==", data.secondaryCategory),
        where("scope", "==", data.scope),
        where("dataSource", "==", data.dataSource)
      );
      // Additional conditions based on dataSource
      if (data.dataSource === "Bill") {
        deleteQuery = query(
          deleteQuery,
          where("contactName", "==", data.contactName),
          where("officeId", "==", data.officeId)
        );
      } else if (data.dataSource !== "Form") {
        deleteQuery = query(
          deleteQuery,
          where("contactName", "==", data.contactName),
          where("accountName", "==", data.accountName)
        );
      }
      // no supplier in the form data
      // else if (data.dataSource === "Form") {
      //   deleteQuery = query(
      //     deleteQuery,
      //     where("officeId", "==", data.officeId)
      //   );
      // }

      const deleteSnapshot = await getDocs(deleteQuery);

      // Delete all documents in one batch operation
      deleteSnapshot.forEach((doc) => {
        transaction.delete(doc.ref);
      });
    });

    console.log("Transaction delete operation completed.");
  } catch (error) {
    console.error("Error updating GroupedUnifiedData:", error);
  }
}

export async function updateGroupedUnifiedData(data) {
  const activeUser = JSON.parse(getActiveUserData());

  try {
    await runTransaction(fireStoreDB, async (transaction) => {
      for (let i = 0; i < data.length; i++) {
        const groupedUnifiedDataCollectionRef = collection(
          fireStoreDB,
          "GroupedUnifiedData",
          activeUser.businessId,
          "Groups"
        );

        const qry = query(
          groupedUnifiedDataCollectionRef,
          where("date", "==", data[i].date),
          where("contactName", "==", data[i].contactName)
        );

        const querySnapshot = await getDocs(qry);

        if (querySnapshot.empty) {
          continue;
        }

        querySnapshot.forEach((doc) => {
          const docRef = doc.ref;
          transaction.update(docRef, {
            scope: data[i].scope,
            primaryCategory: data[i].primaryCategory,
            secondaryCategory: data[i].secondaryCategory,
            totalEmissions: data[i].totalEmissions,
            categoryMatchingSource: data[i].categoryMatchingSource,
          });
        });
      }
    });

    console.log("Transaction update operation completed.");
  } catch (error) {
    console.error("Error updating GroupedUnifiedData:", error);
  }
}

export async function setGroupedUnifiedDataForDateAndContact(data) {
  const activeUser = JSON.parse(getActiveUserData());

  // new Date(updatedDate).toISOString();
  const startDate = new Date(activeUser.currentStartDate).toISOString();
  const endDate = new Date(activeUser.currentEndDate).toISOString();

  // console.log("startDate", startDate);
  // console.log("endDate", endDate);

  try {
    await runTransaction(fireStoreDB, async (transaction) => {
      const groupedUnifiedDataCollectionRef = collection(
        fireStoreDB,
        "GroupedUnifiedData",
        activeUser.businessId,
        "Groups"
      );

      // Query to find documents to delete
      const deleteQuery = query(
        groupedUnifiedDataCollectionRef,
        where("contactName", "==", data[0].contactName), // Assuming all data have the same contactName
        where("date", ">=", startDate),
        where("date", "<=", endDate)
      );

      const querySnapshot = await getDocs(deleteQuery);
      querySnapshot.forEach((docSnapshot) => {
        transaction.delete(
          doc(fireStoreDB, groupedUnifiedDataCollectionRef.path, docSnapshot.id)
        );
      });

      // Adding new documents
      for (let i = 0; i < data.length; i++) {
        const newDocRef = doc(groupedUnifiedDataCollectionRef);
        transaction.set(newDocRef, data[i]);
      }
    });

    console.log("Transaction update operation completed.");
  } catch (error) {
    console.error("Error updating GroupedUnifiedData:", error);
  }
}
