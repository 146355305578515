import React from "react";

import BarChart from "./BarChart";
import { isAllDataZero } from "./chartHelpers";

function ScopeOneCard({ data }) {
  const highlightStyle = {
    // fontWeight: 'bold',
    color: "#007bff",
  };
  const scopeOneData = data?.map(({ month, scope1 }) => ({
    month,
    scope1: scope1?.toFixed(2),
  }));

  const keys = ["scope1"];
  
  const colorsChart = [
    "#7ba8d9", 
  ];
  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        <h4>Scope 1</h4>
        <p>
          Scope 1 emissions are direct greenhouse gas emissions that occur from
          sources that are owned or controlled by a company. This includes
          emissions from combustion in owned or controlled boilers, furnaces,
          vehicles, etc.
        </p>
      </div>
      <BarChart
        data={scopeOneData}
        colorTheme={colorsChart}
        keys={keys}
        title={"Scope 1 Data"}
        skipTotals={true}
      />
    </>
  );
}

export default ScopeOneCard;
