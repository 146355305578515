import React, { useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faCircle } from "@fortawesome/free-solid-svg-icons";
import { Button, Card } from "react-bootstrap";
import { isAllDataZero } from "./chartHelpers";
import { useNavigate } from "react-router-dom";
import ActivityDataModal from "./ActivityDataUpload";

function BarChart({
  data,
  colorTheme,
  keys,
  title,
  small,
  skipTotals,
  leftLegendOffset = -40,
  units,
  titleSize,
  urlAddData,
}) {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const allDataIsZero = isAllDataZero(data, keys);
  let legendConfig = [];
  legendConfig = [
    {
      dataFrom: "keys",
      anchor: "bottom-right",
      direction: "column",
      justify: false,
      translateX: 100,
      translateY: 0,
      itemsSpacing: 2,
      itemWidth: 100,
      itemHeight: 20,
      itemDirection: "left-to-right",
      itemOpacity: 0.85,
      symbolSize: 18,
      effects: [
        {
          on: "hover",
          style: {
            itemOpacity: 1,
          },
        },
      ],
    },
  ];

  let transformedData = data.map((monthData) => {
    return {
      ...monthData,
      // total: (monthData.scope1),
      month: small ? monthData.month.substring(0, 3) : monthData.month,
    };
  });

  const handleModal = () => {
    if (!urlAddData) {
      setShowModal(true);
    } else {
      navigate(urlAddData);
    }
  };

  return (
    <>
      <div style={{ height: "360px" }}>
        {titleSize ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h6>{title}</h6>
          </div>
        ) : (
          <h5>{title}</h5>
        )}
        {!allDataIsZero ? (
          <ResponsiveBar
            data={transformedData}
            keys={keys}
            indexBy="month"
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.3}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={
              !colorTheme ? ({ id, data }) => data[`${id}Color`] : colorTheme
            }
            borderWidth={2}
            borderColor={{
              from: "color",
              modifiers: [["darker", "0.5"]],
            }}
            borderRadius={1}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Month",
              legendPosition: "middle",
              legendOffset: 32,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              ticksRotation: 0,
              legend: units || "CO₂e (tonnes)",
              legendPosition: "middle",
              legendOffset: leftLegendOffset,
            }}
            enableGridY={false}
            labelTextColor={{ from: "color", modifiers: [["darker", 3]] }}
            labelSkipWidth={small ? 1000 : 0}
            labelSkipHeight={small ? 1000 : 20}
            enableTotals={skipTotals ? false : true}
            legends={legendConfig}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            theme={{
              labels: {
                text: {
                  fontSize: 14,
                },
              },
            }}
            tooltip={({ id, value, color }) => {
              // console.log(color);
              return (
                <Card style={{ opacity: 0.8 }}>
                  <div
                    style={{
                      display: "flex",
                      gap: 2,
                      padding: 3,
                    }}
                  >
                    <div style={{ paddingLeft: 8 }}>
                      <FontAwesomeIcon
                        icon={faCircle}
                        className="me-2"
                        style={{ color: color }}
                      />
                    </div>
                    <div>{id}:</div>
                    <div style={{ paddingRight: 8 }}>
                      <strong>{value}</strong> {units}
                    </div>
                  </div>
                </Card>
              );
            }}
          />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <div>
              <p>No data available</p>
            </div>
            <div>
              {" "}
              <Button size="sm" onClick={() => handleModal()}>
                <FontAwesomeIcon icon={faAdd} /> Add Data
              </Button>
            </div>
          </div>
        )}
      </div>
      <ActivityDataModal
        showActivityDataModal={showModal}
        toggleActivityDataModal={() => setShowModal(!showModal)}
      />
    </>
  );
}

export default BarChart;
