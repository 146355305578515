import FlatFileUploader from "../../common/FlatFileUploader";
import { flightFlatFileWorkbook } from "./flightFlatFileWorkbook";
import { flightFlatFileListener } from "./flightFlatFileListener";

import "react-toastify/dist/ReactToastify.css";

const FlightFlatFileUploader = ({ metadata }) => (
  <FlatFileUploader
    metadata={metadata}
    config={{
      title: "Flight Data Uploader",
      workbook: flightFlatFileWorkbook,
      listener: flightFlatFileListener,
      operation: "flight:submit",
      redirectUrl: "/welcome/data/climate-active/flights-form",
    }}
  />
);

export default FlightFlatFileUploader;
