/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-case-declarations */
import { toast } from "react-toastify";
// dummy promo codes
const promoCodes = [
  { code: "GET20", discount: 20 },
  { code: "GET50", discount: 50 },
];

export const projectReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SET_PROJECTS":
      return {
        ...state,
        projects: payload,
      };
    case "SORT_PROJECT":
      return {
        ...state,
        projects: [...state.projects].sort((a, b) => {
          if (payload.sortBy === "review") {
            if (payload.order === "asc") {
              return a.reviews.length - b.reviews.length;
            } else {
              return b.reviews.length - a.reviews.length;
            }
          } else {
            if (payload.order === "asc") {
              return a[payload.sortBy] - b[payload.sortBy];
            } else {
              return b[payload.sortBy] - a[payload.sortBy];
            }
          }
        }),
      };
    case "SET_CURRENT_PROJECT":
      return {
        ...state,
        currentProject: payload.currentProject,
        portfolioModal: {
          show: payload.showModal,
        },
      };
    case "ADD_TO_CART":
      return {
        ...state,
        cartItems: [...state.cartItems, payload.project],
        portfolioModal: {
          show: payload.showModal,
          project: payload.project,
        },
      };
    case "UPDATE_CART_ITEM":
      return {
        ...state,
        cartItems: state.cartItems.map((item) =>
          item.id === payload.project.id ? payload.project : item
        ),
        portfolioModal: {
          show: payload.showModal,
          project: payload.project,
        },
      };

    case "REMOVE_FROM_CART":
      return {
        ...state,
        cartItems: state.cartItems.filter(
          (project) => project.id !== payload.project.id
        ),
        portfolioModal: {
          show: false,
          project: payload.project,
          type: "remove",
        },
      };

    case "ADD_TO_FAVOURITES":
      return {
        ...state,
        projects: state.projects.map((project) =>
          project.id === payload.project.id
            ? { ...project, favorite: project.favorite + 1 }
            : project
        ),
        favouriteItems: [...state.favouriteItems, payload.project],
      };

    case "REMOVE_FROM_FAVOURITES":
      return {
        ...state,
        projects: state.projects.map((project) =>
          project.id === payload.project.id
            ? { ...project, favorite: project.favorite - 1 }
            : project
        ),
        favouriteItems: state.favouriteItems.filter(
          (project) => project.id !== payload.project.id
        ),
      };

    case "SET_TOTAL_EMISSIONS":
      return {
        ...state,
        totalEmissions: payload,
        cartItems: state.cartItems.map((project) => ({
          ...project,
          totalEmission: payload * (project.percentage / 100),
          projectCost:
            payload * (project.percentage / 100) * project.costpertonne,
        })),
      };
    case "TOGGLE_CART_MODAL":
      return {
        ...state,
        portfolioModal: {
          ...state.portfolioModal,
          show: !state.portfolioModal.show,
        },
      };
    case "APPLY_PROMO": {
      const code = promoCodes.find((promo) => promo.code === payload.promoCode);
      if (code) {
        toast.success(
          <span>
            Congratulations, You got <strong>${code.discount}%</strong>{" "}
            discount!
          </span>
        );
      } else {
        toast.error("Promo code is not valid! Try again.");
      }
      return {
        ...state,
        promo: code,
      };
    }
    case "CHECKOUT": {
      return {
        ...state,
        cartItems: [],
      };
    }
    case "RESET":
      return {
        ...state,
      };
    default:
      return state;
  }
};
