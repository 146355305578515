import { clearLoginData } from "../../helpers/store";
import { getAuthorizationHeaderWithContentType } from "../../helpers/utils";
import { toast } from "react-toastify";

export const getInviteUrl = async (travelType) => {
  try {
    const response = await fetch("/api/wfh/get-invite-url", {
      method: "GET",
      headers: getAuthorizationHeaderWithContentType(),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const saveWfhForm = async (body) => {
  try {
    const response = await fetch("/api/wfh/save-wfh-form", {
      method: "POST",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 200) {
      await response.json();
      toast.success("WFH form saved");
    } else if (response.status === 401) {
      clearLoginData();
    } else {
      toast.error("Failed to save WFH form");
    }
  } catch (error) {
    console.error(error);
  }
};

export const getWfhForms = async () => {
  try {
    const response = await fetch("/api/wfh/get-wfh-forms", {
      method: "GET",
      headers: getAuthorizationHeaderWithContentType(),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const getWfhFormUserCount = async (formId) => {
  try {
    const response = await fetch(
      `/api/wfh/get-user-count-by-form-id/${formId}`,
      {
        method: "GET",
        headers: getAuthorizationHeaderWithContentType(),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const saveWfhFormData = async (body) => {
  try {
    const response = await fetch("/api/wfh/save-wfh-form-data", {
      method: "POST",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 200) {
      toast.success("WFH form data saved");
      return await response.json();
    } else if (response.status === 401) {
      clearLoginData();
    } else {
      toast.error("Failed to save WFH form");
    }
  } catch (error) {
    console.error(error);
  }
};

export const getWfhFormData = async (wfhFormId) => {
  try {
    const response = await fetch("/api/wfh/get-wfh-form-data/" + wfhFormId, {
      method: "GET",
      headers: getAuthorizationHeaderWithContentType(),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return null;
  } catch (error) {
    console.error(error);
  }
};

export const getAllWfhFormData = async (wfhFormId) => {
  try {
    const response = await fetch(
      "/api/wfh/get-all-wfh-form-data/" + wfhFormId,
      {
        method: "GET",
        headers: getAuthorizationHeaderWithContentType(),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return null;
  } catch (error) {
    console.error(error);
  }
};

export const fetchWfhInviteUrl = async (wfhFormId) => {
  try {
    // const encodedSupplierName = encodeURIComponent(supplierName);
    const response = await fetch("/api/wfh/get-wfh-invite-url/" + wfhFormId, {
      method: "GET",
      headers: getAuthorizationHeaderWithContentType(),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};
