import React from "react";
import PageHeader from "../common/PageHeader";
import { useState, useEffect } from "react";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import app from "../../firebase";
import RecentLocations from "./RecentLocations";
import { getUserBusinessLocations } from "../new-overview/newOverviewAPI";
import { useAuth } from "../../Contexts/AuthContext";
import {
  getActiveUserData,
  getWhiteLabel,
  setActiveUserData,
} from "../../helpers/store";
import AllTransactionsTable from "../unifiedData/Transactions/AllTransactionsTable";

const fireStoreDB = getFirestore(app);

const Index = () => {
  const { currentUser } = useAuth();
  const [activeUser, setActiveUser] = useState(JSON.parse(getActiveUserData()));
  const [business, setBusiness] = useState(currentUser.businessId);
  const [status, setStatus] = useState(false);
  const [businessName, setBusinessName] = useState(currentUser.businessName);
  const [authProvider] = useState(currentUser.authProvider);
  const [organisationName, setOrganisationName] = useState("");
  const [lastUpdated, setLastUpdated] = useState("");
  const [showUnifiedData, setShowUnifiedData] = useState(false);
  // const subscribeURL = `https://apps.xero.com/${currentUser.organisationId}/au/subscribe/${process.env.REACT_APP_APP_ID}`;
  // const cancelSubURL = `https://apps.xero.com/${currentUser.organisationId}/my-apps/${process.env.REACT_APP_APP_ID}`;
  const bookDemoURL = `https://www.netnada.com.au/book-a-demo`;
  //fetch account details from firestore for business
  async function getOrganisationDetails(business) {
    const accSWToken = authProvider === "MYOB" ? "MyobTokens" : "XeroTokens";

    const accSWDocRef = doc(fireStoreDB, accSWToken, business);
    const accSWDocSnap = await getDoc(accSWDocRef);
    //To check if access token exists
    if (accSWDocSnap.exists()) {
      let data = accSWDocSnap.data();
      setOrganisationName(data["organisation"]["organisationName"]);

      const accSWLastUpdated =
        authProvider === "MYOB" ? "MYOBLastUpdated" : "XeroLastUpdated";
      const journals_docRef = doc(fireStoreDB, "Journals", business);
      const journals_docSnap = await getDoc(journals_docRef);

      //UPDATE LAST CONNECTED ONLY WHEN CONNECTED
      if (journals_docSnap.exists()) {
        let data = journals_docSnap.data();
        setLastUpdated(data[accSWLastUpdated]);
      }
    }
  }

  //check if subscribed from firestore
  async function getSubscriptionDetails(business) {
    //To check if Xero token exists
    const xero_docRef = doc(fireStoreDB, "XeroSub", business);
    const xero_docSnap = await getDoc(xero_docRef);

    if (xero_docSnap.exists()) {
      let data = xero_docSnap.data();
      //check if status is not defined
      if (data["status"] !== undefined) {
        let subStatus = data["status"];
        let subEndDate = data["endDate"];
        //set status to true only if the user has subscribed and have not opted for cancellation
        if (subStatus === "ACTIVE") {
          if (subEndDate === null) {
            setStatus(true);
          }
        }
      }
    }
  }

  //fetch list of offices by business ID
  async function getBusinessLocations() {
    const userOffices = await getUserBusinessLocations();
    let businessOffices = [];
    for (let i in userOffices)
      businessOffices.push(
        userOffices[i]["officename"] + "," + userOffices[i]["office_id"]
      );
    sessionStorage.setItem("businessOffices", JSON.stringify(businessOffices));

    //store all office names in session as string
    let officeName = [];
    for (let i in userOffices) officeName.push(userOffices[i]["officename"]);
    sessionStorage.setItem("officeName", JSON.stringify(officeName));
  }

  useEffect(() => {
    const unitedData = JSON.parse(
      sessionStorage.getItem("unifiedDataCalCompleted")
    );
    if (unitedData !== undefined) {
      setShowUnifiedData(unitedData);
    }
    getBusinessLocations();
    if (authProvider === "Xero" || authProvider === "MYOB") {
      getOrganisationDetails(business);
    }
    getSubscriptionDetails(business);
  }, [business]);

  const [selectedDates, setSelectedDates] = useState({
    startDate: new Date(activeUser.currentStartDate),
    endDate: new Date(activeUser.currentEndDate),
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates) => {
    const [start, end] = dates;
    setSelectedDates({
      startDate: start,
      endDate: end,
    });
    sessionStorage.setItem("dashboardDataCalCompleted", false);
  };

  useEffect(() => {
    if (selectedDates.startDate !== null && selectedDates.endDate !== null) {
      //update user data in session storage
      let userData = JSON.parse(getActiveUserData());
      userData.currentStartDate = selectedDates.startDate;
      userData.currentEndDate = selectedDates.endDate;
      setActiveUserData(JSON.stringify(userData));

      setStartDate(selectedDates.startDate);
      setEndDate(selectedDates.endDate);
    }
  }, [selectedDates]);

  return (
    <>
      <PageHeader
        title="Your Business Details"
        description={`View, add, and manage your business details for your selceted reporting period for better emissions calculations.`}
        className="mb-3"
        col={16}
      >
        <Button
          as={Link}
          to="/welcome/account-details"
          variant="outline-primary"
          className="rounded-pill me-0 mb-0"
          size="sm"
        >
          View Full Detials
        </Button>
        <br /> <br />
        {/* <RecentLocations /> */}
      </PageHeader>

      <PageHeader
        title="Account details"
        description={`See below details on your account and Xero integration. If you are yet to integrate with Xero no details will be shown.`}
        className="mb-3"
      >
        <div>
          <p>
            Business Name : <b>{businessName}</b>
          </p>
          <p>
            Organisation Name : <b>{organisationName}</b>
          </p>
          <p>
            Last Connected : <b>{lastUpdated}</b>
          </p>
        </div>
        <Row className="gx-3">
          <Col lg={6}></Col>
        </Row>
      </PageHeader>

      <PageHeader
        title="Take the next step in sustainability"
        description={`Unlock the power of ${
          getWhiteLabel().name
        } by upgrading your account. You can cancel at any time with no additional fees. Contact support@netnada.com.au if you have any questions regarding your subscription.`}
        className="mb-3"
      >
        <Row className="gx-3">
          <Col lg={6}>
            <button
              onClick={() => {
                window.open(bookDemoURL, "_blank");
              }}
              style={{ border: "none", padding: "0", background: "none" }}
            >
              UPGRADE TO PREMIUM
            </button>
          </Col>
        </Row>
      </PageHeader>

      {showUnifiedData ? (
        <>
          <PageHeader
            title="Transactions"
            description={`The table below displays the data imported from your accounting software. Here you can alter the emission category of each transaction to capture nuances that have not been identified by our software. There are 344 categories in our Software that a single transaction can be matched to. If you need any assistance in reviewing this data please get in touch with support@netnada.com.au or directly with your customer support person.`}
            className="mb-3"
          ></PageHeader>
          <AllTransactionsTable startDate={startDate} endDate={endDate} />{" "}
        </>
      ) : null}
    </>
  );
};

export default Index;
