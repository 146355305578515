import React, { forwardRef, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import WidgetSectionTitle from "../../../widgets/WidgetSectionTitle";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faList } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../Contexts/AuthContext";
import AdvanceTableWrapper from "../../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../../common/advance-table/AdvanceTableFooter";
import { Card } from "react-bootstrap";
import IconButton from "../../../components/common/IconButton";
import { faAdd, faRemove } from "@fortawesome/free-solid-svg-icons";
import { find } from "lodash";
import { getAllPaperData, savePaperDraft, submitPaperForm } from "./paperAPI";

import { toast, ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
const paperWeightPerSheetSize = require("./WeightPerSheetSize.json");
const packageSizeobject = require("./PackageSize.json");

const GreetingsDateInput = forwardRef(({ value, onClick }, ref) => (
  <div>
    <Form.Control
      disabled
      size="lg"
      ref={ref}
      onClick={onClick}
      value={value}
      className="ps-4"
      onChange={(e) => {
        console.log({ e });
      }}
    />
    <FontAwesomeIcon
      icon={faCalendarAlt}
      className="text-primary position-absolute top-50 translate-middle-y ms-2"
    />
  </div>
));

const PaperDataViewer = () => {
  const location = useLocation();

  //if session storage has no data, then set it to the data from the location state
  if (sessionStorage.paperData === undefined) {
    sessionStorage.setItem("paperData", JSON.stringify(location.state.row));
  }

  if (location.state === null) {
    location.state = { row: JSON.parse(sessionStorage.paperData) };
  }

  const [isLoading, setIsLoading] = useState(false);
  const [selectedDatePeriod, setSelectedDatePeriod] = useState({
    startDate: new Date(location.state.row.startdate) || null,
    endDate: new Date(location.state.row.enddate) || null,
  });
  const [showAddPaperDataModal, setShowAddPaperDataModal] = useState(false);
  const [enteredPaperData, setEnteredPaperData] = useState(
    location.state.row.data
  );
  const [addMorePaperData] = useState(
    location.state.row.completion_status === "InProgress" ? true : false
  );

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    await submitPaperForm(enteredPaperData);
    toast(`Paper data submitted successfully.`);
    setIsLoading(false);
    //to initiate chart data calculation
    sessionStorage.setItem("dashboardDataCalCompleted", false);
    window.location.href = "/welcome/data/climate-active/all-forms";
  }

  async function handleSaveDraft(e) {
    e.preventDefault();
    setIsLoading(true);
    await savePaperDraft(enteredPaperData);
    toast(`Paper data saved as draft successfully.`);
    setIsLoading(false);
    window.location.href = "/welcome/data/climate-active/all-forms";
  }

  const onDatePeriodChange = (dates) => {
    const [start, end] = dates;
    setSelectedDatePeriod({
      startDate: start,
      endDate: end,
    });
  };

  async function handleAddDraftClick() {
    setShowAddPaperDataModal(true);
  }

  async function handleAddDraftClose() {
    setShowAddPaperDataModal(false);
  }

  async function handleDeletePaperData(paperDataId) {
    setIsLoading(true);
    setEnteredPaperData((current) =>
      current.filter((item) => item.id !== paperDataId)
    );
    setIsLoading(false);
  }

  const columns = [
    {
      accessor: "id",
      Header: "",
      Cell: (rowData) => {
        return (
          <>
            {rowData.row.original.completion_status === "InProgress" ? (
              <IconButton
                variant="falcon-default"
                size="sm"
                icon={faRemove}
                transform="shrink-3"
                onClick={() => handleDeletePaperData(rowData.row.original.id)}
              ></IconButton>
            ) : null}
          </>
        );
      },
    },
    {
      accessor: "package_size",
      Header: "Package Size",
    },
    {
      accessor: "quantity",
      Header: "How many?",
    },
    {
      accessor: "gsqm",
      Header: "Grams per square meter(gsm)",
    },
    {
      accessor: "paper_size",
      Header: "Paper Size",
    },
    {
      accessor: "sheet_weight",
      Header: "Weight per Sheet(g)",
    },
    {
      accessor: "total_weight",
      Header: "Total Weight (kg)",
    },
  ];

  useEffect(() => {
    //update enteredPaperData
    if (selectedDatePeriod.startDate && selectedDatePeriod.endDate) {
      if (enteredPaperData.length !== 0) {
        for (let i in enteredPaperData) {
          enteredPaperData[i]["startDate"] =
            selectedDatePeriod.startDate.toDateString();
          enteredPaperData[i]["endDate"] =
            selectedDatePeriod.endDate.toDateString();
        }
      }
    }
  }, [selectedDatePeriod]);

  return (
    <>
      {" "}
      <WidgetSectionTitle
        icon={faList}
        title="Paper Form"
        subtitle="Fill your paper form here"
        transform="shrink-2"
        className="mb-4 mt-6"
      />
      {showAddPaperDataModal && (
        <AddPaperDataModal
          showAddPaperDataModal={showAddPaperDataModal}
          handleAddDraftClose={handleAddDraftClose}
          enteredPaperData={enteredPaperData}
          setEnteredPaperData={setEnteredPaperData}
        />
      )}
      <div className="mb-3">
        <AdvanceTableWrapper
          columns={columns}
          data={enteredPaperData}
          sortable
          pagination
          perPage={7}
          rowCount={enteredPaperData.length}
        >
          <Card>
            <Card.Header>
              <Row className="flex-between-center">
                <Col
                  xs={6}
                  sm="auto"
                  className="d-flex align-items-center pe-0"
                >
                  <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                    Paper data
                  </h5>
                </Col>
                <Col
                  xs={4}
                  sm="auto"
                  className="d-flex align-items-center pe-0"
                >
                  {isLoading ? (
                    <Spinner
                      animation="border"
                      role="status"
                      size="sm"
                      style={{ color: "#f5803e" }}
                    >
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col xs={4} sm="auto" className="ms-auto text-end ps-0">
                  {addMorePaperData ? (
                    <div id="orders-actions">
                      <IconButton
                        variant="falcon-default"
                        size="sm"
                        icon={faAdd}
                        style={{ marginRight: "5px" }}
                      >
                        <span
                          className="d-none d-sm-inline-block ms-1"
                          onClick={handleAddDraftClick}
                        >
                          Add Paper Data
                        </span>
                      </IconButton>
                    </div>
                  ) : null}
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="btn-reveal-trigger text-nowrap align-middle"
                tableProps={{
                  size: "lg",
                  className: "fs--1 mb-0 overflow-hidden",
                }}
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTableFooter
                rowCount={enteredPaperData.length}
                table
                rowInfo
                navButtons
              />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </div>
      <hr />
      <br />
      <Col md="auto" className="mb-3 mb-md-0">
        <Row className="g-3 gy-md-0 h-100 align-items-center">
          <Col xs="auto">
            <h6 className="text-700 mb-0 text-nowrap">Select Time Period:</h6>
          </Col>
          <Col md="auto">
            <DatePicker
              disabled={true}
              selected={selectedDatePeriod.startDate}
              onChange={onDatePeriodChange}
              selectsRange
              startDate={selectedDatePeriod.startDate}
              endDate={selectedDatePeriod.endDate}
              dateFormat="dd/MM/yyyy"
              customInput={<GreetingsDateInput />}
            />
          </Col>
        </Row>
      </Col>
      <br />
      {addMorePaperData ? (
        <Row style={{ alignItems: "center", justifyContent: "center" }}>
          <Col sm={2} style={{ padding: "5" }}>
            <Button
              onClick={handleSaveDraft}
              disabled={
                !selectedDatePeriod.startDate ||
                !selectedDatePeriod.endDate ||
                enteredPaperData.length === 0
              }
            >
              Save As Draft
            </Button>
          </Col>
          <Col sm={2} style={{ padding: "5" }}>
            <Button
              onClick={handleSubmit}
              disabled={
                !selectedDatePeriod.startDate ||
                !selectedDatePeriod.endDate ||
                enteredPaperData.length === 0
              }
            >
              Submit
            </Button>
          </Col>
        </Row>
      ) : null}
      <ToastContainer />
    </>
  );
};

const AddPaperDataModal = ({
  showAddPaperDataModal,
  handleAddDraftClose,
  enteredPaperData,
  setEnteredPaperData,
}) => {
  const { currentUser } = useAuth();
  const [packageSize, setPackageSize] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [gsqm, setGsqm] = useState("");
  const [paperSize, setPaperSize] = useState("");
  const [weightPerSheet, setWeightPerSheet] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  function calculateValues() {
    if (gsqm && paperSize) {
      const selectedPaperSize = paperWeightPerSheetSize[paperSize];
      const selectedGsqm = find(selectedPaperSize, function (item) {
        if (item.gramsPerSqm === parseInt(gsqm)) {
          return item;
        }
      });
      setWeightPerSheet(selectedGsqm.weightPerSheet);
    }

    if (weightPerSheet && quantity && packageSize) {
      let pkgSize = packageSizeobject[packageSize];
      setTotalWeight((weightPerSheet * parseInt(quantity) * pkgSize) / 1000);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    const request = {
      id: enteredPaperData[enteredPaperData.length - 1]?.id + 1 || 1,
      name: `${currentUser.businessName} Paper Form`,
      package_size: packageSize,
      quantity: quantity,
      gsqm: gsqm,
      paper_size: paperSize,
      sheet_weight: weightPerSheet,
      total_weight: totalWeight,
    };

    setEnteredPaperData((enteredPaperData) => [...enteredPaperData, request]);

    setIsLoading(false);
    handleAddDraftClose(true);
  }

  useEffect(() => {
    calculateValues();
  }, [packageSize, quantity, gsqm, paperSize, weightPerSheet]);

  return (
    <div>
      <Modal
        show={showAddPaperDataModal}
        onHide={handleAddDraftClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Paper Data
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Package size</Form.Label>
                  <Form.Select
                    aria-label="Default select"
                    value={packageSize}
                    onChange={(e) => setPackageSize(e.target.value)}
                  >
                    <option value="">Please select</option>
                    <option value="Sheet">Sheet</option>
                    <option value="Ream">Ream</option>
                    <option value="Box">Box</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>How many?</Form.Label>
                  <Form.Control
                    type="number"
                    name="quantity"
                    placeholder=""
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Grams per square metre(gsm)</Form.Label>
                  <Form.Select
                    aria-label="Default select"
                    value={gsqm}
                    onChange={(e) => setGsqm(e.target.value)}
                  >
                    <option value="">Please select</option>
                    <option value="75">75</option>
                    <option value="80">80</option>
                    <option value="90">90</option>
                    <option value="100">100</option>
                    <option value="110">110</option>
                    <option value="120">120</option>
                    <option value="160">160</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Paper Size</Form.Label>
                  <Form.Select
                    aria-label="Default select"
                    value={paperSize}
                    onChange={(e) => setPaperSize(e.target.value)}
                  >
                    <option value="">Please select</option>
                    <option value="4A0">4A0</option>
                    <option value="2A0">2A0</option>
                    <option value="A0">A0</option>
                    <option value="A1">A1</option>
                    <option value="A2">A2</option>
                    <option value="A3">A3</option>
                    <option value="A4">A4</option>
                    <option value="A5">A5</option>
                    <option value="A6">A6</option>
                    <option value="A7">A7</option>
                    <option value="A8">A8</option>
                    <option value="A9">A9</option>
                    <option value="A10">A10</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Weight per sheet (g)</Form.Label>
                  <Form.Control
                    type="text"
                    name="weightPerSheet"
                    value={weightPerSheet}
                    placeholder="-"
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Total Weight (kg)</Form.Label>
                  <Form.Control
                    type="text"
                    name="totalWeight"
                    value={totalWeight}
                    placeholder="-"
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            {isLoading ? (
              <Spinner
                animation="border"
                role="status"
                size="sm"
                style={{ color: "#f5803e" }}
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <></>
            )}
            <Button
              type="submit"
              disabled={
                !packageSize || !quantity || !gsqm || !paperSize || isLoading
              }
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default PaperDataViewer;
