import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ResponsivePie } from "@nivo/pie";
import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import { faAdd, faCircle } from "@fortawesome/free-solid-svg-icons";
import UploadDataModal from "./UploadDataModal";
import { useNavigate } from "react-router-dom";

function PieChart({
  pieData,
  colorTheme,
  onClick = () => {},
  title,
  units = "tonnes CO₂e",
  titleSize,
  height = 400,
  margin = { top: 40, right: 80, bottom: 80, left: 80 },
  showLabels = true,
  urlAddData,
}) {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const legends = [
    {
      anchor: "bottom",
      direction: "row",
      justify: false,
      translateX: 0,
      translateY: 26,
      itemsSpacing: 0,
      itemWidth: 80,
      itemHeight: 18,
      itemTextColor: "#999",
      itemDirection: "left-to-right",
      itemOpacity: 1,
      symbolSize: 13,
      symbolShape: "circle",
      effects: [
        {
          on: "hover",
          style: {
            itemTextColor: "#000",
          },
        },
      ],
    },
  ];

  const handleModal = () => {
    if (!urlAddData) {
      setShowModal(true);
    } else {
      navigate(urlAddData);
    }
  };
  return (
    <>
      <div style={{ height: `${height}px` }}>
        {titleSize ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h6>{title}</h6>
          </div>
        ) : (
          <h5>{title}</h5>
        )}
        {pieData && pieData.length > 0 ? (
          <ResponsivePie
            data={pieData}
            margin={{
              top: margin.top,
              right: margin.right,
              bottom: margin.bottom,
              left: margin.left,
            }}
            startAngle={-30}
            sortByValue={true}
            innerRadius={0.5}
            padAngle={2}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            colors={!colorTheme ? ({ data }) => data.color : colorTheme}
            borderWidth={3}
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.5]],
            }}
            enableArcLinkLabels={showLabels}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextOffset={5}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsDiagonalLength={3}
            arcLinkLabelsStraightLength={9}
            arcLinkLabelsThickness={2}
            arcLinkLabelsOffset={4}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={12}
            arcLabelsTextColor={{
              from: "color",
              modifiers: [["darker", 2]],
            }}
            arcLabelsRadiusOffset={0.55}
            onClick={(node) => onClick(node.id, node.data.idColor)}
            transitionMode="startAngle"
            legends={showLabels ? [] : legends}
            tooltip={(node) => {
              return (
                <Card style={{ opacity: 0.8 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      padding: 3,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: 2,
                        alignItems: "center",
                        paddingLeft: 8,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCircle}
                        className="me-2"
                        style={{ color: node.datum.color }}
                      />
                      <div>{node.datum.label}:</div>
                      <div style={{ paddingRight: 8 }}>
                        <strong>{node.datum.value}</strong> {units}
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: "0.7em",
                        color: "#666",
                        textAlign: "center",
                      }}
                    >
                      Click to update Bar Chart
                    </div>
                  </div>
                </Card>
              );
            }}
          />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <div>
              <p>No data available</p>
            </div>
            <div>
              {" "}
              <Button size="sm" onClick={() => handleModal()}>
                <FontAwesomeIcon icon={faAdd} /> Add Data
              </Button>
            </div>
          </div>
        )}
      </div>
      <UploadDataModal
        showSpendDataModal={showModal}
        toggleSpendDataModal={() => setShowModal(false)}
      />
    </>
  );
}

export default PieChart;
