import React, { useEffect, useState, useRef } from "react";
import { Card, Button, Badge, Row, Col } from "react-bootstrap";
import xeroLogo from "../../Assets/img/icons/xero.png";
import myobLogo from "../../Assets/img/icons/myob.png";
import googleDriveLogo from "../../Assets/img/icons/googleDrive.png"; // Import Google Drive logo
import PageHeader from "../common/PageHeader";
import SoftBadge from "../common/SoftBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faEnvelope, faClock } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import { getActiveUserData } from "../../helpers/store";
import app from "../../firebase";
import { doc, getDoc, setDoc, getFirestore } from "firebase/firestore";
import { updateBusinessProcStatusAuthProvider } from "../new-overview/newOverviewAPI";
import { useAuth } from "../../Contexts/AuthContext"; // Import useAuth to get currentUser

const fireStoreDB = getFirestore(app);

const IntegrationCard = ({
  logo,
  title,
  description,
  learnMore,
  enabled,
  link,
  missingPermission,
  lastUpdated,
  reconnectionAllowed,
  handleClick, // Pass handleClick as a prop
  connectedEmail, // Add connectedEmail prop
}) => {
  // Determine the button label based on the title
  const getReconnectionLabel = () => {
    if (title === "Google Drive") {
      return "Connect a different account";
    } else {
      return "Reconnect and update data";
    }
  };

  return (
    <Card style={{ width: "25rem", margin: "10px" }}>
      <Card.Header className="pb-4">
        <div className="d-flex align-items-center gap-3">
          <img
            src={logo.object}
            alt={logo.alt}
            style={{ width: logo.width, height: logo.height }}
            className="object-contain"
          />
          <div>
            <Card.Title className="text-xl">{title}</Card.Title>

            <SoftBadge
              bg={enabled ? "success" : "secondary"}
              className="me-1 py-2"
              key="connected"
            >
              {/* Connected */}
              {enabled ? "Connected" : "Not Connected"}
            </SoftBadge>
          </div>
        </div>
      </Card.Header>

      <Card.Body>
        <Card.Text>
          {description}{" "}
          {learnMore && (
            <a href={learnMore.url} target="_blank" rel="noopener noreferrer">
              {learnMore.label} <FontAwesomeIcon icon={faExternalLinkAlt} />
            </a>
          )}
        </Card.Text>

        {enabled && (
          <>
            {connectedEmail && (
              <Row>
                <Col>
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    style={{ marginRight: "10px" }}
                  />
                  {connectedEmail}
                </Col>
              </Row>
            )}
          </>
        )}
        {lastUpdated && (
          <Row>
            <Col>
              <FontAwesomeIcon icon={faClock} style={{ marginRight: "10px" }} />
              {lastUpdated}
            </Col>
          </Row>
        )}
      </Card.Body>
      <Card.Footer>
        <Row>
          {!enabled && link && (
            <>
              <Col></Col>
              <Col>
                <Button variant="link" onClick={() => handleClick(title)}>
                  {link.label}
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className="ms-1 fs--2"
                  />
                </Button>
              </Col>
            </>
          )}
          {reconnectionAllowed && (
            <Col style={{ display: "flex", justifyContent: "right" }}>
              <Button variant="link" onClick={() => handleClick(title)}>
                {getReconnectionLabel()}
                <FontAwesomeIcon icon={faChevronRight} className="ms-1 fs--2" />
              </Button>
            </Col>
          )}

          {missingPermission && (
            <div className="mt-2">
              <Badge variant="danger">Required permissions missing</Badge>
            </div>
          )}
        </Row>
      </Card.Footer>
    </Card>
  );
};

const Index = () => {
  const [data, setData] = useState([
    {
      title: "Xero",
      description: "Pulls a summary of transactions from Xero.",
      enabled: false,
      link: { url: "/xero-sign-in", label: "Connect", isExternal: false },
      lastUpdated: "",
      reconnectionAllowed: false,
      logo: { object: xeroLogo, alt: "Xero", width: "50px", height: "50px" },
      missingPermission: false,
      learnMore: {
        url: "https://www.netnada.com.au/getting-started-with-xero",
        label: "Learn more",
      },
    },
    {
      title: "MYOB",
      description: "Pulls a summary of transactions from MYOB.",
      enabled: false,
      link: { url: "/myob-sign-in", label: "Connect", isExternal: false },
      lastUpdated: "",
      reconnectionAllowed: false,
      logo: { object: myobLogo, alt: "MYOB", width: "75px", height: "50px" },
      missingPermission: false,
      learnMore: {
        url: "https://www.netnada.com.au/getting-started-with-myob",
        label: "Learn more",
      },
    },
    {
      title: "Google Drive", // Added Google Drive integration
      description: "Allows you to upload files from your Google Drive.",
      enabled: false,
      link: {
        url: "#",
        label: "Connect",
        isExternal: true, // Indicate that this link triggers a function
      },
      lastUpdated: "",
      reconnectionAllowed: false,
      logo: {
        object: googleDriveLogo,
        alt: "Google Drive",
        width: "50px",
        height: "50px",
      },
      missingPermission: false,
      connectedEmail: "", // Add connectedEmail field
      learnMore: {
        url: "https://www.netnada.com.au/getting-started-with-google-drive",
        label: "Learn more",
      },
    },
  ]);

  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const { currentUser } = useAuth(); // Get currentUser from useAuth
  const userId = currentUser?.uid;

  // Google Authentication State
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const tokenClientRef = useRef(null);

  // Load Google API scripts
  useEffect(() => {
    if (userId) {
      // Load the GIS script
      const gisScript = document.createElement("script");
      gisScript.src = "https://accounts.google.com/gsi/client";
      gisScript.async = true;
      gisScript.defer = true;
      gisScript.onload = initializeGisClient;
      document.body.appendChild(gisScript);
    }
  }, [userId]);

  // Initialize Google Identity Services
  async function initializeGisClient() {
    const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const SCOPES =
      "https://www.googleapis.com/auth/drive.readonly email profile";

    tokenClientRef.current = window.google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPES,
      callback: (tokenResponse) => {
        handleTokenResponse(tokenResponse);
      },
    });

    // Check if token exists in Firestore
    const tokenDoc = await getDoc(doc(fireStoreDB, "GoogleTokens", userId));
    if (tokenDoc.exists()) {
      const tokenData = tokenDoc.data().token;
      const expiresAt = tokenData.expires_at;
      const email = tokenDoc.data().email || "";

      if (expiresAt && Date.now() < expiresAt) {
        // Token is valid
        setAccessToken(tokenData.access_token);
        setIsSignedIn(true);
        updateGoogleDriveCard(true, expiresAt, email);
      } else {
        // Attempt silent refresh
        tokenClientRef.current.requestAccessToken({
          prompt: "none",
        });
      }
    }
  }

  // Handle token response
  async function handleTokenResponse(tokenResponse) {
    if (tokenResponse && tokenResponse.access_token) {
      setAccessToken(tokenResponse.access_token);
      setIsSignedIn(true);
      console.log("Access token obtained:", tokenResponse.access_token);

      const expiresIn = tokenResponse.expires_in
        ? parseInt(tokenResponse.expires_in)
        : 3600; // default to 3600 seconds
      const expiresAt = Date.now() + expiresIn * 1000;

      // Prepare the token data to store
      const tokenData = {
        access_token: tokenResponse.access_token,
        expires_at: expiresAt,
        token_type: tokenResponse.token_type,
        scope: tokenResponse.scope,
      };

      // Fetch user's email
      let email = "";
      try {
        const response = await fetch(
          "https://www.googleapis.com/oauth2/v1/userinfo?alt=json",
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
            },
          }
        );
        const data = await response.json();
        email = data.email;
      } catch (error) {
        console.error("Error fetching user info:", error);
      }

      // Store the token data and email in Firestore
      try {
        await setDoc(
          doc(fireStoreDB, "GoogleTokens", userId),
          {
            token: tokenData,
            email: email,
          },
          { merge: true }
        );
        console.log("Token data stored in Firestore");
        updateGoogleDriveCard(true, expiresAt, email);
      } catch (error) {
        console.error("Error storing token data in Firestore:", error);
      }
    } else {
      console.error("Error obtaining access token:", tokenResponse);
    }
  }

  // Update Google Drive card status
  function updateGoogleDriveCard(enabled, expiresAt, email) {
    setData((prevData) =>
      prevData.map((item) => {
        if (item.title === "Google Drive") {
          return {
            ...item,
            enabled: enabled,
            lastUpdated: enabled ? formatDate(expiresAt) : "",
            reconnectionAllowed: enabled,
            connectedEmail: email || "", // Set the connected email
          };
        }
        return item;
      })
    );
  }

  function formatDate(timestamp) {
    var d = new Date(timestamp),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = "" + d.getFullYear();

    return [day, month, year].join(".");
  }

  useEffect(() => {
    const authProvider = activeUser?.authProvider;
    const processingStatus = activeUser?.processingStatus;

    // Update Xero and MYOB cards based on user's data
    getLastUpdatedDate(activeUser?.businessId, authProvider).then(
      (lastUpdated) => {
        if (
          authProvider === "Xero" &&
          (processingStatus === "Completed" ||
            processingStatus === "InProgress")
        ) {
          setData((prevData) => {
            return prevData.map((item) => {
              if (item.title === "Xero") {
                if (processingStatus === "InProgress") {
                  return {
                    ...item,
                    enabled: true,
                    lastUpdated: lastUpdated,
                    reconnectionAllowed: false,
                  };
                } else {
                  if (processingStatus === "Completed") {
                    return {
                      ...item,
                      enabled: true,
                      lastUpdated: lastUpdated,
                      reconnectionAllowed: true,
                    };
                  } else {
                    return {
                      ...item,
                      enabled: false,
                      lastUpdated: lastUpdated,
                      reconnectionAllowed: false,
                    };
                  }
                }
              }
              return item;
            });
          });
        }

        if (
          authProvider === "MYOB" &&
          (processingStatus === "Completed" ||
            processingStatus === "InProgress")
        ) {
          setData((prevData) => {
            return prevData.map((item) => {
              if (item.title === "MYOB") {
                if (processingStatus === "InProgress") {
                  return {
                    ...item,
                    enabled: true,
                    lastUpdated: lastUpdated,
                    reconnectionAllowed: false,
                  };
                } else {
                  return {
                    ...item,
                    enabled: true,
                    lastUpdated: lastUpdated,
                    reconnectionAllowed: true,
                  };
                }
              }
              return item;
            });
          });
        }
      }
    );
  }, [activeUser]);

  async function getLastUpdatedDate(businessId, authProvider) {
    // Reference to the document containing last updated info
    const docRef = doc(fireStoreDB, "Journals", businessId);
    // Fetch the document
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      let data = docSnap.data();

      let lastUpdated = data[`${authProvider}LastUpdated`] || "";

      if (lastUpdated) {
        // Convert timestamp to date as 14.04.2021
        lastUpdated = formatDate(lastUpdated);
      }
      return lastUpdated;
    }
  }

  async function handleClick(title) {
    if (title === "Google Drive") {
      // Handle Google Drive connection
      if (tokenClientRef.current) {
        tokenClientRef.current.requestAccessToken({ prompt: "" });
      } else {
        console.error("Token client not initialized.");
      }
    } else {
      // Handle other integrations (Xero, MYOB)
      const url = title === "Xero" ? "/xero-sign-in" : "/myob-sign-in";
      const businessId = activeUser?.businessId;
      const authProvider = title;
      const processingStatus = null;
      await updateBusinessProcStatusAuthProvider({
        businessId,
        processingStatus,
        authProvider,
      });
      window.location = url;
    }
  }

  return (
    <>
      <PageHeader
        title="Integrations"
        description="Review your integrations and enable new connections to streamline data collection for your sustainability analysis."
        link={{
          to: "https://calendly.com/netnada/customer-support",
          label: "Missing an integration? Let us know",
        }}
      ></PageHeader>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {data.map((cardData, index) => (
          <IntegrationCard
            key={index}
            {...cardData}
            handleClick={handleClick} // Pass handleClick to IntegrationCard
          />
        ))}
      </div>
    </>
  );
};

export default Index;
