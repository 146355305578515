import React, { forwardRef, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Spinner,
  Card,
  ListGroup,
  ListGroupItem,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import WidgetSectionTitle from "../../../widgets/WidgetSectionTitle";
import { useAuth } from "../../../Contexts/AuthContext";
import { find } from "lodash";
import { saveStaffData, getStaffDataById } from "./staffAPI";
import { faCalendarAlt, faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast, ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import {
  fetchDashboardDataFromFirebaseByBusinessId,
  removeUnifiedDateFromStore,
} from "../../../helpers/unifiedDataHelper";

import { getActiveUserData, isDataFrozen } from "../../../helpers/store";
import FrozenDataModal from "../../utilities/FrozenDataModal";

const staffTransportMode = require("./staffTransportMode.json");
const staffAverageCommutingDistance = require("./staffAverageCommutingDistance.json");

const StaffForm = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [staffDataId, setStaffDataId] = useState(0);
  const [daysPerYear, setDaysPerYear] = useState(0);

  const [sydneyStaffCount, setSydneyStaffCount] = useState(0);
  const [melbourneStaffCount, setMelbourneStaffCount] = useState(0);
  const [brisbaneStaffCount, setBrisbaneStaffCount] = useState(0);
  const [adelaideStaffCount, setAdelaideStaffCount] = useState(0);
  const [perthStaffCount, setPerthStaffCount] = useState(0);
  const [hobartStaffCount, setHobartStaffCount] = useState(0);
  const [darwinStaffCount, setDarwinStaffCount] = useState(0);
  const [canberraStaffCount, setCanberraStaffCount] = useState(0);
  const [restOfNSWStaffCount, setRestOfNSWStaffCount] = useState(0);
  const [restOfVICStaffCount, setRestOfVICStaffCount] = useState(0);
  const [restOfQLDStaffCount, setRestOfQLDStaffCount] = useState(0);
  const [restOfSAStaffCount, setRestOfSAStaffCount] = useState(0);
  const [restOfWAStaffCount, setRestOfWAStaffCount] = useState(0);
  const [restOfTASStaffCount, setRestOfTASStaffCount] = useState(0);
  const [restOfNTStaffCount, setRestOfNTStaffCount] = useState(0);

  const [totalBicycleKM, setTotalBicycleKM] = useState(0);
  const [totalBusKM, setTotalBusKM] = useState(0);
  const [totalCarKM, setTotalCarKM] = useState(0);
  const [totalWalkingKM, setTotalWalkingKM] = useState(0);
  const [totalFerryKM, setTotalFerryKM] = useState(0);
  const [totalMotorbikeScooterKM, setTotalMotorbikeScooterKM] = useState(0);
  const [totalTaxiKM, setTotalTaxiKM] = useState(0);
  const [totalTrainKM, setTotalTrainKM] = useState(0);
  const [totalTramKM, setTotalTramKM] = useState(0);
  const [showFrozenDataModal, setShowFrozenDataModal] = useState(false);

  const [selectedDatePeriods, setSelectedDatePeriods] = useState({
    startDate: null,
    endDate: null,
  });

  function onDaysPerYearChange(daysPerYearValue) {
    setDaysPerYear(daysPerYearValue);
  }

  function onSydneyStaffCountChange(staffCountValue) {
    setSydneyStaffCount(staffCountValue);
  }

  function onMelbourneStaffCountChange(staffCountValue) {
    setMelbourneStaffCount(staffCountValue);
  }

  function onBrisbaneStaffCountChange(staffCountValue) {
    setBrisbaneStaffCount(staffCountValue);
  }

  function onAdelaideStaffCountChange(staffCountValue) {
    setAdelaideStaffCount(staffCountValue);
  }

  function onPerthStaffCountChange(staffCountValue) {
    setPerthStaffCount(staffCountValue);
  }

  function onHobartStaffCountChange(staffCountValue) {
    setHobartStaffCount(staffCountValue);
  }

  function onDarwinStaffCountChange(staffCountValue) {
    setDarwinStaffCount(staffCountValue);
  }

  function onCanberraStaffCountChange(staffCountValue) {
    setCanberraStaffCount(staffCountValue);
  }

  function onRestOfNSWStaffCountChange(staffCountValue) {
    setRestOfNSWStaffCount(staffCountValue);
  }

  function onRestOfVICStaffCountChange(staffCountValue) {
    setRestOfVICStaffCount(staffCountValue);
  }

  function onRestOfQLDStaffCountChange(staffCountValue) {
    setRestOfQLDStaffCount(staffCountValue);
  }

  function onRestOfSAStaffCountChange(staffCountValue) {
    setRestOfSAStaffCount(staffCountValue);
  }

  function onRestOfWAStaffCountChange(staffCountValue) {
    setRestOfWAStaffCount(staffCountValue);
  }
  function onRestOfTASStaffCountChange(staffCountValue) {
    setRestOfTASStaffCount(staffCountValue);
  }

  function onRestOfNTStaffCountChange(staffCountValue) {
    setRestOfNTStaffCount(staffCountValue);
  }

  function calculateTotalKM() {
    //Bicycle
    calculateTotalBicycleKM();
    //Bus
    calculateTotalBusKM();
    //Car
    calculateTotalCarKM();
    //Walking
    calculateTotalWalkingKM();
    //Ferry
    calculateTotalFerryKM();
    //Motorbike/Scooter
    calculateTotalMotorbikeKM();
    //Taxi
    calculateTotalTaxiKM();
    //Train
    calculateTotalTrainKM();
    //Tram
    calculateTotalTramKM();
  }

  function calculateTotalBicycleKM() {
    let totalBicycleKMValue = 0;
    let bicycleKMValue = calculateIndividualKM(
      sydneyStaffCount,
      "Sydney",
      "Bicycle"
    );
    totalBicycleKMValue = totalBicycleKMValue + bicycleKMValue;
    bicycleKMValue = calculateIndividualKM(
      melbourneStaffCount,
      "Melbourne",
      "Bicycle"
    );
    totalBicycleKMValue = totalBicycleKMValue + bicycleKMValue;
    bicycleKMValue = calculateIndividualKM(
      brisbaneStaffCount,
      "Brisbane",
      "Bicycle"
    );
    totalBicycleKMValue = totalBicycleKMValue + bicycleKMValue;
    bicycleKMValue = calculateIndividualKM(
      adelaideStaffCount,
      "Adelaide",
      "Bicycle"
    );
    totalBicycleKMValue = totalBicycleKMValue + bicycleKMValue;
    bicycleKMValue = calculateIndividualKM(perthStaffCount, "Perth", "Bicycle");
    totalBicycleKMValue = totalBicycleKMValue + bicycleKMValue;
    bicycleKMValue = calculateIndividualKM(
      hobartStaffCount,
      "Hobart",
      "Bicycle"
    );
    totalBicycleKMValue = totalBicycleKMValue + bicycleKMValue;
    bicycleKMValue = calculateIndividualKM(
      darwinStaffCount,
      "Darwin",
      "Bicycle"
    );
    totalBicycleKMValue = totalBicycleKMValue + bicycleKMValue;
    bicycleKMValue = calculateIndividualKM(
      canberraStaffCount,
      "Canberra",
      "Bicycle"
    );
    totalBicycleKMValue = totalBicycleKMValue + bicycleKMValue;
    bicycleKMValue = calculateIndividualKM(
      restOfNSWStaffCount,
      "Rest of NSW",
      "Bicycle"
    );
    totalBicycleKMValue = totalBicycleKMValue + bicycleKMValue;
    bicycleKMValue = calculateIndividualKM(
      restOfVICStaffCount,
      "Rest of VIC",
      "Bicycle"
    );
    totalBicycleKMValue = totalBicycleKMValue + bicycleKMValue;
    bicycleKMValue = calculateIndividualKM(
      restOfQLDStaffCount,
      "Rest of QLD",
      "Bicycle"
    );
    totalBicycleKMValue = totalBicycleKMValue + bicycleKMValue;
    bicycleKMValue = calculateIndividualKM(
      restOfSAStaffCount,
      "Rest of SA",
      "Bicycle"
    );
    totalBicycleKMValue = totalBicycleKMValue + bicycleKMValue;
    bicycleKMValue = calculateIndividualKM(
      restOfWAStaffCount,
      "Rest of WA",
      "Bicycle"
    );
    totalBicycleKMValue = totalBicycleKMValue + bicycleKMValue;
    bicycleKMValue = calculateIndividualKM(
      restOfTASStaffCount,
      "Rest of TAS",
      "Bicycle"
    );
    totalBicycleKMValue = totalBicycleKMValue + bicycleKMValue;
    bicycleKMValue = calculateIndividualKM(
      restOfNTStaffCount,
      "Rest of NT",
      "Bicycle"
    );
    totalBicycleKMValue = totalBicycleKMValue + bicycleKMValue;
    setTotalBicycleKM(totalBicycleKMValue);
  }

  function calculateTotalBusKM() {
    let totalBusKMValue = 0;
    let busKMValue = calculateIndividualKM(sydneyStaffCount, "Sydney", "Bus");
    totalBusKMValue = totalBusKMValue + busKMValue;
    busKMValue = calculateIndividualKM(melbourneStaffCount, "Melbourne", "Bus");
    totalBusKMValue = totalBusKMValue + busKMValue;
    busKMValue = calculateIndividualKM(brisbaneStaffCount, "Brisbane", "Bus");
    totalBusKMValue = totalBusKMValue + busKMValue;
    busKMValue = calculateIndividualKM(adelaideStaffCount, "Adelaide", "Bus");
    totalBusKMValue = totalBusKMValue + busKMValue;
    busKMValue = calculateIndividualKM(perthStaffCount, "Perth", "Bus");
    totalBusKMValue = totalBusKMValue + busKMValue;
    busKMValue = calculateIndividualKM(hobartStaffCount, "Hobart", "Bus");
    totalBusKMValue = totalBusKMValue + busKMValue;
    busKMValue = calculateIndividualKM(darwinStaffCount, "Darwin", "Bus");
    totalBusKMValue = totalBusKMValue + busKMValue;
    busKMValue = calculateIndividualKM(canberraStaffCount, "Canberra", "Bus");
    totalBusKMValue = totalBusKMValue + busKMValue;
    busKMValue = calculateIndividualKM(
      restOfNSWStaffCount,
      "Rest of NSW",
      "Bus"
    );
    totalBusKMValue = totalBusKMValue + busKMValue;
    busKMValue = calculateIndividualKM(
      restOfVICStaffCount,
      "Rest of VIC",
      "Bus"
    );
    totalBusKMValue = totalBusKMValue + busKMValue;
    busKMValue = calculateIndividualKM(
      restOfQLDStaffCount,
      "Rest of QLD",
      "Bus"
    );
    totalBusKMValue = totalBusKMValue + busKMValue;
    busKMValue = calculateIndividualKM(restOfSAStaffCount, "Rest of SA", "Bus");
    totalBusKMValue = totalBusKMValue + busKMValue;
    busKMValue = calculateIndividualKM(restOfWAStaffCount, "Rest of WA", "Bus");
    totalBusKMValue = totalBusKMValue + busKMValue;
    busKMValue = calculateIndividualKM(
      restOfTASStaffCount,
      "Rest of TAS",
      "Bus"
    );
    totalBusKMValue = totalBusKMValue + busKMValue;
    busKMValue = calculateIndividualKM(restOfNTStaffCount, "Rest of NT", "Bus");
    totalBusKMValue = totalBusKMValue + busKMValue;
    setTotalBusKM(totalBusKMValue);
  }

  function calculateTotalCarKM() {
    //Car as driver
    let totalCarKMValue = 0;
    let carKMValue = calculateIndividualKM(
      sydneyStaffCount,
      "Sydney",
      "Car as driver"
    );
    totalCarKMValue = totalCarKMValue + carKMValue;
    carKMValue = calculateIndividualKM(
      melbourneStaffCount,
      "Melbourne",
      "Car as driver"
    );
    totalCarKMValue = totalCarKMValue + carKMValue;
    carKMValue = calculateIndividualKM(
      brisbaneStaffCount,
      "Brisbane",
      "Car as driver"
    );
    totalCarKMValue = totalCarKMValue + carKMValue;
    carKMValue = calculateIndividualKM(
      adelaideStaffCount,
      "Adelaide",
      "Car as driver"
    );
    totalCarKMValue = totalCarKMValue + carKMValue;
    carKMValue = calculateIndividualKM(
      perthStaffCount,
      "Perth",
      "Car as driver"
    );
    totalCarKMValue = totalCarKMValue + carKMValue;
    carKMValue = calculateIndividualKM(
      hobartStaffCount,
      "Hobart",
      "Car as driver"
    );
    totalCarKMValue = totalCarKMValue + carKMValue;
    carKMValue = calculateIndividualKM(
      darwinStaffCount,
      "Darwin",
      "Car as driver"
    );
    totalCarKMValue = totalCarKMValue + carKMValue;
    carKMValue = calculateIndividualKM(
      canberraStaffCount,
      "Canberra",
      "Car as driver"
    );
    totalCarKMValue = totalCarKMValue + carKMValue;
    carKMValue = calculateIndividualKM(
      restOfNSWStaffCount,
      "Rest of NSW",
      "Car as driver"
    );
    totalCarKMValue = totalCarKMValue + carKMValue;
    carKMValue = calculateIndividualKM(
      restOfVICStaffCount,
      "Rest of VIC",
      "Car as driver"
    );
    totalCarKMValue = totalCarKMValue + carKMValue;
    carKMValue = calculateIndividualKM(
      restOfQLDStaffCount,
      "Rest of QLD",
      "Car as driver"
    );
    totalCarKMValue = totalCarKMValue + carKMValue;
    carKMValue = calculateIndividualKM(
      restOfSAStaffCount,
      "Rest of SA",
      "Car as driver"
    );
    totalCarKMValue = totalCarKMValue + carKMValue;
    carKMValue = calculateIndividualKM(
      restOfWAStaffCount,
      "Rest of WA",
      "Car as driver"
    );
    totalCarKMValue = totalCarKMValue + carKMValue;
    carKMValue = calculateIndividualKM(
      restOfTASStaffCount,
      "Rest of TAS",
      "Car as driver"
    );
    totalCarKMValue = totalCarKMValue + carKMValue;
    carKMValue = calculateIndividualKM(
      restOfNTStaffCount,
      "Rest of NT",
      "Car as driver"
    );
    totalCarKMValue = totalCarKMValue + carKMValue;

    ///Car as passenger
    carKMValue = calculateIndividualKM(
      sydneyStaffCount,
      "Sydney",
      "Car as passenger"
    );
    totalCarKMValue = totalCarKMValue + carKMValue;
    carKMValue = calculateIndividualKM(
      melbourneStaffCount,
      "Melbourne",
      "Car as passenger"
    );
    totalCarKMValue = totalCarKMValue + carKMValue;
    carKMValue = calculateIndividualKM(
      brisbaneStaffCount,
      "Brisbane",
      "Car as passenger"
    );
    totalCarKMValue = totalCarKMValue + carKMValue;
    carKMValue = calculateIndividualKM(
      adelaideStaffCount,
      "Adelaide",
      "Car as passenger"
    );
    totalCarKMValue = totalCarKMValue + carKMValue;
    carKMValue = calculateIndividualKM(
      perthStaffCount,
      "Perth",
      "Car as passenger"
    );
    totalCarKMValue = totalCarKMValue + carKMValue;
    carKMValue = calculateIndividualKM(
      hobartStaffCount,
      "Hobart",
      "Car as passenger"
    );
    totalCarKMValue = totalCarKMValue + carKMValue;
    carKMValue = calculateIndividualKM(
      darwinStaffCount,
      "Darwin",
      "Car as passenger"
    );
    totalCarKMValue = totalCarKMValue + carKMValue;
    carKMValue = calculateIndividualKM(
      canberraStaffCount,
      "Canberra",
      "Car as passenger"
    );
    totalCarKMValue = totalCarKMValue + carKMValue;
    carKMValue = calculateIndividualKM(
      restOfNSWStaffCount,
      "Rest of NSW",
      "Car as passenger"
    );
    totalCarKMValue = totalCarKMValue + carKMValue;
    carKMValue = calculateIndividualKM(
      restOfVICStaffCount,
      "Rest of VIC",
      "Car as passenger"
    );
    totalCarKMValue = totalCarKMValue + carKMValue;
    carKMValue = calculateIndividualKM(
      restOfQLDStaffCount,
      "Rest of QLD",
      "Car as passenger"
    );
    totalCarKMValue = totalCarKMValue + carKMValue;
    carKMValue = calculateIndividualKM(
      restOfSAStaffCount,
      "Rest of SA",
      "Car as passenger"
    );
    totalCarKMValue = totalCarKMValue + carKMValue;
    carKMValue = calculateIndividualKM(
      restOfWAStaffCount,
      "Rest of WA",
      "Car as passenger"
    );
    totalCarKMValue = totalCarKMValue + carKMValue;
    carKMValue = calculateIndividualKM(
      restOfTASStaffCount,
      "Rest of TAS",
      "Car as passenger"
    );
    totalCarKMValue = totalCarKMValue + carKMValue;
    carKMValue = calculateIndividualKM(
      restOfNTStaffCount,
      "Rest of NT",
      "Car as passenger"
    );
    totalCarKMValue = totalCarKMValue + carKMValue;
    setTotalCarKM(totalCarKMValue);
  }

  function calculateTotalWalkingKM() {
    let totalWalkingKMValue = 0;
    let walkingKMValue = calculateIndividualKM(
      sydneyStaffCount,
      "Sydney",
      "Walked only"
    );
    totalWalkingKMValue = totalWalkingKMValue + walkingKMValue;
    walkingKMValue = calculateIndividualKM(
      melbourneStaffCount,
      "Melbourne",
      "Walked only"
    );
    totalWalkingKMValue = totalWalkingKMValue + walkingKMValue;
    walkingKMValue = calculateIndividualKM(
      brisbaneStaffCount,
      "Brisbane",
      "Walked only"
    );
    totalWalkingKMValue = totalWalkingKMValue + walkingKMValue;
    walkingKMValue = calculateIndividualKM(
      adelaideStaffCount,
      "Adelaide",
      "Walked only"
    );
    totalWalkingKMValue = totalWalkingKMValue + walkingKMValue;
    walkingKMValue = calculateIndividualKM(
      perthStaffCount,
      "Perth",
      "Walked only"
    );
    totalWalkingKMValue = totalWalkingKMValue + walkingKMValue;
    walkingKMValue = calculateIndividualKM(
      hobartStaffCount,
      "Hobart",
      "Walked only"
    );
    totalWalkingKMValue = totalWalkingKMValue + walkingKMValue;
    walkingKMValue = calculateIndividualKM(
      darwinStaffCount,
      "Darwin",
      "Walked only"
    );
    totalWalkingKMValue = totalWalkingKMValue + walkingKMValue;
    walkingKMValue = calculateIndividualKM(
      canberraStaffCount,
      "Canberra",
      "Walked only"
    );
    totalWalkingKMValue = totalWalkingKMValue + walkingKMValue;
    walkingKMValue = calculateIndividualKM(
      restOfNSWStaffCount,
      "Rest of NSW",
      "Walked only"
    );
    totalWalkingKMValue = totalWalkingKMValue + walkingKMValue;
    walkingKMValue = calculateIndividualKM(
      restOfVICStaffCount,
      "Rest of VIC",
      "Walked only"
    );
    totalWalkingKMValue = totalWalkingKMValue + walkingKMValue;
    walkingKMValue = calculateIndividualKM(
      restOfQLDStaffCount,
      "Rest of QLD",
      "Walked only"
    );
    totalWalkingKMValue = totalWalkingKMValue + walkingKMValue;
    walkingKMValue = calculateIndividualKM(
      restOfSAStaffCount,
      "Rest of SA",
      "Walked only"
    );
    totalWalkingKMValue = totalWalkingKMValue + walkingKMValue;
    walkingKMValue = calculateIndividualKM(
      restOfWAStaffCount,
      "Rest of WA",
      "Walked only"
    );
    totalWalkingKMValue = totalWalkingKMValue + walkingKMValue;
    walkingKMValue = calculateIndividualKM(
      restOfTASStaffCount,
      "Rest of TAS",
      "Walked only"
    );
    totalWalkingKMValue = totalWalkingKMValue + walkingKMValue;
    walkingKMValue = calculateIndividualKM(
      restOfNTStaffCount,
      "Rest of NT",
      "Walked only"
    );
    totalWalkingKMValue = totalWalkingKMValue + walkingKMValue;
    setTotalWalkingKM(totalWalkingKMValue);
  }

  function calculateTotalFerryKM() {
    let totalKMValue = 0;
    let individualKMValue = calculateIndividualKM(
      sydneyStaffCount,
      "Sydney",
      "Ferry"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      melbourneStaffCount,
      "Melbourne",
      "Ferry"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      brisbaneStaffCount,
      "Brisbane",
      "Ferry"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      adelaideStaffCount,
      "Adelaide",
      "Ferry"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      perthStaffCount,
      "Perth",
      "Bicycle"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      hobartStaffCount,
      "Hobart",
      "Ferry"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      darwinStaffCount,
      "Darwin",
      "Ferry"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      canberraStaffCount,
      "Canberra",
      "Ferry"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfNSWStaffCount,
      "Rest of NSW",
      "Ferry"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfVICStaffCount,
      "Rest of VIC",
      "Ferry"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfQLDStaffCount,
      "Rest of QLD",
      "Ferry"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfSAStaffCount,
      "Rest of SA",
      "Ferry"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfWAStaffCount,
      "Rest of WA",
      "Ferry"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfTASStaffCount,
      "Rest of TAS",
      "Ferry"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfNTStaffCount,
      "Rest of NT",
      "Ferry"
    );
    totalKMValue = totalKMValue + individualKMValue;
    setTotalFerryKM(totalKMValue);
  }

  function calculateTotalMotorbikeKM() {
    let totalKMValue = 0;
    let individualKMValue = calculateIndividualKM(
      sydneyStaffCount,
      "Sydney",
      "Motorbike/scooter"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      melbourneStaffCount,
      "Melbourne",
      "Motorbike/scooter"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      brisbaneStaffCount,
      "Brisbane",
      "Motorbike/scooter"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      adelaideStaffCount,
      "Adelaide",
      "Motorbike/scooter"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      perthStaffCount,
      "Perth",
      "Motorbike/scooter"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      hobartStaffCount,
      "Hobart",
      "Motorbike/scooter"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      darwinStaffCount,
      "Darwin",
      "Motorbike/scooter"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      canberraStaffCount,
      "Canberra",
      "Motorbike/scooter"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfNSWStaffCount,
      "Rest of NSW",
      "Motorbike/scooter"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfVICStaffCount,
      "Rest of VIC",
      "Motorbike/scooter"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfQLDStaffCount,
      "Rest of QLD",
      "Motorbike/scooter"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfSAStaffCount,
      "Rest of SA",
      "Motorbike/scooter"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfWAStaffCount,
      "Rest of WA",
      "Motorbike/scooter"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfTASStaffCount,
      "Rest of TAS",
      "Motorbike/scooter"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfNTStaffCount,
      "Rest of NT",
      "Motorbike/scooter"
    );
    totalKMValue = totalKMValue + individualKMValue;

    setTotalMotorbikeScooterKM(totalKMValue);
  }

  function calculateTotalTaxiKM() {
    let totalKMValue = 0;
    let individualKMValue = calculateIndividualKM(
      sydneyStaffCount,
      "Sydney",
      "Taxi"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      melbourneStaffCount,
      "Melbourne",
      "Taxi"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      brisbaneStaffCount,
      "Brisbane",
      "Taxi"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      adelaideStaffCount,
      "Adelaide",
      "Taxi"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(perthStaffCount, "Perth", "Taxi");
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      hobartStaffCount,
      "Hobart",
      "Taxi"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      darwinStaffCount,
      "Darwin",
      "Taxi"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      canberraStaffCount,
      "Canberra",
      "Taxi"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfNSWStaffCount,
      "Rest of NSW",
      "Taxi"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfVICStaffCount,
      "Rest of VIC",
      "Taxi"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfQLDStaffCount,
      "Rest of QLD",
      "Taxi"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfSAStaffCount,
      "Rest of SA",
      "Taxi"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfWAStaffCount,
      "Rest of WA",
      "Taxi"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfTASStaffCount,
      "Rest of TAS",
      "Taxi"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfNTStaffCount,
      "Rest of NT",
      "Taxi"
    );
    totalKMValue = totalKMValue + individualKMValue;

    setTotalTaxiKM(totalKMValue);
  }

  function calculateTotalTrainKM() {
    let totalKMValue = 0;
    let individualKMValue = calculateIndividualKM(
      sydneyStaffCount,
      "Sydney",
      "Train"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      melbourneStaffCount,
      "Melbourne",
      "Train"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      brisbaneStaffCount,
      "Brisbane",
      "Train"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      adelaideStaffCount,
      "Adelaide",
      "Train"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      perthStaffCount,
      "Perth",
      "Train"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      hobartStaffCount,
      "Hobart",
      "Train"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      darwinStaffCount,
      "Darwin",
      "Train"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      canberraStaffCount,
      "Canberra",
      "Train"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfNSWStaffCount,
      "Rest of NSW",
      "Train"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfVICStaffCount,
      "Rest of VIC",
      "Train"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfQLDStaffCount,
      "Rest of QLD",
      "Train"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfSAStaffCount,
      "Rest of SA",
      "Train"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfWAStaffCount,
      "Rest of WA",
      "Train"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfTASStaffCount,
      "Rest of TAS",
      "Train"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfNTStaffCount,
      "Rest of NT",
      "Train"
    );
    totalKMValue = totalKMValue + individualKMValue;

    setTotalTrainKM(totalKMValue);
  }

  function calculateTotalTramKM() {
    let totalKMValue = 0;
    let individualKMValue = calculateIndividualKM(
      sydneyStaffCount,
      "Sydney",
      "Tram"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      melbourneStaffCount,
      "Melbourne",
      "Tram"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      brisbaneStaffCount,
      "Brisbane",
      "Tram"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      adelaideStaffCount,
      "Adelaide",
      "Tram"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(perthStaffCount, "Perth", "Tram");
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      hobartStaffCount,
      "Hobart",
      "Tram"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      darwinStaffCount,
      "Darwin",
      "Tram"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      canberraStaffCount,
      "Canberra",
      "Tram"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfNSWStaffCount,
      "Rest of NSW",
      "Tram"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfVICStaffCount,
      "Rest of VIC",
      "Tram"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfQLDStaffCount,
      "Rest of QLD",
      "Tram"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfSAStaffCount,
      "Rest of SA",
      "Tram"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfWAStaffCount,
      "Rest of WA",
      "Tram"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfTASStaffCount,
      "Rest of TAS",
      "Tram"
    );
    totalKMValue = totalKMValue + individualKMValue;
    individualKMValue = calculateIndividualKM(
      restOfNTStaffCount,
      "Rest of NT",
      "Tram"
    );
    totalKMValue = totalKMValue + individualKMValue;

    setTotalTramKM(totalKMValue);
  }

  function calculateIndividualKM(staffCount, city, travelMethod) {
    let totalValue = 0;
    const transportationMode = find(staffTransportMode, function (item) {
      if (item.city === city) {
        return item;
      }
    });

    const commutingDistance = find(
      staffAverageCommutingDistance,
      function (item) {
        if (item.city === city) {
          return item;
        }
      }
    );

    const mode = find(transportationMode.methods, function (item) {
      if (item.name === travelMethod) {
        return item;
      }
    });

    const distance = find(commutingDistance.methods, function (item) {
      if (item.name === travelMethod) {
        return item;
      }
    });

    const calc1 = mode.value * staffCount;
    const calc2 = calc1 / 100;
    const calc3 = calc2 * distance.value;
    const calc4 = calc3.toFixed(2) * 2;
    const calc5 = calc4.toFixed(2) * daysPerYear;

    // totalValue =
    //   ((mode.value * staffCount) / 100) * distance.value * 2 * daysPerYear;

    totalValue = calc5;
    if (calc5 > 0) totalValue = Number(calc5.toFixed(2));
    return totalValue;
  }

  async function handleSave(e) {
    e.preventDefault();
    setIsSaving(true);
    await saveStaffForm("DRAFT");
    setIsSaving(false);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (isDataFrozen()) {
      setShowFrozenDataModal(true);
      return;
    }
    setIsSubmiting(true);
    await saveStaffForm("SUBMITTED");
    await removeUnifiedDateFromStore();
    await fetchDashboardDataFromFirebaseByBusinessId(
      activeUser.businessId,
      activeUser.currentStartDate,
      activeUser.currentEndDate
    );
    setIsSubmiting(false);
    //to initiate chart data calculation
    sessionStorage.setItem("dashboardDataCalCompleted", false);
  }

  async function saveStaffForm(status) {
    try {
      const request = {
        id: staffDataId,
        status: status,
        days_per_year: daysPerYear,
        staff_sydney: sydneyStaffCount,
        staff_melbourne: melbourneStaffCount,
        staff_brisbane: brisbaneStaffCount,
        staff_adelaide: adelaideStaffCount,
        staff_perth: perthStaffCount,
        staff_hobrat: hobartStaffCount,
        staff_darwin: darwinStaffCount,
        staff_canberra: canberraStaffCount,
        staff_nsw: restOfNSWStaffCount,
        staff_vic: restOfVICStaffCount,
        staff_qld: restOfQLDStaffCount,
        staff_sa: restOfSAStaffCount,
        staff_wa: restOfWAStaffCount,
        staff_tas: restOfTASStaffCount,
        staff_nt: restOfNTStaffCount,
        total_bicycle: totalBicycleKM,
        total_bus: totalBusKM,
        total_car: totalCarKM,
        total_walking: totalWalkingKM,
        total_ferry: totalFerryKM,
        total_motorbike: totalMotorbikeScooterKM,
        total_taxi: totalTaxiKM,
        total_train: totalTrainKM,
        total_tram: totalTramKM,
        start_date: selectedDatePeriods.startDate.toDateString(),
        end_date: selectedDatePeriods.endDate.toDateString(),
        uploaded_by: JSON.parse(getActiveUserData()).uid,
      };

      await saveStaffData(request);
      toast(`Staff data saved/submitted successfully.`);
      navigate("/welcome/data/climate-active/all-forms?tabKey=staff");
    } catch (error) {
      toast.error(error);
    }
  }

  const onDatePeriodChange = (dates) => {
    const [start, end] = dates;
    setSelectedDatePeriods({
      startDate: start,
      endDate: end,
    });
  };

  const { id } = useParams();
  async function getStaffData(id) {
    setIsLoading(true);
    if (id) {
      setStaffDataId(id);
      const staffData = await getStaffDataById(id);
      if (staffData) {
        setDaysPerYear(staffData.days_per_year);
        setSydneyStaffCount(staffData.staff_sydney);
        setMelbourneStaffCount(staffData.staff_melbourne);
        setBrisbaneStaffCount(staffData.staff_brisbane);
        setAdelaideStaffCount(staffData.staff_adelaide);
        setPerthStaffCount(staffData.staff_perth);
        setHobartStaffCount(staffData.staff_hobrat);
        setDarwinStaffCount(staffData.staff_darwin);
        setCanberraStaffCount(staffData.staff_canberra);
        setRestOfNSWStaffCount(staffData.staff_nsw);
        setRestOfVICStaffCount(staffData.staff_vic);
        setRestOfQLDStaffCount(staffData.staff_qld);
        setRestOfSAStaffCount(staffData.staff_sa);
        setRestOfWAStaffCount(staffData.staff_wa);
        setRestOfTASStaffCount(staffData.staff_tas);
        setRestOfNTStaffCount(staffData.staff_nt);
        setSelectedDatePeriods({
          startDate: new Date(staffData.start_date),
          endDate: new Date(staffData.end_date),
        });
      }
    }
    setIsLoading(false);
  }
  useEffect(() => {
    getStaffData(id);
  }, [id]);

  useEffect(() => {
    setIsLoading(true);
    calculateTotalKM();
    setIsLoading(false);
  }, [
    daysPerYear,
    sydneyStaffCount,
    melbourneStaffCount,
    brisbaneStaffCount,
    adelaideStaffCount,
    perthStaffCount,
    hobartStaffCount,
    darwinStaffCount,
    canberraStaffCount,
    restOfNSWStaffCount,
    restOfVICStaffCount,
    restOfQLDStaffCount,
    restOfSAStaffCount,
    restOfWAStaffCount,
    restOfTASStaffCount,
    restOfNTStaffCount,
  ]);

  return (
    <>
    {showFrozenDataModal && (
        <FrozenDataModal
          show={showFrozenDataModal}
          onHide={() => {
            setShowFrozenDataModal(false);
          }}
        />
      )}
      {" "}
      <WidgetSectionTitle
        icon={faList}
        title="Employee Commute Form"
        subtitle="Fill your Employee Commute form here"
        transform="shrink-2"
        className="mb-4 mt-6"
      />
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Calculator</Card.Title>
              <Card.Text></Card.Text>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>How many commuting days per year?</Form.Label>
                    <Form.Control
                      type="number"
                      name="daysPerYear"
                      placeholder="How many commuting days per year?"
                      value={daysPerYear}
                      onChange={(e) => onDaysPerYearChange(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <h6 className="mb-0">
                <b>
                  How many staff do you have in each of these cities/region?
                </b>
              </h6>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Sydney</Form.Label>
                    <Form.Control
                      type="number"
                      name="Sydney"
                      placeholder="Sydney"
                      value={sydneyStaffCount}
                      onChange={(e) => onSydneyStaffCountChange(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Melbourne</Form.Label>
                    <Form.Control
                      type="number"
                      name="Melbourne"
                      placeholder="Melbourne"
                      value={melbourneStaffCount}
                      onChange={(e) =>
                        onMelbourneStaffCountChange(e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Brisbane</Form.Label>
                    <Form.Control
                      type="number"
                      name="Brisbane "
                      placeholder="Brisbane"
                      value={brisbaneStaffCount}
                      onChange={(e) =>
                        onBrisbaneStaffCountChange(e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Adelaide</Form.Label>
                    <Form.Control
                      type="number"
                      name="Adelaide"
                      placeholder="Adelaide"
                      value={adelaideStaffCount}
                      onChange={(e) =>
                        onAdelaideStaffCountChange(e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Perth</Form.Label>
                    <Form.Control
                      type="number"
                      name="Perth"
                      placeholder="Perth"
                      value={perthStaffCount}
                      onChange={(e) => onPerthStaffCountChange(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Hobart</Form.Label>
                    <Form.Control
                      type="number"
                      name="Hobart"
                      placeholder="Hobart"
                      value={hobartStaffCount}
                      onChange={(e) => onHobartStaffCountChange(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Darwin</Form.Label>
                    <Form.Control
                      type="number"
                      name="Darwin"
                      placeholder="Darwin"
                      value={darwinStaffCount}
                      onChange={(e) => onDarwinStaffCountChange(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Canberra</Form.Label>
                    <Form.Control
                      type="number"
                      name="Canberra"
                      placeholder="Canberra"
                      value={canberraStaffCount}
                      onChange={(e) =>
                        onCanberraStaffCountChange(e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Rest of NSW</Form.Label>
                    <Form.Control
                      type="number"
                      name="Rest of NSW"
                      placeholder="Rest of NSW"
                      value={restOfNSWStaffCount}
                      onChange={(e) =>
                        onRestOfNSWStaffCountChange(e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Rest of VIC</Form.Label>
                    <Form.Control
                      type="number"
                      name="Rest of VIC"
                      placeholder="Rest of VIC"
                      value={restOfVICStaffCount}
                      onChange={(e) =>
                        onRestOfVICStaffCountChange(e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Rest of QLD</Form.Label>
                    <Form.Control
                      type="number"
                      name="Rest of QLD"
                      placeholder="Rest of QLD"
                      value={restOfQLDStaffCount}
                      onChange={(e) =>
                        onRestOfQLDStaffCountChange(e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Rest of SA</Form.Label>
                    <Form.Control
                      type="number"
                      name="Rest of SA"
                      placeholder="Rest of SA"
                      value={restOfSAStaffCount}
                      onChange={(e) =>
                        onRestOfSAStaffCountChange(e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Rest of WA</Form.Label>
                    <Form.Control
                      type="number"
                      name="Rest of WA"
                      placeholder="Rest of WA"
                      value={restOfWAStaffCount}
                      onChange={(e) =>
                        onRestOfWAStaffCountChange(e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Rest of TAS</Form.Label>
                    <Form.Control
                      type="number"
                      name="Rest of TAS"
                      placeholder="Rest of TAS"
                      value={restOfTASStaffCount}
                      onChange={(e) =>
                        onRestOfTASStaffCountChange(e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Rest of NT</Form.Label>
                    <Form.Control
                      type="number"
                      name="Rest of NT"
                      placeholder="Rest of NT"
                      value={restOfNTStaffCount}
                      onChange={(e) =>
                        onRestOfNTStaffCountChange(e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Output</Card.Title>
              <Card.Text></Card.Text>
              <h6 className="mb-0">
                <b>Total km/type of transport category</b>
              </h6>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Bicycle</Form.Label>
                    <Form.Control
                      type="number"
                      name="Bicycle"
                      placeholder="Bicycle"
                      value={totalBicycleKM}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Bus</Form.Label>
                    <Form.Control
                      type="number"
                      name="Bus"
                      placeholder="Bus"
                      value={totalBusKM}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Car</Form.Label>
                    <Form.Control
                      type="number"
                      name="Car"
                      placeholder="Car"
                      value={totalCarKM}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Walking</Form.Label>
                    <Form.Control
                      type="number"
                      name="Walking"
                      placeholder="Walking"
                      value={totalWalkingKM}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Ferry</Form.Label>
                    <Form.Control
                      type="number"
                      name="Ferry"
                      placeholder="Ferry"
                      value={totalFerryKM}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Motorbike/scooter</Form.Label>
                    <Form.Control
                      type="number"
                      name="Motorbike/scooter"
                      placeholder="Motorbike/scooter"
                      value={totalMotorbikeScooterKM}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Taxi</Form.Label>
                    <Form.Control
                      type="number"
                      name="Taxi"
                      placeholder="Taxi"
                      value={totalTaxiKM}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Train</Form.Label>
                    <Form.Control
                      type="number"
                      name="Train"
                      placeholder="Train"
                      value={totalTrainKM}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Tram</Form.Label>
                    <Form.Control
                      type="number"
                      name="Tram"
                      placeholder="Tram"
                      value={totalTramKM}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Time period</Form.Label>
                    <DatePicker
                      selected={selectedDatePeriods.startDate}
                      onChange={onDatePeriodChange}
                      selectsRange
                      startDate={selectedDatePeriods.startDate}
                      endDate={selectedDatePeriods.endDate}
                      dateFormat="dd/MM/yyyy"
                      customInput={<GreetingsDateInput />}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    onClick={handleSave}
                    disabled={
                      !selectedDatePeriods?.startDate ||
                      !selectedDatePeriods?.endDate ||
                      isSaving ||
                      isSubmiting
                    }
                  >
                    {isSaving && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-1"
                      />
                    )}
                    Save as draft
                  </Button>{" "}
                  <Button
                    onClick={handleSubmit}
                    disabled={
                      !selectedDatePeriods?.startDate ||
                      !selectedDatePeriods?.endDate ||
                      isSubmiting ||
                      isSaving
                    }
                  >
                    {isSubmiting && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-1"
                      />
                    )}
                    Submit
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </>
  );
};

const GreetingsDateInput = forwardRef(({ value, onClick }, ref) => (
  <div>
    <Form.Control
      size="lg"
      ref={ref}
      onClick={onClick}
      value={value}
      className="ps-4"
      onChange={(e) => {
        console.log({ e });
      }}
    />
    <FontAwesomeIcon
      icon={faCalendarAlt}
      className="text-primary position-absolute top-50 translate-middle-y ms-2"
    />
  </div>
));

export default StaffForm;
