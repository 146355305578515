import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faBan, faStream } from "@fortawesome/free-solid-svg-icons";
import SoftBadge from "../../common/SoftBadge";
import AdvanceTableWrapper from "../../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../../common/advance-table/AdvanceTableFooter";
import AllEventTableHeader from "./AllEventTableHeader";
// import { getAllFiles } from "../../dashboards/e-commerce/welcomeAPI";
import { getFormattedDate } from "../../../helpers/utils";
import { Link } from "react-router-dom";

const recentPurchaseTableBadge = {
  Completed: {
    content: "Completed",
    type: "success",
    icon: faCheck,
  },
  Pending: { content: "Pending", type: "warning", icon: faStream },
};

const columns = [
  {
    accessor: "name",
    Header: "Event Name",
    headerProps: { className: "pe-1" },
    cellProps: {
      className: "fw-semi-bold",
    },
    Cell: (rowData) => {
      const row = rowData.row.original;
      const { id, name, link } = row;
      return (
        <Link to={link} state={{ id }}>
          {name}
        </Link>
      );
    },
  },
  {
    accessor: "startdate",
    Header: "Start Date",
    Cell: (rowData) => {
      const { startdate } = rowData.row.original;
      return getFormattedDate(startdate);
    },
  },
  {
    accessor: "enddate",
    Header: "End Date",
    Cell: (rowData) => {
      const { enddate } = rowData.row.original;
      return getFormattedDate(enddate);
    },
  },
  // {
  //   accessor: "status",
  //   Header: "Completion Status",

  //   headerProps: { className: "text-center" },
  //   cellProps: {
  //     className: "fs-0",
  //   },
  //   Cell: (rowData) => {
  //     const { status } = rowData.row.original;
  //     return (
  //       <SoftBadge pill bg={status.type} className="d-block">
  //         {status.content}
  //         <FontAwesomeIcon
  //           icon={status.icon}
  //           transform="shrink-2"
  //           className="ms-1"
  //         />
  //       </SoftBadge>
  //     );
  //   },
  // },
];

const AllEventTable = ({ events }) => {
  const [data, setData] = useState(null);

  const storeData = async (events) => {
    try {
      //set status jsonData
      // for (var i = 0; i < events.length; i++) {
      //   const status = events[i]["completion_status"];
      //   events[i]["status"] = recentPurchaseTableBadge[status];
      // }
      //set link jsonData
      for (var i = 0; i < events.length; i++) {
        events[i]["link"] = "/welcome/dashboard/event-form";
      }
      console.log("events", events);
      setData(events);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    storeData(events);
  }, [events]);
  const noDataMessages = {
    title: "No Event Data Found",
    subtitle: "Consider adding event data",
  };
  return (
    <>
      {data ? (
        <AdvanceTableWrapper
          columns={columns}
          data={data}
          selection
          sortable
          pagination
          perPage={7}
          rowCount={data.length}
        >
          <Card>
            <Card.Header>
              <AllEventTableHeader table />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="btn-reveal-trigger text-nowrap align-middle"
                tableProps={{
                  size: "sm",
                  className: "fs--1 mb-0 overflow-hidden",
                }}
                noDataMessage={noDataMessages}
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTableFooter
                rowCount={data.length}
                table
                rowInfo
                navButtons
              />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      ) : null}
    </>
  );
};

export default AllEventTable;
