import React, { useEffect, useState } from "react";
import { Row, Col, Button, Modal, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCircle,
  faArrowRight,
  faCheckCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { deleteEmployeeEngagementEstimationFormData } from "./EmployeeEngagementAPI";

const EmployeeEngagementEstimationCard = ({
  item,
  refreshData,
  formStatus,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Combine them into a formatted string
  const buttonStyle = {
    // width: "100%", // Set the width to 100%
    width: "100%", // Set the width to 100%
    textAlign: "center",
  };

  let timestamp = "";
  if (item.staffData && item.staffData.length > 0) {
    timestamp = item.staffData[0].update_date;
  } else if (item.wfhData && item.wfhData.length > 0) {
    timestamp = item.wfhData[0].modified_at;
  }

  // Get the day, month, and year
  const date = new Date(timestamp);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const year = date.getFullYear();
  const formattedDate = `${day}/${month}/${year}`;
  item.date = formattedDate;

  const handleDelete = async () => {
    setIsLoading(true); // Show loading spinner
    try {
      const response = await deleteEmployeeEngagementEstimationFormData(
        item.formId,
        item.officeId
      );

      if (response.ok) {
        console.log("Employee engagement form data deleted successfully");
        // window.location.reload(); // Refresh the page after deletion
        refreshData(item.formId); // Trigger the parent's get function
      } else {
        console.log("Failed to delete employee engagement form data");
      }
    } catch (error) {
      console.error("Error during delete operation:", error);
    } finally {
      setIsLoading(false); // Hide loading spinner
      setShowDeleteConfirmationModal(false);
    }
  };

  useEffect(() => {}, [location.state]);

  return (
    <>
      {DeleteConfirmationModal(
        showDeleteConfirmationModal,
        setShowDeleteConfirmationModal,
        handleDelete,
        isLoading
      )}
      <Row>
        <Col xs={9}>
          {/* <h5 className="mb-2 d-flex align-items-center h-100"> */}
          <h5 className=" d-flex align-items-center ">
            {item.officeData.name}
          </h5>
          <small style={{ display: "block" }}>
            {item.officeData.street} | {item.officeData.city} |{" "}
            {item.officeData.postcode}
          </small>
          <small style={{ display: "block" }}>
            {item.hasSubmittedData
              ? `Submitted on ${item.date}`
              : "Data not submitted"}
          </small>
        </Col>
        <Col xs={2} className="d-flex align-items-center ">
          <Link
            to="/welcome/data/employee-engagement-estimation-form-data"
            state={{
              ...location.state,
              officeId: item.officeId,
            }}
          >
            {item.hasSubmittedData ? (
              <Button variant="success" className="w-100" style={buttonStyle}>
                View/Edit response
                <FontAwesomeIcon icon={faCheckCircle} className="ms-2" />
              </Button>
            ) : (
              <Button variant="primary" className="w-100" style={buttonStyle}>
                Complete form
                <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
              </Button>
            )}
          </Link>
        </Col>
        <Col xs={1} className="d-flex align-items-center">
          {item.hasSubmittedData ? (
            // <Button variant="outline-danger" onClick={handleDelete}>
            <Button
              variant="outline-danger"
              onClick={() => setShowDeleteConfirmationModal(true)}
              disabled={formStatus === "Completed"}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </>
  );
};

const DeleteConfirmationModal = (
  showDeleteConfirmationModal,
  setShowDeleteConfirmationModal,
  handleDelete,
  isLoading
) => {
  return (
    <Modal
      centered
      show={showDeleteConfirmationModal}
      onHide={() => setShowDeleteConfirmationModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete estimation submission</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete this location's estimation data?
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => setShowDeleteConfirmationModal(false)}
          disabled={isLoading}
        >
          No
        </Button>
        <Button variant="danger" onClick={handleDelete} disabled={isLoading}>
          {isLoading ? <Spinner animation="border" size="sm" /> : "Yes"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmployeeEngagementEstimationCard;
