import BookMeeting from "../navbar/top/BookMeeting";
import { TransparencyContext } from "../../context/Context";
import { IoCheckmarkDoneCircleOutline } from "react-icons/io5";
import { useContext } from "react";
import LoadingTransparencyHub from "./LoadingTransparencyHub";

const Step4TransparencyHub = () => {
  const { isLoading, prefillRequest } = useContext(TransparencyContext);
  // console.log(selectedAssessment);
  // console.log("prefillRequest", prefillRequest);

  return (
    <>
      {isLoading ? (
        <LoadingTransparencyHub />
      ) : (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          {prefillRequest ? (
            <>
              <IoCheckmarkDoneCircleOutline
                style={{
                  marginBottom: 20,
                  fontSize: "3em",
                  color: "#00d27a",
                }}
              />
              <h2>Request to Autocomplete Form Submitted</h2>
              <p>
                We are now in the process of reviewing the documents submitted
                and prefilling the sustainability questionnaire on your behalf.
                If you missed something, you can{" "}
                <strong>go back to the previous step</strong> and upload any
                more documents. In the meantime, if you would like to book a
                meeting to discuss further or share your sustainability strategy
                please use the button below to meet with the NetNada team.
              </p>
              <BookMeeting />
            </>
          ) : (
            <>
              <IoCheckmarkDoneCircleOutline
                style={{
                  marginBottom: 20,
                  fontSize: "3em",
                  color: "#00d27a",
                }}
              />
              <h2>Form Submitted Successfully</h2>
              <p>
                Your form has been successfully submitted. If you have any
                questions at any time, you can either go back and update the
                form or contact our team for assistance.
              </p>
              <p>
                To review your score in detail, please proceed to the next step.
              </p>
              <p>
                If you would like to discuss your results or need further
                assistance, you can book a meeting with our team using the
                button below.
              </p>
              <BookMeeting />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Step4TransparencyHub;
