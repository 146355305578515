import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";

const UpgradeSubscriptionModal = ({ showModal, handleClose }) => {
  return (
    <div>
      {" "}
      <Modal
        show={showModal}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Please upgrade your account to access content.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            We offer flexible plans to ensure that every business can start its
            sustainability journey. We know it’s not a one-size fits all
            approach to helping drive climate action and our pricing reflects
            that.
          </p>
          <Row className="gx-3">
            <Col>
              <Button
                onClick={() => {
                  window.open(
                    "https://www.netnada.com.au/book-a-demo",
                    "_blank"
                  );
                }}
                size="sm"
              >
                TALK TO OUR TEAM
              </Button>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UpgradeSubscriptionModal;
