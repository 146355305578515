import React, { useState } from "react";
import { Button, CloseButton, Form, Modal, Spinner } from "react-bootstrap";
import {
  fetchCampaignURL,
  sendInviteEmailToSupplier,
} from "../../helpers/suppliersHelper";
import { getActiveUserData } from "../../helpers/store";

function TransparencyScoreSendModal({ show, onHide, contactData }) {
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const [inputValue, setInputValue] = useState(
    contactData.supplier ? contactData.supplier.email : ""
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const supplierId = contactData?.supplier.id || "";
    const supplierName = contactData?.supplier?.name || contactData.contactName;
    const reportingYear = activeUser.selectedMeasurementYear;
    const reportingType = activeUser.selectedMeasurementYearType;
    const reportingPeriod = `${
      reportingType === "financial" ? "FY" : "CY"
    }${reportingYear}`;
    const invitedBy = activeUser.businessId;

    //generate supplier invite link
    const inviteLink = await fetchCampaignURL(
      supplierId,
      supplierName,
      reportingPeriod,
      invitedBy
    );

    // Send email to the supplier
    await sendInviteEmailToSupplier({
      businessName: activeUser.businessName,
      supplierId: supplierId,
      supplierName: supplierName,
      supplierEmail: inputValue,
      reportingYear: reportingYear,
      reportingType: reportingType,
      inviteLink: inviteLink,
    });
    setIsLoading(false);
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Send Transparency Score Survey
        </Modal.Title>
        <CloseButton onClick={onHide} />
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3">
          <p>
            The email will be sent to the following address we have recorded in
            our system, but feel free to add or modify it.
          </p>
          <Form.Control
            type="text"
            name="{stat.cardType}"
            placeholder={inputValue}
            value={inputValue}
            onChange={handleChange}
            required
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
        <Button onClick={handleSubmit} disabled={!inputValue}>
          {isLoading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="sr-only">Loading...</span>
            </>
          ) : (
            "Submit"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TransparencyScoreSendModal;
