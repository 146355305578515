import { CountryRegionData } from "react-country-region-selector";

export const countryStateDataJSON = () => {
  let countryStateDataJSON = {};
  for (let i = 0; i < CountryRegionData.length; i++) {
    let stateListString = CountryRegionData[i][2];
    let stateList = stateListString.split("|");
    let stateCodeList = [];
    for (let i = 0; i < stateList.length; i++) {
      let state = stateList[i];
      let stateDataJSON = { name: "", code: "" };
      stateDataJSON["name"] = state.split("~")[0];
      if (state.split("~")[0] === "London, City of") {
        stateDataJSON["name"] = "London";
      }
      stateDataJSON["code"] = state.split("~")[1];
      stateCodeList.push(stateDataJSON);
    }
    countryStateDataJSON[CountryRegionData[i][0]] = stateCodeList;
  }
  return countryStateDataJSON;
};

export const countryNameList = () => {
  let countryNameList = [];
  for (let i = 0; i < CountryRegionData.length; i++) {
    countryNameList.push(CountryRegionData[i][0]);
  }
  return countryNameList;
};

export const countryCodeList = () => {
  let countryCodeList = [];
  for (let i = 0; i < CountryRegionData.length; i++) {
    let countryDataJSON = { name: "", code: "" };
    countryDataJSON["name"] = CountryRegionData[i][0];
    countryDataJSON["code"] = CountryRegionData[i][1];
    countryCodeList.push(countryDataJSON);
  }
  return countryCodeList;
};

export const CountryNameCodeObject = () => {
  let countryNameCodeObject = {};
  for (let i = 0; i < CountryRegionData.length; i++) {
    countryNameCodeObject[CountryRegionData[i][0]] = CountryRegionData[i][1];
  }
  return countryNameCodeObject;
};

export const CountryCodeNameObject = () => {
  let countryCodeNameObject = {};
  for (let i = 0; i < CountryRegionData.length; i++) {
    countryCodeNameObject[CountryRegionData[i][1]] = CountryRegionData[i][0];
  }
  return countryCodeNameObject;
};

export const StateCodeNameObject = (selectedCountryCode, selectedStateCode) => {
  let stateCodeNameObject = {};
  for (let i = 0; i < CountryRegionData.length; i++) {
    if (CountryRegionData[i][1] === selectedCountryCode) {
      let stateListString = CountryRegionData[i][2];
      let stateList = stateListString.split("|");
      for (let j = 0; j < stateList.length; j++) {
        let stateData = stateList[j].split("~");
        if (stateData[1] === selectedStateCode) {
          stateCodeNameObject[stateData[1]] = stateData[0];
          break; // Stop searching once the matching state is found
        }
      }
      break; // Stop searching once the matching country is found
    }
  }
  return stateCodeNameObject;
};

// Function to get data only for selected countires
export const targetedCountryCodeList = (targetCountries) => {
  let countryCodeList = [];
  for (let i = 0; i < CountryRegionData.length; i++) {
    let countryName = CountryRegionData[i][0];
    if (targetCountries.includes(countryName)) {
      let countryDataJSON = { name: "", code: "" };
      countryDataJSON["name"] = countryName;
      countryDataJSON["code"] = CountryRegionData[i][1];
      countryCodeList.push(countryDataJSON);
    }
  }
  return countryCodeList;
};
