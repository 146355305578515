import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../shadcn/Card";
import { Trophy, CheckCircle, SkipForward, ArrowRight } from "lucide-react";
import { Progress } from "../shadcn/Progress";
import { Button } from "../shadcn/Button"; // assuming Button is imported here
import { useNavigate } from "react-router-dom";
import { getActiveUserData, getInventoryProgress } from "../../helpers/store";
import { updateStepProgress } from "../../helpers/stepsProgress";

// Badge import here if needed

const GoalCard = ({
  totalProgress,
  nextTask,
  setStepsProgressData,
  setCurrentInventoryProgress,
}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const [currentInventoryProgress] = useState(
    JSON.parse(getInventoryProgress())
  );

  const handleGoToTask = () => {
    if (nextTask.url.startsWith("https://")) {
      // For external URLs
      window.location.href = nextTask.url;
    } else {
      // For internal URLs, pass stringId in the state
      navigate(nextTask.url, { state: { stringId: nextTask.stringId } });
    }
  };

  const onTaskComplete = async (stringId, officeId) => {
    setLoading(true);
    await updateStepProgress({
      stringId: stringId,
      status: "completed",
      activeUser,
      currentInventoryProgress,
      officeId,
      onUpdateState: false,
      onUpdateInventoryProgress: setCurrentInventoryProgress,
    });

    setLoading(false);
  };
  const onTaskSkip = async (stringId, officeId) => {
    setLoading(true);
    await updateStepProgress({
      stringId: stringId,
      status: "skipped",

      activeUser,
      currentInventoryProgress,
      officeId,
      onUpdateState: false,
      onUpdateInventoryProgress: setCurrentInventoryProgress,
    });
    setLoading(false);
  };
  return (
    <Card className="tw-mb-6">
      <CardHeader>
        <CardTitle className="tw-flex tw-items-center tw-gap-2">
          <Trophy className="tw-h-6 tw-w-6 tw-text-yellow-500" />
          Your Goal: Measure your Carbon Emissions
        </CardTitle>
        <CardDescription>
          Track your progress towards a sustainable future
        </CardDescription>
      </CardHeader>

      <CardContent>
        <div className="tw-flex tw-items-center tw-gap-4 tw-mb-4">
          <Progress value={totalProgress} className="tw-flex-grow" />
          <span className="tw-text-2xl tw-font-bold">
            {totalProgress.toFixed(0)}%
          </span>
        </div>

        {nextTask && (
          <div className="tw-bg-muted tw-p-4 tw-rounded-lg tw-flex tw-items-center tw-justify-between">
            <div>
              <h3 className="tw-font-semibold tw-text-2xl tw-mb-2">
                Next Task: {nextTask.subStep}
              </h3>
              <div className="tw-flex tw-items-center tw-gap-2">
                <span className="tw-font-medium tw-flex tw-items-center tw-h-full">
                  {nextTask.category}
                </span>
                <span className="tw-h-4 tw-w-4 tw-flex tw-items-center tw-justify-center tw-text-lg">
                  →
                </span>
                <span className="tw-font-medium tw-flex tw-items-center tw-h-full">
                  {nextTask.step}
                </span>
                <span className="tw-h-4 tw-w-4 tw-flex tw-items-center tw-justify-center tw-text-lg">
                  →
                </span>
                <span className="tw-font-medium tw-flex tw-items-center tw-h-full">
                  {nextTask.stepLocation} - {nextTask.subStep}
                </span>
              </div>
            </div>

            <div className="tw-flex tw-gap-2 tw-ml-4">
              {nextTask.selfClickable && (
                <Button
                  onClick={() =>
                    onTaskComplete(nextTask.stringId, nextTask.officeId)
                  }
                  className="tw-w-full tw-sm:w-auto tw-flex-1 tw-bg-green-500 hover:tw-bg-green-600"
                >
                  <CheckCircle className="tw-mr-2 tw-h-4 tw-w-4" /> Mark as Done
                </Button>
              )}
              {nextTask.skippable && (
                <Button
                  onClick={() =>
                    onTaskSkip(nextTask.stringId, nextTask.officeId)
                  }
                  variant="outline"
                  className="tw-w-full tw-sm:w-auto tw-flex-1"
                >
                  <SkipForward className="tw-mr-2 tw-h-4 tw-w-4" /> Skip Task
                </Button>
              )}
              <Button
                onClick={handleGoToTask}
                className="tw-w-full tw-sm:w-auto tw-flex-1 tw-bg-blue-500 hover:tw-bg-blue-600"
              >
                <ArrowRight className="tw-mr-2 tw-h-4 tw-w-4" /> Go to Task
              </Button>
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default GoalCard;
