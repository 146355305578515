import { useEffect, useState } from "react";
import { Card, Col, FloatingLabel, Form, Row, Spinner } from "react-bootstrap";
import Chartjs from "../chartjs";
import { getActiveUserData } from "../../helpers/store";

const Index = () => {
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const [data, setData] = useState();
  const [isDefault, setIsDefault] = useState(true);
  const [office, setOffice] = useState("all");
  const [business, setBusiness] = useState(activeUser.businessId);
  const [offices, setOffices] = useState([]);
  const [show, setShow] = useState(true);
  const [nameId, setNameId] = useState(() => {
    const businessOffices = JSON.parse(
      sessionStorage.getItem("businessOffices")
    );
    let nameIdDict = {};
    for (let i = 0; i < businessOffices.length; i++) {
      let str = businessOffices[i].split(",");
      nameIdDict[str[0]] = str[1];
    }
    return nameIdDict;
  });
  const [showUnifiedData] = useState(
    JSON.parse(sessionStorage.getItem("unifiedDataCalCompleted") || null)
  );
  // console.log("showUnifiedData", showUnifiedData);
  const [isLoading, setIsLoading] = useState(true);

  function handleChange(e) {
    if (e.target.value !== "Select..." && e.target.value !== "all") {
      //setBusiness(data[0].business_id)
      setOffice(nameId[e.target.value]);
      setShow(true);
    } else {
      if (e.target.value === "all") {
        setOffice(e.target.value);
        setShow(true);
      } else {
        setShow(false);
      }
    }
  }

  function getOffice() {
    setIsLoading(true);
    const listSize = JSON.parse(sessionStorage.getItem("officeId")).length;
    if (listSize === 0 || listSize > 1) {
      setIsDefault(false);
    } else if (listSize === 1) {
      // setOffice(JSON.parse(sessionStorage.getItem("officeId"))[0]);
      setIsDefault(true);
    }
    setData(JSON.parse(sessionStorage.getItem("officeName")));
    setOffices(JSON.parse(sessionStorage.getItem("officeId")));
    setIsLoading(false);
  }

  useEffect(() => {
    getOffice();
  }, []);

  // Render loading screen until showUnifiedData is true
  // if (!showUnifiedData) {
  //   return (
  //     <div className="d-flex justify-content-center align-items-center vh-100">
  //       <Spinner animation="border" role="status">
  //         <span className="visually-hidden">Loading...</span>
  //       </Spinner>
  //       <div className="ms-3">
  //         <span className="small">Please wait while we load the data.</span>
  //       </div>
  //       {/* Add more informative loading content here */}
  //     </div>
  //   );
  // }

  return (
    <>
      {!isLoading &&
        (isDefault ? (
          <>
            {business ? (
              <>
                <br />{" "}
                <Chartjs
                  id={office}
                  business={business}
                  showUnifiedData={showUnifiedData}
                  offices={[office]}
                />
              </>
            ) : null}
          </>
        ) : (
          <>
            {/* <Form.Label>Select Location</Form.Label>
            <Row className="g-2">
              <Col md>
                <FloatingLabel controlId="floatingSelectGrid" label="Location">
                  <Form.Select
                    defaultValue={office}
                    aria-label="Floating label select example"
                    required
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  >
                    <option>Select...</option>
                    <option value="all">All Locations</option>
                    {data
                      ? data.map((item, i) => (
                          <option key={i} value={item}>
                            {item}
                          </option>
                        ))
                      : null}
                  </Form.Select>
                </FloatingLabel>
              </Col>
            </Row> */}
            {show ? (
              business ? (
                <>
                  <br />
                  <Chartjs
                    id={office}
                    business={business}
                    offices={offices}
                    showUnifiedData={showUnifiedData}
                  />
                </>
              ) : null
            ) : null}
          </>
        ))}
    </>
  );
};

export default Index;
