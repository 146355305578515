import { clearLoginData } from "../../../helpers/store";
import {
  getAuthorizationHeader,
  getAuthorizationHeaderWithContentType,
} from "../../../helpers/utils";

export const getAllForms = async () => {
  try {
    const response = await fetch(`/api/climateActive/all-forms`, {
      method: "GET",
      headers: getAuthorizationHeader(),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const deleteSelectedForms = async (data) => {
  try {
    const response = await fetch(`/api/climateActive/delete-forms`, {
      method: "DELETE",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(data),
      mode: "cors",
    });

    if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};
