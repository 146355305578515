import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import StatisticsCard from "../../chartjs/StatisticsCard";
import { fetchEventFormData } from "./eventDashboardHelper";
import bg1 from "../../../Assets/img/icons/spot-illustrations/corner-1.png";
import bg3 from "../../../Assets/img/icons/spot-illustrations/corner-3.png";

const TotalEmissionsCard = ({ eventDetails }) => {
  const [totalPerAttendee, setTotalPerAttendee] = useState(0);
  const [totalPerAttendeeStatus, setTotalPerAttendeeStatus] = useState(false);
  const [totalStatus, setTotalStatus] = useState(false);
  const [total, setTotal] = useState(0);

  const fetch = async (eventId, businessId, numberOfPeople) => {
    let totalEmissions = 0;
    if (eventId) {
      //fetch water data
      const waterTotalEmissions = await fetchEventFormData(
        businessId,
        eventId,
        "Water"
      );
      if (waterTotalEmissions) {
        totalEmissions += waterTotalEmissions;
      }

      //fetch waste data
      const wasteTotalEmissions = await fetchEventFormData(
        businessId,
        eventId,
        "Waste"
      );
      if (wasteTotalEmissions) {
        totalEmissions += wasteTotalEmissions;
      }

      //fetch food data
      const foodTotalEmissions = await fetchEventFormData(
        businessId,
        eventId,
        "Food"
      );
      if (foodTotalEmissions) {
        totalEmissions += foodTotalEmissions;
      }

      //fetch flight data
      const flightTotalEmissions = await fetchEventFormData(
        businessId,
        eventId,
        "Flight"
      );
      if (flightTotalEmissions) {
        totalEmissions += flightTotalEmissions;
      }

      //fetch local transport data
      const localTransportTotalEmissions = await fetchEventFormData(
        businessId,
        eventId,
        "Local Transport"
      );
      if (localTransportTotalEmissions) {
        totalEmissions += localTransportTotalEmissions;
      }

      //fetch regional ground transport data
      const regionalGroundTransportTotalEmissions = await fetchEventFormData(
        businessId,
        eventId,
        "Regional Ground Transport"
      );
      if (regionalGroundTransportTotalEmissions) {
        totalEmissions += regionalGroundTransportTotalEmissions;
      }
      setTotal(totalEmissions);
      setTotalStatus(true);
      setTotalPerAttendee(totalEmissions / numberOfPeople);
      setTotalPerAttendeeStatus(true);
    }
  };

  useEffect(() => {
    if (eventDetails) {
      const { id, business_id, num_of_people } = eventDetails;
      console.log("Event Id: ", id);
      console.log("Business Id: ", business_id);
      console.log("Number of people: ", num_of_people);
      fetch(id, business_id, num_of_people);
    }
  }, [eventDetails]);

  const statsData = [
    {
      title: "Emmissions per Attendee",
      value: totalPerAttendee,
      decimal: true,
      suffix: " kg CO₂e",
      prefix: "",
      valueClassName: "text-warning",
      badgeBg: "warning",
      //   badgeText: perc1 ? perc1.toFixed(1) + "%" : "0%",
      link: "",
      linkText: "",
      image: bg1,
      status: totalPerAttendeeStatus,
    },
    {
      title: "Total",
      value: total,
      decimal: true,
      suffix: " kg CO₂e",
      prefix: "",
      valueClassName: "",
      badgeBg: "success",
      link: "/",
      linkText: "",
      image: bg3,
      status: totalStatus,
    },
  ];
  return (
    <Row className="g-3 mb-3" style={{ justifyContent: "space-around" }}>
      {statsData.map((stat, index) => (
        <Col key={stat.title} sm={6} md={4}>
          <StatisticsCard stat={stat} style={{ minWidth: "12rem" }} />
        </Col>
      ))}
    </Row>
  );
};

export default TotalEmissionsCard;
