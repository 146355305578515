import team1 from "../Assets/img/team/1.jpg";
import team2 from "../Assets/img/team/2.jpg";
import team3 from "../Assets/img/team/3.jpg";
import team4 from "../Assets/img/team/4.jpg";
import team5 from "../Assets/img/team/5.jpg";
import team6 from "../Assets/img/team/6.jpg";
import team7 from "../Assets/img/team/7.jpg";
import team8 from "../Assets/img/team/8.jpg";
import team9 from "../Assets/img/team/9.jpg";
import team10 from "../Assets/img/team/10.jpg";
import team11 from "../Assets/img/team/11.jpg";
import team12 from "../Assets/img/team/12.jpg";
import team13 from "../Assets/img/team/13.jpg";
import team14 from "../Assets/img/team/14.jpg";
import team15 from "../Assets/img/team/15.jpg";
import team16 from "../Assets/img/team/16.jpg";
import team17 from "../Assets/img/team/17.jpg";
import team18 from "../Assets/img/team/18.jpg";
import team19 from "../Assets/img/team/19.jpg";
import team20 from "../Assets/img/team/20.jpg";
import team21 from "../Assets/img/team/21.jpg";
import team22 from "../Assets/img/team/22.jpg";
import team23 from "../Assets/img/team/23.jpg";
import team24 from "../Assets/img/team/24.jpg";
import team25 from "../Assets/img/team/25.jpg";
import netnada from "../Assets/img/team/NetNada.png";
import { faVrCardboard } from "@fortawesome/free-solid-svg-icons";

export default [
  {
    id: 0,
    avatarSrc: team1,
    name: "Emilia Clarke",
    institution: "Technext limited",
    profileLink: "/pages/profile",
    institutionLink: "#!",
    status: "status-online",
  },
  {
    id: 1,
    avatarSrc: team2,
    name: "Kit Harington",
    institution: "Harvard Korea Society",
    profileLink: "/pages/profile",
    institutionLink: "#!",
    mutual: 5,
    status: "status-away",
  },
  {
    id: 2,
    avatarSrc: team3,
    name: "Sophie Turner",
    institution: "Graduate Student Council",
    profileLink: "/pages/profile",
    institutionLink: "#!",
    mutual: 10,
    status: "status-online",
  },
  {
    id: 3,
    avatarSrc: team4,
    name: "Peter Dinklage",
    institution: "Art Club, MIT",
    profileLink: "/pages/profile",
    institutionLink: "#!",
    mutual: 8,
    status: "status-online",
  },
  {
    id: 4,
    avatarSrc: team5,
    name: "Nikolaj Coster",
    institution: "Archery Club, MIT",
    profileLink: "/pages/profile",
    institutionLink: "#!",
    status: "status-online",
  },
  {
    id: 5,
    avatarSrc: team6,
    name: "Isaac Hempstead",
    institution: "Asymptones",
    profileLink: "/pages/profile",
    institutionLink: "#!",
    status: "status-online",
  },
  {
    id: 6,
    avatarSrc: team7,
    name: "Alfie Allen",
    institution: "Brain Trust",
    profileLink: "/pages/profile",
    institutionLink: "#!",
  },
  {
    id: 7,
    avatarSrc: team8,
    name: "Iain Glen",
    institution: "GSAS Action Coalition",
    profileLink: "/pages/profile",
    institutionLink: "#!",
  },
  {
    id: 8,
    avatarSrc: team9,
    name: "Liam Cunningham",
    institution: "Caving Club, MIT",
    profileLink: "/pages/profile",
    institutionLink: "#!",
  },
  {
    id: 9,
    avatarSrc: team10,
    name: "John Bradley",
    institution: "Chess Club",
    profileLink: "/pages/profile",
    institutionLink: "#!",
  },
  {
    id: 10,
    avatarSrc: team11,
    name: "Rory McCann",
    institution: "Chamber Music Society",
    profileLink: "/pages/profile",
    institutionLink: "#!",
    status: "status-online",
  },
  {
    id: 11,
    avatarSrc: team12,
    name: "Joe Dempsie",
    institution: "Clubchem",
    profileLink: "/pages/profile",
    institutionLink: "#!",
    status: "status-online",
  },
  {
    id: 12,
    avatarSrc: team13,
    name: "Maisie Williams",
    institution: "Brain Trust",
    profileLink: "/pages/profile",
    institutionLink: "#!",
    status: "status-online",
  },
  {
    id: 13,
    avatarSrc: team14,
    name: "Lena Headey",
    institution: "Clubchem",
    profileLink: "/pages/profile",
    institutionLink: "#!",
    status: "status-online",
  },
  {
    id: 14,
    avatarSrc: team15,
    name: "Gwendoline Christie",
    institution: "Technext limited",
    profileLink: "/pages/profile",
    institutionLink: "#!",
  },
  {
    id: 15,
    avatarSrc: team16,
    name: "Nathalie Emmanuel",
    institution: "Asymptones",
    profileLink: "/pages/profile",
    institutionLink: "#!",
  },
  {
    id: 16,
    avatarSrc: team17,
    name: "Natalie Dormer",
    institution: "Musical Club",
    profileLink: "/pages/profile",
    institutionLink: "#!",
  },
  {
    id: 17,
    avatarSrc: team18,
    name: "Carice Houten",
    institution: "Concert Choir",
    profileLink: "/pages/profile",
    institutionLink: "#!",
  },
  {
    id: 18,
    avatarSrc: team19,
    name: "Hannah Murray",
    institution: "Career Club",
    profileLink: "/pages/profile",
    institutionLink: "#!",
  },
  {
    id: 19,
    avatarSrc: team20,
    name: "Rose Leslie",
    institution: "Musical Club",
    profileLink: "/pages/profile",
    institutionLink: "#!",
  },
  {
    id: 20,
    avatarSrc: team21,
    name: "Rory McCann",
    institution: "Alpine Ski Club",
    profileLink: "/pages/profile",
    institutionLink: "#!",
  },
  {
    id: 21,
    avatarSrc: team22,
    name: "Diana Rigg",
    institution: "Clubchem",
    profileLink: "/pages/profile",
    institutionLink: "#!",
  },
  {
    id: 22,
    avatarSrc: team23,
    name: "Michelle Fairley",
    institution: "Alpha Chi Omega",
    profileLink: "/pages/profile",
    institutionLink: "#!",
    status: "status-online",
  },
  {
    id: 23,
    avatarSrc: team24,
    name: "Gemma Whelan",
    institution: "Chamber Music Society",
    profileLink: "/pages/profile",
    institutionLink: "#!",
    status: "status-online",
  },
  {
    id: 24,
    avatarSrc: team25,
    name: "Indira Varma",
    institution: "ASA",
    profileLink: "/pages/profile",
    institutionLink: "#!",
  },
  {
    id: 25,
    avatarSrc: netnada,
    name: "Nettie",
    institution: "Technext limited",
    profileLink: "/pages/profile",
    institutionLink: "#!",
    status: "status-online",
  },
];
