import React, { useState } from "react";
import { Card, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import CountUp from "react-countup";

import { faFileLines } from "@fortawesome/free-solid-svg-icons";
import bg2 from "../../Assets/img/icons/spot-illustrations/corner-2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Background from "../common/Background";
import { BsInfoCircle } from "react-icons/bs";
import { getActiveUserData } from "../../helpers/store";
import { FaFileCsv, FaLink, FaFileInvoiceDollar } from "react-icons/fa";
import { FaCalculator } from "react-icons/fa6";
import { Link } from "react-router-dom";

function DatalinesWidget({ data }) {
  
  const [showModal, setShowModal] = useState(false);
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const dataInfo = {
    id: 2,
    cardType: "employee",
    title: "Number of Datalines",
    amount: data?.totalLines,
    // totalEmissions: yearEmissions,
    // missingAmount: missingEmployees,
    decimals: 0,
    // amountLastYear: deltaEmissionsPerEmployeePreviousYear,
    units: "invoice lines",
    icon: faFileLines,
    image: bg2,
    color: "primary",
    className: "",
  };

  //Check how many have info and always print at least 3

  return (
    <>
      <Card className="pt-3 pb-3 text-center" style={{ minHeight: "170px" }}>
        <Background image={dataInfo.image} className="bg-card" />
        <div className={`icon-circle icon-circle-${dataInfo.color}`}>
          <FontAwesomeIcon
            icon={dataInfo.icon}
            className={`fs-2 text-${dataInfo.color}`}
          />
        </div>
        <h5 className="mb-2 font-sans-serif">
          <span className="fw-normal text-600">{dataInfo.title}</span>
        </h5>
        <div
          style={{
            width: "50%",
            height: "2px",
            backgroundColor: "#ddd",
            margin: "0 auto 10px auto",
          }}
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "start",
              justifyContent: "center",
            }}
          >
            <h3
              className="mb-2 font-sans-serif"
              style={{ marginRight: "10px" }}
            >
              <CountUp
                start={0}
                end={data?.totalLines}
                duration={2.75}
                className="text-800"
                separator=","
                decimals={dataInfo.decimals}
              />
              <span
                className="fw-normal text-600"
                style={{ fontSize: "0.70em" }}
              >
                {" " + dataInfo.units}
              </span>
            </h3>
            <BsInfoCircle
              style={{ color: "#2D7FDE", cursor: "pointer" }}
              onClick={() => setShowModal(true)}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-around",
              // gap: 40,
            }}
          >
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="csv-tooltip">Datalines from CSV Uploads</Tooltip>}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <FaFileCsv className="text-600" />
                <CountUp
                  start={0}
                  end={data?.lines?.countCSV}
                  duration={2.75}
                  className="text-800"
                  separator=","
                  decimals={0}
                />
              </div>
            </OverlayTrigger>

            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="integration-tooltip">
                  Datalines from Xero or MYOB
                </Tooltip>
              }
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 5,
                }}
              >
                <FaLink className="text-600" />
                <CountUp
                  start={0}
                  end={data?.lines?.countIntegration}
                  duration={2.75}
                  className="text-800"
                  separator=","
                  decimals={0}
                />
              </div>
            </OverlayTrigger>

            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="form-tooltip">Datalines from Calculators</Tooltip>
              }
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 5,
                }}
              >
                <FaCalculator className="text-600" />
                <CountUp
                  start={0}
                  end={data?.lines?.countForm}
                  duration={2.75}
                  className="text-800"
                  separator=","
                  decimals={0}
                />
              </div>
            </OverlayTrigger>

            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="bill-tooltip">Datalines from Bill Uploads</Tooltip>}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 5,
                }}
              >
                <FaFileInvoiceDollar className="text-600" />
                <CountUp
                  start={0}
                  end={data?.lines?.countBill}
                  duration={2.75}
                  className="text-800"
                  separator=","
                  decimals={0}
                />
              </div>
            </OverlayTrigger>
          </div>
        </div>
      </Card>

      {showModal && (
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Datalines
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ padding: "15px" }}>
              <p style={{ marginBottom: "10px" }}>
                All the data for this reporting year comes from{" "}
                <strong>{data?.totalLines}</strong> lines of data uploaded by{" "}
                <strong>{activeUser.businessName}</strong>.
              </p>
              <p style={{ marginBottom: "10px" }}>
                These datalines are collected from four different sources:
              </p>
              <ul>
                <li>
                  Integrations with accounting programs such as Xero and MYOB.
                </li>
                <li>
                  Invoices and transactions directly uploaded to the app via CSV
                  files.
                </li>
                <li>Gas and electricity bills uploaded by users.</li>
                <li>
                  Inputs coming from the calculators available
                  <Link
                    to="/welcome/data/calculations"
                    style={{ marginLeft: 5 }}
                  >
                    here
                  </Link>
                  .
                </li>
              </ul>
              <p style={{ marginBottom: "10px" }}>
                We analyze each dataline using AI to categorize it
                appropriately. Then, we apply the emission factors from
                different sources to compute the exact amount of kilograms of
                CO2 that each transaction or dataline emits.
              </p>
              <p style={{ marginBottom: "10px" }}>
                This process ensures accurate and reliable carbon emission
                calculations for all your data entries.
              </p>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default DatalinesWidget;
