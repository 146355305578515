import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { Draggable } from "react-beautiful-dnd";
import { EmissionsBoundariesContext } from "../../context/Context";

const TaskCard = ({
  task: {
    id,
    title,
    status,
    offices,
    description,
    dataSources,
    emissions,
    spendMoneyData,
    reasoning,
  },
  index,
  name,
  emissionsBoundaryId,
}) => {
  const { emissionsBoundariesDispatch } = useContext(
    EmissionsBoundariesContext
  );

  const [showWarning, setShowWarning] = useState(false);

  const handleModalOpen = () => {
    const officeNameId =
      sessionStorage.getItem("officeNameId") !== undefined
        ? JSON.parse(sessionStorage.getItem("officeNameId"))
        : null;

    if (!officeNameId) {
      return;
    }

    const locationList = officeNameId.map((item) => {
      const location = item.split(",");
      return { id: location[1], name: location[0] };
    });

    emissionsBoundariesDispatch({
      type: "OPEN_EMISSIONS_BOUNDARIES_MODAL",
      payload: {
        emissionsBoundaryId: emissionsBoundaryId,
        id,
        title: title,
        name: name,
        dataSources: dataSources,
        description: description,
        locationList: locationList,
        selectedLocations: offices,
        amountAUD: spendMoneyData || "",
        amountCO2e: emissions || "",
        reasoning: reasoning,
        showWarning: showWarning,
      },
    });
  };

  // styles we need to apply on draggables
  const getItemStyle = (isDragging) => ({
    cursor: isDragging ? "grabbing" : "pointer",
    transform: isDragging ? "rotate(-2deg)" : "",
  });

  useEffect(() => {
    if (!reasoning) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  }, [reasoning]);

  return (
    <Draggable draggableId={`task${id}`} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={provided.draggableProps.style}
          className="kanban-item"
        >
          <Card
            style={getItemStyle(snapshot.isDragging)}
            className="kanban-item-card hover-actions-trigger"
            onClick={handleModalOpen}
          >
            <Card.Body>
              <Row className="mb-2">
                <Col xs="auto">
                  <h5 className="mb-0 fw-medium font-sans-serif stretched-link">
                    {title} {""}
                  </h5>
                </Col>
                {(emissionsBoundaryId === 2 || emissionsBoundaryId === 3) &&
                  showWarning && (
                    <Col xs="auto" className="ms-auto">
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>pending</Tooltip>}
                      >
                        <span>
                          <FontAwesomeIcon
                            size="2x"
                            icon={faCircleInfo}
                            className="mx-2"
                            style={{ color: "#eec911" }}
                          />
                        </span>
                      </OverlayTrigger>
                    </Col>
                  )}
              </Row>
            </Card.Body>
          </Card>
        </div>
      )}
    </Draggable>
  );
};

TaskCard.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    members: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string,
      })
    ),
    attachments: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        url: PropTypes.string,
      })
    ),
    labels: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        text: PropTypes.string,
      })
    ),
    checklist: PropTypes.shape({
      totalCount: PropTypes.number,
      completed: PropTypes.number,
    }),
  }),
  index: PropTypes.number,
};

export default TaskCard;
