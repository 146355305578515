import React from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import xeroLogo from "../../Assets/img/icons/xero.png";
import googleDark from "../../Assets/img/icons/googleDark.png";
import googleLight from "../../Assets/img/icons/googleLight.png";
import { useState } from "react";
import myobLogo from "../../Assets/img/icons/myob.png";

const SocialAuthButtons = ({ handleRedirectToOrBack, xero }) => {
  const [imgSrc, setImgSrc] = useState(googleDark);

  return (
    <Form.Group className="mb-0">
      {xero !== undefined ? (
        xero !== "false" ? (
          <>
            <Row>
              <Col sm={6} className="ps-sm-1">
                <Button
                  variant=""
                  size="sm"
                  className="btn-outline-facebook mt-2 w-100"
                  onClick={() => {
                    window.location = "/xero-sign-up";
                  }}
                >
                  <img
                    src={xeroLogo}
                    className="me-2"
                    alt="xero"
                    style={{ height: "30px", width: "30px" }}
                  />{" "}
                  Sign up with Xero
                </Button>
              </Col>
              <Col sm={6} className="ps-sm-1">
                <Button
                  variant=""
                  size="sm"
                  className="btn-outline-facebook mt-2 w-100"
                  onClick={() => {
                    window.location = "/myob-sign-up";
                  }}
                >
                  <div>
                    <img
                      src={myobLogo}
                      alt="myob"
                      style={{ height: "30px", width: "45px" }}
                    />
                    {""}
                    Sign up with MYOB
                  </div>
                </Button>
              </Col>
            </Row>

            <Row>
              <Col sm={12} className="ps-sm-1">
                <Button
                  variant=""
                  size="sm"
                  className="btn-outline-google-plus mt-2 w-100 me-2"
                  onClick={handleRedirectToOrBack}
                  onMouseOver={() => {
                    setImgSrc(googleLight);
                  }}
                  onMouseOut={() => {
                    setImgSrc(googleDark);
                  }}
                >
                  <img
                    src={imgSrc}
                    className="me-1"
                    alt="google"
                    style={{ height: "30px", width: "30px" }}
                  />{" "}
                  Sign up with Google
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <Row>
            <Col sm={12} className="pe-sm-1">
              <Button
                variant=""
                size="sm"
                className="btn-outline-google-plus mt-2 w-100 me-2"
                onClick={handleRedirectToOrBack}
                onMouseOver={() => {
                  setImgSrc(googleLight);
                }}
                onMouseOut={() => {
                  setImgSrc(googleDark);
                }}
              >
                <img
                  src={imgSrc}
                  className="me-1"
                  alt="google"
                  style={{ height: "30px", width: "30px" }}
                />{" "}
                Sign up with Google
              </Button>
            </Col>
          </Row>
        )
      ) : (
        <Row>
          <Col sm={6} className="pe-sm-1">
            <Button
              variant=""
              size="sm"
              className="btn-outline-google-plus mt-2 w-100 me-2"
              onClick={handleRedirectToOrBack}
              onMouseOver={() => {
                setImgSrc(googleLight);
              }}
              onMouseOut={() => {
                setImgSrc(googleDark);
              }}
            >
              <img
                src={imgSrc}
                className="me-1"
                alt="google"
                style={{ height: "30px", width: "30px" }}
              />{" "}
              Sign up with Google
            </Button>
          </Col>
          <Col sm={6} className="ps-sm-1">
            <Button
              variant=""
              size="sm"
              className="btn-outline-facebook mt-2 w-100"
              onClick={() => {
                window.location = "/xero-sign-up";
              }}
            >
              <img
                src={xeroLogo}
                className="me-2"
                alt="xero"
                style={{ height: "30px", width: "30px" }}
              />{" "}
              Sign up with Xero
            </Button>
          </Col>
        </Row>
      )}
    </Form.Group>
  );
};

export default SocialAuthButtons;
