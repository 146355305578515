import { Card, Form, ProgressBar, Spinner } from "react-bootstrap";
import classNames from "classnames";
import IconButton from "../common/IconButton";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import Screen1 from "./Screen1";
import Screen2 from "./Screen2";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
// import LocationsTable from "./LocationsTable";
import {
  UpdateBusinessFromOrganisationDetails,
  UpdateLocation,
  UpdateSuppliersCollection,
} from "./organisationDetailsAPI";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { toast } from "react-toastify";
import {
  getActiveUserData,
  getAllLocationsFromStorage,
  getInventoryProgress,
  getSingleInventoryProgress,
  getStepsProgress,
  setActiveUserData,
  setSingleInventoryProgress,
  toSetLocationsToStorage,
} from "../../helpers/store";
import { updateStepProgress } from "../../helpers/stepsProgress";
import { AddLocationForm } from "../OfficeLocationManagement/AddLocationForm";
import { getBusinessDetails } from "../solutions/solutionsAPI";
import OfficeLocationManagement from "../OfficeLocationManagement/OfficeLocationManagement";

function OrganisationDetailsWizardLayout() {
  // Define total questions per section
  const totalQuestionsPerSection = {
    onboarding: 7, // Example values; adjust as needed
    organization: 7,
    reportingPeriod: 4,
    offices: 1,
  };

  const [businessDetails, setBusinessDetails] = useState(null);
  const [inventoryProgress, setInventoryProgress] = useState(null);
  const [allLocations, setAllLocations] = useState([]);
  const [loadingChild, setLoadingChild] = useState(false);

  const [step, setStep] = useState(1); // Manage current step
  const totalSteps = 3;
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [logoUrl, setLogoUrl] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state
  const progress = (step / totalSteps) * 100;

  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const [currentInventoryProgress, setCurrentInventoryProgress] = useState(
    JSON.parse(getInventoryProgress())
  );
  const [locationIsSelected, setLocationIsSelected] = useState(false);

  const [defaultOffice, setDefaultOffice] = useState({
    locationName: "",
    country: "",
    countryCode: "",
    state: "",
    stateCode: "",
    streetAddress: "",
    city: "",
    postcode: "",
    officeId: "",
  });
  const [primaryCategory, setPrimaryCategory] = useState("");
  const [secondaryCategory, setSecondaryCategory] = useState("");

  // Centralized state for section progresses
  const [sectionProgress, setSectionProgress] = useState({
    onboarding: 0, // Initial progress; adjust as needed
    organization: 0,
    reportingPeriod: 0,
    offices: 0,
  });

  const updateInventoryProgressInState = (updatedDetails) => {
    setInventoryProgress((prevDetails) => {
      const newDetails = {
        ...prevDetails,
        ...updatedDetails,
      };
      // Call setSingleInventoryProgress with inventoryid and newDetails
      setSingleInventoryProgress(prevDetails.inventoryid, newDetails);
      return newDetails;
    });
  };

  const handleMarkAsComplete = async (stringId) => {
    setLoadingChild(true);
    await updateStepProgress({
      stringId,
      status: "completed",
      activeUser,
      currentInventoryProgress,
      onUpdateState: false,
      onUpdateInventoryProgress: false,
    });
    setLoadingChild(false);
    navigate("/welcome/new-overview"); // Navigate to the new route
    toast.success(`Details Saved Successfully!`);
  };

  // 2. Define a reusable callback function to update section progress
  const updateSectionProgress = (section, completedQuestions) => {
    const totalQuestions = totalQuestionsPerSection[section];
    const progress = Math.round((completedQuestions / totalQuestions) * 100);
    setSectionProgress((prev) => ({
      ...prev,
      [section]: progress,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const businessData = await getBusinessDetails();
      const inventoryData = await getSingleInventoryProgress(
        +activeUser.inventoryId
      );
      const locations = await getAllLocationsFromStorage();
      setAllLocations(locations);
      setBusinessDetails(businessData);
      setInventoryProgress(inventoryData);
      setLoading(false);
    };

    fetchData();
  }, []);

  const navigate = useNavigate(); // Initialize navigate function

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm();

  // Watch for changes in the form fields
  const watchedPrimaryCategory = watch("primaryCategory");
  const watchedSecondaryCategory = watch("secondaryCategory");

  // Update state when primary or secondary category changes
  useEffect(() => {
    setPrimaryCategory(watchedPrimaryCategory);
    setSecondaryCategory(watchedSecondaryCategory);
  }, [watchedPrimaryCategory, watchedSecondaryCategory]);

  const onSubmitData = async (data) => {
    if (step < totalSteps) {
      setStep(step + 1);
    } else {
      setLoading(true); // Start loading

      let body = {
        abn: data.organisationId,
        primarycategory: data.primaryCategory,
        secondarycategory: data.secondaryCategory,
        logourl: logoUrl,
        lease_spaces: data.leaseSpaces === "Yes" ? true : false,
        manufacture_or_sell_goods:
          data.manufactureOrSellGoods === "Yes" ? true : false,
        remote_employees: data.remoteEmployees === "Yes" ? true : false,
        own_or_lease_vehicles: data.ownOrLeaseVehicles === "Yes" ? true : false,
      };

      try {
        await UpdateBusinessFromOrganisationDetails(body);

        //Update step
        const stringId = "complete-organisation-details";
        await updateStepProgress({
          stringId,
          status: "completed",

          activeUser,
          currentInventoryProgress,
          onUpdateState: false,
          onUpdateInventoryProgress: false,
        });

        navigate("/welcome/new-overview"); // Navigate to the new route
        toast.success(`Details Saved Successfully!`);
      } catch (error) {
        console.error("Error updating business details:", error);

        // Optionally, handle the error (e.g., display a message to the user)
      } finally {
        setLoading(false); // Stop loading
      }
    }
  };

  const onError = (errors) => {
    console.log("Errors:", errors);
  };

  return (
    <Card
      as={Form}
      noValidate
      onSubmit={handleSubmit(onSubmitData, onError)}
      className="theme-wizard mb-5"
    >
      <Card.Header>
        <div className="text-center">
          <h3>Organisation Details</h3>
          <ProgressBar
            now={progress}
            min={0}
            max={100}
            striped
            animated
            variant="info"
          />
        </div>
      </Card.Header>
      <Card.Body className="fw-normal px-md-6 py-4">
        {step === 1 && (
          <Screen1
            register={register}
            errors={errors}
            logoUrl={logoUrl}
            setLogoUrl={setLogoUrl}
          />
        )}
        {step === 2 && <Screen2 register={register} errors={errors} />}

        {step === 3 && (
          <div>
            {/* Instructional text for the user */}
            {/* <div className="mb-4">
              <h5>Get Started by Adding Your First Office</h5>
              <p>
                To set up your business profile, please provide the details for
                your first office location. Accurate information will ensure a
                smooth setup process. Make sure all fields are completed before
                submitting.
              </p>
            </div> */}
            {/* <AddLocationForm
              useCollapse={false}
              newLocation={defaultOffice}
              setNewLocation={setDefaultOffice}
              register={register}
              errors={errors}
              clearErrors={clearErrors}
              setValue={setValue} // Pass setValue to control form values directly
            /> */}
            <OfficeLocationManagement
              inventoryProgress={inventoryProgress}
              allLocations={allLocations}
              setAllLocations={setAllLocations}
              updateInventoryProgressInState={updateInventoryProgressInState}
              reportingPeriod={
                activeUser.selectedMeasurementYear +
                " " +
                activeUser.selectedMeasurementYearType
              }
              handleMarkAsComplete={handleMarkAsComplete}
              loading={loading}
              updateSectionProgress={updateSectionProgress} // Pass the callback
              section="offices" // Pass the section key
              totalQuestions={totalQuestionsPerSection.offices} // Pass total questions
              title="Please add and select atleast one location that will be included in this carbon inventory" // Pass the section
              setLocationIsSelected={setLocationIsSelected}
              primaryCategory={primaryCategory}
              secondaryCategory={secondaryCategory}
            />
          </div>
        )}
        {/* {step === 3 && (
          <LocationsTable
            selectedLocations={selectedLocations}
            setSelectedLocations={setSelectedLocations}
          />
        )} */}
      </Card.Body>
      <Card.Footer className={classNames("px-md-6 bg-light d-flex")}>
        <IconButton
          variant="link"
          icon={faChevronLeft}
          iconAlign="left"
          transform="down-1 shrink-4"
          className={classNames("px-0 fw-semi-bold", {
            "d-none": step === 1,
          })}
          onClick={() => {
            clearErrors();
            setStep(step - 1);
          }}
        >
          Prev
        </IconButton>

        <IconButton
          variant="primary"
          className="ms-auto px-5"
          type="submit"
          icon={loading ? "" : faChevronRight}
          iconAlign="right"
          transform="down-1 shrink-4"
          disabled={loading || (step === 3 && !locationIsSelected)} // Disable the button when loading
        >
          {loading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
            </>
          ) : step === totalSteps ? (
            "Submit"
          ) : (
            "Next"
          )}
        </IconButton>
      </Card.Footer>
    </Card>
  );
}

export default OrganisationDetailsWizardLayout;
