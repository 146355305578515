import React, { useEffect, useState } from "react";
import { Card, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faStream, faCopy } from "@fortawesome/free-solid-svg-icons";
import SoftBadge from "../common/SoftBadge";
import AdvanceTableWrapper from "../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../common/advance-table/AdvanceTableFooter";
import { getFormattedDate, getFormattedTime } from "../../helpers/utils";
import {
  getBusinessReports,
  updateAccessedAt,
  updateStandardReportAccessedAt,
} from "./reportsApi";
import ReportsTableHeader from "./ReportsTableHeader";
import { defaultStyles, FileIcon } from "react-file-icon";
import Flex from "../common/Flex";
import { getActiveUserData } from "../../helpers/store";
import { v4 as uuidv4 } from "uuid";

//sample document
const sampleDocument = {
  accessedAt: "",
  document_name: "Sample-Report",
  document_type: "pdf",
  document_url:
    "https://firebasestorage.googleapis.com/v0/b/netnadav2.appspot.com/o/Sample%20Report.pdf?alt=media&token=0634e33a-fd85-4c5d-b6ff-ef38bb8a6fcf",
  reportStatus: {
    content: "Ready For Download",
    type: "success",
    icon: faCheck,
  },
  report_type: "PREMIUM",
  report_status: "Completed",
  uploadedAt: "04/11/2022",
};

const recentPurchaseTableBadge = {
  Completed: {
    content: "Ready For Download",
    type: "success",
    icon: faCheck,
  },
  InProgress: { content: "In-Progress", type: "warning", icon: faStream },
};

function assignServerUrl() {
  return process.env.REACT_APP_NODE_ENV === "production"
    ? process.env.REACT_APP_APP_ENV !== "Staging"
      ? "https://www.nettie.com.au"
      : "https://www.nettie.com.au"
    : "https://www.nettie.com.au";
}

const ReportsTable = ({ reports, startDate, endDate }) => {
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState(JSON.parse(getActiveUserData()));
  const [showModal, setShowModal] = useState(false);
  const [shareUrl, setShareUrl] = useState("");
  const [copyAlert, setCopyAlert] = useState("");

  function openShareModal(document_url) {
    let myuuid = uuidv4();
    let serverUrl = assignServerUrl();
    console.log("serverUrl", serverUrl);
    setShareUrl(
      `${serverUrl}/share/?url=` + document_url + "&session_id=" + myuuid
    );
    setShowModal(true);
  }

  function storeData(reports) {
    try {
      //set status reports
      for (var i = 0; i < reports.length; i++) {
        const status = reports[i]["report_status"];
        reports[i]["reportStatus"] = recentPurchaseTableBadge[status];
      }

      //format upload date
      for (var j = 0; j < reports.length; j++) {
        const uploadedAt = reports[j]["updated_at"];
        reports[j]["uploadedAt"] = getFormattedDate(uploadedAt);
      }

      //format access date
      for (var j = 0; j < reports.length; j++) {
        const accessedAt = reports[j]["accessed_at"];
        reports[j]["accessedAt"] = accessedAt
          ? getFormattedDate(accessedAt) + " " + getFormattedTime(accessedAt)
          : "";
      }

      reports.splice(0, 0, sampleDocument);

      setData(reports);
    } catch (err) {
      console.error(err.message);
    }
  }

  function handleCopyToClipboard() {
    navigator.clipboard.writeText(shareUrl).then(
      function () {
        setCopyAlert("Link copied");
        setTimeout(() => {
          setCopyAlert("");
        }, 3000);
      },
      function (err) {
        console.error("Could not copy URL: ", err);
      }
    );
  }

  useEffect(() => {
    storeData(reports);
  }, [reports]);

  //update accessedAt everytime the file was downloaded
  async function handleDownload(id, documentName) {
    console.log("handleDownload", id, documentName);

    const body = {
      documentId: id,
      documentName: documentName,
      userName: userData.displayName,
      userEmail: userData.email,
      businesssName: userData.businessName,
      accessedAt: new Date(new Date().toUTCString()),
    };
    await updateAccessedAt(body);

    if (documentName !== "Sample-Report") {
      const response = await getBusinessReports();
      storeData(response);
    }
    return true;
  }

  async function handleStandardReportDownload(id, documentName, taskId) {
    const body = {
      taskId,
      documentId: id,
      documentName,
    };

    await updateStandardReportAccessedAt(body);

    if (documentName !== "Sample-Report") {
      const response = await getBusinessReports();
      storeData(response);
    }
    return true;
  }

  const columns = [
    {
      accessor: "document_type",
      Header: "Document Name",
      headerProps: { className: "text-left" },
      cellProps: {
        className: "fs-0",
      },
      Cell: (rowData) => {
        const {
          id,
          document_type,
          document_name,
          document_url,
          report_status,
          report_type,
          task_id,
        } = rowData.row.original;

        let lowerCaseDocumentType =
          document_type && document_type !== undefined
            ? document_type.toLowerCase()
            : "";

        return (
          <Flex key={id} alignItems="center" justifyContent="center">
            <div className="file-thumbnail">
              <FileIcon
                size={5}
                extension={lowerCaseDocumentType}
                type="image"
                {...defaultStyles[lowerCaseDocumentType]}
              />
            </div>
            {report_status === "InProgress" ? (
              <div className="ms-3 flex-shrink-1 flex-grow-1">
                {document_name}
              </div>
            ) : null}
            {report_status === "Completed" ? (
              <div className="ms-3 flex-shrink-1 flex-grow-1">
                {report_type === "STANDARD" ? (
                  <a
                    href={document_url}
                    target="_blank"
                    onClick={() => {
                      handleStandardReportDownload(id, document_name, task_id);
                    }}
                    rel="noreferrer"
                  >
                    {document_name}
                  </a>
                ) : (
                  <a
                    href={document_url}
                    target="_blank"
                    onClick={() => {
                      handleDownload(id, document_name);
                    }}
                    rel="noreferrer"
                  >
                    {document_name}
                  </a>
                )}
              </div>
            ) : null}
          </Flex>
        );
      },
    },
    {
      accessor: "uploadedAt",
      Header: "Uploaded At",
    },
    {
      accessor: "accessedAt",
      Header: "Last Accessed At",
    },
    {
      accessor: "report_type",
      Header: "Report Type",
    },
    {
      accessor: "reportStatus",
      Header: "Status",

      headerProps: { className: "text-center" },
      cellProps: {
        className: "fs-0",
      },
      Cell: (rowData) => {
        const { reportStatus } = rowData.row.original;
        return (
          <SoftBadge pill bg={reportStatus.type} className="d-block">
            {reportStatus.content}
            <FontAwesomeIcon
              icon={reportStatus.icon}
              transform="shrink-2"
              className="ms-1"
            />
          </SoftBadge>
        );
      },
    },
    {
      Header: "Share",
      headerProps: { className: "text-center" },
      cellProps: {
        className: "text-center",
      },
      Cell: (rowData) => {
        const { document_url } = rowData.row.original;
        return (
          <button
            className="btn btn-primary"
            onClick={() => openShareModal(document_url)}
          >
            Share using AI
          </button>
        );
      },
    },
  ];
  // console.log("DATA REPORT TABLE", data);
  return (
    <>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Copy and paste this link in your browser to get started
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Using this link will start an instance of Nettie - your helpful AI
            bot that can help answer any questions about your report.
          </p>
          <div className="d-flex">
            <input
              type="text"
              value={shareUrl}
              readOnly
              className="form-control mr-2"
            />
            <button className="btn btn-primary" onClick={handleCopyToClipboard}>
              <FontAwesomeIcon icon={faCopy} />
            </button>
          </div>
          {copyAlert && (
            <div className="alert alert-success mt-3" role="alert">
              {copyAlert}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {data ? (
        <AdvanceTableWrapper
          columns={columns}
          data={data}
          selection
          sortable
          pagination
          perPage={7}
          rowCount={data.length}
        >
          <Card>
            <Card.Header>
              <ReportsTableHeader
                startDate={startDate}
                endDate={endDate}
                userData={userData}
                storeData={storeData}
                handleDownload={handleDownload}
                table
              />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="btn-reveal-trigger text-nowrap align-middle"
                tableProps={{
                  size: "lg",
                  className: "fs--1 mb-0 overflow-hidden",
                }}
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTableFooter
                rowCount={data.length}
                table
                rowInfo
                navButtons
              />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      ) : null}
    </>
  );
};

export default ReportsTable;
