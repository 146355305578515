import React, { useEffect, useState } from "react";
import BusinessDetailsWizardInput from "../BusinessDetailWizard/BusinessDetailsWizardInput";
import AddLogo from "./AddLogo";
import { getFirestore, collection, getDocs, query } from "firebase/firestore";
import app from "../../firebase";
import { omit } from "lodash";

function Screen1({ register, errors, setValue, logoUrl, setLogoUrl }) {
  const fireStoreDB = getFirestore(app);
  const [primaryCategories, setPrimaryCategories] = useState([]);
  const [secondaryCategories, setSecondaryCategories] = useState([]);
  const [combinedCategoryFactorsObj, setCombinedCategoryFactorsObj] = useState(
    {}
  );
  const [selectedPrimaryCategory, setSelectedPrimaryCategory] = useState(null);

  async function fetchCategories() {
    const q = query(collection(fireStoreDB, "EmissionsFactors"));
    const querySnapshot = await getDocs(q);
    let primaryCategories = [];
    let combinedCategoryFactorsObj = {};
    querySnapshot.forEach((doc) => {
      primaryCategories.push(doc.id);
      combinedCategoryFactorsObj[doc.id] = doc.data();
    });
    // console.log("Primary Categories:", primaryCategories);
    // console.log("Combined Category Factors:", combinedCategoryFactorsObj);
    setPrimaryCategories(primaryCategories);
    setCombinedCategoryFactorsObj(combinedCategoryFactorsObj);
  }

  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    setSelectedPrimaryCategory(selectedCategory);
    const categories = omit(
      combinedCategoryFactorsObj[selectedCategory],
      "average_emission_factor"
    );
    setSecondaryCategories(Object.keys(categories));
  };
  useEffect(() => {
    fetchCategories();
  }, []);
  return (
    <>
      <h4>Company Profile</h4>

      {/* 1. Organization Logo */}
      <AddLogo logoUrl={logoUrl} setLogoUrl={setLogoUrl} />
      <BusinessDetailsWizardInput
        label="Please enter your business identification number, tax ID, or company registration number."
        name="organisationId"
        placeholder="Business Identification Number (BIN)"
        errors={errors}
        formGroupProps={{ className: "mb-3" }}
        formControlProps={{
          ...register("organisationId", {
            // required: "Organisation name is requred",
          }),
        }}
      />
      {/* 2. Select a broad category */}
      <BusinessDetailsWizardInput
        type="selectPrimaryCategory"
        label="Primary category *"
        name="primaryCategory"
        handleChange={handleCategoryChange}
        placeholder="Please select a category for your business."
        errors={errors}
        options={primaryCategories}
        setValue={setValue}
        formGroupProps={{
          className: "mb-3",
        }}
        formControlProps={{
          ...register("primaryCategory", {
            required: "Select a primary category",
          }),
        }}
      />
      <BusinessDetailsWizardInput
        type="selectSecondaryCategory"
        label="Secondary category *"
        name="secondaryCategory"
        placeholder="Please select a category for your business."
        errors={errors}
        options={secondaryCategories}
        formGroupProps={{
          className: "mb-3",
        }}
        formControlProps={{
          ...register("secondaryCategory", {
            required: "Select a secondary category",
          }),
        }}
      />
    </>
  );
}

export default Screen1;
