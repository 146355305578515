import React from "react";
import { Button, Form, Table, Tabs, Tab, ProgressBar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faCloudUploadAlt,
  faTimes,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { GoogleDriveUploader } from "./GoogleDriveUploader";

export const NonBusinessExpenses = ({
  metadata,
  selectedFiles,
  setSelectedFiles,
  disableInput,
  progress,
  handleSubmit,
}) => {
  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  return (
    <>
      {/* File upload and Google Drive tabs */}
      <Tabs defaultActiveKey="local">
        <Tab
          eventKey="local"
          title="Local Upload (only .jpg/.pdf/.csv/.xlsx/.xls files
                  accepted)"
        >
          <Form.Group controlId="formFile" className="mt-3 mb-3">
            {/* <Form.Label>
                  Upload Your Invoices (only .jpg/.pdf/.csv/.xlsx/.xls files
                  accepted)
                </Form.Label> */}
            <Form.Control
              type="file"
              accept=".jpg,.pdf,.csv,.xlsx,.xls"
              onChange={handleFileChange}
              multiple
              disabled={disableInput}
            />
          </Form.Group>
        </Tab>

        <Tab eventKey="drive" title="Google Drive">
          <GoogleDriveUploader setSelectedFiles={setSelectedFiles} />
        </Tab>
      </Tabs>

      {/* File list */}
      <RenderFileList
        {...{ selectedFiles, setSelectedFiles, progress, disableInput }}
      />

      {/* Submit button */}
      <div className="d-flex justify-content-between mt-4">
        {/* <Button variant="outline-secondary">
              <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
              Back
            </Button> */}
        <div style={{ width: "100px" }} />
        <Button
          variant="primary"
          onClick={(e) => handleSubmit(e)}
          disabled={
            !(selectedFiles.length > 0) ||
            !metadata.category ||
            !metadata.officeId ||
            !metadata.startDate ||
            !metadata.endDate ||
            disableInput
          }
        >
          <FontAwesomeIcon icon={faUpload} className="me-2" />
          Submit
        </Button>
      </div>
    </>
  );
};

const RenderFileList = ({
  selectedFiles,
  setSelectedFiles,
  progress,
  disableInput,
}) => {
  const handleRemoveFile = (fileName) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file.name !== fileName)
    );
  };
  return (
    <Table striped bordered>
      <thead>
        <tr>
          <th>File Name</th>
          <th>Size</th>
          <th>Type</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {selectedFiles.length === 0 ? (
          <tr>
            <td colSpan={4} className="text-center">
              No files selected
            </td>
          </tr>
        ) : (
          selectedFiles.map((file, index) => (
            <tr key={index}>
              <td>
                <div key={index}>
                  <span>{file.name}</span>
                  <ProgressBar now={progress[index]?.progress} />
                </div>
              </td>
              <td>{(file.size / 1024).toFixed(2)} KB</td>
              <td>{file.type}</td>
              {!disableInput && (
                <td>
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => handleRemoveFile(file.name)}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </td>
              )}
            </tr>
          ))
        )}
      </tbody>
    </Table>
  );
};
