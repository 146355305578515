import React, { useEffect, useState } from "react";
import socketClient from "socket.io-client";
import { Card, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SoftBadge from "../../../components/common/SoftBadge";
import { defaultStyles, FileIcon } from "react-file-icon";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../../../components/common/advance-table/AdvanceTableFooter";
import AllInvoiceTableHeader from "./AllInvoiceTableHeader";
import Flex from "../../common/Flex";
import { collection, getDocs, getFirestore, query } from "firebase/firestore";
import app from "../../../firebase";
import {
  clearLoginData,
  getActiveUserData,
  getUserData,
  isImpersonationModeEnabled,
} from "../../../helpers/store";
import {
  fetchDashboardDataFromFirebaseByBusinessId,
  removeUnifiedDateFromStore,
} from "../../../helpers/unifiedDataHelper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getAuthorizationHeaderWithContentType } from "../../../helpers/utils";
import { toast } from "react-toastify";
import moment from "moment";
import InvoiceDetails from "./InvoiceDetails";
import { getOffices } from "../../admin/adminApi";

const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [day, month, year].join("/");
};
const fireStoreDB = getFirestore(app);
const AllInvoiceTable = ({
  data,
  getUploads,
  isLoading,
  isAdmin,
  handleInvoiceRefresh,
}) => {
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const [isEditing, setIsEditing] = useState(false);
  const [editingRowId, setEditingRowId] = useState(null);
  const [updatedData, setUpdatedData] = useState({});
  const [nameId, setNameId] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedBill, setSelectedBill] = useState(null);
  const userBusinessId = data && data.length ? data[0].business_id : null;

  const handleBillClick = (bill) => {
    setSelectedBill(bill);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedBill(null);
  };

  //fetch list of offices for the user
  useEffect(() => {
    let businessOffices =
      JSON.parse(sessionStorage.getItem("businessOffices")) || [];

    if (isAdmin) {
      const fetchOffices = async () => {
        try {
          const officeList = await getOffices();
          businessOffices = officeList[userBusinessId];
          let nameIdDict = {};
          businessOffices.forEach((office) => {
            nameIdDict[office.id] = office.name;
          });
          setNameId(nameIdDict);
        } catch (error) {
          console.error("Error fetching offices:", error);
        }
      };
      fetchOffices();
    } else {
      let nameIdDict = {};
      businessOffices.forEach((office) => {
        let str = office.split(",");
        nameIdDict[str[1]] = str[0];
      });
      setNameId(nameIdDict);
    }
  }, [isAdmin, userBusinessId]);

  const handleUpdatedData = (field, value, document_id) => {
    setUpdatedData((prevData) => ({
      ...prevData,
      [document_id]: {
        ...prevData[document_id],
        [field]:
          field === "consumption" || field === "marketBasedEmissions"
            ? parseFloat(value)
            : value,
      },
    }));
  };

  const toggleEditMode = (rowData) => {
    setIsEditing(true);
    setEditingRowId(rowData.document_id);
    setUpdatedData((prevData) => ({
      ...prevData,
      [rowData.document_id]: {
        startDate: moment(rowData.start_date).toDate(),
        endDate: moment(rowData.end_date).toDate(),
        consumption: rowData.consumption,
        location: rowData.office_id,
        marketBasedEmissions: rowData.market_based_emissions_tonnes,
        approvalStatus: rowData.approval_status,
      },
    }));
  };

  const allColumns = [
    {
      accessor: "document",
      Header: "Bill Name",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "fw-semi-bold",
      },
      Cell: (rowData) => {
        const { document, document_type } = rowData.row.original;
        return (
          <>
            {isAdmin ? (
              <Flex alignItems="center" justifyContent="center">
                <div className="file-thumbnail">
                  <FileIcon
                    size={5}
                    extension={document_type}
                    type="image"
                    {...defaultStyles[document_type]}
                  />
                </div>
                <div
                  className="ms-3 flex-shrink-1 flex-grow-1"
                  onClick={() => handleBillClick(rowData.row.original)}
                  style={{ cursor: "pointer" }}
                >
                  <a href="#" rel="noreferrer">
                    {document.name}
                  </a>
                </div>
              </Flex>
            ) : (
              <Flex alignItems="center" justifyContent="center">
                <div className="file-thumbnail">
                  <FileIcon
                    size={5}
                    extension={document_type}
                    type="image"
                    {...defaultStyles[document_type]}
                  />
                </div>
                <div className="ms-3 flex-shrink-1 flex-grow-1">
                  <a href={document.url} target="_blank" rel="noreferrer">
                    {document.name}
                  </a>
                </div>
              </Flex>
            )}
          </>
        );
      },
    },
    {
      accessor: "category",
      Header: "Bill Type",
      headerProps: { className: "pe-7" },
      cellProps: {
        className: "fs-0",
      },
    },
    {
      accessor: "startDate",
      Header: "Start Date",
      headerProps: { className: "text-center" },
      Cell: ({ row: { original } }) => {
        const startDateString = formatDate(original.start_date);
        return isEditing && editingRowId === original.document_id ? (
          <DatePicker
            portalId="root-portal"
            // selected={updatedData.startDate || new Date(original.start_date)}
            selected={
              updatedData[original.document_id]?.startDate
                ? updatedData[original.document_id].startDate
                : new Date(original.start_date)
            }
            onChange={(date) =>
              handleUpdatedData("startDate", date, original.document_id)
            }
            dateFormat="yyyy-MM-dd"
            className="text-center"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            popperClassName="date-picker-reports"
            placeholderText="Choose a date"
          />
        ) : (
          <div>
            {moment(
              updatedData[original.document_id]?.startDate ||
                original.start_date
            ).format("YYYY-MM-DD")}
          </div>
        );
      },
      sortType: "datetime",
    },
    {
      accessor: "endDate",
      Header: "End Date",
      headerProps: { className: "text-center" },
      Cell: ({ row: { original } }) => {
        const endDateString = formatDate(original.end_date);
        return isEditing && editingRowId === original.document_id ? (
          <DatePicker
            portalId="root-portal"
            // selected={updatedData.endDate || new Date(original.end_date)}
            selected={
              updatedData[original.document_id]?.endDate
                ? updatedData[original.document_id].endDate
                : new Date(original.end_date)
            }
            onChange={(date) =>
              handleUpdatedData("endDate", date, original.document_id)
            }
            dateFormat="yyyy-MM-dd"
            className="text-center"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            popperClassName="date-picker-reports"
            placeholderText="Choose a date"
          />
        ) : (
          <div>
            {moment(
              updatedData[original.document_id]?.endDate || original.end_date
            ).format("YYYY-MM-DD")}
          </div>
        );
      },
      sortType: "datetime",
    },
    {
      accessor: "consumption",
      Header: "Consumption",
      headerProps: { className: "text-center" },
      Cell: (rowData) => {
        const { consumption, category, document_id } = rowData.row.original;
        return category === "Electricity" || category === "Gas" ? (
          isEditing && editingRowId === document_id ? (
            <input
              type="number"
              value={
                updatedData[document_id]?.consumption !== undefined
                  ? updatedData[document_id].consumption
                  : consumption
              }
              className="text-center"
              onChange={(e) => {
                handleUpdatedData("consumption", e.target.value, document_id);
              }}
              autoFocus
            />
          ) : (
            <div className="text-center">
              {consumption}
              {category === "Electricity" ? " kWh" : " GJ"}
            </div>
          )
        ) : (
          <div className="text-center">N/A</div>
        );
      },
    },
    {
      accessor: "location",
      Header: "Location",
      headerProps: { className: "text-center" },
      cellProps: {
        className: "fs-0",
      },
      Cell: (rowData) => {
        const { office_id, document_id } = rowData.row.original;
        return isEditing && editingRowId === document_id ? (
          <select
            value={
              updatedData[document_id]?.location !== undefined
                ? updatedData[document_id].location
                : office_id
            }
            onChange={(e) =>
              handleUpdatedData("location", e.target.value, document_id)
            }
          >
            {Object.keys(nameId).map((key) => (
              <option key={key} value={key}>
                {nameId[key]}
              </option>
            ))}
          </select>
        ) : (
          <div>{nameId[office_id] || "Unknown Location"}</div> // Handle undefined location
        );
      },
    },
    {
      accessor: "market_based_emissions_tonnes",
      Header: "Market Based Emissions (t CO₂e)",
      headerProps: { className: "text-center" },
      cellProps: {
        className: "fs-0",
      },
      Cell: (rowData) => {
        const { market_based_emissions_tonnes, category, document_id } =
          rowData.row.original;
        return category === "Electricity" || category === "Gas" ? (
          isEditing && editingRowId === document_id ? (
            <input
              type="number"
              value={
                updatedData[document_id]?.marketBasedEmissions !== undefined
                  ? updatedData[document_id].marketBasedEmissions
                  : market_based_emissions_tonnes
              }
              className="text-center"
              onChange={(e) =>
                handleUpdatedData(
                  "marketBasedEmissions",
                  e.target.value,
                  document_id
                )
              }
              autoFocus
            />
          ) : (
            <div className="text-center">{market_based_emissions_tonnes}</div>
          )
        ) : (
          <div className="text-center">N/A</div>
        );
      },
    },
    {
      accessor: "approval_status",
      Header: "Approval Status",
      headerProps: { className: "text-center" },
      cellProps: {
        className: "fs-0",
      },
      Cell: (rowData) => {
        const { status, document_id } = rowData.row.original;

        return isEditing &&
          editingRowId === rowData.row.original.document_id ? (
          <select
            value={
              updatedData[rowData.row.original.document_id]?.approvalStatus
                ? updatedData[rowData.row.original.document_id].approvalStatus
                : status.content
            }
            onChange={(e) =>
              handleUpdatedData("approvalStatus", e.target.value, document_id)
            }
          >
            <option value="Success">Approved</option>
            <option value="Pending">Pending</option>
          </select>
        ) : (
          <SoftBadge pill bg={status.type} className="d-block">
            {status.content}
            <FontAwesomeIcon
              icon={status.icon}
              transform="shrink-2"
              className="ms-1"
            />
          </SoftBadge>
        );
      },
    },
    {
      accessor: "updated_at",
      Header: "Last Edited",
      Cell: (rowData) => {
        const { updated_at } = rowData.row.original;
        const updated_at_string = formatDate(updated_at);
        return <div>{updated_at_string}</div>;
      },
      sortType: "datetime",
    },
    {
      accessor: "updated_by",
      Header: "Uploaded By",
      // headerProps: { className: "pe-7" },
    },
    // {
    //   accessor: "",
    //   Header: "Action",
    //   Cell: (rowData) => {
    //     const { category, document_id } = rowData.row.original;
    //     return (
    //       <>
    //         {category === "Electricity" || category === "Gas" ? (
    //           isEditing && editingRowId === document_id ? (
    //             <IconButton
    //               variant="falcon-default"
    //               size="sm"
    //               icon={faSave}
    //               transform="shrink-3"
    //               onClick={() => {
    //                 setIsEditing(false);
    //                 handleUpdate(rowData.row.original);
    //               }}
    //             />
    //           ) : (
    //             <IconButton
    //               variant="falcon-default"
    //               size="sm"
    //               icon={faPencil}
    //               transform="shrink-3"
    //               onClick={() => {
    //                 toggleEditMode(rowData.row.original);
    //               }}
    //             />
    //           )
    //         ) : (
    //           <div></div>
    //         )}
    //       </>
    //     );
    //   },
    // },
  ];

  const adminOnlyColumns = [
    {
      accessor: "document",
      Header: "Bill Name",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "fw-semi-bold",
      },
      Cell: (rowData) => {
        const { document, document_type } = rowData.row.original;
        return (
          <Flex alignItems="center" justifyContent="center">
            <div className="file-thumbnail">
              <FileIcon
                size={5}
                extension={document_type}
                type="image"
                {...defaultStyles[document_type]}
              />
            </div>
            <div
              className="ms-3 flex-shrink-1 flex-grow-1"
              onClick={() => handleBillClick(rowData.row.original)}
              style={{ cursor: "pointer" }}
            >
              <a href="#" rel="noreferrer">
                {document.name}
              </a>
            </div>
          </Flex>
        );
      },
    },
    {
      accessor: "document_id",
      Header: "ID",
      headerProps: { className: "pe-7" },
      cellProps: {
        className: "fs-0",
      },
    },
    {
      accessor: "startDate",
      Header: "Start Date",
      headerProps: { className: "text-center" },
      Cell: ({ row: { original } }) => {
        const startDateString = formatDate(original.start_date);
        return isEditing && editingRowId === original.document_id ? (
          <DatePicker
            portalId="root-portal"
            // selected={updatedData.startDate || new Date(original.start_date)}
            selected={
              updatedData[original.document_id]?.startDate
                ? updatedData[original.document_id].startDate
                : new Date(original.start_date)
            }
            onChange={(date) =>
              handleUpdatedData("startDate", date, original.document_id)
            }
            dateFormat="yyyy-MM-dd"
            className="text-center"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            popperClassName="date-picker-reports"
            placeholderText="Choose a date"
          />
        ) : (
          <div>
            {moment(
              updatedData[original.document_id]?.startDate ||
                original.start_date
            ).format("YYYY-MM-DD")}
          </div>
        );
      },
      sortType: "datetime",
    },
    {
      accessor: "endDate",
      Header: "End Date",
      headerProps: { className: "text-center" },
      Cell: ({ row: { original } }) => {
        const endDateString = formatDate(original.end_date);
        return isEditing && editingRowId === original.document_id ? (
          <DatePicker
            portalId="root-portal"
            // selected={updatedData.endDate || new Date(original.end_date)}
            selected={
              updatedData[original.document_id]?.endDate
                ? updatedData[original.document_id].endDate
                : new Date(original.end_date)
            }
            onChange={(date) =>
              handleUpdatedData("endDate", date, original.document_id)
            }
            dateFormat="yyyy-MM-dd"
            className="text-center"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            popperClassName="date-picker-reports"
            placeholderText="Choose a date"
          />
        ) : (
          <div>
            {moment(
              updatedData[original.document_id]?.endDate || original.end_date
            ).format("YYYY-MM-DD")}
          </div>
        );
      },
      sortType: "datetime",
    },
    {
      accessor: "consumption",
      Header: "Consumption",
      headerProps: { className: "text-center" },
      Cell: (rowData) => {
        const { consumption, category, document_id } = rowData.row.original;
        return category === "Electricity" || category === "Gas" ? (
          isEditing && editingRowId === document_id ? (
            <input
              type="number"
              value={
                updatedData[document_id]?.consumption !== undefined
                  ? updatedData[document_id].consumption
                  : consumption
              }
              className="text-center"
              onChange={(e) => {
                handleUpdatedData("consumption", e.target.value, document_id);
              }}
              autoFocus
            />
          ) : (
            <div className="text-center">
              {consumption}
              {category === "Electricity" ? " kWh" : " GJ"}
            </div>
          )
        ) : (
          <div className="text-center">N/A</div>
        );
      },
    },
    {
      accessor: "location",
      Header: "Location",
      headerProps: { className: "text-center" },
      cellProps: {
        className: "fs-0",
      },
      Cell: (rowData) => {
        const { office_id, document_id } = rowData.row.original;
        return isEditing && editingRowId === document_id ? (
          <select
            value={
              updatedData[document_id]?.location !== undefined
                ? updatedData[document_id].location
                : office_id
            }
            onChange={(e) =>
              handleUpdatedData("location", e.target.value, document_id)
            }
          >
            {Object.keys(nameId).map((key) => (
              <option key={key} value={key}>
                {nameId[key]}
              </option>
            ))}
          </select>
        ) : (
          <div>{nameId[office_id] || "Unknown Location"}</div> // Handle undefined location
        );
      },
    },
    {
      accessor: "approval_status",
      Header: "Approval Status",
      headerProps: { className: "text-center" },
      cellProps: {
        className: "fs-0",
      },
      Cell: (rowData) => {
        const { status, document_id } = rowData.row.original;

        return isEditing &&
          editingRowId === rowData.row.original.document_id ? (
          <select
            value={
              updatedData[rowData.row.original.document_id]?.approvalStatus
                ? updatedData[rowData.row.original.document_id].approvalStatus
                : status.content
            }
            onChange={(e) =>
              handleUpdatedData("approvalStatus", e.target.value, document_id)
            }
          >
            <option value="Success">Approved</option>
            <option value="Pending">Pending</option>
          </select>
        ) : (
          <SoftBadge pill bg={status.type} className="d-block">
            {status.content}
            <FontAwesomeIcon
              icon={status.icon}
              transform="shrink-2"
              className="ms-1"
            />
          </SoftBadge>
        );
      },
    },
    {
      accessor: "updated_at",
      Header: "Last Edited",
      Cell: (rowData) => {
        const { updated_at } = rowData.row.original;
        const updated_at_string = formatDate(updated_at);
        return <div>{updated_at_string}</div>;
      },
      sortType: "datetime",
    },
    {
      accessor: "updated_by",
      Header: "Uploaded By",
      // headerProps: { className: "pe-7" },
    },
  ];

  const columns = isAdmin ? adminOnlyColumns : allColumns;

  const handleUpdate = async (bill) => {
    const userData = JSON.parse(getActiveUserData());
    let updatedBy = userData.displayName;
    if (isImpersonationModeEnabled()) {
      const parentUserData = JSON.parse(getUserData());
      updatedBy = parentUserData.displayName;
    }
    const data = {
      category: bill.category,
      invoiceNumber: updatedData[bill.document_id]?.invoiceNumber
        ? updatedData[bill.document_id].invoiceNumber
        : bill.invoice,
      startDate: updatedData[bill.document_id]?.startDate
        ? moment(updatedData[bill.document_id].startDate).format("YYYY-MM-DD")
        : bill.start_date,
      endDate: updatedData[bill.document_id]?.endDate
        ? moment(updatedData[bill.document_id].endDate).format("YYYY-MM-DD")
        : bill.end_date,
      cost: updatedData[bill.document_id]?.cost
        ? updatedData[bill.document_id].cost
        : bill.cost,
      consumption: updatedData[bill.document_id]?.consumption
        ? updatedData[bill.document_id].consumption
        : bill.consumption,
      marketBasedEmissions: bill.marketBasedEmissions || null,
      billAddress: updatedData[bill.document_id]?.billAddress
        ? updatedData[bill.document_id].billAddress
        : bill.billaddress,
      officeId: updatedData[bill.document_id]?.location
        ? updatedData[bill.document_id].location
        : bill.office_id,
      providerName: updatedData[bill.document_id]?.providerName
        ? updatedData[bill.document_id].providerName
        : bill.provider,
      providerABN: updatedData[bill.document_id]?.providerABN
        ? updatedData[bill.document_id].providerABN
        : bill.abn,
      approvalStatus: updatedData[bill.document_id]?.approvalStatus
        ? updatedData[bill.document_id].approvalStatus
        : bill.status.content,
      updatedBy: updatedBy,
      documentId: bill.document_id,
      customerBusinessId: bill.business_id,
    };
    const response = await fetch(`/api/files/update-file`, {
      method: "PUT",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(data),
      mode: "cors",
    });
    if (response.status === 200) {
      toast.success("Bill updated successfully");
      setIsEditing(false);
      setUpdatedData({
        startDate:
          updatedData.startDate &&
          moment(updatedData.startDate, "YYYY-MM-DD").isValid()
            ? moment(updatedData.startDate, "YYYY-MM-DD").format("YYYY-MM-DD")
            : bill.start_date,
        endDate:
          updatedData.endDate && moment(updatedData.endDate).isValid()
            ? moment(updatedData.endDate).format("YYYY-MM-DD")
            : bill.end_date,
        consumption: "",
        location: "",
        marketBasedEmissions: "",
        approvalStatus: "",
        invoiceNumber: "",
        cost: "",
        billAddress: "",
        providerName: "",
        providerABN: "",
      });

      if (isAdmin) {
        handleInvoiceRefresh();
      } else {
        handleRefresh();
      }
    } else if (response.status === 401) {
      clearLoginData();
    }
  };
  async function updateChartData() {
    await removeUnifiedDateFromStore();
    await fetchDashboardDataFromFirebaseByBusinessId(
      activeUser.businessId,
      new Date(activeUser.currentStartDate),
      new Date(activeUser.currentEndDate)
    );
    //to initiate chart data calculation
    sessionStorage.setItem("dashboardDataCalCompleted", false);
  }
  const checkCSVPresent = async () => {
    try {
      let qry = query(
        collection(
          fireStoreDB,
          "UnifiedData",
          activeUser.businessId,
          "DataLines"
        )
      );
      let csvPresent = false;
      let unifiedDataCalCompleted = false;
      if (qry !== undefined) {
        let querySnapshot = await getDocs(qry);
        if (!querySnapshot.empty) {
          unifiedDataCalCompleted = true;
          querySnapshot.forEach((doc) => {
            if (doc.data().dataSource === "CSV") {
              csvPresent = true;
              return;
            }
          });
        }
      }
      sessionStorage.setItem("csvPresent", csvPresent);
      sessionStorage.setItem(
        "unifiedDataCalCompleted",
        unifiedDataCalCompleted
      );
      return unifiedDataCalCompleted;
    } catch (error) {
      console.log(error);
    }
  };
  async function handleRefresh() {
    //fetch list of files
    await getUploads();
    //check if unified data exists
    const unifiedDataCalCompleted = await checkCSVPresent();
    if (unifiedDataCalCompleted) {
      //trigger chart data calculation
      await updateChartData();
    }
  }
  function connectSocket() {
    var socket = socketClient();
    socket.on("BILLS_CALCULATED", (data) => {
      if (activeUser.businessId !== data.businessId) return;
      console.log("BILLS_CALCULATED for businessId", data.businessId);

      // Refresh the data
      getUploads();
    });
  }
  useEffect(() => {
    connectSocket();
  }, []);
  // const [currentPageDataLength, setCurrentPageDataLength] = useState(0); // Assume data is the initial data you pass to AdvanceTableWrapper
  const noDataMessages = {
    title: "No Invoices Found",
    subtitle: "Please upload an invoice",
  };
  return (
    <>
      {showModal && (
        <InvoiceDetails
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          handleUpdate={handleUpdate}
          handleUpdatedData={handleUpdatedData}
          selectedBill={selectedBill}
          updatedData={updatedData}
          nameId={nameId}
        />
      )}
      <AdvanceTableWrapper
        columns={columns}
        data={data}
        selection
        sortable
        pagination
        perPage={20}
        rowCount={data && data.length ? data.length : 0}
      >
        <Card>
          <Card.Header>
            {!isAdmin && (
              <AllInvoiceTableHeader
                table
                data={data}
                handleRefresh={handleRefresh}
              />
            )}
          </Card.Header>
          <Card.Body className="p-0">
            {!isLoading ? (
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="btn-reveal-trigger text-nowrap align-middle"
                tableProps={{
                  size: "lg",
                  className: "fs--1 mb-0 overflow-hidden",
                }}
                noDataMessage={noDataMessages}
              />
            ) : (
              <div className="text-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}
          </Card.Body>
          <Card.Footer>
            <AdvanceTableFooter
              rowCount={data && data.length ? data.length : 0}
              table
              rowInfo
              navButtons
            />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    </>
  );
};
export default AllInvoiceTable;
