import React from "react";
import { Col, Row } from "react-bootstrap";
import { FaWeightHanging } from "react-icons/fa";
import { MdFactory } from "react-icons/md";
import WasteTopWidget from "./WasteTopWidget";

function WasteTopWidgets({ topWeight, topEmissions }) {
  return (
    <>
      <Row className="mb-4">
        <div
          style={{ display: "flex", gap: 5, paddingLeft: 20, marginBottom: 10 }}
        >
          <FaWeightHanging />
          <h6>Top Waste Categories by Weight</h6>
        </div>

        {topWeight.map((item, index) => (
          <Col key={index}>
            <WasteTopWidget
              title={item.label}
              total={item.value}
              units={"tonnes of Waste"}
            />
          </Col>
        ))}
      </Row>
      <Row className="mb-4">
        <div
          style={{ display: "flex", gap: 5, paddingLeft: 20, marginBottom: 10 }}
        >
          <MdFactory />
          <h6>Top Waste Categories by Emissions</h6>
        </div>

        {topEmissions.map((item, index) => (
          <Col key={index}>
            <WasteTopWidget
              title={item.label}
              total={item.value}
              units={"t CO₂e"}
            />
          </Col>
        ))}
      </Row>
    </>
  );
}

export default WasteTopWidgets;