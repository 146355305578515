import React, { useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { SolutionContext } from "../../../context/Context";
import { solutionReducer } from "../../../reducers/productReducer";

const SolutionProvider = ({ children }) => {
  //console.log("In SolutionProvider");

  const initData = {
    solutions: [],
    favouriteItems: [],
    cartItems: [],
    cartModal: {
      show: false,
      solution: {},
      businessId: "",
      userId: "",
      solutionId: "",
      type: "add",
    },
  };

  const [solutionsState, solutionsDispatch] = useReducer(
    solutionReducer,
    initData
  );
  //console.log(solutionsState);

  const isInShoppingCart = (id) =>
    !!solutionsDispatch.cartItems.find((cartItem) => cartItem.id === id);

  return (
    <SolutionContext.Provider
      value={{
        solutionsState,
        solutionsDispatch,
        isInShoppingCart,
      }}
    >
      {children}
    </SolutionContext.Provider>
  );
};

SolutionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SolutionProvider;
