import WaterOfficeCharts from "./WaterOfficeCharts";
import WaterCategoriesCharts from "./WaterCategoriesCharts";
import WaterTotalWidgets from "./WaterTotalWidgets";
import WasteTopWidgets from "./WasteTopWidgets";

function WaterCard({ data }) {
  // console.log("Waste Data", data);

  function calculateTotals(yearlyWasteData) {
    let total = { emissions: 0, weight: 0 };
    let recycled = { emissions: 0, weight: 0 };
    let generalWaste = { emissions: 0, weight: 0 };
    yearlyWasteData.forEach((item) => {
      // if (item.id === "General waste") {
      //   generalWaste.weight = item.weight;
      //   generalWaste.emissions = item.value;
      // } else if (item.id === "Recycling") {
      //   recycled.weight = item.weight;
      //   recycled.emissions = item.value;
      // }
      total.emissions += item.value;
      total.weight += item.weight;
    });
    return {
      total,
      // recycled,
      // generalWaste,
    };
  }

  const { total } = calculateTotals(data?.yearlyWasteData);

  const sortedPieData = data?.yearlyWasteData?.sort(
    (a, b) => b.value - a.value
  );

  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>
            {" "}
            <h4>Water</h4>
            <p>
              Measuring water usage is important for businesses who are
              analysing their environmental sustainability because it ensures
              efficient resource management, responsible consumption and
              mitigates risks associated with water scarcity. While water usage
              may not represent a large percentage of the companies overall GHG
              emissions, responsible water use is an important consideration for
              organisations who are looking to act sustainably, as well as meet
              compliance with regulations and contributes to overall
              environmental sustainability.
            </p>
          </div>
        </div>
        {/* <p>{generateCalculatorText("waste", textData)}</p> */}
      </div>
      <WaterTotalWidgets
        total={total}
        // recycled={recycled}
        // generalWaste={generalWaste}
      />
      {/* <WasteTopWidgets topEmissions={topEmissions} topWeight={topWeight} /> */}
      {/* <WasteCategoriesCharts
        sortedPieData={sortedPieData}
        monthlyWasteEmissionData={data?.monthlyWasteEmissionData}
        monthlyWasteWeightData={data?.monthlyWasteWeightData}
      /> */}
      {/* // This is the good one */}
      <WaterCategoriesCharts
        sortedPieData={sortedPieData}
        monthlyWasteEmissionData={data?.monthlyWasteEmissionData}
        monthlyWasteWeightData={data?.monthlyWasteWeightData}
      />
      {/* <WasteOfficeCharts
        yearlyWasteDataByOffice={data.yearlyWasteDataByOffice}
        wasteDataLineGraph={data.wasteDataLineGraph}
      /> */}
      <WaterOfficeCharts
        yearlyWasteDataByOffice={data.yearlyWasteDataByOffice}
        wasteDataLineGraph={data.wasteDataLineGraph}
      />
    </>
  );
}

export default WaterCard;
