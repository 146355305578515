import React, { useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import CountUp from "react-countup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar, faPlane, faTree } from "@fortawesome/free-solid-svg-icons";

import bg2 from "../../Assets/img/icons/spot-illustrations/corner-2.png";
import Background from "../common/Background";

const COEFFICIENTS = {
  car: 0.00593,
  plane: 0.6067,
  trees: 0.0476,
};

function EquivalencesWidget({ total }) {
  const [selectedTransport, setSelectedTransport] = useState("car");

  const TRIP_DESCRIPTIONS_WORD = {
    car: "trips",
    plane: "flights",
    trees: "",
  };
  const TRIP_DESCRIPTIONS_TEXT = {
    car: "Sydney to Melbourne",
    plane: "from SYD to LAX",
    trees: "planted Trees",
  };

  const handleIconClick = (transport) => {
    setSelectedTransport(transport);
  };

  const calculateEquivalentTrips = () => {
    const coefficient = COEFFICIENTS[selectedTransport];
    return (total / coefficient).toFixed(0);
  };

  return (
    <Card className="pt-3 pb-3 text-center" style={{ height: "170px" }}>
      <Background image={bg2} className="bg-card" />
      <div>
        <Row className="mb-3">
          <Col
            style={{ cursor: "pointer" }}
            onClick={() => handleIconClick("car")}
          >
            <FontAwesomeIcon
              icon={faCar}
              className={`fs-2 ${
                selectedTransport === "car" ? "text-primary" : "text-600"
              }`}
            />
            <div className="text-600">Car</div>
            <div
              style={{
                width: "50%",
                height: "2px",
                backgroundColor:
                  selectedTransport === "car" ? "#007bff" : "#ddd",
                margin: "2px auto 0 auto",
              }}
            ></div>
          </Col>
          <Col
            style={{ cursor: "pointer" }}
            onClick={() => handleIconClick("plane")}
          >
            <FontAwesomeIcon
              icon={faPlane}
              className={`fs-2 ${
                selectedTransport === "plane" ? "text-primary" : "text-600"
              }`}
            />
            <div className="text-600">Plane</div>
            <div
              style={{
                width: "50%",
                height: "2px",
                backgroundColor:
                  selectedTransport === "plane" ? "#007bff" : "#ddd",
                margin: "2px auto 0 auto",
              }}
            ></div>
          </Col>
          <Col
            style={{ cursor: "pointer" }}
            onClick={() => handleIconClick("trees")}
          >
            <FontAwesomeIcon
              icon={faTree}
              className={`fs-2 ${
                selectedTransport === "trees" ? "text-primary" : "text-600"
              }`}
            />
            <div className="text-600">Trees</div>
            <div
              style={{
                width: "50%",
                height: "2px",
                backgroundColor:
                  selectedTransport === "trees" ? "#007bff" : "#ddd",
                margin: "2px auto 0 auto",
              }}
            ></div>
          </Col>
        </Row>
      </div>
      <div style={{ position: "relative" }}>
        <div>
          <h2 className="mb-1 font-sans-serif">
            <div>
              <CountUp
                start={0}
                end={calculateEquivalentTrips()}
                duration={2.75}
                className="text-800"
                separator=","
                decimals={0}
              />{" "}
              <span
                className="fw-normal text-600"
                style={{ fontSize: "0.60em" }}
              >{` ${TRIP_DESCRIPTIONS_WORD[selectedTransport]}`}</span>
            </div>

            <div className="fw-normal text-600" style={{ fontSize: "0.60em" }}>
              {` ${TRIP_DESCRIPTIONS_TEXT[selectedTransport]}`}
            </div>
          </h2>
        </div>
      </div>
    </Card>
  );
}

export default EquivalencesWidget;
