import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import FlatFileUploader from "../../common/FlatFileUploader";
import { flatFileWorkbook } from "./flatFileConfig";
import { flatfileEventListener } from "./flatFilelistener";

export const BusinessExpenses = ({ metadata, disableInput }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="space-y-4">
      <p
        style={{
          color: "red",
          fontWeight: "bold",
          fontSize: "1.1rem",
          marginTop: "1rem",
        }}
      >
        If you are uploading business expenses, you need to upload a CSV file.
        You can download the template{" "}
        <a
          href="https://docs.google.com/spreadsheets/d/1MRp2S2K_nVC_b6B2Qgw5bkkzxy08i7JRsfOQLBzrhwU/copy"
          target="_blank"
          rel="noreferrer"
        >
          here <FontAwesomeIcon icon={faFileExcel} />
        </a>
        .
      </p>
      <p className="text-sm text-muted-foreground">
        For Business Expenses, we use Flatfile to ensure data accuracy and
        consistency. Click the button below to open the Flatfile importer.
      </p>
      {/* Open Flatfile Importer button */}
      <div style={{ height: "130px" }} />
      <div className="d-flex justify-content-between mt-4">
        <div style={{ width: "100px" }} />
        <Button
          onClick={() => setShowModal(!showModal)}
          disabled={
            !metadata.category ||
            !metadata.officeId ||
            !metadata.startDate ||
            !metadata.endDate ||
            disableInput
          }
        >
          Open Flatfile Importer
        </Button>
      </div>
      {showModal && (
        <FlatFileUploader
          metadata={metadata}
          config={{
            title: "Data Uploader",
            workbook: flatFileWorkbook,
            listener: flatfileEventListener,
            operation: "expenses:submit",
            redirectUrl: "/welcome/data/all-invoices",
          }}
        />
      )}
    </div>
  );
};
