import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
  Button,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { MySolutionsContext } from "../../../context/Context";
import usePagination from "../../../hooks/usePagination";
import Flex from "../../common/Flex";
import { getSelectedSolutions } from "../solutionsAPI";
import SolutionAccordian from "./solutionAccordian";

const SolutionCardTableWithAccordian = () => {
  // console.log("In SolutionCardTableWithAccordian");
  const {
    mySolutionsState: { mySolutions },
    mySolutionsDispatch,
  } = useContext(MySolutionsContext);

  const [sortBy, setSortBy] = useState("category");
  const [isAsc, setIsAsc] = useState(true);
  const [solutionPerPage, setSolutionPerPage] = useState(20);

  // const { productLayout } = useParams();
  // const layout = productLayout.split(/-/)[1];
  // const isList = layout === "list";
  // const isGrid = layout === "grid";
  const getSolutions = async () => {
    const data = await getSelectedSolutions();
    await mySolutionsDispatch({
      type: "FETCH_MY_SOLUTIONS_SUCCESS",
      payload: { data },
    });
  };

  const {
    paginationState: {
      data: paginatedSolutions,
      totalItems,
      itemsPerPage,
      currentPage,
      canNextPage,
      canPreviousPage,
      paginationArray,
      from,
      to,
    },
    nextPage,
    prevPage,
    goToPage,
    setItemsPerPage,
  } = usePagination(mySolutions, solutionPerPage);

  useEffect(() => {
    const get = async () => {
      await getSolutions();
      await mySolutionsDispatch({
        type: "SORT_MY_SOLUTIONS",
        payload: {
          sortBy,
          order: isAsc ? "asc" : "desc",
        },
      });
    };
    get();
  }, [sortBy, isAsc]);

  // console.log(paginatedSolutions);
  return (
    <>
      <Card className="mb-3">
        <Card.Body>
          <Row className="flex-between-center">
            <Col
              sm="auto"
              as={Flex}
              alignItems="center"
              className="mb-2 mb-sm-0"
            >
              <Form.Select
                size="sm"
                value={itemsPerPage}
                onChange={({ target }) => {
                  setItemsPerPage(target.value);
                  setSolutionPerPage(target.value);
                }}
                style={{ maxWidth: "4.875rem" }}
              >
                <option value={2}>2</option>
                <option value={4}>4</option>
                <option value={6}>6</option>
                <option value={20}>20</option>
                <option value={totalItems}>All</option>
              </Form.Select>
              <h6 className="mb-0 ms-2">
                Showing {from}-{to} of {totalItems} Solutions
              </h6>
            </Col>
            {/* <Col sm="auto">
              <Row className="gx-2 align-items-center">
                <Col xs="auto">
                  <Form as={Row} className="gx-2">
                    <Col xs="auto">
                      <small>Sort by:</small>
                    </Col>
                    <Col xs="auto">
                      <InputGroup size="sm">
                        <Form.Select
                          className="pe-5"
                          defaultValue="category"
                          onChange={({ target }) => setSortBy(target.value)}
                        >
                          <option value="category">Category</option>
                        </Form.Select>
                        <InputGroup.Text
                          as={Button}
                          variant="secondary"
                          onClick={() => setIsAsc(!isAsc)}
                        >
                          <FontAwesomeIcon
                            icon={isAsc ? faSortAmountUp : faSortAmountDown}
                          />
                        </InputGroup.Text>
                      </InputGroup>
                    </Col>
                  </Form>
                </Col>
                <Col xs="auto" className="pe-0">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>Product {isList ? "Grid" : "List"}</Tooltip>
                    }
                  >
                    <Link
                      to={`/e-commerce/product/product-${
                        isList ? "grid" : "list"
                      }`}
                      className="text-600 px-1"
                    >
                      <FontAwesomeIcon
                        icon={isList ? faTh : isGrid ? faListUl : null}
                      />
                    </Link>
                  </OverlayTrigger>
                </Col> 
              </Row>
            </Col> */}
          </Row>
        </Card.Body>
      </Card>
      <Card className="mb-3">
        <Card.Body className="p-0 overflow-hidden">
          <Row className="g-0">
            {paginatedSolutions.map((product, index) => (
              // layout === "list" ? (
              //   <SolutionAccordian
              //     product={product}
              //     key={product.id}
              //     index={index}
              //   />
              // ) : (
              //   <ProductGrid
              //     product={product}
              //     key={product.id}
              //     md={6}
              //     lg={4}
              //     index={index}
              //   />
              // )
              <SolutionAccordian
                product={product}
                key={product.id}
                index={index}
              />
            ))}
          </Row>
        </Card.Body>
        <Card.Footer
          className={classNames("d-flex justify-content-center", "border-top")}
        >
          <div>
            <OverlayTrigger placement="top" overlay={<Tooltip>Prev</Tooltip>}>
              <Button
                variant="falcon-default"
                size="sm"
                disabled={!canPreviousPage}
                onClick={prevPage}
                className="me-2"
                trigger="focus"
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </Button>
            </OverlayTrigger>
          </div>

          <ul className="pagination mb-0">
            {paginationArray.map((page) => (
              <li
                key={page}
                className={classNames({ active: currentPage === page })}
              >
                <Button
                  size="sm"
                  variant="falcon-default"
                  className="page me-2"
                  onClick={() => goToPage(page)}
                >
                  {page}
                </Button>
              </li>
            ))}
          </ul>
          <div>
            <OverlayTrigger
              trigger="click"
              placement="top"
              overlay={<Tooltip id="button-tooltip-2">Next</Tooltip>}
            >
              <Button
                variant="falcon-default"
                size="sm"
                disabled={!canNextPage}
                onClick={nextPage}
                trigger="focus"
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </Button>
            </OverlayTrigger>
          </div>
        </Card.Footer>
      </Card>
    </>
  );
};

export default SolutionCardTableWithAccordian;
