import React, { useEffect, useState } from "react";
import AuthCardLayout from "../../../layouts/AuthCardLayout";
import VerificationResult from "../VerificationResult";
import { getAuth, applyActionCode } from "firebase/auth";
import { useLocation } from "react-router-dom";

const VerifiedEmail = () => {
  const [verificationStatus, setVerificationStatus] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const auth = getAuth();
    const query = new URLSearchParams(location.search);
    const oobCode = query.get("oobCode");
    const mode = query.get("mode");

    if (mode === "verifyEmail" && oobCode) {
      applyActionCode(auth, oobCode)
        .then(() => {
          // Email verified successfully
          setVerificationStatus("success");
        })
        .catch((error) => {
          // Handle error
          console.error("Error verifying email:", error);
          setVerificationStatus("error");
        });
    } else {
      // Invalid or missing action code
      setVerificationStatus("invalid");
    }
  }, [location.search]);

  return (
    <AuthCardLayout footer={false}>
      <VerificationResult verificationStatus={verificationStatus} />
    </AuthCardLayout>

  );
};

export default VerifiedEmail;
