import React, { useContext, useState } from "react";
import Flex from "../common/Flex";
import SoftBadge from "../common/SoftBadge";
import { Dropdown, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppContext, { EmissionsBoundariesContext } from "../../context/Context";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addSelectedLocation,
  removeSelectedLocation,
} from "./EmissionsBoundariesApi";

const OfficePills = ({ offices, onRemoveOffice }) => {
  return (
    <div>
      {offices.map((office) => (
        <SoftBadge bg="info" className="me-1 py-2" key={office.id}>
          {office.name}
          <FontAwesomeIcon
            icon={faTimes}
            className="ms-2 cursor-pointer"
            onClick={() => onRemoveOffice(office)}
          />
        </SoftBadge>
      ))}
    </div>
  );
};

const ModalOfficesContent = ({
  id,
  emissionsBoundaryId,
  locationList,
  selectedLocations,
}) => {
  const {
    config: { isRTL },
  } = useContext(AppContext);

  const { emissionsBoundariesDispatch } = useContext(
    EmissionsBoundariesContext
  );

  const handleOfficeChange = (offices) => {
    emissionsBoundariesDispatch({
      type: "UPDATE_EMISSIONS_BOUNDARIES_MODAL_OFFICES",
      payload: { emissionsBoundaryId, id, offices },
    });
  };

  const [selectedOffices, setSelectedOffices] = useState(selectedLocations);
  const [isLoading, setIsLoading] = useState(false);

  const handleRemoveOffice = async (officeToRemove) => {
    setIsLoading(true);

    const updatedOffices = selectedOffices.filter(
      (office) => office.id !== officeToRemove.id
    );
    setSelectedOffices(updatedOffices);

    //update react state
    handleOfficeChange(updatedOffices);

    // update backend
    const response = await removeSelectedLocation(id, officeToRemove.id);

    setIsLoading(false);

    if (response.status === "success") {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };

  const handleAddOffice = async (officeToAdd) => {
    //check if office already exists
    const officeExists = selectedOffices.find(
      (office) => office.id === officeToAdd.id
    );

    if (officeExists) {
      toast.error("Location is already added");
      return;
    }

    setIsLoading(true);

    const updatedOffices = [...selectedOffices, officeToAdd];
    setSelectedOffices(updatedOffices);

    //update react state
    handleOfficeChange(updatedOffices);

    // update backend
    const response = await addSelectedLocation(id, officeToAdd.id);

    setIsLoading(false);

    if (response.status === "success") {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };

  return (
    <Flex>
      {selectedOffices.length === 0 && (
        <>
          <div className="text-muted">No offices selected</div>{" "}
        </>
      )}
      {selectedOffices.length > 0 && (
        <OfficePills
          offices={selectedOffices}
          onRemoveOffice={handleRemoveOffice}
        />
      )}
      <Dropdown drop={isRTL ? "start" : "end"}>
        <Dropdown.Toggle
          variant="secondary"
          size="sm"
          className="px-2 fsp-75 bg-400 border-400 dropdown-caret-none"
        >
          <FontAwesomeIcon icon={faPlus} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <h6 className="dropdown-header py-0 px-3 mb-0">Select Offices</h6>
          <Dropdown.Divider />
          <div className="px-3">
            {locationList.map((location) => (
              <Dropdown.Item
                as="button"
                className={`badge-soft-info rounded-1 mb-2`}
                key={location.id}
                onClick={() => handleAddOffice(location)}
              >
                {location.name}
              </Dropdown.Item>
            ))}
          </div>
          {/* <Dropdown.Divider />
        <div className="px-3">
          <Button
            variant="outline-secondary"
            size="sm"
            className="d-block w-100 border-400"
          >
            Create label
          </Button>
        </div> */}
        </Dropdown.Menu>
      </Dropdown>{" "}
      {isLoading && <Spinner animation="border" variant="primary" />}
      <ToastContainer />
    </Flex>
  );
};

export default ModalOfficesContent;
