import IconButton from "../../../components/common/IconButton";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Col, Form, Row, Button, Modal, Spinner } from "react-bootstrap";
import { getAuthorizationHeaderWithContentType } from "../../../helpers/utils";
import { faPlus, faFilter } from "@fortawesome/free-solid-svg-icons";
import {
  clearLoginData,
  getActiveUserData,
  isDataFrozen,
} from "../../../helpers/store";
import { useNavigate } from "react-router-dom";
import {
  fetchDashboardDataFromFirebaseByBusinessId,
  removeUnifiedDateFromStore,
} from "../../../helpers/unifiedDataHelper";
import FrozenDataModal from "../../utilities/FrozenDataModal";

const AllInvoiceTableHeader = ({ selectedRowIds, data, handleRefresh }) => {
  const navigate = useNavigate();
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const [showDeleteQuestionModal, setShowDeleteQuestionModal] = useState(false);
  const [showDeleteLoadingModal, setShowDeleteLoadingModal] = useState(false);
  const [showFrozenDataModal, setShowFrozenDataModal] = useState(false);

  const handleDelete = async () => {
    setShowDeleteQuestionModal(false);
    setShowDeleteLoadingModal(true);
    // console.log("Delete", data, selectedRowIds);
    const selectedRows = Object.keys(selectedRowIds);
    let selectedFiles = [];
    for (let i = 0; i < selectedRows.length; i++) {
      const index = parseInt(selectedRows[i]);
      const documentId = data[index].document_id;
      const documentName = data[index].document_name;
      const documentType = data[index].category;
      selectedFiles.push({ documentId, documentName, documentType });
    }
    // console.log("Delete", selectedFiles);
    try {
      const response = await fetch("/api/files/delete-selected-files", {
        method: "DELETE",
        headers: getAuthorizationHeaderWithContentType(),
        body: JSON.stringify({ selectedFiles }),
        mode: "cors",
      });

      if (response.status === 401) {
        clearLoginData();
      } else if (response.status !== 200) {
        setShowDeleteLoadingModal(false);
        toast.error("Failed to delete files.");
      } else {
        setShowDeleteLoadingModal(false);
        toast.success("Successfully deleted selected files.");
        //remove unified data from store
        await removeUnifiedDateFromStore();
        //fetch unified data from firebase
        await fetchDashboardDataFromFirebaseByBusinessId(
          activeUser.businessId,
          activeUser.currentStartDate,
          activeUser.currentEndDate
        );
        //to initiate chart data calculation
        sessionStorage.setItem("dashboardDataCalCompleted", false);
        await handleRefresh();
      }
    } catch (err) {
      console.error(err.message);
      toast.error("Error deleting selected files.");
    }
  };

  const handleNewFile = () => {
    if (isDataFrozen()) {
      setShowFrozenDataModal(true);
      return;
    }
    navigate("/welcome/data/file-uploader");
  };

  const handleDeleteFile = () => {
    if (isDataFrozen()) {
      setShowFrozenDataModal(true);
      return;
    }
    setShowDeleteQuestionModal(true);
  };

  return (
    <>
      {showFrozenDataModal && (
        <FrozenDataModal
          show={showFrozenDataModal}
          onHide={() => {
            setShowFrozenDataModal(false);
          }}
        />
      )}
      {showDeleteQuestionModal && (
        <DeleteQuestionModal
          showDeleteQuestionModal={showDeleteQuestionModal}
          setShowDeleteQuestionModal={setShowDeleteQuestionModal}
          handleDelete={handleDelete}
        />
      )}
      {showDeleteLoadingModal && <DeleteLoadingModal />}
      <Row className="flex-between-center">
        <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
          <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
            Upload and view all expenses
          </h5>
        </Col>
        <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
          {Object.keys(selectedRowIds).length > 0 ? (
            <div className="d-flex">
              {/* <Form.Select
              size="sm"
              aria-label="Bulk actions"
              name="actions"
              value={action}
              onChange={(e) => {
                setAction(e.target.value);
              }}
            >
              <option value="">Select Action</option>
              <option value="refund">Refund</option>
              <option value="delete">Delete</option>
              <option value="archive">Archive</option>
            </Form.Select> */}
              <h5 className="fs-0 mb-0 text-nowrap py-2">
                {Object.keys(selectedRowIds).length >= 0
                  ? "You have selected " +
                    Object.keys(selectedRowIds).length +
                    " file(s)"
                  : "Selection Example"}
              </h5>
              <Button
                type="button"
                variant="falcon-default"
                size="sm"
                className="ms-2"
                onClick={() => {
                  handleDeleteFile();
                }}
              >
                Delete
              </Button>
            </div>
          ) : (
            <div id="orders-actions">
              <IconButton
                variant="falcon-default"
                size="sm"
                icon={faPlus}
                transform="shrink-3"
                onClick={() => {
                  handleNewFile();
                }}
              >
                <span className="d-none d-sm-inline-block ms-1">New</span>
              </IconButton>
              <IconButton
                variant="falcon-default"
                size="sm"
                icon={faFilter}
                transform="shrink-3"
                className="mx-2"
              >
                <span className="d-none d-sm-inline-block ms-1">Filter</span>
              </IconButton>
              <IconButton
                variant="falcon-default"
                size="sm"
                icon={faExternalLinkAlt}
                transform="shrink-3"
              >
                <span className="d-none d-sm-inline-block ms-1">Export</span>
              </IconButton>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

//Ask user to confirm before deleting
const DeleteQuestionModal = ({
  showDeleteQuestionModal,
  setShowDeleteQuestionModal,
  handleDelete,
}) => {
  return (
    <Modal
      show={showDeleteQuestionModal}
      onHide={() => setShowDeleteQuestionModal(false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">Delete Selected Files</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete the selected files?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={() => setShowDeleteQuestionModal(false)}
        >
          Cancel
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

//Show loading modal while deleting
const DeleteLoadingModal = () => {
  return (
    <Modal show={true} centered>
      <Modal.Body>
        <div className="text-center">
          <Spinner animation="border" variant="primary" />
          <p className="mt-2">Deleting selected files...</p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

AllInvoiceTableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
};

export default AllInvoiceTableHeader;
