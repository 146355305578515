import { clearLoginData } from "../../../helpers/store";
import { getAuthorizationHeaderWithContentType } from "../../../helpers/utils";

export const getWaterDataByEventId = async (id) => {
  try {
    const response = await fetch(
      "/api/events/get-water-data-by-event-id/" + id,
      {
        method: "GET",
        headers: getAuthorizationHeaderWithContentType(),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      console.log(jsonData);
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const updateWaterDataById = async (id, body) => {
  try {
    const response = await fetch("/api/events/update-water-data-by-id/" + id, {
      method: "PUT",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 200) {
      return;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};
