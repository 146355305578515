import React from "react";
import { Button, Nav } from "react-bootstrap";

const NetNadaHelp = () => {
  return (
    <Nav.Item as="li">
      <Nav.Link href="https://www.netnada.com.au/help" target="_blank">
        <Button
          variant="outline-primary"
          className="rounded-pill me-0 mb-0"
          size="sm"
        >
          Tutorials
        </Button>
      </Nav.Link>
    </Nav.Item>
  );
};

export default NetNadaHelp;
