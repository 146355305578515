import React from "react";
import { Form } from "react-bootstrap";

const DISPLAY_ITEMS = {
  municipal: "municipal",
  "construction and demolition": "constructionAndDemolition",
  "commercial and industrial": "commercialAndIndustrial",
  recycling: "recycling",
  paper: "paper",
  "sanitary and nappies": "sanitaryAndNappies",
  "green and organic": "greenAndOrganic",
};

const SubmittedWasteForm = ({ data, type }) => {
  return (
    <Form>
      <Form.Group className="mb-3">
        <Form.Label>Number of Standard (240L) Wheelie Bins</Form.Label>
        <Form.Control
          type="number"
          name="numberOfWheelieBins"
          placeholder="0"
          value={data[DISPLAY_ITEMS[type]].numberOfWheelieBins}
          disabled
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>How manys days between collections?</Form.Label>
        <Form.Control
          type="number"
          name="daysBetweenCollection"
          placeholder="14"
          value={data[DISPLAY_ITEMS[type]].daysBetweenCollection}
          disabled
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>How full are they when collected (%)</Form.Label>
        <Form.Control
          type="number"
          name="fullnessOfBins"
          placeholder="100"
          value={data[DISPLAY_ITEMS[type]].fullnessOfBins}
          disabled
        />
      </Form.Group>

      {type !== "green and organic" && (
        <>
          <Form.Group className="mb-3">
            <Form.Label>Number of collections per year</Form.Label>
            <Form.Control
              type="number"
              name="numberOfCollections"
              value={parseInt(data[DISPLAY_ITEMS[type]].numberOfCollections)}
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Number of full (240L) bins of {type} waste</Form.Label>
            <Form.Control
              type="number"
              name="numberOfBins"
              value={parseFloat(data[DISPLAY_ITEMS[type]].numberOfBins).toFixed(
                1
              )}
              placeholder="-"
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Volume of total collections as m³</Form.Label>
            <Form.Control
              type="number"
              name="volumeOfCollections"
              value={parseFloat(
                data[DISPLAY_ITEMS[type]].volumeOfCollections
              ).toFixed(1)}
              placeholder="-"
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Total weight of waste (as T)</Form.Label>
            <Form.Control
              type="number"
              name="totalWeightOfWaste"
              value={parseFloat(
                data[DISPLAY_ITEMS[type]].totalWeightOfWaste
              ).toFixed(2)}
              placeholder="-"
              disabled
            />
          </Form.Group>
        </>
      )}
      {type === "green and organic" && (
        <>
          <Form.Group className="mb-3">
            <Form.Label>Number of collections per year</Form.Label>
            <Form.Control
              type="number"
              name="numberOfCollections"
              value={parseInt(data[DISPLAY_ITEMS[type]].numberOfCollections)}
              placeholder="-"
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Number of full (240L) bins of {type} waste</Form.Label>
            <Form.Control
              type="number"
              name="numberOfBins"
              value={parseFloat(data[DISPLAY_ITEMS[type]].numberOfBins).toFixed(
                1
              )}
              placeholder="-"
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Volume of total collections as m³</Form.Label>
            <Form.Control
              type="number"
              name="volumeOfCollections"
              value={parseFloat(
                data[DISPLAY_ITEMS[type]].volumeOfCollections
              ).toFixed(1)}
              placeholder="-"
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Total weight of waste (as T) of Wood</Form.Label>
            <Form.Control
              type="number"
              name="totalWeightOfWaste"
              value={parseFloat(
                data[DISPLAY_ITEMS[type]].totalWeightOfWasteWood
              ).toFixed(2)}
              placeholder="-"
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Total weight of waste (as T) of Garden</Form.Label>
            <Form.Control
              type="number"
              name="totalWeightOfWaste"
              value={parseFloat(
                data[DISPLAY_ITEMS[type]].totalWeightOfWasteGarden
              ).toFixed(2)}
              placeholder="-"
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Total weight of waste (as T) of Food</Form.Label>
            <Form.Control
              type="number"
              name="totalWeightOfWaste"
              value={parseFloat(
                data[DISPLAY_ITEMS[type]].totalWeightOfWasteFood
              ).toFixed(2)}
              placeholder="-"
              disabled
            />
          </Form.Group>
        </>
      )}
    </Form>
  );
};

export default SubmittedWasteForm;
