import React, { useContext } from "react";
import { Card, Form, Nav, OverlayTrigger, Popover } from "react-bootstrap";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import {
  faUser,
  faCloudArrowUp,
  faComments,
  faStar,
  faChevronRight,
  faChevronLeft,
  faClipboardQuestion,
} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../common/IconButton";
import AppContext, { TransparencyContext } from "../../context/Context";
import Step1TransparencyHub from "./Step1TransparencyHub";
import Step3TransparencyHub from "./Step3TransparencyHub";
import Step4TransparencyHub from "./Step4TransparencyHub";
import Step5TransparencyHub from "./Step5TransparencyHub";
import Step2TransparencyHub from "./Step2TransparencyHub";

const TransparencyWizardLayout = ({ variant, validation, progressBar }) => {
  const { isRTL } = useContext(AppContext);
  const {
    step,
    maxStep,
    nextStep,
    prevStep,
    handleNavs,
    isButtonDisabled,
    hasFiles,
  } = useContext(TransparencyContext);

  const {
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm();

  // function checkIfAnyEntryHasFiles(assessmentData) {
  //   if (!assessmentData) return false;
  //   return assessmentData.some(
  //     (assessment) => assessment.files && assessment.files.length > 0
  //   );
  // }
  // const hasFiles = checkIfAnyEntryHasFiles(assessmentData);

  const navItems = [
    {
      icon: faUser,
      label: "Register Account",
    },
    {
      icon: faCloudArrowUp,
      label: "Submit Documents",
    },
    {
      icon: faClipboardQuestion,
      label: "Form",
    },
    {
      icon: faComments,
      label: "Review Session",
    },
    {
      icon: faStar,
      label: "Receive Score",
    },
  ];

  const onSubmitData = (data) => {
    // nextStep();
  };
  const onError = () => {
    if (!validation) {
      clearErrors();
      nextStep();
    }
  };

  return (
    <>
      <Card
        as={Form}
        noValidate
        onSubmit={handleSubmit(onSubmitData, onError)}
        className="theme-wizard mb-5"
      >
        <Card.Header
          className={classNames("bg-light", {
            "px-4 py-3": variant === "pills",
            "pb-2": !variant,
          })}
        >
          <Nav className="justify-content-center" variant={variant}>
            {navItems.map((item, index) => (
              <NavItem
                key={item.label}
                index={index + 1}
                step={step}
                handleNavs={handleNavs}
                icon={item.icon}
                label={item.label}
              />
            ))}
          </Nav>
        </Card.Header>
        <Card.Body className="fw-normal px-md-6 py-4">
          {step === 1 && <Step1TransparencyHub />}
          {step === 2 && <Step2TransparencyHub />}
          {step === 3 && <Step3TransparencyHub />}
          {step === 4 && <Step4TransparencyHub />}
          {step === 5 && <Step5TransparencyHub />}
        </Card.Body>
        <Card.Footer className="px-md-6 bg-light d-flex">
          <IconButton
            variant="link"
            icon={isRTL ? faChevronRight : faChevronLeft}
            iconAlign="left"
            transform="down-1 shrink-4"
            className={classNames("px-0 fw-semi-bold", {
              "d-none": step === 1,
            })}
            onClick={() => {
              prevStep();
            }}
          >
            Prev
          </IconButton>

          <IconButton
            variant="primary"
            // className="ms-auto px-5"
            className={classNames("ms-auto px-5", {
              "d-none": step === 5,
            })}
            icon={isRTL ? faChevronLeft : faChevronRight}
            iconAlign="right"
            transform="down-1 shrink-4"
            disabled={isButtonDisabled(step, maxStep, hasFiles)}
            onClick={() => {
              nextStep();
            }}
          >
            Next
          </IconButton>
        </Card.Footer>
      </Card>
    </>
  );
};

const NavItem = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames("fw-semi-bold", {
          done: step > index,
          active: step === index,
        })}
        onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs--1">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

export default TransparencyWizardLayout;
