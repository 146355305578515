import React, { useState } from "react";
import { Card } from "react-bootstrap";
import HelpAndSupportOverlay from "./HelpAndSupportOverlay";

const HelpAndSupportToggle = () => {
  const [showHelp, setShowHelp] = useState(false);

  const handleToggle = () => {
    setShowHelp(!showHelp);
  };

  return (
    <>
      {/* Conditionally render the toggle button based on showHelp state */}
      {!showHelp && (
        <Card
          className="help-toggle"
          onClick={handleToggle}
          style={toggleButtonStyle}
        >
          <Card.Body className="d-flex justify-content-center align-items-center py-md-2 px-2 py-1">
            <div
              className="bg-primary-subtle position-relative rounded-start"
              style={{ height: "34px", width: "28px" }}
            >
              <div style={iconStyle}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM10 18.5C5.25 18.5 1.5 14.75 1.5 10C1.5 5.25 5.25 1.5 10 1.5C14.75 1.5 18.5 5.25 18.5 10C18.5 14.75 14.75 18.5 10 18.5Z"
                    fill="#2A7BE4"
                  />
                  <path
                    d="M9.99995 4.99995C9.47495 4.99995 8.99995 5.47495 8.99995 5.99995C8.99995 6.52495 9.47495 6.99995 9.99995 6.99995C10.5249 6.99995 11 6.52495 11 5.99995C11 5.47495 10.5249 4.99995 9.99995 4.99995ZM10 8.99995C9.47495 8.99995 9.00005 9.47495 9.00005 10V14C9.00005 14.525 9.47495 15 10 15C10.525 15 11 14.525 11 14V10C11 9.47495 10.525 8.99995 10 8.99995Z"
                    fill="#2A7BE4"
                  />
                </svg>
              </div>
            </div>
            <small className="text-uppercase text-primary fw-bold bg-primary-subtle py-2 pe-2 ps-1 rounded-end">
              Help & Support
            </small>
          </Card.Body>
        </Card>
      )}

      {/* Help & Support Overlay */}
      <HelpAndSupportOverlay show={showHelp} onClose={handleToggle} />
    </>
  );
};

// Button and container styles remain the same
const toggleButtonStyle = {
  position: "fixed",
  top: "50%",
  right: "25px",
  transform: "translateY(-50%) rotate(-90deg)",
  transformOrigin: "center right",
  cursor: "pointer",
  zIndex: 1040, // Make sure this is lower than NavbarTop
  backgroundColor: "#fff", // White background
  borderRadius: "5px 0 0 5px",
  boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)", // Add a subtle shadow
};

const iconStyle = {
  marginRight: "2px",
  marginTop: "5px",
  marginLeft: "5px",
};

export default HelpAndSupportToggle;
