import React from "react";
import ConfirmMailContent from "../../../components/authentication/ConfirmMailContent";
import AuthCardLayout from "../../../layouts/AuthCardLayout";
import { useParams } from "react-router-dom";

const ConfirmMail = () => {
  const { employeeEngagementFormId } = useParams();

  return (
    <AuthCardLayout>
      <div className="text-center">
        <ConfirmMailContent
          layout="card"
          email={sessionStorage.getItem("useremail")}
          titleTag="h3"
          employeeEngagementFormId={employeeEngagementFormId}
        />
      </div>
    </AuthCardLayout>
  );
};

export default ConfirmMail;
