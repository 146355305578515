import React, { useContext, useEffect, useRef, useState } from "react";
import AddAnotherForm from "./AddAnotherForm";
import EmissionsBoundariesModal from "./EmissionsBoundariesModal";
import { DragDropContext } from "react-beautiful-dnd";
import IconButton from "../common/IconButton";
// import is from "is_js";
import { EmissionsBoundariesContext } from "../../context/Context";
import { Button, Spinner } from "react-bootstrap";
import { updateEmissionsBoundary } from "./EmissionsBoundariesApi";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EmissionsBoundariesColumn from "./EmissionsBoundariesColumn";

const EmissionsBoundariesContainer = ({ emissionsBoundariesList }) => {
  const {
    emissionsBoundariesState: { emissionsBoundaries, emissionsBoundaryModal },
    emissionsBoundariesDispatch,
  } = useContext(EmissionsBoundariesContext);

  useEffect(() => {
    if (emissionsBoundariesList) {
      emissionsBoundariesDispatch({
        type: "SET_EMISSIONS_BOUNDARIES",
        payload: emissionsBoundariesList,
      });
    }
  }, [emissionsBoundariesList]);

  const getColumn = (id) => {
    return emissionsBoundaries.find((item) => item.id === Number(id));
  };

  const reorderArray = (array, fromIndex, toIndex) => {
    const newArr = [...array];

    const chosenItem = newArr.splice(fromIndex, 1)[0];
    newArr.splice(toIndex, 0, chosenItem);

    return newArr;
  };

  const move = (source, destination) => {
    const sourceItemsClone = [...getColumn(source.droppableId).items];
    const destItemsClone = [...getColumn(destination.droppableId).items];

    const [removedItem] = sourceItemsClone.splice(source.index, 1);
    destItemsClone.splice(destination.index, 0, removedItem);

    return {
      updatedDestItems: destItemsClone,
      updatedSourceItems: sourceItemsClone,
    };
  };

  const handleDragEnd = async (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    // Track the change in the queue
    const movedCard = getColumn(source.droppableId).items[source.index];

    if (source.droppableId === destination.droppableId) {
      const items = getColumn(source.droppableId).items;
      const column = getColumn(source.droppableId);
      const reorderedItems = reorderArray(
        items,
        source.index,
        destination.index
      );

      emissionsBoundariesDispatch({
        type: "UPDATE_SINGLE_COLUMN",
        payload: { column, reorderedItems },
      });
    } else {
      const sourceColumn = getColumn(source.droppableId);
      const destColumn = getColumn(destination.droppableId);

      const movedItems = move(source, destination);

      emissionsBoundariesDispatch({
        type: "UPDATE_DUAL_COLUMN",
        payload: {
          sourceColumn,
          updatedSourceItems: movedItems.updatedSourceItems,
          destColumn,
          updatedDestItems: movedItems.updatedDestItems,
        },
      });

      // update backend
      const emissionBoundaryId = movedCard.id;
      const toBeEmissionBoundary = destColumn.name;
      const response = await updateEmissionsBoundary(
        emissionBoundaryId,
        toBeEmissionBoundary
      );

      if (response.status === "success") {
        toast.success("Emission boundary updated successfully");
      } else {
        toast.error("Failed to update emission boundary");
      }
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div
        className="scrollbar"
        style={{
          display: "flex",
          justifyContent:
            "space-between" /* or justify-content: space-around; */,
          width: "100%" /* Take the full width of the parent container */,
          padding: "16px" /* Add padding as needed */,
        }}
      >
        {emissionsBoundaries &&
          emissionsBoundaries?.length > 0 &&
          emissionsBoundaries?.map((emissionsBoundary) => (
            <EmissionsBoundariesColumn
              key={emissionsBoundary.id}
              emissionsBoundary={emissionsBoundary}
            />
          ))}
        <EmissionsBoundariesModal show={emissionsBoundaryModal.show} />
      </div>
      <ToastContainer />
    </DragDropContext>
  );
};

export default EmissionsBoundariesContainer;
