import { clearLoginData } from "../../../helpers/store";
import { getAuthorizationHeaderWithContentType } from "../../../helpers/utils";

export const getLocalTransportDataByEventId = async (eventId) => {
  try {
    const response = await fetch(
      `/api/events/get-local-transport-data-by-event-id/${eventId}`,
      {
        headers: getAuthorizationHeaderWithContentType(),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      console.log(jsonData);
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const updateLocalTransportDataById = async (id, body) => {
  try {
    const response = await fetch(
      `/api/events/update-local-transport-data-by-id/${id}`,
      {
        method: "PUT",
        headers: getAuthorizationHeaderWithContentType(),
        body: JSON.stringify(body),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      console.log(jsonData);
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};
