import { useEffect, useState } from "react";
import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
import { Col, Row } from "react-bootstrap";
import { useAuth } from "../../Contexts/AuthContext";
import {
  getActiveUserData,
  getSingleInventoryProgress,
  setActiveUserData,
  setPeriodEmissionsInventoryProgress,
} from "../../helpers/store";

import {
  fetchDashboardDataFromFirebaseByBusinessId,
  removeUnifiedDateFromStore,
} from "../../helpers/unifiedDataHelper";
import YearPeriodData from "./YearPeriodData";
import { updateOrganisationTotalEmissions } from "../org-details/organisationDetailsAPI";
import { fetchOfficeData } from "./GroupedUnifiedDashboardHelper";
import ScopesCard from "./ScopesCard";
import EmissionsByCategoryCard from "./EmissionsByCategoryCard";
import DatalinesWidget from "./DatalinesWidget";
import EquivalencesWidget from "./EquivalencesWidget";
import ScopeWidgetCard2 from "./ScopeWidgetCard2";
import CalculatorsCard from "./CalculatorsCard";
// import DataDateFilter from "../../helpers/DataDateFilter";

const Chartjs = ({ id, business, offices, showUnifiedData }) => {
  const { currentUser } = useAuth();
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const [isLoading, setIsLoading] = useState(false);
  const periodInventoryProgress = getSingleInventoryProgress(
    +activeUser.inventoryId
  );
  const [yearTotalEmissions, setYearTotalEmissions] = useState(
    periodInventoryProgress?.totalEmissions || 0
  );
  const employeeNumber = periodInventoryProgress?.employeeNumber;
  const revenue = periodInventoryProgress?.revenue;

  useEffect(() => {
    const analytics = getAnalytics();
    setUserId(analytics, currentUser.uid);
    setUserProperties(analytics, { businessId: currentUser.businessId });
    logEvent(analytics, "page_view", {
      page_title: "Dashboard",
    });
  }, [currentUser, id, business]);

  const [dashboardDataCalCompleted, setDashboardDataCalCompleted] = useState(
    JSON.parse(sessionStorage.getItem("dashboardDataCalCompleted") || false)
  );
  const [selectedDates, setSelectedDates] = useState({
    startDate: new Date(activeUser.currentStartDate),
    endDate: new Date(activeUser.currentEndDate),
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const onChange = async (dates) => {
    const [start, end] = dates;

    await removeUnifiedDateFromStore();
    if (start && end) {
      setIsLoading(true);
      await fetchDashboardDataFromFirebaseByBusinessId(
        activeUser.businessId,
        start,
        end
      );

      setIsLoading(false);
    }

    setSelectedDates({
      startDate: start,
      endDate: end,
    });

    sessionStorage.setItem("dashboardDataCalCompleted", false);
    setDashboardDataCalCompleted(false);
  };

  const setData = async () => {
    const periodStart = new Date(activeUser.dateRangeStart);
    const periodEnd = new Date(activeUser.dateRangeEnd);

    if (
      selectedDates.startDate.getTime() !== periodStart.getTime() ||
      selectedDates.endDate.getTime() !== periodEnd.getTime()
    ) {
      // console.log("Refetching data");
      await onChange([periodStart, periodEnd]);
    }

    if (selectedDates.startDate !== null && selectedDates.endDate !== null) {
      // debugger;
      // Call getChartData whenever selectedDates change
      if (
        selectedDates.startDate &&
        selectedDates.endDate &&
        dashboardDataCalCompleted === false // Only fetch data if not completed before
      ) {
        await getChartData(periodStart, periodEnd);
      }

      //update user data in session storage
      let userData = JSON.parse(getActiveUserData());
      userData.currentStartDate = selectedDates.startDate;
      userData.currentEndDate = selectedDates.endDate;
      setActiveUserData(JSON.stringify(userData));

      setStartDate(selectedDates.startDate);
      setEndDate(selectedDates.endDate);
    }
  };

  useEffect(() => {
    setData();
  }, [selectedDates]);

  const [chartData, setChartData] = useState(null);

  async function getChartData(dateStart, dateEnd) {
    // console.log("inside get Chart Data");
    setIsLoading(true);
    const businessId = activeUser.businessId;
    const csvPresent = JSON.parse(sessionStorage.getItem("csvPresent"));

    const dataSource = activeUser.authProvider
      ? activeUser.authProvider
      : csvPresent
      ? "CSV"
      : "";

    if (!businessId || !selectedDates || !offices || !showUnifiedData) {
      if (!showUnifiedData) {
        // console.log("showUnifiedData is, ", showUnifiedData);
      } else {
        console.log("missing data", {
          businessId,
          selectedDates,
          offices,
        });
      }
      //await removeUnifiedDateFromStore();
      setIsLoading(false);
      return;
    }

    const updatedOffices =
      offices.length > 1
        ? [...offices, "all"]
        : offices.length === 1 && ["all"]; // Create a new array to avoid mutating offices array

    const fetchPromises = updatedOffices.map(async (officeId) => {
      const { officeData, officeDashboardData } = await fetchOfficeData(
        officeId,
        dateStart,
        dateEnd
      );
      // console.log("officeDashboardData", officeDashboardData);
      // sessionStorage.setItem(officeId, JSON.stringify(officeData));
      sessionStorage.setItem(officeId, JSON.stringify(officeDashboardData));
    });

    await Promise.all(fetchPromises);

    sessionStorage.setItem("dashboardDataCalCompleted", true);

    setDashboardDataCalCompleted(true);
    setIsLoading(false);
  }

  const updateEmissionsIfNecessary = async (
    id,
    calculatedTotalEmissions,
    storedTotalEmissions
  ) => {
    // console.log("Calculated EMissions:", calculatedTotalEmissions);
    // console.log("Stored Emissions:", storedTotalEmissions);
    if (storedTotalEmissions !== calculatedTotalEmissions) {
      const data = {
        inventoryId: id,
        totalEmissions: calculatedTotalEmissions,
      };
      // console.log("update Organisation Details");
      await updateOrganisationTotalEmissions(data);

      // yearTotalEmissions = chartData.totalEmissionIntensityFactor.total;
      setYearTotalEmissions(calculatedTotalEmissions);

      setPeriodEmissionsInventoryProgress(
        +activeUser.inventoryId,
        calculatedTotalEmissions
      );
    }
  };

  useEffect(() => {
    if (dashboardDataCalCompleted) {
      const data = JSON.parse(sessionStorage.getItem(id));
      if (data) {
        setChartData(data);
      }
      // console.log("DATA", data);
      updateEmissionsIfNecessary(
        +activeUser.inventoryid,
        +data?.totalEmissions?.total,
        +yearTotalEmissions
      );
    }
  }, [dashboardDataCalCompleted, id]);

  //fetch the total
  // let isStartJanFirst = false;
  // let isEndDecThirtyFirst = false;
  // let isStartJulyFirst = false;
  // let isEndJuneThirty = false;

  // if (selectedDates.startDate && selectedDates.endDate) {
  //   isStartJanFirst =
  //     selectedDates.startDate.getDate() === 1 &&
  //     selectedDates.startDate.getMonth() === 0; // January is 0

  //   isEndDecThirtyFirst =
  //     selectedDates.endDate.getDate() === 31 &&
  //     selectedDates.endDate.getMonth() === 11; // December is 11

  //   isStartJulyFirst =
  //     selectedDates.startDate.getDate() === 1 &&
  //     selectedDates.startDate.getMonth() === 6; // July is 6

  //   isEndJuneThirty =
  //     selectedDates.endDate.getDate() === 30 &&
  //     selectedDates.endDate.getMonth() === 5; // June is 5
  // }

  //FETCH AND STORE DATA FOR THE YEAR PERIOD DATA

  // const calendarType = periodInventoryProgress?.measurementyeartype;
  // console.log("Calendar Type",calendarType)

  // useEffect(() => {
  //   const isCalendarYear =
  //     calendarType === "calendar" && isStartJanFirst && isEndDecThirtyFirst;
  //   const isFinancialYear =
  //     calendarType === "financial" && isStartJulyFirst && isEndJuneThirty;

  //   if (
  //     (isCalendarYear || isFinancialYear) &&
  //     chartData?.totalEmissionIntensityFactor?.total != null
  //   ) {
  //     updateEmissionsIfNecessary(
  //       +periodInventoryProgress.inventoryid,
  //       // +chartData.totalEmissionIntensityFactor.total,
  //       +chartData.totalEmissions.total,
  //       +yearTotalEmissions
  //     );
  //   }
  // }, [
  //   isStartJanFirst,
  //   isEndDecThirtyFirst,
  //   isStartJulyFirst,
  //   isEndJuneThirty,
  //   +chartData?.totalEmissions?.total,
  //   calendarType,
  // ]);
  // console.log(chartData);
  return (
    <>
      <YearPeriodData
        isParentLoading={isLoading}
        // yearEmissions={yearTotalEmissions}
        yearEmissions={chartData?.totalEmissions?.total}
        revenue={revenue}
        employeeNumber={employeeNumber}
      />
      <Row className="g-4 mb-3">
        <Col lg={4}>
          <ScopeWidgetCard2 data={chartData?.totalEmissions} />
        </Col>
        <Col lg={4}>
          <DatalinesWidget data={chartData?.totalEmissions} />
        </Col>
        <Col lg={4}>
          <EquivalencesWidget total={chartData?.totalEmissions?.total} />
        </Col>
      </Row>

      {/* <DataDateFilter
        startDate={selectedDates.startDate}
        endDate={selectedDates.endDate}
        onChange={onChange}
      /> */}
      {activeUser.businessPreference === "waste" && (
        <Row className="gx-3 mb-4">
          <Col lg={12}>
            {chartData && (
              <CalculatorsCard
                data={chartData?.emissionsFromCalculators}
                wasteData={chartData?.emissionsFromWasteCalculator}
                waterData={chartData?.emissionsFromWaterCalculator}
                businessPreference={activeUser?.businessPreference}
              />
            )}
          </Col>
        </Row>
      )}
      <Row className="gx-3 mb-4">
        <Col lg={12}>{chartData && <ScopesCard chartData={chartData} />}</Col>
      </Row>
      <Row className="gx-3 mb-4">
        <Col lg={12}>
          {chartData && (
            <EmissionsByCategoryCard data={chartData?.emissionsByCategory} />
          )}
        </Col>
      </Row>

      {activeUser.businessPreference !== "waste" && (
        <Row className="gx-3 mb-4">
          <Col lg={12}>
            {chartData && (
              <CalculatorsCard
                data={chartData?.emissionsFromCalculators}
                wasteData={chartData?.emissionsFromWasteCalculator}
                waterData={chartData?.emissionsFromWaterCalculator}
                businessPreference={activeUser?.businessPreference}
              />
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

export default Chartjs;
