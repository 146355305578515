import React, { useMemo } from "react";
import { Card, ProgressBar } from "react-bootstrap";
import AdvanceTableWrapper from "../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../common/advance-table/AdvanceTable";

function ResultsTable({ tableData }) {
  const wasteColumns = useMemo(() => {
    const baseColumns = [
      {
        accessor: "sectionName",
        Header: "Section Name",
      },
      {
        accessor: "scoreSection",
        Header: "Score",
        Cell: ({ row }) => {
          const score = row.original.scoreSection; // Assuming this is the current score
          const maxScore = row.original.totalScoreSection; // Assuming this is the maximum possible score
          const percentage = (score / maxScore) * 100; // Calculate the percentage score

          return (
            <ProgressBar
              now={percentage}
              label={`${score}/${maxScore}`}
              variant="success"
              animated
              striped
            />
          );
        },
      },
    ];

    return baseColumns;
  }, [tableData]);

  return (
    <div className="mb-3" style={{ width: "80%" }}>
      <AdvanceTableWrapper
        columns={wasteColumns}
        data={tableData}
        sortable
        pagination
        perPage={10}
        rowCount={tableData?.length}
      >
        <Card>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="btn-reveal-trigger text-nowrap align-middle"
              tableProps={{
                size: "lg",
                className: "fs--1 mb-0 overflow-hidden",
              }}
            />
          </Card.Body>
        </Card>
      </AdvanceTableWrapper>
    </div>
  );
}

export default ResultsTable;
