import React from "react";
import { ResponsivePie } from "@nivo/pie";

const PieChartTransparencyHub = ({ pieData }) => {
  // Calculate the remaining score

  // Custom layer to show the total score in the center
  const CenteredMetric = ({ centerX, centerY }) => (
    <text
      x={centerX}
      y={centerY}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize: "16px",
        fontWeight: "600",
      }}
    >
      {`${pieData[0].value} of ${pieData[1].value + pieData[0].value}`}
    </text>
  );

  return (
    <div style={{ width: 150, height: 150 }}>
      <ResponsivePie
        data={pieData}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
        startAngle={0}
        colors={({ data }) => data.color}
        sortByValue={true}
        innerRadius={0.8}
        // borderWidth={3}
        // borderColor={{
        //   from: "color",
        //   modifiers: [["darker", 0.5]],
        // }}
        enableArcLinkLabels={false}
        enableArcLabels={false}
        tooltip={() => null}
        transitionMode="startAngle"
        layers={[
          "arcs",
          "arcLabels",
          "arcLinkLabels",
          "legends",
          CenteredMetric,
        ]}
      />
    </div>
  );
};

export default PieChartTransparencyHub;
