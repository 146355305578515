import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Card, Button, Form, Row, Col, FloatingLabel } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCloudUploadAlt,
  faEllipsisH,
  faUserTag,
  faZap,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
import { useAuth } from "../../../Contexts/AuthContext";
import {
  getActiveUserData,
  getSelectedLocationsFromStorage,
} from "../../../helpers/store";
import { formatDateToYYYYMMDD } from "../../../helpers/utils";
import { uploadFile } from "../filesApi";
import { storeFiles } from "../../../helpers/fileUploaderHelper";
import { BusinessExpenses } from "./BusinessExpenses";
import { NonBusinessExpenses } from "./NonBusinessExpenses";

const categories = [
  { id: "upload-electricity-bill", name: "Electricity", icon: faZap },
  { id: "upload-gas-bill", name: "Gas", icon: faCloudUploadAlt },
  { id: "upload-all-files", name: "Business Expenses", icon: faBuilding },
  { id: "supplier_information", name: "Supplier Information", icon: faUserTag },
  { id: "other", name: "Other", icon: faEllipsisH },
];

const fileTableType = {
  Electricity: "netnada_v2.ElectricityBills",
  Gas: "netnada_v2.GasBills",
  "Business Expenses": "netnada_v2.CSVImports",
  "Supplier Information": "netnada_v2.CSVImports",
  Other: "netnada_v2.CSVImports",
};

const MIME_TYPE_MAP = {
  "application/pdf": "pdf",
  "application/vnd.ms-excel": "xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
  "text/csv": "csv",
  "image/jpeg": "jpeg",
};

export default function FileUploader() {
  const { currentUser } = useAuth();
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const [uploadType, setUploadType] = useState("upload-electricity-bill");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [startDate, setStartDate] = useState(
    formatDateToYYYYMMDD(new Date(activeUser.dateRangeStart))
  );
  const [endDate, setEndDate] = useState(
    formatDateToYYYYMMDD(new Date(activeUser.dateRangeEnd))
  );
  const [progress, setProgress] = useState([]);
  const [disableInput, setDisableInput] = useState(false);
  const location = useLocation();
  const { stringId } = location.state || {}; // Fallback to an empty object if state is undefined

  useEffect(() => {
    if (stringId) {
      setUploadType(stringId);
    } else {
      setUploadType("upload-electricity-bill");
    }
  }, [stringId]);

  // Reset the form when the category is changed
  const resetForm = () => {
    setSelectedFiles([]);
    setSelectedLocation("");
    setStartDate(formatDateToYYYYMMDD(new Date(activeUser.dateRangeStart)));
    setEndDate(formatDateToYYYYMMDD(new Date(activeUser.dateRangeEnd)));
  };

  // Handle when a new tab is selected
  const handleTabChange = (newUploadType) => {
    setUploadType(newUploadType);
    resetForm(); // Reset the form
    setDisableInput(false); // Enable the input fields when switching tabs
  };

  // Converting the date strings to a format suitable for the input type="date" (YYYY-MM-DD)
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // Returning date in YYYY-MM-DD format
  };

  async function uploadSelectedFile(res) {
    try {
      // Find the selected category based on uploadType
      const selectedCategory = categories.find(
        (category) => category.id === uploadType
      );

      // Fetch the tableName based on category name
      const tableName = fileTableType[selectedCategory?.name] || "Unknown";

      const body = {
        tableName,
        userId: currentUser.uid,
        fileKey: res.key,
        fileName: res.file.name,
        fileURL: res.url,
        fileType: MIME_TYPE_MAP[res.file.type],
        approvalStatus: "Pending",
        category: selectedCategory?.name,
        startDate,
        endDate,
        businessId: activeUser.businessId,
        officeId: selectedLocation,
        inventoryId: activeUser.inventoryId,
        updatedBy: currentUser.uid,
      };

      await uploadFile(body);
    } catch (err) {
      console.log(err);
      console.error(err.message);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    setDisableInput(true);

    if (selectedFiles.length > 0) {
      const progressArray = selectedFiles.map((file, index) => ({
        index,
        name: file.name,
        progress: 0,
      }));
      setProgress(progressArray);

      const uploadedFiles = await storeFiles(
        selectedFiles,
        setProgress,
        currentUser
      );

      for (let i = 0; i < uploadedFiles.length; i++) {
        const result = uploadedFiles[i];
        await uploadSelectedFile(result);
      }

      toast(`File Uploaded Successfully`);

      sessionStorage.setItem("fileUploadCompleted", true);
      window.location = "/welcome/data/all-invoices";
    }

    // Handle the case when no files are selected
  }

  useEffect(() => {
    const selectedLocations = getSelectedLocationsFromStorage();
    setLocations(selectedLocations);
  }, []);

  // Google Analytics
  useEffect(() => {
    const analytics = getAnalytics();
    setUserId(analytics, currentUser.uid);
    setUserProperties(analytics, { businessId: currentUser.businessId });
    logEvent(analytics, "page_view", {
      page_title: "File Uploader",
    });
  }, [currentUser]);

  return (
    <Card className="p-1" style={{ height: "60vh" }}>
      <div className="d-flex">
        {/* Sidebar */}
        <div
          className="p-3 border-end"
          style={{
            width: "260px",
            height: "60vh",
            borderRight: "1px solid #dee2e6", // Ensure a distinct right border
          }}
        >
          <h5>Categories</h5>
          <hr
            style={{
              width: "100%",
              marginTop: "0.5rem",
              marginBottom: "1rem",
              borderTop: "1px solid #dee2e6",
            }}
          />{" "}
          {/* Full-width line */}
          <div className="list-group">
            {categories.map((category) => (
              <Button
                key={category.id}
                variant={
                  uploadType === category.id
                    ? "outline-primary"
                    : "outline-secondary"
                }
                className="mb-2 w-100 text-start border-0 shadow-none"
                onClick={() => handleTabChange(category.id)}
                disabled={disableInput}
              >
                <FontAwesomeIcon icon={category.icon} className="me-2" />
                {category.name}
              </Button>
            ))}
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-fill p-3">
          <h4>Upload Your Invoices</h4>
          <p>
            Provide details for your {uploadType.replace("_", " ")} invoice.
          </p>

          <Form.Group className="mb-3">
            <FloatingLabel
              controlId="floatingSelect"
              label="Choose which location this invoice applies to"
            >
              <Form.Select
                aria-label="Floating label select"
                onChange={(e) => setSelectedLocation(e.target.value)}
                disabled={disableInput}
                value={selectedLocation}
              >
                <option value="">Select Location</option>
                {locations.length > 0
                  ? locations.map((item, i) => (
                      <option key={i} value={item.office_id}>
                        {item.officename}
                      </option>
                    ))
                  : null}
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
          <Row className="mt-0 mb-3">
            <Col style={{ textAlign: "right" }}>
              <p className="text-sm text-muted">
                Can't find your location?{" "}
                <Link
                  to={{
                    pathname: "/welcome/account-details",
                  }}
                  state={{
                    stringId: uploadType, // Passing the uploadType as a state
                    tabKey: 4, // Setting the active tab to locations
                  }} // Passing the parameters here as well
                  className="text-primary hover:underline"
                  onClick={() => {
                    sessionStorage.setItem("FileAddLocation", true);
                  }}
                >
                  Add a new location
                </Link>
              </p>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <FloatingLabel
                controlId="floatingStartDate"
                label="Start of billing period"
              >
                <Form.Control
                  type="date"
                  placeholder="dd/mm/yyyy"
                  value={formatDate(startDate)}
                  onChange={(e) => setStartDate(e.target.value)}
                  min={formatDateToYYYYMMDD(
                    new Date(activeUser.dateRangeStart)
                  )} // Restricting the start date range
                  max={formatDateToYYYYMMDD(new Date(activeUser.dateRangeEnd))} // Limiting the max end date for the start date
                  disabled={disableInput}
                />
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel
                controlId="floatingEndDate"
                label="End of billing period"
              >
                <Form.Control
                  type="date"
                  placeholder="dd/mm/yyyy"
                  value={formatDate(endDate)}
                  onChange={(e) => setEndDate(e.target.value)}
                  min={formatDateToYYYYMMDD(
                    new Date(activeUser.dateRangeStart)
                  )} // End date should not be before start date
                  max={formatDateToYYYYMMDD(new Date(activeUser.dateRangeEnd))} // Restricting the end date range
                  disabled={disableInput}
                />
              </FloatingLabel>
            </Col>
          </Row>

          {uploadType !== "upload-all-files" && (
            <NonBusinessExpenses
              metadata={{
                startDate: startDate,
                endDate: endDate,
                category: uploadType,
                officeId: selectedLocation,
              }}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              disableInput={disableInput}
              progress={progress}
              handleSubmit={handleSubmit}
            />
          )}

          {uploadType === "upload-all-files" && (
            <>
              <BusinessExpenses
                metadata={{
                  startDate: startDate,
                  endDate: endDate,
                  category: "Business Expenses",
                  officeId: selectedLocation,
                  inventoryId: activeUser.inventoryId,
                  approvalStatus: "Pending",
                }}
                disableInput={disableInput}
              />
            </>
          )}
        </div>
      </div>
    </Card>
  );
}
