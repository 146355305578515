import { clearLoginData } from "../../helpers/store";
import { getAuthorizationHeaderWithContentType } from "../../helpers/utils";

export const updateReportingPeriodDetailsFromWizard = async (
  inventoryId,
  body
) => {
  try {
    const response = await fetch(
      `/api/organisationDetails/update-reporting-period-details-from-wizard/${inventoryId}`,
      {
        method: "PUT",
        headers: getAuthorizationHeaderWithContentType(),
        body: JSON.stringify(body),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const updateOfficesDetailsFromWizard = async (body, inventoryId) => {
  try {
    const response = await fetch(
      `/api/organisationDetails/update-offices-details-from-wizard/${inventoryId}`,
      {
        method: "PUT",
        headers: getAuthorizationHeaderWithContentType(),
        body: JSON.stringify(body),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};
