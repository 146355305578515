import { clearLoginData } from "../../helpers/store";
import { getAuthorizationHeaderWithContentType } from "../../helpers/utils";

export const getProjectsList = async () => {
  const response = await fetch("/api/offset/get-projects-list", {
    method: "GET",
    headers: getAuthorizationHeaderWithContentType(),
    mode: "cors",
  });

  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else if (response.status === 401) {
    clearLoginData();
  } else {
    return [];
  }
};

export const addSelectedProjects = async (body) => {
  const response = await fetch("/api/offset/add-selected-projects", {
    method: "POST",
    headers: getAuthorizationHeaderWithContentType(),
    mode: "cors",
    body: JSON.stringify(body),
  });

  if (response.status === 200) {
    return { type: "success", message: "Quote requested successfully" };
  } else if (response.status === 401) {
    clearLoginData();
  } else {
    return { type: "error", message: "Quote request failed" };
  }
};
