import {
  getAuthorizationHeader,
  getAuthorizationHeaderWithContentType,
} from "../../helpers/utils";
import { clearLoginData } from "../../helpers/store";
import { toast } from "react-toastify";

export const saveOfficeLocationChanges = async (locationData) => {
  try {
    const response = await fetch(`/api/users/business-add-locations`, {
      method: "POST",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify({ locationData }),
      mode: "cors",
    });

    if (response.status === 401) {
      clearLoginData();
    } else if (response.status !== 200) {
      toast.error("Failed to save location changes. Please try again.");
      console.error("Failed to save location changes:", response);
      return [];
    } else {
      const jsonData = await response.json();
      return jsonData;
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateOfficesDetailsFromWizard = async (body, inventoryId) => {
  try {
    const response = await fetch(
      `/api/organisationDetails/update-offices-details-from-wizard/${inventoryId}`,
      {
        method: "PUT",
        headers: getAuthorizationHeaderWithContentType(),
        body: JSON.stringify(body),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const excludeUnifiedDataByLocation = async (body) => {
  try {
    const response = await fetch(
      `/api/organisationDetails/exclude-unified-data-by-location`, // Replace with your actual endpoint URL if different
      {
        method: "PUT", // Adjust the HTTP method as needed (e.g., POST, GET, DELETE)
        headers: getAuthorizationHeaderWithContentType(),
        body: JSON.stringify(body),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};
