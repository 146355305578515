import React, { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { BsInfoCircle } from "react-icons/bs";
import FileInput from "./FileInput";
import { toast } from "react-toastify";

function DynamicForm({
  questions = [],
  responses = {},
  onSave,
  sectionNumber,
  supplierId,
  assessmentData = {},
}) {
  const [showModal, setShowModal] = useState(false);
  const [textModal, setTextModal] = useState("");
  const [files, setFiles] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: responses,
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    const updatedData = { ...data, ...files };
    await onSave(updatedData);
    // console.log(updatedData);
    setIsLoading(false);
    toast.success("Successfully saved.");
  };

  const handleOpenModal = (text) => {
    setTextModal(text);
    setShowModal(true);
  };

  const handleAddFile = (fileData, questionId) => {
    console.log("Set files in Dynamic form", fileData);
    setFiles((prevFiles) => ({
      ...prevFiles,
      [questionId]: fileData,
    }));
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {questions?.map((field, index) => (
          <Form.Group className="mb-4" controlId={field.id} key={field.id}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ display: "flex", gap: 5 }}>
                <span style={{ fontWeight: "bold" }}>
                  {sectionNumber}.{index + 1}
                </span>
                <Form.Label style={{ fontSize: "18px", marginRight: "8px" }}>
                  {field.label}
                </Form.Label>
              </div>

              {field.description && (
                <BsInfoCircle
                  style={{
                    color: "#2D7FDE",
                    cursor: "pointer",
                    marginLeft: "8px",
                  }}
                  onClick={() => handleOpenModal(field.description)}
                />
              )}
            </div>
            {field.type === "select" ? (
              <Form.Control
                as="select"
                {...register(field.id, {})}
                isInvalid={!!errors[field.id]}
              >
                {field.options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            ) : field.type === "checkbox" ? (
              field.options.map((option) => (
                <div key={option.value}>
                  <Form.Check
                    type="checkbox"
                    id={option.value}
                    label={option.label}
                    {...register(`${field.id}.${option.value}`, {})}
                  />
                </div>
              ))
            ) : field.type === "file" ? (
              <div>
                <FileInput
                  field={field}
                  fileData={responses?.[field.id]}
                  assessmentData={assessmentData}
                  onAddFile={handleAddFile}
                  supplierId={supplierId}
                />
              </div>
            ) : (
              <Form.Control
                type={field.type}
                placeholder={field.placeholder}
                isInvalid={!!errors[field.id]}
                {...register(field.id, {})}
              />
            )}
            {errors[field.id] && (
              <Form.Control.Feedback type="invalid">
                {errors[field.id].message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        ))}
        <Button type="submit" disabled={isLoading}>
          {isLoading ? (
            <>
              Loading...
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </>
          ) : (
            "Save File"
          )}
        </Button>
      </form>
      {showModal && (
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Question context
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ padding: "15px" }}>
              <p style={{ marginBottom: "10px" }}>{textModal}</p>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
export default DynamicForm;
