import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FloatingLabel,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import WidgetSectionTitle from "../../../widgets/WidgetSectionTitle";
import { useAuth } from "../../../Contexts/AuthContext";
import AdvanceTableWrapper from "../../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../../common/advance-table/AdvanceTableFooter";
import { Card } from "react-bootstrap";
import IconButton from "../../../components/common/IconButton";
import { faAdd, faList, faRemove } from "@fortawesome/free-solid-svg-icons";

import { toast, ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import {
  deleteFoodDataById,
  getFoodDataByEventId,
  saveFoodDraft,
  submitFoodForm,
  updateFoodDataByEventId,
} from "./FoodApi";
const foodEmittedCo2 = require("./FoodCo2Emissions.json");

const FoodForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [showAddFoodDataModal, setShowAddFoodDataModal] = useState(false);
  const [enteredFoodData, setEnteredFoodData] = useState([]);
  const [numberOfPeopleInEvent, setNumberOfPeopleInEvent] = useState(0);
  const [eventId, setEventId] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [show, setShow] = useState(true);
  const [dataViewer, setDataViewer] = useState(false);

  async function getFoodData(id) {
    const foodData = await getFoodDataByEventId(id);
    if (foodData) {
      let formattedFoodData = [];
      for (let i in foodData) {
        formattedFoodData.push({
          id: foodData[i].id,
          occurrence: foodData[i].occurrence,
          foodType: foodData[i].type_of_meal,
          numberOfPeople: foodData[i].num_of_ppl,
          numberOfMeals: foodData[i].num_of_times_meal_occurs,
          totalWeight: foodData[i].total_kg_co2,
          eventId: foodData[i].event_id,
          startDate: foodData[i].startdate,
          endDate: foodData[i].enddate,
          existingData: true,
        });
      }
      setEnteredFoodData(formattedFoodData);
      setDataViewer(true);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    //filter and keep only the ones where existingData is false
    const filteredEnteredFoodData = enteredFoodData.filter(
      (item) => item.existingData === false
    );

    if (filteredEnteredFoodData.length !== 0) {
      filteredEnteredFoodData.forEach((item) => {
        item.startDate = startDate;
        item.endDate = endDate;
      });

      await submitFoodForm(filteredEnteredFoodData, eventId);
      toast(`Food data submitted successfully.`);
      setIsLoading(false);
      navigate("/welcome/dashboard/event-form", {
        state: { id: eventId },
      });
    }

    //update the exisiting data
    const filteredEnteredFoodDataExisting = enteredFoodData.filter(
      (item) => item.existingData === true
    );
    if (filteredEnteredFoodDataExisting.length !== 0) {
      filteredEnteredFoodDataExisting.forEach((item) => {
        item.completionStatus = "Completed";
      });
      await updateFoodDataByEventId(filteredEnteredFoodDataExisting, eventId);
    }

    //await getFoodData(eventId);
    setIsLoading(false);
    location.state.completionStatus = "Completed";
    //to initiate chart data calculation
    sessionStorage.setItem("dashboardDataCalCompleted", false);
    navigate("/welcome/data/climate-active/food-form", {
      state: location.state,
    });
  }

  async function handleSaveDraft(e) {
    e.preventDefault();
    setIsLoading(true);

    //filter and keep only the ones where existingData is false
    const filteredEnteredFoodData = enteredFoodData.filter(
      (item) => item.existingData === false
    );

    if (filteredEnteredFoodData.length !== 0) {
      filteredEnteredFoodData.forEach((item) => {
        item.startDate = startDate;
        item.endDate = endDate;
      });

      await saveFoodDraft(filteredEnteredFoodData, eventId);
      toast(`Food data saved as draft successfully.`);
      // setIsLoading(false);
      // navigate("/welcome/data/climate-active/food-form", {
      //   state: location.state,
      // });
    }
    //update the exisiting data
    const filteredEnteredFoodDataExisting = enteredFoodData.filter(
      (item) => item.existingData === true
    );
    if (filteredEnteredFoodDataExisting.length !== 0) {
      filteredEnteredFoodDataExisting.forEach((item) => {
        item.completionStatus = "InProgress";
      });
      await updateFoodDataByEventId(filteredEnteredFoodDataExisting, eventId);
    }

    //await getFoodData(eventId);
    setIsLoading(false);
    location.state.completionStatus = "InProgress";
    navigate("/welcome/data/climate-active/food-form", {
      state: location.state,
    });
  }

  async function handleAddDraftClick() {
    setShowAddFoodDataModal(true);
  }

  async function handleAddDraftClose() {
    setShowAddFoodDataModal(false);
  }

  async function handleDeleteFoodData(foodDataId) {
    setIsLoading(true);
    setEnteredFoodData((current) =>
      current.filter((item) => item.id !== foodDataId)
    );
    setIsLoading(false);
  }

  async function handleDeleteFoodDataById(foodDataId) {
    setIsLoading(true);
    await deleteFoodDataById(foodDataId);
    await getFoodData(eventId);
    setIsLoading(false);
  }
  async function get(location) {
    //fetch food data from data by id
    if (location.state) {
      const eId = location.state.id;
      //get food data by event id
      await getFoodData(eId);
      setNumberOfPeopleInEvent(location.state.numberOfPeople);
      setEventId(eId);
      setStartDate(new Date(location.state.startDate).toDateString());
      setEndDate(new Date(location.state.endDate).toDateString());
      if (location.state.completionStatus === "Completed") {
        setShow(false);
      }
    }
  }
  useEffect(() => {
    get(location);
  }, [location]);

  const columns = [
    {
      accessor: "id",
      Header: "",
      Cell: (rowData) => {
        return (
          <>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon={faRemove}
              transform="shrink-3"
              onClick={
                dataViewer
                  ? () => handleDeleteFoodDataById(rowData.row.original.id)
                  : () => handleDeleteFoodData(rowData.row.original.id)
              }
            ></IconButton>
          </>
        );
      },
    },
    {
      accessor: "occurrence",
      Header: "Occurrence",
      cellProps: {
        className: "text-center",
      },
    },
    {
      accessor: "foodType",
      Header: "Type of Meal",
      cellProps: {
        className: "text-center",
      },
    },
    {
      accessor: "numberOfPeople",
      Header: "Number of people per meal",
      cellProps: {
        className: "text-center",
      },
    },
    {
      accessor: "numberOfMeals",
      Header: "Number of times meal occurs",
      cellProps: {
        className: "text-center",
      },
    },
    {
      accessor: "totalWeight",
      Header: "Total estimated kg CO2-e per meal",
      Cell: (rowData) => {
        return <>{parseFloat(rowData.row.original.totalWeight).toFixed(2)}</>;
      },
      cellProps: {
        className: "text-center",
      },
    },
  ];

  return (
    <>
      {" "}
      <WidgetSectionTitle
        icon={faList}
        title="Food Form"
        subtitle="Fill your food form here"
        transform="shrink-2"
        className="mb-4 mt-6"
      />
      {showAddFoodDataModal && (
        <AddFoodDataModal
          showAddFoodDataModal={showAddFoodDataModal}
          handleAddDraftClose={handleAddDraftClose}
          enteredFoodData={enteredFoodData}
          setEnteredFoodData={setEnteredFoodData}
          numberOfPeopleInEvent={numberOfPeopleInEvent}
        />
      )}
      <div className="mb-3">
        <AdvanceTableWrapper
          columns={columns}
          data={enteredFoodData}
          sortable
          pagination
          perPage={7}
          rowCount={enteredFoodData.length}
        >
          <Card>
            <Card.Header>
              <Row className="flex-between-center">
                <Col
                  xs={6}
                  sm="auto"
                  className="d-flex align-items-center pe-0"
                >
                  <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                    Food data
                  </h5>
                </Col>
                <Col
                  xs={4}
                  sm="auto"
                  className="d-flex align-items-center pe-0"
                >
                  {isLoading ? (
                    <Spinner
                      animation="border"
                      role="status"
                      size="sm"
                      style={{ color: "#f5803e" }}
                    >
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    <></>
                  )}
                </Col>
                {show ? (
                  <Col xs={4} sm="auto" className="ms-auto text-end ps-0">
                    <div id="orders-actions">
                      <IconButton
                        variant="falcon-default"
                        size="sm"
                        icon={faAdd}
                        style={{ marginRight: "5px" }}
                      >
                        <span
                          className="d-none d-sm-inline-block ms-1"
                          onClick={handleAddDraftClick}
                        >
                          Add Food Data
                        </span>
                      </IconButton>
                    </div>
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="btn-reveal-trigger text-nowrap align-middle"
                tableProps={{
                  size: "lg",
                  className: "fs--1 mb-0 overflow-hidden",
                }}
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTableFooter
                rowCount={enteredFoodData.length}
                table
                rowInfo
                navButtons
              />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </div>
      <br />
      {show && (
        <Row style={{ alignItems: "center", justifyContent: "center" }}>
          <Col sm={2} style={{ padding: "5" }}>
            <Button
              onClick={handleSaveDraft}
              disabled={enteredFoodData.length === 0}
            >
              Save As Draft
            </Button>
          </Col>
          <Col sm={2} style={{ padding: "5" }}>
            <Button
              onClick={handleSubmit}
              disabled={enteredFoodData.length === 0}
            >
              Submit
            </Button>
          </Col>
        </Row>
      )}
      <ToastContainer />
    </>
  );
};

const AddFoodDataModal = ({
  showAddFoodDataModal,
  handleAddDraftClose,
  setEnteredFoodData,
  numberOfPeopleInEvent,
}) => {
  const { currentUser } = useAuth();
  const [occurrence, setOccurrence] = useState("");
  const [foodType, setFoodType] = useState("");
  const [numberOfPeople, setNumberOfPeople] = useState("");
  const [numberOfMeals, setNumberOfMeals] = useState("");
  const [totalWeight, setTotalWeight] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [disableNumberOfPeople, setDisableNumberOfPeople] = useState(false);

  function handleOccurrenceChange(e) {
    setOccurrence(e.target.value);
    if (e.target.value === "Included") {
      setNumberOfPeople(numberOfPeopleInEvent.toString());
      setDisableNumberOfPeople(true);
    }
    if (e.target.value === "Optional") {
      setNumberOfPeople("");
      setDisableNumberOfPeople(false);
    }
  }

  function calculateValues() {
    if (foodType && numberOfPeople && numberOfMeals) {
      setTotalWeight(
        foodEmittedCo2[foodType] *
          parseInt(numberOfPeople) *
          parseInt(numberOfMeals)
      );
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    const request = {
      name: `${currentUser.businessName} Food Form`,
      occurrence: occurrence,
      foodType: foodType,
      numberOfPeople: numberOfPeople,
      numberOfMeals: numberOfMeals,
      totalWeight: totalWeight,
      existingData: false,
    };

    setEnteredFoodData((enteredFoodData) => [...enteredFoodData, request]);

    setIsLoading(false);
    handleAddDraftClose(true);
  }

  useEffect(() => {
    calculateValues();
  }, [occurrence, foodType, numberOfPeople, numberOfMeals]);

  return (
    <div>
      <Modal
        show={showAddFoodDataModal}
        onHide={handleAddDraftClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Food Data
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Occurrence</Form.Label>
                  <FloatingLabel label="Choose meal availability">
                    <Form.Select
                      aria-label="Default select"
                      value={occurrence}
                      onChange={handleOccurrenceChange}
                    >
                      <option value="">Please select</option>
                      <option value="Optional">Optional</option>
                      <option value="Included">Included</option>
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Type of meal</Form.Label>
                  <FloatingLabel label="Choose type of food/drink">
                    <Form.Select
                      aria-label="Default select"
                      value={foodType}
                      onChange={(e) => setFoodType(e.target.value)}
                    >
                      <option value="">Please select</option>
                      <option value="Vegan">Vegan</option>
                      <option value="Vegetarian">Vegetarian</option>
                      <option value="Meat">Meat</option>
                      <option value="Drinks">Drinks</option>
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Number of people per meal</Form.Label>
                  <Form.Control
                    type="text"
                    name="numberOfPeople"
                    value={numberOfPeople}
                    onChange={(e) => setNumberOfPeople(e.target.value)}
                    disabled={disableNumberOfPeople}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Number of times meal occurs</Form.Label>
                  <Form.Control
                    type="text"
                    name="numberOfMeals"
                    value={numberOfMeals}
                    onChange={(e) => setNumberOfMeals(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Total Weight (kg)</Form.Label>
                  <Form.Control
                    type="text"
                    name="totalWeight"
                    value={parseFloat(totalWeight).toFixed(2)}
                    placeholder="-"
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            {isLoading ? (
              <Spinner
                animation="border"
                role="status"
                size="sm"
                style={{ color: "#f5803e" }}
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <></>
            )}
            <Button
              type="submit"
              disabled={
                isLoading ||
                !occurrence ||
                !foodType ||
                !numberOfPeople ||
                !numberOfMeals
              }
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default FoodForm;
