import React from "react";
import { getWhiteLabel } from "../../helpers/store";

const errors = () => {
  return (
    <div className="text-danger" style={{ textAlign: "center" }}>
      <h1>Oops…</h1>
      <p>We are sorry, but an error occurred during the integration process.</p>
      <p>
        The {getWhiteLabel().name} team has been notified and will get in touch
        as soon as possible.
      </p>
      <p>Thank you</p>
    </div>
  );
};

export default errors;
