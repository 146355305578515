import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import Background from "../components/common/Background";
import Section from "../components/common/Section";

import NetnadaBackground from "../Assets/onboardingAssets/Netnada.Background.jpg"; // Background image for the landing page

const OnBoardingCardLayout = ({
  leftSideContent,
  children,
  tooltipText,
  userName,
  showQuestions,
  showGettingStarted,
  showBack,
  skip,
  handleQuestions,
  handleBack,
  handleNext,
  handleSkip,
  isLastQuestion,
  isAnswerSelected,
  isFinishing,
}) => {
  // const [typedText, setTypedText] = useState("");
  // const welcomeText = `Welcome ${userName}`;
  // const journeyText = "Let's start your carbon accounting journey.";
  const [showButton, setShowButton] = useState(false);

  // useEffect(() => {
  //   let currentIndex = 0;
  //   const typingInterval = setInterval(() => {
  //     if (currentIndex < welcomeText.length) {
  //       setTypedText(welcomeText.slice(0, currentIndex + 1));
  //       currentIndex++;
  //     } else if (currentIndex < welcomeText.length + journeyText.length) {
  //       currentIndex++;
  //     } else {
  //       clearInterval(typingInterval);
  //       // setTimeout(() => setShowButton(true), 200);
  //       setShowButton(true);
  //     }
  //   }, 50);

  //   return () => clearInterval(typingInterval);
  // }, [welcomeText, journeyText]);
  return (
    <div
      className="onboarding-container"
      style={{
        position: "relative",
        minHeight: "100vh", // Full screen height
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* Background image across the entire screen */}
      <Background
        image={NetnadaBackground}
        className="bg-cover"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />

      <Section
        fluid
        className="py-0"
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <Row className="g-0 flex-center justify-content-center align-items-center w-100 h-100">
          <Col
            lg={10}
            style={{
              width: "100%",
              maxWidth: "1200px",
              height: "100%",
            }}
          >
            {!showQuestions && !showGettingStarted ? (
              <Card
                className="overflow-hidden z-index-1"
                style={{
                  height: "80vh", // Set a fixed height for the card
                  maxHeight: "80vh", // Prevents from exceeding the viewport height
                  minHeight: "80vh", // Ensures it doesn't shrink
                }}
              >
                <Card.Body className="p-0 h-100">
                  <Row className="h-100 g-0">
                    {/* Left side of the card (Static content) */}
                    <Col
                      lg={5}
                      className="bg-white py-4 d-flex flex-column justify-content-between"
                      style={{
                        height: "100%", // Ensures left side takes full height
                      }}
                    >
                      <div className="px-4">
                        <div className="d-flex align-items-center justify-content-between">
                          {/* Logo at the top-left corner */}
                          {/* <Link
                          className="link-dark font-sans-serif fw-bolder fs-4 d-inline-block"
                          to="/onboarding"
                        >
                          <img
                            src={NetnadaLogo}
                            width="50px"
                            alt="Logo"
                            style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                            }}
                          />
                        </Link> */}

                          {/* Back Button (Visible if not on first question) */}
                          {showBack ? (
                            <button
                              className="btn btn-link"
                              style={{
                                // color: "#F89745",
                                color: isLastQuestion ? "#F89745" : "#005677", // Change color based on last question
                                textDecoration: "none",
                                fontSize: "1.2em",
                                marginLeft: "auto",
                                verticalAlign: "middle",
                              }}
                              onClick={handleBack}
                            >
                              ← Back
                            </button>
                          ) : (
                            <div style={{ height: "40px" }} />
                          )}
                        </div>

                        {/* List of onboarding steps */}
                        <div className="onboarding-steps mt-4">
                          {leftSideContent}
                        </div>
                      </div>

                      {/* Next and Skip Buttons at the bottom */}
                      <div className="px-4">
                        {/* <div>
                                {tooltipText && (
                                  <div
                                    className="d-flex align-items-center"
                                    style={{
                                      // backgroundColor: "#F89745",
                                      // color: "#fff",
                                      padding: "10px",
                                      borderRadius: "8px",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <Info
                                      style={{
                                        color: "#F89745",
                                        marginRight: "10px",
                                      }}
                                      size={30}
                                    />
                                    <span>{tooltipText}</span>
                                  </div>
                                )}
                              </div> */}
                        <p>{tooltipText}</p>
                        <button
                          className="btn"
                          onClick={handleNext}
                          style={{
                            width: "100%",
                            marginBottom: "10px",
                            color: "#fff",
                            backgroundColor: "#005677",
                            backgroundColor: isLastQuestion
                              ? "#F89745"
                              : "#005677", // Change color based on last question
                          }}
                          disabled={!isAnswerSelected}
                        >
                          {isFinishing && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              style={{ marginRight: "5px" }}
                            />
                          )}

                          {isLastQuestion ? "Finish" : "Next"}
                        </button>
                        {skip ? (
                          <button
                            className="btn btn-link"
                            onClick={handleSkip}
                            style={{
                              width: "100%",
                              color: isLastQuestion ? "#F89745" : "#005677", // Change color based on last question
                            }}
                          >
                            Skip for now
                          </button>
                        ) : (
                          <div style={{ height: "40px" }} />
                        )}
                      </div>
                    </Col>

                    {/* Right side of the card (Dynamic content) */}
                    <Col
                      lg={7}
                      className="bg-light"
                      style={{
                        height: "100%", // Ensures right side takes full height
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div className="px-5 py-4">{children}</div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            ) : (
              <Card
                className="overflow-hidden z-index-1"
                style={{
                  height: "80vh", // Set a fixed height for the card
                  maxHeight: "80vh", // Prevents from exceeding the viewport height
                  minHeight: "80vh", // Ensures it doesn't shrink
                }}
              >
                <Card.Body className="p-0 h-100">
                  <Row className="h-100 g-0">
                    {/* Left side of the card (Static content) */}
                    <Col
                      lg={5}
                      className="bg-white py-4 d-flex flex-column justify-content-between"
                      style={{
                        height: "100%", // Ensures left side takes full height
                      }}
                    >
                      <div className="px-4">
                        <div className="d-flex align-items-center justify-content-between">
                          <div style={{ height: "40px" }} />
                        </div>

                        {/* List of onboarding steps */}
                        <div className="onboarding-steps mt-4">
                          {leftSideContent}
                        </div>
                      </div>

                      {/* Next and Skip Buttons at the bottom */}
                      <div className="px-4">
                        <button
                          className="btn"
                          onClick={() => {
                            window.location.href = "/welcome";
                          }}
                          style={{
                            width: "100%",
                            marginBottom: "10px",
                            color: "#fff",
                            backgroundColor: "#F89745", // Custom button color
                          }}
                          // disabled={!isAnswerSelected}
                        >
                          Get Started →
                        </button>

                        <div style={{ height: "40px" }} />
                      </div>
                    </Col>

                    {/* Right side of the card (Dynamic content) */}
                    <Col
                      lg={7}
                      className="bg-light"
                      style={{
                        height: "100%", // Ensures right side takes full height
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div className="px-5 py-4">{children}</div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            )}

            {/* </AnimatePresence> */}
          </Col>
        </Row>
      </Section>
    </div>
  );
};

OnBoardingCardLayout.propTypes = {
  leftSideContent: PropTypes.node,
  children: PropTypes.node.isRequired,
  showBack: PropTypes.bool,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  handleSkip: PropTypes.func,
  isLastQuestion: PropTypes.bool,
};

// const FeatureCard = ({ icon, title, description }) => (
//   <div className="bg-white bg-opacity-20 rounded-lg p-6 backdrop-blur-sm">
//     <div className="flex items-center mb-4">
//       {icon}
//       <h3 className="text-xl font-semibold ml-3">{title}</h3>
//     </div>
//     <p className="text-sm">{description}</p>
//   </div>
// );

const FeatureCard = ({ icon, title, description, isActive }) => (
  <Card
    className="text-center"
    style={{
      background: isActive
        ? "rgba(255, 255, 255, 0.9)" // Active tab background
        : "rgba(255, 255, 255, 0.3)", // Non-active tab background
      backdropFilter: "blur(10px)", // Blur effect
      borderRadius: "12px",
      padding: "20px",
      border: isActive ? "2px solid #F89745" : "none", // Highlight active tab
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
      width: "100%",
      height: "300px", // Fixed height for consistency
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    }}
  >
    <Card.Body>
      <div className="d-flex justify-content-center align-items-center mb-3">
        <div style={{ fontSize: "30px" }}>{icon}</div>
      </div>
      <Card.Title
        style={{
          fontSize: "18px",
          fontWeight: "bold",
          color: "#005677", // Darker color for the title
        }}
      >
        {title}
      </Card.Title>
      <Card.Text
        style={{
          fontSize: "14px",
          color: "#333333", // Darker gray for better readability
        }}
      >
        {description}
      </Card.Text>
    </Card.Body>
  </Card>
);

export default OnBoardingCardLayout;
