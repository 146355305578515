import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";

const SAVE_ITEMS = {
  municipal: "municipal",
  "construction and demolition": "constructionAndDemolition",
  "commercial and industrial": "commercialAndIndustrial",
  recycling: "recycling",
  paper: "paper",
  "sanitary and nappies": "sanitaryAndNappies",
  "green and organic": "greenAndOrganic",
};

const SOURCE_EMISSION_FACTORS = {
  municipal: 0.36,
  "construction and demolition": 0.39,
  "commercial and Industrial": 0.33,
  recycling: 0.06,
  paper: 0.09,
  "sanitary and nappies": 0.39,
  "green and organic": 0.24,
  food: 0.5,
  wood: 0.15,
  textiles: 0.14,
  sludge: 0.72,
  "rubber and leather": 0.14,
  inert: 0.42,
};

const DraftedWasteForm = ({ type, wasteData, setWasteData }) => {
  const [formData, setFormData] = useState({
    numberOfWheelieBins: wasteData[SAVE_ITEMS[type]].numberOfWheelieBins,
    daysBetweenCollection: wasteData[SAVE_ITEMS[type]].daysBetweenCollection,
    fullnessOfBins: wasteData[SAVE_ITEMS[type]].fullnessOfBins,
  });

  const [outputData, setOutputData] = useState({
    numberOfCollections: wasteData[SAVE_ITEMS[type]].numberOfCollections,
    numberOfBins: wasteData[SAVE_ITEMS[type]].numberOfBins,
    volumeOfCollections: wasteData[SAVE_ITEMS[type]].volumeOfCollections,
    totalWeightOfWaste: wasteData[SAVE_ITEMS[type]].totalWeightOfWaste,
  });

  const [greenAndOrganicOutputData, setGreenAndOrganicOutputData] = useState({
    numberOfCollections: wasteData.greenAndOrganic.numberOfCollections,
    numberOfBins: wasteData.greenAndOrganic.numberOfBins,
    volumeOfCollections: wasteData.greenAndOrganic.volumeOfCollections,
    totalWeightOfWasteWood: wasteData.greenAndOrganic.totalWeightOfWasteWood,
    totalWeightOfWasteFood: wasteData.greenAndOrganic.totalWeightOfWasteFood,
    totalWeightOfWasteGarden:
      wasteData.greenAndOrganic.totalWeightOfWasteGarden,
  });

  const [disabledInputField, setDisabledInputField] = useState(false);

  const handleFieldChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  async function calculateOutput() {
    //calculate number of collections
    let numberOfCollections = 0;
    if (
      formData.daysBetweenCollection !== "" &&
      formData.daysBetweenCollection !== "0"
    ) {
      numberOfCollections = 365 / formData.daysBetweenCollection;
    }
    setOutputData((prevState) => ({
      ...prevState,
      numberOfCollections: numberOfCollections,
    }));

    //calculate number of bins
    let numberOfBins = 0;
    if (
      numberOfCollections !== 0 &&
      formData.fullnessOfBins !== "" &&
      formData.numberOfWheelieBins !== "" &&
      formData.fullnessOfBins !== "0" &&
      formData.numberOfWheelieBins !== "0"
    ) {
      numberOfBins =
        numberOfCollections *
        formData.numberOfWheelieBins *
        (formData.fullnessOfBins / 100);
    }
    setOutputData((prevState) => ({
      ...prevState,
      numberOfBins: numberOfBins,
    }));

    //calculate volume of collections
    let volumeOfCollections = 0;
    let totalWeightOfWaste = 0;
    if (numberOfBins !== 0) {
      volumeOfCollections = numberOfBins * 0.24;

      totalWeightOfWaste = volumeOfCollections * SOURCE_EMISSION_FACTORS[type];
    }
    setOutputData((prevState) => ({
      ...prevState,
      volumeOfCollections: volumeOfCollections,
      totalWeightOfWaste: totalWeightOfWaste,
    }));
  }

  async function calculateGreenAndOrganicOutput() {
    //calculate number of collections
    let numberOfCollections = 0;
    if (formData.daysBetweenCollection !== 0) {
      numberOfCollections = 365 / formData.daysBetweenCollection;
    }
    setGreenAndOrganicOutputData((prevState) => ({
      ...prevState,
      numberOfCollections: numberOfCollections,
    }));

    //calculate number of bins
    let numberOfBins = 0;
    if (
      numberOfCollections !== 0 &&
      formData.fullnessOfBins !== "" &&
      formData.numberOfWheelieBins !== "" &&
      formData.fullnessOfBins !== "0" &&
      formData.numberOfWheelieBins !== "0"
    ) {
      numberOfBins =
        numberOfCollections *
        formData.numberOfWheelieBins *
        (formData.fullnessOfBins / 100);
    }

    //calculate volume of collections
    let volumeOfCollections = 0;
    let totalWeightOfWasteWood = 0;
    let totalWeightOfWasteFood = 0;
    let totalWeightOfWasteGarden = 0;
    if (numberOfBins !== 0) {
      volumeOfCollections = greenAndOrganicOutputData.numberOfBins * 0.24;

      totalWeightOfWasteWood =
        volumeOfCollections * SOURCE_EMISSION_FACTORS["wood"];
      totalWeightOfWasteFood =
        volumeOfCollections * SOURCE_EMISSION_FACTORS["food"];
      totalWeightOfWasteGarden =
        volumeOfCollections * SOURCE_EMISSION_FACTORS["green and organic"];
    }
    setGreenAndOrganicOutputData((prevState) => ({
      ...prevState,
      numberOfBins: numberOfBins,
      volumeOfCollections: volumeOfCollections,
      totalWeightOfWasteWood: totalWeightOfWasteWood,
      totalWeightOfWasteFood: totalWeightOfWasteFood,
      totalWeightOfWasteGarden: totalWeightOfWasteGarden,
    }));
  }

  async function calculateOutputData() {
    if (type === "green and organic") {
      await calculateGreenAndOrganicOutput();
    } else {
      await calculateOutput();
    }
  }

  useEffect(() => {
    calculateOutputData();
  }, [formData]);

  const handleSave = (e) => {
    e.preventDefault();
    setDisabledInputField(true);
    if (type === "green and organic") {
      setWasteData({
        ...wasteData,
        [SAVE_ITEMS[type]]: {
          ...wasteData[SAVE_ITEMS[type]],
          numberOfWheelieBins: formData.numberOfWheelieBins,
          daysBetweenCollection: formData.daysBetweenCollection,
          fullnessOfBins: formData.fullnessOfBins,
          numberOfCollections: greenAndOrganicOutputData.numberOfCollections,
          numberOfBins: greenAndOrganicOutputData.numberOfBins,
          volumeOfCollections: greenAndOrganicOutputData.volumeOfCollections,
          totalWeightOfWasteWood:
            greenAndOrganicOutputData.totalWeightOfWasteWood,
          totalWeightOfWasteGarden:
            greenAndOrganicOutputData.totalWeightOfWasteGarden,
          totalWeightOfWasteFood:
            greenAndOrganicOutputData.totalWeightOfWasteFood,
        },
      });
    } else {
      setWasteData({
        ...wasteData,
        [SAVE_ITEMS[type]]: {
          ...wasteData[SAVE_ITEMS[type]],
          numberOfWheelieBins: formData.numberOfWheelieBins,
          daysBetweenCollection: formData.daysBetweenCollection,
          fullnessOfBins: formData.fullnessOfBins,
          numberOfCollections: outputData.numberOfCollections,
          numberOfBins: outputData.numberOfBins,
          volumeOfCollections: outputData.volumeOfCollections,
          totalWeightOfWaste: outputData.totalWeightOfWaste,
        },
      });
    }
  };

  return (
    <Form>
      <Form.Group className="mb-3">
        <Form.Label>How manys days between collections?</Form.Label>
        <Form.Control
          type="number"
          name="daysBetweenCollection"
          placeholder="14"
          value={formData.daysBetweenCollection}
          onChange={handleFieldChange}
          disabled={disabledInputField}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Number of Standard (240L) Wheelie Bins</Form.Label>
        <Form.Control
          type="number"
          name="numberOfWheelieBins"
          placeholder="0"
          value={formData.numberOfWheelieBins}
          onChange={handleFieldChange}
          disabled={disabledInputField}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>How full are they when collected (%)</Form.Label>
        <Form.Control
          type="number"
          name="fullnessOfBins"
          placeholder="100"
          value={formData.fullnessOfBins}
          onChange={handleFieldChange}
          disabled={disabledInputField}
        />
      </Form.Group>

      {type !== "green and organic" && (
        <>
          <Form.Group className="mb-3">
            <Form.Label>Number of collections per year</Form.Label>
            <Form.Control
              type="number"
              name="numberOfCollections"
              value={parseInt(outputData.numberOfCollections)}
              placeholder="-"
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Number of full (240L) bins of {type} waste</Form.Label>
            <Form.Control
              type="number"
              name="numberOfBins"
              value={parseFloat(outputData.numberOfBins).toFixed(1)}
              placeholder="-"
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Volume of total collections as m³</Form.Label>
            <Form.Control
              type="number"
              name="volumeOfCollections"
              value={parseFloat(outputData.volumeOfCollections).toFixed(1)}
              placeholder="-"
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Total weight of waste (as T)</Form.Label>
            <Form.Control
              type="number"
              name="totalWeightOfWaste"
              value={parseFloat(outputData.totalWeightOfWaste).toFixed(2)}
              placeholder="-"
              disabled
            />
          </Form.Group>
        </>
      )}
      {type === "green and organic" && (
        <>
          <Form.Group className="mb-3">
            <Form.Label>Number of collections per year</Form.Label>
            <Form.Control
              type="number"
              name="numberOfCollections"
              value={parseInt(greenAndOrganicOutputData.numberOfCollections)}
              placeholder="-"
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Number of full (240L) bins of {type} waste</Form.Label>
            <Form.Control
              type="number"
              name="numberOfBins"
              value={parseFloat(greenAndOrganicOutputData.numberOfBins).toFixed(
                1
              )}
              placeholder="-"
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Volume of total collections as m³</Form.Label>
            <Form.Control
              type="number"
              name="volumeOfCollections"
              value={parseFloat(
                greenAndOrganicOutputData.volumeOfCollections
              ).toFixed(1)}
              placeholder="-"
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Total weight of waste (as T) of Wood</Form.Label>
            <Form.Control
              type="number"
              name="totalWeightOfWaste"
              value={parseFloat(
                greenAndOrganicOutputData.totalWeightOfWasteWood
              ).toFixed(2)}
              placeholder="-"
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Total weight of waste (as T) of Garden</Form.Label>
            <Form.Control
              type="number"
              name="totalWeightOfWaste"
              value={parseFloat(
                greenAndOrganicOutputData.totalWeightOfWasteGarden
              ).toFixed(2)}
              placeholder="-"
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Total weight of waste (as T) of Food</Form.Label>
            <Form.Control
              type="number"
              name="totalWeightOfWaste"
              value={parseFloat(
                greenAndOrganicOutputData.totalWeightOfWasteFood
              ).toFixed(2)}
              placeholder="-"
              disabled
            />
          </Form.Group>
        </>
      )}

      <Form.Group className="mb-4">
        {!disabledInputField && (
          <Button
            className="w-100"
            type="submit"
            disabled={
              type === "green and organic"
                ? !formData.numberOfWheelieBins ||
                  !formData.daysBetweenCollection ||
                  !formData.fullnessOfBins ||
                  !greenAndOrganicOutputData.numberOfCollections ||
                  !greenAndOrganicOutputData.numberOfBins ||
                  !greenAndOrganicOutputData.volumeOfCollections ||
                  !greenAndOrganicOutputData.totalWeightOfWasteWood ||
                  !greenAndOrganicOutputData.totalWeightOfWasteGarden ||
                  !greenAndOrganicOutputData.totalWeightOfWasteFood
                : !formData.numberOfWheelieBins ||
                  !formData.daysBetweenCollection ||
                  !formData.fullnessOfBins ||
                  !outputData.numberOfCollections ||
                  !outputData.numberOfBins ||
                  !outputData.volumeOfCollections ||
                  !outputData.totalWeightOfWaste
            }
            onClick={handleSave}
          >
            Save
          </Button>
        )}
        {/* Display text in center */}
        {disabledInputField && (
          <div className="d-flex justify-content-center align-items-center">
            <FontAwesomeIcon icon={faCheck} className="fs-0" />
            <Form.Control plaintext readOnly defaultValue="Saved" />
          </div>
        )}
      </Form.Group>
    </Form>
  );
};

export default DraftedWasteForm;
