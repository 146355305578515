import React from "react";
import PropTypes from "prop-types";

const EmissionsBoundariesColumnHeader = ({ title, itemCount }) => {
  return (
    <div className="kanban-column-header">
      <h5 className="fs-0 mb-0">
        {title} <span className="text-500">({itemCount})</span>
      </h5>
    </div>
  );
};

EmissionsBoundariesColumnHeader.propTypes = {
  title: PropTypes.string.isRequired,
  itemCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default EmissionsBoundariesColumnHeader;
