import { useContext, useEffect, useState } from "react";
import socketClient from "socket.io-client";
import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
import {
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
  Button,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
  faSortAmountUp,
  faSortAmountDown,
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { AreasOfImprovementContext } from "../../../context/Context";
import usePagination from "../../../hooks/usePagination";
import Flex from "../../common/Flex";
import { getAllAreasOfImprovementByQuestion } from "../solutionsAPI";
import { useAuth } from "../../../Contexts/AuthContext";
import AreasOfImprovementList from "./AreasOfImprovementList";
import GenerateAOIButton from "./GenerateAOIButton";
import { getActiveUserData, setActiveUserData } from "../../../helpers/store";

const AreasOfImprovement = ({ status, setStatus }) => {
  // console.log("In AreasOfImprovement", status);
  const { currentUser } = useAuth();

  useEffect(() => {
    const analytics = getAnalytics();
    setUserId(analytics, currentUser.uid);
    setUserProperties(analytics, { businessId: currentUser.businessId });
    logEvent(analytics, "page_view", {
      page_title: "Areas of Improvement",
    });
  }, [currentUser]);

  const {
    areasOfImprovementState: { areasOfImprovement },
    areasOfImprovementDispatch,
  } = useContext(AreasOfImprovementContext);

  const [sortBy, setSortBy] = useState("priority");
  const [isAsc, setIsAsc] = useState(true);
  const [areasOfImprovementPerPage, setAreasOfImprovementPerPage] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  // const [isInProgress, setIsInProgress] = useState(status);

  const {
    paginationState: {
      data: paginatedAreasOfImprovement,
      totalItems,
      itemsPerPage,
      currentPage,
      canNextPage,
      canPreviousPage,
      paginationArray,
      from,
      to,
    },
    nextPage,
    prevPage,
    goToPage,
    setItemsPerPage,
  } = usePagination(areasOfImprovement, areasOfImprovementPerPage);

  const getAreasOfImprovement = async () => {
    // console.log("In getAreasOfImprovement=>");

    const activeUserData = JSON.parse(getActiveUserData());
    //console.log("activeUserData", activeUserData);

    const { questionId } = activeUserData;
    // console.log(questionId);

    if (questionId) {
      const data = await getAllAreasOfImprovementByQuestion(questionId);
      // console.log(data);
      if (data.length > 0) {
        console.log("Areas of Improvements generated");
        activeUserData.aoiGeneratedStatus = "Completed";
        setActiveUserData(JSON.stringify(activeUserData));
        // setIsInProgress(false);
        setStatus(false);
      } else {
        console.log("Areas of Improvements not generated");
        // setIsInProgress(
        //   activeUserData.aoiGeneratedStatus === "InProgress" ? true : false
        // );
        setStatus(
          activeUserData.aoiGeneratedStatus === "InProgress" ? true : false
        );
      }
      return data;
    }
    console.log("No questionId");
    return [];
  };

  async function get() {
    setIsLoading(true);

    let data = await getAreasOfImprovement();

    areasOfImprovementDispatch({
      type: "FETCH_AREAS_OF_IMPROVEMENT_SUCCESS",
      payload: { data },
    });
    areasOfImprovementDispatch({
      type: "SORT_AREAS_OF_IMPROVEMENT",
      payload: {
        sortBy,
        order: isAsc ? "asc" : "desc",
      },
    });
    setIsLoading(false);
  }
  useEffect(() => {
    console.log("In useEffect");
    get();
  }, [sortBy, isAsc, status]);

  function connectSocket() {
    console.log("In connectSocket");
    var socket = socketClient();
    socket.on("AREAS_OF_IMPROVEMENT_READY", async () => {
      setIsLoading(true);
      console.log("AREAS_OF_IMPROVEMENT_READY");
      let data = await getAreasOfImprovement();
      console.log(data);
      areasOfImprovementDispatch({
        type: "FETCH_AREAS_OF_IMPROVEMENT_SUCCESS",
        payload: { data },
      });
      areasOfImprovementDispatch({
        type: "SORT_AREAS_OF_IMPROVEMENT",
        payload: {
          sortBy,
          order: isAsc ? "asc" : "desc",
        },
      });
      setIsLoading(false);
    });
  }

  useEffect(() => {
    connectSocket();
  }, []);

  return (
    <>
      <Card className="mb-3">
        <Card.Body>
          <Row className="flex-between-center">
            <Col
              sm="auto"
              as={Flex}
              alignItems="center"
              className="mb-2 mb-sm-0"
            >
              <Form.Select
                size="sm"
                value={itemsPerPage}
                onChange={({ target }) => {
                  setItemsPerPage(target.value);
                  setAreasOfImprovementPerPage(target.value);
                }}
                style={{ maxWidth: "4.875rem" }}
              >
                <option value={0}>0</option>
                <option value={2}>2</option>
                <option value={4}>4</option>
                <option value={6}>6</option>
                <option value={20}>20</option>
                <option value={totalItems}>All</option>
              </Form.Select>
              <h6 className="mb-0 ms-2">
                Showing {from}-{to} of {totalItems} Areas of Improvement
              </h6>
            </Col>
            <Col sm="auto">
              <Row className="gx-2 align-items-center">
                <Col xs="auto">
                  <Form as={Row} className="gx-2">
                    <Col xs="auto">
                      <small>Sort by:</small>
                    </Col>
                    <Col xs="auto">
                      <InputGroup size="sm">
                        <Form.Select
                          className="pe-5"
                          defaultValue="priority"
                          onChange={({ target }) => setSortBy(target.value)}
                        >
                          <option value="priority">Priority</option>
                        </Form.Select>
                        <InputGroup.Text
                          as={Button}
                          variant="secondary"
                          onClick={() => setIsAsc(!isAsc)}
                        >
                          <FontAwesomeIcon
                            icon={isAsc ? faSortAmountUp : faSortAmountDown}
                          />
                        </InputGroup.Text>
                      </InputGroup>
                    </Col>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {!status && (
        <>
          {!isLoading && (
            <>
              {paginatedAreasOfImprovement.length > 0 && (
                <Card className="mb-3">
                  <Card.Body className={classNames("p-0 overflow-hidden")}>
                    <Row className={classNames("g-0")}>
                      {paginatedAreasOfImprovement.map(
                        (areaOfImprovement, index) => (
                          <AreasOfImprovementList
                            data={areaOfImprovement}
                            key={areaOfImprovement.id}
                            index={index}
                          />
                        )
                      )}
                    </Row>
                  </Card.Body>
                  <Card.Footer
                    className={classNames(
                      "d-flex justify-content-center",
                      "border-top"
                    )}
                  >
                    <div>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Prev</Tooltip>}
                      >
                        <Button
                          variant="falcon-default"
                          size="sm"
                          disabled={!canPreviousPage}
                          onClick={prevPage}
                          className="me-2"
                          trigger="focus"
                        >
                          <FontAwesomeIcon icon={faChevronLeft} />
                        </Button>
                      </OverlayTrigger>
                    </div>

                    <ul className="pagination mb-0">
                      {paginationArray.map((page) => (
                        <li
                          key={page}
                          className={classNames({
                            active: currentPage === page,
                          })}
                        >
                          <Button
                            size="sm"
                            variant="falcon-default"
                            className="page me-2"
                            onClick={() => goToPage(page)}
                          >
                            {page}
                          </Button>
                        </li>
                      ))}
                    </ul>
                    <div>
                      <OverlayTrigger
                        trigger="click"
                        placement="top"
                        overlay={<Tooltip id="button-tooltip-2">Next</Tooltip>}
                      >
                        <Button
                          variant="falcon-default"
                          size="sm"
                          disabled={!canNextPage}
                          onClick={nextPage}
                          trigger="focus"
                        >
                          <FontAwesomeIcon icon={faChevronRight} />
                        </Button>
                      </OverlayTrigger>
                    </div>
                  </Card.Footer>
                </Card>
              )}
              {paginatedAreasOfImprovement.length === 0 && (
                <Card className="mb-3">
                  <Card.Body className="text-center">
                    <GenerateAOIButton />
                    <h5 className="mb-0">No Areas of Improvement Found</h5>
                  </Card.Body>
                </Card>
              )}
            </>
          )}
          {isLoading && (
            <div className="d-flex justify-content-center align-content-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </>
      )}
      {status && (
        <Card className="mb-3">
          {/* <Card.Body className="text-center"> */}
          <Card.Body className="text-center d-flex align-items-center justify-content-center">
            {/* <h5 className="mb-0">Areas of Improvement Generated</h5> */}
            {/* <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <h5 className="mb-0 ms-2">In Progress</h5>
            </div> */}
            <div className="text-center">
              <Spinner animation="border" variant="primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              <h5 className="mt-3">
                Areas of improvement are being generated based on your answers.
              </h5>
              <p className="text-muted">
                This might take up to 30 seconds. Once they have been generated,
                you can explore solutions for each area of improvement.
              </p>
            </div>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default AreasOfImprovement;
