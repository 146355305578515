// import WaterHubProvider from "./WaterHubProvider";`
// import WaterWizardLayout from "./WaterWizardLayout`";
import WidgetSectionTitle from "../../widgets/WidgetSectionTitle";
import EmployeeEngagementForm from "./EmployeeEngagementForm";
import { faList } from "@fortawesome/free-solid-svg-icons";
// import WaterDataForm from "./WaterDataForm";

const Index = () => {
  const variant = "p";
  const validation = true;
  const progressBar = true;
  // When I call climate-active/Water-form I want to be able to dens some parameters on the route
  // I want to sometimes call it and set lreay the progress and send an id to do an api call

  return (
    <>
      {/* <WidgetSectionTitle
        icon={faList}
        title="Employee Engagement Calculator"
        // subtitle="Fill your water form here"
        subtitle="FILLER TEXT"
        transform="shrink-2"
        className="mb-4 mt-6"
      /> */}
      <EmployeeEngagementForm />
      {/* <WaterHubProvider>
        <WaterWizardLayout
          variant={variant}
          validation={validation}
          progressBar={progressBar}
        />
      </WaterHubProvider> */}
      {/* <WaterDataForm /> */}
    </>
  );
};

export default Index;
