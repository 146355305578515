import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const InvoiceDetails = ({
  showModal,
  handleCloseModal,
  handleUpdate,
  handleUpdatedData,
  selectedBill,
  updatedData,
  nameId,
}) => {
  const [formValues, setFormValues] = useState({});
  const [marketBasedEmissionIsChecked, setMarketBasedEmissionIsChecked] =
    useState(
      selectedBill.market_based_emissions_tonnes !== undefined &&
        selectedBill.market_based_emissions_tonnes !== "" &&
        selectedBill.market_based_emissions_tonnes !== null
    );
  const [isFormEdited, setIsFormEdited] = useState(false);

  useEffect(() => {
    if (selectedBill && !isFormEdited) {
      setFormValues({
        invoiceNumber:
          updatedData[selectedBill.document_id]?.invoiceNumber ||
          selectedBill.invoice ||
          "",
        startDate:
          updatedData[selectedBill.document_id]?.startDate ||
          new Date(selectedBill.start_date),
        endDate:
          updatedData[selectedBill.document_id]?.endDate ||
          new Date(selectedBill.end_date),
        cost:
          updatedData[selectedBill.document_id]?.cost ||
          selectedBill.cost ||
          "",
        consumption:
          updatedData[selectedBill.document_id]?.consumption ||
          selectedBill.consumption ||
          "",
        marketBasedEmissions:
          updatedData[selectedBill.document_id]?.marketBasedEmissions ||
          selectedBill.market_based_emissions_tonnes ||
          "",
        billAddress:
          updatedData[selectedBill.document_id]?.billAddress ||
          selectedBill.billaddress ||
          "",
        location:
          updatedData[selectedBill.document_id]?.location ||
          selectedBill.office_id ||
          "",
        providerName:
          updatedData[selectedBill.document_id]?.providerName ||
          selectedBill.provider ||
          "",
        providerABN:
          updatedData[selectedBill.document_id]?.providerABN ||
          selectedBill.abn ||
          "",
        approvalStatus:
          updatedData[selectedBill.document_id]?.approvalStatus ||
          selectedBill.status.content ||
          "Pending",
      });
    }
  }, [selectedBill, updatedData, isFormEdited]);

  const handleChange = (field, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
    setIsFormEdited(true);
    handleUpdatedData(field, value, selectedBill.document_id);
  };

  const handleSubmit = () => {
    const updatedValues = { ...formValues };
    console.log("updatedValues BEFORE", updatedValues); // Debugging
    if (!marketBasedEmissionIsChecked) {
      updatedValues.marketBasedEmissions = "";
    }
    handleUpdate({ ...selectedBill, ...updatedValues });
    handleCloseModal();
  };

  const isFormValid = () => {
    return (
      formValues.invoiceNumber &&
      formValues.startDate &&
      formValues.endDate &&
      formValues.cost &&
      formValues.consumption &&
      (!marketBasedEmissionIsChecked || formValues.marketBasedEmissions) &&
      formValues.billAddress &&
      formValues.location &&
      formValues.providerName &&
      formValues.providerABN &&
      formValues.approvalStatus
    );
  };

  useEffect(() => {
    if (marketBasedEmissionIsChecked) {
      setFormValues((prevValues) => ({
        ...prevValues,
        marketBasedEmissions:
          updatedData[selectedBill.document_id]?.marketBasedEmissions ||
          selectedBill.market_based_emissions_tonnes ||
          "",
      }));
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        marketBasedEmissions: "",
      }));
    }
  }, [marketBasedEmissionIsChecked]);

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      size="xl"
      style={{ maxWidth: "100%", width: "100%", height: "95vh" }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Invoice Details</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: "100vh", overflowY: "auto" }}>
        {selectedBill && (
          <div className="d-flex" style={{ height: "100%" }}>
            <div
              className="flex-fill me-3"
              style={{ width: "70%", height: "100%" }}
            >
              <iframe
                src={selectedBill.document.url}
                style={{ width: "100%", height: "110%" }}
                frameBorder="0"
              ></iframe>
            </div>
            <div
              className="vertical-divider"
              style={{
                width: "2px",
                backgroundColor: "#ddd",
                height: "110%",
                margin: "0 1rem",
              }}
            ></div>
            <div className="flex-fill" style={{ width: "30%", height: "100%" }}>
              <h5>Update Data</h5>
              <Form style={{ height: "110%" }}>
                <Form.Group className="mb-3">
                  <Form.Label>Invoice number</Form.Label>
                  <Form.Control
                    type="text"
                    value={formValues.invoiceNumber}
                    onChange={(e) =>
                      handleChange("invoiceNumber", e.target.value)
                    }
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Start Date</Form.Label>
                  <DatePicker
                    selected={formValues.startDate}
                    onChange={(date) => handleChange("startDate", date)}
                    dateFormat="yyyy-MM-dd"
                    className="form-control text-center"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    popperClassName="date-picker-reports"
                    placeholderText="Choose a date"
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>End Date</Form.Label>
                  <DatePicker
                    selected={formValues.endDate}
                    onChange={(date) => handleChange("endDate", date)}
                    dateFormat="yyyy-MM-dd"
                    className="form-control text-center"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    popperClassName="date-picker-reports"
                    placeholderText="Choose a date"
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Total Cost</Form.Label>
                  <Form.Control
                    type="number"
                    value={formValues.cost}
                    onChange={(e) => handleChange("cost", e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Consumption</Form.Label>
                  <Form.Control
                    type="number"
                    value={formValues.consumption}
                    onChange={(e) =>
                      handleChange("consumption", e.target.value)
                    }
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Market Based Emissions</Form.Label>
                  <Form.Control
                    type="number"
                    value={formValues.marketBasedEmissions}
                    onChange={(e) =>
                      handleChange("marketBasedEmissions", e.target.value)
                    }
                    required={marketBasedEmissionIsChecked}
                    disabled={!marketBasedEmissionIsChecked}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Include Market Based Emissions"
                    checked={marketBasedEmissionIsChecked}
                    onClick={() =>
                      setMarketBasedEmissionIsChecked(
                        !marketBasedEmissionIsChecked
                      )
                    }
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Customer's Address</Form.Label>
                  <Form.Control
                    type="text"
                    value={formValues.billAddress}
                    onChange={(e) =>
                      handleChange("billAddress", e.target.value)
                    }
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Customer's Office</Form.Label>
                  <Form.Select
                    value={formValues.location}
                    onChange={(e) => handleChange("location", e.target.value)}
                    required
                  >
                    <option value="">Select an office</option>
                    {Object.keys(nameId).map((key) => (
                      <option key={key} value={key}>
                        {nameId[key]}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Provider Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={formValues.providerName}
                    onChange={(e) =>
                      handleChange("providerName", e.target.value)
                    }
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Provider ABN</Form.Label>
                  <Form.Control
                    type="text"
                    value={formValues.providerABN}
                    onChange={(e) =>
                      handleChange("providerABN", e.target.value)
                    }
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Approval Status</Form.Label>
                  <Form.Select
                    value={formValues.approvalStatus}
                    onChange={(e) =>
                      handleChange("approvalStatus", e.target.value)
                    }
                    required
                  >
                    <option value="Success">Approved</option>
                    <option value="Pending">Pending</option>
                  </Form.Select>
                </Form.Group>

                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={!isFormValid()}
                >
                  Update
                </Button>
              </Form>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default InvoiceDetails;
