import PropTypes from "prop-types";
import { Card, Col, Row, ProgressBar } from "react-bootstrap";
import Background from "../common/Background";
import bg2 from "../../Assets/img/icons/spot-illustrations/corner-2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import CountUp from "react-countup";

const Item = ({ item, index, total }) => {
  const { label, color, value } = item;

  // const percentage = ((value * 100) / total).toFixed(0);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 6,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",

          justifyContent: "center",
        }}
      >
        <FontAwesomeIcon
          icon={faCircle}
          className="me-2"
          style={{ color: color }}
        />
        <div className="text-600">{label} </div>
      </div>
      <div
        style={{
          width: "60%",
          height: "2px",
          backgroundColor: "#ddd",
          // margin: "0 auto 10px auto",
        }}
      ></div>
      {/* <div className="text-600">{Math.round(item?.value)}t CO₂e</div> */}
      {/* <div style={{ display: "flex" }}> */}
      <h5>
        <CountUp
          start={0}
          end={item?.value}
          duration={2.75}
          className="text-800"
          separator=","
          decimals={0}
        />
        <span className="fw-normal text-600" style={{ fontSize: "0.80em" }}>
          t CO₂e
        </span>
      </h5>
      {/* </div> */}
    </div>
  );
};

function ScopeWidgetCard2({ data }) {
  const dataChart = [
    {
      id: "Scope1",
      label: "Scope 1",
      value: data?.totalScope1,
      color: "#7ba8d9",
    },
    {
      id: "Scope2",
      label: "Scope 2",
      value: data?.totalScope2,
      color: "#99d6d6",
    },
    {
      id: "Scope3",
      label: "Scope 3",
      value: data?.totalScope3,
      color: "#ffca6c",
    },
  ];

  const totalValue = data?.totalScope1 + data?.totalScope2 + data?.totalScope3;

  return (
    <Card className="pt-3 pb-3" style={{ minHeight: "170px" }}>
      <Background image={bg2} className="bg-card" />
      <div className="d-flex justify-content-center mb-2">
        <h5 className="font-sans-serif">
          <span className="fw-normal text-600">Emissions By Scope</span>
        </h5>
      </div>

      <div style={{ display: "flex", flexDirection: "column", marginTop: 5 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ProgressBar
            style={{
              width: "80%",
              height: "10px",
              borderRadius: "10px",
              overflow: "hidden",
            }}
            className="shadow-none mb-3"
          >
            {dataChart.map((item, index) => (
              <ProgressBar
                key={item.id}
                now={(item.value / totalValue) * 100}
                style={{
                  backgroundColor: item.color,
                //   borderRight:
                //     index !== dataChart.length - 1 ? "2px solid white" : "none",
                }}
              />
            ))}
          </ProgressBar>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          // flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        {dataChart?.map((item, index) => (
          <Item item={item} index={index} key={index} total={totalValue} />
        ))}
      </div>
    </Card>
  );
}

ScopeWidgetCard2.propTypes = {
  data: PropTypes.shape({
    totalScope1: PropTypes.number,
    totalScope2: PropTypes.number,
    totalScope3: PropTypes.number,
  }),
};

export default ScopeWidgetCard2;
