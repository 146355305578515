import React from "react";
import BusinessDetailsWizardInput from "../BusinessDetailWizard/BusinessDetailsWizardInput";

function Screen2({ register, errors }) {
  return (
    <>
      <BusinessDetailsWizardInput
        label=" Enter the amount of carbon offsets your organisation has purchased
              for this reporting period (in tonnes of CO₂e)."
        name="offsetPeriod"
        type="number"
        placeholder="Select an offset period"
        errors={errors}
        formGroupProps={{ className: "mb-3" }}
        formControlProps={{
          ...register("offsetPeriod", {
            // required: "This field is required",
          }),
          className: "w-50",
        }}
      />

      <BusinessDetailsWizardInput
        label="In the past 12 months, have any employees taken business flights?"
        name="businessFlights"
        type="select"
        options={["Yes", "No"]}
        placeholder="Select an option"
        errors={errors}
        formGroupProps={{ className: "mb-3" }}
        formControlProps={{
          ...register("businessFlights", {
            required: "This field is required",
          }),
          className: "w-25",
        }}
      />
    </>
  );
}

export default Screen2;
