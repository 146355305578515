import React from "react";
import WidgetSectionTitle from "../../widgets/WidgetSectionTitle";
import Content from "./content";
import { faList } from "@fortawesome/free-solid-svg-icons";

const Index = () => {
  return (
    <>
      <WidgetSectionTitle
        icon={faList}
        title="Content Viewer"
        subtitle="Request, view, and download all your content."
        transform="shrink-2"
        className="mb-4 mt-6"
      />
      <Content />
    </>
  );
};

export default Index;
