import React, { useContext } from "react";
import { Col } from "react-bootstrap";
import ModalMediaContent from "./ModalMediaContent";
import {
  faAlignLeft,
  faDatabase,
  faLocationDot,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import ModalOfficesContent from "./ModalOfficesContent";
import AppContext from "../../context/Context";
import ModalDataSourceContent from "./ModalDataSourceContent";
import ModalReasonQuestion from "./ModalReasonQuestion";
import createMarkup from "../../helpers/createMarkup";

const EXCLUDED_REASON_OPTIONS = [
  { value: "Too small", label: "Too small" },
  { value: "No influence", label: "No influence" },
  {
    value: "Not relevant to stakeholder",
    label: "Not relevant to stakeholder",
  },
  {
    value: "Other organisations excluded it",
    label: "Other organisations excluded it",
  },
];

const NOT_QUANTIFIED_REASON_OPTIONS = [
  {
    value: "Immaterial(organisation doesn't do this activity)",
    label: "Immaterial(organisation doesn't do this activity)",
  },
  {
    value: "Too expensive to get the data",
    label: "Too expensive to get the data",
  },
  {
    value: "Other organisations excluded it",
    label: "Other organisations excluded it",
  },
];

const ModalOverview = ({
  id,
  locationList,
  selectedLocations,
  dataSources,
  emissionsBoundaryId,
  emissionBoundary,
  description,
  amountAUD,
  amountCO2e,
  reasoning,
  showWarning,
}) => {
  const {
    config: { isRTL },
  } = useContext(AppContext);

  return (
    <Col lg={9}>
      {/* Description */}
      <ModalMediaContent title="Description" icon={faAlignLeft}>
        {description && (
          <p
            className="text-word-break fs--1"
            dangerouslySetInnerHTML={createMarkup(description)}
          />
        )}
      </ModalMediaContent>

      {/* Locations */}
      <ModalMediaContent title="Locations" icon={faLocationDot}>
        <ModalOfficesContent
          id={id}
          emissionsBoundaryId={emissionsBoundaryId}
          locationList={locationList}
          selectedLocations={selectedLocations}
        />
      </ModalMediaContent>

      {/* Data Sources */}
      <ModalMediaContent
        title="Data Sources"
        icon={faDatabase}
        isHr={emissionBoundary !== "Included"}
      >
        <p className="text-word-break fs--1">
          Data sources are the data sets that are used to calculate the
          emissions for this calculation. You can add data sources by clicking
          on any of the options below.
        </p>
        <ModalDataSourceContent
          taskId={id}
          emissionsBoundaryId={emissionsBoundaryId}
          dataSources={dataSources}
          amountAUD={amountAUD}
          amountCO2e={amountCO2e}
        />
      </ModalMediaContent>

      {/*Non-Quantifiable*/}
      {emissionBoundary === "Non-quantified" && (
        <ModalMediaContent
          title="Reason for not quantifying"
          icon={faQuestion}
          isHr={false}
          showWarning={showWarning}
        >
          <p className="text-word-break fs--1">Custom description</p>
          <ModalReasonQuestion
            options={NOT_QUANTIFIED_REASON_OPTIONS}
            isMulti={false}
            reasoning={reasoning}
            id={id}
            emissionsBoundaryId={emissionsBoundaryId}
            status={showWarning}
          />
        </ModalMediaContent>
      )}

      {/* Excluded */}
      {emissionBoundary === "Excluded" && (
        <ModalMediaContent
          title="Reason for excluding"
          icon={faQuestion}
          isHr={false}
          showWarning={showWarning}
        >
          <p className="text-word-break fs--1">Custom description</p>
          <ModalReasonQuestion
            options={EXCLUDED_REASON_OPTIONS}
            isMulti={true}
            reasoning={reasoning}
            id={id}
            emissionsBoundaryId={emissionsBoundaryId}
            status={showWarning}
          />
        </ModalMediaContent>
      )}
    </Col>
  );
};

export default ModalOverview;
