import React from "react";
import PageHeader from "../../common/PageHeader";
import Divider from "../../common/Divider";
import FalconComponentCard from "../../common/FalconComponentCard";
import {
  Button,
  Col,
  FloatingLabel,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getLocalTransportDataByEventId,
  updateLocalTransportDataById,
} from "./LocalTransportAPI";
import { toast, ToastContainer } from "react-toastify";

const LocalTransportForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [eventId] = React.useState(location.state.id);
  const [localTransportId, setLocalTransportId] = React.useState("");
  const [eventDuration, setEventDuration] = React.useState("");
  const [noOfPeople, setNoOfPeople] = React.useState("");
  const [city, setCity] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showButton, setShowButton] = React.useState(true);
  const [disableFields, setDisableFields] = React.useState(false);

  const [input, setInput] = React.useState({
    totalBicycleKM: "",
    totalBusKM: "",
    totalCarKM: "",
    totalWalkingKM: "",
    totalFerryKM: "",
    totalMotorbikeKM: "",
    totalTaxiKM: "",
    totalTrainKM: "",
    totalTramKM: "",
  });
  const [output, setOutput] = React.useState({
    totalBicycleKM: 0,
    totalBusKM: 0,
    totalCarKM: 0,
    totalWalkingKM: 0,
    totalFerryKM: 0,
    totalMotorbikeKM: 0,
    totalTaxiKM: 0,
    totalTrainKM: 0,
    totalTramKM: 0,
  });

  async function get(location) {
    if (location.state) {
      setEventDuration(location.state.numberOfDays.toString());
      setNoOfPeople(location.state.numberOfPeople.toString());
      setCity(location.state.city);

      const jsonData = await getLocalTransportDataByEventId(location.state.id);
      setLocalTransportId(jsonData.id);

      setOutput({
        totalBicycleKM: jsonData.estimate_total_bicycle,
        totalBusKM: jsonData.estimate_total_bus,
        totalCarKM: jsonData.estimate_total_car,
        totalWalkingKM: jsonData.estimate_total_walking,
        totalFerryKM: jsonData.estimate_total_ferry,
        totalMotorbikeKM: jsonData.estimate_total_motorbike,
        totalTaxiKM: jsonData.estimate_total_taxi,
        totalTrainKM: jsonData.estimate_total_train,
        totalTramKM: jsonData.estimate_total_tram,
      });

      setInput({
        totalBicycleKM: jsonData.actual_total_bicycle,
        totalBusKM: jsonData.actual_total_bus,
        totalCarKM: jsonData.actual_total_car,
        totalWalkingKM: jsonData.actual_total_walking,
        totalFerryKM: jsonData.actual_total_ferry,
        totalMotorbikeKM: jsonData.actual_total_motorbike,
        totalTaxiKM: jsonData.actual_total_taxi,
        totalTrainKM: jsonData.actual_total_train,
        totalTramKM: jsonData.actual_total_tram,
      });

      if (jsonData.status === "Completed") {
        setSubmitted(true);
        setDisableFields(true);
        setShowButton(false);
      }
    }
  }

  React.useEffect(() => {
    get(location);
  }, [location]);

  function handleFieldChange(e) {
    setInput((prevState) => ({
      ...prevState,
      [e.target.name]: parseFloat(e.target.value),
    }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    setDisableFields(true);
    const body = { ...input, status: "Completed" };
    const submittedData = await updateLocalTransportDataById(
      localTransportId,
      body
    );
    toast("Local Transport data submitted successfully!");

    setOutput({
      totalBicycleKM: submittedData.estimate_total_bicycle,
      totalBusKM: submittedData.estimate_total_bus,
      totalCarKM: submittedData.estimate_total_car,
      totalWalkingKM: submittedData.estimate_total_walking,
      totalFerryKM: submittedData.estimate_total_ferry,
      totalMotorbikeKM: submittedData.estimate_total_motorbike,
      totalTaxiKM: submittedData.estimate_total_taxi,
      totalTrainKM: submittedData.estimate_total_train,
      totalTramKM: submittedData.estimate_total_tram,
    });

    setInput({
      totalBicycleKM: submittedData.actual_total_bicycle,
      totalBusKM: submittedData.actual_total_bus,
      totalCarKM: submittedData.actual_total_car,
      totalWalkingKM: submittedData.actual_total_walking,
      totalFerryKM: submittedData.actual_total_ferry,
      totalMotorbikeKM: submittedData.actual_total_motorbike,
      totalTaxiKM: submittedData.actual_total_taxi,
      totalTrainKM: submittedData.actual_total_train,
      totalTramKM: submittedData.actual_total_tram,
    });

    setSubmitted(true);
    setShowButton(false);
    setIsLoading(false);

    //to initiate chart data calculation
    sessionStorage.setItem("dashboardDataCalCompleted", false);

    navigate("/welcome/dashboard/event-form", {
      state: { id: eventId },
    });
  }

  async function handleSave(e) {
    e.preventDefault();
    setIsLoading(true);
    setDisableFields(true);
    const body = { ...input, status: "InProgress" };
    const submittedData = await updateLocalTransportDataById(
      localTransportId,
      body
    );
    toast("Local Transport data saved successfully!");

    setOutput({
      totalBicycleKM: submittedData.estimate_total_bicycle,
      totalBusKM: submittedData.estimate_total_bus,
      totalCarKM: submittedData.estimate_total_car,
      totalWalkingKM: submittedData.estimate_total_walking,
      totalFerryKM: submittedData.estimate_total_ferry,
      totalMotorbikeKM: submittedData.estimate_total_motorbike,
      totalTaxiKM: submittedData.estimate_total_taxi,
      totalTrainKM: submittedData.estimate_total_train,
      totalTramKM: submittedData.estimate_total_tram,
    });

    setInput({
      totalBicycleKM: submittedData.actual_total_bicycle,
      totalBusKM: submittedData.actual_total_bus,
      totalCarKM: submittedData.actual_total_car,
      totalWalkingKM: submittedData.actual_total_walking,
      totalFerryKM: submittedData.actual_total_ferry,
      totalMotorbikeKM: submittedData.actual_total_motorbike,
      totalTaxiKM: submittedData.actual_total_taxi,
      totalTrainKM: submittedData.actual_total_train,
      totalTramKM: submittedData.actual_total_tram,
    });

    setSubmitted(false);
    setDisableFields(false);
    setShowButton(true);
    setIsLoading(false);
  }

  return (
    <>
      <PageHeader
        title="Local Transport"
        description=""
        className="mb-3"
      ></PageHeader>

      <FalconComponentCard>
        <FalconComponentCard.WaterDataHeader
          title="Your Event Details"
          light={false}
        />

        <FalconComponentCard.Body>
          <Form.Group className="mb-3">
            <Form.Label>Event Duration</Form.Label>
            <FloatingLabel label="No. of days">
              <Form.Control
                type="number"
                name="eventDuration"
                value={eventDuration}
                disabled={true}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Local Attendees</Form.Label>
            <FloatingLabel label="No. of local attendees(as per % local attendees provided at the start)">
              <Form.Control
                type="number"
                name="noOfPeople"
                value={noOfPeople}
                disabled={true}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Your event is in</Form.Label>
            <FloatingLabel label="City">
              <Form.Control
                type="text"
                name="city"
                value={city}
                disabled={true}
              />
            </FloatingLabel>
          </Form.Group>
        </FalconComponentCard.Body>
      </FalconComponentCard>
      <Row>
        <Col>
          <FalconComponentCard>
            {submitted ? (
              <FalconComponentCard.WaterDataHeader
                title="Estimated km"
                light={false}
              />
            ) : (
              <FalconComponentCard.Header title="Estimated km" light={false} />
            )}
            <FalconComponentCard.Body>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Bicycle</Form.Label>
                    <Form.Control
                      type="number"
                      name="Bicycle"
                      placeholder="Bicycle"
                      value={output.totalBicycleKM}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Bus</Form.Label>
                    <Form.Control
                      type="number"
                      name="Bus"
                      placeholder="Bus"
                      value={output.totalBusKM}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Car</Form.Label>
                    <Form.Control
                      type="number"
                      name="Car"
                      placeholder="Car"
                      value={output.totalCarKM}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Walking</Form.Label>
                    <Form.Control
                      type="number"
                      name="Walking"
                      placeholder="Walking"
                      value={output.totalWalkingKM}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Ferry</Form.Label>
                    <Form.Control
                      type="number"
                      name="Ferry"
                      placeholder="Ferry"
                      value={output.totalFerryKM}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Motorbike/scooter</Form.Label>
                    <Form.Control
                      type="number"
                      name="totalMotorbikeKM"
                      placeholder="Motorbike/scooter"
                      value={output.totalMotorbikeKM}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Taxi</Form.Label>
                    <Form.Control
                      type="number"
                      name="Taxi"
                      placeholder="Taxi"
                      value={output.totalTaxiKM}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Train</Form.Label>
                    <Form.Control
                      type="number"
                      name="Train"
                      placeholder="Train"
                      value={output.totalTrainKM}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Tram</Form.Label>
                    <Form.Control
                      type="number"
                      name="Tram"
                      placeholder="Tram"
                      value={output.totalTramKM}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
            </FalconComponentCard.Body>
          </FalconComponentCard>
        </Col>

        {/* <br />
      <Divider>or fill the below form</Divider>
      <br /> */}
        <Col>
          <FalconComponentCard>
            {submitted ? (
              <FalconComponentCard.WaterDataHeader
                title="Actual km"
                light={false}
              />
            ) : (
              <FalconComponentCard.Header title="Actual km" light={false} />
            )}
            <FalconComponentCard.Body>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Bicycle</Form.Label>
                    <Form.Control
                      type="number"
                      name="totalBicycleKM"
                      placeholder="Bicycle"
                      value={input.totalBicycleKM}
                      onChange={handleFieldChange}
                      disabled={disableFields}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Bus</Form.Label>
                    <Form.Control
                      type="number"
                      name="totalBusKM"
                      placeholder="Bus"
                      value={input.totalBusKM}
                      onChange={handleFieldChange}
                      disabled={disableFields}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Car</Form.Label>
                    <Form.Control
                      type="number"
                      name="totalCarKM"
                      placeholder="Car"
                      value={input.totalCarKM}
                      onChange={handleFieldChange}
                      disabled={disableFields}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Walking</Form.Label>
                    <Form.Control
                      type="number"
                      name="totalWalkingKM"
                      placeholder="Walking"
                      value={input.totalWalkingKM}
                      onChange={handleFieldChange}
                      disabled={disableFields}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Ferry</Form.Label>
                    <Form.Control
                      type="number"
                      name="totalFerryKM"
                      placeholder="Ferry"
                      value={input.totalFerryKM}
                      onChange={handleFieldChange}
                      disabled={disableFields}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Motorbike/scooter</Form.Label>
                    <Form.Control
                      type="number"
                      name="totalMotorbikeKM"
                      placeholder="Motorbike/scooter"
                      value={input.totalMotorbikeKM}
                      onChange={handleFieldChange}
                      disabled={disableFields}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Taxi</Form.Label>
                    <Form.Control
                      type="number"
                      name="totalTaxiKM"
                      placeholder="Taxi"
                      value={input.totalTaxiKM}
                      onChange={handleFieldChange}
                      disabled={disableFields}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Train</Form.Label>
                    <Form.Control
                      type="number"
                      name="totalTrainKM"
                      placeholder="Train"
                      value={input.totalTrainKM}
                      onChange={handleFieldChange}
                      disabled={disableFields}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="">
                    <Form.Label>Tram</Form.Label>
                    <Form.Control
                      type="number"
                      name="totalTramKM"
                      placeholder="Tram"
                      value={input.totalTramKM}
                      onChange={handleFieldChange}
                      disabled={disableFields}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </FalconComponentCard.Body>
          </FalconComponentCard>
        </Col>
      </Row>

      {isLoading && (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
      {!isLoading && showButton && (
        <Row style={{ alignItems: "center", justifyContent: "center" }}>
          <Col sm={2} style={{ padding: "5" }}>
            <Button
              onClick={handleSave}
              // disabled={
              //   !input.totalBicycleKM ||
              //   !input.totalBusKM ||
              //   !input.totalCarKM ||
              //   !input.totalWalkingKM ||
              //   !input.totalFerryKM ||
              //   !input.totalMotorbikeKM ||
              //   !input.totalTaxiKM ||
              //   !input.totalTrainKM ||
              //   !input.totalTramKM
              // }
            >
              Save As Draft
            </Button>
          </Col>
          <Col sm={2} style={{ padding: "5" }}>
            <Button
              onClick={handleSubmit}
              // disabled={
              //   !input.totalBicycleKM ||
              //   !input.totalBusKM ||
              //   !input.totalCarKM ||
              //   !input.totalWalkingKM ||
              //   !input.totalFerryKM ||
              //   !input.totalMotorbikeKM ||
              //   !input.totalTaxiKM ||
              //   !input.totalTrainKM ||
              //   !input.totalTramKM
              // }
            >
              Submit
            </Button>
          </Col>
        </Row>
      )}
      <ToastContainer />
    </>
  );
};

export default LocalTransportForm;
