import React, { useEffect, useState } from "react";
import { Alert, Button, Card, FloatingLabel, Form } from "react-bootstrap";
import { addPartners, getSelectedPartners } from "./partnersAPI";
import { useAuth } from "../../Contexts/AuthContext";

const PartnerCard = ({ data }) => {
  const { currentUser } = useAuth();
  const [business, setBusiness] = useState(currentUser.businessId);
  const [itemKey, setItemKey] = useState();
  const [selectedPartners, setselectedPartners] = useState();
  const [locationData, setLocationData] = useState("");
  const [nameId, setNameId] = useState();
  const [selectedLocation, setSelectedLocation] = useState([]);

  //add selected partner to list
  async function handleAddPartners(business, id) {
    console.log(business);
    const index = selectedLocation.findIndex((l) => l.partnerId === id);
    const body = {
      businessId: business,
      officeId: nameId[selectedLocation[index]["location"]],
      partnerId: id,
      status: "InProgress",
      createdBy: currentUser.uid,
      updatedBy: currentUser.uid,
    };
    await addPartners(body);
    window.location = "/welcome/partners/my-partners";
  }

  //when a partner is selected for a location
  function handleSelection(e, id) {
    //console.log(e.currentTarget.value, id)
    e.preventDefault();
    setItemKey(e.currentTarget.value);
    const jsonSelectedLocation = {
      partnerId: id,
      location: e.currentTarget.value,
    };
    let locationList = [];
    locationList.push(jsonSelectedLocation);
    setSelectedLocation(locationList);
  }

  //fetch list of all selected partner w.r.t office
  async function getSelectedLocationsList() {
    const partners = await getSelectedPartners();
    console.log(partners);
    //store all selected partners in session as string
    let selectedPartners = [];
    for (let i in partners) selectedPartners.push(partners[i]);
    sessionStorage.setItem(
      "selectedPartners",
      JSON.stringify(selectedPartners)
    );
    setselectedPartners(partners);
  }
  //fetch list of offices for the user
  function getOffice() {
    const businessOffices = JSON.parse(
      sessionStorage.getItem("businessOffices")
    );
    let nameIdDict = {};
    for (let i = 0; i < businessOffices.length; i++) {
      let str = businessOffices[i].split(",");
      nameIdDict[str[0]] = str[1];
    }
    setNameId(nameIdDict);
    setLocationData(JSON.parse(sessionStorage.getItem("officeName")));
  }

  useEffect(() => {
    getOffice();
    getSelectedLocationsList();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
      }}
    >
      {data
        ? data.map((item, i) => (
            <Card
              style={{ width: "20rem", margin: "10px", height: "27rem" }}
              key={item.id}
            >
              <Card.Body>
                <Card.Title as="h5">{item.name}</Card.Title>
                <Card.Text>
                  <div>{item.description}</div>
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <>
                  <FloatingLabel
                    controlId="floatingSelectGrid"
                    label="Location Selection"
                  >
                    <Form.Select
                      size="sm"
                      className="me-2"
                      required
                      onChange={(e) => {
                        handleSelection(e, item.id);
                      }}
                    >
                      <option>Select...</option>
                      {locationData
                        ? locationData.map((item, i) => (
                            <option key={item.id} value={item}>
                              {item}
                            </option>
                          ))
                        : null}
                    </Form.Select>
                  </FloatingLabel>
                </>
                <br />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    key={i}
                    color="primary"
                    size="sm"
                    onClick={() => {
                      handleAddPartners(business, item.id);
                    }}
                    disabled={
                      (selectedLocation.findIndex(
                        (l) => l.partnerId === item.id
                      ) === -1 &&
                        selectedLocation.findIndex(
                          (l) => l.location === itemKey
                        ) === -1) ||
                      (selectedLocation.findIndex(
                        (l) => l.partnerId === item.id
                      ) !== -1 &&
                        selectedLocation.findIndex(
                          (l) => l.location === itemKey
                        ) !== -1 &&
                        selectedLocation.findIndex(
                          (l) =>
                            l.partnerId === item.id && l.location === itemKey
                        ) !== -1 &&
                        selectedPartners.findIndex((s) => s.id === item.id) !==
                          -1 &&
                        selectedPartners.findIndex(
                          (s) => s.office_id === nameId[itemKey]
                        ) !== -1 &&
                        selectedPartners.findIndex(
                          (s) =>
                            s.office_id === nameId[itemKey] && s.id === item.id
                        ) !== -1) ||
                      selectedLocation.findIndex(
                        (l) => l.partnerId === item.id
                      ) === -1 ||
                      selectedLocation.findIndex(
                        (l) => l.location === itemKey
                      ) === -1
                    }
                  >
                    Add To List
                  </Button>
                  {item.website ? (
                    <>
                      <br />
                      <Card.Link
                        href={item.website}
                        target="_blank"
                        style={{ width: "10rem", margin: "5px" }}
                      >
                        Check partner
                      </Card.Link>
                    </>
                  ) : null}
                </div>
              </Card.Footer>
            </Card>
          ))
        : null}
    </div>
  );
};

export default PartnerCard;
