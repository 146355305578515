import React, { useState } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../shadcn/Accordion";
import { Badge } from "../shadcn/Badge";
import { DropdownMenu, DropdownMenuTrigger } from "../shadcn/DropdownMenu";
import { Circle, CheckCircle2 } from "lucide-react";

import { getActiveUserData, getInventoryProgress } from "../../helpers/store";
import { updateStepProgress } from "../../helpers/stepsProgress";
import { useNavigate } from "react-router-dom";
import SubStepAccordion from "./SubStepAccordion";
import DropDownContent from "./DropDownContent";

const StepAccordion = ({
  steps,
  categoryIndex,
  calculateProgress,
  nextTask,
  setStepsProgressData,
  openStep, // Accept openStep
  setOpenStep, // Accept setOpenStep
}) => {
  const [loading, setLoading] = useState(false);
  const [activeUser] = useState(JSON.parse(getActiveUserData()));

  const navigate = useNavigate();
  const [currentInventoryProgress, setCurrentInventoryProgress] = useState(
    JSON.parse(getInventoryProgress())
  );
  // console.log(steps);
  const handleSkip = async (stringId, officeId) => {
    // console.log(office_id);
    setLoading(true);
    await updateStepProgress({
      stringId,
      status: "skipped",

      activeUser,
      currentInventoryProgress,
      officeId,
      onUpdateState: setStepsProgressData,
      onUpdateInventoryProgress: false,
    });
    setLoading(false);
  };

  const handleMarkAsDone = async (stringId, officeId) => {
    setLoading(true);
    await updateStepProgress({
      stringId,
      status: "completed",
      activeUser,
      currentInventoryProgress,
      officeId,
      onUpdateState: setStepsProgressData,
      onUpdateInventoryProgress: false,
    });
    setLoading(false);
  };

  const handleNavigation = (url, state) => {
    if (url.startsWith("https://")) {
      // For external URLs, open in a new tab
      window.open(url, "_blank");
    } else {
      // For internal URLs
      navigate(url, { state });
    }
  };

  function transformData(steps) {
    return steps.map((step) => {
      const groupedSubSteps = {};

      // Filter out inactive substeps
      const activeSubSteps = step.subSteps.filter((subStep) => subStep.active);

      // Group active substeps by stringId or name
      activeSubSteps.forEach((subStep) => {
        const key = subStep.stringId || subStep.name;
        if (!groupedSubSteps[key]) {
          groupedSubSteps[key] = {
            stringId: key,
            name: subStep.name,
            subSubSteps: [],
          };
        }
        groupedSubSteps[key].subSubSteps.push(subStep);
      });

      // Calculate progress for each grouped substep
      const newSubSteps = Object.values(groupedSubSteps).map((group) => {
        const total = group.subSubSteps.length;
        const completed = group.subSubSteps.filter(
          (ss) =>
            ss.status === "completed" ||
            (Number(ss.status) >= 1 && Number(ss.status) <= 12)
        ).length;
        const progress = (completed / total) * 100; // as percentage

        return {
          ...group,
          progress,
        };
      });

      return {
        ...step,
        subSteps: newSubSteps,
      };
    });
  }
  const transformedSteps = transformData(steps);
  // console.log("NEW STRUCTURE", transformedSteps);
  return (
    <Accordion
      type="single"
      collapsible
      className="tw-w-full"
      value={openStep} // Controlled value
      onValueChange={(value) => setOpenStep(value)}
    >
      {transformedSteps.map((step, subIndex) => (
        <AccordionItem value={`item-${subIndex}`} key={subIndex}>
          <AccordionTrigger
            style={{ backgroundColor: "white", border: "none" }}
          >
            <div className="tw-flex tw-items-center tw-justify-between tw-w-full">
              <span>{`${categoryIndex + 1}.${subIndex + 1} ${step.name}`}</span>
              <span className="tw-text-sm tw-text-muted-foreground">
                {calculateProgress(steps[subIndex].subSteps).toFixed(0)}%
              </span>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <ul className="tw-space-y-2 tw-list-none">
              {step.subSteps.map((subStep, taskIndex) => {
                // Check if subStep has subSubSteps with length > 1
                if (subStep.subSubSteps && subStep.subSubSteps.length > 1) {
                  // Render SubStepAccordion component
                  return (
                    <SubStepAccordion
                      key={taskIndex}
                      subStep={subStep}
                      categoryIndex={categoryIndex}
                      subIndex={subIndex}
                      taskIndex={taskIndex}
                      nextTask={nextTask}
                      handleMarkAsDone={handleMarkAsDone}
                      handleSkip={handleSkip}
                      handleNavigation={handleNavigation}
                      loading={loading}
                    />
                  );
                } else {
                  // If subSubSteps length is 1 or no subSubSteps, render as before
                  let renderSubStep = subStep;
                  if (subStep.subSubSteps && subStep.subSubSteps.length === 1) {
                    renderSubStep = subStep.subSubSteps[0];
                  }

                  return (
                    <li
                      key={taskIndex}
                      className="tw-flex tw-items-center tw-gap-2 tw-p-1"
                    >
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <div className="tw-flex tw-items-center tw-gap-2">
                            <span className="tw-text-sm tw-text-muted-foreground tw-w-10">
                              {`${categoryIndex + 1}.${subIndex + 1}.${
                                taskIndex + 1
                              }`}
                            </span>
                            {renderSubStep.status === "skipped" ? (
                              <Circle className="tw-h-4 tw-w-4 tw-text-yellow-500" />
                            ) : renderSubStep.status === "completed" ||
                              (Number(renderSubStep.status) >= 1 &&
                                Number(renderSubStep.status) <= 12) ? (
                              <CheckCircle2 className="tw-h-4 tw-w-4 tw-text-green-500" />
                            ) : (
                              <Circle className="tw-h-4 tw-w-4 tw-text-gray-300" />
                            )}
                            <span
                              className={
                                renderSubStep.status === "completed" ||
                                (Number(renderSubStep.status) >= 1 &&
                                  Number(renderSubStep.status) <= 12)
                                  ? "tw-line-through tw-text-gray-500"
                                  : renderSubStep.status === "skipped"
                                  ? "tw-text-gray-400"
                                  : ""
                              }
                            >
                              {renderSubStep.name}
                            </span>
                            {nextTask &&
                              nextTask.catIndex === categoryIndex &&
                              nextTask.step === step.name &&
                              nextTask.subStep === renderSubStep.name && (
                                <Badge variant="secondary" className="tw-ml-2">
                                  Next Task
                                </Badge>
                              )}
                          </div>
                        </DropdownMenuTrigger>
                        <DropDownContent
                          task={renderSubStep}
                          handleMarkAsDone={handleMarkAsDone}
                          handleSkip={handleSkip}
                          handleNavigation={handleNavigation}
                          loading={loading}
                        />
                      </DropdownMenu>
                    </li>
                  );
                }
              })}
            </ul>
          </AccordionContent>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default StepAccordion;
