import { ResponsiveLine } from "@nivo/line";
import { Button, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faCircle } from "@fortawesome/free-solid-svg-icons";
import { getOfficeName } from "./chartHelpers";
import UploadDataModal from "./UploadDataModal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function LineChart({ data, units, title, titleSize, colors, urlAddData }) {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const mappedData = data.map((line) => ({
    ...line,
    id: getOfficeName(line.id) || "Default Office",
  }));

  const getMaxYValue = (data) => {
    let maxY = 0;
    data.forEach((line) => {
      line.data.forEach((point) => {
        if (point.y > maxY) {
          maxY = point.y;
        }
      });
    });
    return maxY;
  };
  const maxY = getMaxYValue(mappedData);

  const handleModal = () => {
    if (!urlAddData) {
      setShowModal(true);
    } else {
      navigate(urlAddData);
    }
  };
  return (
    <>
      <div style={{ height: "360px" }}>
        {titleSize ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h6>{title}</h6>
          </div>
        ) : (
          <h5>{title}</h5>
        )}
        {/* {!allDataIsZero ? ( */}
        {data && data.length > 0 ? (
          <ResponsiveLine
            data={mappedData}
            margin={{ top: 20, right: 200, bottom: 100, left: 100 }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "0",
              max: maxY + maxY / 5,
              stacked: false,
              reverse: false,
            }}
            curve="natural"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: "bottom",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Month",
              legendOffset: 36,
              legendPosition: "middle",
            }}
            axisLeft={{
              orient: "left",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: units || "CO₂e (tonnes)",
              legendOffset: -40,
              legendPosition: "middle",
            }}
            colors={colors}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh={true}
            tooltip={({ point }) => {
              // console.log(point);
              return (
                <Card style={{ opacity: 0.6 }}>
                  <div style={{ textAlign: "center" }}>{point.serieId}</div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      padding: 3,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: 2,
                        alignItems: "center",
                        paddingLeft: 8,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCircle}
                        className="me-2"
                        style={{ color: point.serieColor }}
                      />
                      <div>{point.data.x}:</div>
                      <div style={{ paddingRight: 8 }}>
                        <strong>{point.data.y.toFixed(3)}</strong> {units}
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: "0.7em",
                        color: "#666",
                        textAlign: "center",
                      }}
                    >
                      {/* Click to update Bar Chart */}
                    </div>
                  </div>
                </Card>
              );
            }}
            legends={[
              {
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 200,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 180,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <div>
              <p>No data available</p>
            </div>
            <div>
              {" "}
              <Button size="sm" onClick={() => handleModal()}>
                <FontAwesomeIcon icon={faAdd} /> Add Data
              </Button>
            </div>
          </div>
        )}
      </div>
      <UploadDataModal
        showSpendDataModal={showModal}
        toggleSpendDataModal={() => setShowModal(false)}
      />
    </>
  );
  //   )
}

export default LineChart;
