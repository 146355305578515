import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import BarChart from "./BarChart";
import PieChart from "./PieChart";
import { wasteCategories } from "./chartHelpers";

function WasteCategoriesCharts({
  sortedPieData,
  monthlyWasteEmissionData,
  monthlyWasteWeightData,
}) {
  const [clickedId, setClickedId] = useState(
    sortedPieData?.length > 0 ? sortedPieData[0]?.label : ""
  );
  // const [colorId, setColorId] = useState(0);
  let units = "Tonnes of CO₂e";
  const [showWeight, setShowWeight] = useState(false);

  // const roundedPieData = []
  const roundedPieData = sortedPieData?.map(
    ({ id, label, value, weight, color }, index) => {
      let roundedWeight = 0;
      if (weight) {
        roundedWeight = weight?.toFixed(2);
      }
      return {
        id,
        label,
        value: showWeight ? roundedWeight : value?.toFixed(2),
        weight: weight,
        idColor: index,
        color,
      };
    }
  );

  let allCategoriesData = monthlyWasteEmissionData;
  let monthlyGraph = monthlyWasteEmissionData;
  if (showWeight) {
    allCategoriesData = monthlyWasteWeightData;
    monthlyGraph = monthlyWasteWeightData;
    units = "Tonnes of Waste";
  }

  // const calculatorRoundData = []
  const calculatorRoundData = allCategoriesData.map((item) => {
    const roundedItem = { month: item.month };
    wasteCategories?.forEach((category) => {
      if (typeof item[category] === "number") {
        roundedItem[category] = parseFloat(item[category]?.toFixed(2));
        const colorKey = `${category}Color`;
        if (item[colorKey]) {
          roundedItem[colorKey] = item[colorKey];
        }
      }
    });
    return roundedItem;
  });
  const activeKeys = [];
  calculatorRoundData.forEach((data) => {
    wasteCategories.forEach((category) => {
      if (data[category] > 0 && !activeKeys.includes(category)) {
        activeKeys.push(category);
      }
    });
  });

  const handlePieClick = (id, idColor) => {
    setClickedId(id);
    // setColorId(idColor);
  };

  // const filteredData = []
  const filteredData = monthlyGraph?.map((data) => {
    return {
      month: data.month,
      [clickedId]: data[clickedId]?.toFixed(2) || 0,
      [`${clickedId}Color`]: data[`${clickedId}Color`] || "#ccc",
    };
  });

  const keys = clickedId ? [clickedId] : [];
  // const dynamicColor = colorId !== null ? colors[colorId] : "#ccc";
  const selectedUnit = showWeight ? "tonnes" : "CO₂ Emissions";
  const marginPie = { top: 40, bottom: 80, left: 100, right: 20 };
  return (
    <>
      <Row className="mb-4">
        <h5>Waste by Category in {selectedUnit}</h5>
        <div>
          {" "}
          <Form>
            <Form.Check
              type="switch"
              id="custom-switch"
              label={
                showWeight
                  ? "Click to show CO₂ Emissions"
                  : "Click to show Waste Weight"
              }
              checked={showWeight}
              onChange={() => setShowWeight(!showWeight)}
            />
          </Form>
        </div>
        <BarChart
          data={calculatorRoundData}
          // colorTheme={colors}
          keys={activeKeys}
          title={"All Waste Category Data"}
          skipTotals={true}
          titleSize={true}
          units={units}
          urlAddData="/welcome/data/climate-active/waste-form"
        />
      </Row>
      <Row className="mb-4">
        <Col lg={6}>
          {" "}
          <PieChart
            pieData={roundedPieData}
            // colorTheme={colors}
            onClick={handlePieClick}
            title={`Category Data in ${units}`}
            units={units}
            titleSize={true}
            margin={marginPie}
            urlAddData="/welcome/data/climate-active/waste-form"
          />
        </Col>
        <Col lg={6}>
          <BarChart
            data={filteredData}
            keys={keys}
            title={`${clickedId} Data`}
            small={true}
            units={units}
            skipTotals={true}
            titleSize={true}
            urlAddData="/welcome/data/climate-active/waste-form"
          />
        </Col>
      </Row>
    </>
  );
}

export default WasteCategoriesCharts;
