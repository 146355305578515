import React, { useState, useEffect } from "react";
import { Button, Form, Row, Col, Modal } from "react-bootstrap";
import {
  countryCodeList,
  countryStateDataJSON,
} from "../../data/CountryStateData";
import { useForm } from "react-hook-form";

const LocationModal = ({
  showModal, // Control modal visibility
  handleCloseModal, // Function to close modal
  // locationData, // Data for edit mode (optional)
  handleSave, // Function to save location
  location, // Location form data
  setLocation, // Set location form data
  mode = "add", // Default to "add"; use "edit" for editing mode
}) => {
  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      locationName: "",
      country: "",
      countryCode: "",
      state: "",
      stateCode: "",
      streetAddress: "",
      city: "",
      postcode: "",
    },
  });
  const [countryData] = useState(countryCodeList());
  const [stateData, setStateData] = useState([]);
  const [showState, setShowState] = useState(false);
  const [countryStateJSON] = useState(countryStateDataJSON());

  // Populate form fields based on mode
  useEffect(() => {
    if (showModal && mode === "edit" && location) {
      reset(location); // Populate form with initial values for editing
    } else if (showModal && mode === "add") {
      reset(); // Clear form for add mode
    }
  }, [showModal, mode, location, reset]);

  const handleCountryChange = (event) => {
    const selectedCountryName = event.target.value;
    const selectedCountry = countryData.find(
      (data) => data.name === selectedCountryName
    );

    const statesForCountry = countryStateJSON[selectedCountryName];
    setShowState(statesForCountry && statesForCountry.length > 0);
    setStateData(statesForCountry || []);

    setValue("country", selectedCountryName);
    setValue("countryCode", selectedCountry?.code || "");
    setValue("state", "");
    setValue("stateCode", "");

    if (clearErrors) clearErrors("country");
  };

  const handleStateChange = (event) => {
    const selectedStateName = event.target.value;
    const selectedState = stateData.find(
      (data) => data.name === selectedStateName
    );

    setValue("state", selectedStateName);
    setValue("stateCode", selectedState?.code || "");

    if (clearErrors) clearErrors("state");
  };

  const handleSubmitClick = async () => {
    const isValid = await trigger(); // Validate all form fields
    const formData = getValues(); // Get form data
    if (isValid) {
      handleSave(formData); // Calls `onAddLocationSubmit` with validated `formData`
      handleCloseModal(); // Closes the modal after saving
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {mode === "add" ? "Add New Location" : "Edit Location Details"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          {/* Location Name */}
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Location Name</Form.Label>
                <Form.Control
                  type="text"
                  name="locationName"
                  autoComplete="locationName"
                  {...register("locationName", {
                    required: "Location name is required",
                  })}
                  // onChange={(e) =>
                  //   setLocation({
                  //     ...location,
                  //     locationName: e.target.value,
                  //   })
                  // }
                  isInvalid={errors.locationName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.locationName?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          {/* Country and State */}
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Country</Form.Label>
                <Form.Control
                  as="select"
                  name="country"
                  autoComplete="country"
                  {...register("country", { required: "Country is required" })}
                  onChange={handleCountryChange}
                  isInvalid={errors.country}
                >
                  <option value="">Select the country</option>
                  {countryData.map((data) => (
                    <option key={data.code} value={data.name}>
                      {data.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.country?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              {showState && (
                <Form.Group>
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    as="select"
                    name="state"
                    autoComplete="state"
                    {...register("state", { required: "State is required" })}
                    onChange={handleStateChange}
                    isInvalid={errors.state}
                  >
                    <option value="">Select state</option>
                    {stateData.map((data) => (
                      <option key={data.code} value={data.name}>
                        {data.name}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.state?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
            </Col>
          </Row>

          {/* Street Address */}
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Street Address</Form.Label>
                <Form.Control
                  type="text"
                  name="streetAddress"
                  autoComplete="streetAddress"
                  {...register("streetAddress", {
                    required: "Street address is required",
                  })}
                  // onChange={(e) =>
                  //   setLocation({
                  //     ...location,
                  //     streetAddress: e.target.value,
                  //   })
                  // }
                  isInvalid={errors.streetAddress}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.streetAddress?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          {/* City and Postcode */}
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  autoComplete="city"
                  {...register("city", { required: "City is required" })}
                  // onChange={(e) =>
                  //   setLocation({ ...location, city: e.target.value })
                  // }
                  isInvalid={errors.city}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.city?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Postcode</Form.Label>
                <Form.Control
                  type="number"
                  name="postcode"
                  autoComplete="postcode"
                  {...register("postcode", {
                    required: "Valid postcode is required",
                  })}
                  // onChange={(e) =>
                  //   setLocation({ ...location, postcode: e.target.value })
                  // }
                  isInvalid={errors.postcode}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.postcode?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          {/* Hidden Inputs for countryCode and stateCode */}
          <input type="hidden" {...register("countryCode")} />
          <input type="hidden" {...register("stateCode")} />

          {/* Submit Button */}
          <Button className="mt-3" type="button" onClick={handleSubmitClick}>
            {mode === "add" ? "Add Location" : "Save Changes"}
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default LocationModal;
