import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Form, InputGroup } from "react-bootstrap"; // Added Button component
import Flex from "../common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SimpleBarReact from "simplebar-react";
import {
  faFileClipboard,
  faFileInvoiceDollar,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import SoftBadge from "../common/SoftBadge";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EmissionsBoundariesContext } from "../../context/Context";
import {
  updateEmissionBoundaryAmountAUD,
  updateEmissionBoundaryAmountCO2e,
} from "./EmissionsBoundariesApi";

const ICONS_MAP = {
  faFileInvoiceDollar: faFileInvoiceDollar,
  faFileClipboard: faFileClipboard,
};

const SingleItem = ({ icon, title, type, description, link }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(link); // Navigate to the specified link when clicked
  };

  return (
    <div
      className="border border-1 border-300 rounded-2 p-3 ask-analytics-item position-relative mb-3"
      onClick={handleClick}
    >
      <Flex alignItems="center" className="mb-3">
        <FontAwesomeIcon icon={icon} className="text-primary" />
        <h5 className="fs--1 text-600 mb-0 ps-3">{title}</h5>
        <SoftBadge
          bg={type === "Activity" ? "warning" : "danger"}
          className="ms-2"
        >
          {type}
        </SoftBadge>
      </Flex>
      <h5 className="fs--1 text-800">{description}</h5>
    </div>
  );
};

SingleItem.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const RenderLoadingSpinner = (isLoading) => {
  return isLoading ? (
    <div className="spinner-border spinner-border-sm" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  ) : null;
};

RenderLoadingSpinner.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

const ModalDataSourceContent = ({
  taskId,
  emissionsBoundaryId,
  dataSources,
  amountAUD,
  amountCO2e,
}) => {
  const { emissionsBoundariesDispatch } = useContext(
    EmissionsBoundariesContext
  );
  const [savedAmountAUD, setSavedAmountAUD] = useState(amountAUD);
  const [savedAmountCO2e, setSavedAmountCO2e] = useState(amountCO2e);
  const [isSavingAUD, setIsSavingAUD] = useState(false);
  const [isSavingCO2e, setIsSavingCO2e] = useState(false);

  const validateNumber = (event, setAmount) => {
    const { value } = event.target;
    // Allow float numbers with or without a leading minus sign
    const isNumber = /^-?\d*\.?\d*$/.test(value);

    if (isNumber || value === "") {
      setAmount(value);
    } else {
      toast.error("Please enter a valid number");
      setAmount("");
    }
  };

  const handleInputChange = async (id, setIsSaving, value) => {
    setIsSaving(true);

    //save to state
    emissionsBoundariesDispatch({
      type:
        id === "amountAUD"
          ? "UPDATE_EMISSIONS_BOUNDARIES_MODAL_MANUAL_INPUT_AUD"
          : "UPDATE_EMISSIONS_BOUNDARIES_MODAL_MANUAL_INPUT_CO2e",
      payload: {
        id: taskId,
        emissionsBoundaryId: emissionsBoundaryId,
        [id]: value,
      },
    });

    //save to db
    let response = "";
    if (id === "amountAUD") {
      response = await updateEmissionBoundaryAmountAUD(
        taskId,
        parseFloat(value)
      );
    } else {
      response = await updateEmissionBoundaryAmountCO2e(
        taskId,
        parseFloat(value)
      );
    }

    setIsSaving(false);

    if (response.status === "success") {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };

  return (
    <div className="pt-0 px-1">
      <div>
        <h5 className="">Add data for this year using:</h5>
        <SimpleBarReact className="ask-analytics">
          {dataSources.map((item) => (
            <SingleItem
              key={item.id}
              icon={ICONS_MAP[item.icon]}
              title={item.title}
              type={item.type}
              description={item.description}
              link={item.link}
            />
          ))}
        </SimpleBarReact>
      </div>
      {/* <div
        style={{
          // border: "1px solid #ccc",
          // borderRadius: "5px",
          backgroundColor: "#f5f5f5",
          padding: "20px",
        }}
      >
        <h5>Manual Inputs</h5>
        <Form>
          <Form.Group className="mb-3" controlId="amountAUD">
            <Form.Label>
              Spend Money Data (Press 'Enter' to save ){" "}
              {RenderLoadingSpinner(isSavingAUD)}
            </Form.Label>

            <InputGroup>
              <Form.Control
                placeholder="Enter amount"
                value={savedAmountAUD}
                onChange={(e) => {
                  validateNumber(e, setSavedAmountAUD);
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    e.stopPropagation();
                    handleInputChange(
                      "amountAUD",
                      setIsSavingAUD,
                      savedAmountAUD
                    );
                  }
                }}
              />
              <InputGroup.Text>$(AUD)</InputGroup.Text>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3" controlId="amountCO2e">
            <Form.Label>
              Emissions (Press 'Enter' to save ){" "}
              {RenderLoadingSpinner(isSavingCO2e)}
            </Form.Label>
            <InputGroup>
              <Form.Control
                placeholder="Enter amount"
                value={savedAmountCO2e}
                onChange={(e) => {
                  validateNumber(e, setSavedAmountCO2e);
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    e.stopPropagation();
                    handleInputChange(
                      "amountCO2e",
                      setIsSavingCO2e,
                      savedAmountCO2e
                    );
                  }
                }}
              />
              <InputGroup.Text>Tonnes CO2e</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Form>
      </div> */}
      <ToastContainer />
    </div>
  );
};

ModalDataSourceContent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape(SingleItem.propTypes)).isRequired,
};

export default ModalDataSourceContent;
