import React from "react";
import BusinessDetailsWizardInput from "../BusinessDetailWizard/BusinessDetailsWizardInput";

function Screen2({ register, errors }) {
  return (
    <>
      <h4>Business Operations</h4>

      {/* 4. Manufacture or sell physical goods */}
      <BusinessDetailsWizardInput
        label="Does your company manufacture or sell physical goods?"
        name="manufactureOrSellGoods"
        type="select"
        options={["Yes", "No"]}
        placeholder="Select an option"
        errors={errors}
        formGroupProps={{ className: "mb-3" }}
        formControlProps={{
          ...register("manufactureOrSellGoods", {
            // required: "This field is required",
          }),
          className: "w-25",
        }}
        helperText="If the company deals with physical goods, it can receive its own emissions factor and measurement, improving emissions profile and communication with clients."
      />

      {/* 5. Own or lease vehicles */}
      <BusinessDetailsWizardInput
        label="Does the company own or lease any vehicles?"
        name="ownOrLeaseVehicles"
        type="select"
        options={["Yes", "No"]}
        placeholder="Select an option"
        errors={errors}
        formGroupProps={{ className: "mb-3" }}
        formControlProps={{
          ...register("ownOrLeaseVehicles", {
            // required: "This field is required",
          }),
          className: "w-25",
        }}
      />

      {/* 6. Lease spaces */}
      <BusinessDetailsWizardInput
        label="Does the company own or lease any offices, warehouses, or other spaces to conduct business?"
        name="leaseSpaces"
        type="select"
        options={["Yes", "No"]}
        placeholder="Select an option"
        errors={errors}
        formGroupProps={{ className: "mb-3" }}
        formControlProps={{
          ...register("leaseSpaces", {
            // required: "This field is required",
          }),
          className: "w-25",
        }}
      />

      {/* 7. Remote employees */}
      <BusinessDetailsWizardInput
        label="Are there any employees working remotely or with flexible work arrangements?"
        name="remoteEmployees"
        type="select"
        options={["Yes", "No"]}
        placeholder="Select an option"
        errors={errors}
        formGroupProps={{ className: "mb-3" }}
        formControlProps={{
          ...register("remoteEmployees", {
            // required: "This field is required",
          }),
          className: "w-25",
        }}
        helperText="We will customize your experience and provide you tailored calculators. This will determine if emissions from working from home and commuting are accounted for."
      />

      {/* Removed the Reporting Period field as per your request */}
    </>
  );
}

export default Screen2;
