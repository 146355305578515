import React from "react";
import { Label } from "../shadcn/Label";
import YearSelector from "./YearSelector";

const HomepageHeader = ({
  measurementYear,
  currentInventoryProgress,
  onMeasurementYearSelection,
  onAddNewPeriod,
}) => {
  // console.log("measurementYear", measurementYear);
  return (
    <div className="tw-mb-6 tw-flex tw-items-center tw-justify-between">
      <h1 className="tw-text-3xl tw-font-bold">
        {/* Carbon Accounting for {measurementYear.split(" ")[0]} */}
      </h1>
      <div className="tw-flex tw-items-center tw-gap-2 tw-align-middle">
        <Label
          htmlFor="reporting-period"
          className="tw-inline-flex tw-items-center tw-pt-2"
        >
          Reporting Period:
        </Label>
        <YearSelector
          measurementYearDisplay={measurementYear}
          currentInventoryProgress={currentInventoryProgress}
          onMeasurementYearSelection={onMeasurementYearSelection}
          onAddNewPeriod={onAddNewPeriod}
        />
      </div>
    </div>
  );
};

export default HomepageHeader;
