import "react-toastify/dist/ReactToastify.css";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import {
  getDatabase,
  ref as dbRef,
  child,
  push,
  update,
} from "firebase/database";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import app from "../firebase";

const fireStoreDB = getFirestore(app);
const database = getDatabase(app);

export const storeFiles = async (files, setProgress, currentUser) => {
  const uploadedFiles = [];

  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const fileProgress = { index: i, name: file.name, progress: 0 }; // Progress state for each file
    // console.log("uploading file...", i + 1, "of", files.length);
    const uploadResult = await new Promise((resolve, reject) => {
      const storage = getStorage(app);
      const userFilePath = `users/${currentUser.uid}/${file.name}`;
      const storageRef = ref(storage, userFilePath);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const prog = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          fileProgress.progress = prog; // Update the progress state for the file
          setProgress((prevProgress) => {
            // Update the progress state for all files
            const updatedProgress = [...prevProgress];
            updatedProgress[i] = fileProgress;
            return updatedProgress;
          });
        },
        (error) => {
          console.log(error);
          reject(error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            const uploadData = {
              url: downloadURL,
              user: currentUser.uid,
            };
            // console.log("uploadData", uploadData);

            //create new key for the file --> used as document id in SQL
            const newPostKey = push(child(dbRef(database), "Files")).key;
            const updates = {};
            updates[`/Files/${newPostKey}`] = uploadData;
            update(dbRef(database), updates);

            const docRef = await addDoc(
              collection(fireStoreDB, "Files"),
              uploadData
            );

            resolve({
              key: docRef.id,
              file: file,
              url: downloadURL,
            });
          } catch (error) {
            console.error(error);
            reject(error);
          }
        }
      );
    });
    // console.log("uploading file...", i + 1, "of", files.length, "done");
    // console.log("uploadResult", uploadResult);
    uploadedFiles.push(uploadResult);
  }

  return uploadedFiles;
};
