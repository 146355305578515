import React from "react";
import { DropdownMenuContent, DropdownMenuItem } from "../shadcn/DropdownMenu";
import { CheckCircle, SkipForward, ArrowRight } from "lucide-react";
import { Spinner } from "react-bootstrap";

function DropDownContent({
  task,
  handleMarkAsDone,
  handleSkip,
  handleNavigation,
  loading,
}) {
  //   console.log(task);
  return (
    <DropdownMenuContent align="end" className="tw-z-50">
      {loading ? (
        <DropdownMenuItem disabled>
          <Spinner
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="tw-mx-auto tw-text-primary"
          />
          <span className="tw-ml-2">Loading...</span>
        </DropdownMenuItem>
      ) : (
        <>
          {task.status === "completed" || task.status === "skipped" ? (
            <DropdownMenuItem
              onClick={() =>
                handleNavigation(task.url, {
                  stringId: task.stringId,
                })
              }
            >
              View
            </DropdownMenuItem>
          ) : (
            <>
              {task.selfClickable && (
                <DropdownMenuItem
                  onClick={() => handleMarkAsDone(task.stringId, task.officeId)}
                >
                  <CheckCircle className="tw-mr-2 tw-h-4 tw-w-4" /> Mark as Done
                </DropdownMenuItem>
              )}
              {task.skippable && (
                <DropdownMenuItem
                  onClick={() => handleSkip(task.stringId, task.officeId)}
                >
                  <SkipForward className="tw-mr-2 tw-h-4 tw-w-4" /> Skip Task
                </DropdownMenuItem>
              )}
              {task.url && (
                <DropdownMenuItem
                  onClick={() =>
                    handleNavigation(task.url, {
                      stringId: task.stringId,
                    })
                  }
                >
                  <ArrowRight className="tw-mr-2 tw-h-4 tw-w-4" /> Go to Task
                </DropdownMenuItem>
              )}
            </>
          )}
        </>
      )}
    </DropdownMenuContent>
  );
}

export default DropDownContent;
