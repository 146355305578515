import React from "react";
import PageHeader from "../../../components/common/PageHeader";
import { Button, FloatingLabel, Form, Spinner } from "react-bootstrap";
import FalconComponentCard from "../../common/FalconComponentCard";
import { useLocation, useNavigate } from "react-router-dom";
import { getWaterDataByEventId, updateWaterDataById } from "./WaterAPI";
import { toast, ToastContainer } from "react-toastify";

const WaterForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [eventId, setEventId] = React.useState(location.state.id);
  const [waterId, setWaterId] = React.useState("");
  const [estimate, setEstimate] = React.useState("");
  const [actual, setActual] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    await updateWaterDataById(waterId, { eventId, actual });

    setSubmitted(true);
    setIsLoading(false);
    toast("Water data updated successfully");
    //to initiate chart data calculation
    sessionStorage.setItem("dashboardDataCalCompleted", false);
    navigate("/welcome/dashboard/event-form", {
      state: { id: eventId },
    });
  };

  React.useEffect(() => {
    const getWaterData = async () => {
      try {
        const jsonData = await getWaterDataByEventId(location.state.id);
        setWaterId(jsonData.id);
        setEstimate(jsonData.lts_of_water_estimate);
        setActual(
          jsonData.lts_of_water_actual ? jsonData.lts_of_water_actual : 0
        );
        if (jsonData.completion_status === "Completed") {
          setSubmitted(true);
        }
      } catch (err) {
        console.error(err.message);
      }
    };
    getWaterData();
  }, [location]);

  return (
    <>
      <PageHeader title="Water" description="" className="mb-3"></PageHeader>

      <FalconComponentCard>
        {submitted ? (
          <FalconComponentCard.WaterDataHeader title="Estimate" light={false} />
        ) : (
          <FalconComponentCard.Header title="Estimate" light={false} />
        )}

        <FalconComponentCard.Body>
          <Form.Group className="mb-3">
            <Form.Label>Litres Of Water</Form.Label>
            <FloatingLabel label="Assumption is 36L/person/day">
              <Form.Control
                type="number"
                name="estimate"
                value={estimate}
                disabled={true}
              />
            </FloatingLabel>
          </Form.Group>
        </FalconComponentCard.Body>
      </FalconComponentCard>

      <FalconComponentCard>
        {submitted ? (
          <FalconComponentCard.WaterDataHeader title="Actual" light={false} />
        ) : (
          <FalconComponentCard.Header title="Actual" light={false} />
        )}
        <FalconComponentCard.Body>
          <Form.Group className="mb-3">
            <Form.Label>Litres Of Water</Form.Label>
            <FloatingLabel label="Enter water recorded by venue">
              <Form.Control
                required
                type="number"
                name="actual"
                value={actual}
                disabled={submitted}
                onChange={(e) => setActual(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          {isLoading && (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}

          {!isLoading && !submitted && (
            <Form.Group className="mb-3">
              <Button className="w-100" type="submit" onClick={handleSubmit}>
                Submit
              </Button>
            </Form.Group>
          )}
        </FalconComponentCard.Body>
      </FalconComponentCard>
      <ToastContainer />
    </>
  );
};

export default WaterForm;
