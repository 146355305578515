import React, { useReducer } from "react";
import PropTypes from "prop-types";
import { EmissionsBoundariesContext } from "../../context/Context";
import { emissionsBoundariesReducer } from "../../reducers/emissionsBoundariesReducer";

const EmissionsBoundariesProvider = ({ children }) => {
  const initData = {
    emissionsBoundaries: [],
    emissionsBoundaryModal: {
      show: false,
      modalContent: {
        emissionsBoundaryId: 0,
        id: 0,
        title: "",
        name: "",
        dataSources: [],
        description: "",
        locationList: [],
        selectedLocations: [],
        amountAUD: "",
        amountCO2e: "",
        reasoning: "",
        showWarning: false,
      },
    },
  };

  const [emissionsBoundariesState, emissionsBoundariesDispatch] = useReducer(
    emissionsBoundariesReducer,
    initData
  );

  return (
    <EmissionsBoundariesContext.Provider
      value={{ emissionsBoundariesState, emissionsBoundariesDispatch }}
    >
      {children}
    </EmissionsBoundariesContext.Provider>
  );
};

EmissionsBoundariesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EmissionsBoundariesProvider;
