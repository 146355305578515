import React, { useEffect, useState } from "react";
import { Spinner, Form, Card, Col, Row, Modal, Button } from "react-bootstrap";
import AdvanceTableWrapper from "../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../common/advance-table/AdvanceTable";
import AdvanceTableSearchBox from "../common/advance-table/AdvanceTableSearchBox";
import AdvanceTableFooter from "../common/advance-table/AdvanceTableFooter";
import WidgetSectionTitle from "../../widgets/WidgetSectionTitle";
import IconButton from "../../components/common/IconButton";
import { faEye, faList } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
  orderBy,
  startAfter,
  limit,
} from "firebase/firestore";
import app from "../../firebase";
import { fetchSuppliersFromFirebaseByNameSearch } from "../../helpers/suppliersHelper";

const fireStoreDB = getFirestore(app);

const SuppliersView = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [primaryCategory, setPrimaryCategory] = useState();
  const [showSupplierByCategory, setShowSupplierByCategory] = useState(false);
  const [showViewSupplierData, setShowViewSupplierData] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState();

  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const loadMoreSuppliers = async () => {
    await searchSupplier(lastVisible);
    setCurrentPage(currentPage + 1);
  };

  const DISPLAY_PAGE_SIZE = 10;
  const PAGE_SIZE = 100;

  async function searchSupplier(lastVisible) {
    let suppliersList = [];
    try {
      setIsLoading(true);
      let qry = query(
        collection(fireStoreDB, "Suppliers"),
        // where("status", "==", "approved"),
        orderBy("name"),
        limit(PAGE_SIZE)
      );

      if (lastVisible) {
        qry = query(
          collection(fireStoreDB, "Suppliers"),
          // where("status", "==", "approved"),
          orderBy("name"),
          startAfter(lastVisible),
          limit(PAGE_SIZE)
        );
      }

      const querySnapshot = await getDocs(qry);
      querySnapshot.forEach((queryDoc) => {
        suppliersList.push({ id: queryDoc.id, data: queryDoc.data() });
      });

      setSuppliers((prevSuppliers) => [...prevSuppliers, ...suppliersList]);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setHasMore(suppliersList.length === PAGE_SIZE);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  async function handleSearch() {
    setIsLoading(true);

    let suppliersList = [];
    const result = await fetchSuppliersFromFirebaseByNameSearch(searchTerm);
    // console.log("result", result);

    result.forEach((supplier) => {
      suppliersList.push({ id: supplier.id, data: supplier });
    });

    setSuppliers(suppliersList);

    setIsLoading(false);
  }

  function handleOpenSupplierByCategory(category) {
    setPrimaryCategory(category);
    setShowSupplierByCategory(true);
  }

  function handleCloseSupplierByCategory() {
    setShowSupplierByCategory(false);
  }

  function handleViewJsonOpen(supplier) {
    setSelectedSupplier(supplier);
    setShowViewSupplierData(true);
  }

  function handleViewJsonClose(isRefresh) {
    if (isRefresh) {
      searchSupplier();
    }
    setShowViewSupplierData(false);
  }

  useEffect(() => {
    searchSupplier();
  }, []);

  async function updateSupplierStatus(row, status) {
    console.log(row.id, status);
    try {
      const supplierRef = doc(fireStoreDB, "Suppliers", row.id);
      await updateDoc(supplierRef, { status: status });
      searchSupplier();
    } catch (error) {
      console.log(error);
    }
  }

  const columns = [
    {
      accessor: "",
      Header: "Action",
      Cell: (rowData) => {
        return (
          <>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon={faEye}
              transform="shrink-3"
              onClick={() => handleViewJsonOpen(rowData.row.original)}
            ></IconButton>
          </>
        );
      },
    },
    {
      accessor: "data.name",
      Header: "Name",
      cellProps: {
        className: "text-wrap fw-semi-bold",
      },
    },
    {
      accessor: "data.email",
      Header: "Email",
      cellProps: {
        className: "text-wrap",
      },
    },
    {
      accessor: "data.country",
      Header: "Country",
      cellProps: {
        className: "text-wrap",
      },
    },
    {
      accessor: "data.netnadaId",
      Header: "Business Id",
      cellProps: {
        className: "text-wrap",
      },
    },
    {
      accessor: "data.primaryCategory",
      Header: "Primary Category",
      headerProps: { className: "pe-1" },
    },
    {
      accessor: "data.secondaryCategory",
      Header: "Secondary Category",
      cellProps: {
        className: "text-wrap",
      },
    },
    {
      accessor: "data.status",
      Header: "Status",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "fw-semi-bold",
      },
      Cell: (rowData) => {
        const row = rowData.row.original;
        const { status } = row.data;

        return (
          <Form.Select
            size="sm"
            className="w-auto"
            onChange={(e) => {
              console.log(e.target.value);
              updateSupplierStatus(row, e.target.value);
            }}
            value={status}
          >
            <option value="drafted">Drafted</option>
            <option value="approved">Approved</option>
            <option value="rejected">Rejected</option>
          </Form.Select>
        );
      },
    },
  ];

  return (
    <>
      <WidgetSectionTitle
        icon={faList}
        title="Suppliers"
        subtitle="Suppliers and categories"
        transform="shrink-2"
        className="mb-4 mt-6"
      />
      {showViewSupplierData && (
        <ViewSupplierModal
          showModal={showViewSupplierData}
          handleClose={handleViewJsonClose}
          supplierData={selectedSupplier}
        />
      )}
      {isLoading && suppliers.length === 0 ? (
        <div className="mb-3">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className="mb-3">
          <Form className="d-flex mb-3">
            <Form.Control
              type="text"
              placeholder="Search suppliers by name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />

            <Button variant="primary" onClick={handleSearch}>
              Search
            </Button>
          </Form>
          <AdvanceTableWrapper
            columns={columns}
            data={suppliers}
            sortable
            pagination={true}
            perPage={DISPLAY_PAGE_SIZE}
            rowCount={suppliers.length}
          >
            <Card>
              <Card.Header>
                <Row className="flex-start-center mb-1">
                  <Col xs={6} lg={8}></Col>
                  <Col xs={6} lg={4}>
                    <AdvanceTableSearchBox table />
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="p-0">
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="btn-reveal-trigger text-nowrap align-middle"
                  tableProps={{
                    size: "lg",
                    className: "fs--1 mb-0 overflow-hidden",
                  }}
                />
              </Card.Body>
              <Card.Footer>
                <AdvanceTableFooter
                  data={suppliers}
                  rowCount={suppliers.length}
                  table
                  rowInfo
                  navButtons={true}
                />
                <br />
                {hasMore && (
                  <Button
                    variant="primary"
                    className="w-100"
                    onClick={loadMoreSuppliers}
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading..." : "Load More"}
                  </Button>
                )}
              </Card.Footer>
            </Card>
          </AdvanceTableWrapper>
        </div>
      )}
      {showSupplierByCategory && (
        <SupplierByPrimaryCategoryModal
          showSupplierByPrimaryCategoryModal={showSupplierByCategory}
          handleClose={handleCloseSupplierByCategory}
          primaryCategory={primaryCategory}
        />
      )}
    </>
  );
};

export default SuppliersView;

const SupplierByPrimaryCategoryModal = ({
  showSupplierByPrimaryCategoryModal,
  handleClose,
  primaryCategory,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const columns = [
    {
      accessor: "name",
      Header: "Name",
      cellProps: {
        className: "text-wrap",
      },
    },
    {
      accessor: "email",
      Header: "Email",
      cellProps: {
        className: "text-wrap",
      },
    },
    {
      accessor: "country",
      Header: "Country",
      cellProps: {
        className: "text-wrap",
      },
    },
    {
      accessor: "netnadaId",
      Header: "Business Id",
      cellProps: {
        className: "text-wrap",
      },
    },
  ];

  async function searchSupplierByPrimaryCategory(primaryCategory) {
    setIsLoading(true);
    let suppliersList = [];
    try {
      let qry = query(
        collection(fireStoreDB, "Suppliers"),
        where("primaryCategory", "==", primaryCategory)
      );
      const querySnapshot = await getDocs(qry);
      querySnapshot.forEach(async (queryDoc) => {
        console.log(queryDoc.id, "=>", queryDoc.data());
        suppliersList.push(queryDoc.data());
      });
    } catch (error) {
      console.log(error);
    }

    setSuppliers(suppliersList);
    setIsLoading(false);
  }

  useEffect(() => {
    searchSupplierByPrimaryCategory(primaryCategory);
  }, [primaryCategory]);

  return (
    <Modal
      show={showSupplierByPrimaryCategoryModal}
      onHide={handleClose}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Suppliers by category
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <div className="mb-3">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <AdvanceTableWrapper
            columns={columns}
            data={suppliers}
            sortable
            pagination
            perPage={10}
            rowCount={suppliers.length}
          >
            <Card>
              <Card.Body className="p-0">
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="btn-reveal-trigger text-nowrap align-middle"
                  tableProps={{
                    size: "lg",
                    className: "fs--1 mb-0 overflow-hidden",
                  }}
                />
              </Card.Body>
              <Card.Footer>
                <AdvanceTableFooter
                  data={suppliers}
                  rowCount={suppliers.length}
                  table
                  rowInfo
                  navButtons
                />
              </Card.Footer>
            </Card>
          </AdvanceTableWrapper>
        )}
      </Modal.Body>
    </Modal>
  );
};

const ViewSupplierModal = ({ showModal, handleClose, supplierData }) => {
  const [supplierId, setSupplierId] = useState();
  const [supplierJsonString, setSupplierJsonString] = useState();
  const [supplierJsonValid, setSupplierJsonValid] = useState(true);
  // console.log("supplierData=>", supplierData);

  async function handleSubmit() {
    try {
      const jsonData = JSON.parse(supplierJsonString);
      setSupplierJsonValid(true);

      const supplierDocRef = doc(fireStoreDB, "Suppliers", supplierId);
      if (supplierDocRef) {
        await updateDoc(supplierDocRef, jsonData);
        handleClose(true);
      }
    } catch (error) {
      setSupplierJsonValid(false);
      toast.error("JSON is not valid");
    }
  }

  useEffect(() => {
    setSupplierId(supplierData.id);
    setSupplierJsonString(JSON.stringify(supplierData.data, undefined, 5));
  }, [supplierData]);

  useEffect(() => {
    try {
      JSON.parse(supplierJsonString);
      setSupplierJsonValid(true);
    } catch (error) {
      setSupplierJsonValid(false);
    }
  }, [supplierJsonString]);

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Body>
          <h5>View/Edit Supplier Data</h5>
        </Modal.Body>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Form.Control
            as="textarea"
            rows="20"
            type="text"
            style={{ color: supplierJsonValid ? "" : "red" }}
            value={supplierJsonString}
            onChange={(e) => setSupplierJsonString(e.target.value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={!supplierJsonValid}
          type="button"
          onClick={handleSubmit}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
