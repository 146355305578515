import {
  getAuthorizationHeader,
  getAuthorizationHeaderWithContentType,
} from "../../helpers/utils";
import { clearLoginData } from "../../helpers/store";
import { toast } from "react-toastify";

export const uploadFile = async (body) => {
  try {
    const response = await fetch("/api/files/upload-file", {
      method: "POST",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 401) {
      clearLoginData();
    } else if (response.status !== 200) {
      toast.error("Failed to save whitelist user");
    } else {
      toast.success(`Successfully saved.`);
    }
  } catch (error) {
    console.error(error);
  }
};
