import React, { useState } from "react";
import { Badge } from "../shadcn/Badge";
import { CheckCircle2, ChevronDown, Circle } from "lucide-react";
import { DropdownMenu, DropdownMenuTrigger } from "../shadcn/DropdownMenu";
import DropDownContent from "./DropDownContent";

function SubStepAccordion({
  categoryIndex,
  subIndex,
  taskIndex,
  step,
  nextTask,
  subStep,
  handleMarkAsDone,
  handleSkip,
  handleNavigation,
  loading,
}) {
  const [subStepOpen, setSubStepOpen] = useState(null);
  const locations = JSON.parse(sessionStorage.getItem("userBusinessLocations"));
  // console.log(locations);

  const handleTaskClick = () => {
    setSubStepOpen(subStepOpen === subStep.stringId ? null : subStep.stringId);
  };
  const totalProgressSubStep = subStep.subSubSteps.length;
  const stepsDoneorSkipped = subStep.subSubSteps.filter(
    (subSubStep) =>
      subSubStep.status === "completed" ||
      subSubStep.status === "skipped" ||
      (Number(subSubStep.status) >= 1 && Number(subSubStep.status) <= 12)
  ).length;

  const progressSubStep = (stepsDoneorSkipped / totalProgressSubStep) * 100;

  return (
    <>
      <li className="tw-flex-column tw-items-center tw-gap-2 tw-p-1">
        <div
          className={`tw-flex tw-items-center tw-gap-2 tw-rounded-md tw-hover:tw-bg-muted tw-cursor-pointer`}
          onClick={handleTaskClick}
        >
          <span className="tw-text-sm tw-text-muted-foreground tw-w-10">
            {`${categoryIndex + 1}.${subIndex + 1}.${taskIndex + 1}`}
          </span>
          {progressSubStep === 100 ? (
            <CheckCircle2 className="tw-h-4 tw-w-4 tw-text-green-500" />
          ) : progressSubStep > 0 && progressSubStep < 100 ? (
            <Circle className="tw-h-4 tw-w-4 tw-text-yellow-500" />
          ) : (
            <Circle className="tw-h-4 tw-w-4 tw-text-gray-300" />
          )}
          <span
            className={
              subStep.status === "completed" ||
              (Number(subStep.status) >= 1 && Number(subStep.status) <= 12)
                ? "tw-line-through tw-text-gray-500"
                : subStep.status === "skipped"
                ? "tw-text-gray-400"
                : ""
            }
          >
            {subStep.name}
          </span>
          {nextTask &&
            nextTask.catIndex === categoryIndex &&
            nextTask.step === step.name &&
            nextTask.subStep === subStep.name && (
              <Badge variant="secondary" className="tw-ml-2">
                Next Task
              </Badge>
            )}
          {/* Show ChevronDown if subStep has subSubSteps */}
          {subStep.subSubSteps && subStep.subSubSteps.length > 0 && (
            <ChevronDown
              className={`tw-h-4 tw-w-4 tw-ml-auto tw-transition-transform ${
                subStepOpen === subStep.stringId ? "tw-rotate-180" : ""
              }`}
            />
          )}
        </div>
        {/* Render subSubSteps if subStep is open */}
        {subStepOpen === subStep.stringId && subStep.subSubSteps && (
          <div className="tw-ml-6 tw-mt-2 tw-space-y-1">
            {subStep.subSubSteps.map((subSubStep, subSubIndex) => {
              const location = locations.find(
                (location) => location.office_id === subSubStep.officeId
              );
              return (
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <div
                      key={subSubIndex}
                      className="tw-flex tw-items-center tw-gap-2 tw-p-1 tw-rounded-md tw-hover:tw-bg-muted"
                    >
                      {subSubStep.status === "completed" ||
                      (Number(subSubStep.status) >= 1 &&
                        Number(subSubStep.status) <= 12) ? (
                        <CheckCircle2 className="tw-h-3 tw-w-3 tw-text-green-500" />
                      ) : subSubStep.status === "skipped" ? (
                        <Circle className="tw-h-3 tw-w-3 tw-text-yellow-500" />
                      ) : (
                        <Circle className="tw-h-3 tw-w-3 tw-text-gray-300" />
                      )}
                      <span className="tw-text-sm">{location?.officename}</span>
                    </div>
                  </DropdownMenuTrigger>
                  <DropDownContent
                    task={subSubStep}
                    handleMarkAsDone={handleMarkAsDone}
                    handleSkip={handleSkip}
                    handleNavigation={handleNavigation}
                    loading={loading}
                  />
                </DropdownMenu>
              );
            })}
          </div>
        )}
      </li>
    </>
  );
}

export default SubStepAccordion;
