import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import classNames from "classnames";
import Background from "../../components/common/Background";
import SoftBadge from "../../components/common/SoftBadge";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CountUp from "react-countup";
import { Spinner } from "react-bootstrap";
import {
  faCheck,
  faBan,
  faStream,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";

const badge = {
  Pending: { content: "Pending", type: "warning", icon: faStream },
};

const StatisticsCard = ({
  isParentLoading,
  showUnifiedData,
  stat,
  ...rest
}) => {
  const {
    title,
    value,
    decimal,
    suffix,
    prefix,
    valueClassName,
    linkText,
    link,
    badgeText,
    badgeBg,
    image,
    status,
    className,
  } = stat;

  const [isLoading, setIsLoading] = useState(false);

  const toSetIsLoading = (showLoading) => {
    setIsLoading(value === 0 ? false : showLoading);
  };

  return (
    <Card className={classNames(className, "overflow-hidden")} {...rest}>
      <Background image={image} className="bg-card" />
      <Card.Body className="position-relative">
        <h6>
          {isParentLoading || isLoading ? (
            <Spinner
              animation="border"
              role="status"
              size="sm"
              style={{ color: "#f5803e", marginRight: "4px" }}
            ></Spinner>
          ) : (
            <></>
          )}
          {title}
          {badgeText && (
            <SoftBadge bg={badgeBg} pill className="ms-2">
              {badgeText}
            </SoftBadge>
          )}
          {!status && (
            <SoftBadge pill bg="warning" className="ms-2">
              Pending
              <FontAwesomeIcon
                icon={faStream}
                transform="shrink-2"
                className="ms-1"
              />
            </SoftBadge>
          )}
        </h6>
        <h2 className="mb-1 font-sans-serif">
            <CountUp
              start={0}
              end={value}
              duration={2.75}
              className="text-800"
              separator=","
              decimals={decimal ? 2 : 0}
              onEnd={() => toSetIsLoading(false)}
            onStart={() => toSetIsLoading(true)}
            onUpdate={() => toSetIsLoading(true)}
            />
            
            <span className="fw-normal text-600" style={{ fontSize: "0.90em" }}>
              {" " + suffix}
            </span>
          </h2>

        {/* <div
          className={classNames(
            valueClassName,
            "display-4 fs-4 mb-2 fw-normal font-sans-serif"
          )}
        >
          <CountUp
            start={0}
            delay={0}
            end={value}
            duration={2.75}
            suffix={suffix}
            prefix={prefix}
            separator=","
            decimals={decimal ? 2 : 0}
            decimal="."
            onEnd={() => toSetIsLoading(false)}
            onStart={() => toSetIsLoading(true)}
            onUpdate={() => toSetIsLoading(true)}
          />
        </div> */}
        {/* <Link to={link} className="fw-semi-bold fs--1 text-nowrap">
          {linkText}
          <FontAwesomeIcon
            icon={faAngleRight}
            className="ms-1"
            transform="down-1"
          />
        </Link> */}
      </Card.Body>
    </Card>
  );
};

StatisticsCard.propTypes = {
  stat: PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    decimal: PropTypes.bool,
    suffix: PropTypes.string.isRequired,
    prefix: PropTypes.string.isRequired,
    valueClassName: PropTypes.string,
    linkText: PropTypes.string,
    link: PropTypes.string,
    badgeText: PropTypes.string,
    badgeBg: PropTypes.string,
    image: PropTypes.string,
    className: PropTypes.string,
  }),
};

export default StatisticsCard;
