import React, { useEffect, useState } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Dropdown,
  Form,
  Row,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const CreateHelpAndSupportDocsSubHeader = ({
  onRouteChange,
  showContent,
  setShowContent,
  onSave,
  initialRoutes,
  onCreateRoute,
  handlePreview,
}) => {
  const [routes, setRoutes] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState("");
  const [action, setAction] = useState("Publish");
  const [inputValue, setInputValue] = useState("");

  const handleRouteChange = (selectedOption) => {
    const route = selectedOption ? selectedOption.value : "";

    setSelectedRoute(route);
    setShowContent(route !== ""); // Show content only when a route is selected
    if (route) onRouteChange(route); // Call any additional function if needed
  };

  const handleCreateRoute = () => {
    const newRoute = inputValue;
    const newRouteOption = { value: newRoute, label: newRoute };

    // Add the new route to db
    onCreateRoute(newRoute);

    setRoutes([...routes, newRouteOption]);
    setSelectedRoute(newRoute); // Set selectedRoute as the string value
    onRouteChange(newRoute);
    setInputValue(""); // Reset input value
  };

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
  };

  useEffect(() => {
    // Update routes when initialRoutes changes
    if (initialRoutes && initialRoutes.length > 0) {
      setRoutes(initialRoutes); // Make sure to set the routes here
    }
  }, [initialRoutes]); // Listen for changes in initialRoutes

  return (
    <Card className="mb-3">
      <Card.Body>
        <Row>
          {/* Route Selection */}

          {/* Route Selection with Searchable Dropdown */}
          <Col md={6} className="d-flex justify-content-start">
            <CreatableSelect
              options={routes} // Use the state `routes` for options
              value={
                selectedRoute
                  ? { value: selectedRoute, label: selectedRoute }
                  : null
              }
              onChange={handleRouteChange}
              onInputChange={handleInputChange}
              inputValue={inputValue}
              placeholder="Search or add a route..."
              isClearable
              formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
              noOptionsMessage={() => "No routes found"} // Show if no routes are available
              onCreateOption={handleCreateRoute}
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: "100%", // Adjust width of the select box
                }),
                menu: (provided) => ({
                  ...provided,
                  width: "100%",
                }),
              }}
            />
          </Col>

          <Col md={6} className="d-flex justify-content-end">
            {/* Preview Button */}
            <Button
              variant="outline-primary"
              style={{ marginRight: "1rem" }}
              disabled={!showContent}
              onClick={handlePreview}
            >
              User's Preview <FontAwesomeIcon icon={faEye} />
            </Button>
            {/* Publish/Save as Draft Dropdown Button */}
            <Dropdown as={ButtonGroup}>
              <Button
                variant={action === "Publish" ? "success" : "danger"}
                onClick={() => onSave(action === "Publish")} // Assuming onSave function handles the action
                disabled={!showContent}
              >
                {action}
              </Button>

              <Dropdown.Toggle
                split
                variant={action === "Publish" ? "success" : "danger"}
                id="dropdown-split-basic"
              />

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setAction("Publish");
                    onSave(true); // Assuming onSave function handles the action
                  }}
                >
                  Publish
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setAction("Save as Draft");
                    onSave(false); // Assuming onSave function handles the action
                  }}
                >
                  Save as Draft
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CreateHelpAndSupportDocsSubHeader;
