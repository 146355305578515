import React, { useContext, useState } from "react";
import { Button, Card, Col, Row, Accordion, Spinner } from "react-bootstrap";
import { FaCloudUploadAlt, FaChevronDown } from "react-icons/fa";

import DocumentsUploadedCard from "./DocumentsUploadedCard";
import UploadAssessmentModal from "./UploadAssessmentModal";
import { TransparencyContext } from "../../context/Context";
import {
  createSupplier,
  initializeAssessment,
  updateSupplierId,
  sendLoopsEvent,
} from "./transparencyHubAPI";

const Step2TransparencyHub = () => {
  const [showUploadAssessment, setShowUploadAssessment] = useState(false);
  const { nextStep, assessmentData, supplierData, year, yearType } =
    useContext(TransparencyContext);
  const [isLoading, setIsLoading] = useState(false);
  const handleCloseUploadAssessment = () => {
    setShowUploadAssessment(false);
  };

  const handleStartAssessment = async () => {
    // console.log("CLICKED handleStartAssessment");
    setIsLoading(true);
    let supplierId = supplierData?.supplierId;
    if (!supplierId) {
      supplierId = await createSupplier(supplierData);
      console.log("New supplier", supplierId);
      await updateSupplierId(
        supplierData.netnadaId, //businessId
        supplierId
      );
    }
    await initializeAssessment(
      supplierId,
      supplierData.netnadaId,
      year,
      yearType,
      assessmentData
    );

    const status = "done";
    const eventName = "supplierHasNotUploadedDocs";
    const contactProperties = {
      supplierAssessmentStatus: status,
    };
    await sendLoopsEvent(eventName, contactProperties);
    setIsLoading(false);
    nextStep();
  };

  return (
    <>
      <Row>
        <Col md={8}>
          <Card className="mb-3">
            <Card.Header>
              <h5 className="mb-0">Add Sustainability Documents</h5>
            </Card.Header>
            <Card.Body className="bg-light">
              <h6>
                # Estimate score by uploading your sustainability and ESG
                documentation
              </h6>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <FaChevronDown className="me-2" /> Why Upload?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Our team and systems will pre-fill the answer on your
                      behalf. This will save you time and allow you to return to
                      business as usual as quickly as possible.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <FaChevronDown className="me-2" />
                    What to Upload?
                  </Accordion.Header>
                  <Accordion.Body style={{ backgroundColor: "#f8f9fa" }}>
                    {" "}
                    {/* Lighter background */}
                    <p>
                      While file names may vary, your organization will likely
                      have documents such as:
                    </p>
                    <ul
                      style={{
                        listStyleType: "disc",
                        paddingLeft: "20px",
                        lineHeight: "1.6",
                      }}
                    >
                      {" "}
                      {/* Better readability with list bullets */}
                      <li>Approved environmental policy</li>
                      <li>Carbon emissions report</li>
                      <li>Carbon Neutral Certifications</li>
                      <li>Sustainability 2030 goals</li>
                      <li>Modern slavery statement</li>
                      <li>Procurement policies</li>
                      <li>Other ESG scoring results</li>
                    </ul>
                    <p>
                      You will be able to find these published on your website
                      or internally.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Row className="justify-content-center mt-3">
                <Col md={8} lg={6}>
                  <Button
                    variant="primary"
                    size="lg"
                    className="w-100"
                    onClick={() => setShowUploadAssessment(true)}
                  >
                    <FaCloudUploadAlt className="me-2" />
                    Submit Documentation
                  </Button>
                </Col>
              </Row>

              <Row className="justify-content-center my-2">
                <Col className="text-center">
                  <div className="text-600">OR</div>
                </Col>
              </Row>
              <Row className="justify-content-center mb-3">
                <Col md={8} lg={10}>
                  <Button
                    variant="outline-primary"
                    size="lg"
                    className="w-100"
                    onClick={handleStartAssessment}
                  >
                    {isLoading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      "I do not have Sustainability Documents"
                    )}
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <DocumentsUploadedCard supplierId={supplierData?.supplierId} />
        </Col>
      </Row>
      {showUploadAssessment && (
        <UploadAssessmentModal
          showModal={showUploadAssessment}
          handleCloseModal={handleCloseUploadAssessment}
        />
      )}
    </>
  );
};

export default Step2TransparencyHub;
