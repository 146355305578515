import React, { useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import LineChart from "./LineChart";
import PieChart from "./PieChart";
import { getOfficeName } from "./chartHelpers";
import WasteDashboardTable from "./WasteDashboardTable";

const COLORS = [
  "#e8c1a0", // Nivo
  "#f47560", // Nivo
  "#f1e15b", // Nivo
  "#e8a838", // Nivo
  "#61cdbb", // Nivo
  "#8dd3c7", // Set3
  "#ffffb3", // Set3
  "#bebada", // Set3
  "#fb8072", // Set3
  "#80b1d3", // Set3
  "#fdb462", // Set3
  "#b3de69", // Set3
  "#fccde5", // Set3
  "#d9d9d9", // Set3
  "#bc80bd", // Set3
  "#ccebc5", // Set3
  "#ffed6f", // Set3
];

function WasteOfficeCharts({ yearlyWasteDataByOffice, wasteDataLineGraph }) {
  const [showWeight, setShowWeight] = useState(false);
  const selectedUnit = showWeight ? "tonnes" : "CO₂ Emissions";
  const units = showWeight ? "Tonnes of Waste" : "Tonnes of CO₂e";
  // const pieData = yearlyWasteDataByOffice[0]?.categories;
  const [clickedId, setClickedId] = useState(
    yearlyWasteDataByOffice?.length > 0
      ? yearlyWasteDataByOffice[0]?.officeId
      : ""
  );
  // console.log(yearlyWasteDataByOffice);

  const transformDataTable = (data) => {
    // First, calculate total emissions and total weight across all offices
    const totalValues = data.reduce(
      (totals, office) => {
        office?.categories?.forEach((category) => {
          totals.totalEmissions += category.value;
          totals.totalWeight += category.weight;
        });
        return totals;
      },
      { totalEmissions: 0, totalWeight: 0 }
    );

    // Then, map the data to include percentages
    return data.map((office, index) => {
      const totalEmissions = office.categories.reduce(
        (sum, category) => sum + category.value,
        0
      );
      const totalWeight = office.categories.reduce(
        (sum, category) => sum + category.weight,
        0
      );

      const divertedEmissions = office.categories.reduce(
        (sum, category) =>
          category.label !== "General waste" ? sum + category.value : sum,
        0
      );
      const divertedWeight = office.categories.reduce(
        (sum, category) =>
          category.label !== "General waste" ? sum + category.weight : sum,
        0
      );

      const totalEmissionsPercentage = (
        (totalEmissions / totalValues.totalEmissions) *
        100
      )?.toFixed(2);
      const totalWeightPercentage = (
        (totalWeight / totalValues.totalWeight) *
        100
      )?.toFixed(2);

      const categoryCount = office.categories.length;

      return {
        officeId: office.officeId,
        officeName: getOfficeName(office.officeId) || "Default Office",
        totalEmissions: +totalEmissions?.toFixed(2),
        totalEmissionsPercentage: `${totalEmissionsPercentage}%`,
        totalWeight: +totalWeight?.toFixed(2),
        totalWeightPercentage: `${totalWeightPercentage}%`,
        divertedEmissions: +divertedEmissions?.toFixed(2),
        divertedWeight: +divertedWeight?.toFixed(2),
        categoryCount,
        color: COLORS[index],
      };
    });
  };

  const tableData = transformDataTable(yearlyWasteDataByOffice);
  // console.log("Table Data", tableData);


  // Additional data transformation
  const formattedLineChartData = wasteDataLineGraph?.map((item) => ({
    ...item,
    data: item.data.map((entry) => ({
      x: entry.x,
      y: showWeight ? entry.weight : entry.y,
    })),
  }));

  const onSelect = (officeId) => {
    setClickedId(officeId);
  };

  const selectedOffice = yearlyWasteDataByOffice?.find(
    (officeEntry) => officeEntry.officeId === clickedId
  );
  const pieChartOffice = selectedOffice?.categories?.map((entry) => ({
    ...entry,
    value: showWeight ? entry.weight?.toFixed(2) : entry.value?.toFixed(2),
  }));
  const marginPie = { top: 20, bottom: 40, left: 50, right: 50 };
  // console.log(formattedLineChartData);
  return (
    <>
      <Row className="mb-4">
        <h5>Waste by Locations in {selectedUnit}</h5>
        <div>
          {" "}
          <Form>
            <Form.Check
              type="switch"
              id="custom-switch"
              label={
                showWeight
                  ? "Click to show CO₂ Emissions"
                  : "Click to show Waste Weight"
              }
              checked={showWeight}
              onChange={() => setShowWeight(!showWeight)}
            />
          </Form>
        </div>
        <LineChart
          data={formattedLineChartData}
          units={units}
          title="All Location Waste"
          titleSize={true}
          colors={COLORS}
          urlAddData="/welcome/data/climate-active/waste-form"
        />
      </Row>
      <Row className="mb-4">
        <Col lg={8}>
          <WasteDashboardTable
            tableData={tableData}
            showWeight={showWeight}
            handleSelect={onSelect}
            selectedOfficeId={clickedId}
          />
        </Col>
        <Col lg={4}>
          <PieChart
            pieData={pieChartOffice}
            title={"Office Queensland Data"}
            colorInData={true}
            units={units}
            height={300}
            margin={marginPie}
            titleSize={true}
            showLabels={false}
            urlAddData="/welcome/data/climate-active/waste-form"
          />
        </Col>
      </Row>
    </>
  );
}

export default WasteOfficeCharts;
