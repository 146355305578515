import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import TaskCard from "./TaskCard";
import AddAnotherForm from "./AddAnotherForm";
import IconButton from "../common/IconButton";
import classNames from "classnames";
import { EmissionsBoundariesContext } from "../../context/Context";
import StrictModeDroppable from "./StrictModeDroppable";
import EmissionsBoundariesColumnHeader from "./EmissionsBoundariesColumnHeader";

const EmissionsBoundariesColumn = ({ emissionsBoundary }) => {
  const { id, name, items } = emissionsBoundary;
  const [showForm, setShowForm] = useState(false);
  const formViewRef = useRef(null);
  const {
    emissionsBoundariesState: { emissionsBoundaries },
    emissionsBoundariesDispatch,
  } = useContext(EmissionsBoundariesContext);

  const handleSubmit = (cardData) => {
    const targetList = emissionsBoundaries.find((item) => item.id === id);
    const cardId = targetList.items.length
      ? Math.max(...targetList.items.map((item) => item.id)) + 1
      : Number(`${targetList.id}0`);
    const newCard = {
      id: cardId,
      title: cardData.title,
    };
    const isEmpty = !Object.keys(cardData).length;

    if (!isEmpty) {
      emissionsBoundariesDispatch({
        type: "ADD_TASK_CARD",
        payload: { targetListId: id, newCard },
      });
      setShowForm(false);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      formViewRef.current.scrollIntoView({ behavior: "smooth" });
    }, 500);

    return clearTimeout(timeout);
  }, [showForm]);

  return (
    <div
      className={classNames("kanban-column", { "form-added": showForm })}
      id={`column-${id}`}
      data-testid={`column-${id}`}
      style={{ width: "33%" }}
    >
      <EmissionsBoundariesColumnHeader title={name} itemCount={items.length} />
      <StrictModeDroppable droppableId={`${id}`} type="KANBAN">
        {(provided) => (
          <>
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              id={`container-${id}`}
              className="kanban-items-container scrollbar"
            >
              {items.map((task, index) => (
                <TaskCard
                  key={task.id}
                  index={index}
                  task={task}
                  name={name}
                  emissionsBoundaryId={id}
                />
              ))}
              {
                <AddAnotherForm
                  onSubmit={handleSubmit}
                  type="card"
                  showForm={showForm}
                  setShowForm={setShowForm}
                />
              }
              {provided.placeholder}
              <div ref={formViewRef}></div>
            </div>
            {items.length === 0 && (
              <div className="kanban-column-footer">
                {/* Drag and drop emission categories here */}
                <p
                  style={{
                    textAlign: "center",
                    color: "#6c757d",
                    fontSize: "0.875rem",
                    fontWeight: "600",
                  }}
                >
                  {" "}
                  Drag and drop emission categories here{" "}
                </p>
              </div>
            )}
          </>
        )}
      </StrictModeDroppable>
    </div>
  );
};

EmissionsBoundariesColumn.propTypes = {
  EmissionsBoundariesColumnItem: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    name: PropTypes.string,
    items: PropTypes.arrayOf(TaskCard.propTypes.task),
  }),
};

export default EmissionsBoundariesColumn;
