import React, { useContext } from "react";
import { Card } from "react-bootstrap";
import Flex from "../common/Flex";
import { FileIcon, defaultStyles } from "react-file-icon";
import { TransparencyContext } from "../../context/Context";

function DocumentsUploadedCard({ supplierId }) {
  const { assessmentData, isLoading, hasFiles } =
    useContext(TransparencyContext);

  // Check if supplierId matches the specific ID to not show documents
  const shouldHideDocuments = supplierId === "J6Mt3LD8Uasqkz32Txly";

  return (
    <Card className="mb-3">
      <Card.Header>
        <h5 className="mb-0">Sustainability Documents Uploaded</h5>
      </Card.Header>

      <Card.Body
        className="bg-light"
        style={{ maxHeight: "500px", overflowY: "auto" }}
      >
        {isLoading ? (
          <>
            <h5 className="card-text placeholder-glow">
              <span className="placeholder col-6"></span>
            </h5>
            <h5 className="card-text placeholder-glow">
              <span className="placeholder col-6"></span>
            </h5>
            <h5 className="card-text placeholder-glow">
              <span className="placeholder col-6"></span>
            </h5>
            <h5 className="card-text placeholder-glow">
              <span className="placeholder col-6"></span>
            </h5>
          </>
        ) : shouldHideDocuments ? (
          <div className="text-center p-3">
            <p>No documents are available at the moment.</p>
          </div>
        ) : hasFiles ? (
          <div>
            {assessmentData.map((survey) =>
              survey.files?.map((file, index) => (
                <a
                  href={file.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="dropdown-item"
                  key={index}
                  style={{
                    display: "block",
                    textDecoration: "none",
                    color: "inherit",
                    marginBottom: "5px",
                  }}
                >
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    style={{
                      padding: "5px",
                      borderRadius: "5px",
                      transition: "background-color 0.3s",
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.backgroundColor = "#f0f0f0";
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.backgroundColor = "transparent";
                    }}
                  >
                    <div className="file-thumbnail">
                      <FileIcon
                        size={7}
                        extension={file.fileType.toLowerCase() || ""}
                        type="image"
                        {...defaultStyles[file.fileType.toLowerCase() || ""]}
                      />
                    </div>
                    <div className="ms-3" style={{ fontSize: "12px" }}>
                      {file.fileName}
                    </div>
                  </Flex>
                </a>
              ))
            )}
          </div>
        ) : (
          <div className="text-center p-3">
            <p>No documents have been uploaded yet.</p>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

export default DocumentsUploadedCard;
