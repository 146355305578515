import React, { useEffect, useState } from "react";
import {
  Spinner,
  Form,
  Button,
  Row,
  Col,
  Card,
  Table,
  FloatingLabel,
  Modal,
  ProgressBar,
  Accordion,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import IconButton from "../common/IconButton";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";

import {
  getInventoryProgress as getInventoryProgressFromLocalStorage,
  setInventoryProgress,
  getActiveUserData,
} from "../../helpers/store.js";
import { saveOrganisationDetails } from "./organisationDetailsAPI.js";
import { useNavigate } from "react-router-dom";
import { getBusinessDetails } from "../solutions/solutionsAPI.js";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import app from "../../firebase.js";
import { useAuth } from "../../Contexts/AuthContext.js";
// import AddLocation from "../add-location/AddLocation.js";
import {
  countryCodeList,
  countryStateDataJSON,
  CountryNameCodeObject,
} from "../../data/CountryStateData.js";

import { UpdateLocation } from "../BusinessDetailWizard/BusinessDetailsAPI";
import { getUserBusinessLocations } from "../new-overview/newOverviewAPI.js";

const OrganisationDetails = () => {
  const navigate = useNavigate();
  const [organisationLogoURL, setOrganisationLogoURL] = useState("");
  const [locationData, setLocationData] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [savedOfficeIds, setSavedOfficeIds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const { currentUser } = useAuth();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [editLocationData, setEditLocationData] = useState(null);
  const [editIndex, setEditIndex] = useState(0);

  const [countryData, setCountryData] = useState(countryCodeList());
  const [stateData, setStateData] = useState([]);
  const [showState, setShowState] = useState(false);
  const [countryStateJSON, setCountryStateJSON] = useState(
    countryStateDataJSON()
  );
  const [showEditModal, setShowEditModal] = useState(false);

  const [values, setValues] = useState({
    locationName: "",
    country: "",
    countryCode: "",
    state: "",
    stateCode: "",
    streetAddress: "",
    city: "",
    postcode: "",
    officeId: "",
  });

  const handleChange = (event) => {
    if (event.target.name === "locationName") {
      if (
        event?.nativeEvent?.data !== "," &&
        !event?.target?.value?.includes(",")
      ) {
        setValues((values) => ({
          ...values,
          [event.target.name]: event.target.value,
        }));
      }
    } else if (event.target.name === "country") {
      // console.log("country value", event.target.value.split(",").at(-1));
      setStateData(countryStateJSON[event.target.value.split(",")[0]]);
      setShowState(true);

      setValues((values) => ({
        ...values,
        countryCode: event.target.value.split(",").at(-1),
      }));
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.value.split(",")[0],
      }));
    } else if (event.target.name === "state") {
      // console.log("state value", event.target.value.split(",").at(-1));

      setValues((values) => ({
        ...values,
        stateCode: event.target.value.split(",").at(-1),
      }));
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.value.split(",")[0],
      }));
    } else {
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.value,
      }));
    }
  };

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    defaultValues: async () => fetchData(),
  });

  const fetchData = async () => {
    reset(); // Reset form after submission

    const selectedMeasurementYear = activeUser.selectedMeasurementYear;
    const selectedMeasurementYearType = activeUser.selectedMeasurementYearType;

    const response = getInventoryProgressFromLocalStorage();
    let localStorageParsed = JSON.parse(response);

    // console.log("localStorageParsed", localStorageParsed);

    // Finding the matching object
    const data = localStorageParsed.find(
      (item) =>
        item.measurementyear === selectedMeasurementYear &&
        item.measurementyeartype === selectedMeasurementYearType
    );

    // Extract and set the office IDs from the data
    const officeIds = data.locations;

    // if officeIds is null, then set it to an empty array
    if (!officeIds) {
      setSavedOfficeIds([]);
    } else {
      setSavedOfficeIds(officeIds);
    }

    const businessDetails = await getBusinessDetails();
    const logoUrl = businessDetails.logourl;
    setOrganisationLogoURL(logoUrl);

    return {
      organisationName: data.organisationname,
      abn: data.abn,
      organisationDescription: data.description,
      numberOfEmployees: data.employeeNumber,
      yearlyRevenue: data.revenue,
      boundaryApproach: data.boundaryapproach,
      organisationLogo: "", // Add this line to include the logo URL in the submission
      offsets: data.offsets,
    };
  };

  const getLocations = async () => {
    setIsLoading(true);

    try {
      // Try to get the data from session storage
      const storedLocations = sessionStorage.getItem("userBusinessLocations");
      let response;

      if (storedLocations) {
        // Parse the string back to an object if data is found
        response = JSON.parse(storedLocations);
      } else {
        console.log("No userBusinessLocations found in session storage");
      }

      // console.log("getUserBusinessLocations response", response);

      const transformedLocations = response.map((location) => ({
        locationName: location.officename,
        country: location.country,
        countryCode: "", // Assuming you will fill this in later
        state: location.location,
        stateCode: "", // Assuming you will fill this in later
        streetAddress: location.street,
        city: location.city,
        postcode: location.postcode,
        officeId: location.office_id,
      }));
      setLocationData(transformedLocations);
      // console.log("transformedLocations", transformedLocations);

      setIsLoading(false);
    } catch (err) {
      console.error(err.message);
      setIsLoading(false);
    }
  };

  const renderLocation = (locationData, index) => {
    // Check if location's officeId is in savedOfficeIds
    const isLocationSaved = savedOfficeIds.includes(locationData.officeId);
    const isLocationSelected = selectedLocations.some(
      (item) => item.index === index
    );

    // If the location's officeId is saved and the location is not yet selected,
    // then handle its selection.
    if (isLocationSaved && !isLocationSelected) {
      handleSelectLocation(locationData, index);
      setSavedOfficeIds((prevIds) =>
        prevIds.filter((id) => id !== locationData.officeId)
      );
    }

    return (
      <tr
        key={index}
        style={isLocationSelected ? { backgroundColor: "#E5FCE5" } : {}}
      >
        <td>{locationData.locationName}</td>
        <td>{locationData.country}</td>
        <td>{locationData.state}</td>
        <td>{locationData.streetAddress}</td>
        <td>{locationData.city}</td>
        <td>{locationData.postcode}</td>
        <td>
          <input
            type="checkbox"
            checked={isLocationSelected}
            onChange={() => handleSelectLocation(locationData, index)}
          />
        </td>
        <td>
          {" "}
          <div>
            <IconButton
              variant="falcon-default"
              className="p-0 me-2"
              icon={faEdit}
              onClick={() => handleEditOpen(locationData, index)}
            ></IconButton>
          </div>
        </td>
      </tr>
    );
  };

  const handleEditOpen = (location, index) => {
    // console.log("location", location);
    // console.log("index", index);
    // console.log("locationData", locationData);
    setEditIndex(index);
    setEditLocationData(location);
    setShowEditModal(true);
  };

  async function handleEditClose() {
    getLocations();
    setShowEditModal(false);
    getBusinessOffice();
  }

  async function getBusinessOffice() {
    const response = await getUserBusinessLocations();

    //store all offices id in session as string
    let offices = [];
    for (let i in response) offices.push(response[i]["office_id"]);
    sessionStorage.setItem("officeId", JSON.stringify(offices));

    //store all location w.r.t to office id in session as string
    let locations = [];
    for (let i in response) locations.push(response[i]["location"]);
    sessionStorage.setItem("location", JSON.stringify(locations));

    //store all officeId,officeName in session as string
    let officeNameId = [];
    for (let i in response)
      officeNameId.push(
        response[i]["officename"] + "," + response[i]["office_id"]
      );
    sessionStorage.setItem("officeNameId", JSON.stringify(officeNameId));

    //find offices under same businessId
    let businessOffices = [];
    for (let i in response)
      businessOffices.push(
        response[i]["officename"] + "," + response[i]["office_id"]
      );
    sessionStorage.setItem("businessOffices", JSON.stringify(businessOffices));

    //store all office names in session as string
    let officeName = [];
    for (let i in response) officeName.push(response[i]["officename"]);
    sessionStorage.setItem("officeName", JSON.stringify(officeName));

    // Set all business locations in session
    sessionStorage.setItem("userBusinessLocations", JSON.stringify(response));

    //store officeId in state
    // setOffices(offices);
  }

  const handleEditSave = (updatedLocation) => {
    const updatedLocationData = [...locationData];
    updatedLocationData[editIndex] = updatedLocation;
    setLocationData(updatedLocationData);
    getBusinessOffice();
    setShowEditModal(false);
  };

  const handleSelectLocation = (location, index) => {
    if (selectedLocations.some((item) => item.index === index)) {
      setSelectedLocations((prev) =>
        prev.filter((item) => item.index !== index)
      );
      if (selectedLocations.length <= 1) {
        // Clear error if last selected location is being deselected
        clearErrors("locations");
      }
    } else {
      setSelectedLocations((prev) => [...prev, { ...location, index }]);
      clearErrors("locations"); // Clear error when a new location is selected
    }
    // console.log("selectedLocations", selectedLocations);
  };

  const handleAddLocation = (event) => {
    if (event) event.preventDefault();

    // console.log("final value", values);
    setLocationData([...locationData, values]);

    setValues({
      locationName: "",
      country: "",
      countryCode: "",
      state: "",
      stateCode: "",
      streetAddress: "",
      city: "",
      postcode: "",
      officeId: "",
    });

    // console.log("locationData", locationData);
    setStateData([]);
    setShowState(false);
  };

  const onSubmit = async (data) => {
    // console.log("Submission data", data);

    try {
      if (selectedLocations.length === 0) {
        setError("locations", {
          message: "At least one location must be selected",
        });
        setError("root", {
          message: "At least one location must be selected",
        });
        throw new Error();
      }

      console.log("activeUser DATA", activeUser);
      const selectedMeasurementYear = activeUser.selectedMeasurementYear;
      const selectedMeasurementYearType =
        activeUser.selectedMeasurementYearType;

      const response = getInventoryProgressFromLocalStorage();
      let localStorageParsed = JSON.parse(response);

      // Finding the matching object
      const selectedInvProgItemIndex = localStorageParsed.findIndex(
        (item) =>
          item.measurementyear === selectedMeasurementYear &&
          item.measurementyeartype === selectedMeasurementYearType
      );

      const selectedInvProgItem = localStorageParsed[selectedInvProgItemIndex];
      let inventoryId = selectedInvProgItem.inventoryid;

      const formatData = {
        name: data.organisationName,
        abn: data.abn,
        description: data.organisationDescription,
        boundaryApproach: data.boundaryApproach,
        locations: selectedLocations,
        inventoryId: inventoryId,
        logoUrl: organisationLogoURL, // Add this line to include the logo URL in the submission
        employeeNumber: data.numberOfEmployees,
        revenue: data.yearlyRevenue,
        offsets: data.offsets,
      };

      console.log("formatData", formatData);

      const result = await saveOrganisationDetails(formatData);

      if (result.success) {
        const resultData = result.data;
        const updatedData = resultData[0];

        // console.log("updatedData", updatedData);
        // console.log("localStorageParsed", localStorageParsed);

        localStorageParsed[selectedInvProgItemIndex] = {
          ...localStorageParsed[selectedInvProgItemIndex],
          organisationdetailsadded: true,
          organisationname: updatedData.organisation_name,
          abn: updatedData.abn,
          description: updatedData.description,
          boundaryapproach: updatedData.boundary_approach,
          locations: updatedData.locations,
          employeeNumber: updatedData.employee_number,
          revenue: updatedData.revenue,
          offsets: updatedData.offsets,
        };

        // console.log("localStorageParsed", localStorageParsed);

        setInventoryProgress(JSON.stringify(localStorageParsed));
        navigate("/welcome/new-overview"); // navigate back to the home page
        reset(); // Reset form after submission
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        console.log("Uploading file:", file);
        const downloadURL = await storeFile(file);
        setOrganisationLogoURL(downloadURL);
        setUploadProgress(0); // Reset progress after upload is complete
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  async function storeFile(file) {
    return new Promise((resolve, reject) => {
      const storage = getStorage(app);
      const userFilePath = `OrganisationLogos/${currentUser.uid}/${file.name}`;
      const storageRef = ref(storage, userFilePath);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          // Here you can update the state to reflect the progress
          setUploadProgress(progress); // Update state to reflect the progress
          // console.log("Upload is " + progress + "% done");
        },
        (error) => {
          console.error(error);
          reject(error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL);
          } catch (error) {
            console.error(error);
            reject(error);
          }
        }
      );
    });
  }

  useEffect(() => {
    getBusinessOffice();
    getLocations();
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedLocations.length === 0 && locationData.length > 0) {
      handleSelectLocation(locationData[0], 0);
    }
  }, [selectedLocations, locationData]);

  return (
    <Card>
      {showEditModal && (
        <EditLocationModal
          showEditModal={showEditModal}
          handleEditClose={handleEditClose}
          handleEditSave={handleEditSave}
          data={{
            businessId: currentUser.businessId,
            locationData: { ...editLocationData },
          }}
        />
      )}
      <Card.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <h3>Organisation details</h3>
          <p className="text-danger">Fields marked with * are mandatory.</p>
          <p>
            Add all details regarding this emissions inventory. An organisation
            is defined by its Australian Business Number (ABN) or group of ABNs
            which sits under a parent company.
          </p>
          <p>
            <h5>Reporting Period Details:</h5>
            <strong>Chosen reporting period: </strong>
            {activeUser.selectedMeasurementYear}{" "}
            {activeUser.selectedMeasurementYearType} year
            <br />
            <strong>Start of reporting period: </strong>
            {activeUser.dateRangeStart}
            <br />
            <strong>End of reporting period: </strong>
            {activeUser.dateRangeEnd}
          </p>
          <hr />
          <br />

          <Form.Group className="mb-4" controlId="organisationLogo">
            <Form.Label style={{ fontSize: "18px" }}>
              Organisation Logo
            </Form.Label>
            <Form.Control
              type="file"
              onChange={handleFileChange}
              size="sm"
              accept=".png, .jpg, .jpeg, .svg"
              isInvalid={!!errors.organisationLogo}
              // {...register("organisationLogo", {
              //   validate: {
              //     fileType: (files) => {
              //       if (files.length === 0) return true; // No file uploaded is fine if it's not mandatory
              //       const validTypes = [
              //         "image/png",
              //         "image/jpeg",
              //         "image/svg+xml",
              //       ];
              //       return (
              //         validTypes.includes(files[0]?.type) ||
              //         "Only PNG, JPG, or SVG files are accepted"
              //       );
              //     },
              //   },
              // })}
            />
            {uploadProgress > 0 && (
              <ProgressBar
                now={uploadProgress}
                label={`${uploadProgress}%`}
                style={{ marginTop: "10px" }}
              />
            )}
            {organisationLogoURL && (
              <div>
                <img
                  src={organisationLogoURL}
                  alt="Uploaded Logo"
                  style={{ maxWidth: "100px", marginTop: "10px" }}
                />
              </div>
            )}
            {errors.organisationLogo && (
              <Form.Control.Feedback type="invalid">
                {errors.organisationLogo.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-4" controlId="organisationName">
            <Form.Label style={{ fontSize: "18px" }}>
              Organisation Name *
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter organisation name"
              aria-label="Organisation name"
              isInvalid={!!errors.organisationName}
              {...register("organisationName", {
                required: "Organisation name is required",
              })}
            />
            {errors.organisationName && (
              <Form.Control.Feedback type="invalid">
                {errors.organisationName.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-4" controlId="abn">
            <Form.Label style={{ fontSize: "18px" }}>ABN *</Form.Label>
            <Form.Control
              type="text" // Changed from 'number' to 'text' to handle leading zeros
              placeholder="Enter ABN"
              aria-label="ABN"
              isInvalid={!!errors.abn}
              {...register("abn", {
                required: "ABN is required",
                pattern: {
                  value: /^\d{11}$/, // Regex for exactly 11 digits
                  message: "ABN must be exactly 11 digits",
                },
              })}
            />
            {errors.abn && (
              <Form.Control.Feedback type="invalid">
                {errors.abn.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-4" controlId="organisationDescription">
            <Form.Label style={{ fontSize: "18px" }}>
              Organisation Description
            </Form.Label>
            <p>
              Write about your organisation in a customer facing way. This
              should resemble your "About us" page on your website
            </p>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Describe the organisation"
              aria-label="Organisation description"
              isInvalid={!!errors.organisationDescription}
              {...register("organisationDescription")}
            />
            {errors.organisationDescription && (
              <Form.Control.Feedback type="invalid">
                {errors.organisationDescription.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-4" controlId="numberOfEmployees">
            <Form.Label style={{ fontSize: "18px" }}>
              Number of Employees
            </Form.Label>
            <p>
              How many employees worked at your organisation during this
              reporting period?
            </p>

            <Form.Control
              type="number"
              step="any" // Allows decimal values
              placeholder="Enter number of employees"
              aria-label="Number of employees"
              isInvalid={!!errors.numberOfEmployees}
              {...register("numberOfEmployees", {
                valueAsNumber: true, // Ensures that input is handled as a number
                min: {
                  value: 0,
                  message: "Number of employees cannot be negative",
                },
                pattern: {
                  value: /^[0-9]*\.?[0-9]*$/, // Regex to allow numbers and floating points
                  message:
                    "Invalid number of employees format; only numbers and decimals are allowed.",
                },
              })}
            />
            {errors.numberOfEmployees && (
              <Form.Control.Feedback type="invalid">
                {errors.numberOfEmployees.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-4" controlId="yearlyRevenue">
            <Form.Label style={{ fontSize: "18px" }}>Yearly Revenue</Form.Label>
            <p>
              What is your organisation's annual gross revenue for this
              reporting period?
            </p>

            <Form.Control
              type="number"
              step="any" // Allows decimal values
              placeholder="Enter yearly revenue in dollars"
              aria-label="Yearly revenue"
              isInvalid={!!errors.yearlyRevenue}
              {...register("yearlyRevenue", {
                valueAsNumber: true, // Ensures that input is handled as a number
                min: { value: 0, message: "Revenue cannot be negative" },
                pattern: {
                  value: /^[0-9]*\.?[0-9]*$/, // Regex to allow numbers and floating points
                  message:
                    "Invalid revenue format; only numbers and decimals are allowed.",
                },
              })}
            />
            {errors.yearlyRevenue && (
              <Form.Control.Feedback type="invalid">
                {errors.yearlyRevenue.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-4" controlId="offsets">
            <Form.Label style={{ fontSize: "18px" }}>Offsets</Form.Label>
            <p>
              Enter the amount of carbon offsets your organisation has purchased
              for this reporting period (in tonnes of CO₂e).
            </p>

            <Form.Control
              type="number"
              step="any" // Allows decimal values
              placeholder="Enter offsets in metric tons"
              aria-label="Offsets"
              isInvalid={!!errors.offsets}
              {...register("offsets", {
                valueAsNumber: true, // Ensures that input is handled as a number
                min: { value: 0, message: "Offsets cannot be negative" },
                pattern: {
                  value: /^[0-9]*\.?[0-9]*$/, // Regex to allow numbers and floating points
                  message:
                    "Invalid offsets format; only numbers and decimals are allowed.",
                },
              })}
            />
            {errors.offsets && (
              <Form.Control.Feedback type="invalid">
                {errors.offsets.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label style={{ fontSize: "18px" }}>
              Locations Being Analysed *
            </Form.Label>
            <p>
              Select which location(s) are being analysed this reporting period
              (you must select at least one location)
            </p>
            {errors.locations && (
              <p className="text-danger">{errors.locations.message}</p>
            )}
            {isLoading ? (
              <div className="text-center my-4">
                {/* You can adjust the positioning and spacing as needed */}
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : locationData.length > 0 ? (
              <>
                <Table
                  responsive
                  bordered
                  hover
                  className="table locationTable"
                >
                  <thead className="table-light bg-200">
                    <tr>
                      <th scope="col">Location Name</th>
                      <th scope="col">Country</th>
                      <th scope="col">State</th>
                      <th scope="col">Street Address</th>
                      <th scope="col">City</th>
                      <th scope="col">Postcode</th>
                      <th scope="col">Select</th>
                      <th scope="col">Edit</th>
                    </tr>
                  </thead>
                  <tbody>{locationData.map(renderLocation)}</tbody>
                </Table>
              </>
            ) : (
              <p>No locations found.</p>
            )}

            {/* Form */}
            <Accordion defaultActiveKey="0">
              <Accordion.Header>+ Add a new location</Accordion.Header>
              <Accordion.Body>
                <Row className="justify-content locationForm">
                  <Col md={7}>
                    <Form as={Row} className="g-2">
                      <Col sm>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Location Name"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            name="locationName"
                            placeholder="Location Name"
                            value={values.locationName}
                            onChange={handleChange}
                            // required
                          />
                        </FloatingLabel>

                        <FloatingLabel
                          controlId="floatingSelect"
                          label="Country"
                          className="mb-3"
                        >
                          <Form.Select
                            name="country"
                            value={values.country + "," + values.countryCode}
                            onChange={handleChange}
                            // required
                          >
                            <option>Select the country</option>
                            {countryData.map((data, index) => {
                              return (
                                <option
                                  key={index}
                                  value={data.name + "," + data.code}
                                >
                                  {data.name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </FloatingLabel>

                        {showState ? (
                          <FloatingLabel
                            controlId="floatingSelect"
                            label="State"
                            className="mb-3"
                          >
                            <Form.Select
                              name="state"
                              onChange={handleChange}
                              // required
                            >
                              <option>Select the state</option>
                              {stateData.map((data, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={data.name + "," + data.code}
                                  >
                                    {data.name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </FloatingLabel>
                        ) : null}

                        <FloatingLabel
                          controlId="floatingInput"
                          label="Street address"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            name="streetAddress"
                            placeholder="Street address"
                            value={values.streetAddress}
                            onChange={handleChange}
                            // required
                          />
                        </FloatingLabel>

                        <FloatingLabel
                          controlId="floatingInput"
                          label="City"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            name="city"
                            placeholder="City"
                            value={values.city}
                            onChange={handleChange}
                            // required
                          />
                        </FloatingLabel>

                        <FloatingLabel
                          controlId="floatingPassword"
                          label="Postcode"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            name="postcode"
                            placeholder="Postcode"
                            value={values.postcode}
                            onChange={handleChange}
                            // required
                          />
                        </FloatingLabel>

                        <Row>
                          <Col sm className="mt-3">
                            <Button
                              variant="primary"
                              className="d-block w-100"
                              type="submit"
                              onClick={() => handleAddLocation()}
                              disabled={
                                !values.city ||
                                !values.postcode ||
                                !values.locationName ||
                                !values.country ||
                                !values.state ||
                                !values.streetAddress
                              }
                            >
                              Add Location
                            </Button>
                          </Col>
                          {locationData.length > 0 && (
                            <Col sm className="mt-3">
                              {/* <Button variant="primary" className="d-block w-100" type="submit" onClick={() => handleSave()} disabled={!(locationData.length > 0)}>
                            Save & Continue
                          </Button> */}
                            </Col>
                          )}
                          {/* <ToastContainer /> */}
                        </Row>
                      </Col>
                    </Form>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion>
          </Form.Group>

          <br />
          <h3>Boundary Approach</h3>
          <p>
            The boundary approach sets the basis for determining what emissions
            are considered to be under the direct control of the organisation.
            The most common approach for organisations seeking certification
            through the Australian Government is the operational control
            approach.
          </p>
          <ol>
            <li>
              <strong>Financial control</strong>, whereby the organisation
              reports 100 per cent of operations over which it has "the ability
              to direct the financial and operating policies of the latter with
              a view to gaining economic benefits from its activities" (GHG
              Protocol - Corporate Standard (WBCSDand WRI, 2004)).
            </li>
            <li>
              <strong>Operational control</strong>, whereby the organisation
              reports 100 per cent of the operations over which it has "the full
              authority to introduce and implement its operating policies"
            </li>
            <li>
              <strong>Equity share control</strong>, which requires an
              organisation to account for greenhouse gas emissions according to
              its share of equity in the operations.
            </li>
          </ol>
          <Form.Group className="mb-4" controlId="boundaryApproach">
            <Form.Label style={{ fontSize: "18px" }}>
              Boundary Approach *
            </Form.Label>
            <Form.Select
              aria-label="Boundary Approach"
              isInvalid={!!errors.boundaryApproach}
              {...register("boundaryApproach", {
                required: "Boundary approach selection is required",
              })}
            >
              <option value="">Select control approach</option>
              <option value="Financial Control">Financial Control</option>
              <option value="Operational Control">Operational Control</option>
              <option value="Equity Share Control">Equity Share Control</option>
            </Form.Select>
            {errors.boundaryApproach && (
              <Form.Control.Feedback type="invalid">
                {errors.boundaryApproach.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : "Submit"}
          </Button>

          {errors.locations && (
            <p className="text-danger mt-3">
              <strong>{errors.locations.message}</strong>
            </p>
          )}
        </Form>
      </Card.Body>
    </Card>
  );
};

const EditLocationModal = ({
  showEditModal,
  handleEditClose,
  handleEditSave,
  data,
}) => {
  const [countryData] = useState(countryCodeList());
  const [stateData, setStateData] = useState([]);
  const [showState, setShowState] = useState(false);
  const [countryStateJSON] = useState(countryStateDataJSON());
  const [isLoading, setIsLoading] = useState(false);

  const [values, setValues] = useState({
    locationName: "",
    country: "",
    countryCode: "",
    state: "",
    stateCode: "",
    streetAddress: "",
    city: "",
    postcode: "",
  });

  const handleChange = (event) => {
    event.preventDefault();
    if (event.target.name === "locationName") {
      if (
        event?.nativeEvent?.data !== "," &&
        !event?.target?.value?.includes(",")
      ) {
        setValues((values) => ({
          ...values,
          [event.target.name]: event.target.value,
        }));
      }
    } else if (event.target.name === "country") {
      //console.log("country value", event.target.value.split(",").at(-1));
      setStateData(countryStateJSON[event.target.value.split(",")[0]]);
      setShowState(true);

      setValues((values) => ({
        ...values,
        countryCode: event.target.value.split(",").at(-1),
      }));
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.value.split(",")[0],
      }));
    } else if (event.target.name === "state") {
      //console.log("state value", event.target.value.split(",").at(-1));

      setValues((values) => ({
        ...values,
        stateCode: event.target.value.split(",").at(-1),
      }));
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.value.split(",")[0],
      }));
    } else {
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.value,
      }));
    }
  };

  const handleSubmit = async (event) => {
    setIsLoading(true);
    if (event) event.preventDefault();

    const updatedLocation = {
      ...values,
      officeId: data.locationData.officeId,
      locationName: values.locationName || data.locationData.officename,
    };
    // console.log("updatedLocation", updatedLocation);

    // Assuming UpdateLocation is a function that updates the location in the backend
    const response = await UpdateLocation(updatedLocation);

    if (response.status === 200) {
      // handleEditClose(true);
      handleEditSave(updatedLocation);
      toast.success("Successfully updated location details");
    } else {
      toast.error("Failed to update location details");
    }
  };

  useEffect(() => {
    setValues({
      locationName: data.locationData.locationName,
      country: data.locationData.country,
      countryCode: CountryNameCodeObject()[data.locationData.country],
      state: data.locationData.state,
      stateCode: countryStateJSON[data.locationData.country].find(
        (o) => o.name === data.locationData.state
      ).code,
      streetAddress: data.locationData.streetAddress,
      city: data.locationData.city,
      postcode: data.locationData.postcode,
    });
    if (data.locationData.country) {
      setStateData(countryStateJSON[data.locationData.country]);
      setShowState(true);
    }
  }, [data.locationData]);

  return (
    <div>
      {" "}
      <Modal
        show={showEditModal}
        onHide={handleEditClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {/* Edit Unified data category - <i>{data.unifiedData.id}</i> */}
              Edit Location Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Col sm>
              <FloatingLabel
                controlId="floatingInput"
                label="Location Name"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="locationName"
                  placeholder="Location Name"
                  value={values.locationName}
                  onChange={handleChange}
                  required
                />
              </FloatingLabel>

              <FloatingLabel
                controlId="floatingSelect"
                label="Country"
                className="mb-3"
              >
                <Form.Select
                  name="country"
                  value={values.country + "," + values.countryCode}
                  onChange={handleChange}
                  required
                >
                  <option>Select the country</option>
                  {countryData.map((data, index) => {
                    return (
                      <option key={index} value={data.name + "," + data.code}>
                        {data.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </FloatingLabel>

              {showState ? (
                <FloatingLabel
                  controlId="floatingSelect"
                  label="State"
                  className="mb-3"
                >
                  <Form.Select
                    name="state"
                    onChange={handleChange}
                    value={values.state + "," + values.stateCode}
                    required
                  >
                    <option>Select the state</option>
                    {stateData.map((data, index) => {
                      return (
                        <option key={index} value={data.name + "," + data.code}>
                          {data.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </FloatingLabel>
              ) : null}

              <FloatingLabel
                controlId="floatingInput"
                label="Street address"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="streetAddress"
                  placeholder="Street address"
                  value={values.streetAddress}
                  onChange={handleChange}
                  required
                />
              </FloatingLabel>

              <FloatingLabel
                controlId="floatingInput"
                label="City"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="city"
                  placeholder="City"
                  value={values.city}
                  onChange={handleChange}
                  required
                />
              </FloatingLabel>

              <FloatingLabel
                controlId="floatingPassword"
                label="Postcode"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  name="postcode"
                  placeholder="Postcode"
                  value={values.postcode}
                  onChange={handleChange}
                  required
                />
              </FloatingLabel>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            {isLoading ? (
              <Spinner
                animation="border"
                role="status"
                size="sm"
                style={{ color: "#f5803e" }}
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <Button
                variant="primary"
                className="d-block w-100"
                type="submit"
                onClick={() => handleSubmit()}
                disabled={
                  !values.city ||
                  !values.postcode ||
                  !values.locationName ||
                  !values.country ||
                  !values.state ||
                  !values.streetAddress
                }
              >
                Save Edit
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default OrganisationDetails;
