const ID_TOKEN = "idToken";
const IMPERSONATED_ID_TOKEN = "impersonatedIdToken";
const USER_DATA = "userData";
const IMPERSONATED_USER_DATA = "impersonatedUserData";
const INVENTORY_PROGRESS = "inventoryProgress";
const STEPS_PROGRESS = "stepsProgress";
const IMPERSONATED_INVENTORY_PROGRESS = "impersonatedInventoryProgress";
const SKIP_PROJECT_TOUR = "skipProjectTour";
const IMPERSONATED_STEPS_PROGRESS = "impersonatedStepsProgress";

export const setIdToken = (idToken) => {
  localStorage.setItem(ID_TOKEN, idToken);
};

export const setImpersonatedIdToken = (idToken) => {
  localStorage.setItem(IMPERSONATED_ID_TOKEN, idToken);
};

export const getIdToken = () => {
  return localStorage.getItem(ID_TOKEN);
};

export const getImpersonatedIdToken = () => {
  return localStorage.getItem(IMPERSONATED_ID_TOKEN);
};

export const removeIdToken = () => {
  return localStorage.removeItem(ID_TOKEN);
};

export const removeImpersonatedIdToken = () => {
  return localStorage.removeItem(IMPERSONATED_ID_TOKEN);
};

export const setUserData = (user) => {
  localStorage.setItem(USER_DATA, user);
};

export const setCompanyData = (companyData) => {
  localStorage.setItem("companyData", companyData);
};

export const setInventoryProgress = (inventoryProgress) => {
  const impersonatedInventoryProgress = getImpersonatedInventoryProgress();
  if (impersonatedInventoryProgress) {
    setImpersonatedInventoryProgress(inventoryProgress);
  } else {
    localStorage.setItem(INVENTORY_PROGRESS, inventoryProgress);
  }
};

export const setStepsProgress = (progressData) => {
  const impersonatedStepProgress = getImpersonatedStepsProgress();
  if (impersonatedStepProgress) {
    setImpersonatedStepProgress(progressData);
  } else {
    localStorage.setItem(STEPS_PROGRESS, progressData);
  }
};

export const setYearEmissionsInventoryProgress = (year, totalEmissions) => {
  const storedInventoryProgress = localStorage.getItem(INVENTORY_PROGRESS);
  let inventoryData = storedInventoryProgress
    ? JSON.parse(storedInventoryProgress)
    : [];
  const entryIndex = inventoryData.findIndex(
    (item) => item.measurementyear === year.toString()
  );

  inventoryData[entryIndex].totalEmissions = totalEmissions;

  // Update the inventory progress in local storage with the modified inventory data
  localStorage.setItem(INVENTORY_PROGRESS, JSON.stringify(inventoryData));
};

export const setYearRevenueInventoryProgress = (year, revenue) => {
  const storedInventoryProgress = localStorage.getItem(INVENTORY_PROGRESS);
  let inventoryData = storedInventoryProgress
    ? JSON.parse(storedInventoryProgress)
    : [];
  const entryIndex = inventoryData.findIndex(
    (item) => item.measurementyear === year.toString()
  );

  inventoryData[entryIndex].revenue = revenue;

  // Update the inventory progress in local storage with the modified inventory data
  localStorage.setItem(INVENTORY_PROGRESS, JSON.stringify(inventoryData));
};

export const setYearEmployeeInventoryProgress = (year, employeeNumber) => {
  const storedInventoryProgress = localStorage.getItem(INVENTORY_PROGRESS);
  let inventoryData = storedInventoryProgress
    ? JSON.parse(storedInventoryProgress)
    : [];
  const entryIndex = inventoryData.findIndex(
    (item) => item.measurementyear === year.toString()
  );

  inventoryData[entryIndex].employeeNumber = employeeNumber;

  // Update the inventory progress in local storage with the modified inventory data
  localStorage.setItem(INVENTORY_PROGRESS, JSON.stringify(inventoryData));
};

export const setPeriodEmissionsInventoryProgress = (id, totalEmissions) => {
  let inventoryProgress = [];
  const impersonatedData = getImpersonatedInventoryProgress();

  if (impersonatedData) {
    inventoryProgress = JSON.parse(impersonatedData);
  } else {
    inventoryProgress = JSON.parse(
      localStorage.getItem(INVENTORY_PROGRESS) || "[]"
    );
  }

  const entryIndex = inventoryProgress.findIndex(
    (entry) => entry.inventoryid === id
  );

  inventoryProgress[entryIndex].totalEmissions = totalEmissions;
  setInventoryProgress(JSON.stringify(inventoryProgress));
};

export const setPeriodRevenueInventoryProgress = (id, revenue) => {
  const storedInventoryProgress = localStorage.getItem(INVENTORY_PROGRESS);
  let inventoryData = storedInventoryProgress
    ? JSON.parse(storedInventoryProgress)
    : [];
  const entryIndex = inventoryData.findIndex((item) => item.inventoryid === id);

  inventoryData[entryIndex].revenue = revenue;

  // Update the inventory progress in local storage with the modified inventory data
  localStorage.setItem(INVENTORY_PROGRESS, JSON.stringify(inventoryData));
};

export const setPeriodEmployeeInventoryProgress = (id, employeeNumber) => {
  const storedInventoryProgress = localStorage.getItem(INVENTORY_PROGRESS);
  let inventoryData = storedInventoryProgress
    ? JSON.parse(storedInventoryProgress)
    : [];
  const entryIndex = inventoryData.findIndex((item) => item.inventoryid === id);

  inventoryData[entryIndex].employeeNumber = employeeNumber;

  // Update the inventory progress in local storage with the modified inventory data
  localStorage.setItem(INVENTORY_PROGRESS, JSON.stringify(inventoryData));
};

export const setImpersonatedUserData = (user) => {
  localStorage.setItem(IMPERSONATED_USER_DATA, user);
};

export const setImpersonatedInventoryProgress = (inventoryProgress) => {
  localStorage.setItem(IMPERSONATED_INVENTORY_PROGRESS, inventoryProgress);
};

export const setImpersonatedStepProgress = (stepProgressData) => {
  localStorage.setItem(IMPERSONATED_STEPS_PROGRESS, stepProgressData);
};

export const getUserData = () => {
  return localStorage.getItem(USER_DATA);
};

export const getCompanyData = () => {
  return localStorage.getItem("companyData");
};

export const getInventoryProgress = () => {
  const impersonatedInventoryProgress = getImpersonatedInventoryProgress();
  if (impersonatedInventoryProgress) return impersonatedInventoryProgress;
  return localStorage.getItem(INVENTORY_PROGRESS);
};

export const getStepsProgress = () => {
  const impesonatedStepsProgress = getImpersonatedStepsProgress();
  if (impesonatedStepsProgress) return impesonatedStepsProgress;
  return localStorage.getItem(STEPS_PROGRESS);
};

export const getSingleInventoryProgress = (id) => {
  const impersonatedInventoryProgress = getImpersonatedInventoryProgress();
  let inventoryData;

  if (impersonatedInventoryProgress) {
    inventoryData = JSON.parse(impersonatedInventoryProgress);
  } else {
    const storedInventoryProgress = localStorage.getItem(INVENTORY_PROGRESS);
    if (!storedInventoryProgress) return undefined;
    inventoryData = JSON.parse(storedInventoryProgress);
  }

  return inventoryData.find((item) => item.inventoryid === id);
};

export const getActiveUserData = () => {
  const impersonatedUserData = getImpersonatedUserData();
  if (impersonatedUserData) return impersonatedUserData;
  return localStorage.getItem(USER_DATA);
};

export const setActiveUserData = (userData) => {
  const impersonatedUserData = getImpersonatedUserData();
  if (impersonatedUserData) {
    setImpersonatedUserData(userData);
  } else {
    setUserData(userData);
  }
};

export const getImpersonatedUserData = () => {
  return localStorage.getItem(IMPERSONATED_USER_DATA);
};

export const getImpersonatedInventoryProgress = () => {
  return localStorage.getItem(IMPERSONATED_INVENTORY_PROGRESS);
};

export const getImpersonatedStepsProgress = () => {
  return localStorage.getItem(IMPERSONATED_STEPS_PROGRESS);
};

export const removeUserData = () => {
  return localStorage.removeItem(USER_DATA);
};

export const removeImpersonatedUserData = () => {
  return localStorage.removeItem(IMPERSONATED_USER_DATA);
};

export const removeImpersonatedInventoryProgress = () => {
  return localStorage.removeItem(IMPERSONATED_INVENTORY_PROGRESS);
};

export const isLoginDataExists = () => {
  const ID_TOKEN = getIdToken();
  const USER_DATA = getUserData();
  return ID_TOKEN && USER_DATA ? true : false;
};

export const getUerRole = () => {
  const userData = getUserData();
  if (userData) {
    const parsedUserData = JSON.parse(userData);
    return parsedUserData.role || "ADMIN";
  }

  return "ADMIN";
};

export const isImpersonationModeEnabled = () => {
  const userData = getImpersonatedUserData();
  return userData ? true : false;
};

export const clearLoginData = () => {
  removeIdToken();
  removeUserData();
  clearImpersonatedData();
  window.location.href = "/";
};

export const getImpersonatedUserDisplayName = () => {
  const impersonatedUserData = getImpersonatedUserData();
  if (impersonatedUserData) {
    return JSON.parse(impersonatedUserData).email;
  }
  return null;
};

export const clearImpersonatedData = () => {
  removeImpersonatedIdToken();
  removeImpersonatedUserData();
  removeImpersonatedInventoryProgress();
  window.location.href = "/welcome";
};

export const clearSessionStorage = () => {
  sessionStorage.clear();
};

export const setProjectTour = (value) => {
  localStorage.setItem(SKIP_PROJECT_TOUR, value);
};

export const getProjectTour = () => {
  return localStorage.getItem(SKIP_PROJECT_TOUR) || false;
};

export const setSingleInventoryProgress = (id, updatedData) => {
  const impersonatedInventoryProgress = getImpersonatedInventoryProgress();
  let inventoryData;

  if (impersonatedInventoryProgress) {
    inventoryData = JSON.parse(impersonatedInventoryProgress);
  } else {
    const storedInventoryProgress = localStorage.getItem(INVENTORY_PROGRESS);
    inventoryData = storedInventoryProgress
      ? JSON.parse(storedInventoryProgress)
      : [];
  }

  const entryIndex = inventoryData.findIndex((item) => item.inventoryid === id);

  if (entryIndex !== -1) {
    inventoryData[entryIndex] = {
      ...inventoryData[entryIndex],
      ...updatedData,
    };

    if (impersonatedInventoryProgress) {
      setImpersonatedInventoryProgress(JSON.stringify(inventoryData));
    } else {
      localStorage.setItem(INVENTORY_PROGRESS, JSON.stringify(inventoryData));
    }
  } else {
    console.warn(`No inventory entry found with id: ${id}`);
  }
};

export const getSubCollectionRef = () => {
  let userData;
  const impersonatedUserData = getImpersonatedUserData();

  if (impersonatedUserData) {
    userData = JSON.parse(impersonatedUserData);
  } else {
    const storedUserData = localStorage.getItem(USER_DATA);
    userData = storedUserData ? JSON.parse(storedUserData) : {};
  }

  const year = userData.selectedMeasurementYear;
  const yearPrefix =
    userData.selectedMeasurementYearType === "calendar" ? "CY" : "FY";

  return `${yearPrefix}${year}`;
};

export const isDataFrozen = () => {
  let userData = {};
  const impersonatedUserData = getImpersonatedUserData();
  if (impersonatedUserData) {
    userData = JSON.parse(impersonatedUserData);
  } else {
    userData = JSON.parse(localStorage.getItem(USER_DATA));
  }
  return userData.isFrozen;
};

export const previousPeriodInventoryProgress = () => {
  let userData = {};
  let inventoryProgress = {};
  const impersonatedUserData = getImpersonatedUserData();

  if (impersonatedUserData) {
    userData = JSON.parse(impersonatedUserData);
    const impersonatedInventoryProgress = getImpersonatedInventoryProgress();
    inventoryProgress = JSON.parse(impersonatedInventoryProgress);
  } else {
    userData = JSON.parse(localStorage.getItem(USER_DATA) || "{}");
    inventoryProgress = JSON.parse(
      localStorage.getItem(INVENTORY_PROGRESS) || "[]"
    );
  }
  const year = userData.selectedMeasurementYear - 1;
  const yearType = userData.selectedMeasurementYearType;
  const inventoryData = inventoryProgress.find(
    (period) =>
      period.measurementyeartype === yearType &&
      +period.measurementyear === year
  );

  return inventoryData || null;
};

const defaultWhiteLabel = {
  name: "NetNada",
  subdomain: "default",
  logo: "https://cdn.filestackcontent.com/jI0Bz5GXRTmfkMONEOJH",
  width: 150,
  icon: "https://firebasestorage.googleapis.com/v0/b/netnada-staging.appspot.com/o/WhiteLabelLogos%2Ffavicon.ico?alt=media&token=52e281e7-abd2-4b46-9fd0-09070320527b",
};

export const getWhiteLabel = () => {
  const whiteLabel = JSON.parse(sessionStorage.getItem("whiteLabel"));
  return whiteLabel || defaultWhiteLabel;
};

export const getSelectedLocationsFromStorage = () => {
  const userBusinessLocations = JSON.parse(
    sessionStorage.getItem("userBusinessLocations")
  );

  //fetch inventory ID from active user data
  const activeUserData = getActiveUserData();
  const userData = JSON.parse(activeUserData);
  const inventoryId = userData.inventoryId;

  //fetch inventory data from inventory progress from local storage based on inventory ID
  const inventoryProgress = JSON.parse(getInventoryProgress());
  const inventoryData = inventoryProgress.find(
    (inventory) => inventory.inventoryid === inventoryId
  );

  const selectedLocations = inventoryData.locations;

  if (selectedLocations === null) {
    return [];
  }

  return userBusinessLocations.filter((location) =>
    selectedLocations.includes(location.office_id)
  );
};

export const getAllLocationsFromStorage = () => {
  //filter location whose name is Default
  const userBusinessLocations = JSON.parse(
    sessionStorage.getItem("userBusinessLocations")
  ).filter((location) => location.officename !== "Default");

  //fetch inventory ID from active user data
  const activeUserData = getActiveUserData();
  const userData = JSON.parse(activeUserData);
  const inventoryId = userData.inventoryId;

  //fetch inventory data from inventory progress from local storage based on inventory ID
  const inventoryProgress = JSON.parse(getInventoryProgress());
  const inventoryData = inventoryProgress.find(
    (inventory) => inventory.inventoryid === inventoryId
  );

  const selectedLocations = inventoryData.locations;

  return userBusinessLocations?.map((location) => ({
    ...location,
    officeId: location.office_id,
    locationName: location.officename,
    state: location.location,
    streetAddress: location.street,
    selected: selectedLocations?.includes(location.office_id),
  }));
};

export const toSetLocationsToStorage = (updatedLocations) => {
  // Get stored locations from session storage
  const storedLocations = sessionStorage.getItem("userBusinessLocations");
  let response = storedLocations ? JSON.parse(storedLocations) : [];

  // Ensure updatedLocations is an array, even if it's a single object
  const locationsArray = Array.isArray(updatedLocations)
    ? updatedLocations
    : [updatedLocations];

  // Iterate over each location in updatedLocations array
  locationsArray.forEach((updatedLocation) => {
    // Check if the location already exists in the storage
    const locationExists = response.some(
      (location) =>
        location.office_id === updatedLocation.officeId ||
        location.officename === updatedLocation.tempId
    );

    if (locationExists) {
      // Update the existing location
      response = response.map((location) => {
        if (
          location.office_id === updatedLocation.officeId ||
          location.officename === updatedLocation.tempId
        ) {
          return {
            ...location,
            officename: updatedLocation.locationName,
            country: updatedLocation.country,
            location: updatedLocation.state,
            street: updatedLocation.streetAddress,
            city: updatedLocation.city,
            postcode: updatedLocation.postcode,
          };
        }
        return location;
      });
    } else {
      // Add the new location if it doesn't exist
      response.push({
        businessId: updatedLocation.businessId,
        officename: updatedLocation.locationName,
        country: updatedLocation.country,
        location: updatedLocation.state,
        street: updatedLocation.streetAddress,
        city: updatedLocation.city,
        postcode: updatedLocation.postcode,
        office_id: updatedLocation.officeId,
      });
    }
  });

  // Save the updated locations back to session storage
  sessionStorage.setItem("userBusinessLocations", JSON.stringify(response));
};

export const setInventoryCurrentStepProgressId = (id, stringId) => {
  const impersonatedInventoryProgress = getImpersonatedInventoryProgress();
  let inventoryData;

  if (impersonatedInventoryProgress) {
    inventoryData = JSON.parse(impersonatedInventoryProgress);
  } else {
    const storedInventoryProgress = localStorage.getItem(INVENTORY_PROGRESS);
    inventoryData = storedInventoryProgress
      ? JSON.parse(storedInventoryProgress)
      : [];
  }

  const entryIndex = inventoryData.findIndex((item) => item.inventoryid === id);
  console.log("entryIndex", entryIndex);
  if (entryIndex !== -1) {
    inventoryData[entryIndex].currentStepProgressId = stringId;

    if (impersonatedInventoryProgress) {
      setImpersonatedInventoryProgress(JSON.stringify(inventoryData));
    } else {
      localStorage.setItem(INVENTORY_PROGRESS, JSON.stringify(inventoryData));
    }
  } else {
    console.warn(`No inventory entry found with id: ${id}`);
  }
};
