import React, { useEffect, useState } from "react";
import AdvanceTableWrapper from "../common/advance-table/AdvanceTableWrapper";
import { Button, Card, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import AdvanceTable from "../common/advance-table/AdvanceTable";
import app from "../../firebase";
import { useAuth } from "../../Contexts/AuthContext";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  writeBatch,
} from "firebase/firestore";
import { getAuthorizationHeaderWithContentType } from "../../helpers/utils";
import {
  clearLoginData,
  getActiveUserData,
  setActiveUserData,
} from "../../helpers/store";
import AdvanceTableSearchBox from "../common/advance-table/AdvanceTableSearchBox";

const EXCLUDE_ACCOUNT_TYPES_XERO = ["EQUITY", "REVENUE"];

const fireStoreDB = getFirestore(app);

const AccountsListForm = ({
  accountsList,
  setAccountsList,
  isEdit,
  handleEditSelectedAccountNamesClose,
}) => {
  return (
    <div>
      {/* <h3>Choose accounts to use</h3> */}
      <AccountsTable
        accountsList={accountsList}
        setAccountsList={setAccountsList}
        isEdit={isEdit}
        handleEditSelectedAccountNamesClose={
          handleEditSelectedAccountNamesClose
        }
      />
    </div>
  );
};

const AccountsTable = ({
  accountsList,
  setAccountsList,
  isEdit,
  handleEditSelectedAccountNamesClose,
}) => {
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const csvPresent = JSON.parse(sessionStorage.getItem("csvPresent"));
  const [selectedRowIds, setSelectedRowIds] = useState({}); // State to store selected row IDs
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false); // State to store whether all rows are selected or not
  // Create a state to hold the selected account type for filtering
  const [selectedAccountType, setSelectedAccountType] = useState(null);
  const [filteredAccounts, setFilteredAccounts] = useState(null); // State to hold the filtered accounts

  // Function to handle filtering based on account type
  const handleFilterByAccountType = (accountType) => {
    setSelectedAccountType(accountType);
  };

  useEffect(() => {
    if (!accountsList) return;
    // Filter the accountsList based on the selectedAccountType
    if (!selectedAccountType) {
      // If no account type is selected, set the filteredAccounts to the accountsList
      setFilteredAccounts(accountsList);
    } else {
      let filteredAccounts = [];
      if (activeUser.authProvider === "Xero") {
        filteredAccounts = selectedAccountType
          ? accountsList.filter(
              (account) => account.type === selectedAccountType
            )
          : accountsList;
      } else if (activeUser.authProvider === "MYOB") {
        filteredAccounts = selectedAccountType
          ? accountsList.filter(
              (account) => account.Type === selectedAccountType
            )
          : accountsList;
      }
      setFilteredAccounts(filteredAccounts);
    }
  }, [accountsList, selectedAccountType]);

  // Function to render the pills/buttons for account types
  const renderAccountTypePills = () => {
    // Fetch unique account types from the accountsList
    let uniqueAccountTypes = [];

    if (activeUser.authProvider === "MYOB") {
      uniqueAccountTypes = [
        ...new Set(accountsList.map((account) => account.Type)),
      ];
    } else if (activeUser.authProvider === "Xero") {
      uniqueAccountTypes = [
        ...new Set(accountsList.map((account) => account.type)),
      ];
    }

    return (
      <>
        <div className="account-type-pills">
          {uniqueAccountTypes.map((type, index) => (
            <Button
              key={index}
              variant={
                selectedAccountType === type ? "primary" : "outline-primary"
              }
              onClick={() => handleFilterByAccountType(type)}
              className="m-1"
            >
              {type}
            </Button>
          ))}
        </div>
        <div style={{ textAlign: "right" }}>
          <Button
            variant="primary"
            onClick={() => handleFilterByAccountType(null)}
            className="m-1"
          >
            Clear Filter
          </Button>
        </div>
      </>
    );
  };

  // Function to handle row selection/deselection
  const handleRowSelection = (row) => {
    setSelectedRowIds((prevSelectedRowIds) => {
      const updatedSelectedRowIds = { ...prevSelectedRowIds };
      if (updatedSelectedRowIds[row.index]) {
        delete updatedSelectedRowIds[row.index];
      } else {
        updatedSelectedRowIds[row.index] = true;
      }

      const isAnyRowSelected = Object.keys(updatedSelectedRowIds).length > 0;
      const isAllRowsSelected =
        isAnyRowSelected &&
        Object.keys(updatedSelectedRowIds).length === filteredAccounts.length;

      setIsAllRowsSelected(isAllRowsSelected);

      const selectedAccount = filteredAccounts[row.index];
      const updatedFilteredAccounts = [...filteredAccounts];
      const updatedAccountsList = [...accountsList];

      updatedFilteredAccounts[row.index] = {
        ...selectedAccount,
        isSelected: !selectedAccount.isSelected,
      };

      let accountToUpdate = [];
      if (activeUser.authProvider === "Xero") {
        accountToUpdate = updatedAccountsList.find(
          (account) => account.accountID === selectedAccount.accountID
        );
      } else if (activeUser.authProvider === "MYOB") {
        accountToUpdate = updatedAccountsList.find(
          (account) => account.UID === selectedAccount.UID
        );
      } else if (csvPresent) {
        accountToUpdate = updatedAccountsList.find(
          (account) => account.id === selectedAccount.id
        );
      }

      if (accountToUpdate) {
        accountToUpdate.isSelected = !selectedAccount.isSelected;
      }

      setFilteredAccounts(updatedFilteredAccounts);
      setAccountsList(updatedAccountsList);

      return updatedSelectedRowIds;
    });
  };

  // Function to handle header checkbox selection/deselection
  const handleHeaderCheckboxChange = () => {
    setSelectedRowIds((prevSelectedRowIds) => {
      const updatedSelectedRowIds = {};

      if (!isAllRowsSelected) {
        filteredAccounts.forEach((_, index) => {
          updatedSelectedRowIds[index] = true;
        });
      }

      setIsAllRowsSelected(!isAllRowsSelected);

      const updatedFilteredAccounts = filteredAccounts.map((account) => ({
        ...account,
        isSelected: !isAllRowsSelected,
      }));

      const updatedAccountsList = accountsList.map((account) => {
        let found = [];

        if (activeUser.authProvider === "Xero") {
          found = updatedFilteredAccounts.find(
            (filteredAccount) => filteredAccount.accountID === account.accountID
          );
        } else if (activeUser.authProvider === "MYOB") {
          found = updatedFilteredAccounts.find(
            (filteredAccount) => filteredAccount.UID === account.UID
          );
        } else if (csvPresent) {
          found = updatedFilteredAccounts.find(
            (filteredAccount) => filteredAccount.id === account.id
          );
        }

        if (found) {
          return { ...account, isSelected: !isAllRowsSelected };
        }
        return account;
      });

      setFilteredAccounts(updatedFilteredAccounts);
      setAccountsList(updatedAccountsList);

      return updatedSelectedRowIds;
    });
  };

  //on load, set selectedRowIds based on filteredAccounts
  useEffect(() => {
    if (!filteredAccounts) return;
    let selectedRowIds = {};
    for (let i = 0; i < filteredAccounts.length; i++) {
      if (filteredAccounts[i].isSelected) {
        selectedRowIds[i] = true;
      }
    }
    setSelectedRowIds(selectedRowIds);
  }, [filteredAccounts]);

  const XeroColumns = [
    {
      id: "selection",
      Header: (
        <Form.Check
          type="checkbox"
          className="form-check fs-0 mb-0 d-flex align-items-center"
        >
          <Form.Check.Input
            type="checkbox"
            checked={isAllRowsSelected}
            onChange={handleHeaderCheckboxChange}
            // Add indeterminate state based on the number of selected rows
            ref={(element) => {
              if (element) {
                element.indeterminate =
                  Object.keys(selectedRowIds).length > 0 &&
                  Object.keys(selectedRowIds).length < accountsList.length;
              }
            }}
          />
        </Form.Check>
      ),
      Cell: ({ row }) => (
        <Form.Check
          type="checkbox"
          className="form-check fs-0 mb-0 d-flex align-items-center"
        >
          <Form.Check.Input
            type="checkbox"
            checked={selectedRowIds[row.index]}
            onChange={() => handleRowSelection(row)}
          />
        </Form.Check>
      ),
      width: 10, // Set the desired width for the selection column
    },
    {
      accessor: "code",
      Header: "Account Code",
    },
    {
      accessor: "name",
      Header: "Account Name",
      cellProps: {
        className: "text-wrap",
      },
    },
    {
      accessor: "type",
      Header: "Account Type",
      cellProps: {
        className: "text-wrap",
      },
    },
  ];

  const MYOBColumns = [
    {
      id: "selection",
      Header: (
        <Form.Check
          type="checkbox"
          className="form-check fs-0 mb-0 d-flex align-items-center"
        >
          <Form.Check.Input
            type="checkbox"
            checked={isAllRowsSelected}
            onChange={handleHeaderCheckboxChange}
            // Add indeterminate state based on the number of selected rows
            ref={(element) => {
              if (element) {
                element.indeterminate =
                  Object.keys(selectedRowIds).length > 0 &&
                  Object.keys(selectedRowIds).length < accountsList.length;
              }
            }}
          />
        </Form.Check>
      ),
      Cell: ({ row }) => (
        <Form.Check
          type="checkbox"
          className="form-check fs-0 mb-0 d-flex align-items-center"
        >
          <Form.Check.Input
            type="checkbox"
            checked={selectedRowIds[row.index]}
            onChange={() => handleRowSelection(row)}
          />
        </Form.Check>
      ),
      width: 10, // Set the desired width for the selection column
    },
    {
      accessor: "DisplayID",
      Header: "Display ID",
    },
    {
      accessor: "Name",
      Header: "Account Name",
      cellProps: {
        className: "text-wrap",
      },
    },
    {
      accessor: "Type",
      Header: "Account Type",
      cellProps: {
        className: "text-wrap",
      },
    },
  ];

  const CSVColumns = [
    {
      id: "selection",
      Header: (
        <Form.Check
          type="checkbox"
          className="form-check fs-0 mb-0 d-flex align-items-center"
        >
          <Form.Check.Input
            type="checkbox"
            checked={isAllRowsSelected}
            onChange={handleHeaderCheckboxChange}
            // Add indeterminate state based on the number of selected rows
            ref={(element) => {
              if (element) {
                element.indeterminate =
                  Object.keys(selectedRowIds).length > 0 &&
                  Object.keys(selectedRowIds).length < accountsList.length;
              }
            }}
          />
        </Form.Check>
      ),
      Cell: ({ row }) => (
        <Form.Check
          type="checkbox"
          className="form-check fs-0 mb-0 d-flex align-items-center"
        >
          <Form.Check.Input
            type="checkbox"
            checked={selectedRowIds[row.index]}
            onChange={() => handleRowSelection(row)}
          />
        </Form.Check>
      ),
      width: 10, // Set the desired width for the selection column
    },
    {
      accessor: "code",
      Header: "Account Code",
    },
    {
      accessor: "name",
      Header: "Account Name",
      cellProps: {
        className: "text-wrap",
      },
    },
    {
      accessor: "type",
      Header: "Account Type",
      cellProps: {
        className: "text-wrap",
      },
    },
  ];

  return (
    <>
      {(activeUser.authProvider === "Xero" ||
        activeUser.authProvider === "MYOB") && (
        <>
          {renderAccountTypePills()}
          <br />
        </>
      )}

      {filteredAccounts?.length > 0 ? (
        <AdvanceTableWrapper
          columns={
            activeUser.authProvider === "Xero"
              ? XeroColumns
              : activeUser.authProvider === "MYOB"
              ? MYOBColumns
              : CSVColumns
          }
          data={filteredAccounts}
          selection={false}
          sortable
          // pagination
          // perPage={accountsList.length}
          // rowCount={accountsList.length}
        >
          <Card>
            <Card.Header>
              <Row className="flex-start-center mb-1">
                <Col xs={6} lg={8}>
                  <AdvanceTableSearchBox table />
                </Col>
                <Col xs={6} lg={4} className="text-end">
                  <AccountsTableHeader
                    selectedRowIds={selectedRowIds}
                    setSelectedRowIds={setSelectedRowIds}
                    accountsList={accountsList}
                    setAccountsList={setAccountsList}
                    isEdit={isEdit}
                    handleEditSelectedAccountNamesClose={
                      handleEditSelectedAccountNamesClose
                    }
                  />
                </Col>
              </Row>
            </Card.Header>

            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="btn-reveal-trigger text-nowrap align-middle"
                tableProps={{
                  size: "lg",
                  className: "fs--1 mb-0 overflow-hidden",
                }}
              />
            </Card.Body>
            {/* <Card.Footer>
              <AdvanceTableFooter
                rowCount={accountsList.length}
                table
                rowInfo
                navButtons
              />
            </Card.Footer> */}
          </Card>
        </AdvanceTableWrapper>
      ) : null}
    </>
  );
};

const AccountsTableHeader = ({
  selectedRowIds,
  accountsList,
  setAccountsList,
  isEdit,
  handleEditSelectedAccountNamesClose,
}) => {
  //console.log("selectedRowIds=>", selectedRowIds);
  //console.log("accountsList=>", accountsList);
  const csvPresent = JSON.parse(sessionStorage.getItem("csvPresent"));
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const [isLoading, setIsLoading] = React.useState(false);
  const [showWarningModal, setShowWarningModal] = React.useState(false);
  const [selectedAccountIds, setSelectedAccountIds] = React.useState(() => {
    let selectedAccountIds = {};
    for (let i = 0; i < accountsList.length; i++) {
      if (accountsList[i].isSelected) {
        selectedAccountIds[i.toString()] = true;
      }
    }
    return selectedAccountIds;
  });

  useEffect(() => {
    if (!accountsList) return;

    let selectedAccountIds = {};
    for (let i = 0; i < accountsList.length; i++) {
      if (accountsList[i].isSelected) {
        selectedAccountIds[i.toString()] = true;
      }
    }
    setSelectedAccountIds(selectedAccountIds);
  }, [accountsList]);

  const handleClose = async (isRefresh) => {
    setShowWarningModal(false);
    if (isRefresh) {
      //fetch accounts list from firebase by passing activeUser.businessId
      const querySnapshot = await getDocs(
        collection(
          fireStoreDB,
          "Accounts",
          activeUser.businessId,
          csvPresent ? "CSV" : activeUser.authProvider
        )
      );
      let accountsList = [];
      querySnapshot.forEach((doc) => {
        const data = JSON.parse(JSON.stringify(doc.data()));
        if (activeUser.authProvider === "Xero") {
          if (!EXCLUDE_ACCOUNT_TYPES_XERO.includes(data.type)) {
            accountsList.push(data);
          }
        }
        if (activeUser.authProvider === "MYOB" || csvPresent) {
          accountsList.push(data);
        }
      });
      // console.log("accountsList=>", accountsList);
      if (activeUser.authProvider === "Xero") {
        //sort accountsList by name
        accountsList = accountsList.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
      }
      if (activeUser.authProvider === "MYOB") {
        // sort accountsList by DisplayID
        accountsList = accountsList.sort((a, b) => {
          // Extract the DisplayID values from the account objects
          const displayIDA = a.DisplayID;
          const displayIDB = b.DisplayID;

          // Use the localeCompare method to perform a string comparison
          return displayIDA.localeCompare(displayIDB);
        });
      }
      if (csvPresent) {
        accountsList = accountsList.sort((a, b) => {
          // Extract the DisplayID values from the account objects
          const nameA = a.name;
          const nameB = b.name;

          // Use the localeCompare method to perform a string comparison
          return nameA.localeCompare(nameB);
        });
      }
      //set selectedAccountIds
      let selectedAccountIds = {};
      for (let i = 0; i < accountsList.length; i++) {
        if (accountsList[i].isSelected) {
          selectedAccountIds[i.toString()] = true;
        }
      }
      setAccountsList(accountsList);
      setSelectedAccountIds(selectedAccountIds);
      handleEditSelectedAccountNamesClose(true);
    }
  };

  const findDeSelectedAccounts = async () => {
    //compare selectedRowIds with selectedAccountList
    let deSelectedAccounts = [];
    let selectedAccounts = Object.keys(selectedAccountIds);
    let selectedRowIdsArray = Object.keys(selectedAccountIds);
    for (let i = 0; i < accountsList.length; i++) {
      const item = i.toString();
      if (!selectedRowIdsArray.includes(item)) {
        if (activeUser.authProvider === "Xero") {
          deSelectedAccounts.push(accountsList[item].accountID);
        }
        if (activeUser.authProvider === "MYOB") {
          deSelectedAccounts.push(accountsList[item].UID);
        }
        if (csvPresent) {
          deSelectedAccounts.push(accountsList[item].id);
        }
      }
    }
    // console.log("deSelectedAccounts=>", deSelectedAccounts);
    return deSelectedAccounts;
  };

  const updateSelectedAccounts = async () => {
    //fliter accountsList based on selectedRowIds
    let selectedAccounts = [];
    for (let i = 0; i < accountsList.length; i++) {
      if (selectedAccountIds[i]) {
        if (activeUser.authProvider === "Xero") {
          selectedAccounts.push(accountsList[i].accountID);
        }
        if (activeUser.authProvider === "MYOB") {
          selectedAccounts.push(accountsList[i].UID);
        }
        if (csvPresent) {
          selectedAccounts.push(accountsList[i].id);
        }
      }
    }
    //console.log("selectedAccounts=>", selectedAccounts);

    //update 'isSelected' to true in firebase collection 'Accounts'
    // console.log(
    //   "Updating isSelected to true in firebase collection 'Accounts' START"
    // );
    const accountsRef = collection(
      fireStoreDB,
      "Accounts",
      activeUser.businessId,
      csvPresent ? "CSV" : activeUser.authProvider
    );
    const batch = writeBatch(fireStoreDB);
    selectedAccounts.forEach(async (accountId) => {
      const docRef = doc(accountsRef, accountId);
      batch.update(docRef, {
        isSelected: true,
      });
    });
    await batch.commit();
    // console.log(
    //   "Updating isSelected to true in firebase collection 'Accounts' END"
    // );
  };

  const updateDeSelectedAccounts = async () => {
    const deSelectedAccounts = await findDeSelectedAccounts();
    //console.log("deSelectedAccounts=>", deSelectedAccounts);

    //update 'isSelected' to false in firebase collection 'Accounts'
    // console.log(
    //   "Updating isSelected to false in firebase collection 'Accounts' START"
    // );
    const accountsRef = collection(
      fireStoreDB,
      "Accounts",
      activeUser.businessId,
      csvPresent ? "CSV" : activeUser.authProvider
    );
    const batch = writeBatch(fireStoreDB);
    deSelectedAccounts.forEach(async (accountId) => {
      const docRef = doc(accountsRef, accountId);
      batch.update(docRef, {
        isSelected: false,
      });
    });
    await batch.commit();
    // console.log(
    //   "Updating isSelected to false in firebase collection 'Accounts' END"
    // );
  };

  const handleApply = async () => {
    //console.log("selectedRowIds=>", selectedRowIds);
    if (isEdit) {
      setShowWarningModal(true);
    } else {
      setIsLoading(true);

      //update selected accounts in firebase
      await updateSelectedAccounts();
      //update de-selected accounts in firebase
      await updateDeSelectedAccounts();
      //filter journal based on selected accounts
      const response = await fetch(
        `/api/${activeUser.authProvider.toLowerCase()}/filter-accounts`,
        {
          method: "POST",
          headers: getAuthorizationHeaderWithContentType(),
          body: JSON.stringify({ userId: activeUser.uid }),
          mode: "cors",
        }
      );
      if (response.status === 401) {
        clearLoginData();
      } else if (response.status !== 200) {
        toast.error("Failed to calculate emissions");
        setIsLoading(false);
      } else {
        const data = await response.json();
        //console.log("data=>", data);
        const userData = JSON.parse(getActiveUserData());
        userData.processingStatus = data.processingstatus;
        userData.authProvider = data.authProvider;
        setActiveUserData(JSON.stringify(userData));
        setIsLoading(false);
        window.location.href = "/welcome";
      }
    }
  };

  return (
    <>
      {showWarningModal ? (
        <WarningModal
          show={showWarningModal}
          handleClose={handleClose}
          updateSelectedAccounts={updateSelectedAccounts}
          updateDeSelectedAccounts={updateDeSelectedAccounts}
        />
      ) : null}

      <Row className="flex-between-center">
        <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
          <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
            {Object.keys(selectedAccountIds).length >= 0
              ? "You have selected " +
                Object.keys(selectedAccountIds).length +
                " account(s)"
              : "Selection Example"}
          </h5>
        </Col>
        <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
          <div className="d-flex">
            <Button
              type="button"
              variant="warning"
              size="sm"
              className="ms-2"
              disabled={Object.keys(selectedAccountIds).length === 0}
              onClick={handleApply}
            >
              {isLoading ? (
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                  style={{ marginRight: "10px" }}
                ></span>
              ) : null}
              Apply
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

const WarningModal = ({
  show,
  handleClose,
  updateSelectedAccounts,
  updateDeSelectedAccounts,
}) => {
  const csvPresent = JSON.parse(sessionStorage.getItem("csvPresent"));
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const [isUpdating, setIsUpdating] = React.useState(false);

  const updateUnifiedData = async () => {
    if (csvPresent) {
      const response = await fetch(`/api/files/update-unified-data`, {
        method: "PUT",
        headers: getAuthorizationHeaderWithContentType(),
        body: JSON.stringify({ userId: activeUser.uid }),
        mode: "cors",
      });
      if (response.status === 401) {
        clearLoginData();
      } else if (response.status !== 200) {
        toast.error("Failed to retrigger emission calculations");
      }
    } else {
      if (activeUser.authProvider !== "" || activeUser.authProvider !== null) {
        const response = await fetch(
          `/api/${activeUser.authProvider}/update-unified-data`,
          {
            method: "PUT",
            headers: getAuthorizationHeaderWithContentType(),
            body: JSON.stringify({ userId: activeUser.uid }),
            mode: "cors",
          }
        );
        if (response.status === 401) {
          clearLoginData();
        } else if (response.status !== 200) {
          toast.error("Failed to retrigger emission calculations");
        }
      }
    }
  };

  const handleAccountSelection = async () => {
    setIsUpdating(true);

    // console.log("updating selected accounts in firebase START");
    await updateSelectedAccounts();
    // console.log("updating selected accounts in firebase END");
    // console.log("updating de-selected accounts in firebase START");
    await updateDeSelectedAccounts();
    // console.log("updating de-selected accounts in firebase END");

    // console.log("updating unifiedData based on selected accounts START");
    await updateUnifiedData();
    // console.log("updating unifiedData based on selected accounts END");

    setIsUpdating(false);
    handleClose(true);
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose(false);
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Warning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isUpdating ? (
          <div className="text-center">
            <Spinner animation="border" />
            <p>Updating selected accounts...</p>
          </div>
        ) : (
          <p>
            Do you want to continue? This will overwrite the existing
            calculation.
          </p>
        )}
      </Modal.Body>
      {!isUpdating && (
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleClose(false);
            }}
          >
            Close
          </Button>
          <Button variant="danger" onClick={handleAccountSelection}>
            OK
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default AccountsListForm;
