import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import LoginForm from "../LoginForm";
import AuthCardLayout from "./AuthCardLayout";

const Login = () => {
  const { subscriptionID } = useParams();
  const location = useLocation();
  // const state = location.state || {};
  const [employeeEngagementFormId, setEmployeeEngagementFormId] = useState("");

  useEffect(() => {
    if (location.state && location) {
      setEmployeeEngagementFormId(location.state.employeeEngagementFormId);
    }
  }, [location.state]);

  return (
    <AuthCardLayout
      leftSideContent={
        <p className="text-white">
          Don't have an account?
          <br />
          <Link
            className="text-white text-decoration-underline"
            to="/authentication/card/register"
          >
            Get started!
          </Link>
        </p>
      }
    >
      <h3>Account Login</h3>
      <LoginForm
        layout="card"
        hasLabel
        employeeEngagementFormId={employeeEngagementFormId}
      />
    </AuthCardLayout>
  );
};

export default Login;
