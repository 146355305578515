import React, { useContext, useState } from "react";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  ListGroup,
  Card,
} from "react-bootstrap";
import { WaterContext } from "../../context/Context";
import WaterTable from "./WaterTable";
import Select from "react-select";

const unitOptions = [
  { value: "Litres", label: "Litres" },
  { value: "Kilolitres", label: "Kilolitres" },
];

export const customOption = (props) => {
  const { innerRef, innerProps, data } = props;
  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={{ display: "flex", alignItems: "center", padding: 5 }}
    >
      {data.icon}
      <span style={{ marginLeft: 5 }}>{data.label}</span>
    </div>
  );
};

function PageExactData() {
  // const [wasteType, setWasteType] = useState(null);
  const [volumeOfWater, setVolumeOfWater] = useState("");
  const [unit, setUnit] = useState(null);

  const { lastStep, handleSubmitContext, setCategoriesData, categoriesData } =
    useContext(WaterContext);

  const handleAddEntry = () => {
    // console.log(volumeOfWater);
    let volume = parseFloat(volumeOfWater);
    if (volume && !isNaN(volume) && unit) {
      if (unit.value === "Litres") {
        volume = volume / 1000; // Convert Litres to Kilolitres
      }

      setCategoriesData([
        ...categoriesData,
        {
          category: "Water",
          volumeOfWater: volume,
          source: "units",
        },
      ]);
      // setWasteType(null);
      setVolumeOfWater("");
      setUnit(null);
    }
  };

  const handleSubmit = () => {
    // Handle form submission logic here
    // console.log("Form submitted", categoriesData);
    handleSubmitContext();
  };

  return (
    <Container>
      <Row>
        <p className="text-center mb-4">Please enter your total water usage</p>
      </Row>
      {/* <Form> */}

      <Row className="mb-3">
        {/* <Form.Group controlId="formWaterConsumption"> */}
        <Col>
          <Form.Label>Total water consumption</Form.Label>
          <Form.Control
            type="number"
            placeholder="Water consumption"
            value={volumeOfWater}
            onChange={(e) => setVolumeOfWater(e.target.value)}
          />
        </Col>
        <Col>
          <Form.Label>Units</Form.Label>
          <Select
            options={unitOptions}
            value={unit}
            onChange={setUnit}
            placeholder="Select Unit"
          />
          {/* <Form.Control
            as="select"
            value={unit}
            onChange={(e) => setUnit(e.target.value)}
          >
            <option value="Litres">Litres</option>
            <option value="Kilolitres">Kilolitres</option>
          </Form.Control> */}
        </Col>
        {/* </Form.Group> */}
      </Row>
      <Row className="mt-3">
        <Col>
          <Button variant="primary" onClick={handleAddEntry}>
            Add
          </Button>
        </Col>
      </Row>
      {/* </Form> */}
      <Row className="mt-5">
        <h3>Data to Submit:</h3>
        <WaterTable categoriesData={categoriesData} />
        <Col>
          {categoriesData.length > 0 && (
            <Button variant="success" onClick={handleSubmit} className="mt-3">
              Submit All Entries
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default PageExactData;
