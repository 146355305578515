import React, { useContext, useState } from "react";
import { Button, Container, Form, Row, Col } from "react-bootstrap";
import { WaterContext } from "../../context/Context";
import WaterTable from "./WaterTable";

const infoTable = [
  {
    type: "Residential",
    subtype: "Single Lot Torrens",
    keyMetric: "Dwelling",
    metricUnit: "Number of dwellings",
    avgDemand: 623,
  },
  {
    type: "Residential",
    subtype: "Flats Torrens",
    keyMetric: "Net floor area",
    metricUnit: "m²",
    avgDemand: 2.36,
  },
  {
    type: "Residential",
    subtype: "High Rise Units",
    keyMetric: "Net floor area",
    metricUnit: "m²",
    avgDemand: 3.34,
  },
  {
    type: "Residential",
    subtype: "Single Lot Community",
    keyMetric: "Dwelling",
    metricUnit: "Number of dwellings",
    avgDemand: 623,
  },
  {
    type: "Commercial",
    subtype: "Aged Accom - Self Care",
    keyMetric: "Net floor area",
    metricUnit: "m²",
    avgDemand: 2.5,
  },
  {
    type: "Commercial",
    subtype: "Aged Accom - Hostel",
    keyMetric: "Bed",
    metricUnit: "Number of beds",
    avgDemand: 271,
  },
  {
    type: "Commercial",
    subtype: "Aged Accom - Full Care",
    keyMetric: "Bed",
    metricUnit: "Number of beds",
    avgDemand: 271,
  },
  {
    type: "Commercial",
    subtype: "Childcare",
    keyMetric: "Net floor area",
    metricUnit: "m²",
    avgDemand: 3.6,
  },
  {
    type: "Commercial",
    subtype: "Hotel / motel / serviced apartments",
    keyMetric: "Room",
    metricUnit: "Number of rooms",
    avgDemand: 359.94,
  },
  {
    type: "Commercial",
    subtype: "Office",
    keyMetric: "Net floor area",
    metricUnit: "m²",
    avgDemand: 2.27,
  },
  {
    type: "Commercial",
    subtype: "Shopping Centre",
    keyMetric: "Net floor area",
    metricUnit: "m²",
    avgDemand: 3,
  },
  {
    type: "Commercial",
    subtype: "Laundry / Dry Cleaner",
    keyMetric: "Net floor area",
    metricUnit: "m²",
    avgDemand: 10.5,
  },
  {
    type: "Commercial",
    subtype: "Café / Fast Food / Butcher / Deli",
    keyMetric: "Net floor area",
    metricUnit: "m²",
    avgDemand: 2.48,
  },
  {
    type: "Commercial",
    subtype: "Retail Units",
    keyMetric: "Net floor area",
    metricUnit: "m²",
    avgDemand: 2.48,
  },
  {
    type: "Commercial",
    subtype: "Medical / Veterinary",
    keyMetric: "Net floor area",
    metricUnit: "m²",
    avgDemand: 2.48,
  },
  {
    type: "Commercial",
    subtype: "Mechanical Repair",
    keyMetric: "Net floor area",
    metricUnit: "m²",
    avgDemand: 2.48,
  },
  {
    type: "Commercial",
    subtype: "Car / Boat Sales",
    keyMetric: "Net floor area",
    metricUnit: "m²",
    avgDemand: 2.48,
  },
  {
    type: "Commercial",
    subtype: "Car Wash",
    keyMetric: "Net floor area",
    metricUnit: "m²",
    avgDemand: 9.4,
  },
  {
    type: "Commercial",
    subtype: "Club",
    keyMetric: "Net floor area",
    metricUnit: "m²",
    avgDemand: 3.77,
  },
  {
    type: "Industrial",
    subtype: "Light Factory Unit",
    keyMetric: "Developed floor area",
    metricUnit: "m²",
    avgDemand: 2.82,
  },
  {
    type: "Industrial",
    subtype: "Warehousing",
    keyMetric: "Developed floor area",
    metricUnit: "m²",
    avgDemand: 2.82,
  },
  {
    type: "Industrial",
    subtype: "Transport / Bus depot",
    keyMetric: "Site area",
    metricUnit: "m²",
    avgDemand: 0.91,
  },
  {
    type: "Special Uses",
    subtype: "University",
    keyMetric: "Student",
    metricUnit: "Number of students",
    avgDemand: 20,
  },
  {
    type: "Special Uses",
    subtype: "School",
    keyMetric: "Student",
    metricUnit: "Number of students",
    avgDemand: 20,
  },
  {
    type: "Special Uses",
    subtype: "Hospital",
    keyMetric: "Bed",
    metricUnit: "Number of beds",
    avgDemand: 271,
  },
  {
    type: "Special Uses",
    subtype: "Religious assemblies",
    keyMetric: "Developed floor area",
    metricUnit: "m²",
    avgDemand: 1.3,
  },
  {
    type: "Special Uses",
    subtype: "Government depot",
    keyMetric: "Site area",
    metricUnit: "m²",
    avgDemand: 0.91,
  },
  {
    type: "Special Uses",
    subtype: "Community Centre / Library",
    keyMetric: "Floor area",
    metricUnit: "m²",
    avgDemand: 1.84,
  },
  {
    type: "Special Uses",
    subtype: "Parks & Reserves",
    keyMetric: "Floor area",
    metricUnit: "m²",
    avgDemand: 1.4,
  },
  {
    type: "Special Uses",
    subtype: "Services: Police, Ambulance, etc",
    keyMetric: "Floor area",
    metricUnit: "m²",
    avgDemand: 1.4,
  },
];

function PageEstimationData() {
  const {
    lastStep,
    handleSubmitContext,
    setCategoriesData,
    categoriesData,
    formData,
  } = useContext(WaterContext);
  const [developmentType, setDevelopmentType] = useState("");
  const [developmentSubType, setDevelopmentSubType] = useState("");
  const [keyMetric, setKeyMetric] = useState("");
  const [keyMetricValue, setKeyMetricValue] = useState("");
  const [keyMetricUnit, setKeyMetricUnit] = useState("");
  const [averageDemand, setAverageDemand] = useState(null);
  const [totalVolume, setTotalVolume] = useState(null);
  // console.log("startDate", formData.startDate);
  // console.log("endDate", formData.endDate);

  // Define the start and end dates
  const startDate = new Date(formData.startDate);
  const endDate = new Date(formData.endDate);
  // Calculate the time difference in milliseconds
  const timeDifference = endDate - startDate;
  // Convert the time difference from milliseconds to days
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24) + 1;
  // console.log("daysDifference", daysDifference);

  const handleTypeChange = (e) => {
    setDevelopmentType(e.target.value);
    setDevelopmentSubType("");
    setKeyMetricValue("");
    setAverageDemand(null);
    setTotalVolume(null);
  };

  const handleSubTypeChange = (e) => {
    setDevelopmentSubType(e.target.value);
    setKeyMetricValue("");
    setAverageDemand(null);
    setTotalVolume(null);
  };

  const handleMetricChange = (e) => {
    const value = e.target.value;
    setKeyMetricValue(value);

    const selectedInfo = infoTable.find(
      (info) =>
        info.type === developmentType && info.subtype === developmentSubType
    );

    if (selectedInfo) {
      const calculatedDemand = (selectedInfo.avgDemand * value) / 1000;
      let calculatedTotalDemand = (calculatedDemand * daysDifference).toFixed(
        2
      );
      setAverageDemand(calculatedDemand);
      setTotalVolume(calculatedTotalDemand);
      setKeyMetric(selectedInfo.keyMetric);
      setKeyMetricUnit(selectedInfo.metricUnit);
    }
  };

  const handleAddEntry = () => {
    if (
      developmentType &&
      developmentSubType &&
      keyMetricValue &&
      averageDemand
    ) {
      setCategoriesData((prevEntries) => [
        ...prevEntries,
        {
          category: "Water",
          volumeOfWater: totalVolume,
          // volumeOfWater: averageDemand,
          developmentType: developmentType,
          developmentSubType: developmentSubType,
          keyMetric: keyMetric,
          keyMetricValue: keyMetricValue,
          keyMetricUnit: keyMetricUnit,
          averageDemand: averageDemand,
          source: "estimator",

          // wasteIcon: wasteType.icon,
          // wasteType: wasteType.value,
          // frequency,
          // volBins: volBins.value,
          // numBins,
          // percBins,
          // volumeOfCollections,
          // totalWeightOfWaste,
          // source: "estimator",
        },
      ]);
      resetForm();
    }
  };

  const resetForm = () => {
    setDevelopmentType("");
    setDevelopmentSubType("");
    setKeyMetricValue("");
    setAverageDemand(null);
    setTotalVolume(null);
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // Here you can save the user's answers to a database or another state
  //   console.log({
  //     developmentType,
  //     developmentSubType,
  //     setKeyMetricValue,
  //     averageDemand,
  //   });
  // };

  const handleSubmit = () => {
    // console.log("Form submitted", categoriesData);
    // const addedWaste = categoriesData.map(({ wasteIcon, ...item }) => item);
    // setCategoriesData(addedWaste);
    handleSubmitContext();
  };

  const filteredSubTypes = infoTable.filter(
    (info) => info.type === developmentType
  );
  const selectedInfo = infoTable.find(
    (info) =>
      info.type === developmentType && info.subtype === developmentSubType
  );

  return (
    <Container>
      <Row>
        {/* <h3 className="text-center mb-4">Estimation Page</h3> */}
        <p className="text-center mb-4">
          On this page, you can enter the details of your water consumption.
          Based on the information provided, we will calculate the equivalent
          volume and weight of the waste produced. Please fill in the fields
          below and add your entries.
        </p>
      </Row>
      <Form onSubmit={handleSubmit}>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            Development Type
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              as="select"
              value={developmentType}
              onChange={handleTypeChange}
            >
              <option value="">Select Type</option>
              {[...new Set(infoTable.map((info) => info.type))].map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            Development Sub-Type
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              as="select"
              value={developmentSubType}
              onChange={handleSubTypeChange}
              disabled={!developmentType}
            >
              <option value="">Select Sub-Type</option>
              {[...new Set(filteredSubTypes.map((info) => info.subtype))].map(
                (subtype) => (
                  <option key={subtype} value={subtype}>
                    {subtype}
                  </option>
                )
              )}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            {/* Key Metric ({selectedInfo ? selectedInfo.keyMetric : ""}) */}
            Key Metric - {selectedInfo ? selectedInfo.keyMetric : ""} (
            {selectedInfo ? selectedInfo.metricUnit : ""})
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="number"
              value={keyMetricValue}
              onChange={handleMetricChange}
              placeholder={`Enter ${
                selectedInfo ? selectedInfo.metricUnit : "value"
              }`}
              disabled={!developmentSubType}
            />
          </Col>
        </Form.Group>
        {/* 
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            Average Daily Water Usage (kL)
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              value={averageDemand !== null ? averageDemand.toFixed(2) : ""}
              readOnly
            />
          </Col>
        </Form.Group> */}
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2}>
            Total Water Usage over Reporting Period (kL)
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              value={totalVolume !== null ? totalVolume : ""}
              readOnly
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Col sm={{ span: 10, offset: 2 }}>
            <Button onClick={handleAddEntry}>Add</Button>
          </Col>
        </Form.Group>
      </Form>
      <Row className="mt-5">
        <h3>Data to Submit:</h3>
        <WaterTable categoriesData={categoriesData} />
        <Col>
          {categoriesData.length > 0 && (
            <Button variant="success" onClick={handleSubmit} className="mt-3">
              Submit All Entries
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default PageEstimationData;
