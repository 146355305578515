import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../context/Context";
import { getAllEmissionsBoundaries } from "./EmissionsBoundariesApi";
import { getActiveUserData } from "../../helpers/store";
import EmissionsBoundariesProvider from "./EmissionsBoundariesProvider";
import EmissionsBoundariesHeader from "./EmissionsBoundariesHeader";
import EmissionsBoundariesContainer from "./EmissionsBoundariesContainer";
import { Spinner } from "react-bootstrap";

const EmissionsBoundaries = () => {
  const {
    config: { isFluid, isNavbarVerticalCollapsed },
    setConfig,
  } = useContext(AppContext);
  const [kanbanIsFluid] = useState(isFluid);
  const [kanbanIsNavbarVerticalCollapsed] = useState(isNavbarVerticalCollapsed);
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const [inventoryId] = useState(activeUser.inventoryId);
  const [emissionsBoundaries, setEmissionsBoundaries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setConfig("isFluid", true);
    setConfig("isNavbarVerticalCollapsed", false);

    return () => {
      setConfig("isFluid", kanbanIsFluid);
      setConfig("isNavbarVerticalCollapsed", kanbanIsNavbarVerticalCollapsed);
    };
  }, []);

  const get = async (inventoryId) => {
    setIsLoading(true);
    const emissionsBoundaries = await getAllEmissionsBoundaries(inventoryId);
    setEmissionsBoundaries(emissionsBoundaries);
    setIsLoading(false);
  };

  useEffect(() => {
    if (inventoryId) {
      get(inventoryId);
    }
  }, [inventoryId]);

  return (
    <>
      <EmissionsBoundariesProvider>
        <EmissionsBoundariesHeader />
        {isLoading && (
          <div className="text-center">
            <Spinner animation="border" variant="primary" />
          </div>
        )}
        {!isLoading && !emissionsBoundaries.length && (
          <div className="text-center">
            <p className="text-muted">No emissions boundaries found.</p>
          </div>
        )}
        {!isLoading && emissionsBoundaries.length > 0 && (
          <EmissionsBoundariesContainer
            emissionsBoundariesList={emissionsBoundaries}
          />
        )}
      </EmissionsBoundariesProvider>
    </>
  );
};

export default EmissionsBoundaries;
