import React from "react";
import { AuthProvider } from "../src/Contexts/AuthContext";
import AppRouter from "./AppRouter";

const App = () => {
  return (
    <AuthProvider>
      <AppRouter></AppRouter>
    </AuthProvider>
  );
};

export default App;
