import React, { useContext } from "react";
import { CloseButton, Modal, Row, Badge } from "react-bootstrap";
import AppContext, { EmissionsBoundariesContext } from "../../context/Context";
import ModalOverview from "./ModalOverview";

const EmissionsBoundariesModal = () => {
  const {
    emissionsBoundariesState: { emissionsBoundaryModal },
    emissionsBoundariesDispatch,
  } = useContext(EmissionsBoundariesContext);

  const {
    config: { isRTL },
  } = useContext(AppContext);

  const handleClose = () => {
    emissionsBoundariesDispatch({ type: "TOGGLE_EMISSIONS_BOUNDARIES_MODAL" });
  };

  const {
    id,
    title,
    emissionsBoundaryId,
    name,
    dataSources,
    description,
    locationList,
    selectedLocations,
    amountAUD,
    amountCO2e,
    reasoning,
    showWarning,
  } = emissionsBoundaryModal.modalContent;

  return (
    <Modal
      show={emissionsBoundaryModal.show}
      size="xl"
      onHide={handleClose}
      contentClassName="border-0"
      dialogClassName="mt-6"
    >
      <div className="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
        <CloseButton
          onClick={handleClose}
          className="btn btn-sm btn-circle d-flex flex-center transition-base"
        />
      </div>
      <Modal.Body className="p-0">
        <div className="bg-light rounded-top-lg px-4 py-3">
          <h4 className="mb-1">
            {title}{" "}
            <Badge
              size="2x"
              pill
              bg={
                name === "Included"
                  ? "success"
                  : name === "Non-quantified"
                  ? "info"
                  : name === "Excluded"
                  ? "danger"
                  : null
              }
            >
              {name}
            </Badge>
          </h4>
          <p className="fs--2 mb-0"></p>
        </div>
        <div className="p-4">
          <Row>
            {/* Overview */}
            <ModalOverview
              id={id}
              locationList={locationList}
              selectedLocations={selectedLocations}
              dataSources={dataSources}
              emissionsBoundaryId={emissionsBoundaryId}
              emissionBoundary={name}
              description={description}
              amountAUD={amountAUD}
              amountCO2e={amountCO2e}
              reasoning={reasoning}
              showWarning={showWarning}
            />
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EmissionsBoundariesModal;
