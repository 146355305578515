import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { TransparencyContext } from "../../context/Context";
import {
  getActiveUserData,
  getSingleInventoryProgress,
  getSubCollectionRef,
  setSingleInventoryProgress,
} from "../../helpers/store";
import {
  getBusinessDetails,
  getTransparencyAssessment,
  getTransparencyQuestions,
  updateWizardStepInDatabase,
} from "./transparencyHubAPI";
import { useLocation } from "react-router-dom";

const TransparencyHubProvider = ({ children }) => {
  const [activeUser, setActiveUser] = useState(() =>
    JSON.parse(getActiveUserData())
  );
  const [currentInventoryProgress, setCurrentInventoryProgress] = useState(() =>
    getSingleInventoryProgress(activeUser?.inventoryId)
  );
  const [step, setStep] = useState(
    currentInventoryProgress?.transparencyStep || 1
  );
  const [supplierData, setSupplierData] = useState(null);
  const [transparencyQuestions, setTransparencyQuestions] = useState(null);
  const [assessmentData, setAssessmentData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [year, setYear] = useState(null);
  const [yearType, setYearType] = useState(null);
  const [prefillRequest, setPrefillRequest] = useState();
  const [selectedAssessment, setSelectedAssessment] = useState();
  const location = useLocation();
  const stepSent = location.state ? location.state.stepSent : null;

  const onLoad = async () => {
    setIsLoading(true);
    if (stepSent) {
      setStep(stepSent);
    }
    let transparencyAssessment = null;

    //getSupplierId
    const businessData = await getBusinessDetails();

    const newSupplierData = {
      primaryCategory: businessData?.primarycategory,
      secondaryCategory: businessData?.secondarycategory,
      name: businessData?.name,
      merchantLogo: businessData?.logourl,
      netnadaId: businessData?.id,
      supplierId: businessData?.supplierid,
    };

    //get TransparencyAssessments
    if (businessData?.supplierid) {
      transparencyAssessment = await getTransparencyAssessment(
        businessData.supplierid
      );
    }
    //get transparencyQuestions
    const questions = await getTransparencyQuestions();

    setTransparencyQuestions(questions);
    //check if answers

    //If not answers we set the modules to the sections we have

    //If answers we set the modules to the
    setAssessmentData(transparencyAssessment);
    const subCollectionRef = getSubCollectionRef();
    const assessmentSelected = transparencyAssessment?.find(
      (assessment) => assessment.id === subCollectionRef
    );
    setSelectedAssessment(assessmentSelected);
    setPrefillRequest(assessmentSelected?.responses?.prefillRequest);
    setSupplierData(newSupplierData);
    setIsLoading(false);
  };
  useEffect(() => {
    console.log("Loading");
    onLoad();
    const yearTypeRaw = activeUser.selectedMeasurementYearType;
    let year = activeUser.selectedMeasurementYear;
    let yearType = yearTypeRaw === "calendar" ? "CY" : "FY";
    if (!year && assessmentData && assessmentData.length > 0) {
      const latestAssessment = assessmentData[assessmentData.length - 1];
      year = latestAssessment.reportingYear;
      yearType = latestAssessment.reportingType === "calendar" ? "CY" : "FY";
    } else if (
      !year &&
      !yearTypeRaw &&
      (!assessmentData || assessmentData.length === 0)
    ) {
      const currentDate = new Date();
      year = currentDate.getFullYear();
      yearType = "CY";
    }
    setYear(year);
    setYearType(yearType);
  }, [activeUser]);

  function checkIfAnyEntryHasFiles(assessmentData) {
    if (!assessmentData) return false;
    return assessmentData.some(
      (assessment) => assessment.files && assessment.files.length > 0
    );
  }
  const hasFiles = checkIfAnyEntryHasFiles(assessmentData);

  function isButtonDisabled(step, maxStep, hasFiles) {
    //Edge case that we do not have inventoryProgress
    //Some users with old token did not create inventoryProgress
    if (!activeUser.inventoryId) {
      if (step === 2 && hasFiles) {
        return false;
      } else if (step === 3) {
        return true;
      } else {
        return false;
      }
    }
    // Specific rules when the current step is 2
    if (step === 2) {
      if (hasFiles) {
        return false;
      } else if (maxStep > step) {
        return false;
      } else {
        return true;
      }
    }
    if (step === 4 && !prefillRequest) {
      return false;
    }
    if (step === maxStep) {
      return true;
    }
    return false;
  }

  const handleNavs = (targetStep) => {
    if (!activeUser.inventoryId) {
      return false;
    }

    if (targetStep < step) {
      setStep(targetStep);
    } else if (targetStep <= currentInventoryProgress?.transparencyStep) {
      setStep(targetStep);
    }
  };

  const nextStep = () => {
    const newStep = step + 1;
    setStep((prevStep) => prevStep + 1);
    if (activeUser.inventoryId) {
      if (newStep > currentInventoryProgress?.transparencyStep) {
        updateWizardStepInDatabase(activeUser.inventoryId, newStep);
        let newInventoryProgress = {
          ...currentInventoryProgress,
          transparencyStep: newStep,
        };
        setSingleInventoryProgress(
          activeUser.inventoryId,
          newInventoryProgress
        );
        setCurrentInventoryProgress(newInventoryProgress);
      }
    }
  };

  const prevStep = () => {
    setStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  const modifyPrefillRequest = () => {
    setPrefillRequest(true);
  };

  const value = {
    step,
    maxStep: currentInventoryProgress?.transparencyStep || 1,
    setStep,
    nextStep,
    prevStep,
    handleNavs,
    assessmentData,
    supplierData,
    isLoading,
    hasFiles,
    isButtonDisabled: isButtonDisabled,
    refetchData: onLoad,
    year,
    yearType,
    transparencyQuestions,
    prefillRequest,
    modifyPrefillRequest,
    selectedAssessment,
  };

  return (
    <TransparencyContext.Provider value={value}>
      {children}
    </TransparencyContext.Provider>
  );
};

TransparencyHubProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TransparencyHubProvider;
