import React from "react";
import AuthCardLayout from "./AuthCardLayout";
import MYOBCompanyListForm from "../MYOBCompanyListForm";
import { getCompanyData } from "../../../helpers/store";

const MYOBCompanyList = () => {
  const [companyList] = React.useState(JSON.parse(getCompanyData()));
  console.log("companyList=>", companyList);

  return (
    <AuthCardLayout>
      <h3>Choose a MYOB business to connect to NetNada</h3>
      <MYOBCompanyListForm hasLabel companyList={companyList} />
    </AuthCardLayout>
  );
};

export default MYOBCompanyList;
