import React, { useContext } from "react";
import { Card, Form, ProgressBar, Container, Row, Col } from "react-bootstrap";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../common/IconButton";
import AppContext, { WasteContext } from "../../../context/Context";
import PageWasteQuestion from "./PageWasteQuestion";
import PageCalculator from "./PageCalculator";
import PageWeightForm from "./PageWeightForm";
import PageDateOfficeQuestion from "./PageDateOfficeQuestion";
import PageSubmitted from "./PageSubmitted";
import PageLoading from "./PageLoading";
const WasteWizardLayout = ({ variant, validation }) => {
  const { isRTL } = useContext(AppContext);
  const { step, key, nextStep, prevStep, isLoading } = useContext(WasteContext);
  const {
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm();
  const onSubmitData = (data) => {
    nextStep();
  };
  const onError = () => {
    if (!validation) {
      clearErrors();
      nextStep();
    }
  };
  const totalSteps = 100; // Set this to your total steps value
  return (
    <>
      <Card
        as={Form}
        noValidate
        onSubmit={handleSubmit(onSubmitData, onError)}
        className="theme-wizard mb-5"
      >
        <Card.Header
          className={classNames("bg-light", {
            "px-4 py-3": variant === "pills",
            "pb-2": !variant,
          })}
        >
          <ProgressBar
            variant="warning"
            now={(step / totalSteps) * 100}
            // label={`${Math.round((step / totalSteps) * 100)}%`}
          />
        </Card.Header>
        <Card.Body className="fw-normal px-md-6 py-4">
          <Container>
            <Row></Row>
            <Row>
              <Col>
                {step === 15 && <PageDateOfficeQuestion />}
                {step === 40 && <PageWasteQuestion />}
                {step === 65 && key === "calculatorPage" && <PageCalculator />}
                {step === 65 && key === "tonnesPage" && <PageWeightForm />}
                {step === 85 && isLoading && <PageLoading />}
                {step === 100 && <PageSubmitted />}
              </Col>
            </Row>
          </Container>
        </Card.Body>
        {step !== 100 && step !== 15 && (
          <Card.Footer className="px-md-6 bg-light d-flex justify-content-between">
            <IconButton
              variant="link"
              icon={isRTL ? faChevronRight : faChevronLeft}
              iconAlign="left"
              transform="down-1 shrink-4"
              className={classNames("px-0 fw-semi-bold", {
                "d-none": step === 1,
              })}
              onClick={() => {
                prevStep();
              }}
            >
              Prev
            </IconButton>
          </Card.Footer>
        )}
      </Card>
    </>
  );
};
export default WasteWizardLayout;
