import React, { useContext, useState } from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { WasteContext } from "../../../context/Context";
import { wasteTypes } from "./wasteData";
import WasteTable from "./WasteTable";

export const customOption = (props) => {
  const { innerRef, innerProps, data } = props;
  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={{ display: "flex", alignItems: "center", padding: 5 }}
    >
      {data.icon}
      <span style={{ marginLeft: 5 }}>{data.label}</span>
    </div>
  );
};

function PageWeightForm() {
  const [wasteType, setWasteType] = useState(null);
  const [totalWeightOfWaste, setTotalWeightOfWaste] = useState("");
  const [totalVolumeOfWaste, setTotalVolumeOfWaste] = useState("");
  const [inputMode, setInputMode] = useState("weight"); // "weight" or "volume"

  const { handleSubmitContext, setCategoriesData, categoriesData } =
    useContext(WasteContext);

  const handleAddEntry = () => {
    const volumeOfCollections = totalVolumeOfWaste;
    const weightOfCollections = totalWeightOfWaste;
    if (wasteType && (totalWeightOfWaste || totalVolumeOfWaste)) {
      setCategoriesData([
        ...categoriesData,
        {
          wasteIcon: wasteType.icon,
          wasteType: wasteType.value,
          totalWeightOfWaste: weightOfCollections,
          volumeOfCollections: volumeOfCollections,
          source: "units",
        },
      ]);
      setWasteType(null);
      setTotalWeightOfWaste("");
      setTotalVolumeOfWaste("");
    }
  };

  const handleSubmit = () => {
    // console.log("Form submitted", categoriesData);
    handleSubmitContext();
  };

  const onChangeWeight = (event) => {
    const value = event.target.value;
    if (!value || parseFloat(value) >= 0) {
      setTotalWeightOfWaste(value);
      setTotalVolumeOfWaste((value / wasteType?.density).toFixed(1) * 1000);
    }
  };

  const onChangeVolume = (event) => {
    const value = event.target.value;
    if (!value || parseFloat(value) >= 0) {
      setTotalVolumeOfWaste(value);
      setTotalWeightOfWaste(((value / 1000) * wasteType?.density).toFixed(3));
    }
  };

  const handleInputModeChange = (mode) => {
    setInputMode(mode);
    setTotalWeightOfWaste(""); // Reset the fields when mode changes
    setTotalVolumeOfWaste("");
  };

  return (
    <Container>
      <Row>
        <p className="text-center mb-4">
          Please select whether you want to enter the waste data by weight
          (tonnes) or by volume (liters). You can only input one type at a time.
          After entering the data, click "Add" to save your entry.
        </p>
      </Row>

      {/* Toggle between weight and volume */}
      <Row className="mb-4">
        <Col xs={6}>
          <Form.Check
            type="radio"
            label="Enter by Weight (Tonnes)"
            name="inputMode"
            id="weightMode"
            checked={inputMode === "weight"}
            onChange={() => handleInputModeChange("weight")}
          />
        </Col>
        <Col xs={6}>
          <Form.Check
            type="radio"
            label="Enter by Volume (Liters)"
            name="inputMode"
            id="volumeMode"
            checked={inputMode === "volume"}
            onChange={() => handleInputModeChange("volume")}
          />
        </Col>
      </Row>

      <Row
        className="mb-3"
        style={{ justifyContent: "center", alignItems: "end" }}
      >
        <Col style={{ minWidth: 350 }}>
          <Form.Label>Select the category of Waste</Form.Label>
          <Select
            value={wasteType}
            onChange={setWasteType}
            options={wasteTypes}
            placeholder="Select Waste Type"
            classNamePrefix="react-select"
            components={{
              Option: customOption,
            }}
          />
        </Col>

        <Col>
          <Form.Label>Weight in tonnes</Form.Label>
          <Form.Control
            type="number"
            placeholder="Tonnes of Waste"
            value={totalWeightOfWaste}
            min="0"
            onChange={onChangeWeight}
            disabled={inputMode !== "weight"}
          />
        </Col>

        <Col>
          <Form.Label>Volume in liters</Form.Label>
          <Form.Control
            type="number"
            placeholder="Volume in Liters"
            value={totalVolumeOfWaste}
            min="0"
            onChange={onChangeVolume}
            disabled={inputMode !== "volume"}
          />
        </Col>

        <Col>
          <Button variant="primary" onClick={handleAddEntry}>
            Add
          </Button>
        </Col>
      </Row>

      <Row className="mt-5">
        <h3>Data to Submit:</h3>
        <WasteTable categoriesData={categoriesData} />
        <Col>
          {categoriesData.length > 0 && (
            <Button variant="success" onClick={handleSubmit} className="mt-3">
              Submit All Entries
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default PageWeightForm;
