import { clearLoginData } from "../../helpers/store";
import { getAuthorizationHeaderWithContentType } from "../../helpers/utils";
import { toast } from "react-toastify";

export const submitWaterForm = async (body) => {
  try {
    // console.log("WaterDataAPI body", body);
    const response = await fetch("/api/water/submit-water-form", {
      method: "POST",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    // console.log("WaterDataAPI response", response);

    if (response.status === 200) {
      toast.success("Water form data saved");
      // return await response.json();
    } else if (response.status === 401) {
      clearLoginData();
    } else {
      toast.error("Failed to save Water form");
    }
  } catch (error) {
    console.error(error);
  }
};

export const getWaterDataByWaterId = async (id) => {
  try {
    const response = await fetch(
      "/api/water/get-water-data-by-water-id/" + id,
      {
        method: "GET",
        headers: getAuthorizationHeaderWithContentType(),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};
