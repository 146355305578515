import { Dropdown } from "react-bootstrap";
import CardDropdown from "../../common/CardDropdown";
import { getFormattedDate } from "../../../helpers/utils";
import SoftBadge from "../../common/SoftBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const flightColumns = (handleFlightDataOpen) => [
    // {
    //   accessor: "",
    //   Header: "Actions",
    //   Cell: (rowData) => {
    //     return (
    //       <>
    //         <CardDropdown>
    //           <div className="py-2">
    //             <Dropdown.Item
    //               as="button"
    //               onClick={() => handleFlightDataOpen(rowData.row.original)}
    //             >
    //               View Data
    //             </Dropdown.Item>
    //           </div>
    //         </CardDropdown>
    //       </>
    //     );
    //   },
    // },
    
    {
      accessor: "flight_path",
      Header: "Flight Path",
      Cell: (rowData) => {
        const { flight_path } = rowData.row.original;
        return flight_path;
      },
      headerProps: { className: "text-center" },
      cellProps: {
        className: "text-center",
      },
    },
    {
      accessor: "oneway_flights",
      Header: "One way Flights",
      Cell: (rowData) => {
        const { number_of_oneway_flights } = rowData.row.original;
        return number_of_oneway_flights;
      },
      headerProps: { className: "text-center" },
      cellProps: {
        className: "text-center",
      },
    },
    {
      accessor: "",
      Header: "Departure Date",
      Cell: (rowData) => {
        const { start_date } = rowData.row.original;
        return start_date ? getFormattedDate(start_date) : "";
      },
    },
    // {
    //   accessor: "",
    //   Header: "End Date",
    //   Cell: (rowData) => {
    //     const { end_date } = rowData.row.original;
    //     return end_date ? getFormattedDate(end_date) : "";
    //   },
    // },
    {
      accessor: "total_pax",
      Header: "Total Pax (KM)",
      Cell: (rowData) => {
        const { total_pax } = rowData.row.original;
        return total_pax ? total_pax.toFixed(2) : total_pax;
      },
    },
    {
      accessor: "flight_class",
      Header: "Class",
      Cell: (rowData) => {
        const { flight_class } = rowData.row.original;
        var flightClassNoDistance = flight_class.split(' ')[0];
        return flightClassNoDistance;
      },
    },
    {
      accessor: "status",
      Header: "Completion Status",

      headerProps: { className: "text-center" },
      cellProps: {
        className: "fs-0",
      },
      Cell: (rowData) => {
        const { status } = rowData.row.original;
        return (
          <SoftBadge pill bg={status.type} className="d-block">
            {status.content}
            <FontAwesomeIcon
              icon={status.icon}
              transform="shrink-2"
              className="ms-1"
            />
          </SoftBadge>
        );
      },
    },
  ];