import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Badge, Button, Col, Row } from "react-bootstrap";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faArrowDown,
  faCompass,
} from "@fortawesome/free-solid-svg-icons";

const AreasOfImprovementList = ({ data, index }) => {
  // console.log("In AreasOfImprovementList=>", data);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { id, title, description, priority, climate_action_question_id } = data;

  const handleExploreSolutions = async () => {
    setIsLoading(true);
    navigate("/welcome/solutions/recommended-solutions", {
      state: {
        questionId: climate_action_question_id,
        defaultKey: title,
      },
    });
    setIsLoading(false);
  };

  return (
    <>
      <Col
        xs={12}
        className={classNames("p-card", {
          "bg-100": index % 2 !== 0,
        })}
      >
        <Row>
          <Col sm={12} md={12}>
            <Row className="h-100">
              <h5 className="mt-3 mt-sm-0">{title}</h5>
              <p className="fs--1 mb-2 mb-md-3">
                <Badge
                  pill
                  bg={
                    priority === "High"
                      ? "danger"
                      : priority === "Low"
                      ? "success"
                      : "warning"
                  }
                >
                  {priority}
                  <FontAwesomeIcon
                    icon={
                      priority === "High"
                        ? faArrowUp
                        : priority === "Low"
                        ? faArrowDown
                        : null
                    }
                    transform="shrink-2"
                    className="ms-1"
                  />
                </Badge>
              </p>
              {/* {ratings && <StarRating readonly rating={ratings} />} */}
              {/* <p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: solution,
                  }}
                />
              </p> */}
              <p>{description}</p>
              {/* <p>
                <Accordion>
                  {tasks && tasks.length !== 0
                    ? tasks.map((task, index) => (
                        <Accordion.Item eventKey={task.id}>
                          <Accordion.Header>{task.title}</Accordion.Header>
                          <Accordion.Body>{task.solution}</Accordion.Body>
                        </Accordion.Item>
                        // <>
                        //   <Alert variant="danger">{task.id}</Alert>
                        //   <Alert variant="danger">{task.solution}</Alert>
                        // </>
                      ))
                    : null}
                </Accordion>
              </p> */}
              <div className="mt-2">
                <Row>
                  <Col>
                    <Button onClick={handleExploreSolutions}>
                      Explore Solutions
                      {isLoading && (
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                          style={{ marginLeft: "10px" }}
                        ></span>
                      )}
                      {!isLoading && (
                        <FontAwesomeIcon
                          icon={faCompass}
                          transform="shrink-2"
                          className="ms-1"
                        />
                      )}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Row>
          </Col>
        </Row>
      </Col>
    </>
  );
};

AreasOfImprovementList.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  index: PropTypes.number,
};

export default AreasOfImprovementList;
