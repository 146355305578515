import React, { useEffect } from "react";
import { Button, Modal, Form, Spinner, ProgressBar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faList, faStream } from "@fortawesome/free-solid-svg-icons";
import WidgetSectionTitle from "../../widgets/WidgetSectionTitle";
import { useState } from "react";
import AdvanceTableWrapper from "../common/advance-table/AdvanceTableWrapper";
import { Card } from "react-bootstrap";
import AdvanceTable from "../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../common/advance-table/AdvanceTableFooter";
import { getContentRequests, updateContentRequest } from "./adminApi";
import SoftBadge from "../common/SoftBadge";
import Flex from "../common/Flex";
import IconButton from "../common/IconButton";
import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import app from "../../firebase";
import { getFormattedDate, getFormattedTime } from "../../helpers/utils";

const UploadContent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [contentRequests, setContentRequests] = useState([]);
  const [showContentUploadModal, setShowContentUploadModal] = useState(false);
  const [businessId, setBusinessId] = useState(null);
  const [contentData, setContentData] = useState(null);

  const columns = [
    {
      accessor: "business_id",
      Header: "Business Id",
      cellProps: {
        className: "text-wrap",
      },
    },
    {
      accessor: "business_name",
      Header: "Business Name",
      cellProps: {
        className: "text-wrap",
      },
    },
    {
      accessor: "image_name",
      Header: "Name",
      headerProps: { className: "text-center" },
      cellProps: {
        className: "text-wrap",
      },
      Cell: (rowData) => {
        const { image_name, image_url, content_status } = rowData.row.original;

        // let lowerCaseDocumentType =
        //   document_type && document_type !== undefined
        //     ? document_type.toLowerCase()
        //     : "";
        return (
          <Flex alignItems="center" justifyContent="center">
            {/* <div className="file-thumbnail">
              <FileIcon
                size={5}
                extension={lowerCaseDocumentType}
                type="image"
                {...defaultStyles[lowerCaseDocumentType]}
              />
            </div> */}
            {content_status === "InProgress" ? (
              <div className="ms-3 flex-shrink-1 flex-grow-1">{image_name}</div>
            ) : null}
            {content_status === "Completed" ? (
              <div className="ms-3 flex-shrink-1 flex-grow-1">
                <a href={image_url} target="_blank">
                  {image_name}
                </a>
              </div>
            ) : null}
          </Flex>
        );
      },
    },
    {
      accessor: "content_type",
      Header: "Type",
    },
    {
      accessor: "content_status",
      Header: "Status",

      headerProps: { className: "text-center" },
      cellProps: {
        className: "fs-0",
      },
      Cell: (rowData) => {
        const { content_status } = rowData.row.original;
        return (
          <SoftBadge
            pill
            bg={content_status === "Completed" ? "success" : "warning"}
            className="d-block"
          >
            {content_status}
            <FontAwesomeIcon
              icon={content_status === "Completed" ? faCheck : faStream}
              transform="shrink-2"
              className="ms-1"
            />
          </SoftBadge>
        );
      },
    },
    {
      accessor: "",
      Header: "Created At",
      Cell: (rowData) => {
        const { created_at } = rowData.row.original;
        return created_at
          ? getFormattedDate(created_at) + " " + getFormattedTime(created_at)
          : "";
      },
    },
    {
      accessor: "",
      Header: "Actions",
      Cell: (rowData) => {
        const { id, business_id, content_type, title, description } =
          rowData.row.original;
        return (
          <>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon={faArrowUpFromBracket}
              transform="shrink-3"
              onClick={() =>
                handleOpen(id, content_type, title, description, business_id)
              }
            >
              Upload Content
            </IconButton>
          </>
        );
      },
    },
  ];

  async function getContent() {
    const response = await getContentRequests();
    setContentRequests(response);
    setIsLoading(false);
  }

  useEffect(() => {
    getContent();
  }, []);

  async function handleClose(isRefresh) {
    setShowContentUploadModal(false);
    if (isRefresh) {
      const response = await getContentRequests();
      setContentRequests(response);
    }
  }

  function handleOpen(contentId, contentType, title, description, businessId) {
    setBusinessId(businessId);
    setContentData({ contentId, contentType, title, description, businessId });
    setShowContentUploadModal(true);
  }

  return (
    <>
      <WidgetSectionTitle
        icon={faList}
        title="Content Uploader"
        subtitle="User request for content are here."
        transform="shrink-2"
        className="mb-4 mt-6"
      />
      {showContentUploadModal && (
        <ContentUploadModal
          data={contentData}
          showModal={showContentUploadModal}
          handleClose={handleClose}
        />
      )}

      {isLoading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999, // Adjust the z-index as needed
          }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className="mb-3">
          <AdvanceTableWrapper
            columns={columns}
            data={contentRequests}
            sortable
            pagination
            perPage={7}
            rowCount={contentRequests.length}
          >
            <Card>
              <Card.Header>{/* <ReportsTableHeader table /> */}</Card.Header>
              <Card.Body className="p-0">
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="btn-reveal-trigger text-nowrap align-middle"
                  tableProps={{
                    size: "lg",
                    className: "fs--1 mb-0 overflow-hidden",
                  }}
                />
              </Card.Body>
              <Card.Footer>
                <AdvanceTableFooter
                  rowCount={contentRequests.length}
                  table
                  rowInfo
                  navButtons
                />
              </Card.Footer>
            </Card>
          </AdvanceTableWrapper>
        </div>
      )}
    </>
  );
};

const ContentUploadModal = ({ showModal, handleClose, data }) => {
  const [contentType, setContentType] = useState(data.contentType);
  const [title, setTitle] = useState(data.title);
  const [description, setDescription] = useState(data.description);
  const [selectedFile, setSelectedFile] = useState(null);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(0);
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    if (!selectedFile) {
      console.log("File not selected");
      return;
    }

    const storage = getStorage(app);
    let filePath = "BannerBear/" + data.businessId + "/" + selectedFile.name;
    const storageRef = ref(storage, filePath);
    const uploadTask = uploadBytesResumable(storageRef, selectedFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (error) => console.log(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (documentUrl) => {
          await updateContentRequest({
            contentId: data.contentId,
            contentType: contentType,
            title: title,
            description: description,
            imageURL: documentUrl,
            imageName: selectedFile.name,
            fileType: selectedFile.type,
          });
          handleClose(true);
        });
      }
    );
  }

  function handleSelectFile(uploadedFile) {
    setSelectedFile(uploadedFile);
  }

  return (
    <div>
      {" "}
      <Modal
        show={showModal}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Upload content image(accept only .png, .jpeg, .gif, .svg, .pdf)
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formContentType">
              <Form.Label>Content Type</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => setContentType(e.target.value)}
              >
                <option value="">Please select</option>
                <option value="Milestone">Milestone</option>
                <option value="Other">Other</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                placeholder="Enter description"
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formFileLg" className="mb-3">
              <Form.Label></Form.Label>
              <Form.Control
                type="file"
                size="lg"
                accept="image/png, image/gif, image/jpeg, image/jpg, image/svg, image/svg+xml, application/pdf"
                onChange={(e) => handleSelectFile(e.target.files[0])}
                multiple
              />
            </Form.Group>
            <ProgressBar
              variant="warning"
              now={progress}
              label={`${progress}%`}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              disabled={!contentType || !title || !description || !selectedFile}
            >
              Upload
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default UploadContent;
