import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot } from "@fortawesome/free-solid-svg-icons";
import { exploreSolutions } from "../solutionsAPI";

const ChatButton = ({ id, defaultKey, questionId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleGenerateSolutions = async () => {
    setIsLoading(true);
    try {
      //send solutionId and questionId to backend
      // const body = { solutionId: id, questionId: questionId };
      // console.log("body", body);
      // const response = await exploreSolutions(body);
      // console.log(response);
      navigate("/welcome/solutions/chat", {
        state: {
          areasOfImprovementId: id,
          defaultKey: defaultKey,
          questionId: questionId,
          // initPrompt: response,
        },
      });
    } catch (error) {
      toast.error(error.message);
    }
    setIsLoading(false);
  };

  return (
    <div className="mt-2">
      <Row>
        <Col>
          <Button onClick={handleGenerateSolutions} style={{ width: "100%" }}>
            Generate Solutions
            {isLoading && (
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
                style={{ marginLeft: "10px" }}
              ></span>
            )}
            {!isLoading && (
              <FontAwesomeIcon
                icon={faRobot}
                transform="shrink-2"
                className="ms-1"
              />
            )}
          </Button>
          <br />
          <br />
        </Col>
      </Row>
    </div>
  );
};

export default ChatButton;
