import React, { forwardRef, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import WidgetSectionTitle from "../../../widgets/WidgetSectionTitle";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faList,
  faRefresh,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../Contexts/AuthContext";
import AdvanceTableWrapper from "../../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../../common/advance-table/AdvanceTableFooter";
import { Card } from "react-bootstrap";
import IconButton from "../../../components/common/IconButton";
import { faAdd, faRemove, faUpload } from "@fortawesome/free-solid-svg-icons";
import { find } from "lodash";
import {
  submitFlightForm,
  saveFlightData,
  getAllFlightDataDrafts,
  deleteFlightData,
  getFlightDataById,
  getFlightDataByStatus,
  getAirportDataFromExternal,
  saveFlightDataCsv,
} from "./flightAPI";
import { toast, ToastContainer } from "react-toastify";
import { getFormattedDate } from "../../../helpers/utils";
import { useLocation, useNavigate } from "react-router-dom";
import FlightFlatFileUploader from "./flightFlatFileUploader";

import {
  fetchDashboardDataFromFirebaseByBusinessId,
  removeUnifiedDateFromStore,
} from "../../../helpers/unifiedDataHelper";
import { getActiveUserData, isDataFrozen } from "../../../helpers/store";
import FrozenDataModal from "../../utilities/FrozenDataModal";

const flightClassTypes = require("./flightClassTypes.json");

const FlightForm = () => {
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const location = useLocation();
  const navigate = useNavigate();
  const [eventId, setEventId] = useState(null);
  const [showButton, setShowButton] = useState(false);
  const [flightData, setFlightData] = useState([]);
  const [flightDataInProgress, setFlightDataInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showFrozenDataModal, setShowFrozenDataModal] = useState(false);
  const [selectedDatePeriods, setSelectedDatePeriods] = useState({
    startDate: null,
    endDate: null,
  });
  const [airportData, setAirportData] = useState([]);

  const [showAddFlightDataModal, setShowAddFlightDataModal] = useState(false);

  const [showFlatFileUploader, setShowFlatFileUploader] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    const request = {
      startDate: selectedDatePeriods.startDate,
      endDate: selectedDatePeriods.endDate,
      eventId: eventId,
    };
    await submitFlightForm(request);
    toast(`Flight data submitted successfully.`);

    if (eventId) {
      setIsLoading(false);
      navigate("/welcome/dashboard/event-form", {
        state: { id: eventId },
      });
    } else {
      const flightData = await getAllFlightDataDrafts();

      setFlightData(flightData);
      if (flightData.length !== 0) {
        await checkIsSubmitted(flightData);
      } else {
        setShowButton(false);
      }
    }
    await removeUnifiedDateFromStore();
    await fetchDashboardDataFromFirebaseByBusinessId(
      activeUser.businessId,
      activeUser.currentStartDate,
      activeUser.currentEndDate
    );
    //to initiate chart data calculation
    sessionStorage.setItem("dashboardDataCalCompleted", false);

    setIsLoading(false);
  }

  async function checkIsSubmitted(flightData) {
    //check if submit_status is submitted for all flight data then hide submit button
    const isSubmitStatusSubmitted = flightData.every(
      (flightData) => flightData.submit_status === "SUBMITTED"
    );
    setShowButton(!isSubmitStatusSubmitted);
  }

  async function handleAddDraftClick() {
    setShowAddFlightDataModal(true);
  }

  async function handleAddDraftClose(isRefresh) {
    setShowAddFlightDataModal(false);
    if (isRefresh) {
      setIsLoading(true);
      let flightData = [];
      if (eventId) {
        flightData = await getFlightDataById(eventId);
      } else {
        flightData = await getAllFlightDataDrafts();
      }
      setFlightData(flightData);
      if (flightData.length !== 0) {
        await checkIsSubmitted(flightData);
      } else {
        setShowButton(false);
      }
      setIsLoading(false);
    }
  }

  async function handleDeleteFlightData(flightDataId) {
    setIsLoading(true);
    await deleteFlightData(flightDataId);
    let flightData = [];
    if (eventId) {
      flightData = await getFlightDataById(eventId);
    } else {
      flightData = await getAllFlightDataDrafts();
    }
    setFlightData(flightData);
    if (flightData.length !== 0) {
      await checkIsSubmitted(flightData);
    } else {
      setShowButton(false);
    }
    setIsLoading(false);
  }

  const columns = [
    {
      accessor: "id",
      Header: "",
      Cell: (rowData) => {
        return (
          <>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon={faRemove}
              transform="shrink-3"
              onClick={() => handleDelete(rowData.row.original.id)}
            ></IconButton>
          </>
        );
      },
    },
    {
      accessor: "",
      Header: "Departure Date",
      Cell: (rowData) => {
        const { start_date } = rowData.row.original;
        return start_date ? getFormattedDate(start_date) : "";
      },
    },
    // {
    //   accessor: "",
    //   Header: "End Date",
    //   Cell: (rowData) => {
    //     const { end_date } = rowData.row.original;
    //     return end_date ? getFormattedDate(end_date) : "";
    //   },
    // },
    {
      accessor: "departure",
      Header: "Departure",
    },
    {
      accessor: "destination",
      Header: "Destination",
    },
    {
      accessor: "flight_path",
      Header: "Flight Path",
    },
    {
      accessor: "travel_type",
      Header: "Travel Type",
    },
    {
      accessor: "number_of_oneway_flights",
      Header: "Oneway flights",
    },
    {
      accessor: "distance",
      Header: "Distance (km)",
    },
    {
      accessor: "flight_type",
      Header: "Flight Type",
    },
    {
      accessor: "flight_class",
      Header: "Class",
    },
    {
      accessor: "total_pax",
      Header: "Total pax (km)",
    },
    {
      accessor: "submit_status",
      Header: "Status",
    },
  ];

  async function getFlights() {
    setIsLoading(true);
    await getAirportData();
    var flightInProgress = await getFlightDataByStatus();
    if (flightInProgress.length > 0) {
      setFlightDataInProgress(true);
      setIsLoading(false);
      return;
    }

    setFlightDataInProgress(false);

    let data = [];
    if (location.state) {
      setEventId(location.state.id);
      data = await getFlightDataById(location.state.id);
    } else {
      data = await getAllFlightDataDrafts();
    }
    setFlightData(data);
    if (data.length !== 0) {
      await checkIsSubmitted(data);
    } else {
      setShowButton(false);
    }
    setIsLoading(false);
  }

  async function getAirportData() {
    var airportData = await getAirportDataFromExternal();
    let date = [];
    airportData.forEach((item) => {
      date.push({
        value: item.airportCode,
        label: item.country + " - " + item.city + " - " + item.airportName,
      });
    });
    setAirportData(date);
  }

  useEffect(() => {
    getFlights();
  }, []);

  const noDataMessages = {
    title: "No Flight Data Found",
    subtitle: "Consider adding flight data",
  };

  const inProgressDataMessages = {
    title:
      "Flight data upload in progress. It may take time depends on the number of flight records.",
    subtitle: "Please wait for sometime and click Refresh Data",
  };

  const handleUpload = () => {
    if (isDataFrozen()) {
      setShowFrozenDataModal(true);
      return;
    }
    setShowFlatFileUploader(true);
  };
  const handleAdd = () => {
    if (isDataFrozen()) {
      setShowFrozenDataModal(true);
      return;
    }
    handleAddDraftClick()
  };

  const handleDelete = (id) => {
    if (isDataFrozen()) {
      setShowFrozenDataModal(true);
      return;
    }
    handleDeleteFlightData(id)
  };
  return (
    <>
      {" "}
      <WidgetSectionTitle
        icon={faList}
        title="Flight Form"
        subtitle="Fill your flight form here"
        transform="shrink-2"
        className="mb-4 mt-6"
      />
      {showAddFlightDataModal && (
        <AddFlightDataModal
          showAddFlightDataModal={showAddFlightDataModal}
          handleAddDraftClose={handleAddDraftClose}
          eventId={eventId}
          airportData={airportData}
        />
      )}
      <div className="mb-3">
        <AdvanceTableWrapper
          columns={columns}
          data={flightData}
          sortable
          pagination
          perPage={10}
          rowCount={flightData.length}
        >
          <Card>
            <Card.Header>
              <Row className="flex-between-center">
                <Col
                  lg={7}
                  sm="auto"
                  className="d-flex align-items-center pe-0"
                >
                  <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                    Flights data
                  </h5>

                  <p
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: ".8rem",
                      marginTop: "1rem",
                      marginLeft: ".2rem",
                    }}
                  >
                    (If you are uploading flight data, you need to upload a CSV
                    file. You can download the template{" "}
                    <a
                      href="https://docs.google.com/spreadsheets/d/1ii0eNdydrSZD5ldqxeJdHs9JehRFiWwby2y9x5CHaHs/copy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      here <FontAwesomeIcon icon={faFileExcel} />
                    </a>
                    .)
                  </p>

                  {isLoading ? (
                    <Spinner
                      animation="border"
                      role="status"
                      size="sm"
                      style={{ marginLeft: "10px", color: "#f5803e" }}
                    >
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    <></>
                  )}
                </Col>

                <Col lg={5} className="ms-auto text-end ps-0">
                  <div id="orders-actions">
                    <IconButton
                      variant="falcon-default"
                      size="sm"
                      icon={faRefresh}
                      style={{ marginRight: "10px" }}
                    >
                      <span
                        className="d-none d-sm-inline-block ms-1"
                        onClick={() => {
                          getFlights();
                        }}
                      >
                        Refresh Data
                      </span>
                    </IconButton>
                    <IconButton
                      variant="falcon-default"
                      size="sm"
                      icon={faUpload}
                      style={{ marginRight: "10px" }}
                    >
                      <span
                        className="d-none d-sm-inline-block ms-1"
                        onClick={handleUpload}
                      >
                        Upload Flight Data CSV
                      </span>
                    </IconButton>

                    <IconButton
                      variant="falcon-default"
                      size="sm"
                      icon={faAdd}
                      style={{ marginRight: "5px" }}
                    >
                      <span
                        className="d-none d-sm-inline-block ms-1"
                        onClick={handleAdd}
                      >
                        Add Flight Data
                      </span>
                    </IconButton>
                  </div>
                </Col>

                <Col lg={2} className="ms-auto">
                  <div id="orders-actions"></div>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="btn-reveal-trigger text-nowrap align-middle"
                tableProps={{
                  size: "lg",
                  className: "fs--1 mb-0 overflow-hidden",
                }}
                noDataMessage={
                  flightDataInProgress ? inProgressDataMessages : noDataMessages
                }
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTableFooter
                rowCount={flightData.length}
                table
                rowInfo
                navButtons
              />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </div>
      <hr />
      <Row style={{ alignItems: "center", justifyContent: "left" }}>
        {showFlatFileUploader && <FlightFlatFileUploader />}
        <Col sm={4}>
          {showButton && (
            <Button onClick={handleSubmit}>
              Submit Flight data{" "}
              {isLoading && (
                <Spinner size="sm" style={{ marginRight: "10px" }}></Spinner>
              )}
            </Button>
          )}
        </Col>
      </Row>
      <ToastContainer />
      {showFrozenDataModal && (
        <FrozenDataModal
          show={showFrozenDataModal}
          onHide={() => {
            setShowFrozenDataModal(false);
          }}
        />
      )}
    </>
  );
};

const GreetingsDateInput = forwardRef(({ value, onClick }, ref) => (
  <div>
    <Form.Control
      size="lg"
      ref={ref}
      onClick={onClick}
      value={value}
      className="ps-4"
      onChange={(e) => {
        console.log({ e });
      }}
    />
    <FontAwesomeIcon
      icon={faCalendarAlt}
      className="text-primary position-absolute top-50 translate-middle-y ms-2"
    />
  </div>
));

const AddFlightDataModal = ({
  showAddFlightDataModal,
  handleAddDraftClose,
  eventId,
  airportData,
}) => {
  const [departureCode, setDepartureCode] = useState();
  const [destinationCode, setDestinationCode] = useState();
  const [numberOfOneWayFlights, setNumberOfOneWayFlights] = useState(1);
  const [className, setClassName] = useState(0);
  const [selectedDatePeriods, setSelectedDatePeriods] = useState({
    startDate: null,
    endDate: null,
  });

  const [isLoading, setIsLoading] = useState(false);

  function onDepartureChange(data) {
    setDepartureCode(data);
  }

  function onDestinationChange(data) {
    setDestinationCode(data);
  }

  function onNumberOfFLightsChange(data) {
    setNumberOfOneWayFlights(data);
  }

  function onClassChange(data) {
    setClassName(data);
  }

  const onDatePeriodChange = (departureDate) => {
    //const [start, end] = dates;
    setSelectedDatePeriods({
      startDate: departureDate,
      endDate: departureDate,
    });
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    const request = [
      {
        departureCode: departureCode.value,
        destinationCode: destinationCode.value,
        numberOfOnewayFlights: numberOfOneWayFlights,
        classType: className,
        timePeriodFrom: selectedDatePeriods.startDate.toDateString(),
        timePeriodTo: selectedDatePeriods.endDate.toDateString(),
        uploaded_by: JSON.parse(getActiveUserData()).uid,
      },
    ];
    // console.log(request)
    await saveFlightDataCsv({ data: request });
    setIsLoading(false);
    handleAddDraftClose(true);
  }

  useEffect(() => {}, []);

  return (
    <div>
      <Modal
        show={showAddFlightDataModal}
        onHide={handleAddDraftClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Flight Data
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Departure Date</Form.Label>
                  <div>
                    <DatePicker
                      selected={selectedDatePeriods.startDate}
                      dateFormat="dd/MM/yyyy"
                      onChange={onDatePeriodChange}
                      //startDate={selectedDatePeriods.startDate}
                      //endDate={selectedDatePeriods.endDate}
                      customInput={<GreetingsDateInput />}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Departure</Form.Label>
                  <Select
                    closeMenuOnSelect={true}
                    options={airportData}
                    placeholder="Please select"
                    classNamePrefix="react-select"
                    value={departureCode}
                    onChange={(value) => onDepartureChange(value)}
                  />
                </Form.Group>
              </Col>

              <Col>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Destination</Form.Label>
                  <Select
                    closeMenuOnSelect={true}
                    options={airportData}
                    placeholder="Please select"
                    classNamePrefix="react-select"
                    value={destinationCode}
                    onChange={(value) => onDestinationChange(value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Number of one-way flights</Form.Label>
                  <Form.Control
                    type="text"
                    name="numberOfOneWayFlights"
                    placeholder="Number of one-way flights"
                    value={numberOfOneWayFlights}
                    onChange={(e) => onNumberOfFLightsChange(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Class</Form.Label>
                  <Form.Select
                    aria-label="Default select"
                    value={className}
                    onChange={(e) => onClassChange(e.target.value)}
                  >
                    <option value="">Please select</option>
                    {flightClassTypes.map((data, index) => {
                      return (
                        <option key={index} value={data.name}>
                          {data.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            {isLoading ? (
              <Spinner
                animation="border"
                role="status"
                size="sm"
                style={{ color: "#f5803e" }}
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <></>
            )}
            <Button
              type="submit"
              disabled={
                !departureCode ||
                !destinationCode ||
                !numberOfOneWayFlights ||
                !className ||
                !selectedDatePeriods?.startDate ||
                !selectedDatePeriods?.endDate
              }
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default FlightForm;
