import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  FloatingLabel,
  Table,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { getAuthorizationHeaderWithContentType } from "../../helpers/utils";
import {
  countryStateDataJSON,
  countryCodeList,
} from "../../data/CountryStateData";
import { useNavigate } from "react-router-dom";

const AddLocation = () => {
  const navigate = useNavigate();

  const [values, setValues] = useState({
    locationName: "",
    country: "",
    countryCode: "",
    state: "",
    stateCode: "",
    streetAddress: "",
    city: "",
    postcode: "",
  });

  const [locationData, setLocationData] = useState([]);
  const [countryData, setCountryData] = useState(countryCodeList());
  const [stateData, setStateData] = useState([]);
  const [showState, setShowState] = useState(false);
  const [countryStateJSON, setCountryStateJSON] = useState(
    countryStateDataJSON()
  );
  const [business, setBusiness] = useState(
    sessionStorage.getItem("businessId")
  );

  const handleChange = (event) => {
    if (event.target.name === "locationName") {
      if (
        event?.nativeEvent?.data !== "," &&
        !event?.target?.value?.includes(",")
      ) {
        setValues((values) => ({
          ...values,
          [event.target.name]: event.target.value,
        }));
      }
    } else if (event.target.name === "country") {
      console.log("country value", event.target.value.split(",").at(-1));
      setStateData(countryStateJSON[event.target.value.split(",")[0]]);
      setShowState(true);

      setValues((values) => ({
        ...values,
        ["countryCode"]: event.target.value.split(",").at(-1),
      }));
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.value.split(",")[0],
      }));
    } else if (event.target.name === "state") {
      console.log("state value", event.target.value.split(",").at(-1));

      setValues((values) => ({
        ...values,
        ["stateCode"]: event.target.value.split(",").at(-1),
      }));
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.value.split(",")[0],
      }));
    } else {
      setValues((values) => ({
        ...values,
        [event.target.name]: event.target.value,
      }));
    }
  };

  const handleSubmit = (event) => {
    if (event) event.preventDefault();

    console.log("final value", values);
    setLocationData([...locationData, values]);

    setValues({
      locationName: "",
      country: "",
      countryCode: "",
      state: "",
      stateCode: "",
      streetAddress: "",
      city: "",
      postcode: "",
    });
    setStateData([]);
    setShowState(false);
  };

  const renderLocation = (locationData, index) => {
    return (
      <tr key={index}>
        <td>{locationData.locationName}</td>
        <td>{locationData.country}</td>
        <td>{locationData.state}</td>
        <td>{locationData.streetAddress}</td>
        <td>{locationData.city}</td>
        <td>{locationData.postcode}</td>
      </tr>
    );
  };

  async function handleSave(event) {
    if (event) event.preventDefault();

    console.log("Location", locationData);

    const response = await fetch(`/api/users/business-add-locations`, {
      method: "POST",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify({ locationData }),
      mode: "cors",
    });

    if (response.status === 200) {
      toast(`Successfully added new location`);
      if (
        sessionStorage.getItem("FileAddLocation") !== undefined &&
        sessionStorage.getItem("FileAddLocation") === "true"
      ) {
        navigate("/welcome/data/file-uploader");
        //window.location.href = "/welcome/dashboard/file-uploader";
      } else {
        navigate("/welcome/account");
        //window.location.href = "/welcome/account";
      }
    } else {
      toast(response.message || "Error Occurred");
    }
  }

  return (
    <>
      <Card>
        <Card.Body className="py-4 px-4">
          <h3 className="mb-0">Add A Location</h3>
          <p className="mt-2 mb-4">
            Adding a location will ensure that we can attribute the correct
            emission factors for your business. Locations can be linked to
            offices, co-working spaces, or households if you are fully remote.
          </p>

          {locationData.length > 0 && (
            <>
              <Table
                responsive
                striped
                bordered
                hover
                className="table locationTable"
              >
                <thead className="table-light bg-200">
                  <tr>
                    <th scope="col">Location Name</th>
                    <th scope="col">Country</th>
                    <th scope="col">State</th>
                    <th scope="col">Street Address</th>
                    <th scope="col">City</th>
                    <th scope="col">Postcode</th>
                  </tr>
                </thead>

                <tbody>{locationData.map(renderLocation)}</tbody>
              </Table>
              <Row className="mb-3">
                <Col></Col>
              </Row>
            </>
          )}

          {/* Form */}
          <Row className="justify-content locationForm">
            <Col md={7}>
              <Form as={Row} className="g-2">
                <Col sm>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Location Name"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      name="locationName"
                      placeholder="Location Name"
                      value={values.locationName}
                      onChange={handleChange}
                      required
                    />
                  </FloatingLabel>

                  <FloatingLabel
                    controlId="floatingSelect"
                    label="Country"
                    className="mb-3"
                  >
                    <Form.Select
                      name="country"
                      value={values.country + "," + values.countryCode}
                      onChange={handleChange}
                      required
                    >
                      <option>Select the country</option>
                      {countryData.map((data, index) => {
                        return (
                          <option
                            key={index}
                            value={data.name + "," + data.code}
                          >
                            {data.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </FloatingLabel>

                  {showState ? (
                    <FloatingLabel
                      controlId="floatingSelect"
                      label="State"
                      className="mb-3"
                    >
                      <Form.Select
                        name="state"
                        onChange={handleChange}
                        required
                      >
                        <option>Select the state</option>
                        {stateData.map((data, index) => {
                          return (
                            <option
                              key={index}
                              value={data.name + "," + data.code}
                            >
                              {data.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </FloatingLabel>
                  ) : null}

                  <FloatingLabel
                    controlId="floatingInput"
                    label="Street address"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      name="streetAddress"
                      placeholder="Street address"
                      value={values.streetAddress}
                      onChange={handleChange}
                      required
                    />
                  </FloatingLabel>

                  <FloatingLabel
                    controlId="floatingInput"
                    label="City"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      name="city"
                      placeholder="City"
                      value={values.city}
                      onChange={handleChange}
                      required
                    />
                  </FloatingLabel>

                  <FloatingLabel
                    controlId="floatingPassword"
                    label="Postcode"
                    className="mb-3"
                  >
                    <Form.Control
                      type="number"
                      name="postcode"
                      placeholder="Postcode"
                      value={values.postcode}
                      onChange={handleChange}
                      required
                    />
                  </FloatingLabel>

                  <Row>
                    <Col sm className="mt-3">
                      <Button
                        variant="primary"
                        className="d-block w-100"
                        type="submit"
                        onClick={() => handleSubmit()}
                        disabled={
                          !values.city ||
                          !values.postcode ||
                          !values.locationName ||
                          !values.country ||
                          !values.state ||
                          !values.streetAddress
                        }
                      >
                        Add Location
                      </Button>
                    </Col>
                    {locationData.length > 0 && (
                      <Col sm className="mt-3">
                        <Button
                          variant="primary"
                          className="d-block w-100"
                          type="submit"
                          onClick={() => handleSave()}
                          disabled={!(locationData.length > 0)}
                        >
                          Save & Continue
                        </Button>
                      </Col>
                    )}
                    <ToastContainer />
                  </Row>
                </Col>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default AddLocation;
