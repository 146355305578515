import React, { useEffect, useState } from "react";
import { useAuth } from "../../../Contexts/AuthContext";
import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
import PropTypes from "prop-types";
import { Card, Nav, Row, Tab } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import {
  getAllAreasOfImprovementByQuestion,
  getAllRecommendedSolutionsByQuestionId,
} from "../solutionsAPI";
import RecommendedSolutionCard from "./RecommendedSolutionCard";
import Flex from "../../common/Flex";
import ChatButton from "./ChatButton";

const NavItem = ({ item }) => {
  return (
    <Nav.Item as="li">
      <Nav.Link
        className="mb-0 py-3 cursor-pointer"
        eventKey={item.toLowerCase()}
      >
        {item}
      </Nav.Link>
    </Nav.Item>
  );
};

const Index = () => {
  //log firebase analytics
  const { currentUser } = useAuth();
  useEffect(() => {
    const analytics = getAnalytics();
    setUserId(analytics, currentUser.uid);
    setUserProperties(analytics, { businessId: currentUser.businessId });
    logEvent(analytics, "page_view", {
      page_title: "Recommended Solutions",
    });
  }, [currentUser]);

  const location = useLocation();
  console.log(location);

  const [aoiObject, setAoiObject] = useState();
  const [questionId, setQuestionId] = useState(); //questionId is the id of the question that the user has selected
  const [defaultKey, setDefaultKey] = useState(); //defaultKey is the key of the first tab to be displayed
  const [recommendedSolutions, setRecommendedSolutions] = useState({
    all: [],
    "moving people": [],
    "moving goods": [],
    "office operations": [],
    "site operations and equipment": [],
    "designing products": [],
  });

  async function getRecommendedSolutions(questionId, aoiObject) {
    //fetch all recommended solutions by questionId
    const allRecommendedSolutions =
      await getAllRecommendedSolutionsByQuestionId(questionId);
    setRecommendedSolutions((prevState) => ({
      ...prevState,
      all: allRecommendedSolutions,
    }));

    //filter recommended solutions by area of improvement
    // console.log("Entering for loop =>", aoiObject);
    for (let aoi in aoiObject) {
      const recommendedSolutionsByAOIId = await allRecommendedSolutions.filter(
        (solution) => solution.parent_id === aoiObject[aoi]
      );
      // console.log(recommendedSolutionsByAOIId);
      setRecommendedSolutions((prevState) => ({
        ...prevState,
        [aoi.toLowerCase()]: recommendedSolutionsByAOIId,
      }));
    }
  }

  async function get() {
    const questionId = location.state?.questionId || 48;

    //fetch all areas of improvement by questionId
    const areasOfImprovement = await getAllAreasOfImprovementByQuestion(
      questionId
    );
    // console.log("areasOfImprovement", areasOfImprovement);

    //fetch id of all areas of improvement
    const aoiObject = {};
    areasOfImprovement.forEach((aoi) => {
      //convert title from "Moving people" to "Moving People"
      const splitTitle = aoi.title.split(" ");
      let title = splitTitle[0];
      for (let i = 1; i < splitTitle.length; i++) {
        title +=
          " " + splitTitle[i].charAt(0).toUpperCase() + splitTitle[i].slice(1);
      }
      aoiObject[title] = aoi.id;
    });
    setAoiObject(aoiObject);
    setQuestionId(questionId);
    setDefaultKey(location.state?.defaultKey);

    await getRecommendedSolutions(questionId, aoiObject);
  }

  useEffect(() => {
    get();
  }, []);

  const [navItems] = useState([
    // "All",
    "Moving People",
    "Moving Goods",
    "Office Operations",
    "Site Operations and Equipment",
    "Designing Products",
  ]);

  // console.log("recommendedSolutions=>", recommendedSolutions);
  // console.log("defaultKey=>", defaultKey);
  // console.log("aoiObject=>", aoiObject);

  return (
    <Card className="mt-3">
      {defaultKey && (
        <Tab.Container
          id="audience-tab"
          defaultActiveKey={defaultKey.toLowerCase()}
        >
          <Card.Header
            as={Flex}
            justifyContent="between"
            alignItems="center"
            className="ps-0 py-0 border-bottom"
          >
            <Nav
              as="ul"
              className="nav-tabs border-0 flex-nowrap chart-tab tab-active-caret"
            >
              {navItems.map((item) => (
                <NavItem item={item} key={item} />
              ))}
            </Nav>
          </Card.Header>

          <Card.Body>
            <Row>
              <Tab.Content>
                {/* <Tab.Pane unmountOnExit eventKey="all">
                  {recommendedSolutions.all.length !== 0 && (
                    <RecommendedSolutionCard
                      data={recommendedSolutions.all}
                      aoiObject={aoiObject}
                    />
                  )}
                </Tab.Pane> */}
                <Tab.Pane unmountOnExit eventKey="moving people">
                  <ChatButton
                    id={aoiObject["Moving People"]}
                    defaultKey="Moving People"
                    questionId={questionId}
                  />
                  <RecommendedSolutionCard
                    data={recommendedSolutions["moving people"]}
                    aoiObject={aoiObject}
                  />
                </Tab.Pane>
                <Tab.Pane
                  unmountOnExit
                  eventKey="moving goods"
                  aoiObject={aoiObject}
                >
                  <ChatButton
                    id={aoiObject["Moving Goods"]}
                    defaultKey="Moving Goods"
                    questionId={questionId}
                  />
                  <RecommendedSolutionCard
                    data={recommendedSolutions["moving goods"]}
                    aoiObject={aoiObject}
                  />
                </Tab.Pane>
                <Tab.Pane unmountOnExit eventKey="office operations">
                  <ChatButton
                    id={aoiObject["Office Operations"]}
                    defaultKey="Office Operations"
                    questionId={questionId}
                  />
                  <RecommendedSolutionCard
                    data={recommendedSolutions["office operations"]}
                    aoiObject={aoiObject}
                  />
                </Tab.Pane>
                <Tab.Pane
                  unmountOnExit
                  eventKey="site operations and equipment"
                >
                  <ChatButton
                    id={aoiObject["Site Operations and Equipment"]}
                    defaultKey="Site Operations and Equipment"
                    questionId={questionId}
                  />
                  <RecommendedSolutionCard
                    data={recommendedSolutions["site operations and equipment"]}
                    aoiObject={aoiObject}
                  />
                </Tab.Pane>
                <Tab.Pane unmountOnExit eventKey="designing products">
                  <ChatButton
                    id={aoiObject["Designing Products"]}
                    defaultKey="Designing Products"
                    questionId={questionId}
                  />
                  <RecommendedSolutionCard
                    data={recommendedSolutions["designing products"]}
                    aoiObject={aoiObject}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Row>
          </Card.Body>
        </Tab.Container>
      )}
    </Card>
  );
};

NavItem.propTypes = {
  item: PropTypes.string.isRequired,
};

export default Index;
