import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Table,
  Tooltip,
  OverlayTrigger,
  Button,
  FormControl,
  Form,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  updateDoc,
  addDoc,
  where,
} from "firebase/firestore";
import {
  formatDateToYYYYMM01,
  formatDateToYYYYMMDD,
} from "../../helpers/utils";
import {
  fetchNetnadaFactorsFromMongoDBOpenSearch,
  groupUnifiedData,
} from "../../helpers/suppliersHelper";
import { getActiveUserData, isDataFrozen } from "../../helpers/store";
import app from "../../firebase";

import {
  setGroupedUnifiedDataForDateAndContact,
  updateGroupedUnifiedData,
} from "../../helpers/groupedUnifiedDataHelper";
import {
  fetchDashboardDataFromFirebaseByBusinessId,
  removeUnifiedDateFromStore,
} from "../../helpers/unifiedDataHelper";
import { toast } from "react-toastify";
import FrozenDataModal from "../utilities/FrozenDataModal";

const fireStoreDB = getFirestore(app);

const EmissionDataItem = ({ data, handleCloseCalculationsModal }) => {
  const defaultEmissionFactorData = {
    unit: "[unit]",
    co2e_unit: "[co2e unit]",
    category_co2e_total: "[Emission Factor Name]",
    activity_name: "[Emission Factor Name]",
    source: "[source name]",
  };

  const emissionFactorData =
    data.emissionFactorData ?? defaultEmissionFactorData;

  // What is this used for???
  const [emissionFactor] = useState(() => {
    if (data.categoryMatchingSource !== "MANUAL") {
      if (data.dataSource !== "Bill") {
        return "average emission factor";
      } else {
        return emissionFactorData.co2e_total;
      }
    }
    return data.secondaryEmissionFactor;
  });

  const [editMode, setEditMode] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const [factors, setFactors] = useState([]);
  const activeUser = JSON.parse(getActiveUserData());
  const [loading, setLoading] = useState(false);
  const [selectedDates, setSelectedDates] = useState({
    startDate: new Date(activeUser.currentStartDate),
    endDate: new Date(activeUser.currentEndDate),
  });
  const [unifiedDataCalCompleted] = useState(
    JSON.parse(sessionStorage.getItem("unifiedDataCalCompleted"))
  );
  const [showFrozenDataModal, setShowFrozenDataModal] = useState(false);
  useEffect(() => {
    fetchCustomEmissionFactors();
  }, []);

  const fetchCustomEmissionFactors = async () => {
    const factors = await fetchNetnadaFactorsFromMongoDBOpenSearch({
      sourceDataset: "CUSTOM",
      source: activeUser.businessId,
    });

    if (factors && factors.length > 0) {
      setFactors(factors);
    }
  };

  const selectedFactorName = watch("emissionFactorName");
  const inputActivity = watch("inputActivity");
  const selectedFactor = factors.find(
    (factor) => factor.activity_name === selectedFactorName
  );
  const selectedFactorCo2eTotal = selectedFactor
    ? selectedFactor.co2e_total
    : "";
  const selectedFactorCo2eUnit = selectedFactor
    ? selectedFactor.co2e_unit
    : "0";
  const selectedFactorUnit = selectedFactor ? selectedFactor.unit : "0";
  // const selectedFactorSource = selectedFactor ? selectedFactor.source : "";
  const selectedFactorSource = selectedFactor
    ? selectedFactor.source_dataset === "CUSTOM"
      ? selectedFactor.source_dataset
      : selectedFactor.source
    : "";

  const averageEmissionFactorBySector = selectedFactor
    ? selectedFactor.category_co2e_total
    : emissionFactorData.category_co2e_total;

  const transactionEmissions =
    inputActivity && selectedFactorCo2eTotal
      ? (
          (parseFloat(inputActivity) * parseFloat(selectedFactorCo2eTotal)) /
          1000
        ).toFixed(2)
      : 0;

  async function updateChartData() {
    await removeUnifiedDateFromStore();
    await fetchDashboardDataFromFirebaseByBusinessId(
      activeUser.businessId,
      selectedDates.startDate,
      selectedDates.endDate
    );

    //to initiate chart data calculation
    sessionStorage.setItem("dashboardDataCalCompleted", false);

    toast.success("Data updated successfully");
  }

  const onSubmit = async (formData) => {
    // create a new dataline in unified data, with the new data
    // set the current one to excludeds
    setLoading(true);

    // console.log("Submitted data: ", formData);
    setEditMode(false);

    // console.log("data", data); // Handle the form data here (e.g., update state or make an API call)

    // console.log("activeUser", activeUser);

    const startDateString = `${formatDateToYYYYMMDD(
      new Date(activeUser.currentStartDate)
    )}`;
    const endDateString = `${formatDateToYYYYMMDD(
      new Date(activeUser.currentEndDate)
    )}`;

    const date = `${formatDateToYYYYMMDD(new Date(data.date))}T00:00:00.000Z`;

    const newDataline = {
      accountName: data.accountName,
      accountType: data.accountType,
      categoryMatchingSource: "MANUAL",
      consumption: data.consumption,
      contactId: data.contactId,
      contactName: data.contactName,
      cost: data.cost,
      dataSource: data.dataSource,
      date: date,
      description: "",
      emissionFactorId: selectedFactor ? selectedFactor._id : "",
      emissions: parseFloat(transactionEmissions),
      inclusionStatus: "INCLUDED",
      mainId: data.mainId,
      officeId: data.officeId,
      primaryCategory: selectedFactor ? selectedFactor.category_name : "",
      primaryEmissionFactor: selectedFactor
        ? selectedFactor.category_co2e_total
        : 0,
      reason: "",
      scope: data.scope,
      score: 1,
      secondaryCategory: selectedFactor ? selectedFactor.activity_name : "",
      secondaryEmissionFactor: selectedFactor ? selectedFactor.co2e_total : 0,
      subId: data.subId,
      subTotal: formData.inputActivity,
    };

    // console.log("newDataline", newDataline);

    // save this new dataline to the unified data collection
    // set the current dataline to excluded

    try {
      const qry = query(
        collection(
          fireStoreDB,
          "UnifiedData",
          activeUser.businessId,
          "DataLines"
        )
      );
      const snapshot = await getDocs(qry);
      snapshot.forEach((doc) => {
        if (doc.id === data.id) {
          updateDoc(doc.ref, { inclusionStatus: "EXCLUDED" });
        }
      });

      await addDoc(
        collection(
          fireStoreDB,
          "UnifiedData",
          activeUser.businessId,
          "DataLines"
        ),
        newDataline
      );

      //fetch the updated unified data
      let unifiedDataList = [];

      const updatedUnifiedDataQuery = query(
        collection(
          fireStoreDB,
          "UnifiedData",
          activeUser.businessId,
          "DataLines"
        ),
        where("contactName", "==", data.contactName),
        where("inclusionStatus", "==", "INCLUDED"),
        where("date", ">=", startDateString),
        where("date", "<=", endDateString)
      );
      const updatedUnifiedDataQuerySnapshot = await getDocs(
        updatedUnifiedDataQuery
      );

      for (const doc of updatedUnifiedDataQuerySnapshot.docs) {
        const data = doc.data();
        unifiedDataList.push(data);
      }
      // console.log("unifiedDataList=>", unifiedDataList);

      //group the updated unified data
      const groupedData = await groupUnifiedData(unifiedDataList);
      // console.log("groupedData=>", groupedData);

      //update the GroupedUnifiedData collection
      // await updateGroupedUnifiedData(groupedData);
      await setGroupedUnifiedDataForDateAndContact(groupedData);

      if (unifiedDataCalCompleted) {
        // console.log("unifiedDataCalCompleted ", unifiedDataCalCompleted);
        // console.log("triggering updateChartData");
        await updateChartData();
        // console.log("triggered updateChartData");
      }
    } catch (e) {
      console.error("Error adding document: ", e);
    }

    // handleClose();
    setLoading(false);
    reset(); // reset the form after submission
    handleCloseCalculationsModal(true);
  };

  let decimalPlaces = 2;

  if (data.emissions < 0.1) {
    if (parseFloat(data.emissions.toFixed(2)) === 0) {
      let emissionString = data.emissions.toString();
      let afterDecimalPoint = emissionString.split(".")[1];
      const firstNonZeroIndex = afterDecimalPoint.search(/[1-9]/);
      const decimalCount =
        firstNonZeroIndex === -1 ? afterDecimalPoint.length : firstNonZeroIndex;
      decimalPlaces = decimalCount + 1;
    }
  }

  return (
    <>
      {" "}
      {showFrozenDataModal && (
        <FrozenDataModal
          show={showFrozenDataModal}
          onHide={() => {
            setShowFrozenDataModal(false);
          }}
        />
      )}
      <Table
        style={{
          border: "1px solid #E0E0E0",
          borderRadius: "4px",
          width: "80%",
          tableLayout: "fixed",
        }}
      >
        <tbody>
          <tr>
            <th scope="row">
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    The amount and unit of the input activity that was used to
                    calculate the emissions
                  </Tooltip>
                }
              >
                <strong>Input activity:</strong>
              </OverlayTrigger>
            </th>
            <td>
              {data.dataSource === "Bill"
                ? parseFloat(data.consumption).toFixed(2)
                : parseFloat(data.subTotal).toFixed(2)}{" "}
              {emissionFactorData.unit}
            </td>
            {editMode && (
              <td>
                <FormControl
                  type="number"
                  {...register("inputActivity", {
                    required: "Input Activity is required",
                    valueAsNumber: true,
                  })}
                  placeholder="Edit Input Activity"
                  isInvalid={!!errors.inputActivity}
                />
                {errors.inputActivity && (
                  <FormControl.Feedback type="invalid">
                    {errors.inputActivity.message}
                  </FormControl.Feedback>
                )}
              </td>
            )}
          </tr>
          <tr>
            <th scope="row">
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    The name of the emission factor used in the calculation
                  </Tooltip>
                }
              >
                <strong>Emission factor name:</strong>
              </OverlayTrigger>
            </th>
            <td>
              {emissionFactorData.activity_name || "[Emission Factor Name]"}
            </td>
            {editMode && (
              <td>
                <Form.Select
                  {...register("emissionFactorName", { required: true })}
                  isInvalid={!!errors.emissionFactorName}
                >
                  <option value="">Select Emission Factor</option>
                  {factors.map((factor) => (
                    <option key={factor._id} value={factor.activity_name}>
                      {factor.activity_name}
                    </option>
                  ))}
                </Form.Select>
                {errors.emissionFactorName && (
                  <FormControl.Feedback type="invalid">
                    Emission factor name is required
                  </FormControl.Feedback>
                )}
              </td>
            )}
          </tr>
          <tr>
            <th scope="row">
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>The formula used to calculate the emissions</Tooltip>
                }
              >
                <strong>Emission factor:</strong>
              </OverlayTrigger>
            </th>
            {/* <td>{`${emissionFactor} kg CO₂e/${emissionFactorData.unit}`}</td> // What is this for???*/}
            <td>{`${emissionFactorData.co2e_total} kg CO₂e/${emissionFactorData.unit}`}</td>
            {editMode && (
              <td>
                <FormControl
                  type="text"
                  value={`${selectedFactorCo2eTotal} ${selectedFactorCo2eUnit}/${selectedFactorUnit}`}
                  readOnly
                />
              </td>
            )}
          </tr>
          <tr>
            <th scope="row">
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    The average emission factor for all emission factors in this
                    sector
                  </Tooltip>
                }
              >
                <strong>Average emission factor by sector:</strong>
              </OverlayTrigger>
            </th>
            <td>
              {emissionFactorData.category_co2e_total ||
                "[Emission Factor Name]"}{" "}
              kg CO₂e
            </td>
            {editMode && (
              <td>
                <FormControl
                  type="text"
                  value={`${averageEmissionFactorBySector} kg CO₂e`}
                  readOnly
                />
              </td>
            )}
          </tr>
          <tr>
            <th scope="row">
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    The dataset from which the emission factor originates
                  </Tooltip>
                }
              >
                <strong>Source:</strong>
              </OverlayTrigger>
            </th>
            <td>
              {emissionFactorData.source_dataset === "CUSTOM"
                ? emissionFactorData.source_dataset
                : emissionFactorData.source || "[source name]"}
            </td>
            {editMode && (
              <td>
                <FormControl
                  type="text"
                  value={selectedFactorSource}
                  readOnly
                />
              </td>
            )}
          </tr>
          <tr>
            <th scope="row">
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    The calculated emissions for this transaction
                  </Tooltip>
                }
              >
                <strong>Transaction emissions:</strong>
              </OverlayTrigger>
            </th>
            <td>{parseFloat(data.emissions).toFixed(decimalPlaces)} tCO₂e</td>
            {editMode && (
              <td>
                <FormControl
                  type="text"
                  value={`${transactionEmissions} tCO₂e`}
                  readOnly
                />
              </td>
            )}
          </tr>
          <tr>
            <th scope="row">
              <OverlayTrigger placement="bottom" overlay={"Date"}>
                <strong>Date:</strong>
              </OverlayTrigger>
            </th>
            <td>
              {data.date ? formatDateToYYYYMM01(new Date(data.date)) : ""}
            </td>
            {editMode && (
              <td>
                <FormControl
                  type="text"
                  value={
                    data.date ? formatDateToYYYYMM01(new Date(data.date)) : ""
                  }
                  readOnly
                />
              </td>
            )}
          </tr>
          <tr>
            <th scope="row">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Description</Tooltip>}
              >
                <strong>Description:</strong>
              </OverlayTrigger>
            </th>
            <td>{data.description}</td>
            {editMode && (
              <td>
                <FormControl type="text" value={data.description} readOnly />
              </td>
            )}
          </tr>
        </tbody>
      </Table>
      <Row>
        <Col>
          {!loading ? (
            <>
              <Button
                onClick={() => {
                  if (isDataFrozen()) {
                    setShowFrozenDataModal(true);
                    return;
                  }
                  if (editMode) {
                    reset();
                  }
                  setEditMode(!editMode);
                }}
              >
                {editMode ? "Cancel" : "Edit"}
              </Button>
              {editMode && (
                <Button
                  onClick={handleSubmit(onSubmit)}
                  style={{ marginLeft: "10px" }}
                >
                  Save
                </Button>
              )}
            </>
          ) : (
            <Spinner animation="border" />
          )}
        </Col>
      </Row>
    </>
  );
};

export default EmissionDataItem;
