import React, { useState } from "react";
import { FileIcon, defaultStyles } from "react-file-icon";
import DocumentsModal from "./DocumentsModal";

function FileInput({ field, fileData, assessmentData, onAddFile, supplierId }) {
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [file, setFile] = useState(fileData);
  const handleOpenDocumentModal = () => {
    setShowDocumentModal(true);
  };
  const handleSetFile = (fileSelected) => {
    setFile(fileSelected);
  };
  const text = file ? "Modify File" : "Add File";
  return (
    <>
      <div
        style={{
          border: "1px solid #ced4da",
          borderRadius: 3,
          display: "flex",
          width: "400px",
          height: "80px",
          cursor: "pointer",
        }}
      >
        <div
          style={{
            backgroundColor: "#2d3e50",
            color: "white",
            padding: "8px",
            borderRight: "1px solid #ced4da",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={handleOpenDocumentModal}
        >
          {text}
        </div>
        <div style={{ padding: "15px", flex: 1 }}>
          {file && (
            <a
              href={file?.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                color: "inherit",
                width: "100%",
              }}
            >
              <div className="file-thumbnail">
                <FileIcon
                  size={20}
                  extension={file?.fileType.toLowerCase() || ""}
                  {...defaultStyles[file?.fileType.toLowerCase() || ""]}
                />
              </div>
              <div style={{ fontSize: "14px", marginLeft: "10px" }}>
                {file.fileName}
              </div>
            </a>
          )}
        </div>
      </div>
      {showDocumentModal && (
        <DocumentsModal
          assessmentData={assessmentData}
          showModal={showDocumentModal}
          closeModal={() => setShowDocumentModal(false)}
          onAddFile={onAddFile}
          questionId={field.id}
          supplierId={supplierId}
          onUpdateSelecteFile={handleSetFile}
        />
      )}
    </>
  );
}

export default FileInput;
