import React from "react";
import { Modal, Button } from "react-bootstrap";
import { getActiveUserData } from "../../helpers/store";

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const FrozenDataModal = ({ show, onHide }) => {
  const activeUser = JSON.parse(getActiveUserData());
  const yearType = capitalizeFirstLetter(
    activeUser.selectedMeasurementYearType
  );

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          Data Locked for {activeUser.selectedMeasurementYear} {yearType} Period
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          The data for the selected period is locked. To enter, delete or modify any
          data, please contact our team.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onHide}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FrozenDataModal;
