import React, { useContext, useState } from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { ProjectContext } from "../../context/Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";

const TotalEmissionsController = () => {
  const { projectsDispatch } = useContext(ProjectContext);
  // New state to hold the user's emissions input
  const [userEmissions, setUserEmissions] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  // New state to determine if the input is in edit mode
  const [editMode, setEditMode] = useState(true);

  const validateNumber = (event) => {
    const { value } = event.target;
    // Allow float numbers with or without a leading minus sign
    const isNumber = /^-?\d*\.?\d*$/.test(value);

    if (isNumber || value === "") {
      setUserEmissions(value);
    } else {
      toast.error("Please enter a valid number");
      setUserEmissions("");
    }
  };

  // Function to handle change in the emissions input and dispatch the updated total
  const handleEmissionsChange = () => {
    setIsSaving(true);
    // Convert to number before dispatching, use parseFloat for decimal support
    const emissionsValue = parseFloat(userEmissions) || 0;
    projectsDispatch({
      type: "SET_TOTAL_EMISSIONS",
      payload: emissionsValue,
    });
    setIsSaving(false);
    toast.success("Emissions updated successfully");
  };

  // Toggle between edit and save mode
  const toggleEditSave = () => {
    if (editMode) {
      handleEmissionsChange(); // Save the emissions when switching from edit to save mode
    }
    setEditMode(!editMode); // Toggle the edit mode state
  };

  return (
    <>
      <InputGroup>
        {userEmissions === 0 && (
          <InputGroup.Text>
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className="text-danger"
            />
          </InputGroup.Text>
        )}
        <FormControl
          placeholder="Enter your total emissions"
          value={userEmissions}
          onChange={(e) => validateNumber(e)}
          disabled={!editMode} // Disable when not in edit mode
        />
        <InputGroup.Text>tonnes/CO₂e</InputGroup.Text>
        <Button
          variant="outline-primary"
          onClick={toggleEditSave}
          disabled={
            isSaving ||
            (editMode && (userEmissions === 0 || userEmissions === ""))
          }
        >
          {
            isSaving ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : editMode ? (
              "Save"
            ) : (
              "Edit"
            ) // Change button text based on edit mode
          }
        </Button>
      </InputGroup>
      <ToastContainer />
    </>
  );
};

export default TotalEmissionsController;
