import { find } from "lodash";

const localTransportMode = require("./LocalTransportMode.json");
const localTransportAverageCommutingDistance = require("./LocalTransportAverageCommutingDistance.json");
let daysPerYear = 0;
let sydneyStaffCount = 0;
let melbourneStaffCount = 0;
let brisbaneStaffCount = 0;
let adelaideStaffCount = 0;
let perthStaffCount = 0;
let hobartStaffCount = 0;
let darwinStaffCount = 0;
let canberraStaffCount = 0;

function calculateIndividualKM(staffCount, city, travelMethod) {
  let totalValue = 0;
  const transportationMode = find(localTransportMode, function (item) {
    if (item.city === city) {
      return item;
    }
  });

  const commutingDistance = find(
    localTransportAverageCommutingDistance,
    function (item) {
      if (item.city === city) {
        return item;
      }
    }
  );

  const mode = find(transportationMode.methods, function (item) {
    if (item.name === travelMethod) {
      return item;
    }
  });

  const distance = find(commutingDistance.methods, function (item) {
    if (item.name === travelMethod) {
      return item;
    }
  });

  const calc1 = mode.value * staffCount;
  const calc2 = calc1 / 100;
  const calc3 = calc2 * distance.value;
  const calc4 = calc3.toFixed(2) * 2;
  const calc5 = calc4.toFixed(2) * daysPerYear;

  // totalValue =
  //   ((mode.value * staffCount) / 100) * distance.value * 2 * daysPerYear;

  totalValue = calc5;
  if (calc5 > 0) totalValue = Number(calc5.toFixed(2));
  return totalValue;
}

function calculateTotalBicycleKM() {
  let totalBicycleKMValue = 0;
  let bicycleKMValue = calculateIndividualKM(
    sydneyStaffCount,
    "Sydney",
    "Bicycle"
  );
  totalBicycleKMValue = totalBicycleKMValue + bicycleKMValue;
  bicycleKMValue = calculateIndividualKM(
    melbourneStaffCount,
    "Melbourne",
    "Bicycle"
  );
  totalBicycleKMValue = totalBicycleKMValue + bicycleKMValue;
  bicycleKMValue = calculateIndividualKM(
    brisbaneStaffCount,
    "Brisbane",
    "Bicycle"
  );
  totalBicycleKMValue = totalBicycleKMValue + bicycleKMValue;
  bicycleKMValue = calculateIndividualKM(
    adelaideStaffCount,
    "Adelaide",
    "Bicycle"
  );
  totalBicycleKMValue = totalBicycleKMValue + bicycleKMValue;
  bicycleKMValue = calculateIndividualKM(perthStaffCount, "Perth", "Bicycle");
  totalBicycleKMValue = totalBicycleKMValue + bicycleKMValue;
  bicycleKMValue = calculateIndividualKM(hobartStaffCount, "Hobart", "Bicycle");
  totalBicycleKMValue = totalBicycleKMValue + bicycleKMValue;
  bicycleKMValue = calculateIndividualKM(darwinStaffCount, "Darwin", "Bicycle");
  totalBicycleKMValue = totalBicycleKMValue + bicycleKMValue;
  bicycleKMValue = calculateIndividualKM(
    canberraStaffCount,
    "Canberra",
    "Bicycle"
  );

  totalBicycleKMValue = totalBicycleKMValue + bicycleKMValue;
  return totalBicycleKMValue;
}

function calculateTotalBusKM() {
  let totalBusKMValue = 0;
  let busKMValue = calculateIndividualKM(sydneyStaffCount, "Sydney", "Bus");
  totalBusKMValue = totalBusKMValue + busKMValue;
  busKMValue = calculateIndividualKM(melbourneStaffCount, "Melbourne", "Bus");
  totalBusKMValue = totalBusKMValue + busKMValue;
  busKMValue = calculateIndividualKM(brisbaneStaffCount, "Brisbane", "Bus");
  totalBusKMValue = totalBusKMValue + busKMValue;
  busKMValue = calculateIndividualKM(adelaideStaffCount, "Adelaide", "Bus");
  totalBusKMValue = totalBusKMValue + busKMValue;
  busKMValue = calculateIndividualKM(perthStaffCount, "Perth", "Bus");
  totalBusKMValue = totalBusKMValue + busKMValue;
  busKMValue = calculateIndividualKM(hobartStaffCount, "Hobart", "Bus");
  totalBusKMValue = totalBusKMValue + busKMValue;
  busKMValue = calculateIndividualKM(darwinStaffCount, "Darwin", "Bus");
  totalBusKMValue = totalBusKMValue + busKMValue;
  busKMValue = calculateIndividualKM(canberraStaffCount, "Canberra", "Bus");
  totalBusKMValue = totalBusKMValue + busKMValue;
  return totalBusKMValue;
}

function calculateTotalCarKM() {
  //Car as driver
  let totalCarKMValue = 0;
  let carKMValue = calculateIndividualKM(
    sydneyStaffCount,
    "Sydney",
    "Car as driver"
  );
  totalCarKMValue = totalCarKMValue + carKMValue;
  carKMValue = calculateIndividualKM(
    melbourneStaffCount,
    "Melbourne",
    "Car as driver"
  );
  totalCarKMValue = totalCarKMValue + carKMValue;
  carKMValue = calculateIndividualKM(
    brisbaneStaffCount,
    "Brisbane",
    "Car as driver"
  );
  totalCarKMValue = totalCarKMValue + carKMValue;
  carKMValue = calculateIndividualKM(
    adelaideStaffCount,
    "Adelaide",
    "Car as driver"
  );
  totalCarKMValue = totalCarKMValue + carKMValue;
  carKMValue = calculateIndividualKM(perthStaffCount, "Perth", "Car as driver");
  totalCarKMValue = totalCarKMValue + carKMValue;
  carKMValue = calculateIndividualKM(
    hobartStaffCount,
    "Hobart",
    "Car as driver"
  );
  totalCarKMValue = totalCarKMValue + carKMValue;
  carKMValue = calculateIndividualKM(
    darwinStaffCount,
    "Darwin",
    "Car as driver"
  );
  totalCarKMValue = totalCarKMValue + carKMValue;
  carKMValue = calculateIndividualKM(
    canberraStaffCount,
    "Canberra",
    "Car as driver"
  );

  ///Car as passenger
  carKMValue = calculateIndividualKM(
    sydneyStaffCount,
    "Sydney",
    "Car as passenger"
  );
  totalCarKMValue = totalCarKMValue + carKMValue;
  carKMValue = calculateIndividualKM(
    melbourneStaffCount,
    "Melbourne",
    "Car as passenger"
  );
  totalCarKMValue = totalCarKMValue + carKMValue;
  carKMValue = calculateIndividualKM(
    brisbaneStaffCount,
    "Brisbane",
    "Car as passenger"
  );
  totalCarKMValue = totalCarKMValue + carKMValue;
  carKMValue = calculateIndividualKM(
    adelaideStaffCount,
    "Adelaide",
    "Car as passenger"
  );
  totalCarKMValue = totalCarKMValue + carKMValue;
  carKMValue = calculateIndividualKM(
    perthStaffCount,
    "Perth",
    "Car as passenger"
  );
  totalCarKMValue = totalCarKMValue + carKMValue;
  carKMValue = calculateIndividualKM(
    hobartStaffCount,
    "Hobart",
    "Car as passenger"
  );
  totalCarKMValue = totalCarKMValue + carKMValue;
  carKMValue = calculateIndividualKM(
    darwinStaffCount,
    "Darwin",
    "Car as passenger"
  );
  totalCarKMValue = totalCarKMValue + carKMValue;
  carKMValue = calculateIndividualKM(
    canberraStaffCount,
    "Canberra",
    "Car as passenger"
  );
  totalCarKMValue = totalCarKMValue + carKMValue;
  return totalCarKMValue;
}

function calculateTotalWalkingKM() {
  let totalWalkingKMValue = 0;
  let walkingKMValue = calculateIndividualKM(
    sydneyStaffCount,
    "Sydney",
    "Walked only"
  );
  totalWalkingKMValue = totalWalkingKMValue + walkingKMValue;
  walkingKMValue = calculateIndividualKM(
    melbourneStaffCount,
    "Melbourne",
    "Walked only"
  );
  totalWalkingKMValue = totalWalkingKMValue + walkingKMValue;
  walkingKMValue = calculateIndividualKM(
    brisbaneStaffCount,
    "Brisbane",
    "Walked only"
  );
  totalWalkingKMValue = totalWalkingKMValue + walkingKMValue;
  walkingKMValue = calculateIndividualKM(
    adelaideStaffCount,
    "Adelaide",
    "Walked only"
  );
  totalWalkingKMValue = totalWalkingKMValue + walkingKMValue;
  walkingKMValue = calculateIndividualKM(
    perthStaffCount,
    "Perth",
    "Walked only"
  );
  totalWalkingKMValue = totalWalkingKMValue + walkingKMValue;
  walkingKMValue = calculateIndividualKM(
    hobartStaffCount,
    "Hobart",
    "Walked only"
  );
  totalWalkingKMValue = totalWalkingKMValue + walkingKMValue;
  walkingKMValue = calculateIndividualKM(
    darwinStaffCount,
    "Darwin",
    "Walked only"
  );
  totalWalkingKMValue = totalWalkingKMValue + walkingKMValue;
  walkingKMValue = calculateIndividualKM(
    canberraStaffCount,
    "Canberra",
    "Walked only"
  );
  totalWalkingKMValue = totalWalkingKMValue + walkingKMValue;

  return totalWalkingKMValue;
}

function calculateTotalFerryKM() {
  let totalKMValue = 0;
  let individualKMValue = calculateIndividualKM(
    sydneyStaffCount,
    "Sydney",
    "Ferry"
  );
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(
    melbourneStaffCount,
    "Melbourne",
    "Ferry"
  );
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(
    brisbaneStaffCount,
    "Brisbane",
    "Ferry"
  );
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(
    adelaideStaffCount,
    "Adelaide",
    "Ferry"
  );
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(
    perthStaffCount,
    "Perth",
    "Bicycle"
  );
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(
    hobartStaffCount,
    "Hobart",
    "Ferry"
  );
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(
    darwinStaffCount,
    "Darwin",
    "Ferry"
  );
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(
    canberraStaffCount,
    "Canberra",
    "Ferry"
  );
  totalKMValue = totalKMValue + individualKMValue;

  return totalKMValue;
}

function calculateTotalMotorbikeKM() {
  let totalKMValue = 0;
  let individualKMValue = calculateIndividualKM(
    sydneyStaffCount,
    "Sydney",
    "Motorbike/scooter"
  );
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(
    melbourneStaffCount,
    "Melbourne",
    "Motorbike/scooter"
  );
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(
    brisbaneStaffCount,
    "Brisbane",
    "Motorbike/scooter"
  );
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(
    adelaideStaffCount,
    "Adelaide",
    "Motorbike/scooter"
  );
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(
    perthStaffCount,
    "Perth",
    "Motorbike/scooter"
  );
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(
    hobartStaffCount,
    "Hobart",
    "Motorbike/scooter"
  );
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(
    darwinStaffCount,
    "Darwin",
    "Motorbike/scooter"
  );
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(
    canberraStaffCount,
    "Canberra",
    "Motorbike/scooter"
  );
  totalKMValue = totalKMValue + individualKMValue;

  return totalKMValue;
}

function calculateTotalTaxiKM() {
  let totalKMValue = 0;
  let individualKMValue = calculateIndividualKM(
    sydneyStaffCount,
    "Sydney",
    "Taxi"
  );
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(
    melbourneStaffCount,
    "Melbourne",
    "Taxi"
  );
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(
    brisbaneStaffCount,
    "Brisbane",
    "Taxi"
  );
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(
    adelaideStaffCount,
    "Adelaide",
    "Taxi"
  );
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(perthStaffCount, "Perth", "Taxi");
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(hobartStaffCount, "Hobart", "Taxi");
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(darwinStaffCount, "Darwin", "Taxi");
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(
    canberraStaffCount,
    "Canberra",
    "Taxi"
  );
  totalKMValue = totalKMValue + individualKMValue;

  return totalKMValue;
}

function calculateTotalTrainKM() {
  let totalKMValue = 0;
  let individualKMValue = calculateIndividualKM(
    sydneyStaffCount,
    "Sydney",
    "Train"
  );
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(
    melbourneStaffCount,
    "Melbourne",
    "Train"
  );
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(
    brisbaneStaffCount,
    "Brisbane",
    "Train"
  );
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(
    adelaideStaffCount,
    "Adelaide",
    "Train"
  );
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(perthStaffCount, "Perth", "Train");
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(
    hobartStaffCount,
    "Hobart",
    "Train"
  );
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(
    darwinStaffCount,
    "Darwin",
    "Train"
  );
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(
    canberraStaffCount,
    "Canberra",
    "Train"
  );
  totalKMValue = totalKMValue + individualKMValue;

  return totalKMValue;
}

function calculateTotalTramKM() {
  let totalKMValue = 0;
  let individualKMValue = calculateIndividualKM(
    sydneyStaffCount,
    "Sydney",
    "Tram"
  );
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(
    melbourneStaffCount,
    "Melbourne",
    "Tram"
  );
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(
    brisbaneStaffCount,
    "Brisbane",
    "Tram"
  );
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(
    adelaideStaffCount,
    "Adelaide",
    "Tram"
  );
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(perthStaffCount, "Perth", "Tram");
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(hobartStaffCount, "Hobart", "Tram");
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(darwinStaffCount, "Darwin", "Tram");
  totalKMValue = totalKMValue + individualKMValue;
  individualKMValue = calculateIndividualKM(
    canberraStaffCount,
    "Canberra",
    "Tram"
  );
  totalKMValue = totalKMValue + individualKMValue;

  return totalKMValue;
}

export const calculateTotalKM = (city, numberOfDays, numberOfPeople) => {
  daysPerYear = numberOfDays;

  if (city === "Sydney") {
    sydneyStaffCount = numberOfPeople;
  } else if (city === "Melbourne") {
    melbourneStaffCount = numberOfPeople;
  } else if (city === "Brisbane") {
    brisbaneStaffCount = numberOfPeople;
  } else if (city === "Adelaide") {
    adelaideStaffCount = numberOfPeople;
  } else if (city === "Perth") {
    perthStaffCount = numberOfPeople;
  } else if (city === "Hobart") {
    hobartStaffCount = numberOfPeople;
  } else if (city === "Darwin") {
    darwinStaffCount = numberOfPeople;
  } else if (city === "Canberra") {
    canberraStaffCount = numberOfPeople;
  }

  //Bicycle
  const totalBicycle = calculateTotalBicycleKM();
  //Bus
  const totalBus = calculateTotalBusKM();
  //Car
  const totalCar = calculateTotalCarKM();
  //Walking
  const totalWalking = calculateTotalWalkingKM();
  //Ferry
  const totalFerry = calculateTotalFerryKM();
  //Motorbike/Scooter
  const totalMotorbike = calculateTotalMotorbikeKM();
  //Taxi
  const totalTaxi = calculateTotalTaxiKM();
  //Train
  const totalTrain = calculateTotalTrainKM();
  //Tram
  const totalTram = calculateTotalTramKM();

  return {
    totalBicycle,
    totalBus,
    totalCar,
    totalWalking,
    totalFerry,
    totalMotorbike,
    totalTaxi,
    totalTrain,
    totalTram,
  };
};
