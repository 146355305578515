import React from "react";
import { Card, Image, Button } from "react-bootstrap";
import { getFormattedDate, getFormattedTime } from "../../helpers/utils";
import { updateContentImageAccessedAt } from "./contentApi";

const ContentCard = ({ data }) => {
  async function handleContentDownload(item) {
    const body = {
      id: item.id,
      imageName: item.image_name,
      imageUrl: item.image_url,
      accessedAt: new Date(new Date().toUTCString()),
    };
    await updateContentImageAccessedAt(body);

    return true;
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
      }}
    >
      {data
        ? data.map((item, i) => (
            <Card
              style={{
                width: "20rem",
                margin: "10px",
                height: "25rem",
              }}
              key={item.id}
            >
              {item.image_url !== "imageURL" ? (
                <Image
                  onContextMenu={(e) => e.preventDefault()}
                  onMouseDown={(e) => e.preventDefault()}
                  src={item.image_url}
                  alt={item.image_name}
                  width={250}
                  height={250}
                  rounded
                />
              ) : null}

              {item.image_url !== "imageURL" ? (
                <Card.Body>
                  <Card.Title>{item.title}</Card.Title>
                  <Card.Text>{item.description}</Card.Text>
                  <Button
                    onClick={() => {
                      handleContentDownload(item);
                    }}
                    variant="primary"
                  >
                    Download Content
                  </Button>
                </Card.Body>
              ) : (
                <Card.Body style={{ alignItems: "center" }}>
                  <Card.Title>In Progress</Card.Title>
                  <Card.Text>
                    Requested on{" "}
                    {getFormattedDate(item.created_at) +
                      " " +
                      getFormattedTime(item.created_at)}
                  </Card.Text>
                </Card.Body>
              )}
            </Card>
          ))
        : null}
    </div>
  );
};

export default ContentCard;
