import { clearLoginData } from "../../../helpers/store";
import { getAuthorizationHeaderWithContentType } from "../../../helpers/utils";

export const submitPaperForm = async (body) => {
  try {
    const response = await fetch("/api/climateActive/submit-paper-form", {
      method: "POST",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      console.log(jsonData);
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const savePaperDraft = async (body) => {
  try {
    const response = await fetch("/api/climateActive/save-paper-draft", {
      method: "POST",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      console.log(jsonData);
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const getAllPaperData = async () => {
  try {
    const response = await fetch("/api/climateActive/get-all-paper-data", {
      method: "GET",
      headers: getAuthorizationHeaderWithContentType(),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      console.log(jsonData);
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};
