import React, { useEffect, useState } from "react";
import { Spinner, Modal, Form, Button, Row } from "react-bootstrap";
import {
  createTransparencyAssessment,
  deleteTransparencyAssessment,
  getBusinesses,
  updateTransparencyAssessment,
} from "./adminApi";
import { toast } from "react-toastify";
import Select from "react-select";
import debounce from "lodash.debounce";
import app from "../../firebase";
import { fetchAllApprovedSuppliersFromFirebase } from "../../helpers/suppliersHelper";
import { useForm, Controller } from "react-hook-form";

export const EditTransparencyAssessmentModal = ({
  show,
  onHide,
  data,
  // index,
  onDataUpdated,
}) => {
  const [email, setEmail] = useState(data?.assessmentData?.email);
  const [invitedBy, setInvitedBy] = useState(data?.assessment?.invitedBy);
  const [status, setStatus] = useState(data?.assessment?.status);
  const [score, setScore] = useState(data?.assessment?.score);
  const yearTypePrefix =
    data?.assessment?.reportingType === "financial" ? "FY" : "CY";
  const [isLoading, setIsLoading] = useState(false);
  const transparencyAssessmentId = data.supplierId;
  const documentId = `${yearTypePrefix}${data.assessment?.reportingYear}`;

  const dataForUpdate = {
    transparencyAssessmentId,
    documentId,
    status,
    score,
    email,
    invitedBy,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await updateTransparencyAssessment(dataForUpdate);
      // console.log(response);

      // Assume response contains a success field indicating the operation's result
      toast.success("Successfully updated.");
      onHide();
      onDataUpdated();
    } catch (error) {
      console.error("Error updating the transparency assessment:", error);
      toast.error("An unexpected error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Transparency Assessment of <i>{data.supplierInfo?.name}</i>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <Form.Group controlId="invitedByInput">
              <Form.Label>Linked business ID</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter the linked business ID"
                value={invitedBy}
                onChange={(e) => setInvitedBy(e.target.value)}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group controlId="emailInput">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter the supplier's email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group controlId="statusSelect">
              <Form.Label>Status</Form.Label>
              <Form.Select
                aria-label="Default select"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="NOTSENT">Not Sent</option>
                <option value="SENT">Sent</option>
                <option value="PENDING">Pending</option>
                <option value="REGISTERED">Registered</option>
                <option value="DRAFTED">Drafted</option>
                <option value="SUBMITTED">Submitted</option>
              </Form.Select>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group controlId="finalScoreInput">
              <Form.Label>Final Score</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter final score"
                value={score}
                onChange={(e) => setScore(e.target.value)}
              />
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {isLoading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Saving...
            </>
          ) : (
            <Button type="submit">Save</Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export const CreateTransparencyAssessmentModal = ({
  show,
  onHide,
  onDataUpdated,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const [isLoading, setIsLoading] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [searchSupplierTerm, setSearchSupplierTerm] = useState("");
  const [searchBusinessTerm, setSearchBusinessTerm] = useState("");

  const fetchSuppliers = debounce(async (searchTerm) => {
    const fetchedSuppliers = await fetchAllApprovedSuppliersFromFirebase(
      searchTerm
    );
    setSuppliers(fetchedSuppliers || []);
  }, 300); // Debounce time of 300ms

  useEffect(() => {
    fetchSuppliers(searchSupplierTerm);
  }, [searchSupplierTerm]); // Fetch suppliers whenever searchTerm changes

  useEffect(() => {
    async function setBusinessesToState() {
      setIsLoading(true);
      const response = await getBusinesses();
      setBusinesses(response);
      setIsLoading(false);
    }

    setBusinessesToState();
  }, []);

  const getFilteredBusinesses = () => {
    if (!searchBusinessTerm) return businesses;

    const lowercasedFilter = searchBusinessTerm.toLowerCase();
    return businesses.filter((business) =>
      business.name.toLowerCase().includes(lowercasedFilter)
    );
  };

  const yearOptions = [];
  for (let year = 2019; year <= 2025; year++) {
    yearOptions.push({ label: `${year}`, value: year });
  }

  const statusOptions = [
    { label: "Not Sent", value: "NOTSENT" },
    { label: "Sent", value: "SENT" },
    { label: "Pending", value: "PENDING" },
    { label: "Registered", value: "REGISTERED" },
    { label: "Drafted", value: "DRAFTED" },
    { label: "Submitted", value: "SUBMITTED" },
  ];
  const reportingTypeOptions = [
    { label: "Financial", value: "FY" },
    { label: "Calendar", value: "CY" },
  ];

  const onSubmit = async (data) => {
    const dataForCreate = {
      transparencyAssessmentId: data.supplier.value,
      email: data.email,
      invitedBy: data.business?.value || null,
      documentId: `${data.reportingYearType.value}${data.reportingYear.value}`,
      reportingYear: data.reportingYear.value.toString(),
      reportingType: data.reportingYearType.label.toLowerCase(),
      status: data.status.value,
      score: data.score || 0,
    };

    setIsLoading(true);
    try {
      // console.log("data", dataForCreate)
      await createTransparencyAssessment(dataForCreate);
      toast.success("Successfully created.");
      onHide();
      onDataUpdated();
    } catch (error) {
      console.error("Error updating the transparency assessment:", error);
      toast.error("An unexpected error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Create Transparency Assessment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <Form.Group controlId="editSupplierSelect">
              <Form.Label>Select Supplier</Form.Label>
              <Controller
                name="supplier"
                control={control}
                rules={{ required: "This is required" }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={suppliers.map((supplier) => ({
                      label: supplier.name,
                      value: supplier.id,
                    }))}
                    classNamePrefix="react-select"
                    placeholder="Select a Supplier"
                    onInputChange={(inputValue, actionMeta) => {
                      setSearchSupplierTerm(inputValue);
                      if (actionMeta.action === "input-change") {
                        setValue("supplier", {
                          ...field.value,
                          label: inputValue,
                        });
                      }
                    }}
                    onChange={(val) => {
                      setValue("supplier", val, { shouldDirty: true });
                      field.onChange(val);
                    }}
                  />
                )}
              />
              {errors.supplier && (
                <div className="invalid-feedback d-block">
                  {" "}
                  {errors.supplier.message}
                </div>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group controlId="editBusinessSelect">
              <Form.Label>Select Business linked to</Form.Label>
              <Controller
                name="business"
                control={control}
                // rules={{ required: "This is required" }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={getFilteredBusinesses()?.map((business) => ({
                      label: business.name,
                      value: business.id,
                    }))}
                    classNamePrefix="react-select"
                    placeholder="Select a Business"
                    onInputChange={(inputValue, actionMeta) => {
                      setSearchBusinessTerm(inputValue);
                      if (actionMeta.action === "input-change") {
                        setValue("business", {
                          ...field.value,
                          label: inputValue,
                        });
                      }
                    }}
                    onChange={(val) => {
                      setValue("business", val, { shouldDirty: true });
                      field.onChange(val);
                    }}
                  />
                )}
              />
              {/* {errors.supplier && (
                <div className="invalid-feedback d-block">
                  {" "}
                  {errors.supplier.message}
                </div>
              )} */}
            </Form.Group>
          </Row>
          {/* Email Field */}
          <Row className="mb-3">
            <Form.Group controlId="emailInput">
              <Form.Label>Email Used</Form.Label>
              <Controller
                name="email"
                control={control}
                rules={{ required: "This is required" }}
                defaultValue="" // Default value for the email field
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="email"
                    placeholder="Enter the email"
                    isInvalid={errors.email}
                  />
                )}
              />
              {errors.email && (
                <Form.Control.Feedback type="invalid">
                  {errors.email.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Row>

          {/* Calendar Year Field */}
          <Row className="mb-3">
            <Form.Group controlId="yearSelect">
              <Form.Label>Select a Year</Form.Label>
              <Controller
                name="reportingYear"
                control={control}
                rules={{ required: "This is required" }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={yearOptions}
                    classNamePrefix="react-select"
                    placeholder="Select a Year"
                    onChange={(val) => setValue("reportingYear", val)}
                  />
                )}
              />
              {errors.reportingYear && (
                <div className="invalid-feedback d-block">
                  {" "}
                  {errors.reportingYear.message}
                </div>
              )}
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group controlId="yearTypeSelect">
              <Form.Label>Select Reporting Year Type</Form.Label>
              <Controller
                name="reportingYearType"
                control={control}
                rules={{ required: "This is required" }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={reportingTypeOptions}
                    classNamePrefix="react-select"
                    placeholder="Select a Reporting Year Type"
                    onChange={(val) => setValue("reportingYearType", val)}
                  />
                )}
              />
              {errors.reportingYearType && (
                <div className="invalid-feedback d-block">
                  {" "}
                  {errors.reportingYearType.message}
                </div>
              )}
            </Form.Group>
          </Row>

          {/* Status Field */}
          <Row className="mb-3">
            <Form.Group controlId="statusSelect">
              <Form.Label>Status</Form.Label>
              <Controller
                name="status"
                control={control}
                rules={{ required: "This is required" }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={statusOptions}
                    classNamePrefix="react-select"
                    placeholder="Select a Status"
                    onChange={(val) => setValue("status", val)}
                  />
                )}
              />
              {errors.status && (
                <div className="invalid-feedback d-block">
                  {" "}
                  {errors.status.message}
                </div>
              )}
            </Form.Group>
          </Row>

          {/* Final Score Field (optional) */}
          <Row className="mb-3">
            <Form.Group controlId="finalScoreInput">
              <Form.Label>Final Score (Optional)</Form.Label>
              <Controller
                name="score"
                control={control}
                defaultValue="" // Default value for the score field
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="number"
                    placeholder="Enter final score (optional)"
                  />
                )}
              />
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {isLoading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Saving...
            </>
          ) : (
            <Button type="submit" disabled={!isValid}>
              Save
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

// export const CreateTransparencyAssessmentModal = ({
//   show,
//   onHide,
//   onDataUpdated,
// }) => {
//   const [status, setStatus] = useState();
//   const [score, setScore] = useState();
//   const [email, setEmail] = useState();
//   const [isLoading, setIsLoading] = useState(false);
//   const [suppliers, setSuppliers] = useState([]);
//   const [selectedSupplier, setSelectedSupplier] = useState(null);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [reportingYear, setReportingYear] = useState(null);
//   // const transparencyAssessmentId = data.supplierId;
//   // const documentId = data.assessments[index].reportingYear;

//   const fetchSuppliers = debounce(async (searchTerm) => {
//     const fetchedSuppliers = await fetchAllApprovedSuppliersFromFirebase(
//       searchTerm
//     );
//     setSuppliers(fetchedSuppliers || []);
//   }, 300); // Debounce time of 300ms

//   useEffect(() => {
//     fetchSuppliers(searchTerm);
//   }, [searchTerm]); // Fetch suppliers whenever searchTerm changes

//   const handleChange = (selectedOption) => {
//     setSelectedSupplier(selectedOption);
//     // console.log(`Option selected:`, selectedOption);
//   };

//   const handleYearChange = (selectedOption) => {
//     setReportingYear(selectedOption); // Update the selected year
//   };
//   const yearOptions = [];
//   for (let year = 2019; year <= 2025; year++) {
//     yearOptions.push({ label: `${year}`, value: year });
//   }
//   const handleInputChange = (newValue) => {
//     const inputValue = newValue.replace(/\W/g, "");
//     setSearchTerm(inputValue); // Update the search term which triggers the useEffect
//     return inputValue;
//   };

//   const statusOptions = [
//     { label: "Not Sent", value: "NOTSENT" },
//     { label: "Sent", value: "SENT" },
//     { label: "Pending", value: "PENDING" },
//     { label: "Registered", value: "REGISTERED" },
//     { label: "Drafted", value: "DRAFTED" },
//     { label: "Submitted", value: "SUBMITTED" },
//   ];

//   const handleStatusChange = (selectedOption) => {
//     setStatus(selectedOption);
//   };
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const dataForCreate = {
//       transparencyAssessmentId: selectedSupplier.value,
//       email,
//       documentId: `FY${reportingYear.value}`,
//       reportingYear: reportingYear.value,
//       status: status.value,
//       score,
//     };

//     // console.log("Sent Data =>", dataForCreate);
//     setIsLoading(true);
//     try {
//       const response = await createTransparencyAssessment(dataForCreate);
//       toast.success("Successfully created.");
//       onHide();
//       onDataUpdated();
//     } catch (error) {
//       console.error("Error updating the transparency assessment:", error);
//       toast.error("An unexpected error occurred. Please try again.");
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <Modal
//       show={show}
//       onHide={onHide}
//       size="xl"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Form onSubmit={handleSubmit}>
//         <Modal.Header closeButton>
//           <Modal.Title id="contained-modal-title-vcenter">
//             Create Transparency Assessment
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Row className="mb-3">
//             <Form.Group controlId="editSupplierSelect">
//               <Form.Label>Select Supplier</Form.Label>
//               <Select
//                 options={suppliers.map((supplier) => ({
//                   label: supplier.name,
//                   value: supplier.id,
//                 }))}
//                 classNamePrefix="react-select"
//                 placeholder="Select a Supplier"
//                 value={selectedSupplier}
//                 onChange={handleChange}
//                 onInputChange={handleInputChange}
//                 getOptionLabel={(option) => option.label}
//                 getOptionValue={(option) => option.value}
//               />
//             </Form.Group>
//           </Row>
//           <Row className="mb-3">
//             <Form.Group controlId="emailInput">
//               <Form.Label>Email Used</Form.Label>
//               <Form.Control
//                 type="email"
//                 placeholder="Enter the email"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//               />
//             </Form.Group>
//           </Row>
//           <Row className="mb-3">
//             <Form.Group controlId="yearSelect">
//               <Form.Label>Select Calendar Year</Form.Label>
//               <Select
//                 options={yearOptions}
//                 classNamePrefix="react-select"
//                 placeholder="Select a Year"
//                 value={reportingYear}
//                 onChange={handleYearChange}
//                 getOptionLabel={(option) => option.label}
//                 getOptionValue={(option) => option.value}
//               />
//             </Form.Group>
//           </Row>
//           <Row className="mb-3">
//             <Form.Group controlId="statusSelect">
//               <Form.Label>Status</Form.Label>
//               <Select
//                 options={statusOptions}
//                 classNamePrefix="react-select"
//                 value={statusOptions.find(
//                   (option) => option.value === status?.value
//                 )}
//                 onChange={handleStatusChange}
//                 placeholder="Select a status"
//                 // isClearable={true}
//               />
//             </Form.Group>
//           </Row>
//           <Row className="mb-3">
//             <Form.Group controlId="finalScoreInput">
//               <Form.Label>Final Score</Form.Label>
//               <Form.Control
//                 type="number"
//                 placeholder="Enter final score"
//                 value={score}
//                 onChange={(e) => setScore(e.target.value)}
//               />
//             </Form.Group>
//           </Row>
//         </Modal.Body>
//         <Modal.Footer>
//           {isLoading ? (
//             <>
//               <Spinner
//                 as="span"
//                 animation="border"
//                 size="sm"
//                 role="status"
//                 aria-hidden="true"
//               />{" "}
//               Saving...
//             </>
//           ) : (
//             <Button type="submit">Save</Button>
//           )}
//         </Modal.Footer>
//       </Form>
//     </Modal>
//   );
// };

export const DeleteTransparencyAssessmentModal = ({
  show,
  onHide,
  data,
  // index,
  onDataUpdated,
}) => {
  console.log(data);
  const yearTypePrefix =
    data?.assessment?.reportingType === "financial" ? "FY" : "CY";
  const [isLoading, setIsLoading] = useState(false);
  const transparencyAssessmentId = data.supplierId;
  const documentId = `${yearTypePrefix}${data.assessment?.reportingYear}`;

  const dataForDelete = {
    // deleteAll: data.assessments.length < 2,
    transparencyAssessmentId,
    documentId,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await deleteTransparencyAssessment(dataForDelete);

      // Assume response contains a success field indicating the operation's result
      toast.success("Successfully updated.");
      onHide();
      onDataUpdated();
    } catch (error) {
      console.error("Error updating the transparency assessment:", error);
      toast.error("An unexpected error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Delete Transparency Assessment of <i>{data.supplierInfo?.name}</i>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the Transparency Assessment?
        </Modal.Body>
        <Modal.Footer>
          {isLoading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Saving...
            </>
          ) : (
            <Button type="submit">Confirm</Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
