import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { submitEngageSupplier } from "./suppliersAPI";
import { getActiveUserData, setActiveUserData } from "../../helpers/store";

function EngageSuppliersModal({
  show,
  handleClose,
  setSuppliersEngaged,
  suppliersEngaged,
}) {
  const activeUser = JSON.parse(getActiveUserData());
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    let data;
    let userData 
    if (suppliersEngaged) {
      data = {
        engage: false,
        businessName: activeUser.businessName,
      };
      userData = {
        ...activeUser,
        suppliersEngaged: false,
      };

    } else {
      data = {
        engage: true,
        businessName: activeUser.businessName,
      };
      userData = {
        ...activeUser,
        suppliersEngaged: true,
      };
    }

    await submitEngageSupplier(activeUser.businessId, data);
    //Modify Active User
   
    setActiveUserData(JSON.stringify(userData));
    setSuppliersEngaged(data.engage);
    setIsLoading(false);
    handleClose();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="m"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {suppliersEngaged ? `Pause Supplier Engagement` : `Enable Supplier Engagement`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
  <p>
    {suppliersEngaged ? 
      `Click 'Pause' to temporarily halt supplier engagement. You can resume at any time.` :
      `Click 'Enable' to authorize us to contact your suppliers, inviting them to our platform to assess and understand their sustainability performance.`
    }
  </p>
  <p>
    {suppliersEngaged ? 
      `This will stop the process, maintaining existing data until re-engagement.` :
      `This initiative enhances transparency and equips you with a dashboard to monitor sustainability scores. Consent can be withdrawn at any time.`
    }
  </p>
</Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>
          Close
        </Button>
        <Button onClick={handleSubmit}>
          {isLoading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="sr-only">Loading...</span>
            </>
          ) : suppliersEngaged ? (
            "Pause"
          ) : (
            "Enable"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EngageSuppliersModal;
