import React, { useState } from "react";
import { FileIcon, defaultStyles } from "react-file-icon";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { addFilesToSurvey } from "../transparencyHubAPI";
import { getActiveUserData, getSubCollectionRef } from "../../../helpers/store";
import app from "../../../firebase";

function DocumentsModal({
  assessmentData,
  showModal,
  closeModal,
  onAddFile,
  questionId,
  supplierId,
  onUpdateSelecteFile,
}) {
  const activeUser = JSON.parse(getActiveUserData() || "{}");
  const businessId = activeUser.businessId || "";
  const userId = activeUser.uid || "";
  const year = +activeUser.selectedMeasurementYear || new Date().getFullYear();
  const subCollectionRef = getSubCollectionRef();
  const currentDate = new Date().toISOString();
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleCheckboxChange = (file) => {
    setSelectedFile((prevFile) => (prevFile === file ? null : file));
  };

  const handleSave = async () => {
    console.log(selectedFile);
    if (selectedFile) {
      if (selectedFile.upload) {
        // Only upload a file on save when it was uploaded and not on selected files
        console.log("Upload");
        setIsLoading(true);
        try {
          await addFilesToSurvey(supplierId, subCollectionRef, selectedFile);
        } catch (error) {
          console.error("Error adding file to survey:", error);
        }
        setIsLoading(false);
      }

      onUpdateSelecteFile(selectedFile);
      onAddFile(selectedFile, questionId);
      closeModal();
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        setIsLoading(true);
        const fileData = await storeFile(file);
        fileData.upload = true;
        // console.log("file Uploaded", fileData);
        setSelectedFile(fileData);
        setUploadProgress(0);
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  async function storeFile(file) {
    return new Promise((resolve, reject) => {
      const storage = getStorage(app);
      const userFilePath = `businesses/${businessId}/sustainabilityAssessments/${year}/${file.name}`;
      const storageRef = ref(storage, userFilePath);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setUploadProgress(progress);
        },
        (error) => {
          console.error(error);
          reject(error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            const fileType = file.type.replace("application/", "");
            const fileData = {
              url: downloadURL,
              fileType: fileType,
              fileName: file.name,
              userId: userId,
              uploadDate: currentDate,
            };

            resolve(fileData);
          } catch (error) {
            console.error(error);
            reject(error);
          }
        }
      );
    });
  }

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add a file</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ padding: "15px" }}>
          <div style={{ marginBottom: "15px" }}>
            <h5 style={{ marginBottom: 8 }}>Choose an Existing File</h5>
            <div style={{ maxHeight: "300px", overflowY: "auto" }}>
              {assessmentData.map((survey) =>
                survey.files?.map((file, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "10px",
                      borderRadius: "5px",
                      transition: "background-color 0.3s",
                      marginBottom: "5px",
                      backgroundColor:
                        selectedFile && selectedFile.file === file
                          ? "#e0e0e0"
                          : "#f9f9f9",
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.backgroundColor = "#f0f0f0";
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.backgroundColor =
                        selectedFile && selectedFile.file === file
                          ? "#e0e0e0"
                          : "#f9f9f9";
                    }}
                  >
                    <a
                      href={file.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        textDecoration: "none",
                        color: "inherit",
                        width: "100%",
                      }}
                    >
                      <div className="file-thumbnail">
                        <FileIcon
                          size={20}
                          extension={file.fileType.toLowerCase() || ""}
                          {...defaultStyles[file.fileType.toLowerCase() || ""]}
                        />
                      </div>
                      <div style={{ fontSize: "14px", marginLeft: "10px" }}>
                        {file.fileName}
                      </div>
                    </a>
                    <Form.Check
                      type="checkbox"
                      checked={
                        selectedFile && selectedFile.fileName === file.fileName
                      }
                      onChange={() => handleCheckboxChange(file)}
                      style={{ marginLeft: "10px" }}
                    />
                  </div>
                ))
              )}
            </div>
          </div>
          <div style={{ marginTop: 20 }}>
            <h5 style={{ marginBottom: 8 }}>Or Upload a New File</h5>
            <Form.Control
              type="file"
              onChange={handleFileChange}
              size="sm"
              accept=".png, .jpg, .jpeg, .svg, .pdf"
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSave} disabled={!selectedFile || isLoading}>
          {isLoading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "Save File"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DocumentsModal;
