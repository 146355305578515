import React, { useEffect } from "react";
import WidgetSectionTitle from "../../widgets/WidgetSectionTitle";
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { submitEventForm } from "./eventsAPI";
import { useNavigate } from "react-router-dom";
import { calculateTotalKM } from "./LocalTransport/LocalTransportHelper";

const GreetingsDateInput = React.forwardRef(({ value, onClick }, ref) => (
  <div>
    <Form.Control
      ref={ref}
      onClick={onClick}
      value={value}
      className="ps-4"
      onChange={(e) => {
        console.log({ e });
      }}
    />
    <FontAwesomeIcon
      icon={faCalendarAlt}
      className="text-primary position-absolute top-50 translate-middle-y ms-2"
    />
  </div>
));

const Setup = ({ eventDetails }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = React.useState({
    eventName: "",
    city: "",
    state: "",
    venueType: "",
    numberOfDays: 0,
    numberOfPeople: 0,
    startDate: null,
    endDate: null,
    percOfLocalAttendees: 0,
    percOfRegionalAttendees: 0,
    percOfNationalAttendees: 0,
    percOfInternationalAttendees: 0,
  });
  const [eventSize, setEventSize] = React.useState("");
  const [total, setTotal] = React.useState("");
  const [disableFields, setDisableFields] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showButton, setShowButton] = React.useState(true);

  const onDatePeriodChange = (dates) => {
    const [start, end] = dates;
    setFormData({
      ...formData,
      startDate: start,
      endDate: end,
    });
  };

  const handleFieldChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  async function calculateEventSize(formData) {
    const { numberOfDays, numberOfPeople } = formData;

    if (!numberOfDays || !numberOfPeople) return;

    if (numberOfPeople < 5000) {
      setEventSize("Small");
    } else if (numberOfPeople >= 5000 && numberOfPeople < 10000) {
      setEventSize("Large");
      if (numberOfDays <= 1) {
        setEventSize("Small");
      }
    } else {
      setEventSize("Large");
    }
  }

  async function estimatePercentage(formData) {
    const {
      percOfLocalAttendees,
      percOfRegionalAttendees,
      percOfNationalAttendees,
      percOfInternationalAttendees,
    } = formData;
    const total =
      parseFloat(percOfLocalAttendees) +
      parseFloat(percOfRegionalAttendees) +
      parseFloat(percOfNationalAttendees) +
      parseFloat(percOfInternationalAttendees);
    setTotal(isNaN(total) ? 0 : total);
  }

  async function get(formData) {
    await calculateEventSize(formData);
    await estimatePercentage(formData);
  }

  React.useEffect(() => {
    get(formData);
  }, [formData]);

  function setEventDetailsIntoFormData(eventDetails) {
    setDisableFields(true);
    setShowButton(false);
    const data = {
      eventName: eventDetails.name,
      city: eventDetails.city,
      state: eventDetails.state,
      venueType: eventDetails.num_of_venue,
      numberOfDays: eventDetails.num_of_days,
      numberOfPeople: eventDetails.num_of_people,
      startDate: new Date(eventDetails.startdate),
      endDate: new Date(eventDetails.enddate),
      percOfLocalAttendees: eventDetails.perc_local_attendees,
      percOfRegionalAttendees: eventDetails.perc_regional_attendees,
      percOfNationalAttendees: eventDetails.perc_national_attendees,
      percOfInternationalAttendees: eventDetails.perc_international_attendees,
    };
    // format date when number of days is greater than 1
    if (eventDetails.num_of_days <= 1) {
      // data.startDate = new Date(eventDetails.startdate);
      // data.endDate = new Date(eventDetails.enddate);
      data.startDate = new Date(eventDetails.startdate)
        .toISOString()
        .split("T")[0];
      data.endDate = new Date(eventDetails.enddate).toISOString().split("T")[0];
    }
    setFormData(data);
    setEventSize(eventDetails.event_size);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    setDisableFields(true);

    if (!formData.endDate) {
      formData.endDate = formData.startDate;
    }

    //estimate litres of water consumed in event
    //Assumption is 36L/person/day
    const litresOfWaterEstimate =
      36 * formData.numberOfPeople * formData.numberOfDays;

    //estimate total km travelled by attendees by mode of transport locally
    const numberOfLocalAttendees =
      (formData.percOfLocalAttendees / 100) * formData.numberOfPeople;

    const localTransportEstimates = calculateTotalKM(
      formData.city,
      formData.numberOfDays,
      numberOfLocalAttendees
    );
    console.log("localTransportEstimates", localTransportEstimates);

    const body = {
      ...formData,
      eventSize,
      litresOfWaterEstimate,
      numberOfLocalAttendees,
      localTransportEstimates: localTransportEstimates,
    };
    console.log("body:", body);
    const id = await submitEventForm(body);
    console.log(id);
    setIsLoading(false);
    navigate("/welcome/dashboard/event-form", { state: { id } });
  }

  React.useEffect(() => {
    // const eventDetails = JSON.parse(sessionStorage.getItem("eventDetails"));
    if (eventDetails) {
      setEventDetailsIntoFormData(eventDetails);
    }
  }, [eventDetails]);

  console.log(formData);
  return (
    <>
      {/* <WidgetSectionTitle
        icon="list"
        title="Getting Started"
        subtitle="Upload basic event information."
        transform="shrink-2"
        className="mb-4 mt-6"
      /> */}
      <Form onSubmit={handleSubmit}>
        <Card>
          <Card.Body>
            {/* <Card.Title>Enter Event Details</Card.Title>
            <p>Start by providing basic details</p> */}

            <Form.Group className="mb-3">
              <Form.Label>Event Name</Form.Label>
              <FloatingLabel label="Enter event name">
                <Form.Control
                  required
                  type="text"
                  name="eventName"
                  value={formData.eventName}
                  onChange={handleFieldChange}
                  disabled={disableFields}
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Select city where event is taking place</Form.Label>
              <FloatingLabel label="City Selection">
                <Form.Select
                  required
                  name="city"
                  value={formData.city}
                  onChange={handleFieldChange}
                  disabled={disableFields}
                >
                  <option>Select...</option>
                  <option value="Sydney">Sydney</option>
                  <option value="Melbourne">Melbourne</option>
                  <option value="Brisbane">Brisbane</option>
                  <option value="Adelaide">Adelaide</option>
                  <option value="Perth">Perth</option>
                  <option value="Hobart">Hobart</option>
                  <option value="Darwin">Darwin</option>
                  <option value="Canberra">Canberra</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>
                Select state/region where event is taking place
              </Form.Label>
              <FloatingLabel label="State Selection">
                <Form.Select
                  required
                  name="state"
                  value={formData.state}
                  onChange={handleFieldChange}
                  disabled={disableFields}
                >
                  <option>Select...</option>
                  <option value="ACT">ACT</option>
                  <option value="NSW">NSW</option>
                  <option value="NT">NT</option>
                  <option value="QLD (Bris/South East Qld)">
                    QLD (Bris/South East Qld)
                  </option>
                  <option value="QLD (Other Qld)">QLD (Other Qld)</option>
                  <option value="SA">SA</option>
                  <option value="TAS">TAS</option>
                  <option value="VIC">VIC</option>
                  <option value="WA (Perth area)">WA (Perth area)</option>
                  <option value="WA (Remote)">WA (Remote)</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Select venue types</Form.Label>
              <FloatingLabel label="Venue Type Selection">
                <Form.Select
                  required
                  name="venueType"
                  value={formData.venueType}
                  onChange={handleFieldChange}
                  disabled={disableFields}
                >
                  <option>Select...</option>
                  <option value="Single venue">Single venue</option>
                  <option value="Multiple venues">Multiple venues</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Number of people</Form.Label>
              <FloatingLabel label="People includes presenters, staff and attendees">
                <Form.Control
                  required
                  type="number"
                  name="numberOfPeople"
                  value={formData.numberOfPeople}
                  onChange={handleFieldChange}
                  disabled={disableFields}
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Number of days</Form.Label>
              <FloatingLabel label="Enter number of days">
                <Form.Control
                  required
                  type="number"
                  name="numberOfDays"
                  value={formData.numberOfDays}
                  onChange={handleFieldChange}
                  disabled={disableFields}
                />
              </FloatingLabel>
            </Form.Group>

            {formData.numberOfDays <= 1 && (
              <Form.Group className="mb-3">
                <Form.Label>Event Date</Form.Label>
                <FloatingLabel label="Select Date">
                  <Form.Control
                    required
                    type="date"
                    name="startDate"
                    value={formData.startDate}
                    onChange={handleFieldChange}
                    disabled={disableFields}
                  />
                </FloatingLabel>
              </Form.Group>
            )}

            {formData.numberOfDays > 1 && (
              <Col md="auto" className="mb-3 mb-md-0">
                <Row className="g-3 gy-md-0 h-100 align-items-center">
                  <Col xs="auto">
                    <h6 className="text-700 mb-0 text-nowrap">
                      Select Time Period:
                    </h6>
                  </Col>
                  <Col md="auto">
                    <DatePicker
                      selected={formData.startDate}
                      onChange={onDatePeriodChange}
                      selectsRange
                      startDate={formData.startDate}
                      endDate={formData.endDate}
                      dateFormat="dd/MM/yyyy"
                      customInput={<GreetingsDateInput />}
                      disabled={disableFields}
                    />
                  </Col>
                </Row>
              </Col>
            )}

            <Form.Group className="mb-3">
              <Form.Label>Event Size</Form.Label>
              <FloatingLabel label="Determined by attendees and days ">
                <Form.Control
                  required
                  name="eventSize"
                  value={eventSize}
                  disabled
                />
              </FloatingLabel>
            </Form.Group>
            <hr />
            <Card.Title>Attendees and staff breakdown</Card.Title>
            <p>Estimate the type of attendees including staff:</p>
            <Form.Text>
              Total percentage: {total}
              {total > 100 && (
                <span className="text-danger">
                  {" "}
                  - Total percentage must be equal to 100%
                </span>
              )}
            </Form.Text>
            <Form.Group className="mb-3">
              <Form.Label>Local</Form.Label>
              <FloatingLabel label="e.g. those within 20km">
                <Form.Control
                  required
                  type="number"
                  name="percOfLocalAttendees"
                  value={formData.percOfLocalAttendees}
                  onChange={handleFieldChange}
                  disabled={disableFields}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Regional</Form.Label>
              <FloatingLabel label="e.g. those driving more than 20km">
                <Form.Control
                  required
                  type="number"
                  name="percOfRegionalAttendees"
                  value={formData.percOfRegionalAttendees}
                  onChange={handleFieldChange}
                  disabled={disableFields}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>National</Form.Label>
              <FloatingLabel label="e.g. those flying domestic">
                <Form.Control
                  required
                  type="number"
                  name="percOfNationalAttendees"
                  value={formData.percOfNationalAttendees}
                  onChange={handleFieldChange}
                  disabled={disableFields}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>International</Form.Label>
              <FloatingLabel label="e.g. those flying international">
                <Form.Control
                  required
                  type="number"
                  name="percOfInternationalAttendees"
                  value={formData.percOfInternationalAttendees}
                  onChange={handleFieldChange}
                  disabled={disableFields}
                />
              </FloatingLabel>
            </Form.Group>

            {/* <ProgressBar now={progress} label={`${progress}%`} /> */}
            {/* <ToastContainer /> */}
            <br />
            {isLoading && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
            {!isLoading && showButton && (
              <Button
                className="w-100"
                type="submit"
                disabled={
                  !formData.city ||
                  !formData.eventName ||
                  !formData.city ||
                  !formData.state ||
                  !formData.venueType ||
                  !formData.numberOfPeople ||
                  !formData.numberOfDays ||
                  !formData.startDate ||
                  total !== 100 ||
                  !eventSize ||
                  disableFields
                }
              >
                Submit
              </Button>
            )}
          </Card.Body>
        </Card>
      </Form>
    </>
  );
};

export default Setup;
