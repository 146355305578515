import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import IconButton from "../../components/common/IconButton";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import UpgradeSubscriptionRequestModal from "./UpgradeSubscriptionRequestModal";
import ReportRequestModal from "./ReportRequestModal";
import {
  getBusinessReports,
  requestReport,
  requestStandardReport,
} from "./reportsApi";
import AccountingSoftwareIntegrationRequestModal from "./AccountingSoftwareIntegrationRequestModal";
import {
  getCompanyLogo,
  getReportIntroduction,
  getScopesEmissions,
  getTopSuppliersDescription,
  getTopSuppliers,
  getTopPrimaryCategoriesDescription,
  getTopPrimaryCategories,
  getMethodology,
  getEmissionsBoundaries,
  getScopeEmissionsDonut,
  getScopeEmissionsDonutDescription,
  getLocations,
  getOperationalBoundaries,
  getOrganisationalBoundaries,
} from "./reportHelper";
import {
  getActiveUserData,
  getInventoryProgress,
  getWhiteLabel,
} from "../../helpers/store";
import { ToastContainer, toast } from "react-toastify";
import {
  fetchDashboardDataFromFirebaseByBusinessId,
  removeUnifiedDateFromStore,
} from "../../helpers/unifiedDataHelper";

const ReportsTableHeader = ({
  startDate,
  endDate,
  userData,
  storeData,
  handleDownload,
}) => {
  const navigate = useNavigate();
  const [activeUser] = useState(JSON.parse(getActiveUserData()));

  const [modalShow, setModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [unifiedDataCalCompleted] = useState(
    JSON.parse(sessionStorage.getItem("unifiedDataCalCompleted"))
  );
  const [dashboardDataCalCompleted] = useState(
    JSON.parse(sessionStorage.getItem("dashboardDataCalCompleted"))
  );

  const selectedMeasurementYear = activeUser.selectedMeasurementYear;
  const selectedMeasurementYearType = activeUser.selectedMeasurementYearType;
  const response = getInventoryProgress();
  let localStorageParsed = JSON.parse(response);

  // console.log("localStorageParsed", localStorageParsed);

  // Finding the matching object
  const inventoryProgress = localStorageParsed.find(
    (item) =>
      item.measurementyear === selectedMeasurementYear &&
      item.measurementyeartype === selectedMeasurementYearType
  );

  //update chart data if date range changes
  async function updateChartData() {
    await removeUnifiedDateFromStore();
    await fetchDashboardDataFromFirebaseByBusinessId(
      activeUser.businessId,
      new Date(activeUser.currentStartDate),
      new Date(activeUser.currentEndDate)
    );

    //to initiate chart data calculation
    sessionStorage.setItem("dashboardDataCalCompleted", false);
  }

  async function handlePremiumReportClick() {
    setModalShow(true);

    await requestReport({
      requestedBy: userData.uid,
      documentName:
        userData.businessName + "-" + userData.displayName.split(" ")[0],
      reportStatus: "InProgress",
      userName: userData.displayName,
      userEmail: userData.email,
      businesssName: userData.businessName,
      accountingSoftware: userData.authProvider,
      processingStatus: userData.processingStatus,
      subscription: userData.subscription,
    });

    if (
      userData.subscription === "PAID" ||
      userData.subscription === "PREMIUM"
    ) {
      if (unifiedDataCalCompleted) {
        //update chart data
        if (!dashboardDataCalCompleted) {
          toast.info("Collecting data. Please wait...");
          await updateChartData();
        }
        toast.info("Generating request. Please wait...");
        const response = await getBusinessReports();
        storeData(response);
        navigate("/welcome/user-reports");
      }
    }
  }

  async function handleStandardReportClick() {
    //to show modal
    setModalShow(true);

    //console.log("userData=>", userData);
    setIsLoading(true);

    //check if user has paid subscription
    if (
      userData.subscription !== "PAID" &&
      userData.subscription !== "PREMIUM"
    ) {
      setIsLoading(false);
      return;
    }

    //check if user has uploaded data
    if (!unifiedDataCalCompleted) {
      setIsLoading(false);
      return;
    }

    toast.info("Collecting data. Please wait...");

    //update chart data
    if (unifiedDataCalCompleted && !dashboardDataCalCompleted) {
      await updateChartData();
    }

    const fromDate = startDate.toDateString();
    const toDate = endDate.toDateString();
    const introductionText = await getReportIntroduction(
      inventoryProgress,
      userData,
      fromDate.toString(),
      toDate.toString()
    );
    const companyLogo = await getCompanyLogo(
      userData,
      fromDate.toString(),
      toDate.toString()
    );
    // console.log("introductionText=>", introductionText);
    const scopeEmissions = await getScopesEmissions(
      inventoryProgress,
      userData,
      startDate,
      endDate
    );
    // console.log("scopeEmissions=>", scopeEmissions);

    const methodology = await getMethodology(
      inventoryProgress,
      userData,
      startDate,
      endDate,
      getWhiteLabel().name
    );

    const operationalBoundaries = await getOperationalBoundaries(
      userData,
      startDate,
      endDate,
      getWhiteLabel().name
    );
    const organisationalBoundaries = await getOrganisationalBoundaries(
      inventoryProgress,
      userData,
      startDate,
      endDate,
      getWhiteLabel().name
    );

    const topSuppliersDescription = await getTopSuppliersDescription(
      inventoryProgress,
      userData,
      startDate,
      endDate
    );
    // console.log("topSuppliersDescription=>", topSuppliersDescription);

    // top10Suppliers,
    // top10SuppliersObjects,

    const { top10Suppliers, top10SuppliersObjects } = await getTopSuppliers(
      userData,
      startDate,
      endDate
    );
    // console.log("top10Suppliers=>", top10Suppliers);
    // console.log("top10SuppliersObjects=>", top10SuppliersObjects);

    const topCategoriesDescription = await getTopPrimaryCategoriesDescription(
      inventoryProgress,
      userData,
      startDate,
      endDate
    );
    // console.log("topCategoriesDescription=>", topCategoriesDescription);

    const { topPrimaryCategories, topPrimaryCategoriesObjects } =
      await getTopPrimaryCategories(userData, startDate, endDate);
    // console.log("topPrimaryCategories=>", topPrimaryCategories);
    // console.log("topPrimaryCategoriesObjects=>", topPrimaryCategoriesObjects);

    const { emissionsBoundaryOverview, emissionsBoundaryReasons } =
      await getEmissionsBoundaries(
        userData,
        startDate,
        endDate,
        topPrimaryCategories
      );
    // console.log("emissionsBoundaryOverview=>", emissionsBoundaryOverview);
    // console.log("emissionsBoundaryReasons=>", emissionsBoundaryReasons);

    const scopeEmissionsDonut = await getScopeEmissionsDonut(
      userData,
      startDate,
      endDate,
      scopeEmissions
    );
    const scopeEmissionsDonutDescription =
      await getScopeEmissionsDonutDescription(
        inventoryProgress,
        userData,
        startDate,
        endDate
      );

    const locations = await getLocations(
      inventoryProgress,
      userData,
      startDate,
      endDate
    );
    // console.log("locations=>", locations);

    // const scope3BarChart = await getScope3Emissions(
    //   userData,
    //   startDate,
    //   endDate
    // );

    // //console.log("scope3BarChart=>", scope3BarChart);
    // const scope2GasBarChart = await getScope2GasEmissions(
    //   userData,
    //   startDate,
    //   endDate
    // );
    // // console.log("scope2GasBarChart=>", scope2GasBarChart);
    // const scope2ElectricityBarChart = await getScope2ElectricityEmissions(
    //   userData,
    //   startDate,
    //   endDate
    // );
    // // console.log("scope2ElectricityBarChart=>", scope2ElectricityBarChart);
    // const scope1PetrolAndDieselBarChart =
    //   await getScope1PetrolAndDieselEmissions(userData, startDate, endDate);
    // console.log(
    //   "scope1PetrolAndDieselBarChart=>",
    //   scope1PetrolAndDieselBarChart
    // );

    // console.log("userData=>", userData);
    // console.log("introductionText=>", introductionText);
    // console.log("companyLogo=>", companyLogo);
    // console.log("scopeEmissions=>", scopeEmissions);
    // console.log("topSuppliersDescription=>", topSuppliersDescription);
    // console.log("top10Suppliers=>", top10Suppliers);
    // console.log("top10SuppliersObjects=>", top10SuppliersObjects);
    // console.log("topCategoriesDescription=>", topCategoriesDescription);
    // console.log("topPrimaryCategories=>", topPrimaryCategories);
    // console.log("topPrimaryCategoriesObjects=>", topPrimaryCategoriesObjects);
    // console.log("methodology=>", methodology);
    // console.log("emissionsBoundaryOverview=>", emissionsBoundaryOverview);
    // console.log("emissionsBoundaryReasons=>", emissionsBoundaryReasons);
    // console.log("scopeEmissionsDonut=>", scopeEmissionsDonut);
    // console.log(
    //   "scopeEmissionsDonutDescription=>",
    //   scopeEmissionsDonutDescription
    // );
    // console.log("locations=>", locations);
    // console.log("operationalBoundaries=>", operationalBoundaries);
    // console.log("organisationalBoundaries=>", organisationalBoundaries);
    // console.log("inventoryProgress=>", inventoryProgress);

    const request = {
      businessId: userData.businessId,
      businessName: userData.businessName,
      requestedBy: userData.uid,
      documentName: userData.businessName + " - Standard Report",
      reportData: {
        mainTitle: getWhiteLabel().name,
        logo: `<img src='${
          getWhiteLabel().logo
        }' alt='Description of Image' width='100' height='100'>`,
        subTitle: "Carbon Emissions Summary Report",
        introductionText,
        companyLogo,
        scopeEmissions,
        topSuppliersDescription,
        top10Suppliers,
        top10SuppliersObjects,
        topCategoriesDescription,
        topPrimaryCategories,
        topPrimaryCategoriesObjects,
        methodology,
        emissionsBoundaryOverview,
        emissionsBoundaryReasons,
        scopeEmissionsDonut,
        scopeEmissionsDonutDescription,
        locations,
        operationalBoundaries,
        organisationalBoundaries,

        // scope3BarChart,
        // scope2GasBarChart,
        // scope2ElectricityBarChart,
        // scope1PetrolAndDieselBarChart,
      },
    };
    // console.log("request=>", request);
    toast.info("Generating report. Please wait...");
    await requestStandardReport(request);

    const response = await getBusinessReports();
    storeData(response);
    setIsLoading(false);
    navigate("/welcome/user-reports");
  }

  function handleClose() {
    setModalShow(false);
  }

  return (
    <>
      {userData.subscription !== "PAID" &&
      userData.subscription !== "PREMIUM" ? (
        <UpgradeSubscriptionRequestModal
          showModal={modalShow}
          handleClose={handleClose}
        />
      ) : !unifiedDataCalCompleted ? (
        <AccountingSoftwareIntegrationRequestModal
          showModal={modalShow}
          handleClose={handleClose}
        />
      ) : (
        <ReportRequestModal
          showModal={modalShow}
          handleDownload={handleDownload}
          handleClose={handleClose}
        />
      )}
      <Row className="flex-between-center">
        <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
          <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
            Reports ready to download
          </h5>
        </Col>
        <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
          <div id="orders-actions">
            <IconButton
              variant="falcon-default"
              size="sm"
              icon={faDownload}
              transform="shrink-3"
              style={{ marginRight: "5px" }}
            >
              <span
                className="d-none d-sm-inline-block ms-1"
                onClick={handleStandardReportClick}
              >
                Request Standard Report
              </span>
              {isLoading && (
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                  style={{ marginLeft: "10px" }}
                ></span>
              )}
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon={faDownload}
              transform="shrink-3"
            >
              <span
                className="d-none d-sm-inline-block ms-1"
                onClick={handlePremiumReportClick}
              >
                Request Premium Report
              </span>
            </IconButton>
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </>
  );
};

export default ReportsTableHeader;
