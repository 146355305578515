import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";

const ReportRequestModal = ({ showModal, handleDownload, handleClose }) => {
  return (
    <div>
      {" "}
      <Modal
        show={showModal}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Thank you for requesting your report!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            We will use your available data to produce your baseline analysis
            report. We will then instruct you on how to best use and share this
            information. Please email support@netnada.com.au if you have any
            questions.
          </p>
          <Row className="gx-3">
            <Col lg={6}>
              <Button
                size="sm"
                href="https://firebasestorage.googleapis.com/v0/b/netnadav2.appspot.com/o/Sample%20Report.pdf?alt=media&token=0634e33a-fd85-4c5d-b6ff-ef38bb8a6fcf"
                target="_blank"
                onClick={() => {
                  handleDownload(undefined, "Sample-Report");
                  handleClose();
                }}
                download
              >
                SEE SAMPLE REPORT
              </Button>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ReportRequestModal;
