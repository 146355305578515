import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthCardLayout from "../../../layouts/AuthCardLayout";
import {
  clearLoginData,
  setCompanyData,
  setIdToken,
  setInventoryProgress,
  setStepsProgress,
  setUserData,
} from "../../../helpers/store";
import { auth } from "../../../firebase";
import { Form } from "react-bootstrap";

const MyobCallback = () => {
  const queryParams = useLocation()?.search;

  useEffect(() => {
    if (queryParams) {
      const code = new URLSearchParams(queryParams).get("code");
      if (code) {
        getToken(code);
      }
    }
  }, [queryParams]);

  const getToken = async (code) => {
    const response = await fetch("/api/myob/myob-token?code=" + code);
    if (response.status !== 200) {
      clearLoginData();
    }
    const data = await response.json();

    if (!data || !data.customToken)
      throw new Error("customToken should not be empty");

    await auth.signInWithCustomToken(data.customToken);
    const idToken = await auth.currentUser.getIdToken(true);
    setIdToken(idToken);

    //check if companyFileData exists in data
    if (!data.companyFileData) {
      setUserData(JSON.stringify(data.user));
      setInventoryProgress(JSON.stringify(data.inventoryProgress));
      setStepsProgress(JSON.stringify(data.stepsProgress));
      window.location.href = "/welcome";
    } else {
      setCompanyData(JSON.stringify(data.companyFileData));
      window.location.href = "/myob-company-list";
    }
  };

  return (
    <AuthCardLayout>
      <Form.Group>
        <button class="btn btn-primary mt-3 w-100" type="button" disabled>
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
            style={{ marginRight: "10px" }}
          ></span>
          <span class="sr-only" style={{ marginLeft: "20px" }}>
            MYOB login in progress...
          </span>
        </button>
      </Form.Group>
    </AuthCardLayout>
  );
};

export default MyobCallback;
