import React from "react";
import { Card } from "react-bootstrap";
import CountUp from "react-countup";
import { wasteTypes } from "../ClimateActive/Waste/wasteData";
import { FaEllipsisH } from "react-icons/fa";

function WasteTopWidget({ title, total, units }) {
  const wasteData =
    title === "Others"
      ? { icon: <FaEllipsisH />, color: "#000" } // Set a default color for "Others"
      : wasteTypes?.find((wasteType) => wasteType.value === title);

  return (
    <Card className="pt-1 pb-1 text-center" style={{ minHeight: 120 }}>
      <div
        className="icon-circle-primary"
        style={{ fontSize: "1.3rem", color: wasteData?.color }}
      >
        {wasteData?.icon}
      </div>
      <h6 className="mb-1 font-sans-serif">
        <span className="fw-normal text-600">{title}</span>
      </h6>
      <div
        style={{
          width: "40%",
          height: "1px",
          backgroundColor: "#ddd",
          margin: "0 auto 5px auto",
        }}
      ></div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
          }}
        >
          <h4 className="mb-1 font-sans-serif" style={{ marginRight: "5px" }}>
            <CountUp
              start={0}
              end={total}
              duration={2}
              className="text-800"
              separator=","
              decimals={2}
              style={{ fontSize: "1rem" }}
            />
            <span className="fw-normal text-600" style={{ fontSize: "0.5em" }}>
              {units}
            </span>
          </h4>
        </div>
      </div>
    </Card>
  );
}

export default WasteTopWidget;
