import React from "react";

const Pagination = ({ currentPage, totalPages, onNext, onPrevious }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: "20px",
        paddingBottom: "20px",
        width: "80%",
      }}
    >
      <button
        onClick={onPrevious}
        disabled={currentPage <= 0}
        style={{
          padding: "10px 20px",
          cursor: "pointer",
          border: "none",
          background: "#f0f0f0", // Neutral background
          color: "#333", // Readable text color
          borderRadius: "4px",
          // Add more styling as needed
        }}
      >
        ❮ Previous
      </button>
      <span>
        Calculations {currentPage + 1} of {totalPages} for this supplier
      </span>
      <button
        onClick={onNext}
        disabled={currentPage >= totalPages - 1}
        style={{
          padding: "10px 20px",
          cursor: "pointer",
          border: "none",
          background: "#f0f0f0", // Neutral background
          color: "#333", // Readable text color
          borderRadius: "4px",
          // Add more styling as needed
        }}
      >
        Next ❯
      </button>
    </div>
  );
};

export default Pagination;
