import { useEffect, useState } from "react";
import { getAllForms } from "./AllFormsApi";
import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
import { useAuth } from "../../../Contexts/AuthContext";
import { Card, Nav, Row, Tab, Spinner } from "react-bootstrap";
import Flex from "../../common/Flex";
import WfhFormSummary from "../../wfh/WfhFormSummary";
import AllFormsTable from "./AllFormsTable";
import { getActiveUserData } from "../../../helpers/store";

const NavItem = ({ item }) => {
  return (
    <Nav.Item as="li">
      <Nav.Link
        className="mb-0 py-3 cursor-pointer"
        eventKey={item.toLowerCase()}
      >
        {item}
      </Nav.Link>
    </Nav.Item>
  );
};

const AllForms = () => {
  const { currentUser } = useAuth();
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const [activeTabKey, setActiveTabKey] = useState("all");
  useEffect(() => {
    const analytics = getAnalytics();
    setUserId(analytics, currentUser.uid);
    setUserProperties(analytics, { businessId: currentUser.businessId });
    logEvent(analytics, "page_view", {
      page_title: "All Forms",
    });
  }, [currentUser]);

  const [allForms, setAllForms] = useState();
  const [wasteForms, setWasteForms] = useState();
  const [paperForms, setPaperForms] = useState();
  const [flightsForms, setFlightsForms] = useState();
  const [staffForms, setStaffForms] = useState();
  const [waterForms, setWaterForms] = useState();
  const [employeeEngagementForms, setEmployeeEngagementForms] = useState();
  const [wfhForms, setWfhForms] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const rearrangeFlightData = (forms) => {
    const rearrangedForms = [];
    const batchMap = {};
    let nullBatchCounter = 0;

    forms.forEach((form) => {
      if (form.category !== "Flight") {
        rearrangedForms.push(form);
        return;
      }

      let key;
      if (form.uploaded_batch_id) {
        key = form.uploaded_batch_id;
      } else {
        key = `null-${form.id}-${nullBatchCounter++}`;
      }

      if (!batchMap[key]) {
        batchMap[key] = {
          ids: [form.id],
          category: form.category,
          update_date: form.update_date,
          uploaded_by: form.uploaded_by,
          uploaded_batch_id: form.uploaded_batch_id,
          completion_status: form.completion_status,
          uploaded_by_name: form.uploaded_by_name,
        };
      } else {
        batchMap[key].ids.push(form.id);
      }
    });

    rearrangedForms.push(...Object.values(batchMap));

    return rearrangedForms;
  };

  const getForms = async () => {
    setIsLoading(true);
    let forms = await getAllForms();

    // console.log("All Forms", forms);

    if (!forms) return;

    let paperDataObject = {};
    for (let i = 0; i < forms.length; i++) {
      if (forms[i].category !== "Paper") continue;
      let id = forms[i]["id"];
      let startdate = forms[i]["startdate"];
      let enddate = forms[i]["enddate"];
      let key = startdate + "-" + enddate + "-" + forms[i]["completion_status"];
      let name = forms[i]["name"];
      let category = forms[i]["category"];
      let completion_status = forms[i]["completion_status"];
      let update_date = forms[i]["update_date"];
      let uploaded_by = forms[i]["uploaded_by"];
      let uploaded_by_name = forms[i]["uploaded_by_name"];
      //combine all the forms with same startdate and enddate and completion_status
      if (paperDataObject[key] === undefined) {
        paperDataObject[key] = {
          id: id,
          name: name,
          category: category,
          startdate: startdate,
          enddate: enddate,
          completion_status: completion_status,
          link: "/welcome/data/climate-active/paper-data-viewer",
          data: [],
          update_date: update_date,
          uploaded_by: uploaded_by,
          uploaded_by_name: uploaded_by_name,
        };
      }
      paperDataObject[key].data.push(forms[i]);
    }

    //convert the object to an array
    const paperData = Object.values(paperDataObject);
    // console.log("PaperData",paperData);

    //remove all forms that has category as 'Paper'
    forms = forms.filter((forms) => {
      return forms.category !== "Paper";
    });

    //Add paper data to forms
    forms.push(...paperData);

    // get rid of Staff forms that have the field employee_engagement_form_id !== null
    forms = forms.filter((form) => {
      return form.category !== "Staff";
    });

    setAllForms(rearrangeFlightData(forms));

    let result = [];

    //waste
    result = forms.filter((forms) => {
      return forms.category === "Waste";
    });
    for (let i = 0; i < result.length; i++) {
      const updateDate = new Date(result[i]["update_date"]);
      const targetDate = new Date("2024-07-01T00:00:00.000Z"); // Target date
      if (updateDate > targetDate) {
        result[i]["link"] = "/welcome/data/climate-active/waste-form";
      } else {
        result[i]["link"] = "/welcome/data/climate-active/waste-data-viewer";
      }
    }
    setWasteForms(result);

    //water
    result = forms.filter((forms) => {
      return forms.category === "Water";
    });
    for (let i = 0; i < result.length; i++) {
      result[i]["link"] = "/welcome/data/water";
      result[i]["startdate"] = result[i]["start_date"];
      result[i]["enddate"] = result[i]["end_date"];
      result[i]["update_date"] = result[i]["created_at"];
    }
    setWaterForms(result);

    //Employee Engagement
    result = forms.filter((forms) => {
      return forms.category === "Employee Engagement";
    });
    for (let i = 0; i < result.length; i++) {
      // result[i]["link"] = "/welcome/data/employee-engagement-form-data";
      result[i]["link"] = "/welcome/data/employee-engagement-response-overview";
      result[i]["startdate"] = result[i]["start_date"];
      result[i]["enddate"] = result[i]["end_date"];
      result[i]["update_date"] = result[i]["created_at"];
      result[i]["uid"] = activeUser.uid;
    }
    setEmployeeEngagementForms(result);
    // console.log("Employee Engagement Forms result", result);

    //paper
    result = forms.filter((forms) => {
      return forms.category === "Paper";
    });
    setPaperForms(result);

    //flights
    result = forms.filter((forms) => {
      return forms.category === "Flight";
    });
    setFlightsForms(result);

    //Staff
    result = forms.filter((forms) => {
      return forms.category === "Staff";
    });
    setStaffForms(result);
    // console.log("staff result", result);

    setIsLoading(false);
  };

  const handleClose = (isRefresh) => {
    if (isRefresh) {
      getForms();
    }
  };

  const queryParams = new URLSearchParams(window.location.search);
  const tabKey = queryParams.get("tabKey");
  useEffect(() => {
    setActiveTabKey(tabKey || "all");
  }, [tabKey]);

  useEffect(() => {
    getForms();
  }, []);
  //console.log(allForms);

  const [navItems] = useState([
    "All",
    "Waste",
    "Flights",
    "Paper",
    // "Staff",
    // "WFH",
    "Water",
    "Employee-Engagement",
  ]);

  return (
    <>
      <Card className="mt-3">
        <Tab.Container
          id="audience-tab"
          //defaultActiveKey="all"
          activeKey={activeTabKey}
          onSelect={(k) => setActiveTabKey(k)}
        >
          <Card.Header
            as={Flex}
            justifyContent="between"
            alignItems="center"
            className="ps-0 py-0 border-bottom"
          >
            <Nav
              as="ul"
              className="nav-tabs border-0 flex-nowrap chart-tab tab-active-caret"
            >
              {navItems.map((item) => (
                <NavItem item={item} key={item} />
              ))}
            </Nav>
          </Card.Header>

          <Card.Body>
            <Row>
              <Tab.Content>
                {isLoading ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  <>
                    <Tab.Pane unmountOnExit eventKey="all">
                      <AllFormsTable
                        forms={allForms}
                        name="all"
                        handleClose={handleClose}
                        isLoading={isLoading}
                      />
                    </Tab.Pane>
                    <Tab.Pane unmountOnExit eventKey="waste">
                      <AllFormsTable
                        forms={wasteForms}
                        name="waste"
                        handleClose={handleClose}
                        isLoading={isLoading}
                      />
                    </Tab.Pane>
                    <Tab.Pane unmountOnExit eventKey="flights">
                      {/* <FlightsDataSummary /> */}
                      <AllFormsTable
                        forms={flightsForms}
                        name="flight"
                        handleClose={handleClose}
                        isLoading={isLoading}
                      />
                    </Tab.Pane>
                    <Tab.Pane unmountOnExit eventKey="paper">
                      <AllFormsTable
                        forms={paperForms}
                        name="paper"
                        handleClose={handleClose}
                        isLoading={isLoading}
                      />
                    </Tab.Pane>
                    <Tab.Pane unmountOnExit eventKey="staff">
                      {/* <StaffDataSummary /> */}
                      <AllFormsTable
                        forms={staffForms}
                        name="staff"
                        handleClose={handleClose}
                        isLoading={isLoading}
                      />
                    </Tab.Pane>
                    <Tab.Pane unmountOnExit eventKey="water">
                      <AllFormsTable
                        forms={waterForms}
                        name="water"
                        handleClose={handleClose}
                        isLoading={isLoading}
                      />
                    </Tab.Pane>
                    <Tab.Pane unmountOnExit eventKey="employee-engagement">
                      <AllFormsTable
                        forms={employeeEngagementForms}
                        name="employee engagement"
                        handleClose={handleClose}
                        isLoading={isLoading}
                      />
                    </Tab.Pane>
                    <Tab.Pane unmountOnExit eventKey="wfh">
                      <WfhFormSummary />
                    </Tab.Pane>
                  </>
                )}
              </Tab.Content>
            </Row>
          </Card.Body>
        </Tab.Container>
      </Card>
    </>
  );
};

export default AllForms;
