import React, { forwardRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { getActiveUserData } from "./store";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

export const GreetingsDateInput = forwardRef(({ value, onClick }, ref) => (
  <div>
    <Form.Control
      size="lg"
      ref={ref}
      onClick={onClick}
      value={value}
      className="ps-4"
      onChange={(e) => {
        console.log({ e });
      }}
      style={{ width: "280px" }}
    />
    <FontAwesomeIcon
      icon={faCalendarAlt}
      className="text-primary position-absolute top-50 translate-middle-y ms-2"
    />
  </div>
));

const DataDateFilter = ({ startDate, endDate, onChange }) => {
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const [dateRangeStart, setDateRangeStart] = useState(
    new Date(activeUser.dateRangeStart)
  );
  const [dateRangeEnd, setDateRangeEnd] = useState(
    new Date(activeUser.dateRangeEnd)
  );

  return (
    <Col md="auto" className="mb-3 mb-md-0">
      <Row className="g-3 gy-md-0 h-100 align-items-center">
        <Col xs="auto">
          <h4 className="text-700 mb-0 text-nowrap">Data For:</h4>
        </Col>
        <Col md="auto">
          <DatePicker
            selected={startDate}
            onChange={onChange}
            selectsRange
            startDate={startDate}
            endDate={endDate}
            dateFormat="dd/MM/yyyy"
            customInput={<GreetingsDateInput />}
            minDate={dateRangeStart}
            maxDate={dateRangeEnd}
          />
        </Col>
      </Row>
    </Col>
  );
};

export default DataDateFilter;
