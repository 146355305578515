import React from "react";
import PropTypes from "prop-types";
import { Tab } from "react-bootstrap";
import ChatContentHeader from "./ChatContentHeader";
// import threads from "../../../../data/chat/threads";
import ChatContentBody from "./ChatContentBody";
import MessageTextArea from "./MessageTextArea";

const ChatContent = ({ setHideSidebar, threads, isLoading }) => {
  console.log("ChatContent.js, threads: ", threads);
  const thread = threads[0];
  const { isEnded } = thread;
  return (
    <Tab.Content className="card-chat-content">
      {/* {threads.map((thread, index) => (
        <Tab.Pane key={index} eventKey={index} className="card-chat-pane">
          <ChatContentHeader thread={thread} setHideSidebar={setHideSidebar} />
          <ChatContentBody thread={thread} />
        </Tab.Pane>
      ))} */}

      <Tab.Pane key={0} eventKey={0} className="card-chat-pane">
        <ChatContentBody thread={thread} isLoading={isLoading} />
      </Tab.Pane>
      {!isEnded && <MessageTextArea />}
    </Tab.Content>
  );
};

ChatContent.propTypes = {
  setHideSidebar: PropTypes.func.isRequired,
};

export default ChatContent;
