import WasteOfficeCharts from "./WasteOfficeCharts";
import WasteCategoriesCharts from "./WasteCategoriesCharts";
import WasteTotalWidgets from "./WasteTotalWidgets";
import WasteTopWidgets from "./WasteTopWidgets";

function WasteCard({ data }) {
  // console.log("Waste Data", data);

  function calculateTotals(yearlyWasteData) {
    let total = { emissions: 0, weight: 0 };
    let recycled = { emissions: 0, weight: 0 };
    let generalWaste = { emissions: 0, weight: 0 };
    yearlyWasteData.forEach((item) => {
      if (item.id === "General waste") {
        generalWaste.weight = item.weight;
        generalWaste.emissions = item.value;
      } else if (item.id === "Recycling") {
        recycled.weight = item.weight;
        recycled.emissions = item.value;
      }
      total.emissions += item.value;
      total.weight += item.weight;
    });
    return {
      total,
      recycled,
      generalWaste,
    };
  }

  const { total, recycled, generalWaste } = calculateTotals(
    data?.yearlyWasteData
  );

  const sortedPieData = data?.yearlyWasteData?.sort(
    (a, b) => b.value - a.value
  );
  const sortedByWeight = [...sortedPieData].sort((a, b) => b.weight - a.weight);

  function transformToTopFive(data, key) {
    // Sort and get the top 4 entries
    const topFour = data.slice(0, 4).map(({ label, [key]: value }) => ({
      label,
      value: value?.toFixed(2),
    }));
    // Sum the remaining entries
    const othersValue = data
      .slice(4)
      .reduce((sum, item) => sum + item[key], 0)
      .toFixed(2);

    // Add the "Others" entry
    if (data.length > 4) {
      topFour.push({
        label: "Others",
        value: othersValue,
      });
    }

    return topFour;
  }

  const topEmissions = transformToTopFive(sortedPieData, "value");
  const topWeight = transformToTopFive(sortedByWeight, "weight");
  // const textData = data?.monthlyWasteEmissionData?.map((item) => {
  //   const { month, ...rest } = item;
  //   const wasteSum = Object.values(rest).reduce((acc, curr) => acc + curr, 0);
  //   return {
  //     month: item.month,
  //     waste: wasteSum?.toFixed(3),
  //   };
  // });

  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>
            {" "}
            <h4>Waste</h4>
          </div>
        </div>
        {/* <p>{generateCalculatorText("waste", textData)}</p> */}
      </div>
      <WasteTotalWidgets
        total={total}
        recycled={recycled}
        generalWaste={generalWaste}
      />
      <WasteTopWidgets topEmissions={topEmissions} topWeight={topWeight} />
      <WasteCategoriesCharts
        sortedPieData={sortedPieData}
        monthlyWasteEmissionData={data?.monthlyWasteEmissionData}
        monthlyWasteWeightData={data?.monthlyWasteWeightData}
      />
      <WasteOfficeCharts
        yearlyWasteDataByOffice={data.yearlyWasteDataByOffice}
        wasteDataLineGraph={data.wasteDataLineGraph}
      />
    </>
  );
}

export default WasteCard;
