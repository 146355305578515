import React, { useState } from "react";
import PieChart from "./PieChart";
import BarChart from "./BarChart";
import { Col, Row } from "react-bootstrap";
import {
  computePercentages,
  extractDataBarChart,
  getTopThreeLabels,
} from "./chartHelpers";
import { Link } from "react-router-dom";

function ScopeThreeCard({ pieData, barData, totalSuppliers }) {
  // console.log("PieData", pieData);
  const [clickedId, setClickedId] = useState(
    pieData.length > 0 ? pieData[0].label : ""
  );

  const [colorId, setColorId] = useState(0);
  const { percentageTopTen, percentageOthers } = computePercentages(pieData);
  const roundedPieData = pieData
    ?.filter(({ label }) => label !== "Others")
    ?.map(({ id, label, value, datalines }, index) => ({
      id,
      label,
      value: Math.round(value),
      datalines,
      idColor: index,
    }));

  // console.log(roundedPieData)
  const {
    maxDatalinesLabel,
    maxDatalines,
    specifiedLabel,
    isSameAsMax,
    specifiedDatalines,
  } = extractDataBarChart(clickedId, roundedPieData);
  const { first, second, third } = getTopThreeLabels(roundedPieData);

  // const colors = [
  //   "#7daefc",
  //   "#4df2a9",
  //   "#f8ad7c",
  //   "#ef7280",
  //   "#535d66",
  //   "#717c8a",
  //   "#4d81a1",
  //   "#8696ff",
  //   "#ffba62",
  //   "#5cbfb1",
  //   "#f4ae51",
  //   "#ee8974",
  // ];
  const colors = [
    "#00688b", // Slightly Lighter Dark Blue
    "#4da6cc", // Light Blue
    "#9ec5d3", // Very Light Blue
    "#ffd099", // Light Orange
    "#ffb066", // Lighter Orange
    "#ff9015", // Orange Logo (less bright)
    "#b0b0b0", // Gray
    "#909090", // Dark Gray
    "#707070", // Darker Gray
    "#505050", // Darkest Gray
  ];

  const handlePieClick = (id, idColor) => {
    setClickedId(id);
    setColorId(idColor);
  };

  // Filter the barData to show only data for the clicked ID
  const filteredData = barData.map((data) => ({
    month: data.month,
    [clickedId]: data[clickedId]?.toFixed(1) || 0, // Fall back to 0 if no data is available for this ID
  }));

  // Set the keys dynamically based on the clickedId
  const keys = clickedId ? [clickedId] : [];
  const dynamicColor = colorId !== null ? colors[colorId] : "#ccc"; // Default color
  const marginPie = { top: 40, bottom: 80, left: 100, right: 20 };
  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        <h4>Scope 3 Emissions</h4>
        <p>
          Scope 3 emissions are all the other indirect emissions that are a
          consequence of the activities of the company but occur from sources
          not owned or controlled by the company. This can include emissions
          associated with business travel, procurement, waste disposal, and the
          use of sold products and services. For example, if a company produces
          a product that requires a lot of energy to use, like a refrigerator,
          the emissions from using that refrigerator are considered Scope 3.
        </p>
      </div>
      <Row>
        <Col lg={6}>
          <>
            <PieChart
              pieData={roundedPieData}
              colorTheme={colors}
              onClick={handlePieClick}
              title={"Top 10 Suppliers Data in tonnes of CO₂e"}
              margin={marginPie}
            />
            {pieData.length > 1 && (
              <p>
                <strong>{first}</strong>, <strong>{second}</strong>, and{" "}
                <strong>{third}</strong> are the top emission contributors. Out
                of <strong>{totalSuppliers}</strong> suppliers, the top 10
                account for <strong>{percentageTopTen}%</strong> of emissions,
                while the rest contribute <strong>{percentageOthers}%</strong>.
              </p>
            )}
          </>
        </Col>
        <Col lg={6}>
          <>
            <BarChart
              data={filteredData}
              colorTheme={dynamicColor}
              keys={keys}
              title={`${clickedId} Data`}
              small={true}
            />
            {pieData.length > 1 && (
              <p style={{ marginTop: 40 }}>
                <strong>{specifiedLabel}</strong> has{" "}
                <strong>{specifiedDatalines}</strong> invoices
                {isSameAsMax ? (
                  " and has the highest number of invoices."
                ) : (
                  <>
                    , while <strong>{maxDatalinesLabel}</strong> has the highest
                    with <strong>{maxDatalines}</strong>.
                  </>
                )}
                <Link to="/welcome/data/supply-chain" style={{ marginLeft: 5 }}>
                  See Supply Chain Table
                </Link>{" "}
                for more details.
              </p>
            )}
          </>
        </Col>
      </Row>
    </>
  );
}

export default ScopeThreeCard;
