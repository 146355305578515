import { useContext } from "react";
import { SolutionContext } from "../../../context/Context";

const useSolutionHook = (solution) => {
  console.log(solution);
  console.log("In useSolutionHook");
  const {
    solutionsState: { cartItems },
    solutionsDispatch,
    isInShoppingCart,
  } = useContext(SolutionContext);

  const handleAddToCart = (showModal, businessId, userId, solutionId) => {
    solutionsDispatch({
      type: "ADD_TO_CART",
      payload: {
        showModal,
        solution: {
          ...solution,
        },
        businessId,
        userId,
        solutionId,
      },
    });
  };
  return { handleAddToCart };
};

export default useSolutionHook;
