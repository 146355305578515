import IconButton from "../../../components/common/IconButton";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Col, Row, Button, Modal, Spinner } from "react-bootstrap";
import { deleteSelectedForms } from "./AllFormsApi";
import { useNavigate } from "react-router-dom";
import { getActiveUserData, isDataFrozen } from "../../../helpers/store";
import app from "../../../firebase";
import {
  collection,
  deleteDoc,
  getDocs,
  getFirestore,
  increment,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  fetchDashboardDataFromFirebaseByBusinessId,
  removeUnifiedDateFromStore,
} from "../../../helpers/unifiedDataHelper";
import FrozenDataModal from "../../utilities/FrozenDataModal";

const fireStoreDB = getFirestore(app);

const AllFormsTableHeader = ({ selectedRowIds, name, data, handleClose }) => {
  const navigate = useNavigate();
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const [showDeleteQuestionModal, setShowDeleteQuestionModal] = useState(false);
  const [showDeleteLoadingModal, setShowDeleteLoadingModal] = useState(false);
  const [showFrozenDataModal, setShowFrozenDataModal] = useState(false);

  //delete from 'UnifiedData' collection in firebase where dataSource is 'Form',mainId is formId and primaryCategory is category
  const deleteFromFirebase = async (formId, formCategory) => {
    const businessId = activeUser.businessId;

    let primaryCategory = [formCategory];

    if (formCategory === "Flight") {
      primaryCategory = ["Flight", "Transport (air)"];
    } else if (formCategory === "Staff") {
      primaryCategory = ["Staff", "Transport (land and sea)"];
    } else if (formCategory === "Employee Engagement") {
      primaryCategory = ["Transport (land and sea)", "WFH"];
    }

    const dataLinesCollection = collection(
      fireStoreDB,
      "UnifiedData",
      businessId,
      "DataLines"
    );
    const groupedDataCollection = collection(
      fireStoreDB,
      "GroupedUnifiedData",
      businessId,
      "Groups"
    );

    let qry = query(
      dataLinesCollection,
      where("dataSource", "==", "Form"),
      where("mainId", "==", formId),
      where("primaryCategory", "in", primaryCategory)
    );

    let querySnapshot = await getDocs(qry);

    // If no data found, return
    if (querySnapshot.empty) {
      return;
    }

    querySnapshot.forEach(async (doc) => {
      // Fetch data from the document
      let data = doc.data();
      let date = data.date;
      let emissions = data.emissions;

      // Replace the day with 01
      let newDate = date.slice(0, 8) + "01";
      // console.log("newDate", newDate);

      // Look for a document in GroupedUnifiedData/businessId/Groups with the same date, primaryCategory, secondaryCategory, scope, and dataSource
      let groupedDataQuery = query(
        groupedDataCollection,
        where("primaryCategory", "in", primaryCategory),
        where("scope", "==", data.scope),
        where("secondaryCategory", "==", data.secondaryCategory),
        where("date", "==", newDate),
        where("dataSource", "==", "Form"),
        where("officeId", "==", data.officeId)
      );

      // Execute the query and get the result
      const groupedDataSnapshot = await getDocs(groupedDataQuery);

      // Check if there are any documents in the groupedDataQuery result
      if (groupedDataSnapshot.docs.length > 0) {
        let emissionToBeSubtracted = emissions;
        if (data.dataSource === "Form") {
          //skip dividing by 1000 if the primaryCategory is Waste or Paper or Water
          if (
            data.primaryCategory !== "Waste" &&
            data.primaryCategory !== "Paper" &&
            data.primaryCategory !== "Water"
          ) {
            emissionToBeSubtracted = emissions / 1000;
          }
        }
        // Update the existing document with the decrement
        const totalEmissionsAfterDecrement =
          groupedDataSnapshot.docs[0].data().totalEmissions -
          emissionToBeSubtracted;

        if (totalEmissionsAfterDecrement <= 0) {
          // If totalEmissions becomes 0 after decrement, delete the document from GroupedUnifiedData
          await deleteDoc(groupedDataSnapshot.docs[0].ref);
        } else {
          // Update the existing document with the decrement
          await updateDoc(groupedDataSnapshot.docs[0].ref, {
            totalEmissions: increment(-emissionToBeSubtracted),
          });
        }
      } else {
        // Handle the case where there's no corresponding document in GroupedUnifiedData
        // You may log a warning or perform other logic based on your requirements
        console.warn(
          `No corresponding document in GroupedUnifiedData for ${doc.id}`
        );
      }

      await deleteDoc(doc.ref); // Add delete operation to the batch
    });
  };

  const handleDelete = async () => {
    setShowDeleteQuestionModal(false);
    setShowDeleteLoadingModal(true);

    let selectedForms = [];
    //for each selected row id from object get the form data

    for (let key in selectedRowIds) {
      let form = data[key];

      if (form.id) {
        // Single ID case
        selectedForms.push({
          id: form.id,
          category: form.category,
        });
      } else if (form.ids && Array.isArray(form.ids)) {
        // Multiple IDs case
        form.ids.forEach((id) => {
          selectedForms.push({
            id: id,
            category: form.category,
          });
        });
      }
    }

    //delete selected forms

    //from firebase
    for (let i = 0; i < selectedForms.length; i++) {
      await deleteFromFirebase(selectedForms[i].id, selectedForms[i].category);
    }
    //from database
    await deleteSelectedForms(selectedForms);

    await removeUnifiedDateFromStore();
    await fetchDashboardDataFromFirebaseByBusinessId(
      activeUser.businessId,
      activeUser.currentStartDate,
      activeUser.currentEndDate
    );
    //to initiate chart data calculation
    sessionStorage.setItem("dashboardDataCalCompleted", false);

    setShowDeleteLoadingModal(false);
    handleClose(true);
  };

  const handleAddNewForm = () => {
    //Navigate to calculators
    if (name === "all") {
      navigate("/welcome/data/calculations");
    }
    //Navigate to waste form
    if (name === "waste") {
      navigate("/welcome/data/climate-active/waste-form");
    }
    //Navigate to paper form
    if (name === "paper") {
      navigate("/welcome/data/climate-active/paper-form");
    }
    //Navigate to flight form
    if (name === "flight") {
      navigate("/welcome/data/climate-active/flights-form");
    }
    //Navigate to staff form
    if (name === "staff") {
      navigate("/welcome/data/climate-active/staff-form");
    }
    //Navigate to wfh form
    if (name === "wfh") {
      navigate("/welcome/data/wfh");
    }
    //Navigate to water form
    if (name === "water") {
      navigate("/welcome/data/water");
    }
    //Navigate to employeeEngagement form
    if (name === "employee engagement") {
      navigate("/welcome/data/employee-engagement");
    }
  };

  const handleDeleteFile = () => {
    if (isDataFrozen()) {
      setShowFrozenDataModal(true);
      return;
    }
    setShowDeleteQuestionModal(true);
  };

  const handleNewFile = () => {
    if (isDataFrozen()) {
      setShowFrozenDataModal(true);
      return;
    }
    handleAddNewForm();
  };

  return (
    <>
      {showFrozenDataModal && (
        <FrozenDataModal
          show={showFrozenDataModal}
          onHide={() => {
            setShowFrozenDataModal(false);
          }}
        />
      )}
      {showDeleteQuestionModal && (
        <DeleteQuestionModal
          showDeleteQuestionModal={showDeleteQuestionModal}
          setShowDeleteQuestionModal={setShowDeleteQuestionModal}
          handleDelete={handleDelete}
        />
      )}
      {showDeleteLoadingModal && <DeleteLoadingModal />}
      <Row className="flex-between-center">
        <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
          <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
            View {name === "flight" ? "flights" : name}{" "}
            {name === "flight" ? "" : "forms"}
          </h5>
        </Col>
        <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
          {Object.keys(selectedRowIds).length > 0 ? (
            <div className="d-flex">
              <h5 className="fs-0 mb-0 text-nowrap py-2">
                {Object.keys(selectedRowIds).length >= 0
                  ? "You have selected " +
                    Object.keys(selectedRowIds).length +
                    " file(s)"
                  : "Selection Example"}
              </h5>
              <Button
                type="button"
                variant="danger"
                size="sm"
                className="ms-2"
                onClick={() => {
                  handleDeleteFile();
                }}
                disabled={Object.keys(selectedRowIds).length === 0}
              >
                Delete
              </Button>
            </div>
          ) : (
            <div id="orders-actions">
              <IconButton
                variant="warning"
                size="sm"
                icon={faPlus}
                transform="shrink-3"
                onClick={handleNewFile}
              >
                <span className="d-none d-sm-inline-block ms-1">New</span>
              </IconButton>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

AllFormsTableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
};

//Ask user to confirm before deleting
const DeleteQuestionModal = ({
  showDeleteQuestionModal,
  setShowDeleteQuestionModal,
  handleDelete,
}) => {
  return (
    <Modal
      show={showDeleteQuestionModal}
      onHide={() => setShowDeleteQuestionModal(false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">Delete Selected Files</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete the selected files?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={() => setShowDeleteQuestionModal(false)}
        >
          Cancel
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

//Show loading modal while deleting
const DeleteLoadingModal = () => {
  return (
    <Modal show={true} centered>
      <Modal.Body>
        <div className="text-center">
          <Spinner animation="border" variant="primary" />
          <p className="mt-2">Deleting selected files...</p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AllFormsTableHeader;
