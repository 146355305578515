import React from "react";
import PropTypes from "prop-types";
import { Button, Form, InputGroup } from "react-bootstrap";
import classNames from "classnames";

const PortfolioPercentageController = ({
  percentage,
  handlePercentageChange,
  handleIncrease,
  handleDecrease,
  btnClassName,
}) => {
  return (
    <InputGroup size="sm" className="w-100">
      <Button
        variant="outline-secondary"
        size="sm"
        className={classNames(btnClassName, "border-300")}
        onClick={handleDecrease}
      >
        -
      </Button>
      <Form.Control
        className="text-center input-spin-none"
        type="number"
        min="0"
        max="100"
        value={Math.round(percentage)}
        onChange={handlePercentageChange}
      />
      <Button
        variant="outline-secondary"
        size="sm"
        className={classNames(btnClassName, "border-300")}
        onClick={handleIncrease}
      >
        +
      </Button>
    </InputGroup>
  );
};

PortfolioPercentageController.propTypes = {
  percentage: PropTypes.number.isRequired,
  handlePercentageChange: PropTypes.func.isRequired,
  handleIncrease: PropTypes.func.isRequired,
  handleDecrease: PropTypes.func.isRequired,
  btnClassName: PropTypes.string,
};

export default PortfolioPercentageController;
