export const errorMessage = {
  //'auth/wrong-password': 'The password is invalid or the user does not have a password.',
  "auth/wrong-password":
    "Your credentials already exist. Try to login using other methods",
  "auth/user-not-found":
    "There is no user record corresponding to this identifier. The user may have been deleted.",
  "auth/email-already-in-use":
    "There already exists an account with the given email address.",
  "auth/invalid-email": "The email address is not valid.",
  "auth/weak-password": "Password should be at least 6 characters",
};
//https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#signinwithpopup
