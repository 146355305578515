import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useAuth } from "../../../Contexts/AuthContext";
import { faGear } from "@fortawesome/free-solid-svg-icons";

const ProfileDropdown = () => {
  const [error, setError] = useState("");
  const { currentUser, logout } = useAuth();

  async function handleLogout() {
    setError("");
    try {
      await logout().then(() => {
        sessionStorage.clear();
        //to preserve 'skipProjectTour' value in localStorage
        const keyToPreserve = "skipProjectTour";

        // Iterate through localStorage keys
        for (let i = 0; i < localStorage.length; i++) {
          const key = localStorage.key(i);

          // Check if the key is the one to preserve
          if (key !== keyToPreserve) {
            localStorage.removeItem(key);
          }
        }
        window.location.href = "/";
      });
    } catch {
      setError("Failed to log out");
    }
  }
  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        className="px-2 theme-control-toggle"
      >
        <div className="theme-control-toggle-label tour-style-profile">
          <FontAwesomeIcon icon={faGear} className="fs-0" />
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          {/* <Dropdown.Item className="fw-bold text-warning" href="#!">
            <FontAwesomeIcon icon="crown" className="me-1" />
            <span>Go Pro</span>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item href="#!">Set status</Dropdown.Item>
          <Dropdown.Item as={Link} to="/user/profile">
            Profile &amp; account
          </Dropdown.Item>
          <Dropdown.Item href="#!">Feedback</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to="/pages/settings">
            Settings
          </Dropdown.Item> */}
          {/* <Dropdown.Item as={Link} to={handleLogout}>
            Logout
          </Dropdown.Item> */}
          <Dropdown.Item as={Link} to="/welcome/account">
            Account
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/welcome/getting-started">
            Getting Started
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/welcome/FAQs">
            FAQs
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/welcome/inviteUser">
            Invite User
          </Dropdown.Item>
          <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
