import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import NewOverviewCard from "../new-overview/newOverviewCard";
import { fa1, fa2 } from "@fortawesome/free-solid-svg-icons";

function UploadDataModal({ showSpendDataModal, toggleSpendDataModal }) {
  return (
    <Modal
      show={showSpendDataModal}
      onHide={toggleSpendDataModal}
      size="xl"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Spend Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-2">
          <Col>
            <NewOverviewCard
              title="Upload expenses in CSV"
              descriptionIncompleted="Upload expense data in CSV format. You can download relevant reports from your accounting system or with the help of your finance team"
              descriptionCompleted="You have successfully uploaded expense data. Feel free to add more data or edit the existing data"
              buttonTextIncompleted="Upload Expenses"
              buttonTextCompleted="Expenses Eploaded"
              isCompleted={false}
              link="/welcome/data/file-uploader"
              icon={fa1}
            />
          </Col>
          <Col>
            <NewOverviewCard
              title="Integrate with accounting software"
              descriptionIncompleted="Use one of our read-only integrations to bring your expense data to NetNada"
              descriptionCompleted="You have successfully integrated your accounting software with NetNada"
              buttonTextIncompleted="Integrate"
              buttonTextCompleted="Successfully Integrated"
              isCompleted={false}
              link="/welcome/integrations"
              icon={fa2}
              tutorialLink="https://www.netnada.com.au/documentation/connecting-your-accounting-software"
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={toggleSpendDataModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UploadDataModal;
