import React from "react";
import BusinessDetailsWizardInput from "../BusinessDetailWizard/BusinessDetailsWizardInput";

function Screen1({ register, errors, year }) {
  return (
    <>
      <BusinessDetailsWizardInput
        label={`What is the exact number of employees in your organization during ${year}?`}
        name="numberOfEmployees"
        type="number"
        placeholder="Enter the number of employees"
        errors={errors}
        formGroupProps={{ className: "mb-3" }}
        formControlProps={{
          ...register("numberOfEmployees", {
            // required: "This field is required",
            min: { value: 1, message: "Must be at least 1" },
          }),
          className: "w-25",
        }}
      />

      <BusinessDetailsWizardInput
        label={`What is your organisation's annual gross revenue for ${year}?`}
        subText="Providing your annual revenue helps us calculate an emission factor for
        your company. This information is optional."
        name="annualRevenue"
        type="number"
        placeholder="Enter the annual revenue in dollars"
        errors={errors}
        formGroupProps={{ className: "mb-3" }}
        formControlProps={{
          ...register("annualRevenue", {
            // required: "This field is required",
            min: { value: 0, message: "Must be a positive number" },
          }),
          className: "w-25",
        }}
      />
    </>
  );
}

export default Screen1;
