import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";

const Collapsible = ({ open, submitted, children }) => {
  const [isOpen, setIsOpen] = useState(open);

  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    setIsOpen(submitted ? false : open);
  }, [submitted]);

  return (
    <>
      <div>
        <div className="card">
          <div className="p-3 border-bottom d-flex justify-content-between">
            <h4 className="font-weight-bold">
              <span>
                Your Event Details
                {submitted && (
                  <span>
                    <FontAwesomeIcon
                      size="lg"
                      icon={faCircleCheck}
                      style={{ color: "#31af4b", marginLeft: "5px" }}
                    />
                  </span>
                )}
              </span>
            </h4>

            {/* {submitted && (
              <span>
                Submitted
                <span>
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ color: "#2eb25c" }}
                  />
                  <FontAwesomeIcon
                    size="lg"
                    icon={faCircleCheck}
                    style={{ color: "#31af4b" }}
                  />
                </span>
              </span>
            )} */}
            {/* <FontAwesomeIcon
              size="lg"
              icon={faCircleCheck}
              style={{ color: "#31af4b" }}
            /> */}
            <button type="button" className="btn" onClick={handleFilterOpening}>
              {!isOpen ? (
                <FontAwesomeIcon icon={faChevronDown} />
              ) : (
                <FontAwesomeIcon icon={faChevronUp} />
              )}
            </button>
          </div>
        </div>

        <div className="border-bottom">
          <div>{isOpen && <div className="p-3">{children}</div>}</div>
        </div>
      </div>
    </>
  );
};

export default Collapsible;
