import {
  FaRecycle,
  FaTrash,
  FaIndustry,
  FaBuilding,
  FaAppleAlt,
  FaTree,
  FaTshirt,
  FaBiohazard,
  FaToilet,
  FaBaby,
  FaGavel,
  FaGlassMartiniAlt,
  FaBoxOpen,
} from "react-icons/fa";
import { GiWoodBeam } from "react-icons/gi";
export const wasteTypes = [
  {
    label: "General Waste",
    value: "General waste",
    icon: <FaTrash />,
    density: 0.36,
    color: "#FF0000", // Bright Red for General Waste
  },
  {
    label: "Commercial and Industrial Waste",
    value: "Commercial and Industrial Waste",
    icon: <FaIndustry />,
    density: 0.33,
    color: "#009688", // Teal
  },
  {
    label: "Construction and Demolition Waste",
    value: "Construction and demolition waste",
    icon: <FaBuilding />,
    density: 0.39,
    color: "#FFA726", // Strong Orange
  },
  {
    label: "Organics (Food)",
    value: "Food",
    icon: <FaAppleAlt />,
    density: 0.5,
    color: "#4CAF50", // Darker Green for Food Waste
  },
  {
    label: "Paper & Cardboard",
    value: "Paper and cardboard",
    icon: <FaBoxOpen />,
    density: 0.09,
    color: "#1E90FF", // Standard Blue for Paper & Cardboard
  },
  {
    label: "Garden & Green Materials",
    value: "Garden and green",
    icon: <FaTree />,
    density: 0.24,
    color: "#4CAF50", // Darker Green for Green Waste
  },
  {
    label: "Wood & Timber",
    value: "Wood",
    icon: <GiWoodBeam />,
    density: 0.15,
    color: "#795548", // Brown for Wood
  },
  {
    label: "Textiles",
    value: "Textiles",
    icon: <FaTshirt />,
    density: 0.14,
    color: "#B3E5FC", // Very Soft Blue for Textiles
  },
  {
    label: "Sludge",
    value: "Sludge",
    icon: <FaBiohazard />,
    density: 0.72,
    color: "#9E9E9E", // Grey
  },
  {
    label: "Sanitary Waste",
    value: "Sanitary and nappies",
    icon: <FaToilet />,
    density: 0.39,
    color: "#FFC0CB", // Soft Pink
  },
  {
    label: "Nappies",
    value: "Sanitary and nappies",
    icon: <FaBaby />,
    density: 0.39,
    color: "#FFAB91", // Coral for Nappies
  },
  {
    label: "Rubber and Leather",
    value: "Rubber and leather",
    icon: <FaGavel />,
    density: 0.14,
    color: "#5D4037", // Dark Brown
  },
  {
    label: "Mixed Recycling (Co-mingled Recyclables)",
    value: "Recycling",
    icon: <FaRecycle />,
    density: 0.06,
    color: "#FFD600", // Bright Yellow, distinctly for Mixed Recycling
  },
  {
    label: "Glass",
    value: "Glass",
    icon: <FaGlassMartiniAlt />,
    density: 2.3,
    color: "#673AB7", // Strong Purple
  },
];
export const binVolumes = [
  {
    label: "240-litre wheelie bin",
    value: "240",
  },
  {
    label: "660-litre wheelie bin sizes",
    value: "660",
  },
];
// const SOURCE_WASTE_DENSITY_tonPerm3 = {
// municipal: 0.36,
// "construction and demolition": 0.39,
// "commercial and Industrial": 0.33,
// recycling: 0.06,
// paper: 0.09,
// "sanitary and nappies": 0.39,
// "green and organic": 0.24,
// food: 0.5,
// wood: 0.15,
// textiles: 0.14,
// sludge: 0.72,
// "rubber and leather": 0.14,
// inert: 0.42,
// };
