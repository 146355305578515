import { Container, Row, Col, Card, Placeholder } from "react-bootstrap";

function PageLoading() {
  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <h2 className="placeholder-glow">
              <Placeholder as="span" animation="glow">
                <Placeholder xs={8} />
              </Placeholder>
            </h2>

            <p className="placeholder-glow">
              <Placeholder as="span" animation="glow">
                <Placeholder xs={6} />
              </Placeholder>

              <Placeholder as="span" animation="glow">
                <Placeholder xs={7} />
              </Placeholder>

              <Placeholder as="span" animation="glow">
                <Placeholder xs={5} />
              </Placeholder>

              <Placeholder as="span" animation="glow">
                <Placeholder xs={4} />
              </Placeholder>
            </p>

            <p className="placeholder-glow">
              <Placeholder as="span" animation="glow">
                <Placeholder xs={5} />
              </Placeholder>

              <Placeholder as="span" animation="glow">
                <Placeholder xs={6} />
              </Placeholder>

              <Placeholder as="span" animation="glow">
                <Placeholder xs={8} />
              </Placeholder>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default PageLoading;
