import { useEffect, useState } from "react";
import OverviewCard from "./newOverviewCard";
import { Row, Col, Button, Form, Modal, Spinner } from "react-bootstrap";
import {
  fa1,
  fa2,
  fa3,
  faChessRook,
  faCrown,
  faVideo,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import {
  addMeasurementYear,
  addEmissionBoundariesStatus,
  getEmployeeCount,
  getUserBusinessLocations,
  getStepsProgressByInventoryId,
} from "./newOverviewAPI";
import {
  getActiveUserData,
  getInventoryProgress,
  getWhiteLabel,
  setActiveUserData,
  setInventoryProgress,
  setStepsProgress,
} from "../../helpers/store";
import { useAuth } from "../../Contexts/AuthContext";
import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
import {
  collection,
  getDocs,
  getFirestore,
  limit,
  query,
  where,
} from "firebase/firestore";
import app from "../../firebase";
import {
  createContentImageAPI,
  getBusinessContent,
} from "../content/contentApi";
import WelcomeModal from "./WelcomeModal";
import WelcomeBanner from "./welcomeBanner";
import PageHeader from "../../components/common/PageHeader";
import { ToastContainer, toast } from "react-toastify";
import {
  fetchDashboardDataFromFirebaseByBusinessId,
  removeUnifiedDateFromStore,
} from "../../helpers/unifiedDataHelper";
import NewReportingPeriodModal from "./NewReportingPeriodModal";

const fireStoreDB = getFirestore(app);

const NewOverview = () => {
  const { currentUser } = useAuth();
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const [measurementYearSet, setMeasurementYearSet] = useState(
    activeUser.measurementYearSet
  );
  const [showToSetMeasurementYearModal, setShowToSetMeasurementYearModal] =
    useState(false);
  const [measurementYear, setMeasurementYear] = useState(
    activeUser.selectedMeasurementYear +
      "-" +
      activeUser.selectedMeasurementYearType
  );

  const [currentInventoryProgress, setCurrentInventoryProgress] = useState(
    JSON.parse(getInventoryProgress())
  );
  const [isLoadingInventoryProgress, setIsLoadingInventoryProgress] =
    useState(false);
  const [showSpendDataModal, setShowSpendDataModal] = useState(false);
  const [showActivityDataModal, setShowActivityDataModal] = useState(false);
  const [emissionsBoundariesSet, setEmissionsBoundariesSet] = useState(
    activeUser.emissionBoundariesSet
  );

  const [isLoading, setIsLoading] = useState(false);

  const toggleSpendDataModal = () => {
    setShowSpendDataModal(!showSpendDataModal);
  };

  const toggleActivityDataModal = () => {
    setShowActivityDataModal(!showActivityDataModal);
  };

  const toggleToSetMeasurementYearModal = () => {
    setShowToSetMeasurementYearModal(!showToSetMeasurementYearModal);
  };

  const onMeasurementYearSelection = async (reportingYear) => {
    setIsLoading(true);
    const year = reportingYear.split("-")[0];
    const yearType = reportingYear.split("-")[1];

    //update this flag to false so that unified data can be recalculated
    sessionStorage.setItem("dashboardDataCalCompleted", false);

    setMeasurementYear(reportingYear);
    //get inventory progress for the selected year
    setIsLoadingInventoryProgress(true);

    const inventoryProgress = JSON.parse(getInventoryProgress());
    const selectedInventoryProgress = inventoryProgress.filter(
      (item) =>
        item.measurementyear === year && item.measurementyeartype === yearType
    );

    //update user data
    const activeUser = JSON.parse(getActiveUserData());
    activeUser.inventoryId = selectedInventoryProgress[0].inventoryid;
    activeUser.selectedMeasurementYear =
      selectedInventoryProgress[0].measurementyear;
    activeUser.selectedMeasurementYearType =
      selectedInventoryProgress[0].measurementyeartype;
    activeUser.currentStartDate = selectedInventoryProgress[0].currentStartDate;
    activeUser.currentEndDate = selectedInventoryProgress[0].currentEndDate;
    activeUser.emissionBoundariesSet =
      selectedInventoryProgress[0].emissionBoundariesSet;
    activeUser.dateRangeStart = selectedInventoryProgress[0].dateRangeStart;
    activeUser.dateRangeEnd = selectedInventoryProgress[0].dateRangeEnd;
    activeUser.isFrozen = selectedInventoryProgress[0].isFrozen;
    activeUser.showPreviousYear = selectedInventoryProgress[0].showPreviousYear;
    activeUser.totalEmissions = selectedInventoryProgress[0].totalEmissions;

    sessionStorage.setItem("dashboardDataCalCompleted", false);

    setActiveUserData(JSON.stringify(activeUser));

    // Fetch steps_progress data for the selected inventoryId

    try {
      const stepsProgressData = await getStepsProgressByInventoryId(
        activeUser.inventoryId
      );
      console.log("stepsProgressData", stepsProgressData);
      // Store it in local storage
      setStepsProgress(JSON.stringify(stepsProgressData));
    } catch (error) {
      console.error("Error fetching steps progress data:", error);
      // Handle the error as needed, e.g., show a notification to the user
    }

    await removeUnifiedDateFromStore();
    await fetchDashboardDataFromFirebaseByBusinessId(
      activeUser.businessId,
      activeUser.currentStartDate,
      activeUser.currentEndDate
    );
    setEmissionsBoundariesSet(
      selectedInventoryProgress[0].emissionBoundariesSet
    );

    setIsLoadingInventoryProgress(false);
    setIsLoading(false);
    toast("Reporting year changed!", { type: "success" });
  };
  // text and links for welcome banner
  const dataSample = [
    {
      title: "NetNada Academy",
      text: "Learn about sustainability and how to use NetNada",
      icon: faChessRook,
      color: "primary",
      link: "https://www.netnada.com.au/help",
    },
    {
      title: "Engage your supply chain",
      text: "Understand who you buy from and improve your sustainability",
      icon: faCrown,
      color: "warning",
      link: "mailto:support@netnada.com.au?subject=We%20are%20ready%20to%20engage%20our%20suppliers!&body=We%20understand%20that%20supplier%20engagement%20is%20an%20important%20step%20in%20any%20sustainability%20journey.%20We%20would%20love%20your%20help%20to%20better%20understand%20our%20supply%20chain%20and%20what%20we%20can%20do%20to%20improve%20it.%20If%20you%20can%20get%20back%20to%20me%20with%20a%20time%20to%20meet%20and%20discuss%20that%20would%20be%20ideal.%20Thank%20you!",
    },
    {
      title: "Book a meeting",
      text: "Need help? Reach out to us",
      icon: faVideo,
      color: "success",
      link: "https://calendly.com/netnada/customer-support?month=2023-11",
    },
    {
      title: "Invite your team",
      text: "Teamwork makes the dream work!",
      icon: faUser,
      color: "info",
      link: "/welcome/inviteUser",
    },
  ];

  const spendDataModal = (
    <Modal
      show={showSpendDataModal}
      onHide={toggleSpendDataModal}
      size="xl"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Spend Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-2">
          <Col>
            <OverviewCard
              title="Upload expenses in CSV"
              descriptionIncompleted="Upload expense data in CSV format. You can download relevant reports from your accounting system or with the help of your finance team"
              descriptionCompleted="You have successfully uploaded expense data. Feel free to add more data or edit the existing data"
              buttonTextIncompleted="Upload Expenses"
              buttonTextCompleted="Expenses Eploaded"
              isCompleted={false}
              link="/welcome/data/file-uploader"
              icon={fa1}
            />
          </Col>
          <Col>
            <OverviewCard
              title="Integrate with accounting software"
              descriptionIncompleted="Use one of our read-only integrations to bring your expense data to NetNada"
              descriptionCompleted="You have successfully integrated your accounting software with NetNada"
              buttonTextIncompleted="Integrate"
              buttonTextCompleted="Successfully Integrated"
              isCompleted={false}
              link="/welcome/integrations"
              icon={fa2}
              tutorialLink="https://www.netnada.com.au/documentation/connecting-your-accounting-software"
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={toggleSpendDataModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const activityDataModal = (
    <Modal
      show={showActivityDataModal}
      onHide={toggleActivityDataModal}
      size="xl"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Add activity data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-2">
          <Col>
            <OverviewCard
              title="Upload Invoices"
              descriptionIncompleted="Add invoices from your energy provider and we will calculate emissions based on your consumption"
              descriptionCompleted="Invoices uploaded successfully. Feel free to add more data or edit the existing data"
              buttonTextIncompleted="Upload Invoices "
              buttonTextCompleted="Invoices Added"
              isCompleted={false}
              link="/welcome/data/file-uploader"
              icon={fa1}
              tutorialLink="https://www.netnada.com.au/documentation/adding-invoices-to-activity-data"
            />
          </Col>
          <Col>
            <OverviewCard
              title="Emissions Calculators"
              descriptionIncompleted="Calculate emissions with our easy-to-use calculators for flights, waste, WFH, events, and employee commuting"
              descriptionCompleted="Successfully calculated emissions. Feel free to add more data or edit the existing data"
              buttonTextIncompleted="Emission Calculators"
              buttonTextCompleted="Emissions Added"
              isCompleted={false}
              link="/welcome/data/calculations"
              icon={fa2}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={toggleActivityDataModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );

  async function toSetEmissionBoundariesStatus(checked) {
    const body = { checked };
    try {
      const response = await addEmissionBoundariesStatus(body);

      if (response.status === "success") {
        const activeUser = JSON.parse(getActiveUserData());
        activeUser.emissionBoundariesSet = checked;

        setActiveUserData(JSON.stringify(activeUser));
        toast("Emission boundaries status updated!", { type: "success" });
      }
    } catch (error) {
      console.error("Failed to set emission boundaries status:", error);
    }
  }

  async function toAddMeasurementYear(
    measurementYear,
    measurementType,
    newUser
  ) {
    // Set loading state if needed
    // When I add a new measurement year I should also copy the company details
    const existingInventoryProgress = JSON.parse(getInventoryProgress()) || [];
    const isDuplicate = existingInventoryProgress.some(
      (inv) =>
        inv.measurementyear === measurementYear &&
        inv.measurementyeartype === measurementType
    );

    if (!isDuplicate) {
      const body = {
        measurementYear,
        measurementType,
        newUser,
      };
      const response = await addMeasurementYear(body);
      const inventoryProgress = response.data.inventoryProgress;

      if (response.status === "success") {
        // Update user data
        const activeUser = JSON.parse(getActiveUserData());
        activeUser.inventoryId = inventoryProgress.inventoryid;
        activeUser.measurementYearSet = true;
        activeUser.selectedMeasurementYear =
          inventoryProgress.selectedMeasurementYear;
        activeUser.currentStartDate = inventoryProgress.currentStartDate;
        activeUser.currentEndDate = inventoryProgress.currentEndDate;
        activeUser.dateRangeStart = inventoryProgress.dateRangeStart;
        activeUser.dateRangeEnd = inventoryProgress.dateRangeEnd;
        setActiveUserData(JSON.stringify(activeUser));
        setEmissionsBoundariesSet(false);

        // If setting reporting year first time
        if (!measurementYearSet) {
          // Update state
          setCurrentInventoryProgress([inventoryProgress]);
          // Update inventory progress
          setInventoryProgress(JSON.stringify([inventoryProgress]));
        } else {
          // If setting consecutive reporting year
          // Update state
          setCurrentInventoryProgress((prevState) => [
            inventoryProgress,
            ...prevState,
          ]);
          // Update inventory progress
          const inventoryProgressList = JSON.parse(getInventoryProgress());
          inventoryProgressList.unshift(inventoryProgress);
          setInventoryProgress(JSON.stringify(inventoryProgressList));
        }

        // Update flag to recalculate unified data
        setMeasurementYearSet(true);
        sessionStorage.setItem("dashboardDataCalCompleted", false);

        toast("Welcome to new reporting year!", { type: "success" });
      } else {
        toast("Error adding measurement year", { type: "error" });
      }
    } else {
      toast("Measurement year already exists!", { type: "error" });
    }
  }

  const [business] = useState(activeUser.businessId);
  const [offices, setOffices] = useState(null);

  useEffect(() => {
    const analytics = getAnalytics();
    setUserId(analytics, currentUser.uid);
    setUserProperties(analytics, { businessId: currentUser.businessId });
    logEvent(analytics, "page_view", {
      page_title: "Overview",
    });
  }, [currentUser]);

  const [showWelcomeModal] = useState(
    activeUser.authProvider === "MYOB" || activeUser.authProvider === "Xero"
      ? activeUser.processingStatus === "InProgress"
        ? sessionStorage.getItem("showModal")
          ? false
          : true
        : false
      : false
  );

  //check if unified data is present for the current business
  const checkUnifiedDataExistence = async () => {
    const qry = query(
      collection(fireStoreDB, "UnifiedData", business, "DataLines"),
      limit(1)
    );

    const querySnapshot = await getDocs(qry);
    return !querySnapshot.empty;
  };

  const checkUnifiedDataCSV = async () => {
    const qry = query(
      collection(fireStoreDB, "UnifiedData", business, "DataLines"),
      where("dataSource", "==", "CSV"),
      limit(1)
    );

    const querySnapshot = await getDocs(qry);
    return !querySnapshot.empty;
  };

  const checkUnifiedData = async () => {
    try {
      var csvPresent = await checkUnifiedDataCSV();
      var unifiedDataCalCompleted = false;

      if (csvPresent) {
        unifiedDataCalCompleted = true;
      } else {
        unifiedDataCalCompleted = await checkUnifiedDataExistence();
      }
      // // Parallel execution
      // const [unifiedDataCalCompleted, csvPresent] = await Promise.all([
      //   checkUnifiedDataExistence(),
      //   checkUnifiedDataCSV(),
      // ]);

      sessionStorage.setItem(
        "unifiedDataCalCompleted",
        unifiedDataCalCompleted
      );
      sessionStorage.setItem("csvPresent", csvPresent);
    } catch (error) {
      console.error("Error checking unified data:", error);
      throw error;
    }
  };

  async function createContentImage() {
    const body = {
      requestedBy: currentUser.uid,
      contentType: "Milestone",
      title: `Welcome to ${getWhiteLabel().name}`,
      description: `Welcome to ${getWhiteLabel().name}`,
      imageName: "welcome.jpg",
      modifications: {
        companyName: currentUser.businessName,
        whitelabelName: getWhiteLabel().name,
      },
    };
    const response = await createContentImageAPI(body);
    //console.log("response", response);
  }
  //check if a content image has been uploaded
  const checkContentImageUploads = async () => {
    try {
      const response = await getBusinessContent();

      if (!response) {
        createContentImage();
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  async function getBusinessOffice() {
    const response = await getUserBusinessLocations();

    //store all offices id in session as string
    let offices = [];
    for (let i in response) offices.push(response[i]["office_id"]);
    sessionStorage.setItem("officeId", JSON.stringify(offices));

    //store all location w.r.t to office id in session as string
    let locations = [];
    for (let i in response) locations.push(response[i]["location"]);
    sessionStorage.setItem("location", JSON.stringify(locations));

    //store all officeId,officeName in session as string
    let officeNameId = [];
    for (let i in response)
      officeNameId.push(
        response[i]["officename"] + "," + response[i]["office_id"]
      );
    sessionStorage.setItem("officeNameId", JSON.stringify(officeNameId));

    //find offices under same businessId
    let businessOffices = [];
    for (let i in response)
      businessOffices.push(
        response[i]["officename"] + "," + response[i]["office_id"]
      );
    sessionStorage.setItem("businessOffices", JSON.stringify(businessOffices));

    //store all office names in session as string
    let officeName = [];
    for (let i in response) officeName.push(response[i]["officename"]);
    sessionStorage.setItem("officeName", JSON.stringify(officeName));

    // Set all business locations in session
    sessionStorage.setItem("userBusinessLocations", JSON.stringify(response));

    //store officeId in state
    setOffices(offices);
  }

  async function get() {
    setIsLoading(true);
    //to check if registration is by
    if (sessionStorage.getItem("xero") !== undefined) {
      sessionStorage.setItem("xero", "true");
    }

    //if 'dashboardDataCalCompleted' is not present in session, then initialize it to false
    if (
      sessionStorage.getItem("dashboardDataCalCompleted") === undefined ||
      !sessionStorage.getItem("dashboardDataCalCompleted")
    ) {
      console.log("dashboardDataCalCompleted is not present in session");
      sessionStorage.setItem("dashboardDataCalCompleted", false);
    }

    // await getBusinessOffice();

    await checkUnifiedData();

    await removeUnifiedDateFromStore();

    await fetchDashboardDataFromFirebaseByBusinessId(
      activeUser.businessId,
      activeUser.currentStartDate,
      activeUser.currentEndDate
    );
    setIsLoading(false);
    await checkContentImageUploads();

    const userRole = activeUser.role;
    if (userRole !== "EMPLOYEE") {
      const employeeCount = await getEmployeeCount();
      sessionStorage.setItem("employeeCount", employeeCount);
    }
  }

  useEffect(() => {
    getBusinessOffice();
    if (!measurementYearSet) return;
    //if 'unifiedDataCalCompleted' is not present in session
    const unifiedDataCalCompleted = sessionStorage.getItem(
      "unifiedDataCalCompleted"
    );

    if (unifiedDataCalCompleted) {
      return;
    }
    get();
  }, [measurementYearSet]);

  //render loading modal while session is being set
  const isLoadingModal = (
    <Modal show={isLoading} size="sm" centered>
      <Modal.Body>
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <div className="ms-3">
            <span className="small">
              Please wait while we set your profile.
            </span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );

  return (
    <>
      {isLoadingModal}
      {showWelcomeModal && !isLoading ? <WelcomeModal /> : null}
      <Row></Row>
      <WelcomeBanner
        data={dataSample}
        measurementYear={measurementYear}
        currentInventoryProgress={currentInventoryProgress}
        toggleToSetMeasurementYearModal={toggleToSetMeasurementYearModal}
        onMeasurementYearSelection={onMeasurementYearSelection}
        isLoadingInventoryProgress={isLoadingInventoryProgress}
      />
      <br />
      <Row className="mb-2">
        <Col>
          {/* <WidgetSectionTitle
            icon={faSearch}
            title="Identify"
            subtitle="Set clear boundaries for business emissions and identify sources contributing to your carbon footprint. This involves understanding the full scope of emissions, including both direct and indirect sources, to establish a comprehensive baseline for measurement"
            transform="shrink-2"
            className="mb-4 mt-6"
          /> */}
          <PageHeader
            title="Identify"
            description="Set clear boundaries for business emissions and identify sources contributing to your carbon footprint. This involves understanding the full scope of emissions, including both direct and indirect sources, to establish a comprehensive baseline for measurement"
            className="mb-3"
            image="null"
          ></PageHeader>
        </Col>
      </Row>
      <Row>
        <Col style={{ flex: 1 }}>
          <OverviewCard
            className="mb-3 tour-style-overview-supply-chain-table"
            title="Reporting Year"
            descriptionIncompleted="Pick a calendar or financial year for your emissions calculations so you can track progress"
            descriptionCompleted="You have added a reporting year"
            buttonTextIncompleted="Set Reporting Year"
            buttonTextCompleted="Reporting Year Set"
            isCompleted={measurementYearSet}
            link=""
            icon={fa1}
            tutorialLink="https://www.netnada.com.au/documentation/account-sign-up"
          />
        </Col>
        <Col style={{ flex: 1 }}>
          <OverviewCard
            className="mb-3 tour-style-overview-supply-chain-table"
            title="Organisation Details"
            descriptionIncompleted="Add details such as Name, ABN,  and Relevant Locations"
            descriptionCompleted="Thank you for telling us more about your business"
            buttonTextIncompleted="Add organisation details"
            buttonTextCompleted="Details Added"
            isCompleted={
              currentInventoryProgress?.some(
                (entry) => entry.organisationdetailsadded
              ) ?? false
            }
            link="/welcome/org-details"
            icon={fa2}
            tutorialLink="https://www.netnada.com.au/documentation/adding-organisation-details-for-reporting-year"
          />
        </Col>
        <Col style={{ flex: 1 }}>
          {/* <OvervieCardInstance /> */}
          <OverviewCard
            className="mb-3 tour-style-overview-supply-chain-table"
            title="Emissions Boundaries"
            descriptionIncompleted="Select what will be included in your emissions measurement"
            descriptionCompleted="You have set your boundaries but you can edit them anytime"
            buttonTextIncompleted="Set Boundaries"
            buttonTextCompleted="Boundaries Set"
            isCompleted={emissionsBoundariesSet}
            onClick={toSetEmissionBoundariesStatus}
            link="/welcome/identify/emissions-boundaries"
            icon={fa3}
            tutorialLink="https://www.netnada.com.au/documentation/determining-emissions-boundaries"
          />
        </Col>
      </Row>
      <br />
      <br />
      <Row className="mb-2">
        <Col>
          {/* <WidgetSectionTitle
            icon={faCalculator}
            title="Measure"
            subtitle="Add relevant data from various sources and perform calculations to quantify emissions accurately. This involves tracking energy consumption, adding expenses, and using calculators to establish a quantitative foundation"
            transform="shrink-2"
            className="mb-4 mt-6"
          /> */}
          <PageHeader
            title="Measure"
            description="Add relevant data from various sources and perform calculations to quantify emissions accurately. This involves tracking energy consumption, adding expenses, and using calculators to establish a quantitative foundation"
            className="mb-3"
            image="null"
          ></PageHeader>
        </Col>
      </Row>
      <Row>
        <Col style={{ flex: 1 }}>
          <OverviewCard
            className="mb-3 tour-style-overview-supply-chain-table"
            title="Spend Data"
            descriptionIncompleted="Start your measurement by adding expenses or choosing one of our integrations"
            descriptionCompleted="You have uploaded your expenses and are ready for calculations"
            buttonTextIncompleted="Add Spend Data"
            buttonTextCompleted="Spend Data Added"
            isCompleted={false}
            link=""
            icon={fa1}
            toggleModal={toggleSpendDataModal}
          />
        </Col>
        <Col style={{ flex: 1 }}>
          <OverviewCard
            className="mb-3 tour-style-overview-supply-chain-table"
            title="Activity Data"
            descriptionIncompleted="Upload invoices such as electricity bills or use our calculators to improve your measurement"
            descriptionCompleted="You have added all the activity within your emissions boundaries"
            buttonTextIncompleted="Add Activity Data"
            buttonTextCompleted="Activity Data Added"
            isCompleted={false}
            toggleModal={toggleActivityDataModal}
            link=""
            icon={fa2}
          />
        </Col>
        <Col style={{ flex: 1 }}>
          <OverviewCard
            className="mb-3 tour-style-overview-supply-chain-table"
            title="Supply Chain"
            descriptionIncompleted="Complete your account mapping and review the emissions categories of your suppliers"
            descriptionCompleted="You reviewed all your supplier's emissions and categories"
            buttonTextIncompleted="Review Supply Chain"
            buttonTextCompleted="Suppliers Reviewed"
            isCompleted={false}
            link="/welcome/data/supply-chain"
            icon={fa3}
          />
        </Col>
      </Row>
      <br />
      <br />
      <Row className="mb-2">
        <Col>
          {/* <WidgetSectionTitle
            icon={faTree}
            title="Reduce"
            subtitle="Develop sustainability strategies to minimise emissions, from engaging suppliers to optimising energy efficiency. Offsetting emissions by investing in carbon credits is also considered to mitigate the impact"
            transform="shrink-2"
            className="mb-4 mt-6"
          /> */}
          <PageHeader
            title="Reduce"
            description="Develop sustainability strategies to minimise emissions, from engaging suppliers to optimising energy efficiency. Offsetting emissions by investing in carbon credits is also considered to mitigate the impact"
            className="mb-3"
            image="null"
          ></PageHeader>
        </Col>
      </Row>
      <Row>
        <Col>
          <OverviewCard
            className="mb-3 tour-style-overview-supply-chain-table"
            title="Reduction Strategies"
            descriptionIncompleted="Use Nettie (NetNada AI) to generate a list of strategies that fit your business in our 2-step process"
            descriptionCompleted="You have generated strategies and added them to your measurement year"
            buttonTextIncompleted="Generate Reduction Strategies"
            buttonTextCompleted="Reductions Strategies Added"
            isCompleted={false}
            link="/welcome/solutions/areas-of-improvement"
            icon={fa1}
          />
        </Col>
        <Col>
          <OverviewCard
            className="mb-3 tour-style-overview-supply-chain-table"
            title="Offsets"
            descriptionIncompleted="Offset emissions for this year. We can access a range of projects and carbon credits on your behalf without any hidden fees"
            descriptionCompleted="You have successfully picked, purchased, and retired carbon credits"
            buttonTextIncompleted="See All Projects"
            buttonTextCompleted="Carbon Credits Purchased"
            isCompleted={false}
            link=""
            icon={fa2}
          />
        </Col>
        <Col></Col>
      </Row>
      <br />
      <br />
      <Row className="mb-2">
        <Col>
          {/* <WidgetSectionTitle
            icon={faFileAlt}
            title="Report"
            subtitle="Create a compliant report that communicates the organisation's carbon reduction efforts and achievements. Align with industry standards and disclose emissions data, sustainability initiatives, and progress against reduction targets, allowing stakeholders and the public to assess the business's commitment to environmental responsibility."
            transform="shrink-2"
            className="mb-4 mt-6"
          /> */}
          <PageHeader
            title="Report"
            description="Create a compliant report that communicates the organisation's carbon reduction efforts and achievements. Align with industry standards and disclose emissions data, sustainability initiatives, and progress against reduction targets, allowing stakeholders and the public to assess the business's commitment to environmental responsibility."
            className="mb-3"
            image="null"
          ></PageHeader>
        </Col>
      </Row>
      <Row>
        <Col>
          <OverviewCard
            className="mb-3 tour-style-overview-supply-chain-table"
            title="Audit"
            descriptionIncompleted="If you are going for certification such as Climate Active you can give auditors access to your account"
            descriptionCompleted="You have invited an auditor to your account"
            buttonTextIncompleted="Invite Auditor"
            buttonTextCompleted="Audit Completed"
            isCompleted={false}
            link=""
            icon={fa1}
          />
        </Col>
        <Col>
          {/* <OvervieCardInstance /> */}
          <OverviewCard
            className="mb-3 tour-style-overview-supply-chain-table"
            title="Reports"
            descriptionIncompleted="Generate reports for this measurement year. You can generate an emissions report once you - at a minimum - have added 12 months of spend data to your account"
            descriptionCompleted="You have successfully generated a report"
            buttonTextIncompleted="Generate Report"
            buttonTextCompleted="Report Generated"
            isCompleted={false}
            link="/welcome/user-reports"
            icon={fa2}
          />
        </Col>
        <Col></Col>
      </Row>
      {spendDataModal}
      {activityDataModal}
      <NewReportingPeriodModal
        show={!measurementYearSet || showToSetMeasurementYearModal}
        onHide={!measurementYearSet ? null : toggleToSetMeasurementYearModal}
        onAddMeasurementYear={toAddMeasurementYear}
      />
      <ToastContainer />
    </>
  );
};

export default NewOverview;
