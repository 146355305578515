import React from "react";
import WidgetSectionTitle from "../../widgets/WidgetSectionTitle";
import AllEventTable from "./AllEvents/AllEventTable";
import { getAllEvents } from "./eventsAPI";
import PageHeader from "../common/PageHeader";
import { faList } from "@fortawesome/free-solid-svg-icons";
//import AllInvoiceTable from "../DataUploader/AllInvoices/AllInvoiceTable";

const Index = () => {
  const [events, setEvents] = React.useState([]);

  React.useEffect(() => {
    const getEvents = async () => {
      try {
        const jsonData = await getAllEvents();
        setEvents(jsonData);
      } catch (err) {
        console.error(err.message);
      }
    };
    getEvents();
  }, []);

  return ( 
    <>
      <PageHeader
        title="Measure your event sustainability"
        description={` Identify areas where emissions can be reduced, set goals for future, and create more sustainable events. This tool is designed to accommodate a wide variety of events in Australia. From large-scale festivals with many thousands of participants to small community events.`}
        className="mb-3"
        col={8}
      ></PageHeader>
      <WidgetSectionTitle
        icon={faList}
        title="Create or manage an existing event"
        subtitle={`Browse from the table below or click "New" to get started`}
        transform="shrink-2"
        className="mb-4 mt-4"
      />
      <div className="mb-3">
        <AllEventTable events={events} />
      </div>
    </>
  );
};

export default Index;
