import React from "react";
import WidgetSectionTitle from "../../../widgets/WidgetSectionTitle";
import { useAuth } from "../../../Contexts/AuthContext";
import { Col, Row } from "react-bootstrap";
import PageHeader from "../../common/PageHeader";
import TotalEmissionsCard from "./totalEmissionsCard";
import { faList } from "@fortawesome/free-solid-svg-icons";

const Index = ({ wfhFormDataId }) => {
  const { currentUser } = useAuth();

  return (
    <>
      <WidgetSectionTitle
        icon={faList}
        title="Dashboard"
        subtitle="WFH Dashboard"
        transform="shrink-2"
        className="mb-4 mt-6"
      />
      <PageHeader
        title="Total Emissions"
        description="The component displays total emissions"
        className="mb-3"
      ></PageHeader>
      <Row className="gx-3">
        <Col lg={12}>
          <TotalEmissionsCard
            wfhFormDataId={wfhFormDataId}
            userId={currentUser.uid}
            businessId={currentUser.businessId}
          />
        </Col>
      </Row>
    </>
  );
};

export default Index;
