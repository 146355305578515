import React, { useState, useEffect } from "react";
import OnBoardingCardLayout from "../../layouts/OnBoardingCardLayout";
import BannerImageCard from "./BannerImageCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const GettingStarted = ({ loading, imageURL }) => {
  const [loadedImageUrl, setLoadedImageUrl] = useState(null);

  // Function to download the image
  const downloadImage = () => {
    window.open(loadedImageUrl, "_blank");
  };

  useEffect(() => {
    if (!loading) {
      setLoadedImageUrl(imageURL);
    }
  }, [loading]);

  return (
    <OnBoardingCardLayout
      leftSideContent={
        <div>
          <h1 style={{ color: "#005677" }}>Getting Started</h1>
          <p style={{ color: "#333333", fontSize: "18px" }}>
            Welcome to your journey of carbon accounting! We’re excited to help
            you understand your environmental impact and guide you to a more
            sustainable future. Let’s begin by exploring the key features of our
            platform.
          </p>
          <ul style={{ color: "#333333", fontSize: "16px" }}>
            <li>Measure and track your emissions.</li>
            <li>Get insights on your data.</li>
            <li>Communicate your progress effectively.</li>
            <li>Grow with sustainability initiatives.</li>
          </ul>
        </div>
      }
      showGettingStarted={true}
      showQuestions={false}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          height: "100%",
        }}
      >
        {loading ? (
          <p>Loading your custom post...</p> // Display loading message until imageUrl is available
        ) : loadedImageUrl ? (
          <>
            <BannerImageCard
              optionText="Your Company is committed to lead the way in sustainability"
              imageUrl={loadedImageUrl}
              onClick={() => console.log("Card clicked!")}
            />
            <div
              style={{ marginTop: "20px", cursor: "pointer" }}
              onClick={downloadImage}
            >
              <FontAwesomeIcon icon={faDownload} size="2x" />
            </div>
          </>
        ) : (
          <p>No image available.</p> // Display if imageUrl is null after loading
        )}
      </div>
    </OnBoardingCardLayout>
  );
};

export default GettingStarted;
