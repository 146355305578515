import React, { useState, useEffect } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import WidgetSectionTitle from "../../../widgets/WidgetSectionTitle";
import { useAuth } from "../../../Contexts/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { find, findIndex, sumBy } from "lodash";
import { getEventDetailsById } from "../eventsAPI";
import {
  saveRegionalGroundTransport,
  getRegionalGroundTransportDataByEventId,
} from "./RegionalGroundTransportAPI";
import { faList } from "@fortawesome/free-solid-svg-icons";

const DistanceBetweenRegionalTravelRoute = require("./DistanceBetweenRegionalTravelRoute.json");

const RegionalGroundTransportForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [eventId, setEventId] = useState(location.state.id);
  const { currentUser } = useAuth();
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [eventDetails, setEventDetails] = useState();
  const [routes, setRoutes] = useState([]);

  function onCustomValueChange(selectedItem, value) {
    const newRoutes = [...routes];
    const selectedIndex = findIndex(newRoutes, function (item) {
      if (item.location === selectedItem.location) {
        return item;
      }
    });

    let newItem = { ...newRoutes[selectedIndex] };
    newItem.averageValue = value;
    newItem.total = newItem.distance * value * newItem.direction;
    newRoutes[selectedIndex] = newItem;

    setRoutes(newRoutes);
    const total = calculateTotal(newRoutes);
    eventDetails.regional_ground_transport_total = total;
    setEventDetails(eventDetails);
  }

  function onDirectionChange(selectedItem, value) {
    debugger;
    const newRoutes = [...routes];
    const selectedIndex = findIndex(newRoutes, function (item) {
      if (item.location === selectedItem.location) {
        return item;
      }
    });

    let newItem = { ...newRoutes[selectedIndex] };
    newItem.direction = value;
    newItem.total = newItem.distance * newItem.averageValue * value;
    newRoutes[selectedIndex] = newItem;

    setRoutes(newRoutes);
    const total = calculateTotal(newRoutes);
    eventDetails.regional_ground_transport_total = total;
    setEventDetails(eventDetails);
  }

  const handleActualChange = (e) => {
    const newEventDetails = { ...eventDetails };
    newEventDetails.regional_ground_transport_actual = e.target.value;
    setEventDetails(newEventDetails);
  };

  function calculateTotal(newRoutes) {
    const total = sumBy(newRoutes, "total");
    return total;
  }

  async function handleSave(status) {
    setIsLoading(true);
    try {
      await saveRegionalGroundTransport({
        eventDetails,
        routes,
        status,
      });
      toast(`Regional ground transport data saved/submitted successfully.`);
      //to initiate chart data calculation
      sessionStorage.setItem("dashboardDataCalCompleted", false);
      navigate("/welcome/dashboard/event-form", {
        state: { id: eventId },
      });
    } catch (error) {
      toast.error(error.message);
    }
    setIsLoading(false);
  }

  async function get(location) {
    setIsLoading(true);

    const eventDetails = await getEventDetailsById(location.state.id);
    if (!eventDetails) return;
    const regionalGroundTransportData =
      await getRegionalGroundTransportDataByEventId(eventDetails.id);
    const routeArray = [];
    if (regionalGroundTransportData && regionalGroundTransportData.length > 0) {
      regionalGroundTransportData.map((item) => {
        const newItem = {
          id: item.id,
          eventId: item.event_id,
          location: item.location,
          distance: item.distance,
          averageValue: item.average_value,
          direction: item.direction,
          total: item.total,
          status: item.status,
        };

        routeArray.push(newItem);
      });
    } else {
      const city = find(DistanceBetweenRegionalTravelRoute, function (item) {
        if (item.city === eventDetails.city) {
          return item;
        }
      });

      const defaultAverageValue =
        (eventDetails.perc_regional_attendees * eventDetails.num_of_people) /
        100;

      city?.routes?.map((item) => {
        const newItem = {
          id: 0,
          eventId: eventDetails.id,
          location: item.location,
          distance: item.distance,
          averageValue: defaultAverageValue,
          direction: 2,
          total: item.distance * defaultAverageValue * 2,
          status: "InProgress",
        };

        routeArray.push(newItem);
      });
      const total = calculateTotal(routeArray);
      eventDetails.regional_ground_transport_total = total;
    }

    setIsCompleted(routeArray[0]?.status == "Completed" ? true : false);
    setRoutes(routeArray);
    setEventDetails(eventDetails);
    setIsLoading(false);
  }

  useEffect(() => {
    get(location);
  }, [location]);

  return (
    <>
      {" "}
      <WidgetSectionTitle
        icon={faList}
        title="Regional Ground Transport Form"
        subtitle="Fill Regional Ground Transport form here"
        transform="shrink-2"
        className="mb-4 mt-6"
      />
      <Row>
        <Col>
          <h5>
            City <br />
          </h5>
        </Col>
        <Col>
          <h5>Distance </h5>
        </Col>
        <Col>
          {" "}
          <h5>No of People / No of Cities</h5>
        </Col>
        <Col>
          {" "}
          <h5>Direction</h5>
        </Col>
        <Col>
          {" "}
          <h5>Total</h5>
        </Col>
      </Row>
      <hr></hr>
      {routes.map((item) => (
        <>
          <Row>
            <Col>
              <b> {item.location} </b>
              <br />
              <i>
                {eventDetails?.city} - {item.location}
              </i>
            </Col>
            <Col>{item.distance}</Col>
            <Col>
              <Form.Control
                type="number"
                placeholder="Value"
                value={item.averageValue}
                disabled={isCompleted}
                onChange={(e) => onCustomValueChange(item, e.target.value)}
              />
            </Col>
            <Col>
              <Form.Select
                aria-label="Default select"
                value={item.direction}
                disabled={isCompleted}
                onChange={({ target }) => onDirectionChange(item, target.value)}
              >
                <option value="">Please select</option>
                <option value="1">One-Way</option>
                <option value="2">Return</option>
              </Form.Select>
            </Col>
            <Col>
              <Form.Control
                type="number"
                placeholder="Value"
                value={item.total}
                disabled
              />
            </Col>
          </Row>
          <hr></hr>
        </>
      ))}
      <Row>
        <Col>
          <h4>Actual KM</h4>
        </Col>
        <Col>
          <Form.Control
            type="text"
            placeholder="Value"
            onChange={handleActualChange}
            disabled={isCompleted}
            value={eventDetails?.regional_ground_transport_actual}
          />
        </Col>
        <Col></Col>
        <Col></Col>
        <Col>
          {" "}
          <Form.Control
            type="number"
            name="total"
            placeholder="Value"
            value={eventDetails?.regional_ground_transport_total}
            disabled
          />
        </Col>
      </Row>
      <hr style={{ height: "4px" }}></hr>
      <Row>
        <Col>
          <Button
            onClick={() => handleSave("InProgress")}
            disabled={isCompleted}
          >
            Save as draft
          </Button>{" "}
          <Button
            onClick={() => handleSave("Completed")}
            disabled={isCompleted}
          >
            Submit
          </Button>
        </Col>
        <Col></Col>
      </Row>
      <ToastContainer />
    </>
  );
};

export default RegionalGroundTransportForm;
