import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { updateEmissionBoundaryReasoning } from "./EmissionsBoundariesApi";
import { EmissionsBoundariesContext } from "../../context/Context";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ModalReasonQuestion = ({
  options,
  isMulti,
  reasoning,
  id,
  emissionsBoundaryId,
  status,
}) => {
  const { emissionsBoundariesDispatch } = useContext(
    EmissionsBoundariesContext
  );

  const [selectedOptions, setSelectedOptions] = useState([]); // State to store selected options
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = async (selected) => {
    setIsLoading(true);
    setSelectedOptions(selected);

    let selectedReasonsString = "";
    let showWarning = status;
    if (Array.isArray(selected)) {
      const selectedReasons = selected.map((reason) => reason.value);
      selectedReasonsString = selectedReasons.join(",");
      showWarning = selectedReasons.length === 0;
    } else if (selected && typeof selected === "object") {
      // Handle the case when selected is a single object (not an array)
      selectedReasonsString = selected.value;
      showWarning = selectedReasonsString === "";
    }

    //update react state
    emissionsBoundariesDispatch({
      type: "UPDATE_EMISSIONS_BOUNDARIES_MODAL_REASONING",
      payload: {
        emissionsBoundaryId,
        id,
        reasoning: selectedReasonsString,
        showWarning,
      },
    });

    // update backend
    const response = await updateEmissionBoundaryReasoning(
      id,
      selectedReasonsString
    );

    setIsLoading(false);

    if (response.status === "success") {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };

  useEffect(() => {
    if (reasoning) {
      setIsLoading(true);
      if (isMulti) {
        const selectedReasons = reasoning.split(",");
        const selectedReasonsArray = selectedReasons.map((reason) => ({
          value: reason,
          label: reason,
        }));
        setSelectedOptions(selectedReasonsArray);
      } else {
        setSelectedOptions({ value: reasoning, label: reasoning });
      }
      setIsLoading(false);
    }
  }, [reasoning]);

  return (
    <Form>
      {isMulti ? (
        <Form.Group className="mb-3" controlId="amountAUD">
          <Form.Label>
            Select all that apply{" "}
            {isLoading && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
          </Form.Label>
          <Select
            options={options}
            isMulti={isMulti}
            value={selectedOptions}
            onChange={handleChange}
            loadingMessage={() => "Loading reasons..."}
          />
        </Form.Group>
      ) : (
        <Form.Group className="mb-3" controlId="amountAUD">
          <Form.Label>
            Select reason{" "}
            {isLoading && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
          </Form.Label>
          <Select
            options={options}
            value={selectedOptions}
            onChange={handleChange}
            loadingMessage={() => "Loading reasons..."}
          />
        </Form.Group>
      )}
      {/* <br />
      <Button
        variant="primary"
        type="submit"
        disabled={selectedOptions.length === 0}
      >
        Save
      </Button> */}
      <ToastContainer />
    </Form>
  );
};

export default ModalReasonQuestion;
