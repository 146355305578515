import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Col, Row, Spinner, Dropdown, Modal } from "react-bootstrap";
import {
  getInviteUrl,
  getWfhForms,
  getWfhFormUserCount,
  getAllWfhFormData,
} from "./wfhAPI";
import { getFormattedDate } from "../../helpers/utils";
import AdvanceTableWrapper from "../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../common/advance-table/AdvanceTableFooter";
import { useAuth } from "../../Contexts/AuthContext";
import CardDropdown from "../common/CardDropdown";
import AllFormsTableHeader from "../ClimateActive/MyForms/AllFormsTableHeader";

const WfhFormSummary = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useAuth();
  const [showInviteUrlModal, setShowInviteUrlModal] = useState(false);
  const [showWfhFormModal, setShowWfhFormModal] = useState(false);
  const [inviteUrl, setInviteUrl] = useState("");
  const [wfhForms, setWfhForms] = useState([]);
  const [wfhForm, setWfhForm] = useState();
  const [showSubmittedByColumn, setShowSubmittedByColumn] = useState(
    currentUser.role !== "EMPLOYEE" ? true : false
  );
  const [showWfhDataModal, setShowWfhDataModal] = useState(false);
  const [selectedWfhData, setSelectedWfhData] = useState(null);

  async function get() {
    setIsLoading(true);
    const inviteUrl = await getInviteUrl();
    setInviteUrl(inviteUrl);
    const wfhForms = await getWfhForms();
    //fetch count of users who submitted each form
    for (let i = 0; i < wfhForms.length; i++) {
      const wfhFormUserCount = await getWfhFormUserCount(wfhForms[i].id);
      wfhForms[i]["count"] = wfhFormUserCount.count;
    }
    //set wfh category
    wfhForms.forEach((form) => {
      form.category = "WFH";
    });
    setWfhForms(wfhForms);
    setIsLoading(false);
  }

  useEffect(() => {
    get();
  }, []);

  const columns = [
    {
      accessor: "",
      Header: "Actions",
      Cell: (rowData) => {
        return (
          <>
            <CardDropdown>
              <div className="py-2">
                <Dropdown.Item
                  as="button"
                  onClick={() => handleWfhDataOpen(rowData.row.original)}
                >
                  View Data
                </Dropdown.Item>
              </div>
            </CardDropdown>
          </>
        );
      },
    },
    {
      accessor: "name",
      Header: "Name",
    },
    {
      accessor: "",
      Header: "Created At",
      Cell: (rowData) => {
        const { created_at } = rowData.row.original;
        return created_at ? getFormattedDate(created_at) : "";
      },
    },
    {
      accessor: "status",
      Header: "Status",
    },
    {
      accessor: "",
      Header: "Start Date",
      Cell: (rowData) => {
        const { start_date } = rowData.row.original;
        return start_date ? getFormattedDate(start_date) : "";
      },
    },
    {
      accessor: "",
      Header: "End Date",
      Cell: (rowData) => {
        const { end_date } = rowData.row.original;
        return end_date ? getFormattedDate(end_date) : "";
      },
    },
  ];

  function handleWfhDataOpen(data) {
    setSelectedWfhData(data);
    setShowWfhDataModal(true);
  }

  function handleWfhDataClose(isRefresh) {
    setSelectedWfhData(null);
    setShowWfhDataModal(false);
    if (isRefresh) {
      get();
    }
  }

  const noDataMessages = {
    title: "No WFH Data Found",
    subtitle: "Consider adding wfh data",
  };

  return (
    <>
      {isLoading ? (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <>
          {" "}
          {showWfhDataModal && (
            <WfhDataModal
              data={selectedWfhData}
              showModal={showWfhDataModal}
              handleClose={handleWfhDataClose}
            />
          )}
          <AdvanceTableWrapper
            columns={columns}
            data={wfhForms}
            selection
            sortable
            pagination
            perPage={7}
            rowCount={wfhForms.length}
          >
            <Card>
              <Card.Header>
                <AllFormsTableHeader
                  table
                  name="wfh"
                  data={wfhForms}
                  handleClose={handleWfhDataClose}
                />
              </Card.Header>
              <Card.Body className="p-0">
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="btn-reveal-trigger text-nowrap align-middle"
                  tableProps={{
                    size: "lg",
                    className: "fs--1 mb-0 overflow-hidden",
                  }}
                  noDataMessage={noDataMessages}
                />
              </Card.Body>
              <Card.Footer>
                <AdvanceTableFooter
                  rowCount={wfhForms.length}
                  table
                  rowInfo
                  navButtons
                  isLoading={isLoading}
                />
              </Card.Footer>
            </Card>
          </AdvanceTableWrapper>
        </>
      )}
    </>
  );
};

const WfhDataModal = ({ showModal, handleClose, data }) => {
  const [wfhFormData, setWfhFormData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    {
      accessor: "people_id",
      Header: "User Id",
      disableSortBy: true,
    },
    {
      accessor: "email",
      Header: "Email",
    },
    {
      accessor: "business_id",
      Header: "Business Id",
      disableSortBy: true,
    },
    {
      accessor: "state",
      Header: "State",
      disableSortBy: true,
    },
  ];

  async function getData() {
    const response = await getAllWfhFormData(data.id);
    setWfhFormData(response || []);
    setIsLoading(false);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      {" "}
      <Modal
        show={showModal}
        onHide={() => handleClose(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            WFH Form - Users
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <div className="mb-3">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              <div className="mb-3">
                <AdvanceTableWrapper
                  columns={columns}
                  data={wfhFormData}
                  sortable
                  pagination
                  perPage={5}
                  rowCount={wfhFormData.length}
                >
                  <Card>
                    <Card.Body className="p-0">
                      <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="btn-reveal-trigger text-nowrap align-middle"
                        tableProps={{
                          size: "lg",
                          className: "fs--1 mb-0 overflow-hidden",
                        }}
                      />
                    </Card.Body>
                    <Card.Footer>
                      <AdvanceTableFooter
                        rowCount={wfhFormData.length}
                        table
                        rowInfo
                        navButtons
                      />
                    </Card.Footer>
                  </Card>
                </AdvanceTableWrapper>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default WfhFormSummary;
