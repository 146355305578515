import React from "react";
import { isAllDataZero } from "./chartHelpers";

function TextAllScopesGraph({ data }) {
  const keys = ["scope1", "scope2", "scope3"]
  const allDataIsZero = isAllDataZero(data, keys);

  function generateTextForScopes(data) {
    let highestEmissions = { month: "", total: 0 };
    let averageEmission =
      data.reduce((sum, curr) => sum + curr.scope3, 0) / data.length;

    data.forEach((item) => {
      const totalEmissions = item.scope1 + item.scope2 + item.scope3;
      if (totalEmissions > highestEmissions.total) {
        highestEmissions = { month: item.month, total: totalEmissions };
      }
    });

    const increaseFactor = (highestEmissions.total / averageEmission).toFixed(1);
    const mostEmissionsFromScope3 = data.every(
      (item) => item.scope3 >= item.scope1 && item.scope3 >= item.scope2
    );

    return {
      highestEmissions,
      increaseFactor,
      mostEmissionsFromScope3,
    };
  }

  const { highestEmissions, increaseFactor, mostEmissionsFromScope3 } =
    generateTextForScopes(data);

  return (
    <div style={{ marginBottom: "20px" }}>
      <h4>Emissions by Scope</h4>
     {!allDataIsZero &&  <p>
        The graph below shows the emissions for all scopes during the selected
        period. The <strong>highest emissions</strong> were in{" "}
        {highestEmissions.month} with a total of{" "}
        <strong>{highestEmissions.total.toFixed(2)}</strong> tonnes of CO₂e, which is{" "}
        <strong>{increaseFactor} times higher</strong> than the average.{" "}
        {mostEmissionsFromScope3
          ? <span>Most emissions come from Scope 3, which is <strong>normal</strong> for this dataset.</span>
          : <span>Emissions from Scope 3 are not the highest, which is <strong>unusual</strong> and may warrant further investigation.</span>}
      </p>}
      <p>Under each scope, you will find its definition.</p>
    </div>
  );
}

export default TextAllScopesGraph;
