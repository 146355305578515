import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Card } from "react-bootstrap";
import CountUp from "react-countup";
import { BsInfoCircle } from "react-icons/bs";

function WaterWidget({ icon, title, total }) {
  return (
    <Card className="pt-2 pb-2 text-center">
      <div className={`icon-circle-primary`}>
        <FontAwesomeIcon icon={icon} className={`fs-1 text-primary`} />
      </div>
      <h5 className="mb-2 font-sans-serif">
        <span className="fw-normal text-600">{title}</span>
      </h5>
      <div
        style={{
          width: "50%",
          height: "2px",
          backgroundColor: "#ddd",
          margin: "0 auto 10px auto",
        }}
      ></div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
          }}
        >
          <h3 className="mb-2 font-sans-serif" style={{ marginRight: "10px" }}>
            <CountUp
              start={0}
              end={total.weight}
              duration={2}
              className="text-800"
              separator=","
              decimals={2}
              style={{ fontSize: "20px" }}
            />
            <span className="fw-normal text-600" style={{ fontSize: "0.60em" }}>
              {/* {" " + dataInfo.units} */} kL of Water
            </span>
          </h3>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
          }}
        >
          <h3 className="mb-2 font-sans-serif" style={{ marginRight: "10px" }}>
            <CountUp
              start={0}
              end={total.emissions}
              duration={2}
              className="text-800"
              separator=","
              decimals={2}
              style={{ fontSize: "20px" }}
            />
            <span className="fw-normal text-600" style={{ fontSize: "0.60em" }}>
              {/* {" " + dataInfo.units} */} t CO₂e
            </span>
          </h3>
        </div>
      </div>
    </Card>
  );
}

export default WaterWidget;
