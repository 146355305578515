import React from "react";

function LoadingTransparencyHub() {
  return (
    <>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <h2 className="placeholder-glow">
          <span className="placeholder col-8"></span>
        </h2>
        <p className="placeholder-glow">
          <span className="placeholder col-6"></span>
          <span className="placeholder col-7"></span>
          <span className="placeholder col-5"></span>
          <span className="placeholder col-4"></span>
        </p>
        <p className="placeholder-glow">
          <span className="placeholder col-5"></span>
          <span className="placeholder col-6"></span>
          <span className="placeholder col-8"></span>
        </p>
      </div>
    </>
  );
}

export default LoadingTransparencyHub;
