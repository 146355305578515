import { FlatfileListener } from "@flatfile/listener";
import { find } from "lodash";
import { calculateDomesticFlightDistance } from "./flightDistanceCalculatorHelper";
import { saveFlightDataCsv } from "./flightAPI";
import { recordHook } from "@flatfile/plugin-record-hook";
import { getActiveUserData } from "../../../helpers/store";

function parseDate(inputDate) {
  const formats = [
    // dd/mm/yyyy or d/m/yyyy
    {
      format: "dd/mm/yyyy",
      regex: /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[012])\/(\d{4})$/,
    },
    // dd-mm-yyyy or d-m-yyyy
    {
      format: "dd-mm-yyyy",
      regex: /^(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[012])-(\d{4})$/,
    },
    // dd.mm.yyyy or d.m.yyyy
    {
      format: "dd.mm.yyyy",
      regex: /^(0?[1-9]|[12][0-9]|3[01])\.(0?[1-9]|1[012])\.(\d{4})$/,
    },
    // dd,mm,yyyy or d,m,yyyy
    {
      format: "dd,mm,yyyy",
      regex: /^(0?[1-9]|[12][0-9]|3[01]),(0?[1-9]|1[012]),(\d{4})$/,
    },
    // dd mm yyyy or d m yyyy
    {
      format: "dd mm yyyy",
      regex: /^(0?[1-9]|[12][0-9]|3[01])\s(0?[1-9]|1[012])\s(\d{4})$/,
    },
    // yyyy-mm-dd
    {
      format: "yyyy-mm-dd",
      regex: /^(\d{4})-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/,
    },
    // dd mmm yyyy
    {
      format: "dd mmm yyyy",
      regex: /^(0?[1-9]|[12][0-9]|3[01])\s(\w{3})\s(\d{4})$/,
    },
    // dd-mmm-yyyy
    {
      format: "dd-mmm-yyyy",
      regex: /^(0?[1-9]|[12][0-9]|3[01])-(\w{3})-(\d{4})$/,
    },
    // yyyy/mm/dd
    {
      format: "yyyy/mm/dd",
      regex: /^(\d{4})\/(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])$/,
    },
    // yyyy.mm.dd
    {
      format: "yyyy.mm.dd",
      regex: /^(\d{4})\.(0?[1-9]|1[012])\.(0?[1-9]|[12][0-9]|3[01])$/,
    },
  ];

  for (const { format, regex } of formats) {
    const match = inputDate.match(regex);

    if (match) {
      // Destructure day, month, year directly
      let day, month, year;
      if (format.startsWith("yyyy")) {
        [, year, month, day] = match;
      } else {
        [, day, month, year] = match;
      }
      //check if month is a 2 digit number that is a string
      if (month.length === 3) {
        // Convert month abbreviation to month number
        const monthAbbreviation = month.toLowerCase();
        const months = [
          "jan",
          "feb",
          "mar",
          "apr",
          "may",
          "jun",
          "jul",
          "aug",
          "sep",
          "oct",
          "nov",
          "dec",
        ];
        const monthIndex = months.indexOf(monthAbbreviation);
        if (monthIndex !== -1) {
          month = String(monthIndex + 1).padStart(2, "0"); // Pad month with zero if needed
        } else {
          throw new Error("Invalid month abbreviation");
        }
      }
      day = day.padStart(2, "0");
      month = month.padStart(2, "0");
      return { year, month, day }; // Return object with separate components
    }
  }

  throw new Error("Invalid date input");
}

async function submit(jobId, spaceId, workbookId) {
  //acknowledge the job
  let url = `https://api.x.flatfile.com/v1/jobs/${jobId}/ack`;
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_FLATFILE_SECRET_KEY}`,
    },
    body: '{"info":"string","progress":33}',
  };

  try {
    const response = await fetch(url, options);
    const data = await response.json();
    //console.log(data);
  } catch (error) {
    console.error(error);
  }

  const sheetIds = await getSheetIds(workbookId);

  const data = await getRecords(sheetIds[0]);
  var userFlightData = data?.records?.map((row) => {
    //Format class
    let classFlight = row.values.class?.value?.toLowerCase();
    if (classFlight === "business" || classFlight === "first") {
      classFlight = "premium";
    }

    //Format date to YYYY-MM-DD
    const { day, month, year } = parseDate(row.values.timePeriodFrom.value);
    const date = `${year}-${month}-${day}`;

    //console.log("row", row);
    var departureCode = row.values.departureCode.value;
    var destinationCode = row.values.destinationCode.value;
    var numberOfOnewayFlights = row.values.numberOfOnewayFlights.value;
    var classType = classFlight;
    var timePeriodFrom = date;
    var timePeriodTo = timePeriodFrom; //row.values.timePeriodTo.value;
    return {
      departureCode,
      destinationCode,
      numberOfOnewayFlights,
      classType,
      timePeriodFrom,
      timePeriodTo,
      uploaded_by: JSON.parse(getActiveUserData()).uid,
    };
  });

  console.log("userFlightData", userFlightData);
  saveFlightDataCsv({
    data: userFlightData || [],
  });

  //complete the job
  url = `https://api.x.flatfile.com/v1/jobs/${jobId}/complete`;
  const body = {
    info: "Job's work is done",
    outcome: { next: { type: "wait" } },
  };
  options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_FLATFILE_SECRET_KEY}`,
    },
    body: JSON.stringify(body),
  };

  try {
    const response = await fetch(url, options);
    const data = await response.json();
    //console.log(data);
  } catch (error) {
    console.error(error);
  }
}

async function cancel(jobId) {
  window.location = "/welcome/data/climate-active/flights-form";
}

async function getSheetIds(workbookId) {
  const url = `https://api.x.flatfile.com/v1/workbooks/${workbookId}`;
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_FLATFILE_SECRET_KEY}`,
    },
  };

  try {
    const response = await fetch(url, options);
    const data = await response.json();
    const sheets = data["data"]["sheets"];
    const sheetIds = sheets.map((sheet) => sheet["id"]);
    return sheetIds;
  } catch (error) {
    console.error(error);
  }
}

async function getRecords(sheetId) {
  const url = `https://api.x.flatfile.com/v1/sheets/${sheetId}/records`;
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_FLATFILE_SECRET_KEY}`,
    },
  };

  try {
    const response = await fetch(url, options);
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error(error);
  }
}

export const flightFlatFileListener = FlatfileListener.create((client) => {
  client.use(
    recordHook("flightDataDefault", (record) => {
      const flightCodeRegex = /^[A-Z]{3}(?<![A-Z]{4})(?![A-Z])$/;
      const dateRegexYearFirstDash =
        /^(19|20)\d\d-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/;
      const dateRegexDayFirstDash =
        /^(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[012])-(19|20)\d\d$/;
      const dateRegexYearFirstSlash =
        /^(19|20)\d\d\/(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])$/;
      const dateRegexDayFirstSlash =
        /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[012])\/(19|20)\d\d$/;
      const dateRegexYearFirstDot =
        /^(19|20)\d\d\.(0?[1-9]|1[012])\.(0?[1-9]|[12][0-9]|3[01])$/;
      const dateRegexDayFirstDot =
        /^(0?[1-9]|[12][0-9]|3[01])\.(0?[1-9]|1[012])\.(19|20)\d\d$/;

      record.validate(
        "destinationCode",
        (value) => flightCodeRegex.test(value),
        "Code must be 3 letters"
      );
      record.validate(
        "departureCode",
        (value) => flightCodeRegex.test(value),
        "Code must be 3 letters"
      );
      record.validate(
        "class",
        (value) =>
          value.toLowerCase() === "economy" ||
          value.toLowerCase() === "premium" ||
          value.toLowerCase() === "business" ||
          value.toLowerCase() === "first class",
        "Flight class must be either 'economy', 'premium', 'business', or 'first class'"
      );
      record.validate(
        "timePeriodFrom",
        (value) =>
          dateRegexYearFirstDash.test(value) ||
          dateRegexDayFirstDash.test(value) ||
          dateRegexYearFirstSlash.test(value) ||
          dateRegexDayFirstSlash.test(value) ||
          dateRegexYearFirstDot.test(value) ||
          dateRegexDayFirstDot.test(value),
        "date formats accepted: DD/MM/YYYY, YYYY/MM/DD, with - or . as separators"
      );
      return record;
    })
  );

  // when the user clicks the submit button
  client.on(
    "job:ready",
    { payload: { operation: "flight:submit" } },
    async (event) => {
      const { context } = event;
      return submit(context.jobId, context.spaceId, context.workbookId);
    }
  );

  //when the user clicks the cancel button
  client.on(
    "job:ready",
    { payload: { operation: "flight:cancel" } },
    async (event) => {
      const { context } = event;
      return cancel(context.jobId);
    }
  );

  //after the user maps the column
  client.on("records:created", async ({ context: { sheetId } }) => {
    console.log("records:created");
  });

  // after file creation, check if the file is of type csv
  client.on("file:created", async ({ context: { fileId } }) => {
    console.log("file:created", fileId);

    const url = `https://api.x.flatfile.com/v1/files/${fileId}`;
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_FLATFILE_SECRET_KEY}`,
      },
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();

      if (data.data.mimetype !== "text/csv") {
        alert("Please upload a csv file");

        const url = `https://api.x.flatfile.com/v1/files/${fileId}`;
        const options = {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_FLATFILE_SECRET_KEY}`,
          },
        };

        try {
          const response = await fetch(url, options);
          const data = await response.json();
          // console.log(data);
        } catch (error) {
          console.error(error);
        }
      }
    } catch (error) {
      console.error(error);
    }
  });
});
