/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useAsyncDebounce } from "react-table";

const AdvanceTableSearchBox = ({
  searchValue = "",
  setSearchValue = () => {},
  globalFilter,
  setGlobalFilter,
  placeholder = "Search...",
}) => {
  const [value, setValue] = useState(globalFilter);

  const onChange = useAsyncDebounce((value) => {
    setSearchValue(value);
    setGlobalFilter(value || undefined);
  }, 200);

  useEffect(() => {
    setValue(searchValue);
    setGlobalFilter(searchValue);
  }, [searchValue]);

  return (
    <InputGroup className="position-relative">
      <FormControl
        value={value || ""}
        onChange={({ target: { value } }) => {
          setValue(value);
          onChange(value);
        }}
        size="sm"
        id="search"
        placeholder={placeholder}
        type="search"
        className="shadow-none"
      />
      <InputGroup.Text className="bg-transparent">
        <FontAwesomeIcon icon={faSearch} className="fs--1 text-600" />
      </InputGroup.Text>
    </InputGroup>
  );
};

export default AdvanceTableSearchBox;
