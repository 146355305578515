import React, { useContext, useMemo } from "react";
import { WaterContext } from "../../context/Context";
import AdvanceTableWrapper from "../common/advance-table/AdvanceTableWrapper";
import { Card, Alert } from "react-bootstrap";
import AdvanceTable from "../common/advance-table/AdvanceTable";
import { IoCheckmarkDoneCircleOutline } from "react-icons/io5";
import WaterTable from "./WaterTable";

function PageSubmitted() {
  const { categoriesData, formData, isLoading } = useContext(WaterContext);
  const storedLocationsJSON = sessionStorage.getItem("userBusinessLocations");
  const locations = JSON.parse(storedLocationsJSON);
  const officeSelected = locations.find(
    (office) => office.office_id === formData?.office?.value
  );
  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      <Alert variant="success" className="d-flex align-items-center">
        <IoCheckmarkDoneCircleOutline
          style={{
            // marginBottom: 20,
            fontSize: "3em",
            color: "#00d27a",
          }}
        />
        Data submitted successfully!
      </Alert>
      {/* <h2>Form Data</h2> */}
      <p>
        <strong>Form Name:</strong> {formData.formName}
      </p>
      <p>
        <strong>Office:</strong> {officeSelected?.officename}
      </p>
      <p>
        <strong>Date Range:</strong>{" "}
        {new Date(formData.startDate).toLocaleDateString()} -{" "}
        {formData.endDate
          ? new Date(formData.endDate).toLocaleDateString()
          : "N/A"}
      </p>
      <p>
        <strong>Update Date:</strong>{" "}
        {new Date(formData.update_date).toLocaleDateString()}
      </p>

      <WaterTable categoriesData={categoriesData} />
    </div>
  );
}

export default PageSubmitted;
