import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import ChatContentBodyIntro from "./ChatContentBodyIntro";
import Message from "./Message";
import SimpleBarReact from "simplebar-react";
import ThreadInfo from "./ThreadInfo";
import { ChatContext } from "../../../../context/Context";
import ChatLoader from "./ChatLoader";

const ChatContentBody = ({ thread, isLoading }) => {
  let lastDate = null;
  const messagesEndRef = useRef();

  const {
    getUser,
    messages,
    currentThread,
    scrollToBottom,
    setScrollToBottom,
  } = useContext(ChatContext);
  const user = getUser(thread);
  const { content } = messages.find(({ id }) => id === thread.messagesId);

  useEffect(() => {
    if (scrollToBottom) {
      setTimeout(() => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      }, 500);
      setScrollToBottom(false);
    }
  }, [scrollToBottom]);

  // console.log("ChatContentBody.js, thread: ", thread);
  // console.log("ChatContentBody.js, currentThread: ", currentThread);
  // console.log("ChatContentBody.js, user: ", user);
  // console.log("ChatContentBody.js, messages: ", messages);
  // console.log("ChatContentBody.js, content: ", content);

  return (
    <div className="chat-content-body" style={{ display: "inherit" }}>
      <ThreadInfo thread={thread} isOpenThreadInfo={true} />
      <SimpleBarReact style={{ height: "100%" }}>
        <div className="chat-content-scroll-area">
          <ChatContentBodyIntro user={user} />
          <>
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center h-100">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                {content.map(
                  (
                    {
                      message,
                      time,
                      senderUserId,
                      status,
                      delayMessage,
                      isSolution,
                    },
                    index
                  ) => (
                    <div key={index}>
                      {lastDate !== time.date && (
                        <div className="text-center fs--2 text-500">{`${time.date}, ${time.hour}`}</div>
                      )}
                      {(() => {
                        lastDate = time.date;
                      })()}
                      <Message
                        message={message}
                        senderUserId={senderUserId}
                        time={time}
                        status={status}
                        isGroup={thread.type === "group"}
                        isSolution={isSolution}
                      />
                    </div>
                  )
                )}
                {currentThread.isWaiting && (
                  <>
                    <div className="text-center fs--2 text-500">
                      The bot might take sometime to respond
                    </div>
                    {/* TODO:Build a loader towards left*/}
                    <ChatLoader senderUserId={25} />
                  </>
                )}
              </>
            )}
          </>
        </div>
        <div ref={messagesEndRef} />
      </SimpleBarReact>
    </div>
  );
};

ChatContentBody.propTypes = {
  thread: PropTypes.object.isRequired,
};

export default ChatContentBody;
