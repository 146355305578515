export const flatFileWorkbook = {
  name: "Spend Based Data",
  sheets: [
    {
      name: "Standard Expense",
      slug: "expenseByAccountsBeta",
      fields: [
        {
          key: "date",
          type: "string",
          label: "Date (click for formats allowed)",
          description: "Enter the date in one of the following formats: YYYY/MM/DD, YYYY-MM-DD, YYYY.MM.DD, DD/MM/YYYY, DD-MM-YYYY, or DD.MM.YYYY.",
          constraints: [
            {
              type: "required",
            },
          ],
        },
        {
          key: "description",
          type: "string",
          label: "Description",
        },
        {
          key: "accountCode",
          type: "string",
          label: "Account Code",
        },
        {
          key: "accountName",
          type: "string",
          label: "Account Name",
          constraints: [
            {
              type: "required",
            },
          ],
        },
        {
          key: "accountType",
          type: "string",
          label: "Account Type",
        },
        {
          key: "contactName",
          type: "string",
          label: "Contact Name",
          constraints: [
            {
              type: "required",
            },
          ],
        },
        {
          key: "subTotal",
          type: "number",
          label: "Debit(AUD)",
          description: "Accepted formats: 2000.50 , 2,000.50, $2,000.50",
          constraints: [
            {
              type: "required",
            },
          ],
        },
      ],
    },
    // {
    //   name: "Expense by Contact (supplier)",
    //   slug: "expenseByContacts",
    //   fields: [
    //     {
    //       key: "contactName",
    //       type: "string",
    //       label: "Supplier name",
    //       constraints: [
    //         {
    //           type: "required",
    //         },
    //       ],
    //     },
    //     {
    //       key: "subTotal",
    //       type: "number",
    //       label: "Total amount",
    //       constraints: [
    //         {
    //           type: "required",
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   name: "Itemised Expense Transaction",
    //   slug: "expenseByAccounts",
    //   fields: [
    //     {
    //       key: "date",
    //       type: "string",
    //       label: "Date",
    //       constraints: [
    //         {
    //           type: "required",
    //         },
    //       ],
    //     },
    //     {
    //       key: "description",
    //       type: "string",
    //       label: "Description",
    //     },
    //     {
    //       key: "accountName",
    //       type: "string",
    //       label: "Account Name",
    //     },
    //     {
    //       key: "contactName",
    //       type: "string",
    //       label: "Contact Name",
    //     },
    //     {
    //       key: "subTotal",
    //       type: "number",
    //       label: "Debit(AUD)",
    //       description:"Valid formats are 10,000.85 and -10000.85",
    //       constraints: [
    //         {
    //           type: "required",
    //         },
    //       ],
    //     },
    //   ],
    // },
  ],
  actions: [
    {
      label: "Submit",
      operation: "expenses:submit",
      description: "Would you like to submit your workbook?",
      mode: "foreground",
      primary: true,
      confirm: true,
      constraints: [{ type: 'hasAllValid'},{ type: 'hasData' }],
    },
    {
      label: "Cancel",
      operation: "expenses:cancel",
      description: "Would you like to cancel your workbook?",
      mode: "foreground",
      primary: false,
      confirm: true,
    },
  ],
};
