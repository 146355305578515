import React, { useState, useEffect } from "react";
import {
  collection,
  doc,
  setDoc,
  getDocs,
  getFirestore,
  getDoc,
} from "firebase/firestore";
import { Col, Form, Row } from "react-bootstrap";
import "react-quill/dist/quill.snow.css"; // Quill.js styles
import app from "../../../firebase";
import CreateHelpAndSupportDocsHeader from "./CreateHelpAndSupportDocsHeader";
import HelpAndSupportPreview from "./HelpAndSupportPreview";
import CreateHelpAndSupportDocsSubHeader from "./CreateHelpAndSupportDocsSubHeader";
import CreateNewSection from "./CreateNewSection";
import { toast } from "react-toastify";
import HelpAndSupportOverlay from "../../help-panel/HelpAndSupportOverlay";

const db = getFirestore(app); // Initialize Firestore

const CreateHelpAndSupportDocs = () => {
  const [sectionData, setSectionData] = useState({});
  const [mainTitle, setMainTitle] = useState("");
  const [learnMoreLink, setLearnMoreLink] = useState("");
  const [showLearnMoreLink, setShowLearnMoreLink] = useState(false);
  const [content, setContent] = useState("");
  const [hideHelpAndSupportToggle, setHideHelpAndSupportToggle] =
    useState(false);
  const [selectedRoute, setSelectedRoute] = useState(""); // Default to first route
  const [initialRoutes, setInitialRoutes] = useState([]);
  const [showContent, setShowContent] = useState(false);
  const [published, setPublished] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const handlePreview = () => {
    setSectionData({
      mainTitle: mainTitle,
      content: content,
      showLearnMoreLink: showLearnMoreLink,
      learnMoreLink: learnMoreLink,
      published: published,
    });
    setShowPreview(!showPreview);
  };

  // Fetch existing data from Firestore for the selected route
  const fetchHelpData = async (route) => {
    // Remove only the first '/' from the route
    route = route.replace(/^\//, ""); // This ensures only the first '/' is removed

    // Create a unique key for the document
    const routeKey = route.replace(/\//g, "__SLASH__"); // Replace all '/' with '__SLASH__' in the route

    // Get a document from Firestore
    const docRef = doc(db, "HelpContent", routeKey);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      setMainTitle(data.mainTitle || "");
      setLearnMoreLink(data.learnMoreLink || "");
      setShowLearnMoreLink(data.learnMoreLink ? true : false);
      setContent(data.content || "");
      setHideHelpAndSupportToggle(data.hideHelpAndSupportToggle || false);
      setPublished(data.published || false);
      setSectionData({
        mainTitle: data.mainTitle || "",
        content: data.content || "",
        showLearnMoreLink: data.learnMoreLink ? true : false,
        learnMoreLink: data.learnMoreLink || "",
        published: data.published || false,
      });
      setShowContent(true);
    } else {
      // console.log("No such route found!");
      // Reset to default if no document found
      setMainTitle("");
      setLearnMoreLink("");
      setShowLearnMoreLink(false);
      setContent("");
      setHideHelpAndSupportToggle(false);
      setPublished(false);
      setSectionData({});
      setShowContent(true);
    }
  };

  // Save data to Firestore
  const handleSave = async (publish) => {
    if (selectedRoute && mainTitle && content) {
      try {
        // Create a copy of selectedRoute to avoid mutating state directly
        let routeCopy = selectedRoute;

        routeCopy = routeCopy.replace(/^\//, ""); // Remove the first '/'
        const selectedRouteKey = routeCopy.replace(/\//g, "__SLASH__"); // Replace all '__SLASH__' with '-'

        await setDoc(doc(db, "HelpContent", selectedRouteKey), {
          mainTitle: mainTitle,
          learnMoreLink: learnMoreLink,
          showLearnMoreLink: showLearnMoreLink,
          content: content,
          hideHelpAndSupportToggle: hideHelpAndSupportToggle,
          published: publish,
        });

        if (publish) {
          toast.success("Document published successfully!");
        } else {
          toast.success("Document drafted successfully!");
        }
      } catch (error) {
        console.error("Error saving document: ", error);
        toast.error("Error saving the document.");
      }
    } else {
      toast.error("Please fill in all required fields!");
    }
  };

  // Handle route creation
  const handleCreateRoute = async (route) => {
    // Remove only the first '/' from the route
    route = route.replace(/^\//, ""); // This ensures only the first '/' is removed

    // Create a unique key for the document (e.g., replace '/' with '-')
    const routeKey = route.replace(/\//g, "__SLASH__"); // Replace all '/' with '-' in the route

    // Check if the route already exists
    const docRef = doc(db, "HelpContent", routeKey);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      toast.error("Route already exists!");
    } else {
      // Create a new document with the route key
      await setDoc(doc(db, "HelpContent", routeKey), {
        mainTitle: "",
        learnMoreLink: "",
        showLearnMoreLink: false,
        content: "",
        hideHelpAndSupportToggle: false,
        published: false,
      });

      toast.success("Route created successfully!");
    }

    // Fetch data for the newly created route
    fetchHelpData(route);
  };

  // Fetch all existing routes from Firestore
  const fetchRoutes = async () => {
    const routes = [];
    const querySnapshot = await getDocs(collection(db, "HelpContent"));

    querySnapshot.forEach((doc) => {
      const storedRoute = doc.id;

      // Convert `__SLASH__` back to `/`
      const convertedRoute = `/${storedRoute.replace(/__SLASH__/g, "/")}`;

      routes.push({ value: convertedRoute, label: convertedRoute });
    });

    setInitialRoutes(routes); // Set the initial routes
  };

  // Handle route change and fetch associated data
  const handleRouteChange = (route) => {
    setSelectedRoute(route);
    fetchHelpData(route);
  };

  const handleMainTitleChange = (value) => {
    setMainTitle(value);
    setSectionData({ ...sectionData, mainTitle: value });
  };

  const handleLearnMoreLinkChange = (value) => {
    setLearnMoreLink(value);
    setSectionData({ ...sectionData, learnMoreLink: value });
  };

  const handleShowLearnMoreLink = () => {
    setShowLearnMoreLink(!showLearnMoreLink);
    setSectionData({ ...sectionData, showLearnMoreLink: !showLearnMoreLink });
  };

  const handleContentChange = (value) => {
    setContent(value);
    setSectionData({ ...sectionData, content: value });
  };

  const handleHideHelpAndSupportToggle = () => {
    setHideHelpAndSupportToggle(!hideHelpAndSupportToggle);
    setSectionData({
      ...sectionData,
      hideHelpAndSupportToggle: !hideHelpAndSupportToggle,
    });
  };

  useEffect(() => {
    if (selectedRoute) fetchHelpData(selectedRoute);
  }, [selectedRoute]);

  useEffect(() => {
    fetchRoutes();
  }, []);

  return (
    <>
      <CreateHelpAndSupportDocsHeader />

      <CreateHelpAndSupportDocsSubHeader
        onRouteChange={handleRouteChange}
        showContent={showContent}
        setShowContent={setShowContent}
        onSave={handleSave}
        initialRoutes={initialRoutes}
        onCreateRoute={handleCreateRoute}
        handlePreview={handlePreview}
      />

      {showContent && (
        <Form>
          <Row className="g-lg-3 font-sans-serif">
            <Col lg={8}>
              <CreateNewSection
                mainTitle={mainTitle}
                onMainTitleChange={handleMainTitleChange}
                learnMoreLink={learnMoreLink}
                onLearnMoreLinkChange={handleLearnMoreLinkChange}
                showLearnMoreLink={showLearnMoreLink}
                handleShowLearnMoreLink={handleShowLearnMoreLink}
                content={content}
                onContentChange={handleContentChange}
                hideHelpAndSupportToggle={hideHelpAndSupportToggle}
                handleHideHelpAndSupportToggle={handleHideHelpAndSupportToggle}
              />
            </Col>
            <Col lg={4}>
              {/* Help and Support Preview, updated dynamically */}
              <HelpAndSupportPreview
                mainTitle={mainTitle}
                content={content}
                showLearnMoreLink={showLearnMoreLink}
                learnMoreLink={learnMoreLink}
              />
            </Col>
          </Row>
        </Form>
      )}

      {showPreview && (
        <HelpAndSupportOverlay
          show={showPreview}
          onClose={handlePreview}
          sectionData={sectionData}
        />
      )}
    </>
  );
};

export default CreateHelpAndSupportDocs;
