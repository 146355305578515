import { Modal, Spinner } from "react-bootstrap";

import React, { useContext, useEffect, useState } from "react";
import PageHeader from "../common/PageHeader";
import { getActiveUserData } from "../../helpers/store";
import { Button, Card, Form, ProgressBar, Table } from "react-bootstrap";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import app from "../../firebase";
import {
  addFilesToSurvey,
  createSupplier,
  initializeAssessment,
  sendLoopsEvent,
  updateSupplierId,
} from "./transparencyHubAPI";
import { TransparencyContext } from "../../context/Context";

const MIME_TYPE_NOT_INCLUDED = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "text/csv",
];

function UploadAssessmentModal({ showModal, handleCloseModal }) {
  const { assessmentData, supplierData, refetchData, year, yearType } =
    useContext(TransparencyContext);
  const activeUser = JSON.parse(getActiveUserData());
  const [files, setFiles] = useState([]);
  const [disableInput, setDisableInput] = useState(false); //for multiple files upload
  const [progress, setProgress] = useState(0);
  const businessId = activeUser.businessId;
  const userId = activeUser.uid;
  const storage = getStorage(app);
  const [isLoading, setIsLoading] = useState(false);

  async function storeFiles(files) {
    const uploadedFiles = [];
    const currentDate = new Date().toISOString();

    let supplierId = supplierData?.supplierId;
    if (!supplierId) {
      supplierId = await createSupplier(supplierData);
      // console.log("New supplier", newSupplierId);
      await updateSupplierId(
        supplierData.netnadaId, //businessId
        supplierId
      );
    }

    // Initialize assessment or survey as needed
    await initializeAssessment(
      supplierId,
      supplierData.netnadaId,
      year,
      yearType,
      assessmentData
    );

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileProgress = { index: i, name: file.name, progress: 0 }; // Progress state for each file
      // console.log("uploading file...", i + 1, "of", files.length);
      const uploadResult = await new Promise((resolve, reject) => {
        const userFilePath = `businesses/${businessId}/sustainabilityAssessments/${year}/${file.name}`;
        const storageRef = ref(storage, userFilePath);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const prog = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            fileProgress.progress = prog; // Update the progress state for the file
            setProgress((prevProgress) => {
              // Update the progress state for all files
              const updatedProgress = [...prevProgress];
              updatedProgress[i] = fileProgress;
              return updatedProgress;
            });
          },
          (error) => {
            console.log(error);
            reject(error);
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              const fileType = file.type.replace("application/", "");
              const fileData = {
                url: downloadURL,
                fileType: fileType,
                fileName: file.name,
                userId: userId,
                uploadDate: currentDate,
              };
              // console.log("Add file to survey", fileData)
              await addFilesToSurvey(
                supplierId,
                `${yearType}${year}`,
                fileData
              );

              resolve(fileData);
            } catch (error) {
              console.error("Error uploading Documemt to TA", error);
              console.log("User ID:", userId);
              console.log("Business ID:", businessId);
              reject(error);
            }
          }
        );
      });
      // console.log("uploading file...", i + 1, "of", files.length, "done");
      // console.log("uploadResult", uploadResult);
      uploadedFiles.push(uploadResult);
    }

    return uploadedFiles;
  }

  async function handleSubmit(e) {
    setIsLoading(true);
    e.preventDefault();
    e.stopPropagation();
    setDisableInput(true);

    if (files.length > 0) {
      const progressArray = files.map((file, index) => ({
        index,
        name: file.name,
        progress: 0,
      }));
      setProgress(progressArray);

      const uploadedFiles = await storeFiles(files);

      toast(`Successfull Upload`);
      // sessionStorage.setItem("fileUploadCompleted", true);
      // window.location = "/welcome/data/all-invoices";
      const status = "done";
      const eventName = "supplierHasUploadedDocs";
      const contactProperties = {
        supplierAssessmentStatus: status,
      };
      await sendLoopsEvent(eventName, contactProperties);
      refetchData();
      setIsLoading(false);
      handleCloseModal();
      return;
    }

    // Handle the case when no files are selected
  }

  //upload multiple files
  const uploadMultipleFiles = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleFileDelete = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Calculations
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Upload your Sustainability Documents here.</p>

          <Form.Group controlId="formFileLg" className="mb-3">
            <Form.Label>
              Upload Your Invoices(only .jpg/.pdf/.csv/.xlsx/.xls files
              accepted)
            </Form.Label>

            <Form.Control
              type="file"
              size="lg"
              accept=".jpg,.pdf,.csv,.xlsx,.xls"
              onChange={uploadMultipleFiles}
              multiple
              disabled={disableInput}
            />
          </Form.Group>

          <Table striped bordered>
            <thead>
              <tr>
                <th>File Name</th>
                <th>Size</th>
                <th>Type</th>
                {!disableInput && <th>Actions</th>}
              </tr>
            </thead>
            <tbody>
              {files.length > 0 ? (
                files.map((file, index) => (
                  <tr key={index}>
                    <td>
                      <div key={index}>
                        <span>{file.name}</span>
                        <ProgressBar now={progress[index]?.progress} />
                      </div>
                    </td>
                    <td>{(file.size / 1024).toFixed(2)} KB</td>
                    <td>{file.type}</td>
                    {!disableInput && (
                      <td>
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={() => handleFileDelete(index)}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </button>
                      </td>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center">
                    No files selected
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          {isLoading && (
            <div className="mb-3">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
          <Button type="submit" disabled={files.length < 1 || isLoading}>
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default UploadAssessmentModal;
