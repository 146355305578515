import React, { useState } from "react";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import app from "../../firebase";

import { useAuth } from "../../Contexts/AuthContext";
import { Form, ProgressBar } from "react-bootstrap";

function AddLogo({ logoUrl, setLogoUrl }) {
  const [uploadProgress, setUploadProgress] = useState(0);
  const { currentUser } = useAuth();

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        console.log("Uploading file:", file);
        const downloadURL = await storeFile(file);
        setLogoUrl(downloadURL);
        setUploadProgress(0); // Reset progress after upload is complete
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };
  async function storeFile(file) {
    return new Promise((resolve, reject) => {
      const storage = getStorage(app);
      const userFilePath = `OrganisationLogos/${currentUser.uid}/${file.name}`;
      const storageRef = ref(storage, userFilePath);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          // Here you can update the state to reflect the progress
          setUploadProgress(progress); // Update state to reflect the progress
          // console.log("Upload is " + progress + "% done");
        },
        (error) => {
          console.error(error);
          reject(error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL);
            // setValue("logourl", downloadURL);
          } catch (error) {
            console.error(error);
            reject(error);
          }
        }
      );
    });
  }

  return (
    <Form.Group className="mb-4" controlId="organisationLogo">
      <Form.Label style={{ fontSize: "14px" }}>Organisation Logo</Form.Label>
      <Form.Control
        type="file"
        onChange={handleFileChange}
        size="sm"
        accept=".png, .jpg, .jpeg, .svg"
        // isInvalid={!!errors.organisationLogo}
      />
      {uploadProgress > 0 && (
        <ProgressBar
          now={uploadProgress}
          label={`${uploadProgress}%`}
          style={{ marginTop: "10px" }}
        />
      )}
      {logoUrl && (
        <div>
          <img
            src={logoUrl}
            alt="Uploaded Logo"
            style={{ maxWidth: "100px", marginTop: "10px" }}
          />
        </div>
      )}
      {/* {errors.organisationLogo && (
      <Form.Control.Feedback type="invalid">
        {errors.organisationLogo.message}
      </Form.Control.Feedback>
    )} */}
    </Form.Group>
  );
}

export default AddLogo;
