import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { getWhiteLabel } from "../../helpers/store";

const AccountingSoftwareIntegrationRequestModal = ({
  showModal,
  handleClose,
}) => {
  return (
    <div>
      {" "}
      <Modal
        show={showModal}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Please add data to your {getWhiteLabel().name} account.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Before we can generate a report for your business you first need to
            add expenditure data to your account. Use our Xero or MYOB
            Integration to add data in a secure way. You can also upload a CSV
            file with your data.If you have any questions please email
            support@netnada.com.au.
          </p>
          <Row className="gx-3">
            <Col lg={6}>
              <Button
                size="sm"
                onClick={() => {
                  window.location = "/connect-to-xero";
                }}
              >
                CONNECT TO XERO
              </Button>
            </Col>
          </Row>
          <br />
          <Row className="gx-3">
            <Col lg={6}>
              <Button
                size="sm"
                onClick={() => {
                  window.location = "/connect-to-MYOB";
                }}
              >
                CONNECT TO MYOB
              </Button>
            </Col>
          </Row>
          <br />
          <Row className="gx-3">
            <Col lg={6}>
              <Button
                size="sm"
                onClick={() => {
                  window.location = "/welcome/data/file-uploader";
                }}
              >
                UPLOAD CSV DATA
              </Button>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AccountingSoftwareIntegrationRequestModal;
