import { clearLoginData } from "../../../helpers/store";
import { getAuthorizationHeaderWithContentType } from "../../../helpers/utils";

export const getFoodDataByEventId = async (id) => {
  try {
    const response = await fetch(
      "/api/events/get-food-data-by-event-id/" + id,
      {
        method: "GET",
        headers: getAuthorizationHeaderWithContentType(),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      console.log(jsonData);
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const submitFoodForm = async (body, id) => {
  try {
    const response = await fetch("/api/events/submit-food-form/" + id, {
      method: "POST",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      console.log(jsonData);
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const saveFoodDraft = async (body, id) => {
  try {
    const response = await fetch("/api/events/save-food-draft/" + id, {
      method: "POST",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      console.log(jsonData);
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const deleteFoodDataById = async (id) => {
  try {
    const response = await fetch("/api/events/delete-food-data-by-id/" + id, {
      method: "DELETE",
      headers: getAuthorizationHeaderWithContentType(),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      console.log(jsonData);
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const updateFoodDataByEventId = async (body, id) => {
  try {
    const response = await fetch(
      "/api/events/update-food-data-by-event-id/" + id,
      {
        method: "PUT",
        headers: getAuthorizationHeaderWithContentType(),
        body: JSON.stringify(body),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      console.log(jsonData);
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};
