import { find } from "lodash";

const flightsDistanceDomestic = require("./flightsDistanceDomestic.json");

export const calculateDomesticFlightDistance = async (
  departureName,
  destinationName,
  numberOfOneWayFlights
) => {
  try {
    if (departureName && destinationName) {
      const selectedDeparture = find(flightsDistanceDomestic, function (item) {
        if (item.departure.includes(departureName)) {
          return item;
        }
      });

      if (!selectedDeparture) {
        return null;
      }

      const selectedDestination = find(
        selectedDeparture.destinations,
        function (item) {
          if (item.name.includes(destinationName)) {
            return item;
          }
        }
      );

      if (!selectedDestination) {
        return null;
      }

      var distance = selectedDestination.distance;
      var flightType = "Adjust flight route";
      if (selectedDestination.distance > 3700) {
        flightType = "Long";
      } else if (
        selectedDestination.distance <= 3700 &&
        selectedDestination.distance > 400
      ) {
        flightType = "Short";
      } else if (
        selectedDestination.distance >= 1 &&
        selectedDestination.distance <= 400
      ) {
        flightType = "Very short";
      }

      var totalPax = selectedDestination.distance * numberOfOneWayFlights;
      return { distance, flightType, totalPax };
    }
  } catch (error) {
    console.error(error);
  }
};
