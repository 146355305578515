import React, { useEffect, useState } from "react";
import { useAuth } from "../../../Contexts/AuthContext";
import app from "../../../firebase";
import { collection, getDocs, getFirestore } from "firebase/firestore";

import Section from "../../common/Section";
import { Col, Row } from "react-bootstrap";
import bgShape from "../../../Assets/img/illustrations/bg-shape.png";
import shape1 from "../../../Assets/img/illustrations/shape-1.png";
import Logo from "../../common/Logo";
import Flex from "../../common/Flex";
import AccountsListForm from "../AccountsListForm";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { getWhiteLabel } from "../../../helpers/store";

const EXCLUDE_ACCOUNT_TYPES_XERO = ["EQUITY", "REVENUE"];

const fireStoreDB = getFirestore(app);

const AccountsList = () => {
  const { currentUser } = useAuth();
  const [accountsList, setAccountsList] = useState([]);

  useEffect(() => {
    const fetchAccountsList = async () => {
      //fetch accounts list from firebase
      const querySnapshot = await getDocs(
        collection(
          fireStoreDB,
          "Accounts",
          currentUser.businessId,
          currentUser.authProvider
        )
      );
      let accountsList = [];
      querySnapshot.forEach((doc) => {
        const data = JSON.parse(JSON.stringify(doc.data()));
        if (currentUser.authProvider === "Xero") {
          if (!EXCLUDE_ACCOUNT_TYPES_XERO.includes(data.type)) {
            accountsList.push(data);
          }
        }
        if (currentUser.authProvider === "MYOB") {
          accountsList.push(data);
        }
      });
      // console.log("accountList=>", accountsList);
      if (currentUser.authProvider === "Xero") {
        //sort accountsList by name
        accountsList = accountsList.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
      }
      if (currentUser.authProvider === "MYOB") {
        //sort accountsList by DisplayID
        accountsList = accountsList.sort((a, b) => {
          // Extract the DisplayID values from the account objects
          const displayIDA = a.DisplayID;
          const displayIDB = b.DisplayID;

          // Use the localeCompare method to perform a string comparison
          return displayIDA.localeCompare(displayIDB);
        });
      }
      setAccountsList(accountsList);
    };

    fetchAccountsList();
  }, []);
  return (
    <Section className="py-0">
      <Row className="g-0 min-vh-100 flex-center">
        <Col className="col-xxl-10">
          <img
            className="bg-auth-circle-shape"
            src={bgShape}
            alt=""
            width="250"
          />
          <img
            className="bg-auth-circle-shape-2"
            src={shape1}
            alt=""
            width="150"
          />
          <Logo width={50} textClass="fs-4" />
          <FormTitle
            icon={faCheckDouble}
            title={`Map accounts between your accounting software and ${
              getWhiteLabel().name
            }`}
          />
          <AccountsListForm
            accountsList={accountsList}
            setAccountsList={setAccountsList}
            isEdit={false}
          />
        </Col>
      </Row>
    </Section>
  );
};

const FormTitle = ({ icon, title }) => {
  return (
    <Flex className="mb-4">
      <span className="fa-stack me-2 ms-n1">
        {/* <FontAwesomeIcon icon="circle" className="fa-stack-2x text-300" />
          <FontAwesomeIcon
            icon={icon}
            className="fa-inverse fa-stack-1x text-primary"
          /> */}
      </span>
      <div className="flex-1">
        <h5 className="mb-0 text-primary position-relative">
          <span className="bg-200 dark__bg-1100 pe-3">{title}</span>
          <span className="border position-absolute top-50 translate-middle-y w-100 start-0 z-index--1"></span>
        </h5>

        <p className="mb-0">
          This should take you no more than a few minutes. You will be able to
          edit this accounts later with the support of our team.
          <br />
          <br />
          <b>What to include:</b>
          <li>Expenses (exceptions below)</li>
          <li>Cost of goods sold</li>
          <br />
          <b>What to exclude:</b>
          <li>Salary/wages</li>
          <li>Superannuation, or</li>
          <li>Revenue</li>
          <li>Depreciation</li>
          <br />
          You can click on the table columns to sort account types or use the
          search bar.
        </p>
      </div>
    </Flex>
  );
};

export default AccountsList;
