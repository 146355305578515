import React from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import ReactQuill from "react-quill";

const CreateNewSection = ({
  mainTitle,
  onMainTitleChange,
  learnMoreLink,
  onLearnMoreLinkChange,
  showLearnMoreLink,
  handleShowLearnMoreLink,
  content,
  onContentChange,
  hideHelpAndSupportToggle,
  handleHideHelpAndSupportToggle,
}) => {
  const handleMainTitleChange = (e) => {
    onMainTitleChange(e.target.value);
  };

  const handleLearnMoreLinkChange = (e) => {
    onLearnMoreLinkChange(e.target.value);
  };

  const handleContentChange = (value) => {
    onContentChange(value);
  };

  return (
    <Card className="mb-3">
      <Card.Header as="h5">Add Section Information</Card.Header>
      <Card.Body className="bg-body-tertiary">
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group controlId="mainTitle">
              <Form.Label>
                Main Title<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="mainTitle"
                required
                placeholder="Enter main title"
                value={mainTitle}
                onChange={handleMainTitleChange} // Update the section title
              />
            </Form.Group>
          </Col>

          <Col md="12">
            <Form.Group controlId="learnMoreLink">
              <div className="d-flex justify-content-between align-items-center">
                <Form.Label>
                  Learn More Link<span className="text-danger">*</span>
                </Form.Label>
                <Form.Check
                  type="switch" // Change to switch for better UX
                  label="Show Learn More Link"
                  checked={showLearnMoreLink}
                  onChange={handleShowLearnMoreLink}
                />
              </div>

              <Form.Control
                type="text"
                name="learnMoreLink"
                required
                placeholder="Enter learn more link"
                value={learnMoreLink}
                onChange={handleLearnMoreLinkChange} // Update the learn more link
                disabled={!showLearnMoreLink} // Disable input if "Show Learn More Link" is unchecked
              />
            </Form.Group>
          </Col>

          <Col xs="12">
            <Form.Group controlId="richTextEditor" className="mb-3">
              <Form.Label>
                Section Description<span className="text-danger">*</span>
              </Form.Label>
              <ReactQuill
                theme="snow"
                onChange={handleContentChange} // Update the content
                placeholder="Enter the content for this section"
                modules={{
                  toolbar: [
                    [{ header: "1" }, { header: "2" }, { font: [] }],
                    [{ size: [] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["link", "image", "video"],
                    ["clean"],
                    [{ color: [] }, { background: [] }],
                  ],
                }}
                value={content}
              />

              {/* <TextEditor
                onContentChange={handleContentChange}
                content={content}
              /> */}
            </Form.Group>
          </Col>

          <Col xs="12">
            <Form.Group controlId="hideHelpAndSupportToggle" className="mb-3">
              <Form.Check
                type="checkbox"
                label="Hide Help and Support Section"
                checked={hideHelpAndSupportToggle}
                onChange={handleHideHelpAndSupportToggle}
              />
            </Form.Group>
          </Col>

          {/* <Col xs="12">
            <Form.Group controlId="videoUrl" className="mb-3">
              <Form.Label>Video URL (optional)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter video URL"
                onChange={(e) => setVideoUrl(e.target.value)} // Update the video URL
              />
            </Form.Group>
          </Col> */}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CreateNewSection;
