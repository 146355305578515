import React, { useContext } from "react";
import { Accordion, Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { FaChevronDown } from "react-icons/fa";
import { TransparencyContext } from "../../context/Context";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  createSupplier,
  initializeAssessment,
  updateSupplierId,
} from "./transparencyHubAPI";
import { getActiveUserData, getWhiteLabel } from "../../helpers/store";

const Step1TransparencyHub = () => {
  const {
    nextStep,
    maxStep,
    assessmentData,
    supplierData,
    year,
    yearType,
    isLoading,
  } = useContext(TransparencyContext);
  const activeUser = JSON.parse(getActiveUserData());
  // console.log(supplierData);
  const handleStartAssessment = async () => {
    let supplierId = supplierData?.supplierId;
    if (!supplierId) {
      supplierId = await createSupplier(supplierData);
      console.log("New supplier", supplierId);
      await updateSupplierId(
        supplierData.netnadaId, //businessId
        supplierId
      );
    }
    await initializeAssessment(
      supplierId,
      supplierData.netnadaId,
      year,
      yearType,
      assessmentData
    );

    // const status = "in-progress";
    // const contactProperties = {
    //   supplierAssessmentStatus: status,
    // };
    // await updateLoopsContact(contactProperties);
    nextStep();
  };
  return (
    <Row className="justify-content-center m-3">
      <Col md={12}>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <FaChevronDown className="me-2" />
              What is a Sustainability Transparency Score?
            </Accordion.Header>
            <Accordion.Body>
              <p>
                A Sustainability Transparency score is used by companies,
                investors, and governments to understand and compare their
                environmental impact. The score reflects the level of
                transparency, action, and progress an organisation is taking to
                tackle certain ESG criteria.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <FaChevronDown className="me-2" />
              How is it scored?
            </Accordion.Header>
            <Accordion.Body>
              <p>
                If an organisation’s score is high (A the highest score), it
                demonstrates dedication and commitment to improving their ESG
                metrics, lowering GHG emissions, and their overall environmental
                impact, ensuring that they are moving towards a more sustainable
                future. It is important to note that high emissions do not
                equate to a low score. What is being measured and how, is the
                contributing factor for the overall score.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <FaChevronDown className="me-2" />
              Why am I being requested to complete a Sustainability Transparency
              score?
            </Accordion.Header>
            <Accordion.Body>
              <p>
                A {getWhiteLabel().name} business or partner is currently
                calculating the carbon footprint of their operations and
                implementing a supply chain sustainability strategy. As part of
                the process, they are requesting information from their
                suppliers (you) to better understand ESG practices and further
                assist their supply chain on improving them if necessary.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <FaChevronDown className="me-2" />
              Who many questions are involved?
            </Accordion.Header>
            <Accordion.Body>
              <p>
                A full assessment contains 180 questions across several
                categories including but not limited to: Environment, Public
                Sustainability Reporting, Climate Change Action, Risk
                Management, Gender Equality, Diversity.
              </p>
              <p>
                You can download the full assessment <a href="#">here</a>.
                <strong>
                  {" "}
                  However, we recommend you start by using our upload files
                  option to fast track the process.
                </strong>
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              <FaChevronDown className="me-2" />
              How can I get started and how can {getWhiteLabel().name} help?
            </Accordion.Header>
            <Accordion.Body>
              <p>
                The easiest way to start is uploading any corporate reporting
                you have available and let our team and systems pre-fill the
                answer on your behalf. This will save you time and have you
                going back to business as usual as fast as possible.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {activeUser.inventoryId && (
          <Row className="justify-content-center mb-3 mt-4">
            <Col md={8} lg={6}>
              {maxStep && maxStep === 1 ? (
                <Button
                  variant="outline-primary"
                  size="lg"
                  className="w-100"
                  onClick={handleStartAssessment}
                >
                  {isLoading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: 5 }}
                      />
                      Loading...
                    </>
                  ) : (
                    "Start Assessment"
                  )}
                </Button>
              ) : (
                <Button variant="outline-success" size="lg" className="w-100">
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ marginRight: "5px" }}
                  />
                  Assessment Started
                </Button>
              )}
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default Step1TransparencyHub;
