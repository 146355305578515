import React, { useEffect } from "react";
import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
import WidgetSectionTitle from "../../widgets/WidgetSectionTitle";
import ReportsTable from "./ReportsTable";
import { getBusinessReports } from "./reportsApi";
import { useState } from "react";
import { useAuth } from "../../Contexts/AuthContext";
import { faList } from "@fortawesome/free-solid-svg-icons";
import {
  getActiveUserData,
  getWhiteLabel,
  setActiveUserData,
} from "../../helpers/store";
import DataDateFilter from "../../helpers/DataDateFilter";

const Index = () => {
  const { currentUser } = useAuth();
  const [activeUser, setActiveUser] = useState(JSON.parse(getActiveUserData()));
  useEffect(() => {
    const analytics = getAnalytics();
    setUserId(analytics, currentUser.uid);
    setUserProperties(analytics, { businessId: currentUser.businessId });
    logEvent(analytics, "page_view", {
      page_title: "Reports",
    });
  }, [currentUser]);

  const [reports, setReports] = useState();

  async function getReports() {
    const response = await getBusinessReports();
    setReports(response);
  }

  useEffect(() => {
    getReports();
  }, []);

  const [selectedDates, setSelectedDates] = useState({
    startDate: new Date(activeUser.currentStartDate),
    endDate: new Date(activeUser.currentEndDate),
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates) => {
    const [start, end] = dates;
    setSelectedDates({
      startDate: start,
      endDate: end,
    });
    //to initiate chart data calculation
    sessionStorage.setItem("dashboardDataCalCompleted", false);
  };

  useEffect(() => {
    if (selectedDates.startDate !== null && selectedDates.endDate !== null) {
      //update user data in session storage
      let userData = JSON.parse(getActiveUserData());
      userData.currentStartDate = selectedDates.startDate;
      userData.currentEndDate = selectedDates.endDate;
      setActiveUserData(JSON.stringify(userData));

      setStartDate(selectedDates.startDate);
      setEndDate(selectedDates.endDate);
    }
  }, [selectedDates]);

  return (
    <>
      <WidgetSectionTitle
        icon={faList}
        title="Report Viewer"
        subtitle={`Request, view, and download all your ${
          getWhiteLabel().name
        } sustainability reports. The information on these reports is based on the data available in your account.`}
        transform="shrink-2"
        className="mb-4 mt-6"
      />
      <div className="mb-3">
        <DataDateFilter
          startDate={selectedDates.startDate}
          endDate={selectedDates.endDate}
          onChange={onChange}
        />
      </div>
      <div className="mb-3">
        <ReportsTable
          reports={reports}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
    </>
  );
};

export default Index;
