import { clearLoginData } from "../../../helpers/store";
import { getAuthorizationHeaderWithContentType } from "../../../helpers/utils";

export const submitWasteForm = async (body) => {
  try {
    const response = await fetch("/api/climateActive/submit-waste-form", {
      method: "POST",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 200) {
      return { message: "Success" };
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const saveWasteDraft = async (body) => {
  try {
    const response = await fetch("/api/climateActive/save-waste-draft", {
      method: "POST",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const submitDraftedWasteForm = async (body) => {
  try {
    const response = await fetch(
      "/api/climateActive/submit-drafted-waste-form",
      {
        method: "PUT",
        headers: getAuthorizationHeaderWithContentType(),
        body: JSON.stringify(body),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      return;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const saveDraftedWasteForm = async (body) => {
  try {
    const response = await fetch("/api/climateActive/save-drafted-waste-form", {
      method: "PUT",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const getWasteDataByEventId = async (id) => {
  try {
    const response = await fetch(
      "/api/climateActive/get-waste-data-by-event-id/" + id,
      {
        method: "GET",
        headers: getAuthorizationHeaderWithContentType(),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const getWasteDataByWasteId = async (id) => {
  try {
    const response = await fetch(
      "/api/climateActive/get-waste-data-by-waste-id/" + id,
      {
        method: "GET",
        headers: getAuthorizationHeaderWithContentType(),
        mode: "cors",
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};
