import React from "react";

const AlphabeticalGrade = ({ score = 30, maxScore = 40 }) => {
  const calculateGrade = (score, maxScore) => {
    const percentage = (score / maxScore) * 100;

    if (percentage >= 80) return "A";
    if (percentage >= 60) return "B";
    if (percentage >= 40) return "C";
    if (percentage >= 20) return "D";
    return "F";
  };

  const grade = calculateGrade(score, maxScore);

  return (
    <div className="grade-container">
      <div className={`grade-box ${grade === "A" ? "highlight" : ""}`}>A</div>
      <div className={`grade-box ${grade === "B" ? "highlight" : ""}`}>B</div>
      <div className={`grade-box ${grade === "C" ? "highlight" : ""}`}>C</div>
      <div className={`grade-box ${grade === "D" ? "highlight" : ""}`}>D</div>
      <div className={`grade-box ${grade === "F" ? "highlight" : ""}`}>E</div>
    </div>
  );
};

export default AlphabeticalGrade;
