import React from "react";
import { Row, Col } from "react-bootstrap";
import WidgetSectionTitle from "../../widgets/WidgetSectionTitle";
import {
  faCalculator,
  fa1,
  fa2,
  fa3,
  fa4,
  fa5,
  fa6,
} from "@fortawesome/free-solid-svg-icons";
import OverviewCard from "../new-overview/newOverviewCard";

const ActivityData = () => {
  return (
    <>
      <Row label="level_1_block">
        <Row className="mb-2">
          <WidgetSectionTitle
            icon={faCalculator}
            title="Calculators"
            subtitle="Use calculators to estimate emissions based on activity data and are used to improve the quality of your carbon emissions inventory."
            transform="shrink-2"
            className="mb-4 mt-6"
          />
        </Row>
        <Row>
          <Row className="mb-4">
            <Col>
              <OverviewCard
                className="mb-3 tour-style-overview-supply-chain-table"
                title="See all"
                descriptionIncompleted="See"
                descriptionCompleted="See All emissions."
                buttonTextIncompleted=""
                buttonTextCompleted="Go to Summary"
                isCompleted={true}
                link="/welcome/data/climate-active/all-forms"
              ></OverviewCard>
            </Col>
            <Col></Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <OverviewCard
                className="mb-3 tour-style-overview-supply-chain-table"
                title="Flights"
                descriptionIncompleted="Estimate flight by adding airport codes and dates or importing a CSV of your business flights to speed the process."
                descriptionCompleted=""
                buttonTextIncompleted="Go to Calculator"
                buttonTextCompleted=""
                isCompleted={false}
                link="/welcome/data/climate-active/flights-form"
                icon={fa1}
              ></OverviewCard>
            </Col>
            <Col>
              <OverviewCard
                className="mb-3 tour-style-overview-supply-chain-table"
                title="Waste"
                descriptionIncompleted="Streamline the process for waste-related emissions. Input the types and quantities of waste generated by your business to gauge your environmental impact accurately."
                descriptionCompleted=""
                buttonTextIncompleted="Go to Calculator"
                buttonTextCompleted=""
                isCompleted={false}
                link="/welcome/data/climate-active/waste-form"
                icon={fa2}
              ></OverviewCard>
            </Col>
            <Col>
              <OverviewCard
                className="mb-3 tour-style-overview-supply-chain-table"
                title="Paper"
                descriptionIncompleted="Paper usage might be a specific metric you are tracking.  Easily estimate the carbon footprint associated with your paper consumption and reduce your environmental impact through sustainable paper practices."
                descriptionCompleted=""
                buttonTextIncompleted="Go to Calculator"
                buttonTextCompleted=""
                isCompleted={false}
                link="/welcome/data/climate-active/paper-form"
                icon={fa3}
              ></OverviewCard>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <OverviewCard
                className="mb-3 tour-style-overview-supply-chain-table"
                title="Water"
                descriptionIncompleted="Estimate water emissions by either uploading your water usage data or answering a few simple questions about your home or work environment. Get accurate insights into your water usage and take steps towards a more sustainable lifestyle."
                descriptionCompleted=""
                buttonTextIncompleted="Go to Calculator"
                buttonTextCompleted=""
                isCompleted={false}
                link="/welcome/data/water"
                icon={fa4}
              ></OverviewCard>
              {/* <OverviewCard
                className="mb-3 tour-style-overview-supply-chain-table"
                title="Employee Commute"
                descriptionIncompleted="Measure the emissions generated by employee commuting. This calculator helps you understand your workforce's daily commute and make informed to support sustainable commuting options."
                descriptionCompleted=""
                buttonTextIncompleted="Go to Calculator"
                buttonTextCompleted=""
                isCompleted={false}
                link="/welcome/data/climate-active/staff-form"
                icon={fa4}
              ></OverviewCard> */}
            </Col>
            <Col>
              <OverviewCard
                className="mb-3 tour-style-overview-supply-chain-table"
                title="Employee Engagement"
                descriptionIncompleted="Measure the environmental impact of your daily work habits, whether you commute to the office or work from home to gain insights into your carbon footprint and contribute to a more sustainable workplace."
                descriptionCompleted=""
                buttonTextIncompleted="Go to Calculator"
                buttonTextCompleted=""
                isCompleted={false}
                link="/welcome/data/employee-engagement"
                icon={fa5}
              ></OverviewCard>
              {/* <OverviewCard
                className="mb-3 tour-style-overview-supply-chain-table"
                title="WFH Emissions"
                descriptionIncompleted="Assess the carbon emissions associated with remote work. As working from home becomes more prevalent, this tool allows you to calculate the environmental impact of remote work arrangements."
                descriptionCompleted=""
                buttonTextIncompleted="Go to Calculator"
                buttonTextCompleted=""
                isCompleted={false}
                link="/welcome/data/wfh"
                icon={fa5}
              ></OverviewCard> */}
            </Col>
            <Col>
              <OverviewCard
                className="mb-3 tour-style-overview-supply-chain-table"
                title="Events"
                descriptionIncompleted="Estimate Events emissions."
                descriptionCompleted=""
                buttonTextIncompleted="Go to Calculator"
                buttonTextCompleted=""
                isCompleted={false}
                link="/welcome/dashboard/events"
                icon={fa6}
              ></OverviewCard>
            </Col>
          </Row>
        </Row>
      </Row>
      {/* <Row>
        <video width="100%" controls>
          <source
            src="https://firebasestorage.googleapis.com/v0/b/netnada-staging.appspot.com/o/videos%2F1918465-uhd_3840_2160_24fps.mp4?alt=media&token=0a126fa3-0948-4194-833b-f15989a1c654"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </Row> */}
    </>
  );
};
export default ActivityData;
