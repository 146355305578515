import { formatDateToYYYYMM01 } from "../../helpers/utils";
import {
  fetchEmissionsByCategory,
  fetchEmissionsByScope,
  fetchEmissionsBySupplier2,
  fetchEmissionsFromClimateActive,
  fetchEmissionsFromWasteCalculator,
  fetchEmissionsFromWaterCalculator,
} from "./UnifiedDataEmissionDataHelperNew";
export const fetchOfficeData = async (
  officeId,
  selectedStartDate,
  selectedEndDate
) => {
  // console.log(
  //   "fetchOfficeData=>",
  //   officeId,
  //   selectedStartDate,
  //   selectedEndDate
  // );
  const officeDashboardData = {
    emissionsByScope: {},
    emissionsBySupplier: {},
    emissionsByCategory: {},
    emissionsFromCalculators: {},
    emissionsFromWasteCalculator: {},
    emissionsFromWaterCalculator: {},
    totalEmissions: {
      total: null,
      totalScope1: null,
      totalScope2: null,
      totalScope3: null,
      totalLines: null,
    },
  };
  try {
    const startDate = formatDateToYYYYMM01(new Date(selectedStartDate));
    // console.log("startDate=>", startDate);
    const endDate = formatDateToYYYYMM01(new Date(selectedEndDate));
    // console.log("endDate=>", endDate);
    const calculatorEmissionData = await fetchEmissionsFromClimateActive(
      startDate,
      endDate
    );
    // console.log("calculatorEmissionData", calculatorEmissionData);
    if (calculatorEmissionData) {
      officeDashboardData.emissionsFromCalculators = calculatorEmissionData;
    }

    const wasteCalculatorEmissionData = await fetchEmissionsFromWasteCalculator(
      startDate,
      endDate
    );
    // console.log("wasteCalculatorEmissionData", wasteCalculatorEmissionData);
    if (wasteCalculatorEmissionData) {
      officeDashboardData.emissionsFromWasteCalculator =
        wasteCalculatorEmissionData;
    }

    const waterCalculatorEmissionData = await fetchEmissionsFromWaterCalculator(
      startDate,
      endDate
    );
    // console.log("waterCalculatorEmissionData", waterCalculatorEmissionData);
    if (waterCalculatorEmissionData) {
      officeDashboardData.emissionsFromWaterCalculator =
        waterCalculatorEmissionData;
    }

    const supplierEmissionData = await fetchEmissionsBySupplier2(
      startDate,
      endDate
    );
    // console.log("supplierEmissionData", supplierEmissionData);
    if (supplierEmissionData) {
      officeDashboardData.emissionsBySupplier = supplierEmissionData;
    }

    const scopeEmissionData = await fetchEmissionsByScope(startDate, endDate);
    // console.log("scopeEmissionData", scopeEmissionData);
    if (scopeEmissionData) {
      officeDashboardData.totalEmissions = scopeEmissionData.totals;
      officeDashboardData.totalEmissions.total =
        scopeEmissionData.totals.totalScope1 +
        scopeEmissionData.totals.totalScope2 +
        scopeEmissionData.totals.totalScope3;
      officeDashboardData.emissionsByScope = scopeEmissionData.monthlyData;
    }
    const categoryEmissionData = await fetchEmissionsByCategory(
      startDate,
      endDate
    );
    // console.log("categoryEmissionData", categoryEmissionData);
    if (categoryEmissionData) {
      officeDashboardData.emissionsByCategory = categoryEmissionData;
    }
  } catch (err) {
    console.log(err);
  }
  return { officeData: {}, officeDashboardData };
};
