import {
  getAuthorizationHeader,
  getAuthorizationHeaderWithContentType,
} from "../../helpers/utils";
import { clearLoginData } from "../../helpers/store";
import { toast } from "react-toastify";

export const getReportRequests = async () => {
  try {
    const response = await fetch("/api/admin/get-report-requests", {
      method: "GET",
      headers: getAuthorizationHeader(),
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const getContentRequests = async () => {
  try {
    const response = await fetch("/api/admin/get-content-requests", {
      method: "GET",
      headers: getAuthorizationHeader(),
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const updateReportRequest = async (body) => {
  try {
    const response = await fetch("/api/admin/update-report-request", {
      method: "PUT",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 401) {
      clearLoginData();
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteReportRequest = async (body) => {
  try {
    const response = await fetch("/api/admin/delete-report-request", {
      method: "DELETE",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 401) {
      clearLoginData();
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateContentRequest = async (body) => {
  try {
    const response = await fetch("/api/admin/update-content-request", {
      method: "PUT",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 401) {
      clearLoginData();
    }
  } catch (error) {
    console.error(error);
  }
};

export const getAdvisorBusinesses = async (advisorId) => {
  try {
    const response = await fetch(
      `/api/admin/get-advisor-businesses/${advisorId}`,
      {
        method: "GET",
        headers: getAuthorizationHeader(),
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const getAdvisors = async () => {
  try {
    const response = await fetch("/api/admin/get-advisors", {
      method: "GET",
      headers: getAuthorizationHeader(),
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const getBusinesses = async () => {
  try {
    const response = await fetch("/api/admin/get-businesses", {
      method: "GET",
      headers: getAuthorizationHeader(),
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const getUsers = async () => {
  try {
    const response = await fetch("/api/admin/get-users", {
      method: "GET",
      headers: getAuthorizationHeader(),
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const getOffices = async () => {
  try {
    const response = await fetch("/api/admin/get-offices", {
      method: "GET",
      headers: getAuthorizationHeader(),
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const getUsersAndOfficesByBusinessId = async (businessId) => {
  try {
    const response = await fetch(
      "/api/admin/get-users-offices-by-business-id/" + businessId,
      {
        method: "GET",
        headers: getAuthorizationHeader(),
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const getBusinessByUserId = async (userId) => {
  try {
    const response = await fetch(
      "/api/admin/get-businesses-by-user-id/" + userId,
      {
        method: "GET",
        headers: getAuthorizationHeader(),
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const updateBusiness = async (body) => {
  try {
    const response = await fetch("/api/admin/update-business", {
      method: "PUT",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 401) {
      clearLoginData();
    } else if (response.status !== 200) {
      toast.error("Failed to update business");
    } else {
      toast.success(`Successfully updated business`);
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateUser = async (body) => {
  try {
    const response = await fetch("/api/admin/update-user", {
      method: "PUT",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 401) {
      clearLoginData();
    } else if (response.status !== 200) {
      toast.error("Failed to update user");
    } else {
      toast.success(`Successfully updated user`);
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteBusinessByBusinessId = async (body) => {
  try {
    const response = await fetch("/api/admin/delete-business-by-business-id", {
      method: "DELETE",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 401) {
      clearLoginData();
    } else if (response.status !== 200) {
      toast.error("Failed to delete business");
    } else {
      toast.success(`Successfully deleted business`);
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteUserByUserId = async (body) => {
  try {
    const response = await fetch("/api/admin/delete-user-by-user-id", {
      method: "DELETE",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 401) {
      clearLoginData();
    } else if (response.status !== 200) {
      toast.error("Failed to delete user");
    } else {
      toast.success(`Successfully deleted user`);
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteUsersByBusinessId = async (body) => {
  try {
    const response = await fetch("/api/admin/delete-users-by-business-id", {
      method: "DELETE",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 401) {
      clearLoginData();
    } else if (response.status !== 200) {
      toast.error("Failed to delete users");
    } else {
      toast.success(`Successfully deleted users`);
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteNoUserByBusinessId = async (body) => {
  try {
    const response = await fetch("/api/admin/delete-no-user-by-business-id", {
      method: "DELETE",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 401) {
      clearLoginData();
    } else if (response.status !== 200) {
      toast.error("Failed to nullify users");
    } else {
      toast.success(`Successfully modified business for users`);
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateBusinessByUserId = async (body) => {
  try {
    const response = await fetch("/api/admin/update-business-by-user-id", {
      method: "PUT",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 401) {
      clearLoginData();
    } else if (response.status !== 200) {
      toast.error("Failed to update user's business");
    } else {
      toast.success(`Successfully updated users's business`);
    }
  } catch (error) {
    console.error(error);
  }
};

export const getAllWhitelist = async () => {
  try {
    const response = await fetch("/api/admin/get-all-whitelist", {
      method: "GET",
      headers: getAuthorizationHeader(),
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const saveWhitelist = async (body) => {
  try {
    const response = await fetch("/api/admin/save-whitelist", {
      method: "POST",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 401) {
      clearLoginData();
    } else if (response.status !== 200) {
      toast.error("Failed to save whitelist user");
    } else {
      toast.success(`Successfully saved.`);
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteWhitelist = async (whitelistId) => {
  try {
    const response = await fetch("/api/admin/delete-whitelist/" + whitelistId, {
      method: "DELETE",
      headers: getAuthorizationHeaderWithContentType(),
      mode: "cors",
    });

    if (response.status === 401) {
      clearLoginData();
    } else if (response.status !== 200) {
      toast.error("Failed to delete whitelist user");
    } else {
      toast.success(`Successfully deleted`);
    }
  } catch (error) {
    console.error(error);
  }
};

export const getUserTickets = async (status) => {
  try {
    const response = await fetch(
      "/api/userTicket/get-user-tickets?status=" + status,
      {
        method: "GET",
        headers: getAuthorizationHeader(),
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const updateUserTicketStatus = async (body) => {
  try {
    const response = await fetch("/api/userTicket/update-user-ticket-status", {
      method: "PUT",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 401) {
      clearLoginData();
    } else if (response.status !== 200) {
      toast.error("Failed to update user ticket status");
    } else {
      toast.success(`Successfully updated.`);
    }
  } catch (error) {
    console.error(error);
  }
};

export const getBusinessDataSourcesFromContacts = async (businessId) => {
  try {
    const response = await fetch(
      `/api/admin/get-data-sources-from-contacts/${businessId}`,
      {
        method: "GET",
        headers: getAuthorizationHeader(),
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const getSupplierDetailsForBusiness = async (
  businessId,
  reportingYear,
  reportingType,
  dataSource
) => {
  try {
    const response = await fetch(
      `/api/admin/get-suppliers-for-business/${businessId}/${reportingYear}/${reportingType}/${dataSource}`,
      {
        method: "GET",
        headers: getAuthorizationHeader(),
      }
    );

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const sendSuppliersByEmail = async (body) => {
  try {
    const response = await fetch("/api/admin/send-suppliers-by-email", {
      method: "POST",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 401) {
      clearLoginData();
    } else if (response.status !== 200) {
      toast.error("Failed to send suppliers by email");
    } else {
      toast.success(`Successfully sent.`);
    }

    return response.status;
  } catch (error) {
    console.error(error);
  }
};

export const getTransparencyAssessments = async () => {
  try {
    const response = await fetch("/api/admin/get-transparency-assessments", {
      method: "GET",
      headers: getAuthorizationHeader(),
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

export const updateTransparencyAssessment = async (body) => {
  try {
    const response = await fetch("/api/admin/update-transparency-assessment", {
      method: "PUT",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 401) {
      clearLoginData();
    } else if (response.status !== 200) {
      toast.error("Failed to update Transparency Assessment");
    } else {
      toast.success(`Successfully updated.`);
    }
  } catch (error) {
    console.error(error);
  }
};

export const createTransparencyAssessment = async (body) => {
  try {
    const response = await fetch("/api/admin/create-transparency-assessment", {
      method: "POST",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 401) {
      clearLoginData();
    } else if (response.status !== 200) {
      toast.error("Failed to create Transparency Assessment");
    } else {
      toast.success(`Successfully created.`);
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteTransparencyAssessment = async (body) => {
  try {
    const response = await fetch("/api/admin/delete-transparency-assessment", {
      method: "DELETE",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });
    if (response.status === 401) {
      clearLoginData();
    } else if (response.status !== 200) {
      toast.error("Failed to update Transparency Assessment");
    } else {
      toast.success(`Successfully updated.`);
    }
  } catch (error) {
    console.error(error);
  }
};

export const getAllInventoryProgress = async () => {
  try {
    const response = await fetch("/api/users/get-all-inventory-progress", {
      method: "GET",
      headers: getAuthorizationHeader(),
    });
    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }
    return [];
  } catch (error) {
    console.error(error);
  }
};

export const updateFrozenStatus = async (body) => {
  try {
    const response = await fetch("/api/users/update-frozen-data-status", {
      method: "PUT",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });
    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }
    return [];
  } catch (error) {
    console.error(error);
  }
};
export const createUnifiedFrozenDataCollection = async (body) => {
  try {
    const response = await fetch("/api/admin/create-unified-frozen-data", {
      method: "POST",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });

    if (response.status === 401) {
      clearLoginData();
    } else if (response.status !== 200) {
      toast.error("Failed to create Unified Frozen Data");
    } else {
      toast.success(`Successfully created.`);
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateFrozenDate = async (body) => {
  try {
    const response = await fetch("/api/users/update-frozen-date", {
      method: "PUT",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });
    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }
    return [];
  } catch (error) {
    console.error(error);
  }
};

export const updateShowPreviousYearStatus = async (body) => {
  try {
    const response = await fetch(
      "/api/users/update-show-previous-year-status",
      {
        method: "PUT",
        headers: getAuthorizationHeaderWithContentType(),
        body: JSON.stringify(body),
        mode: "cors",
      }
    );
    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }
    return [];
  } catch (error) {
    console.error(error);
  }
};

export const updateDashboardOrder = async (body) => {
  try {
    const response = await fetch("/api/users/update-dashboard-order", {
      method: "PUT",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });
    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }
    return [];
  } catch (error) {
    console.error(error);
  }
};

export const getAllInvoicesByBusinessId = async (businessId) => {
  try {
    const response = await fetch(
      `/api/admin/get-all-invoices-by-business-id/${businessId}`,
      {
        method: "GET",
        headers: getAuthorizationHeader(),
      }
    );
    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }
    return [];
  } catch (error) {
    console.error(error);
  }
};

export const refreshDashboardCalculation = async (body) => {
  try {
    const response = await fetch("/api/admin/set-grouped-unified-data", {
      method: "POST",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify(body),
      mode: "cors",
    });
    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      clearLoginData();
    }
    return [];
  } catch (error) {
    console.error(error);
  }
};
