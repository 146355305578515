import React, { useEffect } from "react";
import {
  Spinner,
  Col,
  Row,
  Dropdown,
  Modal,
  Form,
  Button,
} from "react-bootstrap";
import { useState } from "react";
import WidgetSectionTitle from "../../widgets/WidgetSectionTitle";
import AdvanceTableWrapper from "../common/advance-table/AdvanceTableWrapper";
import { Card } from "react-bootstrap";
import AdvanceTable from "../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../common/advance-table/AdvanceTableFooter";
import AdvanceTableSearchBox from "../common/advance-table/AdvanceTableSearchBox";
import { getAllWhitelist, saveWhitelist, deleteWhitelist } from "./adminApi";
import { useAuth } from "../../Contexts/AuthContext";
import CardDropdown from "../common/CardDropdown";
import SoftBadge from "../common/SoftBadge";
import IconButton from "../../components/common/IconButton";
import { faAdd } from "@fortawesome/free-solid-svg-icons";

const WhitelistUsers = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [whitelist, setWhitelist] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedWhitelist, setSelectedWhitelist] = useState(null);

  const columns = [
    {
      accessor: "",
      Header: "Actions",
      Cell: (rowData) => {
        return (
          <>
            <CardDropdown>
              <div className="py-2">
                <Dropdown.Item
                  as="button"
                  onClick={() => handleEditOpen(rowData.row.original)}
                >
                  Edit
                </Dropdown.Item>

                <Dropdown.Item
                  className="text-danger"
                  as="button"
                  onClick={() => handleDeleteWhitelist(rowData.row.original.id)}
                >
                  Delete
                </Dropdown.Item>
              </div>
            </CardDropdown>
          </>
        );
      },
    },
    {
      accessor: "domain",
      Header: "Domain",
    },
    {
      accessor: "ip",
      Header: "IP",
    },
    {
      accessor: "user",
      Header: "User",
    },
    {
      accessor: "is_active",
      Header: "Status",

      headerProps: { className: "text-center" },
      cellProps: {
        className: "fs-0",
      },
      Cell: (rowData) => {
        const { is_active } = rowData.row.original;
        return (
          <>
            <SoftBadge
              pill
              bg={is_active === true ? "success" : "warning"}
              className="d-block"
            >
              {is_active === true ? "Active" : "Inactive"}
            </SoftBadge>
          </>
        );
      },
    },
  ];

  async function setWhitelistToState() {
    setIsLoading(true);
    const whitelistItems = (await getAllWhitelist()) || [];
    setWhitelist(whitelistItems);
    setIsLoading(false);
  }

  function handleEditOpen(item) {
    setSelectedWhitelist(item);
    setShowEditModal(true);
  }

  async function handleEditClose(isRefresh) {
    setShowEditModal(false);
    if (isRefresh) {
      await setWhitelistToState();
    }
  }

  async function handleDeleteWhitelist(id) {
    await deleteWhitelist(id);
    await setWhitelistToState();
  }

  useEffect(async () => {
    setWhitelistToState();
  }, []);

  return (
    <>
      <WidgetSectionTitle
        title="Whitelist"
        subtitle="Whitelist Users"
        transform="shrink-2"
        className="mb-4 mt-6"
      />

      {showEditModal && (
        <EditWhitelistModal
          showEditModal={showEditModal}
          handleEditClose={handleEditClose}
          data={{ ...selectedWhitelist }}
        />
      )}

      {isLoading ? (
        <div className="mb-3">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className="mb-3">
          <AdvanceTableWrapper
            columns={columns}
            data={whitelist}
            sortable
            pagination
            perPage={25}
            rowCount={whitelist.length}
          >
            <Card>
              <Card.Header>
                <Row className="flex-end-center mb-3">
                  <Col
                    xs={4}
                    sm="auto"
                    className="d-flex align-items-center pe-0"
                  >
                    <div id="orders-actions">
                      <IconButton
                        variant="falcon-default"
                        size="sm"
                        icon={faAdd}
                        transform="shrink-3"
                      >
                        <span
                          className="d-none d-sm-inline-block ms-1"
                          onClick={() => handleEditOpen(null)}
                        >
                          Add whitelist rule
                        </span>
                      </IconButton>
                    </div>
                  </Col>
                  <Col xs="auto" sm={6} lg={4}>
                    <AdvanceTableSearchBox table />
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="p-0">
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="btn-reveal-trigger text-nowrap align-middle"
                  tableProps={{
                    size: "lg",
                    className: "fs--1 mb-0 overflow-hidden",
                  }}
                />
              </Card.Body>
              <Card.Footer>
                <AdvanceTableFooter
                  data={whitelist}
                  rowCount={whitelist.length}
                  table
                  rowInfo
                  navButtons
                />
              </Card.Footer>
            </Card>
          </AdvanceTableWrapper>
        </div>
      )}
    </>
  );
};

const EditWhitelistModal = ({ showEditModal, handleEditClose, data }) => {
  console.log("data=>", data);
  const [whitelistId] = useState(data?.id || null);
  const [whitelistDomain, setWhitelistDomain] = useState(data?.domain || null);
  const [whitelistIP, setWhitelistIP] = useState(data?.ip || null);
  const [whitelistUser, setWhitelistUser] = useState(data?.user || null);
  const [whitelistIsActive, setWhitelistIsActive] = useState(
    data?.is_active || false
  );

  async function handleSubmit(e) {
    e.preventDefault();
    await saveWhitelist({
      id: whitelistId,
      domain: whitelistDomain,
      ip: whitelistIP,
      user: whitelistUser,
      is_active: whitelistIsActive,
    });
    handleEditClose(true);
  }

  return (
    <div>
      {" "}
      <Modal
        show={showEditModal}
        onHide={handleEditClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {whitelistId !== null ? "Edit" : "Add"} whitelist IP
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Form.Group className="mb-3" controlId="">
                <Form.Label>Domain</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter domain"
                  value={whitelistDomain}
                  onChange={(e) => setWhitelistDomain(e.target.value)}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="">
                <Form.Label>IP</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter ip"
                  value={whitelistIP}
                  onChange={(e) => setWhitelistIP(e.target.value)}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="">
                <Form.Label>User</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter username"
                  value={whitelistUser}
                  onChange={(e) => setWhitelistUser(e.target.value)}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="">
                {/* <Form.Label>Active</Form.Label> */}
                <Form.Check
                  type="checkbox"
                  id="show-followers"
                  label="Activate this rule"
                  className="form-label-nogutter"
                  name="showFollowers"
                  onChange={() => setWhitelistIsActive(!whitelistIsActive)}
                  checked={whitelistIsActive}
                />
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              disabled={!whitelistDomain || !whitelistIP || !whitelistUser}
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default WhitelistUsers;
