import { useEffect, useState } from 'react'
import { getFlightDataByDates } from '../Flights/flightAPI';
import { Card, Modal, Spinner } from 'react-bootstrap';
import AdvanceTableWrapper from '../../common/advance-table/AdvanceTableWrapper';
import AdvanceTable from '../../common/advance-table/AdvanceTable';
import AdvanceTableFooter from '../../common/advance-table/AdvanceTableFooter';

const FlightDetailDataModalTable = ({ showModal, handleClose, data }) => {
    const [flightData, setFlightData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
  
    const columns = [
      {
        accessor: "departure",
        Header: "Departure",
      },
      {
        accessor: "destination",
        Header: "Destination",
      },
      {
        accessor: "flight_path",
        Header: "Flight Path",
      },
      {
        accessor: "travel_type",
        Header: "Travel Type",
      },
      {
        accessor: "number_of_oneway_flights",
        Header: "Oneway flights",
      },
      {
        accessor: "distance",
        Header: "Distance (km)",
      },
      {
        accessor: "flight_type",
        Header: "Flight Type",
      },
      {
        accessor: "flight_class",
        Header: "Class",
      },
      {
        accessor: "total_pax",
        Header: "Total pax (km)",
      },
    ];
    async function getData() {
      const response = await getFlightDataByDates(
        data.start_date,
        data.end_date,
        "SUBMITTED"
      );
      setFlightData(response);
      setIsLoading(false);
    }
  
    useEffect(() => {
      getData();
    }, []);
  
    return (
      <div>
        {" "}
        <Modal
          show={showModal}
          onHide={handleClose}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Flight Data
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isLoading ? (
              <div className="mb-3">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <>
                <div className="mb-3">
                  <AdvanceTableWrapper
                    columns={columns}
                    data={flightData}
                    sortable
                    pagination
                    perPage={5}
                    rowCount={flightData.length}
                  >
                    <Card>
                      {/* <Card.Header>
                        {" "}
                        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                          Businesses
                        </h5>
                      </Card.Header> */}
                      <Card.Body className="p-0">
                        <AdvanceTable
                          table
                          headerClassName="bg-200 text-900 text-nowrap align-middle"
                          rowClassName="btn-reveal-trigger text-nowrap align-middle"
                          tableProps={{
                            size: "lg",
                            className: "fs--1 mb-0 overflow-hidden",
                          }}
                        />
                      </Card.Body>
                      <Card.Footer>
                        <AdvanceTableFooter
                          rowCount={flightData.length}
                          table
                          rowInfo
                          navButtons
                        />
                      </Card.Footer>
                    </Card>
                  </AdvanceTableWrapper>
                </div>
              </>
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  };
  

export default FlightDetailDataModalTable