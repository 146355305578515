import React, { useEffect, useState, useRef } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faCloudUploadAlt,
} from "@fortawesome/free-solid-svg-icons";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import app from "../../../firebase";
import { useAuth } from "../../../Contexts/AuthContext";

const fireStoreDB = getFirestore(app);

export const GoogleDriveUploader = ({ setSelectedFiles }) => {
  const [isGoogleDriveConnected, setIsGoogleDriveConnected] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [pickerApiLoaded, setPickerApiLoaded] = useState(false);
  const { currentUser } = useAuth();
  const userId = currentUser.uid;

  const tokenClientRef = useRef(null);

  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
  const SCOPES = "https://www.googleapis.com/auth/drive.readonly email profile";

  useEffect(() => {
    if (userId) {
      loadGoogleScripts();
    }
  }, [userId]);

  // Load Google API scripts for Drive and Picker
  const loadGoogleScripts = () => {
    const gisScript = document.createElement("script");
    gisScript.src = "https://accounts.google.com/gsi/client";
    gisScript.async = true;
    gisScript.defer = true;
    gisScript.onload = initializeGisClient;
    document.body.appendChild(gisScript);

    const gapiScript = document.createElement("script");
    gapiScript.src = "https://apis.google.com/js/api.js";
    gapiScript.onload = () => {
      window.gapi.load("picker", { callback: () => setPickerApiLoaded(true) });
    };
    document.body.appendChild(gapiScript);
  };

  // Initialize Google Identity Services (GIS) client
  const initializeGisClient = () => {
    tokenClientRef.current = window.google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPES,
      callback: handleTokenResponse,
    });

    checkStoredToken();
  };

  // Handle token response from Google
  const handleTokenResponse = (tokenResponse) => {
    if (tokenResponse && tokenResponse.access_token) {
      const expiresIn = tokenResponse.expires_in
        ? parseInt(tokenResponse.expires_in)
        : 3600;
      const expiresAt = Date.now() + expiresIn * 1000;

      const tokenData = {
        access_token: tokenResponse.access_token,
        expires_at: expiresAt,
        token_type: tokenResponse.token_type,
        scope: tokenResponse.scope,
      };

      setAccessToken(tokenResponse.access_token);
      setIsGoogleDriveConnected(true);
      storeTokenInFirestore(tokenData);
    } else {
      console.error("Error obtaining access token:", tokenResponse);
    }
  };

  // Check if token exists in Firestore and is still valid
  const checkStoredToken = async () => {
    try {
      const tokenDoc = await getDoc(doc(fireStoreDB, "GoogleTokens", userId));
      if (tokenDoc.exists()) {
        const tokenData = tokenDoc.data().token;
        const expiresAt = tokenData.expires_at;

        if (expiresAt && Date.now() < expiresAt) {
          setAccessToken(tokenData.access_token);
          setIsGoogleDriveConnected(true);
        } else {
          tokenClientRef.current.requestAccessToken({ prompt: "none" });
        }
      } else {
        setIsGoogleDriveConnected(false);
      }
    } catch (error) {
      console.error("Error retrieving token from Firestore:", error);
      setIsGoogleDriveConnected(false);
    }
  };

  // Store token data in Firestore
  const storeTokenInFirestore = async (tokenData) => {
    try {
      await setDoc(
        doc(fireStoreDB, "GoogleTokens", userId),
        { token: tokenData },
        { merge: true }
      );
    } catch (error) {
      console.error("Error storing token data in Firestore:", error);
    }
  };

  // Handle Google Drive Connect/Disconnect
  const handleGoogleDriveConnect = () => {
    if (tokenClientRef.current) {
      tokenClientRef.current.requestAccessToken({ prompt: "" });
    } else {
      console.error("Token client not initialized.");
    }
  };

  const handleGoogleDriveDisconnect = async () => {
    if (accessToken) {
      window.google.accounts.oauth2.revoke(accessToken, async () => {
        setAccessToken(null);
        setIsGoogleDriveConnected(false);
        await clearTokenFromFirestore();
      });
    }
  };

  // Clear token from Firestore
  const clearTokenFromFirestore = async () => {
    try {
      await setDoc(
        doc(fireStoreDB, "GoogleTokens", userId),
        { token: {} },
        { merge: true }
      );
    } catch (error) {
      console.error("Error removing token from Firestore:", error);
    }
  };

  // Create Google Picker to select files from Drive
  const createPicker = async () => {
    if (pickerApiLoaded && accessToken) {
      const picker = new window.google.picker.PickerBuilder()
        .addView(window.google.picker.ViewId.DOCS)
        .setOAuthToken(accessToken)
        .setDeveloperKey(API_KEY)
        .setCallback(pickerCallback)
        .setOrigin(window.location.protocol + "//" + window.location.host)
        .build();
      picker.setVisible(true);
    } else {
      console.error("Picker API not loaded or access token missing.");
    }
  };

  const pickerCallback = (data) => {
    if (data.action === window.google.picker.Action.PICKED) {
      const files = data.docs;
      console.log("Files selected:", files);
      handleSelectedFiles(files);
    }
  };

  const handleSelectedFiles = async (files) => {
    try {
      const downloadedFiles = await Promise.all(
        files.map(async (file) => {
          const fileId = file.id;
          const fileName = file.name;
          const mimeType = file.mimeType;

          const response = await fetch(
            `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`,
            {
              headers: new Headers({ Authorization: `Bearer ${accessToken}` }),
            }
          );

          const blob = await response.blob();
          const fileObj = new File([blob], fileName, { type: mimeType });

          return fileObj;
        })
      );

      setSelectedFiles((prevFiles) => [...prevFiles, ...downloadedFiles]);
    } catch (error) {
      console.error("Error downloading files:", error);
    }
  };

  return (
    <div className="mt-3 mb-3 d-flex justify-content-between">
      {isGoogleDriveConnected ? (
        <>
          <Button
            variant="dark"
            onClick={createPicker}
            className="me-2 flex-fill"
          >
            <FontAwesomeIcon icon={faCloudUploadAlt} className="me-2" />
            Select from Google Drive
          </Button>
          <Button
            variant="outline-secondary"
            className="flex-fill"
            onClick={handleGoogleDriveDisconnect}
          >
            <FontAwesomeIcon icon={faArrowRightFromBracket} className="me-2" />
            Disconnect Google Drive
          </Button>
        </>
      ) : (
        <Button
          variant="dark"
          onClick={handleGoogleDriveConnect}
          className="flex-fill"
        >
          <FontAwesomeIcon icon={faCloudUploadAlt} className="me-2" />
          Connect to Google Drive
        </Button>
      )}
    </div>
  );
};
