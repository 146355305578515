import React, { useState, useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import { ChatContext } from "../../../context/Context";
import users from "../../../data/people";
// import rawThreads from "../../../data/chat/threads";
// import rawMessages from "../../../data/chat/messages";
import groups from "../../../data/chat/groups";
import { arrayReducer } from "../../../reducers/arrayReducer";
import { useLocation } from "react-router-dom";
import { exploreSolutions } from "../solutionsAPI";
import io from "socket.io-client";
import { useAuth } from "../../../Contexts/AuthContext";

const formatDate = (date) => {
  const options = {
    weekday: "short",
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  //format date from "Mon, May 22, 2023 at 1:16 PM" to "Mon, 1:16 PM, May 22, 2023"
  const now = date
    .toLocaleString("en-US", options)
    .split(",")
    .map((item) => item.trim())
    .map((item, index) => {
      if (index === 0) {
        return item;
      } else if (index === 2) {
        return item.split("at").map((item) => item.trim());
      } else {
        return item;
      }
    });

  // console.log("ChatProvider.js, now: ", now);

  return {
    day: now[0],
    hour: now[2][1],
    date: now[1] + ", " + now[2][0],
  };
};

const ChatProvider = ({ children }) => {
  const location = useLocation();
  // console.log("ChatProvider.js, location: ", location);

  const [data] = useState(location.state);

  const rawMessages = [
    {
      id: 0,
      content: [
        // {
        //   senderUserId: 25,
        //   message: data.initPrompt,
        //   time: formatDate(new Date()),
        //   delayMessage: false,
        //   isSolution: false,
        // },
      ],
    },
  ];

  const rawThreads = [
    {
      id: 0,
      userId: 25,
      type: "user",
      messagesId: 0,
      questionId: data.questionId,
      areasOfImprovementId: data.areasOfImprovementId,
      defaultKey: data.defaultKey,
      read: true,
      isEnded: false,
      isWaiting: false,
    },
  ];
  const { currentUser } = useAuth();
  const [messages, messagesDispatch] = useReducer(arrayReducer, rawMessages);
  const [threads, threadsDispatch] = useReducer(arrayReducer, rawThreads);
  const [currentThread, setCurrentThread] = useState(threads[0]);
  const [textAreaInitialHeight, setTextAreaInitialHeight] = useState(32);
  const [activeThreadId, setActiveThreadId] = useState(threads[0].id);
  const [isOpenThreadInfo, setIsOpenThreadInfo] = useState(false);
  const [scrollToBottom, setScrollToBottom] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const getUser = (thread) => {
    let user = {};
    if (thread.type === "group") {
      const { name, members } = groups.find(({ id }) => id === thread.groupId);
      user = {
        name,
        avatarSrc: members.map(
          (member) => users.find(({ id }) => id === member.userId).avatarSrc
        ),
      };
    } else {
      user = users.find(({ id }) => id === thread.userId);
    }
    return user;
  };

  const value = {
    users,
    groups,
    threads,
    getUser,
    messages,
    activeThreadId,
    setActiveThreadId,
    threadsDispatch,
    messagesDispatch,
    textAreaInitialHeight,
    setTextAreaInitialHeight,
    isOpenThreadInfo,
    setIsOpenThreadInfo,
    currentThread,
    setCurrentThread,
    scrollToBottom,
    setScrollToBottom,
    isLoading,
    setIsLoading,
  };

  useEffect(() => {
    const get = async () => {
      // console.log("ChatProvider.js, data: ", data);
      setIsLoading(true);
      if (data) {
        //send solutionId and questionId to backend
        const body = {
          solutionId: data.areasOfImprovementId,
          questionId: data.questionId,
        };
        //  console.log("body", body);
        await exploreSolutions(body);
        //   const botMessage = await exploreSolutions(body);
        //   console.log(botMessage);

        //   const date = new Date();

        //   if (botMessage) {
        //     let botNewMessage = {
        //       senderUserId: 25,
        //       message: `${botMessage.replace(/(?:\r\n|\r|\n)/g, "<br>")}`,
        //       status: "seen",
        //       time: formatDate(date),
        //       delayMessage: false,
        //       isSolution: false,
        //     };

        //     const { content } = messages.find(
        //       ({ id }) => id === currentThread.messagesId
        //     );

        //     const newContent = [...content, botNewMessage];
        //     messagesDispatch({
        //       type: "EDIT",
        //       payload: {
        //         id: currentThread.messagesId,
        //         content: newContent,
        //       },
        //       id: currentThread.messagesId,
        //     });

        //     threadsDispatch({
        //       type: "EDIT",
        //       payload: currentThread,
        //       id: currentThread.id,
        //       isUpdatedStart: true,
        //     });
        //   }
      }
      // setIsLoading(false);
    };
    get();
  }, [data]);

  function connectSocket() {
    const socket = io();

    socket.on("INIT_PROMPT", async (data) => {
      // console.log("INIT_PROMPT", data);

      const response = data.data;

      if (currentUser.businessId !== response.businessId) return;

      const botMessage = response.message;

      const date = new Date();

      if (botMessage) {
        let botNewMessage = {
          senderUserId: 25,
          message: `${botMessage.replace(/(?:\r\n|\r|\n)/g, "<br>")}`,
          status: "seen",
          time: formatDate(date),
          delayMessage: false,
          isSolution: false,
        };

        const { content } = messages.find(
          ({ id }) => id === currentThread.messagesId
        );

        const newContent = [...content, botNewMessage];
        messagesDispatch({
          type: "EDIT",
          payload: {
            id: currentThread.messagesId,
            content: newContent,
          },
          id: currentThread.messagesId,
        });

        threadsDispatch({
          type: "EDIT",
          payload: currentThread,
          id: currentThread.id,
          isUpdatedStart: true,
        });
      }
      setIsLoading(false);
    });
  }

  useEffect(() => {
    connectSocket();
  }, []);

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

ChatProvider.propTypes = { children: PropTypes.node.isRequired };

export default ChatProvider;
